import React, { useCallback, useRef, useEffect } from 'react';
import { connect } from "react-redux";
import Cell from './cell';
import LeftActionCall from './leftActionCall';
import { reactOnAssignedItem } from '../../../../store/actions/deliveredTrucksActions/deliveredTrucks';

const Row = (
    {
        data,
        style,
        itemSize,
        reactOnAssignedItem,
        state,
        listRef,
        startOnScroll,
        resource,
        setUploadRowState,
        setUploadModalIsOpen,
        setCommentsModalIsOpen,
    }) => {
    const rowRef = useRef({});
    const loadArea = state[resource];
    const isSubOrder = data.rowState?.loadData?.parentLoadId && data.rowState?.type === 'order';

    const subOrdersOrderIDs = loadArea?.subOrders?.map(sub => sub.orderId);
    const hasSubOrder = data?.rowState.type === 'order' && subOrdersOrderIDs?.includes(data?.rowState.orderData?.id) && !isSubOrder;

    const color = data.rowState.type === 'load' ? '#65A7A5' : isSubOrder ? '#b4d5bc' : 'white';

    useEffect(() => {
        if (rowRef.current) {
            listRef.current.resetAfterIndex(0);
        }
    }, [rowRef, listRef]);

    const onClickRow = useCallback(() => {
        reactOnAssignedItem(resource, data.rowState.id, data.rowState.type);
    }, [reactOnAssignedItem, data, resource]);

    style.width = 'max-content';
    const rowStyle = { ...style, ...{ height: `${itemSize}px`, backgroundColor: color } };
    
    const cellContent = (i, ind) => {
        const header = loadArea.headers.find(h => h.key === i.key);

        if (header.leftAction) {
            return (<LeftActionCall
                innerRef={rowRef}
                key={ind}
                type={data.rowState.type}
                cellData={i}
                reacted={data.rowState.reacted}
                itemSize={itemSize}
                onClickRow={onClickRow}
                bgColor={color}
                resource={resource}
            />);
        }

        return (<Cell
            key={ind}
            cellData={i}
            reacted={data.rowState.reacted}
            type={data.rowState.type}
            itemSize={itemSize}
            onClickRow={onClickRow}
            startOnScroll={startOnScroll}
            rowState={data.rowState}
            resource={resource}
            setUploadRowState={setUploadRowState}
            setUploadModalIsOpen={setUploadModalIsOpen}
            setCommentsModalIsOpen={setCommentsModalIsOpen}
            hasSubOrder={hasSubOrder}
        />);
    };

    return data ?
        <div className='resp-table-row' style={{ ...rowStyle, display:'flex' }}>
            {data.row.map(cellContent)}
        </div> : null;
    };

export default connect(state => ({
    state: state
}), dispatch => ({
    reactOnAssignedItem: (resource, id, type) => dispatch(reactOnAssignedItem(resource, id, type)),
}))(Row);