import React from 'react';
import { connect } from "react-redux";
import Autocomplete from 'react-google-autocomplete';

import { GetPlace } from './util/location';
import { CssErrorClassHandling } from '../helper/element';
import { ValidationErrorClass } from '../helper/validation';

const AddressField = props => {
    const { isLoaded, type, condition, ref, data, 
        classes, action, out, valudationMessages,
        placeholder, onKeyPress, name, itemValue, style } = props;

    return isLoaded ? (<Autocomplete
        disabled={!condition}
        type="text"
        types={type === 'address' ? [] : [type]}
        ref={ref}
        {...(itemValue && { value: { itemValue } })}
        value={data || ''}
        className={CssErrorClassHandling(ValidationErrorClass, classes, valudationMessages).join(' ')}
        style={style}
        onChange={event => {
            action(event);
        }}
        onKeyPress={onKeyPress}
        onPlaceSelected={place => { GetPlace(place, action); }}
        placeholder={placeholder}
        name={name}
        onBlur={out}
        componentRestrictions={{ country: ["us", "ca", "mx"] }}
    />) : null;
};

const mapStateToProps = state => {
    return {
        isLoaded: state.google.isLoaded
    }
};

export default connect(mapStateToProps)(AddressField);