import React, { useState } from 'react';
import { Checkbox } from 'antd';

const MultipleCheckbox = props => {
  const { data, type, dispatch } = props;
  const restrictionList = [
    { label: 'Straight', value: 1 }, 
    { label: 'Chimney', value: 2 }, 
    { label: 'Other', value: 3 }, 
    { label: 'Sideways', value: 4 }
  ];
  const [checkedValue, setCheckedValue] = useState(data || 4);
  
  const onChange = value => {
    setCheckedValue(value);
    dispatch({ type: type, value: value });
  };

  return <div>
          <div style={{ fontWeight: '900', margin: '0 4px 12px 8px' }}>Loading Restriction</div>
          {restrictionList.map(item => 
            <Checkbox
                key={item.value}
                onChange={e => onChange(e.target.value)}
                checked={item.value === checkedValue}
                value={item.value}
              >
                {item.label}
            </Checkbox>
          )}
    </div>
}

export default MultipleCheckbox;