import React, { useRef } from 'react';

import { useOuterClickNotifier } from '../../../../hook/action/outerClickNotifier';

const Container = props => {
    const innerRef = useRef();

    useOuterClickNotifier(
        props.onClose,
        innerRef,
        props.skipCloseClassNames,
        props.closeOnOutsideClick
    );

    return (
        <div ref={innerRef} className={props.className}>
            {props.children}
        </div>
    );
};

export default Container;