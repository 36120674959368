import React from 'react';

import { ActionBarAlert } from '../actionBar/actionBar';

const Alert = props => (<div>
        <div>
            <div className="message_box">
                <div className={`message_container${props.classes ? ` ${props.classes}` : ''}`}>
                    <h3>{props.title}</h3>
                    {props.text && <p>{props.text}</p>}
                    {props.html && props.html}
                </div>
                <ActionBarAlert {...props} />
            </div>
        </div>
    </div>);

Alert.defaultProps = {
    title: '',
    text: ''
};

export default Alert;