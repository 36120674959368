import React, { useRef, useContext, useState } from 'react';
import { CSVLink } from 'react-csv';
import { Tooltip } from 'antd';
import { GridContext } from '../list';
import axios from '../../../axios/axios';

import { CommonLabels } from '../../../../project/Defines';

const Download = () => {
    const data = useContext(GridContext);
    const [dataSource, setDataSource] = useState([]);
    const [header, setHeader] = useState([]);
    const headerArr = [];
    const csvElRef = useRef(null);

    const downloadGrid = () => {
        axios.get(data.screen, {
            params: {
                request: {
                    Sorts: data.sorts
                    
                },
                filterData: data.filter
            }
        })
            .then(result => {
                setDataSource(result.data.data);

                for (let label in data.columns) {
                    headerArr.push({ label: data.columns[label].Title, key: label });
                }

                setHeader(headerArr);
                csvElRef.current.link.click();
            });
    };

    return <div>
        <Tooltip title={CommonLabels.Buttons.Download}>
            <div className='button accent' onClick={downloadGrid}><text>{CommonLabels.Buttons.Download}</text></div>
        </Tooltip>

        <CSVLink
            headers={header}
            data={dataSource}
            filename={`${data.screen}.csv`}
            ref={csvElRef}
        />
    </div>
};

export default Download;
