export const ERROR = 'ERROR';
export const SET_HEADER_WIDTH_REJECTED_ORDERS = 'SET_HEADER_WIDTH_REJECTED_ORDERS';
export const SET_IN_LOADING = 'SET_IN_LOADING';
export const RELOAD_ON_ERROR = 'RELOAD_ON_ERROR';
export const RESET_ERROR = 'RESET_ERROR';
export const SET_DISPLAY_STATE = 'SET_DISPLAY_STATE';
export const CUSTOMER_ORDERS_REJECTED_LOADING = 'CUSTOMER_ORDERS_REJECTED_LOADING';
export const GET_CUSTOMER_ORDERS_REJECTED = 'GET_CUSTOMER_ORDERS_REJECTED';
export const SORT_ORDERS_REJECTED_ORDERS = 'SORT_ORDERS_REJECTED_ORDERS';

export const getActionType = (recource, key) => {
    return CUSTOMER_ORDERS_BUILD[key]
};

export const CUSTOMER_ORDERS_BUILD = {
    RELOAD_ON_ERROR: 'RELOAD_ON_ERROR',
    ERROR: 'ERROR',
    SET_HEADER_WIDTH_REJECTED_ORDERS: 'SET_HEADER_WIDTH_REJECTED_ORDERS',
    RESET_ERROR: 'RESET_ERROR',
    SORT_ORDERS_REJECTED_ORDERS: 'SORT_ORDERS_REJECTED_ORDERS',
    GET_CUSTOMER_ORDERS_REJECTED: 'GET_CUSTOMER_ORDERS_REJECTED',
    CUSTOMER_ORDERS_REJECTED_LOADING: 'CUSTOMER_ORDERS_REJECTED_LOADING',
};
