import React, { useMemo, useRef, useEffect, useCallback, useState } from 'react';
import { useLocation} from 'react-router-dom';
import { connect } from "react-redux";
import { VariableSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { message, Input, Checkbox } from 'antd';
import Select from 'react-select'
import { load, resetError, setOrderId, setChecked } from '../../../../store/actions/accountReceivableActions/accountReceivableActions';
import { getContentWidth } from '../../../../store/reducers/accountReceivableReducer/helper/general';
import Row from './row';
import HeaderRow from './headerRow';
import Loading from "../../screen/loading";
import HideShowMenuSwitch from '../../layout/hideShowMenuSwitch';
import UploadModal from './UploadModal';
import CommentsModal from './CommentsModal';
import ChangeRequestModal from './ChangeRequestModal';
import ChangeRequestAcceptModal from './ChangeRequestAcceptModal';
import { useClientHubOrder } from '../../../hook/hub/useClientHubOrder';
import ARSubHeader from './ARSubHeader';

const Spreadsheet = props => {
    const { 
        state,
        availableOrders,
        resetError,
        haseError,
        actionMessage,
        loading,
        load,
        resource,
        setOrderId,
        setChecked,
        userRole,
        openOrdersLoading,
        changeRequestListLoading,
        userId,
    } = props;

    const scrollSizeSmall = 18;
    const scrollSize = 16;
    const defRowHeight = 20;
    const assignedRef = useRef({});
    const [uploadRowState, setUploadRowState] = useState('');
    const [uploadModalIsOpen, setUploadModalIsOpen] = useState(false);
    const [commentsModalIsOpen, setCommentsModalIsOpen] = useState(false);
    const [changeRequestModalIsOpen, setChangeRequestModalIsOpen] = useState(false);
    const [changeRequestAcceptModalIsOpen, setChangeRequestAcceptModalIsOpen] = useState(false);
    const [allIsChecked, setAllIsChecked] = useState(false);
    const isAdmin = userRole === 'Admin';
    const [podStatus, setPodStatus] = useState('All');

    const { pathname } = useLocation()
    let endpoint;
    let filter;

    if(pathname === '/ar/deliverednopod') {
        endpoint = 'delivered';
        filter = '{"isReadyToBill": false, "isBilled": false}';
    } else if(pathname === '/ar/readytobill') {
        endpoint = 'readytobill';
        filter = '{"isReadyToBill": true, "isBilled": false}';
    } else if(pathname === '/ar/alreadybilled') {
        endpoint = 'billed';
        filter = '{"isReadyToBill": false, "isBilled": true}';
    } else if (pathname === '/ar/openorders'){
        endpoint = 'availableorderlist/';
    } else if (pathname === '/ar/changerequestsar') {
        endpoint = 'orderschangerequestslist';
    };

    useClientHubOrder((message) => {
        if (userId !== message.byId) {
            load(resource, filter, endpoint, 'withoutLoading');
        }
    }, 'notification');

    useEffect(() => {
        load(resource, filter, endpoint);
    }, [load, resource, endpoint, filter]);

    useEffect(() => {
        if (haseError) {
            message.error(actionMessage);
            resetError(resource, filter);
        }
    }, [haseError, actionMessage, resetError, resource, filter]);

    const listReference = useRef(null);
    const listHeaderReference = useRef(null);
    const mainScroll = useRef(null);
    const startOnScroll = width => mainScroll.current.scrollLeft = width;

    const onScrollX = e => {
        if (listHeaderReference.current && listReference.current && e.target) {
            listHeaderReference.current.scrollLeft =
            listReference.current.scrollLeft = e.target.scrollLeft;
        }
    };

    const header = useMemo(() =>
        <div style={{ overflow: 'hidden' }}>
            <div
                ref={listHeaderReference}
                style={{
                    width: '100%',
                    overflow: 'hidden'
                }}>
                <HeaderRow resource={resource} />
            </div>
        </div>,
    [resource]);
    
    const width = getContentWidth(state[resource].headers) + scrollSize;
    const extraScrollVisibility = window.innerWidth > width ? 'none' : 'flex';
    const [filteredData, setFilteredData] = useState([])

    useEffect(()=>{
        if(availableOrders){
            setFilteredData(availableOrders)
        }
    },[availableOrders])

    const filteredAvailableOrder =  filteredData.filter(order => order.rowState.visible)        

    const myAvailableOrders = useMemo(() => filteredAvailableOrder
        .filter(order => order.rowState?.orderData?.outNotes?.includes(podStatus) || podStatus === 'All')            
        , [filteredAvailableOrder, podStatus]
    );

    const getAsignedItemSize = useCallback(
        index => myAvailableOrders[index] ? myAvailableOrders[index].rowState.height : defRowHeight,
        [myAvailableOrders]);

    const RenderAssignedRow = useCallback(({ data, index, style }) => (
        (index !== undefined && data[index])
            ? <Row
                listRef={assignedRef}
                data={data[index]}
                itemSize={getAsignedItemSize(index)}
                style={style}
                dndRowIndex={index}
                startOnScroll={startOnScroll}
                resource={resource}
                uploadModalIsOpen={uploadModalIsOpen}
                setUploadModalIsOpen={setUploadModalIsOpen}
                setUploadRowState={setUploadRowState}
                setCommentsModalIsOpen={setCommentsModalIsOpen}
                setChangeRequestModalIsOpen={setChangeRequestModalIsOpen}
                changeRequestAcceptModalIsOpen={changeRequestAcceptModalIsOpen}
                setChangeRequestAcceptModalIsOpen={setChangeRequestAcceptModalIsOpen}
                podStatus={podStatus}
            />
            : null
    ), [changeRequestAcceptModalIsOpen, getAsignedItemSize, resource, uploadModalIsOpen, podStatus]);

    const allOrdersList = useMemo(() => myAvailableOrders &&
        <AutoSizer disableWidth>
            {({ height }) => (
                <>
                    <VariableSizeList
                        ref={assignedRef}
                        outerRef={el => {
                            listReference.current = el;
                        }}
                        style={{ overflowX: 'hidden', overflowY: 'scroll' }}
                        itemData={myAvailableOrders}
                        itemCount={myAvailableOrders.length}
                        height={height}
                        itemSize={getAsignedItemSize}>
                        {RenderAssignedRow}
                    </VariableSizeList>
                </>
            )}
        </AutoSizer>
        , [myAvailableOrders, getAsignedItemSize, RenderAssignedRow]);

    const content = (<>
        <div style={{ display: 'flex', paddingLeft: '10px' }}>
            {header}
            <div style={{
                width: `${scrollSizeSmall}px`,
                maxWidth: `${scrollSizeSmall}px`,
                minWidth: `${scrollSizeSmall}px`,
                height: '32px',
                backgroundColor: 'lightgrey',
                display: extraScrollVisibility,
            }} />
        </div>
        <div style={{ flexGrow: 1, width: '100%', paddingLeft: '10px' }}>{allOrdersList}</div>
        <div style={{
            backgroundColor: 'red',
            width: '100%',
            height: `${scrollSize}px`,
            overflowX: 'scroll',
            flexShrink: 0,
        }}
            ref={mainScroll}
            onScroll={e => onScrollX(e)}>
            <div className='resp-table-row'>
                <div
                    className='table-body-cell'
                    style={{
                        maxWidth: `${width}px`,
                        width: `${width}px`,
                        minWidth: `${width}px`,
                        boxSizing: 'border-box',
                        backgroundColor: 'black',
                    }} />
            </div>
        </div>
    </>);

    const markAllAsChecked = () => {
        const orderIds = myAvailableOrders?.map(order => order.rowState.orderObjectId);
        if (orderIds.length) {
            setAllIsChecked(true);
            setChecked(resource, orderIds);
            setOrderId(resource, orderIds);
            setChangeRequestAcceptModalIsOpen(true);
        }
    };

    const subHeader = (
        <>
            <ARSubHeader
                podStatus={podStatus}
                setPodStatus={setPodStatus}
                filteredAvailableOrder={filteredAvailableOrder}
                pathname={pathname}
                resource={resource}
                filter={filter}
                endpoint={endpoint}
                load={load}
                availableOrders={availableOrders}
                setFilteredData={setFilteredData}
                isAdmin={isAdmin}
                markAllAsChecked={markAllAsChecked}
                allIsChecked={allIsChecked}
            />
            {uploadModalIsOpen && 
                <UploadModal
                    rowState={uploadRowState}
                    uploadModalIsOpen={uploadModalIsOpen}
                    setUploadModalIsOpen={setUploadModalIsOpen}
                />
            }
            {commentsModalIsOpen && 
                <CommentsModal
                    commentsModalIsOpen={commentsModalIsOpen}
                    setCommentsModalIsOpen={setCommentsModalIsOpen}
                    rowState={uploadRowState}
                />
            }
            {changeRequestModalIsOpen && 
                <ChangeRequestModal
                    changeRequestModalIsOpen={changeRequestModalIsOpen}
                    setChangeRequestModalIsOpen={setChangeRequestModalIsOpen}
                    resource={resource}
                />
            }
            {changeRequestAcceptModalIsOpen && 
                <ChangeRequestAcceptModal
                    changeRequestAcceptModalIsOpen={changeRequestAcceptModalIsOpen}
                    setChangeRequestAcceptModalIsOpen={setChangeRequestAcceptModalIsOpen}
                    resource={resource}
                    setAllIsChecked={setAllIsChecked}
                />
            }            
        </>
    )

    const pageLoading = (pathname === '/ar/changerequestsar' && changeRequestListLoading) || 
                        (pathname === '/ar/openorders' && openOrdersLoading) || 
                        loading;

    return  <>
                {subHeader}
                {pageLoading ? <Loading /> : <>{content}</>}
            </>;
};

const mapStateToProps = state => ({
    state: state,
    availableOrders: state.AccountReceivable.availableOrders,
    loading: state.AccountReceivable.loading,
    haseError: state.AccountReceivable.hasError,
    actionMessage: state.AccountReceivable.message,
    filterType: state.AccountReceivable.filterType,
    userRole: state.auth.userRole,
    changeRequestListLoading: state.AccountReceivable.changeRequestListLoading,
    openOrdersLoading: state.AccountReceivable.openOrdersLoading,
    userId: state.auth.userId,
});

const mapDispatchToProps = (dispatch) => ({
    load: (resource, method, endpoint, loadingType) => dispatch(load(resource, method, endpoint, loadingType)),
    resetError: (resource, method) => dispatch(resetError(resource, false, method)),
    setOrderId: (resource, orderId) => dispatch(setOrderId(resource, orderId)),
    setChecked: (resource, orderId) => dispatch(setChecked(resource, orderId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Spreadsheet);