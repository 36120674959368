import React from 'react';

import Element from '../sectionGroup/section/formField/element/element';
import Scroll from '../../scroll/scroll';

const ScreenToolbar = props => {

    return (<toolbar
        scroll={props.noScroll ? null : 'scroll'}
        none={props.isNone ? '' : null}>
        <Scroll scrollToElementClass={null}>
        <wrap>
            <action right={props.isRight ? '' : null}>
                <wrap>
                    {props.fields && Object.keys(props.fields).map((e, i) => {
                        let field = props.fields[e];
                        let fieldClasses = ['line'];
                        if (field.FieldClasses) {
                            fieldClasses = [...fieldClasses, field.FieldClasses];
                        }
                        return <React.Fragment key={e + i}>
                            <Element
                                messageIdentityPrefix={props.messageIdentityPrefix}
                                {...field}
                                type={e}
                                data={props.data}
                                dispatch={props.dispatch}
                                messages={props.messages}
                                messageDispatch={props.messageDispatch}
                                val={props.data ? props.data[e] : ''}
                                fieldClasses={fieldClasses}
                                customEvents={props.customEvents} />
                            {/* {i < Object.keys(props.fields).length - 1 ? <separator vertical=''></separator> : null} */}
                        </React.Fragment>;
                    })}
                </wrap>
            </action>
            </wrap>
        </Scroll>
    </toolbar>);
};

export default ScreenToolbar;