import { orderBy } from "lodash";

export const createFactoringCompany = (factoringData, headers) => {
  const data = { ...factoringData };
  const orderWebData = {
    row: [],
    rowState: {
      factoringCompanyData: data,
      height: 20,
    },
  };

  for (let headerIndex = 0; headerIndex < headers.length; headerIndex++) {
    const headerItem = headers[headerIndex];
    let value = null;
    let fieldType = 'text';
    switch (headerItem.key) {
      case 'name':
        value = data.companyName;
        fieldType = 'link';
        break;
      case 'terms':
        value = data.terms;
        fieldType = 'text';
        break;
      case 'mailingAddress':
        value = data.mailingAddress;
        fieldType = 'text';
        break;
      case 'additionalAddress':
        value = data.additionalAddress;
        fieldType = 'text';
        break;
      case 'city':
        value = data.city;
        break;
      case 'state':
        value = data.state;
        fieldType = 'text';
        break;
      case 'zipCode':
        fieldType = 'text';
        value = data.zipCode;
        break;
      case 'email':
        value = data.email;
        fieldType = 'text';
        break;
      case 'phone':
        value = data.phone;
        fieldType = 'text';
        break;
      case 'notes':
        value = data.notes;
        break;
      default:
        break;
    }
    orderWebData.row.push({
      key: headerItem.key,
      value,
      fieldType,
    });
  }
  return orderWebData;
};

const createFactoringCompanyList = (state, factoringCompanyList) => factoringCompanyList?.map(data => createFactoringCompany(data, state.headers));

export const getFactoringCompanyList = (state, response, hasError = false, message = '') => {
  const { data } = response;
  const factoringCompanyList = data?.data?.length && createFactoringCompanyList(state, data?.data);
  return {
    ...state,
    ...{
      loading: false,
      hasError: hasError,
      messageText: message,
      factoringCompanyList:  [...orderBy(factoringCompanyList, item => item.rowState.factoringCompanyData.companyName, state.sortValue='asc')],
      total: data.total,
      newFactoryAdded: false,
    },
  };
};

export const getFactoringCompanyDetails = (state, response, hasError = false, message = '') => {
  const { data } = response;
  return {
    ...state,
    ...{
      loading: false,
      hasError: hasError,
      messageText: message,
      factoringCompanyDetails:  data,
      newFactoryAdded: false,
      factoryCompanyUpdated: false,
    },
  };
};

export const addFactoringCompanyDetails = (state, response, hasError = false, message = '') => {
  const { data } = response.response;
  return {
    ...state,
    ...{
      hasError: data.hasError,
      messageText: data.message,
      factoringCompanyDetails:  data,
      newFactoryAdded: true,
      factoryCompanyUpdated: false,
      submitActinType: response.submitActinType,
    },
  };
};

export const updateFactoringCompanyDetails = (state, response) => {
  const { data } = response.response;
  return {
    ...state,
    ...{
      hasError: data.hasError,
      messageText: data.message,
      factoringCompanyDetails:  data,
      submitActinType: response.submitActinType,
      newFactoryAdded: false,
      factoryCompanyUpdated: true,
    },
  };
};

export const getStatesList = (state, response, hasError = false, message = '') => {
  const { data } = response;
  return {
    ...state,
    ...{
      hasError: hasError,
      messageText: message,
      statesList:  data,
    },
  };
};

export const sortBy = (state, sortValue) => ({
  ...state,
  ...{
      factoringCompanyList: [...orderBy(state.factoringCompanyList, item => item.rowState.factoringCompanyData.companyName, sortValue)],
      sortType: sortValue,
  },
});

export const emptyFactoringCompanyDetails = state => ({
  ...state,
  ...{
      loading: false,
      factoringCompanyDetails: '',
      factoryCompanyUpdated: false,
      newFactoryAdded: false,
  },
});

export const setDisplayState = (state, rowState, header, enable) => ({
  ...state,
  ...{
    lastSelected: {
      id: rowState.id,
      headerKey: header.key,
      enable: enable,
    },
  },
});

export const setHeaderWidth = (state, ind, width) => {
  const header = state.headers[ind];
  header.width = width;
  return {
    ...state,
    ...{
      headers: [...state.headers.slice(0, ind), ...[header], ...state.headers.slice(ind + 1)],
    },
  };
};

export const setError = (state, errorMessage) => ({
  ...state,
  ...{
    hasError: true,
    messageText: errorMessage ? errorMessage : 'Something went wrong, please try again',
  },
});

export const resetError = state => ({
  ...state,
  ...{
    hasError: false,
    message: '',
    loading: false,
    factoryCompanyUpdated: false,
    newFactoryAdded: false,
  },
});

export const setInLoading = state => ({
  ...state,
  ...{
    loading: true,
  },
});


