import axios from 'axios';
import moment from 'moment';
import store from '../..';
import * as actionTypes from './callCheckActionTypes';

const buildUrl = (resource, endPoint = '', loadId) => loadId ? `/api/${resource}/${endPoint}?loadId=${loadId}` : `/api/${resource}/${endPoint}`;

const axiosConfig = () => ({
    headers: {
        'Authorization': 'Bearer ' + store.getState().auth.userToken
    }
});

export const load = (resource, loadingType) => dispatch => {
    if (loadingType !== 'withoutLoading') {
        dispatch({ type: actionTypes.getActionType(resource, actionTypes.GET_CALL_CHECK_LOADING) });
    }
    axios.get(buildUrl(resource, 'area'),
        axiosConfig())
        .then(responce => dispatch({ type: actionTypes.getActionType(resource, actionTypes.GET_CALL_CHECK), data: responce }))
        .catch((err) => {
            console.error(err);
            console.log(err.response);
            dispatch({ type: actionTypes.getActionType(resource, actionTypes.CALL_CHECK_ERROR) });
        })
};

export const reactOnAssignedItem = (resource, id, type) =>
    ({ type: actionTypes.getActionType(resource, actionTypes.REACTED_ASSIGNED), data: { id, type } });

export const reactOnAssignedItems = (resource, ids, type) =>
    ({ type: actionTypes.getActionType(resource, actionTypes.REACTED_ASSIGNEDS), data: { ids, type } });

export const setHeaderWidth = (resource, ind, width) =>
    ({ type: actionTypes.getActionType(resource, actionTypes.SET_HEADER_WIDTH_CALL_CHECK), data: { ind, width } });

export const setItemData = (resource, value, rowState, header, hasSubOrder) => dispatch => {
    dispatch({
        type: actionTypes.getActionType(resource, actionTypes.SET_CALL_CHECK_DATA), data: {
            value,
            rowState,
            header,
            hasSubOrder,
        }
    });

    let requestData = {};
    let orderDelayedReasonByCall;
    let orderStatusByCall;
    let orderNotesByCall;
    let loadCallCheckNote;
    let pickInDate;
    let pickOutDate;
    let dropInDate;
    let dropOutDate;
    let deliverDate;
    let puDate;
    let podRequestStatus;
    let callCheckAttachment;
    let inboundDeliveryEstimate;
    let browserDateTime = moment.utc(new Date()).local().utc(true).format();

    const order = rowState.type === 'stop' ? rowState.firstOrderData : rowState.orderData;
    if (rowState.loadData.loadType === 3) {
        orderDelayedReasonByCall = order.outDelayedReason;
        orderStatusByCall = order.outOrderStatusByCall;
        orderNotesByCall = order?.outNotes;
        podRequestStatus = order?.outPodRequestStatus
        pickInDate = order.outboundPickIn;
        deliverDate = order.outDeliverDate;
        puDate = order.outPuDate;
        pickOutDate = order.outboundPickOut;
        dropInDate = order.outboundDropIn;
        dropOutDate = order.outboundDropOut;
        callCheckAttachment = order.callCheckOutAttachment;
        loadCallCheckNote = order.outLoadCallCheckNote;
    } else if (rowState.loadData.loadType === 1) {
        orderDelayedReasonByCall = order.inDelayedReason;
        orderStatusByCall = order.inOrderStatusByCall;
        orderNotesByCall = order.inNotes;
        podRequestStatus = order?.inPodRequestStatus;
        pickInDate = order.inboundPickIn;
        pickOutDate = order.inboundPickOut;
        deliverDate = order.inDeliverDate;
        puDate = order.inPuDate;
        dropInDate = order.inboundDropIn;
        dropOutDate = order.inboundDropOut;
        callCheckAttachment = order.callCheckInAttachment;
        loadCallCheckNote = order.inLoadCallCheckNote;
    }

    requestData = {
        orderId: order.id,
        loadId: rowState.loadData?.id,
        orderStatusByCall,
        orderDelayedReasonByCall,
        orderNotesByCall,
        podRequestStatus,
        loadCallCheckNote,
        callCheckAttachment,
        pickInDate,
        pickOutDate,
        dropInDate,
        dropOutDate,
        deliverDate,
        puDate,
        inboundDeliveryEstimate,
        browserDateTime,
    }

    order[header?.key] = value;

    if (header.key === 'orderStatusByCall') {
        requestData.orderStatusByCall = value;
        requestData.orderDelayedReasonByCall = null;
    } else if (header.key === 'delayedReason') {
        requestData.orderDelayedReasonByCall = value;
    } else if (header.key === 'orderNotesByCall') {
        requestData.orderNotesByCall = value;
    } else if (header.key === 'podRequestStatus') {
        if (rowState.orderData?.subOrderId) {
            requestData.outPodRequestStatus = value;
        }
        if (value === 3 || 
            (rowState.loadData.loadType === 1 && ((value === 0 || value === 2) && (rowState?.orderData?.inPodRequestStatus === 1 || rowState?.orderData?.inPodRequestStatus === 3))) ||
            (rowState.loadData.loadType === 3 && ((value === 0 || value === 2) && (rowState?.orderData?.outPodRequestStatus === 1 || rowState?.orderData?.outPodRequestStatus === 3)))) {
            requestData.callCheckAttachment = null;
            requestData.callCheckOutAttachment = null;
            requestData.callCheckInAttachment = null;
        }
        requestData.podRequestStatus = value;
    } else if (header.key === 'podDoc') {
        requestData.callCheckAttachment = value;
    } else if (header.key === 'pickInDate') {
        requestData.pickInDate = value;
    } else if (header.key === 'pickOutDate') {
        requestData.pickOutDate = value;
    } else if (header.key === 'dropInDate') {
        requestData.dropInDate =  value
    } else if (header.key === 'dropOutDate') {
        requestData.dropOutDate = value;
    } else if (header.key === 'loadCallCheckNote') {
        requestData.loadCallCheckNote = value;
    } else if (header.key === 'deliverDate') {
        requestData.deliverDate = value;
    } else if (header.key === 'puDate') {
        requestData.puDate = value;
    } else if (header.key === 'inboundDeliveryEstimate') {
        requestData.inboundDeliveryEstimate = value;
    }
    
    if (rowState.orderData?.subOrderId || rowState.firstOrderData?.subOrderId) {
        const orderData = rowState.firstOrderData || rowState.orderData;

        let subOrderData = {
            ...requestData,
            subOrderId: orderData.subOrderId,
            orderId: order.orderId,
            id: orderData.subOrderId,
            pickAppointmentDate: resource === 'load' ? orderData.inboundPickAppointment : orderData.outboundPickAppointment,
            pickAppointmentStatus: resource === 'load' ? orderData.inboundAppointmentStatus : orderData.pickOutboundAppointmentStatus,
            dropAppointmentDate: resource === 'load' ? orderData.inboundDropAppointment : orderData.outboundDropAppointment,
            dropAppointmentStatus: resource === 'load' ? orderData.inboundDropAppointmentStatus : orderData.outboundAppointmentStatus,
            pickWeek: resource === 'load' ? orderData.pickWeek : orderData.pickWeek,
            dueDate: orderData.dueDate,
            puDate: orderData.puDate,
            deliverDate: orderData.deliverDate,
            lumper: resource === 'load' ? orderData.inboundLumper : orderData.outboundLumper,
            dropRestriction: resource === 'load' ? orderData.pickRestriction : orderData.dropRestriction,
            palletCount: orderData.palletCount,
            cases: orderData.cases,
            space: orderData.space,
            weight: orderData.weight,
            poNumber: orderData.poNumber,
            stepCount: orderData.stepCount ? orderData.stepCount : 1,
        };

        let url = buildUrl('suborder');
        if (header?.key === 'podDoc'  ||
            header?.key === 'podRequestStatus' ||
            header?.key === 'orderStatusByCall' ||
            header?.key === 'orderNotesByCall' ||
            header?.key === 'puDate' ||
            header?.key === 'delayedReason') {
            url = buildUrl('CallCheck', 'loadorderitem');
            subOrderData = {
                ...subOrderData,
                subOrderId: orderData.subOrderId,
            }
        }

        axios.put(url, subOrderData,
            axiosConfig())
            .then(response => {
                if (response.data.hasError) {
                    dispatch(errorAction(resource, true, response.data.message));
                    console.error(response.data.message);
                } else if ((response.data.callCheckInAttachment || response.data.callCheckOutAttachment) && header.key === 'podDoc') {
                    dispatch({ type: actionTypes.getActionType(resource, actionTypes.SET_CALL_CHECK_DATA), data: {
                            value:  rowState.loadData.loadType === 1 ? response.data.callCheckInAttachment : 
                                    rowState.loadData.loadType === 3 ? response.data.callCheckOutAttachment : '',
                            rowState,
                            header
                        }
                    })
                }
            }).catch(err => {
                dispatch(errorAction(resource, true));
                console.error(err);
                console.log(err.response);
            });
    } else {
        axios.put(buildUrl('CallCheck', 'loadorderitem'), requestData,
        axiosConfig())
        .then(response => {
            if (response.data.hasError) {
                dispatch(errorAction(resource, true, response.data.message));
                console.error(response.data.message);
            } else if ((response.data.callCheckInAttachment || response.data.callCheckOutAttachment) && header.key === 'podDoc') {
                dispatch({ type: actionTypes.getActionType(resource, actionTypes.SET_CALL_CHECK_DATA), data: {
                        value:  rowState.loadData.loadType === 1 ? response.data.callCheckInAttachment : 
                                rowState.loadData.loadType === 3 ? response.data.callCheckOutAttachment : '',
                        rowState,
                        header
                    }
                })
                dispatch(reloadAfterUpdate(resource));
            } else if (header.key === 'orderNotesByCall' || header.key === 'podRequestStatus' || header.key === 'podDoc') {
                // dispatch(reloadAfterUpdate(resource))
            }
        }).catch(err => {
            dispatch(errorAction(resource, true));
            console.error(err);
            console.log(err.response);
        });
    }
};

export const setItemDisplayState = (resource, rowState, header, enable) => ({
    type: actionTypes.getActionType(resource, actionTypes.SET_DISPLAY_STATE), data: {
        rowState,
        header,
        enable
    }
});

export const filterCallChecks = (resource, key) => ({
    type: actionTypes.getActionType(resource, actionTypes.FILTER_CALL_CHECKS),
    data: key
});

export const filterByPODStatus = (resource, podStatus) => ({
    type: actionTypes.getActionType(resource, actionTypes.FILTER_BY_POD_STATUS),
    data: podStatus,
});

export const sendToDelivered = (resource, loadId) => dispatch => {
    axios.put(buildUrl(resource, 'loadordelivery', loadId), loadId,
        axiosConfig())
        .then(response => {
            if (response.data.hasError) {
                dispatch(errorAction(resource, true, response.data.message));
                console.error(response.data.message);
            } else {
                dispatch(reloadAfterUpdate(resource))
            }
        }).catch(err => {
            dispatch(errorAction(resource, true));
            console.error(err);
            console.log(err.response);
        });
};

export const getHistoryListCallCheck = (resource, url, orderId, field, isSub) => dispatch => {
    dispatch({ type: actionTypes.getActionType(resource, actionTypes.GET_HISTORY_CALL_CHECK_LOADING) });
    const data = {
        params: {
            filterData: isSub ? {
                SubOrderId: orderId,
                field: field,
            } : 
            {
                orderId: orderId,
                field: field,
            },
        },
        headers: axiosConfig().headers,
    };
    axios.get(buildUrl(url), data, axiosConfig())
        .then(response => dispatch({ type: actionTypes.getActionType(resource, actionTypes.GET_HISTORY_CALL_CHECK), data: response }))
        .catch((err) => {
            console.error(err);
            console.log(err.response);
            dispatch({ type: actionTypes.getActionType(resource, actionTypes.CALL_CHECK_ERROR) });
        });
};

export const resetError = resource =>
    ({ type: actionTypes.getActionType(resource, actionTypes.RESET_ERROR) });

const errorAction = (resource, reload, errorText) => dispatch => {
    if (reload) {
        axios.get(buildUrl(resource, 'area'),
            axiosConfig()
        )
            .then(responce => dispatch({ type: actionTypes.getActionType(resource, actionTypes.RELOAD_ON_ERROR_CALL_CHECK), data: {
                responce, 
                errorText,
            }}))
            .catch((err) => {
                console.error(err);
                console.log(err.response);
                dispatch({ type: actionTypes.getActionType(resource, actionTypes.CALL_CHECK_ERROR) });
            });
    }
};

const reloadAfterUpdate = (resource) => dispatch => {
    axios.get(buildUrl(resource, 'area'),
        axiosConfig()
    )
        .then(responce => dispatch({ type: actionTypes.getActionType(resource, actionTypes.GET_CALL_CHECK), data: responce }))
        .catch((err) => {
            console.error(err);
            console.log(err.response);
            dispatch({ type: actionTypes.getActionType(resource, actionTypes.CALL_CHECK_ERROR) });
        });
};
