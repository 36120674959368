import { NOTES_BUILD as actionTypes } from '../../actions/notes/notesActionTypes';
import * as loadArea from './notesReducer';

const defaultState = {
    loading: true,
    hasError: false,
    message: '',
    notes: null,
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case actionTypes.NOTES_LOADING:
            return loadArea.setInLoading(state);
        case actionTypes.GET_NOTES:
            return loadArea.getNotes(state, action.data);
        case actionTypes.RELOAD_ON_ERROR_NOTES:
            return loadArea.getNotes(state, action.data.responce, true, action.data.errorText ? action.data.errorText : 'Something go wrong, data reloaded!');
        case actionTypes.NOTES_ERROR:
            return loadArea.setError(state, action.data);
        case actionTypes.RESET_ERROR:
            return loadArea.resetError(state);
        case actionTypes.SET_NOTES:
            return loadArea.setNotes(state, action.data.value, action.data.rowState, action.data.header);
        default:
            return state;
    }
};