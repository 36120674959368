import { CUSTOMER_DASHBOARD_BUILD as actionTypes } from '../../actions/customerDashboard/customerDashboardActionTypes';
import * as customerDashboard from './customerDashboardReducers';

const defaultState = {
  hasError: false,
  message: '',
  loading: true,
  customerDashboard: null,
};

export default (state = defaultState, action) => {
  switch (action.type) {    
    case actionTypes.CUSTOMER_DASHBOARD_LOADING:
      return customerDashboard.setInLoading(state);
    case actionTypes.RESET_ERROR:
      return customerDashboard.resetError(state);
    case actionTypes.SET_CUSTOMER_DASHBOARD_DATA:
      return customerDashboard.setCustomerDashboard(state, action.data);
      default:
      return state;
  }
};
