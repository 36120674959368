import React from 'react';

import Element from './formField/element/element';

const Group = props => {
    const fieldGroupClasses = `field_group ${props.fieldGroupClasses || ''}`;
    
    return <React.Fragment>
        {props.useSeparator !== false && <div className="form_fields">
            <div className="section_title">
                <text>
                    {props.title ? props.title : ''}
                    {props.unitTitle ? <>(<b>{props.unitTitle}</b>)</> : ''}
                </text>
            </div>
            <div className="form_separator">
            </div>
        </div>}
        <div className={fieldGroupClasses}>
            {Object.keys(props.fields).map((e, i) => {
                return <Element
                messageIdentityPrefix={props.messageIdentityPrefix}
                key={e + i}
                {...props.fields[e]}
                type={e}
                dispatch={props.dispatch}
                messages={props.messages}
                messageDispatch={props.messageDispatch}
                data={props.data}
                val={props.data ? props.data[e] : ''}
                clicked={props.clicked} />;
            })}
        </div>
    </React.Fragment>
};

export default Group;