import React, { useState, useEffect } from 'react';
import { message } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';

import Uploader from './uploader';
import ThumbnailImage from './thumbnailImage';
import AuthenticatedLink from './authenticatedLink';
import { ApiUrl } from '../../project/Pages';
import { CssErrorClassHandling } from '../helper/element';
import { ValidationErrorClass } from '../helper/validation';

const AvatarUploader = props => {
    const [loading, setLoading] = useState(false);
    const [guid, setGuid] = useState('');
    const [originalName, setOriginalName] = useState('');
    const noImage = props.noImage;

    useEffect(() => {
        setGuid(props.guid);
        setOriginalName(props.originalName);
    }, [props.guid, props.originalName]);

    const restriction = file => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'application/pdf';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG/PDF file!');
            return false;
        }
        const isLt = file.size / 1024 / 1024 < props.max;
        if (!isLt) {
            message.error(`Image must be smaller than ${props.max}MB!`);
            return false;
        }
        return true;
    };

    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

    const onDelete = () => {
        setGuid(null);
        setOriginalName('');
        action(null);
    };

    const action = result => {
        const outeSource = [];
        if (result) {
            outeSource.push({
                value: result.guId,
                originalName: result.originalName
            });
        }
        else {
            outeSource.push({
                value: '',
                originalName: ''
            });
        }
        props.action && typeof props.action === 'function' && props.action(
            {
                nativeEvent: null,
                source: outeSource,
                target: {
                    value: result ? result.guId : ''
                }
            });
    };

    return (
        <div uploader='' className={CssErrorClassHandling(ValidationErrorClass, props.classes, props.validationMessages).join(' ')}>
            <Uploader
                name='avatar'
                listType='picture-card'
                className='avatar-uploader'
                setLoading={setLoading}
                setGuid={setGuid}
                setOriginalName={setOriginalName}
                restrictions={noImage ? null : restriction}
                onChange={action}
                disabled={!props.condition}>
                {guid ? <ThumbnailImage guid={guid} originalName={originalName} width={70} height={70} /> : uploadButton}
            </Uploader>
            {guid
                ? <action>
                    <div
                        onClick={onDelete}>
                        <icon>delete</icon>
                    </div>
                    <AuthenticatedLink url={`${ApiUrl.Download}/${guid}`} filename={originalName}>
                        <icon>download</icon>
                    </AuthenticatedLink>
                </action>
                : null}
        </div>
    );
};

export default AvatarUploader;