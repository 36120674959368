import axios from 'axios';
import store from '../..';
import * as actionTypes from './rateConfirmationActionTypes';

const buildUrl = (resource, id) => `/api/${resource}/${id}`;
const buildUrlPDF = (resource, id) => `/api/${resource}/download/${id}`;
const buildUrlPDFSend = (resource, sendPDF) => sendPDF ? `/api/${resource}/sendrateconfirmation` : `/api/${resource}`;
const buildUrlCarrierRate = (resource, carrierId) => `/api/${resource}`;

const axiosConfig = () => ({
    headers: {
        'Authorization': 'Bearer ' + store.getState().auth.userToken
    }
});

export const getRateConfirmationData = (resource, loadId) => dispatch => {
    dispatch({ type: actionTypes.getActionType(resource, actionTypes.GET_RATE_CONFIRMATION_LOADING) });
    axios.get(buildUrl(resource, loadId),
        axiosConfig())
        .then(responce => dispatch({ type: actionTypes.getActionType(resource, actionTypes.GET_RATE_CONFIRMATION_DATA), data: responce }))
        .catch((err) => {
            console.error(err);
            console.log(err.response);
            dispatch({ type: actionTypes.getActionType(resource, actionTypes.GET_RATE_CONFIRMATION_ERROR) });
        })
};

export const sendRateConfirmationData = (resource, data, sendPDF = false) => dispatch => {
    dispatch({ type: actionTypes.getActionType(resource, actionTypes.GET_RATE_CONFIRMATION_LOADING) });
    axios.put(buildUrlPDFSend(resource, sendPDF), data,
        axiosConfig())
        .then(responce => dispatch({ type: actionTypes.getActionType(resource, actionTypes.GET_RATE_CONFIRMATION_DATA), data: responce }))
        .catch((err) => {
            console.error(err);
            console.log(err.response);
            dispatch({ type: actionTypes.getActionType(resource, actionTypes.GET_RATE_CONFIRMATION_ERROR) });
        })
};

export const getCarierRateConfirmation = (resource, carrierId) => dispatch => {
    const data  = {
        params: {
            request: {
                Page: 1,
                PageSize: 100,
                Sorts: null
            },
            filterData: { carrierId: carrierId},
        },
        headers: axiosConfig().headers,
    };
    // dispatch({ type: actionTypes.getActionType(resource, actionTypes.GET_CARRIER_RATE_CONFIRMATION_LOADING) });
    axios.get(buildUrlCarrierRate(resource, carrierId), data,
        axiosConfig())
        .then(responce => dispatch({ type: actionTypes.getActionType(resource, actionTypes.GET_CARRIER_RATE_CONFIRMATION_DATA), data: responce }))
        .catch((err) => {
            console.error(err);
            console.log(err.response);
            dispatch({ type: actionTypes.getActionType(resource, actionTypes.GET_CARRIER_RATE_CONFIRMATION_ERROR) });
        })
};

export const getRateConfirmationDataPDF = (resource, loadId) => dispatch => {
    dispatch({ type: actionTypes.getActionType(resource, actionTypes.GET_RATE_CONFIRMATION_LOADING) });
    axios.get(buildUrlPDF(resource, loadId), { 
            headers: { 'Authorization': 'Bearer ' + store.getState().auth.userToken },
            responseType: 'blob' })
        .then(response => {
                //Create a Blob from the PDF Stream
                console.log(response)
                const file = new Blob(
                    [response.data], 
                    {type: 'application/pdf'}
                );
                //Build a URL from the file
                const fileURL = URL.createObjectURL(file);
                //Open the URL on new Window
                dispatch({ type: actionTypes.getActionType(resource, actionTypes.GET_RATE_CONFIRMATION_PDF_URL), data: fileURL });
                window.open(fileURL);
        })                    
        .catch((err) => {
            console.error(err);
            console.log(err.response);
            dispatch({ type: actionTypes.getActionType(resource, actionTypes.GET_RATE_CONFIRMATION_ERROR) });
        })
};

export const resetError = resource =>
    ({ type: actionTypes.getActionType(resource, actionTypes.RESET_ERROR) });

// const errorAction = (resource, reload, errorText) => dispatch => {
//     if (reload) {
//         axios.get(buildUrl(resource),
//             axiosConfig()
//         )
//             .then(responce => dispatch({ type: actionTypes.getActionType(resource, actionTypes.RELOAD_ON_ERROR_LOAD_DETAILS), data: {
//                 responce, 
//                 errorText,
//             }}))
//             .catch((err) => {
//                 console.error(err);
//                 console.log(err.response);
//                 dispatch({ type: actionTypes.getActionType(resource, actionTypes.LOAD_DETAILS_ERROR) });
//             });
//     }
// };

// const reloadAfterUpdate = (resource, loadId) => dispatch => {
//     axios.get(buildUrl(resource, loadId),
//         axiosConfig()
//     )
//         .then(responce => dispatch({ type: actionTypes.getActionType(resource, actionTypes.GET_LOAD_DETAILS), data: responce }))
//         .catch((err) => {
//             console.error(err);
//             console.log(err.response);
//             dispatch({ type: actionTypes.getActionType(resource, actionTypes.LOAD_DETAILS_ERROR) });
//         });
// };
