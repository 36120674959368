export const getLoadDetails = (state, response, hasError = false, message = '') => {
    const data = response.data || [];
    return {
        ...state, ...{
            loadDetails: data,
            loading: false,
            hasError,
            message,
        }    
    }
};

export const setLoadDetails = (state, loadDetails) => {
    return {
        ...state, ...{
            loadDetails: loadDetails,
        }    
    }
};

export const setError = (state, err) => ({
    ...state,
    ...{
        hasError: true,
        message: 'Something went wrong, please try again',
    },
});

export const resetError = state => ({
    ...state,
    ...{
        hasError: false,
        message: '',
    }
});

export const clearLoadDetailsData = state => ({
    ...state,
    ...{
        loadDetails: null,
    }
});

export const setInLoading = state => ({
    ...state, ...{
        loading: true
    }
});

export const getCarrierList = (state, response, hasError = false, message = '') => {
    const data = response.data || [];
    return {
        ...state, ...{
            carrierList: data?.filter(carrier => !carrier.inactive),
            loading: false,
            hasError,
            message,
        }    
    }
};

export const getTrucksList = (state, response, hasError = false, message = '') => {
    const data = response.data || [];
    return {
        ...state, ...{
            trucksList: data?.filter(truck => !truck.inactive),
            loading: false,
            hasError,
            message,
        }    
    }
};

export const getTrailersList = (state, response, hasError = false, message = '') => {
    const data = response.data || [];
    return {
        ...state, ...{
            trailersList: data?.filter(trailer => !trailer.inactive),
            loading: false,
            hasError,
            message,
        }    
    }
};

export const getDriversList = (state, response, hasError = false, message = '') => {
    const data = response.data || [];
    return {
        ...state, ...{
            driversList: data?.filter(driver => !driver.inactive),
            loading: false,
            hasError,
            message,
        }    
    }
};

export const getEmptyEFS = (state, response, hasError = false, message = '') => {
    const data = response.data || [];
    return {
        ...state, ...{
            EFS: data,
            EFSLoading: false,
            hasError: hasError || data.hasError,
            message: message || data.message,
        }    
    }
};

export const getEFS = (state, response, hasError = false, message = '') => {
    const data = response.data || [];
    return {
        ...state, ...{
            EFS: data,
            EFSLoading: false,
            hasError: hasError || data.hasError,
            message: message || data.message,
        }    
    }
};

export const getEFSList = (state, response, hasError = false, message = '') => {
    const data = response.data || [];
    return {
        ...state, ...{
            EFSList: data,
            EFSLoading: false,
            hasError: hasError || data.hasError,
            message: message || data.message,
        }    
    }
};

export const setEFSLoading = state => ({
    ...state, ...{
        EFSLoading: true
    }
});

export const setEFS = (state, response, hasError = false, message = '') => {
    const data = response.data || [];
    return {
        ...state, ...{
            EFS: data,
            EFSLoading: false,
            hasError: hasError || data.hasError,
            message: message || data.message,
        }    
    }
};

export const updateEFS = (state, response, hasError = false, message = '') => {
    const data = response.data || [];
    return {
        ...state, ...{
            EFS: data,
            EFSLoading: false,
            hasError: hasError || data.hasError,
            message: message || data.message,
        }    
    }
};