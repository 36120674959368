import React from "react";

export default (data) => (
  <table className="auto_width border">
    <tbody>
      <tr>
        <th>
          <text>Dispatcher:</text>
        </th>
        <td>
          <wrap left="">
            <text>{data.dispatcher}</text>
          </wrap>
        </td>
        <th>
          <text>LOAD #</text>
        </th>
        <td>
          <wrap left="">
            <text>{data.loadNumber}</text>
          </wrap>
        </td>
      </tr>
      <tr>
        <th>
          <text>Phone #: </text>
        </th>
        <td>
          <wrap left="">
            <text>{data.phone}</text>
          </wrap>
        </td>
        <th>
          <text>Ship Date</text>
        </th>
        <td>
          <wrap left="">
            <text>{data.pickUpDate}</text>
          </wrap>
        </td>
      </tr>
      <tr>
        <th>
          <text>Fax #:</text>
        </th>
        <td>
          <wrap left="">
            <text>{data.fax}</text>
          </wrap>
        </td>
        <th>
          <text>Today's Date:</text>
        </th>
        <td>
          <wrap left="">
            <text>{data.currentDate}</text>
          </wrap>
        </td>
      </tr>
      <tr>
        <th>
          <text>Email:</text>
        </th>
        <td colSpan="3">
          <wrap left="">
            <text>
              <a href="mailto:dispatch@truestartransit.com">{data.email}</a>
            </text>
          </wrap>
        </td>
      </tr>
    </tbody>
  </table>
)