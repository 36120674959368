import React from 'react';

import { CommonLabels } from '../../../../project/Defines';

const FilterContainer = props => (
    <view className="filter_container">
        <div className="filter_content">
            <wrap>
                {props.children}
            </wrap>
            <toolbar>
                <action>
                    <a className="button link right" href="/" disabled="" onClick={(e) => { e.preventDefault(); props.onClearAll(); } }>
                        <text>
                            {CommonLabels.Buttons.ClearAll}
                        </text>
                    </a>
                </action>
                <action right="">
                    <button command="Apply" className="button action" type="button" onClick={props.onApply}>
                        <text>
                            {CommonLabels.Buttons.Apply}
                        </text>
                    </button>
                </action>
            </toolbar>
        </div>
    </view>
    );

export default FilterContainer;