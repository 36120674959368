import {
    ElementTypes,
    ElementValidations,
    GenerateToolBars
} from '../../../config';

import { InboundAppointmentFields } from '../appointmentFieldsConfig';

const Dispatch = {
    Title: 'Dispatch',
    //Resource: 'InboundAppointment/stopdispatch',
    Key: 'id',
    IsRest: true,
    Form: {
        Title: 'Dispatch',
        SectionGroups: [
            {
                Sections: [
                    {
                        classes: 'wide',
                        Fields: {
                            ...InboundAppointmentFields,
                            Group3: [
                                {
                                    useSeparator: false,
                                    Fields: {
                                        inboundPickAppointment: {
                                            Title: 'Appt',
                                            Field: ElementTypes.LabelField()
                                        }
                                    }
                                }
                            ],
                            Group4: [
                                {
                                    useSeparator: false,
                                    Fields: {
                                        inboundPickIn: {
                                            Title: 'In Time',
                                            Field: ElementTypes.TextField(),
                                            Validations: [ElementValidations.Time()]
                                        }
                                    }
                                }
                            ],
                            Group5: [
                                {
                                    useSeparator: false,
                                    Fields: {
                                        outTime: {
                                            Title: 'Out Time',
                                            Field: ElementTypes.TextField(),
                                            Validations: [ElementValidations.Time()]
                                        }
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        ],
        ToolBars: GenerateToolBars(['Save', 'Cancel'])
    }
};

export default Dispatch;