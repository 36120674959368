import React from 'react';

const ActionBar = props => {

    const onYesButtonClick = event => {
        props.yesCallback && props.yesCallback(event);
        props.onClose();
    };

    const onNoButtonClick = event => {
        props.noCallback && props.noCallback(event);
        props.onClose();
    };

    return (<panel>
        <button type="button" effect="material" command="yes" className="button primary" onClick={onYesButtonClick}>
            <text>{props.buttonYesText}</text>
        </button>
        <separator vertical=""></separator>
        <button type="button" effect="material" command="no" className="button accent" onClick={onNoButtonClick}>
            <text>{props.buttonNoText}</text>
        </button>
    </panel>);
};

ActionBar.defaultProps = {
    buttonYesText: "OK",
    buttonNoText: "Cancel"
};

export const ActionBarAlert = props => {
    const onYesButtonClick = event => {
        props.yesCallback && props.yesCallback(event);
        props.onClose();
    };

    return (<panel>
        <button type="button" effect="material" command="yes" className="button primary" onClick={onYesButtonClick}>
            <text>{props.buttonYesText}</text>
        </button>
    </panel>);
}

export default ActionBar;