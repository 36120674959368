export const useLoadSequence = setStops => {
    const updateInboundLoadSequence = (newStops, result) => {
        const changedStop = newStops.find(s => s.id === result.id);
        if (changedStop) {
            changedStop.sequencesName = result.inboundLoadingSequenceName;

            const newOrders = [...changedStop.orders];
            newOrders.forEach(o => {
                o.inboundLoadingSequenceName = result.inboundLoadingSequenceName;
            });
            changedStop.orders = newOrders;

            setStops(newStops);
        }
        else {
            console.error(`Stop not found, id - ${result.id}`)
        }
    };

    const updateOutboundLoadSequence = (newStops, result) => {
        const changedStop = newStops.find(s => s.id === result.id);
        if (changedStop) {
            changedStop.sequencesName = result.outboundLoadingSequenceName;

            const newOrders = [...changedStop.orders];
            newOrders.forEach(o => {
                o.outboundLoadingSequenceName = result.outboundLoadingSequenceName;
            });
            changedStop.orders = newOrders;

            setStops(newStops);
        }
        else {
            console.error(`Stop not found, id - ${result.id}`)
        }
    };
    return [updateInboundLoadSequence, updateOutboundLoadSequence];
};