import React, { useState, useEffect, useRef } from 'react';
import { connect } from "react-redux";
import ResizeObserver from 'rc-resize-observer';

import { setProfileColumnWidth, saveProfileColumnWidth } from '../../../../store/actions/authentication/authentication';
import SheetHeader from './sheetHeader';
import SheetBody from './sheetBody';
import SheetFooter from './sheetFooter';
import { sorColumnsByIndex } from '../../../helper/list'

const SheetTableContent = props => {
    const [resizeMode, setResizeMode] = useState(false);
    const profileColumns = props.profileSettings?.screens?.[props.resource]?.list?.columns;
    let columns = {};

    const isMounted = useRef(true);

    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);
    let i = 0;
    for (const cI in props.columns) {
        const c = props.columns[cI];
        if (!c) {
            continue;
        }
        if (profileColumns && profileColumns[cI] && profileColumns[cI].hidden) {
            continue;
        }
        else {
            columns[cI] = c;
            if (profileColumns && profileColumns[cI] && (profileColumns[cI].index || profileColumns[cI].index === 0)) {
                columns[cI].index = profileColumns[cI].index;
            }
            else {
                columns[cI].index = i;
            }
        }
        i++;
    }

    columns = sorColumnsByIndex(columns);

    const [tableWidth, setTableWidth] = useState(0);
    const onChangeHandler = event => {
        props.onChange && props.onChange(event);
    };

    const onResize = ({ size }, field, e) => {
        const selectedColumns = [];
        selectedColumns.push({
            name: field,
            width: size.width
        });
        props.setColumnWidth(props.resource, selectedColumns);
    };

    const onResizeStop = ({ size }, field) => {
        const selectedColumns = [];
        selectedColumns.push({
            name: field,
            width: Math.floor(size.width)
        });
        props.saveColumnWidth(props.resource, selectedColumns);
        if ('AvailableOrders' === props.resource) {
            props.saveColumnWidth('AssignedOrders', selectedColumns);
        }
        setResizeMode(false);
    };

    const onResizeStart = () => {
        setResizeMode(true);
    };

    return <>
        <SheetHeader
            columns={columns}
            tableWidth={tableWidth}
            action={props.action}
            draggable={props.draggable}
            selectable={props.selectable}
            colgroup={props.colgroup}
            header={props.header}
            resource={props.resource}
            resizable={props.resizable}
            onResize={onResize}
            onResizeStop={onResizeStop}
            onResizeStart={onResizeStart}
            addIndex={props.addIndex}
            onSort={props.onSort}
            sort={props.sort}
        />
        <ResizeObserver
            onResize={(e) => {
                if (isMounted.current) {
                    setTableWidth(e.width);
                }
            }}
        >
            <SheetBody onActionRecord={props.onActionRecord}
                tableWidth={tableWidth}
                action={props.action}
                actions={props.actions}
                messageIdentityPrefix={props.messageIdentityPrefix}
                keyFieldName={props.keyFieldName}
                columns={columns}
                data={props.data}
                parentData={props.parentData}
                onChange={onChangeHandler}
                messageDispatch={props.messageDispatch}
                messages={props.messages}
                minRowsCanDelete={props.minRowsCanDelete}
                draggable={props.draggable}
                providedPlaceholder={props.providedPlaceholder}
                handleCheck={props.handleCheck}
                selectable={props.selectable}
                selectedRows={props.selectedRows}
                colgroup={props.colgroup}
                setSelectedRows={props.setSelectedRows}
                resizable={props.resizable}
                resizeMode={resizeMode}
                resource={props.resource}
                disableDrag={props.disableDrag}
                coloring={props.coloring}
                grouping={props.grouping}
                groupBy={props.groupBy}
                groupType={props.groupType}
                groupTypeValue={props.groupTypeValue}
                subGroup={props.subGroup}
                addIndex={props.addIndex}
            />
        </ResizeObserver>
        {props.hasFooter && <SheetFooter columns={columns} action={props.action} />}
    </>;
};

const mapStateToProps = state => {
    return {
        profileSettings: state.auth.profileSettings
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setColumnWidth: (resource, columns) => dispatch(setProfileColumnWidth(resource, columns)),
        saveColumnWidth: (resource, columns) => dispatch(saveProfileColumnWidth(resource, columns)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SheetTableContent);