import { LOCATIONS_BUILD as actionTypes } from '../../actions/locations/locationsActionTypes';
import * as locations from './locationsReducer';

const defaultState = {
    loading: true,
    hasError: false,
    message: '',
    locations: null,
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case actionTypes.LOCATIONS_LOADING:
            return locations.setInLoading(state);
        case actionTypes.GET_LOCATIONS:
            return locations.getLocations(state, action.data);
        case actionTypes.LOCATIONS_ERROR:
            return locations.setError(state, action.data);
        default:
            return state;
    }
};