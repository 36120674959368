export const GET_LOCATIONS = 'GET_LOCATIONS';
export const LOCATIONS_LOADING = 'LOCATIONS_LOADING';
export const LOCATIONS_ERROR = 'LOCATIONS_ERROR';

export const getActionType = (resource, key) => {
    return LOCATIONS_BUILD[key];
};

export const LOCATIONS_BUILD = {
    GET_LOCATIONS: 'GET_LOCATIONS',
    LOCATIONS_LOADING: 'LOCATIONS_LOADING',
    LOCATIONS_ERROR: 'LOCATIONS_ERROR',
};
