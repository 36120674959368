import React, { useState, useEffect } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import axios from '../axios/axios';
import errorHandler from '../error/errorHandler';
import { ApiUrl } from '../../project/Pages';

const ThumbnailImage = props => {
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState(null);
    const { guid, width, height, originalName } = props;

    useEffect(() => {
        let isMounted = true;
        const fetchData = async () => {
            if (!guid) {
                return;
            }
            if (isMounted) {
                setLoading(true);
            }
            const response = await axios.get(`${ApiUrl.Thumbnail}/${width}/${height}/${guid}`, {
                responseType: 'blob',
                timeout: 30000,
            }).finally(() => {
                if (isMounted) {
                    setLoading(false);
                }
            });
            if (isMounted) {
                const reader = new FileReader();
                reader.addEventListener("load", function () {
                    setImageUrl(reader.result);
                }, false);
                if (response) {
                    reader.readAsDataURL(new File([response.data], originalName));
                }
            }
        };
        fetchData();
        return () => { isMounted = false };
    }, [width, height, guid, originalName]);

    if (loading)
    {
        return <LoadingOutlined />;
    }
    if (imageUrl) {
        return <img src={imageUrl} alt={originalName} width={width} height={height} />
    }
    return null;
};

export default errorHandler(ThumbnailImage, axios);