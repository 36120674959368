import { CUSTOMER_ORDERS_BUILD as actionTypes } from '../../actions/customerLastOrders/customerLastOrdersActionTypes';
import * as customerOrder from './customerLastOrdersReducers';

const defaultState = {
  headers: [
    {
      key: 'readyDate',
      title: 'READY DATE',
      width: 80,
    },
    {
      key: 'pueDate',
      title: 'PU DATE',
      width: 80,
    },
    {
      key: 'dueDate',
      title: 'DUE DATE',
      width: 80,
    },
    {
      key: 'deliveryDate',
      title: 'DELIVERY DATE',
      width: 90,
    },
    {
      key: 'pickLocation',
      title: 'PICK LOCATION',
      width: 100,
    },
    {
      key: 'deliverWH',
      title: 'DELIVERY WH',
      width: 100,
    },
    {
      key: 'deliverCity',
      title: 'DELIVERY CITY',
      width: 90,
    },
    {
      key: 'deliverState',
      title: 'DELIVERY STATE',
      width: 100,
    },
    {
      key: 'temperature',
      title: 'TEMP',
      width: 60,
    },
    // {
    //   key: 'freight',
    //   title: 'FRIEGHT VALUE',
    //   width: 60,
    // },
    {
      key: 'poNumber',
      title: 'PO#',
      width: 60,
    },
    {
      key: 'soNumber',
      title: 'SO#',
      width: 60,
    },
    {
      key: 'palletCount',
      title: 'PAL',
      width: 40,
    },
    {
      key: 'spa',
      title: 'SPA',
      width: 40,
    },
    {
      key: 'lbs',
      title: 'LBS',
      width: 50,
    },
    {
      key: 'price',
      title: 'PRICE',
      width: 50,
    },
    {
      key: 'notes',
      title: 'NOTES',
      width: 60,
    },
    {
      key: 'podStatus',
      title: 'POD STATUS',
      width: 70,
    },
    {
      key: 'orderCycle',
      title: 'ORDER CYCLE',
      width: 70,
    },
  ],
  loading: false,
  hasError: false,
  message: '',
  total: 0,
  existing: 0,
  customerOrder: null,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.CUSTOMER_LAST_ORDERS_LOADING:
      return customerOrder.setInLoading(state);
    case actionTypes.SET_HEADER_WIDTH_LAST_ORDERS:
      return customerOrder.setHeaderWidth(state, action.data.ind, action.data.width);
    case actionTypes.RESET_ERROR:
      return customerOrder.resetError(state);
    case actionTypes.RELOAD_ON_ERROR:
      return customerOrder.loadContent(state, action.data, true, 'Something go wrong, data reloaded!');
    case actionTypes.GET_CUSTOMER_LAST_ORDERS:
      return customerOrder.customerLastOrders(state, action.data);
    case actionTypes.SORT_LAST_ORDERS:
      return customerOrder.sortBy(state, action.data);
    default:
      return state;
  }
};
