import React from 'react';

import Element from './formField/element/element';
import Group from './group';
import List from '../../../list/list';

const Section = props => {
    const classes = props.classes || '';

    return <React.Fragment>
        {props.title && <h1>{props.title}</h1>}
        <div className={`sections ${classes} ${props.extra ? 'extra' : ''}`}>
            {props.fields && Object.keys(props.fields).map((e, i) => {
                let field = props.fields[e];
                if (Array.isArray(field)) {
                    field = field[0];
                    return field ? <Group
                        messageIdentityPrefix={props.messageIdentityPrefix}
                        key={e + i}
                        title={field.Title}
                        unitTitle={field.UnitTitle}
                        useSeparator={field.useSeparator}
                        fields={field.Fields}
                        type={e}
                        dispatch={props.dispatch}
                        messages={props.messages}
                        messageDispatch={props.messageDispatch}
                        fieldGroupClasses={field.classes}
                        data={props.data}
                        val={props.data ? props.data[e] : ''}
                        clicked={props.clicked} /> : null;
                }
                if (e === 'Grids') {
                    return <div className='section-table' key={e + i} style={{ height: '200px' }}>
                        <List screen={{ ...field, ParentValue: props.data.id }} columnConfig={field.List.Columns} />
                    </div>;
                }

                if(e === 'Components') {
                    return field
                        && props.data
                        && Object.keys(field).map((c, i) =>
                            <React.Fragment key={c + i}>
                                {field[c].Component(
                                    c,
                                    props.data.hasOwnProperty(c) ? props.data[c] : props.data,
                                    props.dispatch,
                                    props.messages,
                                    props.messageDispatch,
                                    props.componentRefs,
                                    props.messageIdentityPrefix,
                                    props.data,
                                    props.customEvents)}
                            </React.Fragment>);
                }
                
                return <Element
                    messageIdentityPrefix={props.messageIdentityPrefix} key={e + i}
                    {...field}
                    type={e}
                    data={props.data}
                    dispatch={props.dispatch}
                    messages={props.messages}
                    messageDispatch={props.messageDispatch}
                    val={props.data ? props.data[e] : ''}
                    customEvents={props.customEvents}
                    object={props.object}
                    clicked={props.clicked} />;
            })}

            {props.components
                && props.data
                && Object.keys(props.components).map((c, i) =>
                    <React.Fragment key={c + i}>
                        {props.components[c].Component(
                            c,
                            props.data.hasOwnProperty(c) ? props.data[c] : props.data,
                            props.dispatch,
                            props.messages,
                            props.messageDispatch,
                            props.componentRefs,
                            props.messageIdentityPrefix,
                            props.data,
                            props.customEvents)}
                    </React.Fragment>)}
        </div>
    </React.Fragment>;
};

export default Section;