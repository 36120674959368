import React from 'react';

// import ColorPicker from 'rc-color-picker';
import ColorPicker from '../components/custom/rc-color-picker/lib';
import { ElementConditions } from '../config/condition.config';
import { RGBAToHexA, HexToRGBAFormat } from '../helper/common';

const ColorElement = props => {
    const { data, condition, action } = props;
    const RGBA = RGBAToHexA(data || 'rgba(255, 255, 255, 1)');

    const Close = e => {
        action({
            nativeEvent: e,
            target: {
                value: HexToRGBAFormat(e.color, e.alpha),
                type: 'colorPicker'
            }
        });
    };

    return condition !== ElementConditions.Hidden && <div className='color-picker'>
        <ColorPicker color={RGBA[0]} alpha={RGBA[1]} placement="topRight" onClose={Close} />
    </div>;
};

export default ColorElement;