import {
    ElementTypes,
    GenerateToolBars
} from '../../../config';

import { OutboundAppointmentFields } from '../appointmentFieldsConfig';

const Dispatch = {
    Title: 'Dispatch',
    //Resource: 'InboundAppointment/stopdispatch',
    Key: 'id',
    IsRest: true,
    Form: {
        Title: 'Dispatch',
        SectionGroups: [
            {
                Sections: [
                    {
                        classes: 'wide',
                        Fields: {
                            ...OutboundAppointmentFields,
                            Group3: [
                                {
                                    useSeparator: false,
                                    Fields: {
                                        outboundDropAppointment: {
                                            Title: 'Appt Date',
                                            Field: ElementTypes.LabelFieldDate()
                                        }
                                    }
                                }
                            ],
                            Group4: [
                                {
                                    useSeparator: false,
                                    Fields: {
                                        outboundDropIn: {
                                            Title: 'In Date',
                                            Field: ElementTypes.DateField()
                                        }
                                    }
                                }
                            ],
                            Group5: [
                                {
                                    useSeparator: false,
                                    Fields: {
                                        outboundDropOut: {
                                            Title: 'Out Date',
                                            Field: ElementTypes.DateField()
                                        }
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        ],
        ToolBars: GenerateToolBars(['Save', 'Cancel'])
    }
};

export default Dispatch;