import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { GoogleMap, Marker, InfoWindow } from '@react-google-maps/api';
import Geocode from "react-geocode";

import { locationHandler } from './util/location';
import { CssErrorClassHandling } from '../helper/element';
import { ValidationErrorClass } from '../helper/validation';


const defaultPosition = {
    lat: 34.0522,
    lng: -118.2437
};

const mapFieldArr = [
    {
        position: {
            lat: 40.4580726,
            lng: -3.8625651,
        },
        name: 'TstWarehouse',
        isTstWarehouse: true
    },
    {
        position: {
            lat: 40.4381311,
            lng: -3.8196227
        },
        name: 'Warehouse1',
        isTstWarehouse: false
    },
    {
        position: {
            lat: 40.4210771,
            lng: -3.7097202
        },
        name: 'Warehouse2',
        isTstWarehouse: false
    }
];

const getIcon = (isTstWarehouse, width = 42, height = 42) => {
    const icon = {
        url: require(isTstWarehouse ? '../../mapMarkerIcons/tstWarehouse.png' : '../../mapMarkerIcons/warehouse.png'),
        scaledSize: {
            width: width,
            height: height
        }
    };

    return icon;
}

const MultipleMarkersMapField = props => {
    const { isLoaded, googleApiKey, type, condition, ref, data,
        classes, action, valudationMessages,
        placeholder, name } = props;

    Geocode.setApiKey(googleApiKey);

    //const [position, setPosition] = useState(defaultPosition);
    const [infoWindowState, setInfoWindowState] = useState(false);

    //useEffect(() => {
    //    if (object && object.lat && object.lng) {
    //        setPosition({
    //            lat: object.lat,
    //            lng: object.lng
    //        });
    //    }
    //}, [object]);


    const onDragEndHandler = event => {
        Geocode.fromLatLng(event.latLng.lat(), event.latLng.lng()).then(
            response => {
                if (response && response.status === "OK" && response.results && response.results.length > 0) {
                    locationHandler(response.results[0], action);
                }
                else {
                    console.error(response);
                }
            },
            error => {
                console.log('no address', error);
            });
    };

    const infoWindowCloseHandler = () => {
        setInfoWindowState(false);
    };

    const infoWindowOpenHandler = () => {
        setInfoWindowState(true);
    };

    useEffect(() => {
        setInfoWindowState(data !== null);
    }, [data]);

    return isLoaded ? (
        <GoogleMap
            map='map'
            mapContainerClassName='map'
            ref={ref}
            id={name}
            placeholder={placeholder}
            className={CssErrorClassHandling(ValidationErrorClass, classes, valudationMessages).join(' ')}
            mapTypeId={type}
            center={defaultPosition}
            zoom={10}>
            {
                mapFieldArr.map(field => {
                    return (
                        <Marker
                            position={field.position}
                            draggable={condition}
                            onClick={infoWindowOpenHandler}
                            onDragEnd={onDragEndHandler}
                            key={field.name}
                            icon={getIcon(field.isTstWarehouse)}>
                            {
                                infoWindowState &&
                                <InfoWindow onCloseClick={infoWindowCloseHandler}
                                    position={field.position}
                                    options={{ pixelOffset: { width: -1, height: -45 } }}>
                                    <div>
                                        <span>{field.name}</span>
                                    </div>
                                </InfoWindow>
                            }
                        </Marker>
                    )
                })
            }
        </GoogleMap>
    ) : <></>
};

const mapStateToProps = state => {
    return {
        googleApiKey: state.auth.googleApiKey,
        isLoaded: state.google.isLoaded
    }
};

export default connect(mapStateToProps)(MultipleMarkersMapField);