import axios from 'axios';
import store from '../..';
import * as actionTypes from './trafficControlActionTypes';

const buildUrl = (resource = 'traffic', endPoint) => `/api/${resource}/${endPoint}`;

const axiosConfig = () => ({
    headers: {
        'Authorization': 'Bearer ' + store.getState().auth.userToken
    }
});

export const trafficControl = (resource, loadingType) => dispatch => {
    if (loadingType !== 'withoutLoading') {
        dispatch({ type: actionTypes.getActionType(resource, actionTypes.TRAFFIC_LOADING) });
    }
    axios.get(buildUrl(resource, 'GetTraffic'),
        axiosConfig())
        .then(response => dispatch({ type: actionTypes.getActionType(resource, actionTypes.GET_TRAFFIC_CONTROL), data: response }))
        .catch((err) => {
            console.error(err);
            console.log(err.response);
            dispatch({ type: actionTypes.getActionType(resource, actionTypes.ERROR) });
        });
};

export const setHeaderWidthTraffic = (resource, ind, width) =>
    ({ type: actionTypes.getActionType(resource, actionTypes.SET_HEADER_WIDTH_TRAFFIC), data: { ind, width } });

const updateData = (dispatch, resource, url, value) => {
    axios.put(buildUrl(resource, url), value,
        axiosConfig())
        .then(response => {
            if (response.data.hasError) {
                dispatch({ type: actionTypes.getActionType(resource, actionTypes.ERROR), data: response.data });
                dispatch(errorAction(resource, true));
                console.error(response.data.message);
            }
        }).catch(err => {
            dispatch(errorAction(resource, true));
            console.error(err);
            console.log(err.response);
        });
};

export const setItemData = (resource, value, rowState, header, subType) => dispatch => {
    dispatch({
        type: actionTypes.getActionType(resource, actionTypes.SET_DATA), data: {
            value,
            rowState,
            header,
            subType,
        }
    });

    const createQueryParam = (param, query) => query ? `${param}?loadId=${rowState.loadId}&${query}=${value}` : `${param}?loadId=${rowState.loadId}`;

    switch (header.key) {
        case 'eta':
            updateData(dispatch, resource, createQueryParam('changewarehousedoors', 'doornumber'));
        break;
        case 'truckVerified':
            updateData(dispatch, resource, createQueryParam('trackverified', 'trackverifystatus'));
        break;
        case 'trafficNote':
            updateData(dispatch, resource, createQueryParam('addtrafficnote', 'note'));
        break;
        case 'status':
            if (subType === 'priority') {
                updateData(dispatch, resource, createQueryParam('priority'));
            }
            if (subType === 'delayed') {
                updateData(dispatch, resource, createQueryParam('delayed'));
            }
        break;
        case 'status2':
            const createQueryParamForStatus = () => `changestatus?loadId=${rowState.loadId}&status=${value}`;
            updateData(dispatch, resource, createQueryParamForStatus());
        break;
        default:
        break;
    }
};

export const setItemDisplayState = (resource, rowState, header, enable) => ({
    type: actionTypes.getActionType(resource, actionTypes.SET_DISPLAY_STATE), data: {
        rowState,
        header,
        enable
    }
});

export const updateLoadData = (resource, newData) => ({
    type: actionTypes.getActionType(resource, actionTypes.UPDATE_LOAD_DATA),
    data: newData
});

export const setLoadNeedId = (resource, loadId) => ({
    type: actionTypes.getActionType(resource, actionTypes.SET_LOAD_NEED_ID),
    data: loadId,
});

export const updateLoadStatus = (resource, newStatus, rowState, header, loadId) => dispatch => {
    dispatch({
        type: actionTypes.getActionType(resource, actionTypes.UPDATE_LOAD_STATUS), data: {
            newStatus,
            rowState,
            header,
            loadId,
        }
    });
    
    const createQueryParam = () => `changestatus?loadId=${loadId}&status=${newStatus}`;
    updateData(dispatch, resource, createQueryParam());
};

export const reOrderLoadsByDragAndDrop = (resource, newList) => dispatch => {
    dispatch({
        type: actionTypes.getActionType(resource, actionTypes.RE_ORDER_LOADS), data: {
            newList,
        }
    });

    updateData(dispatch, resource, 'loadordering', newList);
};

export const resetError = resource =>
    ({ type: actionTypes.getActionType(resource, actionTypes.RESET_ERROR) });

const errorAction = (resource, reload) => dispatch => {
    if (reload) {
        axios.get(buildUrl(resource, 'GetTraffic'),
            axiosConfig()
        )
            .then(response => dispatch({ type: actionTypes.getActionType(resource, actionTypes.RELOAD_ON_ERROR_TRAFFIC), data: response }))
            .catch((err) => {
                console.error(err);
                console.log(err?.response);
                // dispatch({ type: actionTypes.getActionType(resource, actionTypes.ERROR) });
            });
    }
};