import React, { useEffect } from 'react';
import { connect } from "react-redux";
import { setGoogleLoaded } from '../../../store/actions/google/google';

import { useGoogleApiLoader } from '../../elements/util/location';

const ScriptLoader = props => {
    const { setGoogleLoaded, googleApiKey } = props;
    const { isLoaded } = useGoogleApiLoader(googleApiKey);
    
    

    useEffect(() => {
        if (isLoaded) {
            setGoogleLoaded();
        }
    }, [isLoaded, setGoogleLoaded]);

    return <></>;
};

const mapStateToProps = state => {
    return {
        googleApiKey: state.auth.googleApiKey,
        isLoaded: state.google.isLoaded
    }
};


const mapDispatchToProps = dispatch => {
    return {
        setGoogleLoaded: () => dispatch(setGoogleLoaded())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ScriptLoader);