import React from 'react';
import { connect } from "react-redux";

import { openPopup, closePopup, closePopupAsync } from '../../../../store/actions/popup/popup';
import { PopupTypes } from '../../custom/popup/popup';
import Sheet from '../../custom/sheet/sheet';
import { CloneObject } from '../../../helper/common';
import { Action } from '../../../config/action.config';
import Loading from '../../screen/loading';

const Orders = props => {
    const onActionRecordHandler = (record, action) => {
        switch (action) {
            case Action.Edit:
                editOrder(record.recordId);
                break;
            default:
                break;
        }
    };

    const editOrder = (id) => {
        const windowKey = 'Order';
        const config = CloneObject(props.OrderForm);
        const title = `Edit ${config.Title}`;
        config.screen = config;
        config.screen.Form.DefaultId = id;
        config.screen.Resource = 'ReceivingLoad\\receivingloadorderitem';

        props.openPopup({
            windowKey,
            fullScreen: false,
            title: title,
            type: PopupTypes.ConfigForm,
            bodyProps: { windowKey },
            config: config,
            saveAction: (result) => {
                const data = [...props.data];
                const index = data.indexOf(data.find(i => i.id === result.id));
                data.splice(index, 1, result);
                props.dispatchData({ type: props.dropId, value: data });

                props.closePopupAsync(windowKey);
            },
            closeAction: () => {
                props.closePopup(windowKey);
            }
        });
    };

    return (props.isLoading
        ? <Loading />
        : <Sheet
            colgroup
            resource={props.resource}
            keyFieldName={props.keyFieldName}
            minRowsCanDelete={Number.MAX_SAFE_INTEGER}
            columns={props.columns}
            data={props.data}
            classes='table_view extra'
            onActionRecord={onActionRecordHandler}
            action
            resizable
        />
    );
};

const mapDispatchToProps = dispatch => {
    return {
        openPopup: (data) => dispatch(openPopup(data)),
        closePopup: (key) => dispatch(closePopup(key)),
        closePopupAsync: (key) => dispatch(closePopupAsync(key))
    };
};

export default connect(null, mapDispatchToProps)(Orders);