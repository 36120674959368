import React from 'react';
import { Droppable } from 'react-beautiful-dnd';
import SheetTableContent from './sheetTableContent';
import { SelectableGroup } from '../../custom/reactSelectable';
import { useState } from 'react';

const Sheet = props => {
    const groupBy = props.groupBy;
    const groupType = props.groupType;
    const groupTypeValue = props.groupTypeValue;
    const notUseInnerSelection = props.notUseInnerSelection;

    const SelectableTable = (children) => props.selectable
        ? <SelectableGroup onBeginSelection={handleBeginSelection} onEndSelection={handleMouseEndSelection} tolerance={10}>
            {children}
        </SelectableGroup>
        : children;

    const [selected, setSelected] = useState([]);

    const handleBeginSelection = () => {
        setSelected([...props.selectedRows].filter(r => props.data.find(i => i.id === r.id) !== undefined));
    }

    const handleMouseEndSelection = selectedItems => {
        if (notUseInnerSelection) {
            props.onAfterMouseSelect && props.onAfterMouseSelect(selectedItems);
            return;
        }
        if (!selectedItems?.length) {
            return;
        }

        if (selectedItems.length === props.selectedRows.length) {
            return;
        }

        selectedItems = selectedItems.filter(r => {
            return selected.find(i => {
                return i.id === r.id;
            }) === undefined;
        });

        const newItems = [];
        let groupIndex = ''
        selectedItems.forEach(i => {
            if(props.grouping && i[groupType] === groupTypeValue) {
                if(groupIndex === i[groupBy]) {
                    return;
                }
                groupIndex = i[groupBy];

                newItems.push(...props.data.filter(d => d[groupType] === groupTypeValue && d[groupBy] === i[groupBy]));

                return;
            }

            newItems.push(i);
        });

        const data = [...selected, ...newItems];
        props.setSelectedRows(data);

        props.onAfterMouseSelect && props.onAfterMouseSelect(data);
    };
 
    let disableDrag = false;

    if (props?.selectedRows?.length) {
        for (const i in props.selectedRows) {
            const item = props.selectedRows[i];

            const originalItem = props.data.find(i => i.id === item.id);
            const index = props.data.indexOf(originalItem);

            if (index === -1) {
                disableDrag = true;

                break;
            }
        }
    }

    return (props.draggable
        ? (
            <Droppable
                droppableId={props.droppableId || "droppable"}
                direction="vertical"
            >
                {(provided, snapshot) => (
                    <div
                        className={props.classes || "table_view adaptive small"}
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        dragging={snapshot.isDraggingOver ? '' : null}
                    >
                        {SelectableTable(<table
                            id={props.resource ? props.resource : null}
                        >
                            <SheetTableContent
                                columns={props.columns}
                                action={props.action}
                                actions={props.actions}
                                colgroup={props.colgroup}
                                header={props.header}
                                resource={props.resource}
                                onActionRecord={props.onActionRecord}
                                messageIdentityPrefix={props.messageIdentityPrefix}
                                keyFieldName={props.keyFieldName}
                                data={props.data}
                                parentData={props.parentData}
                                messageDispatch={props.messageDispatch}
                                messages={props.messages}
                                minRowsCanDelete={props.minRowsCanDelete}
                                handleCheck={props.handleCheck}
                                onChange={props.onChange}
                                selectedRows={props.selectedRows}
                                setSelectedRows={props.setSelectedRows}
                                selectable={props.selectable}
                                resizable={props.resizable}
                                draggable={props.draggable}
                                disableDrag={disableDrag}
                                providedPlaceholder={provided.placeholder}
                                coloring={props.coloring}
                                grouping={props.grouping}
                                groupBy={props.groupBy}
                                groupType={props.groupType}
                                groupTypeValue={props.groupTypeValue}
                                subGroup={props.subGroup}
                                addIndex={props.addIndex}
                                onSort={props.onSort}
                                sort={props.sort}
                            />
                        </table>
                        )}
                        <table style={snapshot.isDraggingOver ? null : { display: 'none' }}>
                            <tbody>{provided.placeholder}</tbody>
                        </table>
                    </div>
                )}
            </Droppable>
        )
        : (
            <div className={props.classes || "table_view adaptive small"}>
                {SelectableTable(
                    <table id={props.resource ? props.resource : null}>
                        <SheetTableContent
                            columns={props.columns}
                            action={props.action}
                            actions={props.actions}
                            draggable={props.draggable}
                            selectable={props.selectable}
                            colgroup={props.colgroup}
                            header={props.header}
                            resource={props.resource}
                            onActionRecord={props.onActionRecord}
                            messageIdentityPrefix={props.messageIdentityPrefix}
                            keyFieldName={props.keyFieldName}
                            data={props.data}
                            parentData={props.parentData}
                            messageDispatch={props.messageDispatch}
                            messages={props.messages}
                            minRowsCanDelete={props.minRowsCanDelete}
                            handleCheck={props.handleCheck}
                            selectedRows={props.selectedRows}
                            setSelectedRows={props.setSelectedRows}
                            resizable={props.resizable}
                            onChange={props.onChange}
                            coloring={props.coloring}
                            grouping={props.grouping}
                            groupBy={props.groupBy}
                            groupType={props.groupType}
                            groupTypeValue={props.groupTypeValue}
                            subGroup={props.subGroup}
                            addIndex={props.addIndex}
                            onSort={props.onSort}
                            sort={props.sort}
                        />
                    </table>
                )}
            </div>
        )
    );
};
export default Sheet;