import axios from '../../../axios/axios';


export const useDragging = (
    defaultDroppableId,
    availableOrders,
    mainResource,
    getFilteredSortedOrders,
    getStops,
    updateSelectedOrders,
    stops,
    apiResource,
    load,
    setAvailableOrders,
    initStops,
    initOrderFromStop,
    getAllStopOrders,
    loads,
    setStops,
    assignApiPath,
    unassignApiPath,
    loadIdField,
    resetOrder
) => {

    const getDragDropData = droppableId => {
        if (droppableId === defaultDroppableId) {
            return {
                type: droppableId,
                data: getFilteredSortedOrders(availableOrders, mainResource)
            };
        }
        else {
            const stop = getStops().find(s => s.key === droppableId);
            if (stops) {
                return {
                    type: droppableId,
                    data: stop.orders
                };
            }
        }
    };

    const createRequest = (stop, newOrders) => {
        const request = {
            orderIds: newOrders.reduce((r, o) => { r.push(o.id); return r }, [])
        };
        request[loadIdField] = stop.loadId;
        return request;
    };

    const apiUpdate = (stop, newOrders) => {
        axios.put(`${apiResource}/${assignApiPath}`,
            createRequest(stop, newOrders)).then(response => {
                const errors = response.data.filter(r => r.hasError);
                if (errors.length > 0) {
                    //show error messages todo Emma
                    load();
                }
            });
    };

    const updateDragDropData = data => {
        const type = data.type;
        updateSelectedOrders([], '');
        if (type === "all") { //we have drag drop from different locations
            const types = Object.keys(data).splice(1);
            const from = data[types[0]];
            const to = data[types[1]];
            const toData = to.data;
            if (from.type === defaultDroppableId || to.type === defaultDroppableId) {
                if (from.type === defaultDroppableId) {
                    //from available to assigned
                    const stop = stops.find(s => s.key === to.type);
                    const newOrders = toData.filter(o => !o[loadIdField]);
                    //call api
                    apiUpdate(stop, newOrders);
                    const newAvailableOrders = [...availableOrders];
                    newOrders.forEach(o => {
                        newAvailableOrders.splice(newAvailableOrders.indexOf(newAvailableOrders.find(ao => ao.id === o.id)), 1);
                        initOrderFromStop(o, stop, stops);
                    });
                    setAvailableOrders(newAvailableOrders);
                    initStops([...getAllStopOrders(stops), ...newOrders], loads);
                }
                else {
                    //from assigned to available
                    const dropAvailableOrders = toData.filter(o => o[loadIdField]);
                    const newStops = [...stops];
                    const stop = newStops.find(s => s.key === from.type);
                    const newAvailableOrders = [...availableOrders];
                    //call api
                    axios.put(`${apiResource}/${unassignApiPath}`,
                        {
                            orderIds: dropAvailableOrders.reduce((r, o) => { r.push(o.id); return r }, [])
                        }).then(response => {
                            const errors = response.data.filter(r => r.hasError);
                            if (errors.length > 0) {
                                //show error messages todo Emma
                                load();
                            }
                        });
                    dropAvailableOrders.forEach(o => {
                        resetOrder(o);
                        newAvailableOrders.push(o);
                        stop.orders.splice(stop.orders.indexOf(stop.orders.find(so => so.id === o.id)), 1);
                    });
                    setAvailableOrders(newAvailableOrders);
                    initStops(getAllStopOrders(newStops), loads);
                }
            }
            else {
                //from assigned to assigned
                const newStops = [...stops];
                const stop = newStops.find(s => s.key === to.type);
                const oldStop = newStops.find(s => s.key === from.type);
                const newOrders = toData.filter(o => o[loadIdField] === oldStop.loadId);
                //call api
                apiUpdate(stop, newOrders);
                const currentOrders = getAllStopOrders(stops);
                newOrders.forEach(dropO => initOrderFromStop(currentOrders.find(order => order.id === dropO.id), stop, newStops));
                initStops(currentOrders, loads);
            }
        }
        else {
            //not called from drag drop, we can delete this in future
            const updatedData = data.value;
            if (type === defaultDroppableId) {
                setAvailableOrders(updatedData);
            }
            else {
                const newStop = [...stops];
                const stop = newStop.find(s => s.key === type);
                if (stop) {
                    stop.orders = updatedData;
                    setStops(newStop);
                }
            }
        }
    };

    return [getDragDropData, updateDragDropData];
};