import React, { useRef, useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import Split from "react-split-it";
import { message } from 'antd';
import PieChart from "./pieChart";
import BarChart from "./barChart";
import { useClientHub } from "../../../hook/hub/useClientHub";
import { setCustomerDashboard, resetError } from '../../../../store/actions/customerDashboard/customerDashboard';
import Loading from "../../screen/loading";
import { formatPhoneNumber } from "../../../../codeless/helper/common";
import { useTidioScript } from "../../../hook/data/useTidioScript";

const CustomerDashBoard = props => {
    const { setCustomerDashboard, customerDashboard, loading, Resource: resource, haseError, resetError, actionMessage } = props;
    useTidioScript();

    useEffect(() => {
        setCustomerDashboard(resource);
    }, [resource, setCustomerDashboard]);

    useEffect(() => {
        if (haseError) {
            message.error(actionMessage);
            // resetError(resource);
        }
    }, [haseError, actionMessage, resetError, resource]);

    const { paletCount, paletWeigth, paletSpace, organization } = customerDashboard;

    const getCurrentWeekNumber = () => {
        const date = new Date();
        const oneJan = new Date(date.getFullYear(), 0, 1);
        const numberOfDays = Math.floor((date - oneJan) / (24 * 60 * 60 * 1000));
        const weekNumber = Math.ceil((date.getDay() + 1 + numberOfDays) / 7);

        return weekNumber
    };

    const cuurentWeek = getCurrentWeekNumber();
    const cuurentYear = new Date().getFullYear()
    const [year, setYear] = useState(cuurentYear);
    const [week, setWeek] = useState(cuurentWeek);
    const [yearNumber, setYearNumber] = useState(cuurentYear);
    const [weekNumber, setWeekNumber] = useState(cuurentWeek);
    const yearRef = useRef();
    const weekRef = useRef();

    const [changesCount, setChangesCount] = useState(0);

    useClientHub(() => {
        setChangesCount(changesCount+1);
    }, 'notification');

    const format = (value, dispatch) => {
        const int = +value
        if(int) {
            dispatch(int);
        }
    }

    const getYear = value => {
        const year = new Date().getFullYear();
        if(value <= year - 1) {
            setYear(year - 1);
            setYearNumber(year - 1);
        } else if(value >= year) {
            setYear(year);
            setYearNumber(year);

            const currenWeek = getCurrentWeekNumber();
            if(weekRef.current.value > currenWeek) {
                setWeek(currenWeek);
                setWeekNumber(currenWeek);
            }
        }
    };

    const getWeek = value => {
        const currenWeek = getCurrentWeekNumber();
        if(value < 0) {
            setWeek(currenWeek);
            setWeekNumber(currenWeek);
            return;
        }

        const date = new Date();
        if(value > currenWeek) {
            if(+yearRef.current.value === date.getFullYear()) {
                setWeek(currenWeek);
                setWeekNumber(currenWeek);

                return;
            } else {
                if(value > 53) {
                    setWeek(currenWeek);
                    setWeekNumber(currenWeek);

                    return;
                }
            }
        }
        setWeek(value);
        setWeekNumber(value);
    };

    return (
    <>
        {(loading || haseError) ? <Loading /> :
        <div style={{overflowY: 'scroll'}}>
            <view dashboard="" main="">
                <div style={{position: "relative"}}>
                    <div className="customer-page__title">Order Management System</div>
                    <div className="customer-page__btn">
                        <Link to="/customerorder">Go To My Orders</Link>
                    </div>
                    <div className="dots_0"></div>
                </div>
                <Split sizes={[0.5, 0.5]} gutterSize={3} className="customerDashBoard">
                    <dashboard>
                        <wrap chart="" horizontal="" s_space="">
                            <item dwidget="">
                                <wrap>
                                    <PieChart Resource={`${resource}/orderstatuswidget`} changesCount={changesCount} />
                                </wrap>
                            </item>
                            <item dwidget="">
                                <toolbar border="bottom">
                                    <wrap>
                                        <action>
                                            <wrap>
                                                <div className="form_fields line small">
                                                    <div className="data_label">Year</div>
                                                    <input name="Year" type="text" ref={yearRef} value={yearNumber} onBlur={((e) => getYear(e.target.value))} onChange={e => format(e.target.value, setYearNumber)} />
                                                </div>
                                                <separator vertical=""></separator>
                                                <div className="form_fields line small">
                                                    <div className="data_label">Week</div>
                                                    <input name="Year" type="text" ref={weekRef} value={weekNumber} onBlur={((e) => getWeek(e.target.value))} onChange={e => format(e.target.value, setWeekNumber)} />
                                                </div>
                                            </wrap>
                                        </action>
                                    </wrap>
                                </toolbar>
                                <wrap>
                                    <BarChart Resource={`${resource}/orderweeklystatuswidget/${year}/${week}`} changesCount={changesCount} />
                                </wrap>
                            </item>
                        </wrap>
                    </dashboard>
                </Split>
            </view>
            <div style={{marginTop: "94px", height: '100%'}}>
            <iframe title="weather-forecast" width="100%" height="100%" src="https://embed.windy.com/embed2.html?lat=37.250&lon=260.000&width=650&height=450&zoom=4.5&level=surface&overlay=wind&product=ecmwf&menu=&message=&marker=&calendar=now&pressure=&type=map&location=coordinates&detail=&metricWind=default&metricTemp=%C2%B0F&radarRange=-1" frameborder="0"></iframe>            </div>
            <div style={{position: "relative"}}>
                <div className="statistics">Statistics Info</div>
                <div className="statistics-info__container">
                    <div className="statistics-info">
                        <p className="statistics-info__data">{+paletCount}</p>
                        <p className="statictics__title">Pallet</p>
                    </div>
                    <div className="statistics-info">
                        <p className="statistics-info__data">{+paletWeigth}</p>
                        <p className="statictics__title">Weight</p>
                    </div>
                    <div className="statistics-info">
                        <p className="statistics-info__data">{+paletSpace}</p>
                        <p className="statictics__title">Space</p>
                    </div>
                </div>
            </div>
            <div className="customer-landing__img"></div>
            <div className="contactUs__container">
                <div className="dots_1"></div>
                <div className="contactUs__title">Contact Us</div>
                <div className="contactUs__data">{organization?.email}</div>
                <div className="contactUs__data">{formatPhoneNumber(organization?.mainPhone)}</div>
                <div className="contactUs__address">{organization?.address}</div>
                <div className="contactUs__address">{organization?.addressLocation}</div>
                <div className="contactUs__address">MC#{organization?.mcNumber}</div>
                <div className="dots_2"></div>
            </div>
        </div>
        }
    </>
    );
};


const mapStateToProps = state => ({
    state: state,
    customerDashboard: state.customerDashboard,
    loading: state.customerDashboard.loading,
    haseError: state.customerDashboard.hasError,
    actionMessage: state.customerDashboard.message,
});

const mapDispatchToProps = (dispatch) => ({
    setCustomerDashboard: (resource) => dispatch(setCustomerDashboard(resource)),
    resetError: resource => dispatch(resetError(resource)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomerDashBoard);