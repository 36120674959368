import React from 'react';
import { DragDropContext } from 'react-beautiful-dnd';

const useDraggabale = (props) => {
    function onDragEnd(result) {
        if (!result.destination) { // Don't update any data if Dropped out of DropZone
            return
        }

        const dragIndex = result.source.index;
        let dropIndex = result.destination.index;

        const draggableSource = props.getDragDropData && typeof props.getDragDropData === 'function' ?
            props.getDragDropData(result.source.droppableId) : props[result.source.droppableId];
        const droppableSource = props.getDragDropData && typeof props.getDragDropData === 'function' ?
            props.getDragDropData(result.destination.droppableId) : props[result.destination.droppableId];

        let dragRecord = draggableSource.data[dragIndex]; // Get Drag item from dragIndex
        const currentRecord = draggableSource.data[dragIndex];
        const newSourceData = [...draggableSource.data]; // Clone Drag srource data

        const groupBy = props.groupBy;
        const groupType = props.groupType;
        const groupTypeValue = props.groupTypeValue;

        if (props.selectedRows?.length) { // props.selectedRows as dragRecord
            props.selectedRows.forEach(item => {
                const originalItem = newSourceData.find(i => i.id === item.id);
                const index = newSourceData.indexOf(originalItem);

                if (index !== -1) {
                    newSourceData.splice(index, 1);
                } else {
                    alert(`${item.id}: selected item not exists`);

                    return;
                }
            });
        } else {
            if (props.grouping && groupBy && currentRecord?.[groupType] === groupTypeValue) {
                dragRecord = newSourceData.filter(i => i[groupBy] === currentRecord[groupBy]);
                dragRecord.forEach(item => {
                    const originalItem = newSourceData.find(i => i.id === item.id);
                    const index = newSourceData.indexOf(originalItem);

                    if (index !== -1) {
                        newSourceData.splice(index, 1);
                    } else {
                        alert(`${item.id}: selected item not exists`);

                        return;
                    }
                });
            } else {
                newSourceData.splice(dragIndex, 1); // Remove Drag item from Clone data
            }
        }

        const newDestinationData = [...droppableSource.data]; // Clone Drop srource data

        if (result.source.droppableId === result.destination.droppableId) { // if Dropped in the same Data
            if (dragIndex === dropIndex) {
                return;
            }

            if (props.selectedRows?.length) {
                newSourceData.splice(dropIndex, 0, ...props.selectedRows); // Insert Selected Drag items to dropIndex
            } else {
                if (Array.isArray(dragRecord)) {
                    newSourceData.splice(dropIndex, 0, ...dragRecord); // Insert Drag item to dropIndex
                } else {
                    newSourceData.splice(dropIndex, 0, currentRecord); // Insert Drag item to dropIndex
                }
            }

            if (props.grouping && groupBy) {
                if ((droppableSource.data[dropIndex]?.[groupBy] !== currentRecord[groupBy] && droppableSource.data[dropIndex + 1]?.[groupBy] !== currentRecord[groupBy])
                    || (droppableSource.data[dropIndex]?.[groupBy] !== currentRecord[groupBy] && droppableSource.data[dropIndex - 1]?.[groupBy] !== currentRecord[groupBy])
                ) {
                    return;
                }
            }

            props.dispatch({ type: draggableSource.type, value: newSourceData }); // Update Drag source data. In all cases Drag source need to update

            return;
        } else { // if Dropped in the other Data
            let dropData = [];

            if (props.selectedRows?.length) {
                dropData = props.selectedRows;
                // newDestinationData.splice(dropIndex, 0, ...props.selectedRows); // Insert Selected Drag items to dropIndex
            } else {
                if (Array.isArray(dragRecord)) {
                    dropData = dragRecord;
                    // newDestinationData.splice(dropIndex, 0, ...dragRecord); // Insert Drag item to dropIndex
                } else {
                    dropData = [currentRecord];
                    // newDestinationData.splice(dropIndex, 0, currentRecord); // Insert Drag item to dropIndex
                }
            }

            // if(result.destination.droppableId !== 'orders') {
            if (props.grouping && groupBy) {
                newDestinationData.push(...dropData);
            } else {
                newDestinationData.splice(dropIndex, 0, ...dropData);
            }
            // } else {
            //     if (props.grouping && groupBy) {
            //         if (droppableSource.data?.length && droppableSource.data[dropIndex]?.[groupBy] === droppableSource.data[dropIndex - 1]?.[groupBy]) {
            //             return;
            //         }
            //     } else {
            //         newDestinationData.splice(dropIndex, 0, ...dropData);
            //     }
            // }

            if (!props.oneState) {
                props.dispatch({ type: droppableSource.type, value: newDestinationData }); // Update Drop source data
            }
        }

        if (props.oneState) {
            props.dispatch(
                {
                    type: 'all',
                    [draggableSource.type]: { type: draggableSource.type, data: newSourceData },
                    [droppableSource.type]: { type: droppableSource.type, data: newDestinationData }
                },
                {
                    dragSource: draggableSource.type,
                    dropSource: droppableSource.type,
                    record: props.selectedRows?.length ? props.selectedRows : dragRecord
                }
            ); // Update Drag source data. In all cases Drag source need to update
        } else {
            props.dispatch({ type: draggableSource.type, value: newSourceData }); // Update Drag source data. In all cases Drag source need to update
        }
    }

    return { onDragEnd };
}

/**
* @props = { // List of Draggable data
    droppableId: {
        data: firstData, // state
        dispatch: setFirstData, // useState
        type: type // Type for dispatch
    },
    droppableId: {
        data: scondData, // state
        dispatch: setScondData, // useState
        type: type // Type for dispatch
    },
}
**/
const DragDrop = (props) => {
    const { onDragEnd } = useDraggabale(props);
    return (
        <DragDropContext onDragEnd={onDragEnd}>
            {props.children}
        </DragDropContext>
    );
};

export default DragDrop

