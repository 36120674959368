import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import Scroll from '../../scroll/scroll';
import './index.css'

const SubNavigation = props => {
    return (<>
        {useMemo(() => <Scroll parentMenu={props.parentMenu}>
            <div className='subHeader_first' style={{ height: '32px' }}>
                <ul>
                    {props.managedMenu?.map((item, i) => {
                        let selectedMenu = false;
                        const toItem = item.to.toLowerCase();
                        selectedMenu = toItem === props.location;
                        if ((props.location === '/loadplanning/revision' && toItem === '/loadplanning/changerequest') || 
                            (props.location === '/loadplanning/cancelorder' && toItem === '/loadplanning/changerequest')) {
                            selectedMenu = true;                            
                        }
                        return <li
                            key={i}
                            className={selectedMenu || (item.detail && props.location.startsWith(item.detail + '/'))
                                ? 'selected' : ''} >
                            <Link to={item.to}>
                                <text>{item.text}</text>
                            </Link>
                        </li>
                    })}
                </ul>
            </div>
        </Scroll>, [props.location, props.managedMenu, props.parentMenu])}</>
    );
}

export default SubNavigation;