import React, { useRef, useEffect } from 'react';
import { connect } from "react-redux";
import Cell from './cell';

const Row = ({ data, style, itemSize, listRef, startOnScroll, resource, setCheckedList, index, checked }) => {
    const rowRef = useRef({});
    const color = index % 2 === 0 ? '#F9FFFF' : '#FFFFFF';

    useEffect(() => {
        if (rowRef.current) {
            listRef.current.resetAfterIndex(0);
        }
    }, [rowRef, listRef, data.rowState.loadStatus]);

    style.width = 'max-content';
    const rowStyle = { ...style, ...{ height: `${itemSize}px`, backgroundColor: color } };
    
    const cellContent = (i, ind) => {

    return (<Cell
            key={ind}
            cellData={i}
            type={data.rowState.type}
            itemSize={itemSize}
            startOnScroll={startOnScroll}
            rowState={data.rowState}
            resource={resource}
            checked={checked}
            setCheckedList={setCheckedList}
        />);
    };

    return data ? 
        <div className='resp-table-row lastRowBorder lastRowBorder_customer' style={{ ...rowStyle, display:'flex' }}>
            {data.row.map(cellContent)}
        </div> : null;
    };

    export default connect(state => ({ state: state}))(Row);