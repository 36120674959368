import moment from 'moment';
import { NullOrUndefined } from '../../helper/common';
import { Defines } from '../../../project/Defines';
import { ColumnType } from '../../config';

const pickAppointmentButton = Appointment => (Appointment
    ? {
        Title: 'PICK APPT',
        NoSort: true,

        Type: ColumnType.Button,
        Icon: 'calendar',
        PopupConfig: {
            Resource: '',
            Form: Appointment
        },
        Action: 'RequestAppointment',
        ActionType: 'PickAppointment',
        Label: o => o && o.pickAppointment ? moment(o.pickAppointment).format(Defines.Format.PrintDateTime) : '',
        Field: 'pickAppointment',

        Display: o => !o.isEmpty,

        Width: 140,
        Attributes: {
            action: {
                right: ''
            }
        }
    }
    : null
);

const pickAppointmentField = Appointment => (Appointment ?
    null :
    {
        Title: 'Pick Appt',
        NoSort: true,
        Type: ColumnType.DateTime,
        Width: 100
    }
);

const dropAppointmentButton = DropAppointment => (DropAppointment
    ? {
        Title: 'TRF APT',
        NoSort: true,

        Type: ColumnType.Button,
        Icon: 'calendar',
        PopupConfig: {
            Resource: '',
            Form: DropAppointment
        },
        Action: 'RequestAppointment',
        ActionType: 'DropAppointment',
        Label: o => o && o.dropAppointment ? moment(o.dropAppointment).format(Defines.Format.PrintDateTime) : '',
        Field: 'dropAppointment',

        Display: o => !o.isEmpty,

        Width: 140,
        Attributes: {
            action: {
                right: ''
            }
        }
    }
    : null
);

const dropAppointmentField = DropAppointment => (DropAppointment ?
    null : {
        Title: 'Drop Appt',
        NoSort: true,
        Type: ColumnType.DateTime,
        Width: 100
    }
);

const loadSequence = (LoadSequence, LoadSequenceAction) => (LoadSequence
    ? {
        Title: 'Load Sequence',
        NoSort: true,

        Type: ColumnType.Button,
        Icon: 'info',
        PopupConfig: {
            Resource: '',
            Form: LoadSequence
        },
        Action: 'OpenForm',
        ActionType: LoadSequenceAction,
        Label: o => o && o.sequencesName,

        Display: o => !o.isEmpty,

        Width: 100,
        Attributes: {
            action: {
                right: ''
            }
        }
    }
    : null
);

const Columns = (Appointment, DropAppointment, LoadSequence, LoadSequenceAction) => ({
    loadNumber: {
        Title: ' LOAD #',
        NoSort: true,
        Type: ColumnType.Text,
        Width: 110
    },
    puDate: {
        Title: 'READY',
        NoSort: true,
        Type: ColumnType.Date,
        Width: 90,
    },
    buttonPickAppt: pickAppointmentButton(Appointment),
    pickAppointment: pickAppointmentField(Appointment),
    customer: {
        Title: 'CUS',
        NoSort: true,
        Type: ColumnType.Text,
        DataTitle: true,
        DataTitleField: 'customerTooltip',
        Width: 70
    },
    pickStatus: {
        Title: '',
        NoSort: true,
        Display: o => !o.isEmpty,
        Type: ColumnType.StatusIndicator,
        DataTitle: true,
        Width: 30,
        Attributes: {
            wrap: {
                center: ''
            }
        }
    },
    pickWarehouse: {
        Title: 'P/U WH',
        NoSort: true,
        Type: ColumnType.Text,
        Width: 160
    },
    pickCity: {
        Title: 'P/U C',
        NoSort: true,
        Type: ColumnType.Text,
        Width: 100
    },
    pickState: {
        Title: 'P/U ST',
        NoSort: true,
        Field: ColumnType.Text,
        Width: 80,
    },
    buttonDropAppt: dropAppointmentButton(DropAppointment),
    dropAppointment: dropAppointmentField(DropAppointment),
    dropStatus: {
        Title: '',
        NoSort: true,
        Display: o => !o.isEmpty,
        Type: ColumnType.StatusIndicator,
        DataTitle: true,
        Width: 30,
        Attributes: {
            wrap: {
                center: ''
            }
        }
    },
    dropWarehouse: {
        Title: 'TRF WH',
        NoSort: true,
        Type: ColumnType.Text,
        Width: 160
    },
    dropCity: {
        Title: 'TRF CITY',
        NoSort: true,
        Field: ColumnType.Text,
        Width: 80,
    },
    dropState: {
        Title: 'TRF ST',
        NoSort: true,
        Field: ColumnType.Text,
        Width: 80,
    },
    poNumberCount: {
        Title: 'PO #',
        NoSort: true,
        Type: ColumnType.Text,
        Width: 50,
        DataTitle: true,
        DataTitleField: 'poNumbers'
    },
    totalPallets: {
        Title: 'Pal',
        NoSort: true,
        Type: ColumnType.Text,
        Width: 50
    },
    totalWeight: {
        Title: 'LBS',
        NoSort: true,
        Type: ColumnType.Text,
        Width: 80
    },
    loadTotalPallets: {
        Title: 'Total Pal',
        NoSort: true,
        Display: o => o.isFirst,
        Type: ColumnType.Text,
        Width: 80,
        Attributes: {
            text: {
                red: (o, data) => {
                    if (o && NullOrUndefined(data)) {
                        return data > 30 ? '' : null;
                    }

                    return null;
                },
                orange: (o, data) => {
                    if (o && NullOrUndefined(data)) {
                        return data < 30 ? '' : null;
                    }

                    return null;
                },
                green: (o, data) => {
                    if (o && NullOrUndefined(data)) {
                        return data === 30 ? '' : null;
                    }

                    return null;
                }
            }
        }
    },
    loadTotalWeight: {
        Title: 'Total LBS',
        NoSort: true,
        Display: o => o.isFirst,
        Type: ColumnType.Text,
        Width: 80,
        Attributes: {
            text: {
                red: (o, data) => {
                    if (o && NullOrUndefined(data)) {
                        return data > 42800 ? '' : null;
                    }

                    return null;
                },
                orange: (o, data) => {
                    if (o && NullOrUndefined(data)) {
                        return data < 42800 ? '' : null;
                    }

                    return null;
                },
                green: (o, data) => {
                    if (o && NullOrUndefined(data)) {
                        return data === 42800 ? '' : null;
                    }

                    return null;
                }
            }
        }
    },
    buttonSequencesName: loadSequence(LoadSequence, LoadSequenceAction)
});

export default Columns;