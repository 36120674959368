import { useEffect, useState } from 'react';
import { HubConnectionBuilder } from '@microsoft/signalr';

import store from '../../../store';

export const useClientHub = (onNotificationReceive, event) => {
    const [connection, setConnection] = useState(null);

    useEffect(() => {
        const token = store.getState().auth.userToken;
        const parts = token.split('.');
        let clientId = null;
        if (parts.length > 1) {
            const part = parts[1];
            const jPart = JSON.parse(atob(part));
            const userData = JSON.parse(jPart["http://schemas.microsoft.com/ws/2008/06/identity/claims/userdata"]);
            clientId = userData.CustomerId;
        }
        if (clientId) {
            const newConnection = new HubConnectionBuilder()
                .withUrl(`/NotificationHub?clientId=${clientId}`, {
                    accessTokenFactory: () => store.getState().auth.userToken
                })
                .withAutomaticReconnect()
                .build();

            setConnection(newConnection);
        }
        else {
            console.error('Client HUB Connection not established');
        }
    }, []);

    useEffect(() => {
        let isMounted = true;
        const fetchConnection = async () => {
            if (isMounted && connection) {
                if (connection.connectionStarted) {
                    //await connection.stop();
                    connection.off(event);
                    connection.on(event, message => {
                        if (isMounted) {
                            onNotificationReceive(message);
                        }
                    });
                } else if (connection.connectionState === 'Disconnected') {
                    connection.start()
                        .then(() => {
                            console.log('Client HUB Connected!');
                            connection.on(event, message => {
                                if (isMounted) {
                                    onNotificationReceive(message);
                                }
                            });
                        })
                        .catch(e => console.error('Client HUB Connection failed: ', e));
                }
            }
        }
        fetchConnection();
        return () => {
            isMounted = false;
        };
    }, [connection, onNotificationReceive, event]);

    return null;
};

export const useCacheHub = (onNotificationReceive, event) => {
    const [connection, setConnection] = useState(null);

    useEffect(() => {
        const token = store.getState().auth.userToken;
        const parts = token.split('.');
        let clientId = null;
        if (parts.length > 1) {
            const part = parts[1];
            const jPart = JSON.parse(atob(part));
            const userData = JSON.parse(jPart["http://schemas.microsoft.com/ws/2008/06/identity/claims/userdata"]);
            clientId = userData.UserID;
        }
        if (clientId) {
            const newConnection = new HubConnectionBuilder()
                .withUrl(`/CacheUpdateHub?clientId=${clientId}`, {
                    accessTokenFactory: () => token
                })
                .withAutomaticReconnect()
                .build();

            setConnection(newConnection);
        }
        else {
            console.error('Client HUB Connection not established');
        }
    }, []);

    useEffect(() => {
        let isMounted = true;
        const fetchConnection = async () => {
            if (isMounted && connection?.connectionState === 'Disconnected') {
                if (connection.connectionStarted) {
                    //await connection.stop();
                    connection.off(event);
                    connection.on(event, message => {
                        if (isMounted) {
                            onNotificationReceive(message);
                        }
                    });
                }
                else {
                    connection.start()
                        .then(() => {
                            console.log('Client HUB Connected!');
                            connection.on(event, message => {
                                if (isMounted) {
                                    onNotificationReceive(message);
                                }
                            });
                        })
                        .catch(e => console.error('Client HUB Connection failed: ', e));
                }
            }
        }
        fetchConnection();
        return () => {
            isMounted = false;
        };
    }, [connection, onNotificationReceive, event]);

    return null;
};