export const RELOAD_ON_ERROR_LOAD_DETAILS = 'RELOAD_ON_ERROR_LOAD_DETAILS';
export const RESET_ERROR = 'RESET_ERROR';
export const GET_ORDERS_LIST_BY_ID = 'GET_ORDERS_LIST_BY_ID';
export const GET_ORDERS_LIST_BY_ID_LOADING = 'GET_ORDERS_LIST_BY_ID_LOADING';
export const GET_ORDERS_LIST_BY_ID_ERROR = 'GET_ORDERS_LIST_BY_ID_ERROR';
export const CREATE_NEW_GROUP = 'CREATE_NEW_GROUP';
export const CREATE_NEW_GROUP_LOADING = 'CREATE_NEW_GROUP_LOADING';
export const CREATE_NEW_GROUP_ERROR = 'CREATE_NEW_GROUP_ERROR';
export const SET_BATCH_TYPE = 'SET_BATCH_TYPE';

export const getActionType = (resource, key) => {
    return ORDERS_BUILD[key];
};

export const ORDERS_BUILD = {
    RELOAD_ON_ERROR_LOAD_DETAILS: 'RELOAD_ON_ERROR_LOAD_DETAILS',
    RESET_ERROR: 'RESET_ERROR',
    GET_ORDERS_LIST_BY_ID: 'GET_ORDERS_LIST_BY_ID',
    GET_ORDERS_LIST_BY_ID_LOADING: 'GET_ORDERS_LIST_BY_ID_LOADING',
    GET_ORDERS_LIST_BY_ID_ERROR: 'GET_ORDERS_LIST_BY_ID_ERROR',
    CREATE_NEW_GROUP: 'CREATE_NEW_GROUP',
    CREATE_NEW_GROUP_LOADING: 'CREATE_NEW_GROUP_LOADING',
    CREATE_NEW_GROUP_ERROR: 'CREATE_NEW_GROUP_ERROR',
    SET_BATCH_TYPE: 'SET_BATCH_TYPE',
};
