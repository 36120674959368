import React, { useMemo, useRef, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { VariableSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { Dropdown, Space, message, Menu, Button } from 'antd';
import { orderBy } from 'lodash';
import { resetError, filterDeliveredLoads } from '../../../../store/actions/deliveredLoads/deliveredLoads';
import { load } from '../../../../store/actions/deliveredLoads/deliveredLoads';
import { getContentWidth } from '../../../../store/reducers/deliveredLoads/helper/general';
import { createSeparator } from '../../../../store/reducers/deliveredLoads/helper/separator';
import Row from './row';
import HeaderRow from './headerRow';
import Loading from '../../screen/loading';
import HideShowMenuSwitch from '../../layout/hideShowMenuSwitch';
import { useClientHubOrder } from "../../../hook/hub/useClientHubOrder";

const Spreadsheet = props => {
    const { state, 
            loadsAssignedOrders,
            resetError,
            haseError,
            actionMessage,
            loading,
            load,
            resource, 
            filterDeliveredLoads,
            filterType,
            sortType,
            userId,
        } = props;

    const scrollSizeSmall = 18;
    const scrollSize = 16;
    const defRowHeight = 20;
    const assignedRef = useRef({});

    useClientHubOrder((message) => {
        if (userId !== message.byId) {
            load('deliverdLoads', 'withoutLoading')
        }
    }, 'notification');

    useEffect(() => {
        load('deliverdLoads', '');
    }, [load, resource]);

    useEffect(() => {
        filterDeliveredLoads(resource, filterType);
    }, [filterDeliveredLoads, filterType, resource]);

    useEffect(() => {
        if (haseError) {
        message.error(actionMessage);
        resetError(resource);
        }
    }, [haseError, actionMessage, resetError, resource]);

    const listReference = useRef(null);
    const listHeaderReference = useRef(null);
    const mainScroll = useRef(null);
    const startOnScroll = width => (mainScroll.current.scrollLeft = width);

    const onScrollX = e => {
        if (listHeaderReference.current && listReference.current && e.target) {
        listHeaderReference.current.scrollLeft = listReference.current.scrollLeft = e.target.scrollLeft;
        }
    };

    const header = useMemo(
        () => (
        <div style={{ overflow: 'hidden' }}>
            <div
            style={{
                width: '36px',
                position: 'absolute',
                background: '#449996',
                height: '31px',
                zIndex: '1',
                borderRight: '1px solid #325353',
            }}></div>
            <div
            ref={listHeaderReference}
            style={{
                width: '100%',
                overflow: 'hidden',
            }}>
            <HeaderRow resource={resource} />
            </div>
        </div>
        ),
        [resource],
    );

    const width = getContentWidth(state[resource].headers) + scrollSize;
    const extraScrollVisibility = window.innerWidth > width ? 'none' : 'flex';

    let filteredData = loadsAssignedOrders;
    if (filterType === 'byIn') {
        filteredData = loadsAssignedOrders?.filter(item => item.rowState.loadData.loadType === 1);
    } else if (filterType === 'byOut') {
        filteredData = loadsAssignedOrders?.filter(item => item.rowState.loadData.loadType === 3);
    } else if (filterType === 'all') {
        filteredData = loadsAssignedOrders;
    }
    if (sortType && sortType.sort !== null) {
        let newList = [];
        let newListTwo = [];
        const newArr = [...filteredData];
        newArr.forEach((item, index) => {
        if (item.rowState.type === 'load') {
            newListTwo = orderBy(newListTwo, [c => c.row[sortType.ind].value], sortType.sort);
            newList = [...newList, ...newListTwo, item];
            newListTwo.length = 0;
        } else if (index === newArr.length - 1) {
            newListTwo.push(item);
            newListTwo = orderBy(newListTwo, [c => c.row[sortType.ind].value], sortType.sort);
            newList = [...newList, ...newListTwo];
        } else {
            newListTwo.push(item);
        }
        });
        filteredData = newList;
        // filteredData = orderBy(filteredData, [c => c.row[sortType.ind].value], sortType.sort);
    }
    const displayLoadsAssignedOrders = useMemo(
        () => (!filteredData || filteredData.length === 0 ? [createSeparator(defRowHeight, state[resource].headers, 'load', 0)] : filteredData),
        [filteredData, state, resource],
    );

    const myLoadsAssignedOrders = useMemo(() => displayLoadsAssignedOrders.filter(d => d.rowState.visible), [displayLoadsAssignedOrders]);

    const getAsignedItemSize = useCallback(
        index => (myLoadsAssignedOrders[index] ? myLoadsAssignedOrders[index].rowState.height : defRowHeight),
        [myLoadsAssignedOrders],
    );

    const RenderAssignedRow = useCallback(
        ({ data, index, style }) =>
        index !== undefined && data[index] ? (
            <Row
            listRef={assignedRef}
            data={data[index]}
            itemSize={getAsignedItemSize(index)}
            style={style}
            dndRowIndex={index}
            startOnScroll={startOnScroll}
            resource={resource}
            />
        ) : null,
        [getAsignedItemSize, resource],
    );

    const allOrdersList = useMemo(
        () =>
        myLoadsAssignedOrders && (
            <AutoSizer disableWidth>
            {({ height }) => (
                <>
                <VariableSizeList
                    ref={assignedRef}
                    outerRef={el => {
                    listReference.current = el;
                    }}
                    style={{ overflowX: 'hidden', overflowY: 'scroll' }}
                    itemData={myLoadsAssignedOrders}
                    itemCount={myLoadsAssignedOrders.length}
                    height={height}
                    itemSize={getAsignedItemSize}>
                    {RenderAssignedRow}
                </VariableSizeList>
                </>
            )}
            </AutoSizer>
        ),
        [myLoadsAssignedOrders, getAsignedItemSize, RenderAssignedRow],
    );

    const content = (
        <>
        <div style={{ display: 'flex' }}>
            {header}
            <div
            style={{
                width: `${scrollSizeSmall}px`,
                maxWidth: `${scrollSizeSmall}px`,
                minWidth: `${scrollSizeSmall}px`,
                height: '32px',
                backgroundColor: 'lightgrey',
                display: extraScrollVisibility,
            }}
            />
        </div>
        <div style={{ flexGrow: 1, width: 'calc(100%)' }}>{allOrdersList}</div>
        <div
            style={{
            backgroundColor: 'red',
            width: '100%',
            height: `${scrollSize}px`,
            overflowX: 'scroll',
            flexShrink: 0,
            }}
            ref={mainScroll}
            onScroll={e => onScrollX(e)}>
            <div className='resp-table-row'>
            <div
                className='table-body-cell'
                style={{
                maxWidth: `${width}px`,
                width: `${width}px`,
                minWidth: `${width}px`,
                boxSizing: 'border-box',
                backgroundColor: 'black',
                }}
            />
            </div>
        </div>
        </>
    );

    const handleSelectChange = e => {
        filterDeliveredLoads(resource, e.key);
    };

    const menu = (
        <Menu className='customer-order__menu callBack-filter__menu' onClick={handleSelectChange}>
        <Menu.Item key='all'>All</Menu.Item>
        <Menu.Item key='byIn'>IN</Menu.Item>
        <Menu.Item key='byOut'>OUT</Menu.Item>
        </Menu>
    );

    const refreshPage = () => {
        load('deliverdLoads');
    };

    const subHeader = (
        <div
        style={{
            display: 'flex',
            justifyContent: 'space-between',
            background: '#F2F2F2',
            height: '46px',
        }}>
        <div className='customer-order__upper-header__sort'>
            <div className='filter_call sort_icon'></div>
                <Dropdown className='customer-order__dropdown' overlay={menu} placement='bottomLeft'>
                    <span onClick={e => e.preventDefault()}>
                        <Space>Filter</Space>
                    </span>
                </Dropdown>
                <Button
                    onClick={refreshPage}
                    style={{
                        height: '22px',
                        width: '81px',
                        borderRadius: '12px',
                        borderColor: '#3D5252',
                        marginTop: '10px',
                        padding: '0',
                    }}>Refresh
                </Button>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <HideShowMenuSwitch />
            </div>
        </div>
    );
    return (<> 
                {loading ? 
                    (<Loading />) : (
                    <>{subHeader}{content}</>
                )}
            </>);
    };

    const mapStateToProps = state => ({
        state: state,
        loadsAssignedOrders: state.deliveredLoads.loadsAssignedOrders && state.deliveredLoads.loadsAssignedOrders.filter(item => item.rowState.type !== 'stop'),
        loading: state.deliveredLoads.loading,
        haseError: state.deliveredLoads.hasError,
        actionMessage: state.deliveredLoads.message,
        filterType: state.deliveredLoads.filterType,
        sortType: state.deliveredLoads.sortType,
        userId: state.auth.userId,
    });

    const mapDispatchToProps = dispatch => ({
        load: (resource, loadingType) => dispatch(load(resource, loadingType)),
        resetError: (resource, method) => dispatch(resetError(resource, false, method)),
        filterDeliveredLoads: (resource, key) => dispatch(filterDeliveredLoads(resource, key)),
    });

    export default connect(mapStateToProps, mapDispatchToProps)(Spreadsheet);
