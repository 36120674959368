import React from 'react';
import ColorPickerExt from 'rc-color-picker';

import 'rc-color-picker/assets/index.css';

const ColorPicker = props => {

    //const [displayColorPicker, setDisplayColorPicker] = useState(false);

    //const handleClick = () => {
    //    setDisplayColorPicker(!displayColorPicker);
    //};

    //const handleClose = () => {
    //    setDisplayColorPicker(false);
    //};

    


    return <div colorPicker='' style={{ margin: '20px 20px 20px', height: '500px', textAlign: 'center' }}>
        <ColorPickerExt color={'#F10'} placement="topRight" style={{ height: '500px' }} onClose={() => { debugger;  return false; }} />
        </div>;
};

export default ColorPicker;