import {
    ElementTypes,
    ButtonActionTypes
} from '../../config';
import CustomerForm from './customerFormConfig';
import LocationForm from './locationFormConfig';
import WarehouseForm from './warehouseFormConfig';

export const InboundLoadingSequenceFields = {
    Group1: [
        {
            useSeparator: false,
            Fields: {
                loadNumber: {
                    Title: 'Load Number',
                    Field: ElementTypes.LabelField()
                },
                customerNameCode: {
                    Title: 'Customer',
                    Field: ElementTypes.Button({
                        Action: 'OpenForm',
                        PopupConfig: {
                            Key: 'customerId',
                            Form: CustomerForm,
                            Template: ButtonActionTypes.FormField
                        },
                    })
                }
            }
        }
    ],
    Group2: [
        {
            useSeparator: false,
            Fields: {
                pickLocationId: {
                    Title: 'Pickup',
                    Field: ElementTypes.Button({
                        Action: 'OpenForm',
                        PopupConfig: {
                            Key: 'pickLocationId',
                            Form: LocationForm,
                            Template: ButtonActionTypes.FormField,
                            Display: o => `${o.pickCity}, ${o.pickState}${o.pickLocationName ? `, ${o.pickLocationName}` : ''}`
                        },
                    })
                },
                dropLocationId: {
                    Title: 'Drop',
                    Field: ElementTypes.Button({
                        Action: 'OpenForm',
                        PopupConfig: {
                            Key: o => o.warehouseId ? o.warehouseId : o.dropLocationId,
                            Form: o => o.warehouseId ? WarehouseForm : LocationForm,
                            Template: ButtonActionTypes.FormField,
                            Display: (o, d) => {
                                if (o.warehouseId) {
                                    return `${o.drop1City}, ${o.drop1State}${o.drop1WH ? `, ${o.drop1WH}` : ''}`;
                                } else {
                                    return `${o.drop2City}, ${o.drop2State}${o.drop2WH ? `, ${o.drop2WH}` : ''}`;
                                }
                            }
                        },
                    })
                }
            }
        }
    ]
};

export const OutboundLoadingSequenceFields = {
    Group1: [
        {
            useSeparator: false,
            Fields: {
                loadNumber: {
                    Title: 'Load Number',
                    Field: ElementTypes.LabelField()
                },
                customerNameCode: {
                    Title: 'Customer',
                    Field: ElementTypes.Button({
                        Action: 'OpenForm',
                        PopupConfig: {
                            Key: 'customerId',
                            Form: CustomerForm,
                            Template: ButtonActionTypes.FormField
                        },
                    })
                }
            }
        }
    ],
    Group2: [
        {
            useSeparator: false,
            Fields: {
                pickLocationId: {
                    Title: 'Pickup',
                    Field: ElementTypes.Button({
                        Action: 'OpenForm',
                        PopupConfig: {
                            Key: 'warehouseId',
                            Form: WarehouseForm,
                            Template: ButtonActionTypes.FormField,
                            Display: o => `${o.pickCity}, ${o.pickState}${o.pickLocationName ? `, ${o.pickLocationName}` : ''}`
                        },
                    })
                },
                dropLocationId: {
                    Title: 'Drop',
                    Field: ElementTypes.Button({
                        Action: 'OpenForm',
                        PopupConfig: {
                            Key: 'dropLocationId',
                            Form: LocationForm,
                            Template: ButtonActionTypes.FormField,
                            Display: (o, d) => {
                                return `${o.drop2City}, ${o.drop2State}${o.drop2WH ? `, ${o.drop2WH}` : ''}`;
                            }
                        },
                    })
                }
            }
        }
    ]
};