import React from 'react';
import Modal from 'react-modal';

const EditWarningModal = ({ 
    errorModalText,
    setErrorModalText,
    message,
    resetError,
    resource,
    orderAdded,
    hasError,
    setIsOpen, 
    setButtonDisabled,
  }) => {

  const closeModal = () => {
    hasError && !orderAdded && setIsOpen(true);
    setErrorModalText('');
    resetError && resetError(resource);
    setButtonDisabled && setButtonDisabled(false);
  };

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      minWidth: '400px',
      minHeight: '200px',      
      borderRadius: '8px',
      padding: '6px 12px',
    },
  };

  return (
    <Modal
        isOpen={errorModalText && true}
        onRequestClose={closeModal}
        style={customStyles}
        ariaHideApp={false}
        >
        <div className='edit_modal__container'>
          <div className='edit_modal_text'>{message}</div>
          <div>
            <button className='edit-modal-button' type="submit" onClick={closeModal}>Close</button>
          </div>
        </div>  
    </Modal>
  );
};

export default EditWarningModal;