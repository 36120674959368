import React from 'react';
import { Tooltip } from 'antd';
import { CommonLabels } from '../../../project/Defines';

const CreateGroupButton = props => (<React.Fragment>
    {
        <Tooltip title={props.createGroupButtonText || CommonLabels.Buttons.CreateGroup}>
            <div className='button primary' onClick={props.onCreateGroupClick}>
                <text>{props.createGroupButtonText || CommonLabels.Buttons.CreateGroup}</text>
            </div>
        </Tooltip>
    }
</React.Fragment>);

export default CreateGroupButton;
