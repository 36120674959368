import React from 'react';
import { connect } from "react-redux";

const CurrentAndNextCell = (
    {
        state,
        cellData,
        itemSize,
        resource
    }) => {
    const traffic = state[resource];
    const width = traffic.currentAndNextHeaders.find(h => h.key === cellData.key).width(traffic.headers);

    let cellStyle = {
        maxWidth: `${width}px`,
        width: `${width}px`,
        minWidth: `${width}px`,
        height: `${itemSize}px`,
        backgroundColor: '#ffffff'
    };

    return (
        <div
            className='table-body-cell subHeaderTitle'
            style={cellStyle}>
        </div>
    );
};
export default connect(state => ({
    state: state
}))(CurrentAndNextCell);