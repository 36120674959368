import React from 'react';

const TableStructure = () => (
    <>
        <view intro="">
            <view style={{ minWidth: "2300px" }}>
                <view auto_height="">
                    <div class="table_view extra header">
                        <table>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th></th>
                                    <th class="sheet-table-header react-resizable"><wrap sort=""><text>P/U C</text><div sort=""><span><icon>up</icon></span><span><icon>down</icon></span></div></wrap><span class="react-resizable-handle"></span></th>
                                    <th class="sheet-table-header react-resizable"><wrap sort=""><text>PO#</text><div sort=""><span><icon>up</icon></span><span><icon>down</icon></span></div></wrap><span class="react-resizable-handle"></span></th>
                                    <th class="sheet-table-header react-resizable"><wrap sort=""><text>Row</text><div sort=""><span><icon>up</icon></span><span><icon>down</icon></span></div></wrap><span class="react-resizable-handle"></span></th>
                                    <th class="sheet-table-header react-resizable"><wrap sort=""><text>LBS</text><div sort=""><span><icon>up</icon></span><span><icon>down</icon></span></div></wrap><span class="react-resizable-handle"></span></th>
                                    <th class="sheet-table-header react-resizable"><wrap sort=""><text>ORDER CYCLE</text><div sort=""><span><icon>up</icon></span><span><icon>down</icon></span></div></wrap><span class="react-resizable-handle"></span></th>
                                    <th class="sheet-table-header react-resizable"><wrap sort=""><text>Drop City</text><div sort=""><span><icon>up</icon></span><span><icon>down</icon></span></div></wrap><span class="react-resizable-handle"></span></th>
                                    <th class="sheet-table-header react-resizable"><wrap sort=""><text>READY</text><div sort=""><span><icon>up</icon></span><span><icon>down</icon></span></div></wrap><span class="react-resizable-handle"></span></th>
                                    <th class="sheet-table-header react-resizable"><wrap sort=""><text></text><div sort=""><span><icon>up</icon></span><span><icon>down</icon></span></div></wrap><span class="react-resizable-handle"></span></th>
                                    <th class="sheet-table-header react-resizable"><wrap sort=""><text>B</text><div sort=""><span><icon>up</icon></span><span><icon>down</icon></span></div></wrap><span class="react-resizable-handle"></span></th>
                                    <th class="sheet-table-header react-resizable"><wrap sort=""><text>Drop ST</text><div sort=""><span><icon>up</icon></span><span><icon>down</icon></span></div></wrap><span class="react-resizable-handle"></span></th>
                                    <th class="sheet-table-header react-resizable"><wrap sort=""><text>CUS</text><div sort=""><span class="select"><icon>up</icon></span><span><icon>down</icon></span></div></wrap><span class="react-resizable-handle"></span></th>
                                    <th class="sheet-table-header react-resizable"><wrap sort=""><text>P/U WH</text><div sort=""><span><icon>up</icon></span><span><icon>down</icon></span></div></wrap><span class="react-resizable-handle"></span></th>
                                    <th class="sheet-table-header react-resizable"><wrap sort=""><text>P/U ST</text><div sort=""><span><icon>up</icon></span><span><icon>down</icon></span></div></wrap><span class="react-resizable-handle"></span></th>
                                    <th class="sheet-table-header react-resizable"><wrap sort=""><text>TRF WH</text><div sort=""><span><icon>up</icon></span><span><icon>down</icon></span></div></wrap><span class="react-resizable-handle"></span></th>
                                    <th class="sheet-table-header react-resizable"><wrap sort=""><text>DEL CITY</text><div sort=""><span><icon>up</icon></span><span><icon>down</icon></span></div></wrap><span class="react-resizable-handle"></span></th>
                                    <th class="sheet-table-header react-resizable"><wrap sort=""><text>DEL ST</text><div sort=""><span><icon>up</icon></span><span><icon>down</icon></span></div></wrap><span class="react-resizable-handle"></span></th>
                                    <th class="sheet-table-header react-resizable"><wrap sort=""><text>DEL WH</text><div sort=""><span><icon>up</icon></span><span><icon>down</icon></span></div></wrap><span class="react-resizable-handle"></span></th>
                                    <th class="sheet-table-header react-resizable"><wrap sort=""><text>DUE</text><div sort=""><span><icon>up</icon></span><span><icon>down</icon></span></div></wrap><span class="react-resizable-handle"></span></th>
                                    <th class="sheet-table-header react-resizable"><wrap sort=""><text>SO#</text><div sort=""><span><icon>up</icon></span><span><icon>down</icon></span></div></wrap><span class="react-resizable-handle"></span></th>
                                    <th class="sheet-table-header react-resizable"><wrap sort=""><text>PAL</text><div sort=""><span><icon>up</icon></span><span><icon>down</icon></span></div></wrap><span class="react-resizable-handle"></span></th>
                                    <th class="sheet-table-header react-resizable"><wrap sort=""><text>SPA</text><div sort=""><span><icon>up</icon></span><span><icon>down</icon></span></div></wrap><span class="react-resizable-handle"></span></th>
                                    <th class="sheet-table-header react-resizable"><wrap sort=""><text>CS</text><div sort=""><span><icon>up</icon></span><span><icon>down</icon></span></div></wrap><span class="react-resizable-handle"></span></th>
                                    <th></th>
                                    <th action="">Actions</th></tr>
                            </thead>
                            </table>
                    </div>
                </view>
                <view>
                    <div class="split split-vertical">
                        <div class="pane" style={{ flexBasis: "30%" }}>
                            <view light="">
                                <div class="table_view extra">
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td><wrap left=""><text class=""></text></wrap></td>
                                                <td><wrap left=""><text class="">77777</text></wrap></td>
                                                <td><wrap></wrap></td>
                                                <td><wrap left=""><text class="">0110</text></wrap></td>
                                                <td><wrap left=""><text class="">CA</text></wrap></td>
                                                <td><wrap left=""><text class=""></text></wrap></td>
                                                <td><wrap left=""><text class="">77777</text></wrap></td>
                                                <td><wrap></wrap></td>
                                                <td><wrap left=""><text class="">0110</text></wrap></td>
                                                <td><wrap left=""><text class="">CA</text></wrap></td>
                                                <td><wrap left=""><text class=""></text></wrap></td>
                                                <td><wrap left=""><text class="">77777</text></wrap></td>
                                                <td><wrap></wrap></td>
                                                <td><wrap left=""><text class="">0110</text></wrap></td>
                                                <td><wrap left=""><text class="">CA</text></wrap></td>
                                                <td><wrap left=""><text class=""></text></wrap></td>
                                                <td><wrap left=""><text class="">77777</text></wrap></td>
                                                <td><wrap></wrap></td>
                                                <td><wrap left=""><text class="">0110</text></wrap></td>
                                                <td><wrap left=""><text class="">CA</text></wrap></td>
                                                <td><wrap left=""><text class=""></text></wrap></td>
                                                <td><wrap left=""><text class="">77777</text></wrap></td>
                                                <td><wrap></wrap></td>
                                                <td><wrap left=""><text class="">0110</text></wrap></td>
                                                <td><wrap left=""><text class="">CA</text></wrap></td>
                                                <td><elastic><action><div command="Edit" class="button micro"><icon edit="">Edit</icon></div></action></elastic></td>

                                            </tr>
                                            <tr><td colspan=""><wrap><text>before</text></wrap></td><td colspan="24"><wrap></wrap></td><td colspan=""><wrap><text>after</text></wrap></td></tr>
                                            <tr><td colspan=""><wrap><text>before</text></wrap></td><td colspan="24"><wrap></wrap></td><td colspan=""><wrap><text>after</text></wrap></td></tr>
                                            <tr><td colspan=""><wrap><text>before</text></wrap></td><td colspan="24"><wrap></wrap></td><td colspan=""><wrap><text>after</text></wrap></td></tr>
                                            <tr><td colspan=""><wrap><text>before</text></wrap></td><td colspan="24"><wrap></wrap></td><td colspan=""><wrap><text>after</text></wrap></td></tr>
                                            <tr><td colspan=""><wrap><text>before</text></wrap></td><td colspan="24"><wrap></wrap></td><td colspan=""><wrap><text>after</text></wrap></td></tr>
                                            <tr><td colspan=""><wrap><text>before</text></wrap></td><td colspan="24"><wrap></wrap></td><td colspan=""><wrap><text>after</text></wrap></td></tr>
                                            <tr><td colspan=""><wrap><text>before</text></wrap></td><td colspan="24"><wrap></wrap></td><td colspan=""><wrap><text>after</text></wrap></td></tr>
                                            <tr><td colspan=""><wrap><text>before</text></wrap></td><td colspan="24"><wrap></wrap></td><td colspan=""><wrap><text>after</text></wrap></td></tr>
                                            <tr><td colspan=""><wrap><text>before</text></wrap></td><td colspan="24"><wrap></wrap></td><td colspan=""><wrap><text>after</text></wrap></td></tr>
                                            <tr><td colspan=""><wrap><text>before</text></wrap></td><td colspan="24"><wrap></wrap></td><td colspan=""><wrap><text>after</text></wrap></td></tr>
                                            <tr><td colspan=""><wrap><text>before</text></wrap></td><td colspan="24"><wrap></wrap></td><td colspan=""><wrap><text>after</text></wrap></td></tr>
                                            <tr><td colspan=""><wrap><text>before</text></wrap></td><td colspan="24"><wrap></wrap></td><td colspan=""><wrap><text>after</text></wrap></td></tr>
                                            <tr><td colspan=""><wrap><text>before</text></wrap></td><td colspan="24"><wrap></wrap></td><td colspan=""><wrap><text>after</text></wrap></td></tr>
                                        </tbody>
                                    </table>
                                </div>
                            </view>
                        </div>

                        <div class="gutter gutter-vertical " style={{ flexBasis: "5px" }}></div>
                        <div class="pane" style={{ flexBasis: "70%" }}>
                            <view light="">
                                <div class="table_view extra">
                                    <toolbar>
                                        <wrap>
                                            <action>
                                                <div class="form_fields line"><div class="data_label">Sample Title</div></div>
                                            </action>
                                        </wrap>
                                    </toolbar>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td><wrap left=""><text class=""></text></wrap></td>
                                                <td><wrap left=""><text class="">77777</text></wrap></td>
                                                <td><wrap></wrap></td>
                                                <td><wrap left=""><text class="">0110</text></wrap></td>
                                                <td><wrap left=""><text class="">CA</text></wrap></td>
                                                <td><wrap left=""><text class=""></text></wrap></td>
                                                <td><wrap left=""><text class="">77777</text></wrap></td>
                                                <td><wrap></wrap></td>
                                                <td><wrap left=""><text class="">0110</text></wrap></td>
                                                <td><wrap left=""><text class="">CA</text></wrap></td>
                                                <td><wrap left=""><text class=""></text></wrap></td>
                                                <td><wrap left=""><text class="">77777</text></wrap></td>
                                                <td><wrap></wrap></td>
                                                <td><wrap left=""><text class="">0110</text></wrap></td>
                                                <td><wrap left=""><text class="">CA</text></wrap></td>
                                                <td><wrap left=""><text class=""></text></wrap></td>
                                                <td><wrap left=""><text class="">77777</text></wrap></td>
                                                <td><wrap></wrap></td>
                                                <td><wrap left=""><text class="">0110</text></wrap></td>
                                                <td><wrap left=""><text class="">CA</text></wrap></td>
                                                <td><wrap left=""><text class=""></text></wrap></td>
                                                <td><wrap left=""><text class="">77777</text></wrap></td>
                                                <td><wrap></wrap></td>
                                                <td><wrap left=""><text class="">0110</text></wrap></td>
                                                <td><wrap left=""><text class="">CA</text></wrap></td>
                                                <td><elastic><action><div command="Edit" class="button micro"><icon edit="">Edit</icon></div></action></elastic></td>
                                            </tr>

                                            <tr><td colspan=""><wrap><text>before</text></wrap></td><td colspan="24"><wrap></wrap></td><td colspan=""><wrap><text>after</text></wrap></td></tr>
                                            <tr><td colspan=""><wrap><text>before</text></wrap></td><td colspan="24"><wrap></wrap></td><td colspan=""><wrap><text>after</text></wrap></td></tr>
                                            <tr><td colspan=""><wrap><text>before</text></wrap></td><td colspan="24"><wrap></wrap></td><td colspan=""><wrap><text>after</text></wrap></td></tr>
                                        </tbody>
                                    </table>
                                    <toolbar details="details">
                                        <wrap>
                                            <action>
                                                <div class="form_fields descriptionn sort">
                                                    <div class="data_label">Sample Title</div>
                                                    <div class="data_help">Configure settings when and how You'll get notified for content changes</div>
                                                </div>
                                            </action>
                                        </wrap>
                                    </toolbar>
                                    <table>
                                        <tbody>
                                            <tr><td colspan=""><wrap><text>before</text></wrap></td><td colspan="24"><wrap></wrap></td><td colspan=""><wrap><text>after</text></wrap></td></tr>
                                            <tr><td colspan=""><wrap><text>before</text></wrap></td><td colspan="24"><wrap></wrap></td><td colspan=""><wrap><text>after</text></wrap></td></tr>
                                            <tr><td colspan=""><wrap><text>before</text></wrap></td><td colspan="24"><wrap></wrap></td><td colspan=""><wrap><text>after</text></wrap></td></tr>
                                            <tr><td colspan=""><wrap><text>before</text></wrap></td><td colspan="24"><wrap></wrap></td><td colspan=""><wrap><text>after</text></wrap></td></tr>
                                        </tbody>
                                    </table>
                                    <toolbar scroll="scroll">
                                        <wrap>
                                            <action>
                                                <div class="button mini"><icon>plus</icon></div>
                                                <separator vertical=""></separator>
                                                <div class="button mini "><icon>copy</icon></div>
                                                <button disabled="" class="button mini"><icon>Paste</icon></button>
                                                <separator vertical=""></separator>
                                                <div class="button mini " data-tip="Remove Selected Item"><icon>delete</icon></div>
                                                <separator vertical=""></separator>
                                                <div class="button primary adaptive"><icon>save</icon><text>Save Changes</text></div>
                                                <separator vertical=""></separator>
                                                <div class="button "><text>Delete Overlay</text></div>
                                            </action>
                                        </wrap>
                                    </toolbar>
                                    <table>
                                        <tbody>
                                            <tr><td colspan=""><wrap><text>before</text></wrap></td><td colspan="24"><wrap></wrap></td><td colspan=""><wrap><text>after</text></wrap></td></tr>
                                            <tr><td colspan=""><wrap><text>before</text></wrap></td><td colspan="24"><wrap></wrap></td><td colspan=""><wrap><text>after</text></wrap></td></tr>
                                            <tr><td colspan=""><wrap><text>before</text></wrap></td><td colspan="24"><wrap></wrap></td><td colspan=""><wrap><text>after</text></wrap></td></tr>
                                            <tr><td colspan=""><wrap><text>before</text></wrap></td><td colspan="24"><wrap></wrap></td><td colspan=""><wrap><text>after</text></wrap></td></tr>
                                        </tbody>
                                    </table>
                                    <toolbar scroll="scroll">
                                        <wrap>
                                            <action>
                                                <wrap>
                                                    <div className="form_fields line">
                                                        <div className="data_label">Feed URL</div>
                                                        <input name="FeedURL" type="text" setvalue="" />
                                                    </div>
                                                    <separator vertical=""></separator>
                                                    <div className="form_fields line small">
                                                        <div className="data_label">Durations<tip>sec</tip></div>
                                                        <input name="Duration" type="text" />
                                                    </div>
                                                </wrap>
                                            </action>
                                        </wrap>
                                    </toolbar>
                                    <table>
                                        <tbody>
                                            <tr><td colspan=""><wrap><text>before</text></wrap></td><td colspan="24"><wrap></wrap></td><td colspan=""><wrap><text>after</text></wrap></td></tr>
                                            <tr><td colspan=""><wrap><text>before</text></wrap></td><td colspan="24"><wrap></wrap></td><td colspan=""><wrap><text>after</text></wrap></td></tr>
                                            <tr><td colspan=""><wrap><text>before</text></wrap></td><td colspan="24"><wrap></wrap></td><td colspan=""><wrap><text>after</text></wrap></td></tr>
                                            <tr><td colspan=""><wrap><text>before</text></wrap></td><td colspan="24"><wrap></wrap></td><td colspan=""><wrap><text>after</text></wrap></td></tr>
                                        </tbody>
                                    </table>
                                </div>
                            </view>
                        </div>
                    </div>
                </view>
            </view>
        </view>
    </>
);

export default TableStructure;