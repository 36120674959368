import { CALL_CHECK_BUILD as actionTypes } from '../../actions/callCheck/callCheckActionTypes';
import * as loadArea from './callCheckReducer';

const defaultState = {
    headers: [
        {
            key: 'left_action',
            title: '',
            width: 36,
            leftAction: true,
            visible: true
        },
        {
            key: 'inboundDeliveryEstimate',
            title: 'Del Est Time',
            width: 110,
            leftAction: false,
            visible: true,
        },
        {
            key: 'inboundPickAppointment',
            title: 'Pick Appt',
            width: 110,
            leftAction: false,
            visible: true,
            dropGroup: true
        },
        {
            key: 'pickInDate',
            title: 'Pick IN',
            width: 110,
            leftAction: false,
            visible: true,
            pickGroup: true
        },
        {
            key: 'pickOutDate',
            title: 'Pick OUT',
            width: 110,
            leftAction: false,
            visible: true,
            pickGroup: true
        },
        {
            key: 'dueDate',
            title: 'Due Date',
            width: 110,
            leftAction: false,
            visible: true,
        },
        {
            key: 'inDelAppt',
            title: 'In Del Appt',
            width: 110,
            leftAction: false,
            visible: true,
        },
        {
            key: 'outDelAppt',
            title: 'Out Del Appt',
            width: 110,
            leftAction: false,
            visible: true,
        },
        {
            key: 'dropInDate',
            title: 'Del IN',
            width: 110,
            leftAction: false,
            visible: true,
            pickGroup: true
        },
        {
            key: 'dropOutDate',
            title: 'Del Out',
            width: 110,
            leftAction: false,
            visible: true,
            pickGroup: true
        },
        {
            key: 'deliverDate',
            title: 'D/L Date',
            width: 60,
            leftAction: false,
            visible: true,
            pickGroup: true
        },
        {
            key: 'puDate',
            title: 'P/U Date',
            width: 60,
            leftAction: false,
            visible: true,
        },
        {
            key: 'warehouse',
            title: 'Warehouse',
            width: 183,
            leftAction: false,
            visible: true,
            dropGroup: true
        },
        {
            key: 'customerName',
            title: 'Cust.',
            width: 50,
            leftAction: false,
            visible: true
        },
        {
            key: 'poNumber',
            title: 'PO#',
            width: 90,
            leftAction: false,
            visible: true
        },
        {
            key: 'soNumber',
            title: 'SO#',
            width: 60,
            leftAction: false,
            visible: true
        },
        {
            key: 'palletCount',
            title: 'Pal',
            width: 50,
            leftAction: false,
            visible: true
        },
        {
            key: 'cases',
            title: 'CS',
            width: 50,
            leftAction: false,
            visible: true
        },
        {
            key: 'weight',
            title: 'LBS',
            width: 60,
            leftAction: false,
            visible: true
        },
        {
            key: 'loadPositionType',
            title: 'Sequence',
            width: 80,
            leftAction: false,
            visible: true
        },
        {
            key: 'loadNumber',
            title: 'Load #',
            width: 120,
            leftAction: false,
            visible: true
        },
        {
            key: 'carrierName',
            title: 'Carrier',
            width: 98,
            leftAction: false,
            visible: true
        },
        {
            key: 'driverName',
            title: 'Driver',
            width: 70,
            leftAction: false,
            visible: true
        },
        {
            key: 'driverPhone',
            title: 'Driver Phone',
            width: 105,
            leftAction: false,
            visible: true
        },
        {
            key: 'loadCallCheckNote',
            title: 'Notes',
            width: 100,
            leftAction: false,
            visible: true
        },
        {
            key: 'lumper_efs',
            title: 'Lumper',
            width: 60,
            leftAction: false,
            visible: true
        },
        {
            key: 'orderStatusByCall',
            title: 'Status',
            width: 77,
            leftAction: false,
            visible: true
        },
        {
            key: 'delayedReason',
            title: 'Delayed reason',
            width: 110,
            leftAction: false,
            visible: true
        },
        {
            key: 'podRequestStatus',
            title: 'POD Request',
            width: 95,
            leftAction: false,
            visible: true
        },
        {
            key: 'podDoc',
            title: 'POD Doc.',
            width: 100,
            leftAction: false,
            visible: true
        },
        {
            key: 'orderNotesByCall',
            title: 'POD Status',
            width: 100,
            leftAction: false,
            visible: true
        },
        {
            key: 'actions',
            title: 'Action',
            width: 60,
            leftAction: false,
            visible: true
        },
        {
            key: 'delivered',
            title: '',
            width: 60,
            leftAction: false,
            visible: true
        },
    ],
    lastSelected: {
        id: null,
        headerKey: null,
        enable: false
    },
    orders: null,
    loads: null,
    loadsAssignedOrders: null,
    loading: true,
    hasError: false,
    message: '',
    filterType: '',
    podStatus: '',
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case actionTypes.GET_CALL_CHECK:
            return loadArea.loadContent(state, action.data);
        case actionTypes.CALL_CHECK_ERROR:
            return loadArea.setError(state, action.data);
        case actionTypes.RESET_ERROR:
            return loadArea.resetError(state);
        case actionTypes.FILTER_CALL_CHECKS:
            return loadArea.filterCallChecks(state, action.data);
        case actionTypes.GET_CALL_CHECK_LOADING:
            return loadArea.setInLoading(state);
        case actionTypes.RELOAD_ON_ERROR_CALL_CHECK:
            return loadArea.loadContent(state, action.data.responce, true, action.data.errorText ? action.data.errorText : 'Something go wrong, data reloaded!');
        case actionTypes.SET_HEADER_WIDTH_CALL_CHECK:
            return loadArea.setHeaderWidth(state, action.data.ind, action.data.width);
        case actionTypes.SET_DISPLAY_STATE:
            return loadArea.setDisplayState(state, action.data.rowState, action.data.header, action.data.enable);
        case actionTypes.REACTED_ASSIGNED:
            return loadArea.reactedAssigned(state, action.data.type, action.data.id);
        case actionTypes.SET_CALL_CHECK_DATA:
            return loadArea.setData(state, action.data.value, action.data.rowState, action.data.header);
        case actionTypes.GET_HISTORY_CALL_CHECK:
            return loadArea.getHistoryListCallCheck(state, action.data);
        case actionTypes.GET_HISTORY_CALL_CHECK_LOADING:
            return loadArea.setHistoryLoadingCallCheck(state, action.data);
        case actionTypes.FILTER_BY_POD_STATUS:
            return loadArea.filterByPODStatus(state, action.data);
        default:
            return state;
    }
};