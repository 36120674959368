import moment from 'moment';
import { createSeparator } from '../separator';
import { getEfsStatusTooltip, getEfsBGColor } from '../dataGeneral';

export const itemDefaultSize = 18;
const regionGroupField = 'pickRegionCode';
const RegionsList = [ 'NORTHEAST', 'SOUTHEAST', 'MIDWEST', 'MOUNTAIN', 'NORTHWEST', 'ARIZONA', 'CALIFORNIA' ];

export const extractWebLoadsToList = (loads, headers, doNotrenderSeparator, renderLastSep) => {
    let loadsAssignedOrders = [];
    loads.forEach((loadItem, i) => {
        loadsAssignedOrders.push(loadItem);
        loadItem.ordersData.forEach(order => {
            loadsAssignedOrders.push(order);
        });
        loadsAssignedOrders = [...loadsAssignedOrders, ...extractWebLoadsToList(loadItem.subLoads, headers, true, false)];
        //if (!doNotrenderSeparator) {
            //loadsAssignedOrders.push(createSeparator(itemDefaultSize, headers, 'load', i, loadItem.rowState.id));
        //}
    });
    //if (renderLastSep) {
    //    loadsAssignedOrders.push(createSeparator(itemDefaultSize, headers, 'load', '-1', '-1'));
    //}
    return loadsAssignedOrders;
};

export const createSubOrder = (so, originalOrder, childSubOrder) => ({
    ...originalOrder,
    ...{
        parentId: so.parentId,
        subOrderId: so.id,
        loadId: so.loadId,
        originalOrderId: so.orderId,
        pickLocationId: so.dropLocationId,
        pickLocation: so.dropLocation,
        dropLocationId: childSubOrder ? childSubOrder.dropLocationId : originalOrder.dropLocationId,
        dropLocation: childSubOrder ? childSubOrder.dropLocation : originalOrder.dropLocation,
        inboundPickIn: so.pickInDate,
        inboundPickOut: so.pickOutDate,
        inboundPickAppointment: so.pickAppointmentDate,
        inboundAppointmentStatus: so.pickAppointmentStatus,
        puDate: so.puDate,
        dueDate: so.dueDate,
        deliverDate: so.deliverDate,
        inboundDropAppointment: so.dropAppointmentDate,
        inboundDropIn: so.dropInDate,
        inboundDropOut: so.dropOutDate,
        inboundLumper: so.lumper,
        dropRestriction: so.dropRestriction,
        pickRestriction: so.pickRestriction,
        stepCount: so.stepCount,
        carrierName: so.carrierName,
        palletCount: so.palletCount,
        cases: so.cases,
        space: so.space,
        weight: so.weight,
        poNumber: so.poNumber,
    }
});

export const createWebLoadArray = (loadIdField, loadsData, assignedOrders, subOrders, headers, oldLoadsAssignedOrders) => {
    //create sub orders
    const subOrdersData = [];
    subOrders.forEach(so => {
        subOrdersData.push(createSubOrder(so, assignedOrders.find(o => o.id === so.orderId),
            subOrders.find(fso => fso.orderId === so.orderId && fso.parentId === so.id)));
    });
    const subLoads = loadsData.filter(l => l.parentLoadId);
    const loads = [];
    loadsData.filter(l => !l.parentLoadId).forEach(l => loads.push(createWebLoad(l, assignedOrders.filter(o => o[loadIdField] === l.id),
        subLoads,
        subOrdersData,
        headers,
        (oldLoadsAssignedOrders || []).find(ol => ol.rowState.type === 'load' && ol.rowState.loadData.id === l.id), false)));
    if (assignedOrders.length !== loads.reduce((r, o) => r += o.ordersData.length, 0)) {
        console.log('Some assigned orders not found there loads');
    }
    return loads;
};

export const createWebLoad = (loadData, loadOrders, subLoads, subOrdersData, headers, oldWebLoad, isSub) => {
    const loadId = `load_${loadData.id}`;
    const loadWebData = {
        row: [],
        ordersData: [],
        subLoads: [],
        rowState: {
            type: 'load',
            isSub,
            subId: subLoads ? subLoads.find(l => l.parentLoadId === loadData.id) : null,
            id: loadId,
            loadId: loadData.id,
            loadData,
            visible: true,
            reacted: oldWebLoad !== undefined && oldWebLoad.rowState.reacted,
            isChecked: oldWebLoad !== undefined && oldWebLoad.rowState.reacted,
            available: false,
            createPickKey: order => {
                let createKey = '0';
                if (order.loadId) {
                    createKey = `${order.loadId}_${order.pickLocationId}`;
                }
                return createKey;
            },
            createDropKey: order => {
                let createKey = '0';
                if (order.loadId) {
                    createKey = `${order.loadId}`;
                    if (order.warehouse) {
                        createKey = `${createKey}_0_${order.warehouseId}`;
                    }
                    else {
                        createKey = `${createKey}_${order.dropLocationId}_0`;
                    }
                }
                return createKey;
            },
            groups: (orders, keyFunction) =>  orders.reduce((r, o) => {
                    const groupBy = keyFunction(o.rowState.orderData);
                    r[groupBy] = [...r[groupBy] || [], o.rowState.orderData];
                    return r;
                }, {}),
            findOrderGroup: (groups, order, createKey) => {
                return Object.keys(groups).find(go => {
                    const oG = groups[go];
                    if (oG.some(e => e.id === order.id)) {
                        return groups[go];
                    }
                    if (createKey && go === createKey(order)) {
                        return groups[go];
                    }
                    return null;
                });
            },
            height: itemDefaultSize
        }
    };

    const sortedOrdersByOrderPositionIndex = loadOrders.sort((a,b) => a.inOrderPossitionIndex - b.inOrderPossitionIndex);
    sortedOrdersByOrderPositionIndex.forEach(lo => loadWebData.ordersData.push(createWebOrder(lo, headers, loadData, null, loadId, loadWebData.rowState.reacted, isSub, subOrdersData)));
    loadWebData.ordersData =
        loadWebData.ordersData.sort((a, b) => new Date(a.rowState.orderData.inboundPickAppointment) - new Date(b.rowState.orderData.inboundPickAppointment ?? ''));

    const pickInboundGroups = loadWebData.rowState.groups(loadWebData.ordersData, loadWebData.rowState.createPickKey);
    // const dropInboundGroups = loadWebData.rowState.groups(loadWebData.ordersData, loadWebData.rowState.createDropKey);
    let stopNumber = 0;
    if (!isSub) {
        Object.keys(pickInboundGroups).forEach(key => {
            stopNumber++;
            const groupOrders = pickInboundGroups[key];
            groupOrders.forEach(o => {
                loadWebData.ordersData.find(wo => wo.rowState.orderData.id === o.id).row.find(f => f.key === 'left_action').value = stopNumber;
            });
        });
    }

    loadWebData.ordersData =
        loadWebData.ordersData.sort((a, b) => +a.row.find(f => f.key === 'left_action').value - +b.row.find(f => f.key === 'left_action').value);
    for (let hederIndex = 0; hederIndex < headers.length; hederIndex++) {
    
        const headerItem = headers[hederIndex];
        let value = null;
        let fieldType = 'text';
        let editable = false;
        let isBold = false;
        let cellStyle = {};
        let tooltip = null;
        if (!headerItem.leftAction && !headerItem.rightAction) {
        
            switch (headerItem.key) {
                case 'id':
                    value = loadData.id;
                    break;
                case 'inboundPickIn': 
                    if (!isSub) {
                        value = `${Object.keys(pickInboundGroups).length}P`;
                    }
                    break;
                case 'readyDate':
                    if (!isSub) {
                        value = '';
                        if (loadWebData.ordersData.length !== 0) {
                            const filterData = loadWebData.ordersData.filter(o => o.rowState.orderData[headerItem.key]);
                            if (filterData && filterData.length > 0) {
                                const maxDate = Math.max.apply(null,
                                    filterData.map(order => {
                                        return new Date(order.rowState.orderData[headerItem.key]);
                                    }));
                                if (maxDate) {
                                    value = new Date(maxDate);
                                }
                            }
                        }
                        fieldType = 'date';
                    }
                    break;
                case 'inboundPickAppointment':
                    value = '';
                    if (loadWebData.ordersData.length !== 0) {
                        const order = loadWebData.ordersData[loadWebData.ordersData.length - 1];
                        if (order) {
                            value = order.row.find(f => f.key === headerItem.key).value;
                        }
                    }
                    fieldType = 'dateTime';
                    break;
                case 'puDate':
                    value = '';
                    if (loadWebData.ordersData.length !== 0) {
                        const filterData = loadWebData.ordersData.filter(o => o.rowState.orderData[headerItem.key]);
                        if (filterData && filterData.length > 0) {
                            const minDate = Math.min.apply(null,
                                filterData.map(order => {
                                    return new Date(order.rowState.orderData[headerItem.key]);
                                }));
                            if (minDate) {
                                value = new Date(minDate);
                            }
                        }
                    }
                    fieldType = 'date';
                    break;
                case 'dueDate':
                    value = '';
                    fieldType = 'date';
                    break;
                case 'deliverDate':
                    value = '';
                    if (loadWebData.ordersData.length !== 0) {
                        const order = loadWebData.ordersData[loadWebData.ordersData.length - 1];
                        if (order) {
                            value = order.row.find(f => f.key === headerItem.key).value;
                        }
                    }
                    fieldType = 'date';
                    break;
                case 'inboundDropAppointment':
                    value = '';
                    if (loadWebData.ordersData.length !== 0) {
                        const order = loadWebData.ordersData[loadWebData.ordersData.length - 1];
                        if (order) {
                            value = order.row.find(f => f.key === headerItem.key).value;
                        }
                    }
                    fieldType = 'dateTime';
                    break;
                case 'pickLocation':
                    if (loadWebData.ordersData.length !== 0) {
                        const order = loadWebData.ordersData[0];
                        if (order) {
                            value = order.row.find(f => f.key === headerItem.key).value;
                        }
                    }
                    break;
                case 'dropLocation':
                    if (loadWebData.ordersData.length !== 0 && 
                        loadWebData.ordersData.find(order => order.rowState.orderData.routeId === 2)) {
                        const order = loadWebData.ordersData[loadWebData.ordersData.length - 1];
                        if (order) {
                            value = order.row.find(f => f.key === headerItem.key).value;
                        }
                    } else {
                        value = 'San Fernando, CA (TST)';
                    }
                    if (loadWebData.ordersData.length === 0) {
                        value = '';
                    }
                    fieldType = 'text';
                    break;
                case 'poNumber':
                    value = loadWebData.ordersData.length;
                    fieldType = 'text';
                    break;
                case 'palletCount':
                    if (loadWebData.ordersData.length > 0) {
                        value = loadWebData.ordersData.reduce((a, b) => {
                            return a + (b ?
                                +b.row.find(f => f.key === headerItem.key).value : 0);
                        }, 0);
                    }
                    fieldType = 'text';
                    break;
                case 'space':
                    if (loadWebData.ordersData.length > 0) {
                        value = loadWebData.ordersData.reduce((a, b) => {
                            return a + (b ?
                                +b.row.find(f => f.key === headerItem.key).value : 0);
                        }, 0);
                    }
                    fieldType = 'text';
                    break;
                case 'cases':
                    if (loadWebData.ordersData.length > 0) {
                        value = loadWebData.ordersData.reduce((a, b) => {
                            return a + (b ?
                                +b.row.find(f => f.key === headerItem.key).value : 0);
                        }, 0);
                    }
                    fieldType = 'text';
                    break;
                case 'weight':
                    if (loadWebData.ordersData.length > 0) {
                        value = loadWebData.ordersData.reduce((a, b) => {
                            return a + (b ?
                                +b.row.find(f => f.key === headerItem.key).value : 0);
                        }, 0);
                    }
                    fieldType = 'text';
                    break;
                case 'loadNumber':
                    value = loadData.loadNumber;
                    fieldType = 'text';
                    break;
                case 'wk':
                    value = loadData.pickWeek;
                    fieldType = 'selectManifest';
                    editable = true;
                    break;
                case 'in_load':
                    value = isSub ? "" : loadData.loadNum;
                    break;
                //case 'out_load':
                //    //??? it should be the outbound load index, not implemented on backend yet
                //    break;
                case 'carrier':
                    value = loadData.carrierName;
                    fieldType = 'text';
                    //editable = true;
                    break;
                case 'rate':
                    value = loadData.rate ? loadData.rate : '';
                    fieldType = 'currency';
                    //editable = true;
                    break;
                case 'target':
                    value = loadData.target ? loadData.target:'';
                    fieldType = 'currency';
                    //editable = true;
                    break;
                case 'efs':
                    //value = `${loadData.inPaymentMethod ? loadData.inPaymentMethod : ''}  ${loadData.inAmount ? '$' + loadData.inAmount : ''} ${loadData.inNote ? loadData.inNote : ''} ${`${loadData.inCardNumber ? `(${loadData.inCardNumber})` : ''}`} ${loadData.inEfsCreateDate ? (moment(loadData.inEfsCreateDate).format('L')) : ''}`;
                    value = loadData.efsSum;
                    fieldType = 'currency';
                    cellStyle = getEfsBGColor(loadData.efsStatusMin);
                    tooltip = getEfsStatusTooltip(loadData.efsStatusMin);
                    break;
                case 'inboundLumper':
                    value = Math.round(loadData.inboundLumper);
                    fieldType = 'currency';
                    //editable = true;
                    break;
                case 'driver_truck_Trailer_VIN':
                    value = `${(loadData.driverName ? loadData.driverName : '')} ${(loadData.truckNumber ? loadData.truckNumber : '')} ${(loadData.trailer ? loadData.trailer : '')}`;//??? + vin
                    break;
                case 'notes':
                    value = loadData.loadCommentsCount > 0 ? loadData.loadCommentsCount : '0' ;
                    fieldType = 'text';
                    break;
                case 'inPickNummber':
                    value = loadData.inPickNummber;
                    fieldType = 'textInput';
                    break;
                case 'inDeliverNummber':
                    value = loadData.inDeliverNummber;
                    fieldType = 'textInput';
                    break;
                // case 'loadPositionType':
                //     value = loadData.loadPositionType;
                //     fieldType = 'select';
                //     editable = true;
                //     break;
                default:
                    break;
            }
        }
        else {
            value = '';
            fieldType = 'text';
        }

        loadWebData.row.push({
            key: headerItem.key,
            value,
            fieldType,
            displayState: 1,
            isBold,
            editable,
            cellStyle,
            tooltip
        });
    }
    if (subLoads) {
        subLoads.filter(sl => sl.parentLoadId === loadData.id).forEach(sl => {
            loadWebData.subLoads.push(createWebLoad(sl, subOrdersData.filter(so => so.loadId === sl.id), subLoads, subOrdersData, headers, oldWebLoad, true));
        });
    }

    // if (!isSub) {
    //     let ordersNewList = [];
    //     const keyList = Object.keys(pickInboundGroups);
    //     const loadOrders = loadWebData.ordersData.filter(o => o.rowState.loadData.id === loadData.id);
    //     ordersNewList = loadOrders;

    //     let num = 0;
    //     let finalList = [];
    //     ordersNewList.forEach((order, i) => {
    //         if (keyList[num]?.includes(order.rowState?.orderData?.pickLocationId)) {
    //             finalList.push(order)
    //             if (!keyList[num]?.includes(ordersNewList[i+1]?.rowState?.orderData?.pickLocationId)) {
    //                 finalList.push(createEmpty(order.rowState.orderData.pickLocationId))
    //                 num++;
    //             }
    //         }
    //     })
    //     // remove the last element
    //     if (finalList?.length) {
    //         finalList.length = finalList?.length - 1;
    //     }
    //     loadWebData.ordersData = finalList;
    // }
    return loadWebData;
};

// export const createEmpty = id => {
//     const emptyRawHeaders = [ { key: 'main', width: 100 } ];
//     const emptyData = {
//         row: [],
//         ordersData: [],
//         rowState: {
//             type: 'emptyRow',
//             id: `pick_${id}`,
//             visible: true,
//             reacted: false,
//             available: true,
//             height: 8,
//             orderData: {
//                 pickLocationId: id,
//             }
//         }
//     };
//     for (let i = 0; i < emptyRawHeaders.length; i++) {
//         const emptyRawHeader = emptyRawHeaders[i];
//         emptyData.row.push({
//             key: emptyRawHeader.key,
//             value: '',
//             editable: false
//         });
//     }
//     return emptyData;
// };

export const extractWebRegionsToList = (regions, headers) => {
    const regionsAvailableOrders = [];
    regions.forEach((regionItem, i) => {
        extractWebRegionToList(regionItem, headers, regionsAvailableOrders, i);
    });
    return regionsAvailableOrders;
};

export const extractWebRegionToList = (region, headers, regionsAvailableOrders, index) => {
    regionsAvailableOrders.push(region);
    region.ordersData
    .sort((a, b) => { // sort orders alphabetically
        var textA = a.rowState.orderData.pickLocation.toLowerCase();
        var textB = b.rowState.orderData.pickLocation.toLowerCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    })
    .forEach(order => {
        regionsAvailableOrders.push(order);
    });
    regionsAvailableOrders.push(createSeparator(headers, 'region', index, null, region.rowState.id));
};

export const regionOrderGrouping = orders => orders.reduce((r, o) => {
    const groupBy = o[regionGroupField];
    r[groupBy] = [...r[groupBy] || [], o];
    return r;
}, {});

export const createWebRegionArray = (availableOrders, headers, regionHeaders) => {
    const regionsOrders = regionOrderGrouping(availableOrders);
    const regions = [];
    const sortedRegionsList = Object.keys(regionsOrders).sort((a, b) => RegionsList.indexOf(a) - RegionsList.indexOf(b));
    sortedRegionsList.forEach(r => {
        regions.push(createWebRegion(r, regionsOrders[r], headers, regionHeaders));
    });
    return regions;
};

export const createRegionWebId = regionName => `region_${regionName}`;

export const createWebRegion = (regionData, regionsOrders, headers, regionHeaders) => {
    const regionWebId = createRegionWebId(regionData);
    const regionWebData = {
        row: [],
        ordersData: [],
        rowState: {
            type: 'region',
            id: regionWebId,
            visible: true,
            reacted: false,
            available: true,
            height: itemDefaultSize
        }
    };
    for (let rIndex = 0; rIndex < regionHeaders.length; rIndex++) {
        const regionHeader = regionHeaders[rIndex];
        regionWebData.row.push({
            key: regionHeader.key,
            value: rIndex === 1 ? regionData : '',
            editable: false
        });
    }
    regionsOrders.forEach(o => regionWebData.ordersData.push(createWebOrder(o, headers, null, regionWebId, null, false, false, null)));
    return regionWebData;
};

const toDate = value => value ? moment.utc(value).toDate() : '';
const toDateTime = value => value ? moment.utc(value).toDate() : '';

const findFirstSubOrder = (selfId, subOrders, originalOrderId) =>
    subOrders.find(o => o.originalOrderId === originalOrderId && o.parentId === selfId);

//created spradesheet order row
export const createWebOrder = (oData, headers, loadData, regionId, loadId, isHidden, isSub, subOrders) => {
    const orderData = { ...oData };
    let allowCreateSubOrder = false;
    let firstSubOrder = null;
    if (!isSub && loadData !== null) {
        firstSubOrder = subOrders ? subOrders.find(o => o.originalOrderId === orderData.id && !o.parentId) : null;
        allowCreateSubOrder = firstSubOrder === undefined;
        if (firstSubOrder) {
            orderData.dropLocationId = firstSubOrder.pickLocationId;
            orderData.dropLocation = firstSubOrder.pickLocation;
        }
    }
    else if (isSub && subOrders) {
        firstSubOrder = findFirstSubOrder(orderData.subOrderId, subOrders, orderData.id);
        allowCreateSubOrder = firstSubOrder === undefined;
    }
    if (regionId && loadId) {
        console.warn(`regionId and loadId can not have value at same time -> ${regionId}, ${loadId}`);
    }
    const orderWebData = {
        row: [],
        rowState: {
            type: 'order',
            isSub,
            //efsId: orderData?.efsInId,
            subOrderId: orderData.subOrderId,
            subId: firstSubOrder && firstSubOrder.id,
            id: `${(isSub ? `sub_${orderData.parentId ? orderData.parentId : ''}` : '')}order_${orderData.id}`,
            orderObjectId: orderData.id,
            loadObjectId: loadData && loadData.id,
            orderData: orderData,
            loadData,
            loadId: loadId && loadId,
            regionId: regionId && regionId,
            visible: !isHidden,
            reacted: false,
            available: loadData === null || !loadData.id,
            height: itemDefaultSize,
            allowCreateSubOrder,
        }
    };
    
    for (let hederIndex = 0; hederIndex < headers.length; hederIndex++) {
        const headerItem = headers[hederIndex];
        let value = null;
        let secondValue = null;
        let thirdValue = null;
        let fieldType = 'text';
        let editable = false;
        let isBold = false;
        let cellStyle = {};
        let tooltip = null;
        if (!headerItem.leftAction && !headerItem.rightAction) {
            
            switch (headerItem.key) {
                case 'id':
                    value = orderData.id;
                    break;
                case 'inboundPickIn':
                    value = toDateTime(orderData.inboundPickIn);
                    fieldType = 'dateTime';
                    editable = false;
                    break;
                case 'inboundPickOut':
                    value = toDateTime(orderData.inboundPickOut);
                    fieldType = 'dateTime';
                    editable = false;
                    break;
                case 'readyDate':
                    value = toDate(orderData.readyDate);
                    fieldType = 'date';
                    editable = !isSub;
                    break;
                case 'inboundPickAppointment':
                    value = [toDateTime(orderData.inboundPickAppointment), Number(orderData.inboundAppointmentStatus), orderData.inPickNummber, orderData.lastInboundPickFromData, orderData.lastInboundPickToData, orderData.inPickAuthorFullName];
                    fieldType = 'appointmentSelector';
                    editable = true;
                    break;
                case 'inboundAppointmentStatus':
                    value = orderData.inboundAppointmentStatus;
                    fieldType = 'select';
                    editable = !orderWebData.rowState.available;
                    break;
                case 'puDate':
                    value = toDate(orderData.puDate);
                    fieldType = 'date';
                    editable = false;
                    break;
                case 'dueDate':
                    value = toDate(orderData.dueDate);
                    fieldType = 'date';
                    editable = true;
                    break;
                case 'deliverDate':
                    value = toDate(orderData.deliverDate);
                    fieldType = 'date';
                    editable = true;
                    break;
                case 'inboundDropAppointment':
                    value = [toDateTime(orderData.inboundDropAppointment), Number(orderData.dropInboundAppointmentStatus), orderData.inDeliverNummber, orderData.lastInboundDropFromData, orderData.lastInboundDropToData, orderData.inDropAuthorFullName];
                    fieldType = 'appointmentSelector';
                    editable = !orderWebData.rowState.available;
                    break;
                case 'dropInboundAppointmentStatus':
                    value = orderData.dropInboundAppointmentStatus;
                    fieldType = 'select';
                    editable = !orderWebData.rowState.available;
                    break;
                case 'inboundDropIn':
                    value = toDateTime(orderData.inboundDropIn);
                    fieldType = 'dateTime';
                    editable = false;
                    break;
                case 'inboundDropOut':
                    value = toDateTime(orderData.inboundDropOut);
                    fieldType = 'dateTime';
                    editable = false;
                    break;
                case 'customerCodeOrName':
                    value = orderData.customerCodeOrName;
                    fieldType = 'textInputReadOnly';
                    editable = true;
                    break;
                case 'pickLocation':
                    value = orderData.pickLocation;
                    secondValue = orderData.pickWorkingDaysStr;
                    fieldType = 'text';
                    editable = false;
                    break;
                case 'dropLocation':
                    value = orderWebData.rowState.available ? orderData.dropLocation : orderData.dropLocationId;
                    fieldType = orderWebData.rowState.available ? 'text' : 'selectLocations';
                    isBold = !orderData.warehouse;
                    editable = !orderWebData.rowState.available;
                    break;
                case 'poNumber':
                    value = orderData.poNumber;
                    secondValue = isSub ? orderData.stepCount : '';
                    fieldType = orderData?.isTbd && !orderWebData.rowState.available? 'textInput' : 'textInputPO';
                    editable = (orderData?.isTbd && !orderWebData.rowState.available) || isSub ? true : false;
                    break;
                case 'soNumber':
                    value = orderData.soNumber;
                    fieldType = orderData?.isTbd && !orderWebData.rowState.available ? 'textInput' : 'textInputReadOnly';
                    editable = true;
                    break;
                case 'palletCount':
                    value = orderData.palletCount;
                    thirdValue = orderData?.orderHistoryDict?.palletCount;
                    fieldType = 'integer';
                    editable = !isSub;
                    break;
                case 'space':
                    value = orderData.space;
                    thirdValue = orderData?.orderHistoryDict?.space;
                    fieldType = 'integer';
                    editable = !isSub;
                    break;
                case 'cases':
                    value = orderData.cases;
                    thirdValue = orderData?.orderHistoryDict?.cases;
                    fieldType = 'integer';
                    editable = !isSub;
                    break;
                case 'weight':
                    value = orderData.weight;
                    thirdValue = orderData?.orderHistoryDict?.weight;
                    fieldType = 'integer';
                    editable = !isSub;
                    break;
                case 'temperature':
                    value = orderData.temperature;
                    fieldType = 'integer';
                    editable = false;
                    break;
                case 'temperatureDegre':
                    value = orderData.temperatureDegre;
                    fieldType = 'integer';
                    editable = false;
                    break;
                case 'pickRestriction':
                    value = orderData.pickRestriction;
                    fieldType = 'select';
                    editable = true;
                    break;
                case 'loadNumber':
                    value = loadData ? loadData.loadNumber : '';
                    secondValue = loadData ? loadData.rateConfirmed : '';
                    thirdValue = loadData ? loadData.carrierInformed : '';
                    fieldType = 'text';
                    break;
                case 'wk':
                    value = orderData.puDate && new Date(orderData.puDate).getWeek();
                    fieldType = 'text';
                    break;
                case 'in_load':
                    value = orderData.inLoad;
                    break;
                case 'out_load':
                    value = orderData.outLoad;
                    break;
                //case 'target':
                //    value = orderData.target;
                //    break;
                case 'carrier':
                    //??? 'Single, Straight', 'SIDEWAYS', I think this should be order field
                    break;
                case 'inboundLumper':
                    value = Math.round(orderData.inboundLumper);
                    fieldType = 'currency';
                    editable =orderData.inboundDropIn;
                    break;
                case 'inPickNummber':
                    value = orderData.inPickNummber;
                    fieldType = 'textInput';
                    editable = !orderWebData.rowState.available;
                    break;
                case 'inDeliverNummber':
                    value = orderData.inDeliverNummber;
                    fieldType = 'textInput';
                    editable = !orderWebData.rowState.available;
                    break;
                case 'efs':
                    //value = `${orderData.inPaymentMethod ? orderData.inPaymentMethod : ''}  ${orderData.inAmount ? '$' + orderData.inAmount : ''} ${orderData.inNote ? orderData.inNote : ''} ${`${orderData.inCardNumber ? `(${orderData.inCardNumber})` : ''}`} ${orderData.inEfsCreateDate ? (moment(orderData.inEfsCreateDate).format('L')) : ''}`;
                    break;
                case 'ap_notes':
                    value = orderData.lastComment && orderData.lastComment.Comment;
                    fieldType = 'text';
                    break;
                default:
                    break;
            }
        }
        else {
            value = '';
            secondValue = '';
            thirdValue = '';
            fieldType = 'text';
        }

        orderWebData.row.push({
            key: headerItem.key,
            value,
            secondValue,
            thirdValue,
            fieldType,
            displayState: 1,
            isBold,
            editable,
            cellStyle,
            tooltip
        });
    }
    return orderWebData;
};


