import React, { useState, useEffect } from 'react';

import axios from '../../../axios/axios';

import errorHandler from '../../../error/errorHandler';
import { ElementTypes } from '../../../config';

import Orders from './orders';

const LoadOrders = props => {
    const [isLoading, setLoading] = useState(true);

    const {
        List,
        OrderForm,
        dropId,
        Key, 
        dragDropData,
        dispatchDropData
    } = props;
    const Resource = `ReceivingLoad\\assignedorderlist\\${props.recordId}`;

    const columns = List.Columns;
    
    columns['inboundPickAppointment'] = {
        Title: 'Appt',
        Field: ElementTypes.Generic(ElementTypes.TableLabelField, {
            style: (_, data) => {
                return data ? null : ({ backgroundColor: 'rgb(255, 240, 86)', height:'20px', width: '100%' });
            }
        }),
        Width: 60
    };

    useEffect(() => {
        let isMounted = true;
        const fetchData = async (isMounted) => {
            if (isMounted) {
                setLoading(true);
            }
            const response = await axios(
                Resource,
                {
                    params: {}
                }
            ).finally(() => {
                if (isMounted) {
                    setLoading(false);
                }
            });
            if (isMounted) {
                if (response) {
                    const result = response.data;
                    if (result) {
                        const data = result.data;
                        for (var i = 0; i < data.length; i++) {
                            if (!data[i].key) {
                                data[i].key = data[i][Key || 'id'];
                            }
                        };

                        dispatchDropData({ type: dropId, value: data });
                        setLoading(false);
                    }
                }
            }
        };
        setLoading(true);
        fetchData(isMounted);
        return () => { isMounted = false };
    }, [Resource, dropId, Key, dispatchDropData]);

    return <Orders
        isLoading={isLoading}
        resource={props.resource}
        OrderForm={OrderForm}
        keyFieldName={Key}
        columns={columns}
        data={dragDropData?.[dropId]?.data || []}
        dispatchData={dispatchDropData}
    />;
};
export default errorHandler(LoadOrders, axios);