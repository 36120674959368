import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import moment from 'moment';
import { message, Tooltip } from 'antd';

import { ColumnType } from '../config/list.config';
import { Project } from '../../project/Project';
import { AppPages } from '../../project/Pages';
import { CommonLabels, Defines, bytesToSize } from '../../project/Defines';
import { NullOrUndefined, ParseJson, InvertColor, CloneObject } from '../helper/common';
import { getNormalizedAttributes } from '../config/element.config';
import ButtonAction from '../components/list/buttonActions';
import { ApptField } from '../components/project/loadArea/apptField';
import Download from '../elements/download';
import { formatPhoneNumber } from "../helper/common";

const Column = (props) => {
    const history = useHistory();
    const attributes = props.attributes || {};
    return (<wrap 
                className={props.classes && props.classes.join(' ')}
                {...attributes.wrap}
                style={{ display: 'flex', justifyContent: 'center' }}
            >
            {props?.record?.batch === 3 && props.linkAppPage && props.dataTitle === 'orderGroupIndex' ? 
                <div></div> :
            props?.record?.batch === 3 && props.dataTitle === 'orderGroupIndex' ? 
            <div></div> :

            (props?.record?.loadId || props?.record?.outboundLoadId) && (props?.dataTitle === 'PO#' || props?.dataTitle === 'orderGroupIndex') ? 
            <div 
                onClick={() => message.error('Impossible to edit order, as it is already in a Load')}
                style={{ color: "var(--main-color)" }}
                >
                {props.children}
            </div> :

            props.linkAppPage ? <Link
                to={{
                    pathname: Project.getPageUrl(AppPages[props.resource + Defines.DetailsComponentSufix], props.record[props.id]),
                    state: {
                        search: history.location.search
                    }
                }}
                style={{ color: "var(--main-color)" }}>
                {props.children}
            </Link> : props.children}
    </wrap>);
};

const Text = props => {
    const title = props.dataTitle && (props.dataTip ? props.dataTip : (NullOrUndefined(props.text) ? null : props.text?.toString()));
    return <Tooltip placement="topLeft" title={title}>
        <text {...props.attributes.text}>{props.text} {props.symbol && <span>&#8457;</span>}</text>
    </Tooltip>
};

const MultilineText = props => {
    const title = props.dataTitle && (props.dataTip ? props.dataTip : (NullOrUndefined(props.text) ? null : props.text?.toString()));
    return <Tooltip placement="topLeft" title={title}>
        <text {...props.attributes.text} style={{ whiteSpace: 'pre' }}>{props.text}</text>
    </Tooltip>
};

const GenerateColumnDecimal = (text, dataTip, linkAppPage, dataTitle, resource, record, id, classes = null, attributes = {}) => {
    const title = dataTitle && (dataTip ? dataTip : (NullOrUndefined(text) ? null : text?.toString()));
    return <Column
        text={text}
        linkAppPage={linkAppPage}
        dataTitle={dataTitle}
        resource={resource}
        record={record}
        classes={classes}
        id={id}><Tooltip placement="topLeft" title={title}>
            <text {...attributes.text}>{text?.toLocaleString()}</text>
        </Tooltip></Column>
};

const SimpleIndicator = (props) => (
    <Tooltip placement="topLeft" title={(props.dataTitle && props.text) ? props.text.toString() : ""}>
        <group circle=''><circle {...props.attributes.circle} /></group>
    </Tooltip>
);

const ReceiveIndicator = (props) => {
    const data = props.data;
    const dataTitle = props.dataTitle === undefined ? true : props.dataTitle;
    const formattedText = data.receiveUser && data.receiveDate && data.received ?
        <div>
            <div><b>Receive By:</b> {data.receiveUser}</div>
            <div><b>Receive Date:</b> {moment.utc(data.receiveDate).local().format(Defines.Format.PrintDateTime)}</div>
            <div><b>Received</b>: {data.received ? 'Yes' : 'No'}</div>
        </div>
        : null;

    return (
        data &&
        <Tooltip placement="topLeft" title={(dataTitle && data) ? formattedText : ""}>
            <group circle=''><circle {...props.attributes.circle} /></group>
        </Tooltip>
    )
};

const StatusIndicator = (props) => {
    const attributes = props.attributes || {};
    if (!attributes.circle) {
        attributes.circle = {};
    }

    const style = { boxShadow: 'inset 0 0 0 1px #000' };
    attributes.circle.style = style;

    switch (props.data) {
        case 'Open':
            style.backgroundColor = '#fff';
            break;
        case 'ReOpen':
        case 'Alternative':
            style.backgroundColor = '#029b96';
            break;
        case 'Requested':
            style.backgroundColor = '#fbcb00';
            break;
        case 'Confirmed':
            style.backgroundColor = '#76a45d';
            break;
        case 'Pending':
            style.backgroundColor = '#fbcb00';
            break;
        case 'Request':
            style.backgroundColor = 'tomato';
            break;
        default:
            delete attributes.circle.style;
            break
    }

    return (
        <Tooltip placement="top" title={props.dataTitle && props.data}>
            <wrap {...props.attributes.wrap}>
                <group circle=''>
                    <circle {...props.attributes.circle} />
                </group>
            </wrap>
        </Tooltip>
    )
};

const CommentIndicator = props => {
    const attributes = props.attributes || {};
    if (!attributes.circle) {
        attributes.circle = {};
    };
    attributes.circle.style = { backgroundColor: 'green' };;

    return props?.data?.comment ? (
        <Tooltip placement="top" title={props.data.comment}>
            <wrap {...props.attributes.wrap}>
                <group circle=''>
                    <circle {...props.attributes.circle} />
                </group>
            </wrap>
        </Tooltip>
    ) : null;
};

const GetCancelIndicator = (props) => {
    const data = props.data;
    const dataTitle = props.dataTitle === undefined ? true : props.dataTitle;
    const formattedText = data.cancel ?
        <div>
            <div><b>Cancel By:</b> {data.cancelByStr}</div>
            <div><b>Cancel Date:</b> {moment.utc(data.cancelDate).local().format(Defines.Format.PrintDate)}</div>
            <div><b>Cancel Time:</b> {moment.utc(data.cancelDate).local().format(Defines.Format.MomentTime)}</div>
            <div><b>Cancel Reason</b>: {data.cancelReason}</div>
        </div>
        : null;

    return (
        data &&
        <Tooltip placement="topLeft" title={(dataTitle && data) ? formattedText : ""}>
            <group circle=''><circle {...props.attributes.circle} /></group>
        </Tooltip>
    )
};

const GetLatestCommentIndicator = (props) => {
    const data = props.data || props.lastComment;
    const dataTitle = props.dataTitle === undefined ? true : props.dataTitle;
    const formattedText = data.user && data.createDate && data.comment ?
        <div>
            <div><b>Comment By:</b> {data.user}</div>
            <div><b>Comment Date:</b> {moment.utc(data.createDate).local().format(Defines.Format.PrintDate)}</div>
            <div><b>Comment Time:</b> {moment.utc(data.createDate).local().format(Defines.Format.MomentTime)}</div>
            <div><b>Comment</b>: {data.comment}</div>
        </div>
        : null;

    return (
        data &&
        <Tooltip placement="topLeft" title={(dataTitle && data) ? formattedText : ""}>
            <group circle=''><circle {...props.attributes.circle} /></group>
        </Tooltip>
    )
};

const ColumnBadge = (props) => {
    const attributes = props.attributes || {};
    if (!attributes.badge) {
        attributes.badge = {};
    }

    const style = { boxShadow: 'inset 0 0 0 1.5px #000', color: '#fff' };
    attributes.badge.style = style;

    switch (props.data) {
        case 'Open':
        case 'ReOpen':
            style.backgroundColor = '#fff';
            style.color = '#000';
            break;
        case 'Canceled':
            style.backgroundColor = 'tomato';
            break;
        case 'PU Scheduled':
            style.backgroundColor = '#81d4fa';
            break;
        case 'WH- In Transit':
            style.backgroundColor = '#4fc3f7';
            break;
        case 'Direct In Transit':
            style.backgroundColor = '#29b6f6';
            break;
        case 'Drop Scheduled':
            style.backgroundColor = '#039be5';
            break;
        case 'DL- In Transit':
            style.backgroundColor = '#0277bd';
            break;
        case 'Delivered':
            style.backgroundColor = '#01579b';
            break;
        case 'Billing':
            style.backgroundColor = 'orange';
            break;
        case 'Requested':
            style.backgroundColor = '#76a45d';
            break;
        case 'Confirmed':
        case 'Ready to Build':
        case 'Completed':
        case 'Received':
            style.backgroundColor = '#029b96';
            break;
        case 'Over Received':
        case 'Pending':
            style.backgroundColor = '#fbcb00';
            break;
        case 'Short Received':
        case 'Request':
            style.backgroundColor = 'tomato';
            break;
        case 'Invoiced':
            style.backgroundColor = 'orange';
            break;
        default:
            delete attributes.badge.style;
            break
    }

    return (
        <Column>
            <group compact="">
                <badge {...attributes.badge}>{props.data}</badge>
            </group>
        </Column>
    )
};

export const GenerateColumnTitle = (title) => <wrap><text>{title}</text></wrap>;

export const GenerateColumnText = (text, dataTip, linkAppPage, dataTitle, resource, record, id, classes = null, attributes = {}, symbol = null) => (<Column
    text={text}
    linkAppPage={linkAppPage}
    dataTitle={dataTitle}
    resource={resource}
    record={record}
    classes={classes}
    id={id}
    symbol={symbol}>
    <Text text={text} dataTip={dataTip} dataTitle={dataTitle} attributes={attributes} symbol={symbol}/>
</Column>);

export const GenerateColumnPhone = (text, dataTip, linkAppPage, dataTitle, resource, record, id, classes = null, attributes = {}) => (<Column
    text={formatPhoneNumber(text)}
    linkAppPage={linkAppPage}
    dataTitle={dataTitle}
    resource={resource}
    record={record}
    classes={classes}
    id={id}>
    <Text text={formatPhoneNumber(text)} dataTip={dataTip} dataTitle={dataTitle} attributes={attributes} />
</Column>);

export const GenerateColumnMultilineText = (text, dataTip, linkAppPage, dataTitle, resource, record, id, classes = null, attributes = {}) => (<Column
    text={text}
    linkAppPage={linkAppPage}
    dataTitle={dataTitle}
    resource={resource}
    record={record}
    classes={classes}
    id={id}>
    <MultilineText text={text} dataTip={dataTip} dataTitle={dataTitle} attributes={attributes} />
</Column>);

export const GenerateColumnFile = (text, fileFieldName, width = 70, height = 70, downloadUrl, record, classes = null, attributes = {}) => (<Column
    text={bytesToSize(text)}
    record={record}
    classes={classes}
    attributes={attributes}>
    <Download
        guid={text}
        // externalLink={downloadUrl}
        width={width}
        height={height}
        filename={record[fileFieldName]} />
</Column>);

export const GenerateColumnDateLabel = (text, linkAppPage, dataTitle, resource, record, id, classes = null, attributes = {}) => {
    return <Text text={text ? moment(text).format(Defines.Format.PrintDate) : ''} dataTitle={dataTitle} attributes={attributes} />;
};

export const GenerateColumnDateTimeLabel = (text, linkAppPage, dataTitle, resource, record, id, classes = null, attributes = {}) => {
    return <Text text={text ? moment.utc(text).local().utc(true).format(Defines.Format.PrintDateTime) : ''} dataTitle={dataTitle} attributes={attributes} />;
};

export const GenerateColumnTimeLabel = (text, linkAppPage, dataTitle, resource, record, id, classes = null, attributes = {}) => {
    return <Text text={text ? moment.utc(text).format(Defines.Format.MomentTime) : ''} dataTitle={dataTitle} attributes={attributes} />;
};

export const GenerateColumnBoolLabel = (text, linkAppPage, dataTitle, resource, record, id, classes = null, attributes = {}) => {
    return <Text text={text ? 'Yes' : 'No'} dataTitle={dataTitle} attributes={attributes} />;
};

export const GenerateColumnDate = (text, linkAppPage, dataTitle, resource, record, id, classes = null, attributes = {}) => {
    return (<Column
        text={text}
        linkAppPage={linkAppPage}
        dataTitle={dataTitle}
        resource={resource}
        record={record}
        classes={classes}
        id={id}>
        <Text text={text ? moment(text, Defines.Format.MomentDate).format(Defines.Format.PrintDate) : ''} dataTitle={dataTitle} attributes={attributes} />
    </Column>)
};

export const GenerateColumnTime = (text, linkAppPage, dataTitle, resource, record, id, classes = null, attributes = {}) => {
    return (<Column
        text={text}
        linkAppPage={linkAppPage}
        dataTitle={dataTitle}
        resource={resource}
        record={record}
        classes={classes}
        id={id}>
        <Text text={text ? moment(text, Defines.Format.MomentTime).format(Defines.Format.MomentTime) : ''} dataTitle={dataTitle} attributes={attributes} />
    </Column>)
};

export const GenerateColumnDateTime = (text, linkAppPage, dataTitle, resource, record, id, classes = null, attributes = {}) => {
    return (<Column
        text={text}
        linkAppPage={linkAppPage}
        dataTitle={dataTitle}
        resource={resource}
        record={record}
        classes={classes}
        id={id}>
        <Text text={text ? moment(text, Defines.Format.MomentDateTime).format(Defines.Format.PrintDateTime) : ''} dataTitle={dataTitle} attributes={attributes} />
    </Column>)
};

export const GenerateColumnNumber = (text, linkAppPage, dataTitle, resource, record, id, classes = null, attributes = {}) => {
    return (<Column
        text={text}
        linkAppPage={linkAppPage}
        dataTitle={dataTitle}
        resource={resource}
        record={record}
        classes={classes}
        id={id}>
        <Text text={text ? parseInt(text) : ''} dataTitle={dataTitle} attributes={attributes} />
    </Column>)
};

export const GenerateColumnCurrency = (text, linkAppPage, dataTitle, resource, record, id, classes = null, attributes = {}) => {
    return (<Column
        text={text}
        linkAppPage={linkAppPage}
        dataTitle={dataTitle}
        resource={resource}
        record={record}
        id={id}>
        <Text text={text ? text.toLocaleString("en-US", { style: 'currency', currency: 'USD', }) : ''} dataTitle={dataTitle} attributes={attributes} />
    </Column>)
};

export const GenerateColumnBool = (text, linkAppPage, dataTitle, resource, record, id, classes = null, attributes = {}) => {
    return (<Column
        text={text}
        linkAppPage={linkAppPage}
        dataTitle={dataTitle}
        resource={resource}
        record={record}
        classes={classes}
        id={id}>
        <Text text={text ? 'Yes' : 'No'} dataTitle={dataTitle} attributes={attributes} />
    </Column>)
};

export const GenerateSimpleColumnIndicator = (text, dataTitle, attributes = {}) =>
    <SimpleIndicator text={text} dataTitle={dataTitle} attributes={attributes} />;

export const GenerateCancelIndicator = (data, dataTitle, attributes = {}) =>
    <GetCancelIndicator data={data} dataTitle={dataTitle} attributes={attributes} />;

export const GenerateLatestCommentIndicator = (data, dataTitle, attributes = {}) =>
    <GetLatestCommentIndicator data={data} dataTitle={dataTitle} attributes={attributes} />;

export const GenerateReceiveIndicator = (data, dataTitle, attributes = {}) =>
    <ReceiveIndicator data={data} dataTitle={dataTitle} attributes={attributes} />;

export const GenerateStatusIndicator = (data, dataTitle, attributes = {}) =>
    <StatusIndicator data={data} dataTitle={dataTitle} attributes={attributes} />;

export const GenerateCommentIndicator = (data, dataTitle, attributes = {}) =>
    <CommentIndicator data={data} attributes={attributes} />;

export const GenerateColumnBadge = (data, dataTitle, attributes = {}) =>
    <ColumnBadge data={data} dataTitle={dataTitle} attributes={attributes} />;

export const GenerateColumnButton = (record, props, dataSource, data, setDataSource, attributes) => {
    let field = null;
    if (props.Field) {
        if (props.Field === 'pickAppointment' || props.Field === 'dropAppointment') {
            if (!data[props.Field]) {
                field = <ApptField
                    data={record}
                    dataSource={dataSource}
                    setDataSource={setDataSource}
                    dispatchAction={props.ActionType}
                    attributes={attributes}
                />;
            }
        }
    }

    return <ButtonAction
        id={record.id}
        icon={props.Icon}
        action={props.Action}
        actionType={props.ActionType}
        dataSource={dataSource}
        data={data}
        setDataSource={setDataSource}
        label={props.Label}
        field={field}
        popupConfig={{ ...props.PopupConfig }}
        attributes={attributes}
    />;
};

export const GenerateColumnAction = (record, items, resource, onAction, attributes = {}, hasReference = false) => {
    if (!attributes.action) {
        attributes.action = {};
    }
    items = items.filter(item => {
        let i = item;
        if (typeof (i) === 'function') {
            i = item(record);
        }
        return i;
    });

    return items?.length
        ? <elastic>
            <action {...attributes.action}>
                {items.map((item) => {
                    let i = item;
                    if (typeof (i) === 'function') {
                        i = item(record);
                    }
                    if (!i) {
                        return null;
                    }
                    if (i === "Edit") {
                        return (<div
                            key={i}
                            command={i}
                            className="button micro"
                            onClick={() => { onAction(record, i, hasReference); }}>
                            <svg width="14" height="14" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.06 6.02L11.98 6.94L2.92 16H2V15.08L11.06 6.02ZM14.66 0C14.41 0 14.15 0.1 13.96 0.29L12.13 2.12L15.88 5.87L17.71 4.04C18.1 3.65 18.1 3.02 17.71 2.63L15.37 0.29C15.17 0.09 14.92 0 14.66 0ZM11.06 3.19L0 14.25V18H3.75L14.81 6.94L11.06 3.19Z" fill="black"></path></svg>
                        </div>);
                    }
                    if (i === "NeedToClarify") {
                        return (
                            <Tooltip placement="topLeft" title={'Need to clarify'}>
                                <div
                                    key={i}
                                    command={i}
                                    className="button micro flag"
                                    onClick={() => { onAction(record, i, hasReference); }}>
                                    <svg width="18" height="20" viewBox="0 0 18 20" fill={`${record.isNeedToClarify ? "#D05757" : "#DEDEDE"}`} xmlns="http://www.w3.org/2000/svg">
                                        <path d="M17.1788 1.86667C16.9219 1.6839 16.6251 1.5635 16.3124 1.51524C15.9996 1.46698 15.6798 1.49221 15.3788 1.58889C14.5847 1.80943 13.7623 1.91424 12.9375 1.9C11.7187 1.83466 10.5236 1.54052 9.41626 1.03333C8.04828 0.40848 6.56859 0.0572844 5.06251 0C1.81127 0 0.562519 1.11111 0.337519 1.26667C0.229866 1.37088 0.144516 1.4954 0.0865386 1.63283C0.0285612 1.77026 -0.000861205 1.91779 1.91891e-05 2.06667V18.8889C1.91891e-05 19.1836 0.118546 19.4662 0.329524 19.6746C0.540502 19.8829 0.82665 20 1.12502 20C1.42339 20 1.70953 19.8829 1.92051 19.6746C2.13149 19.4662 2.25002 19.1836 2.25002 18.8889V14.1111C3.14645 13.7721 4.10342 13.6171 5.06251 13.6556C6.28135 13.7209 7.47641 14.015 8.58376 14.5222C9.95174 15.1471 11.4314 15.4983 12.9375 15.5556C14.2937 15.6061 15.6428 15.3396 16.875 14.7778C17.2071 14.6237 17.4887 14.3805 17.6876 14.076C17.8865 13.7715 17.9948 13.4179 18 13.0556V3.45556C17.9996 3.14553 17.925 2.83998 17.7823 2.56385C17.6396 2.28772 17.4328 2.04883 17.1788 1.86667Z"/>
                                    </svg>
                                </div>
                            </Tooltip>);
                    }
                    if (i === "SONUCancelation") {
                        return (
                            <Tooltip placement="topLeft" title={'SONU'}>
                                <div
                                    key={i}
                                    command={i}
                                    className="button micro"
                                    style={{ width: '17px', height: '17px' }}
                                    onClick={() => { onAction(record, i, hasReference); }}>
                                    <svg viewBox="64 64 896 896" focusable="false" data-icon="minus-circle" width="17px" height="17px" fill="currentColor" aria-hidden="true">
                                        <path d="M696 480H328c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h368c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8z"></path><path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path>
                                    </svg>
                                </div>
                            </Tooltip>);
                    }
                    if (i === "play" || 'done') {
                        return (
                            <Tooltip key={`t_${i}`}
                                placement="topLeft" 
                                title={
                                    i === "play" ? 'Send to Ready to Bill' :
                                    i === "Delete" ? 'Delete' :
                                    i === "Cancel" ? 'Canceled' :
                                    i === "CancelReason" ? 'Canceled' :
                                    'Send to Completed'}>
                                    <div
                                        key={i}
                                        command={i}
                                        className="button micro"
                                        onClick={() => { onAction(record, i, hasReference); }}>
                                        <icon edit="">{i}</icon>
                                    </div>
                            </Tooltip>);
                    }
                    return (<div
                        key={i}
                        command={i}
                        className="button micro"
                        onClick={() => { onAction(record, i, hasReference); }}>
                        <icon edit="">{i}</icon>
                    </div>);
                })}
            </action>
        </elastic>
        : null;
};

export const sorColumnsByIndex = obj => {
    let sortedList = [];
    sortedList = Object.entries(obj).sort((a, b) =>
        a[1] && b[1] && a[1].index - b[1].index
    );
    let ret = {};
    for (const a in sortedList) {
        ret[sortedList[a][0]] = sortedList[a][1];
    }
    return ret;
}

export const Columns = (config, onAction, dataSource, setDataSource, sorts, profileSettings, onResize, onResizeStop, onResizeStart, resizeMode, tableWidth) => {
    let columnConfigs = CloneObject(config.List.Columns);
    const actions = config.List.Actions;
    const resource = config.Resource;
    const columnSettings = profileSettings &&
        profileSettings.screens &&
        profileSettings.screens[resource] &&
        profileSettings.screens[resource].list &&
        profileSettings.screens[resource].list.columns;

    if (columnSettings) {
        let cIndex = 0;
        for (const field in columnConfigs) {
            const column = columnConfigs[field];
            column && (column['index'] = columnSettings[field] && columnSettings[field].index ? columnSettings[field].index : cIndex);

            cIndex++;
        }
        columnConfigs = sorColumnsByIndex(columnConfigs);
    }

    columnConfigs['_empty'] =
    {
        Title: '',
        NoSort: true,
        Type: ColumnType.Empty
    };
    const columns = [];
    const defaultSorts = [];
    let columsTotalWidth = 0;
    let emptyWidthColumns = 0;
    for (const field in columnConfigs) {
        const column = columnConfigs[field];
        if (!column) {
            continue;
        }
        let width = column.Width;
        if (columnSettings && columnSettings[field] && columnSettings[field].hidden) {
            continue;
        }
        if (columnSettings && columnSettings[field] && columnSettings[field].width) {
            width = columnSettings[field].width;
        }
        if (width) {
            columsTotalWidth += width;
        }
        else if (column.Type !== ColumnType.Empty) {
            emptyWidthColumns++;
        }
    }
    columsTotalWidth += (actions ? (actions.Width ? actions.Width : 80) : 0);
    let delta = tableWidth - columsTotalWidth;
    let approximateColumnWidth = emptyWidthColumns > 0 ? Math.floor(delta / emptyWidthColumns) : 0;
    if (approximateColumnWidth > 0) {
        columsTotalWidth = 0;
        for (const field in columnConfigs) {
            const column = columnConfigs[field];
            let width = column.Width;
            if (columnSettings && columnSettings[field] && columnSettings[field].hidden) {
                continue;
            }
            if (columnSettings && columnSettings[field] && columnSettings[field].width) {
                width = columnSettings[field].width;
            }
            if (width) {
                columsTotalWidth += width;
            }
            else if (column.Type !== ColumnType.Empty) {
                columsTotalWidth += approximateColumnWidth;
            }
        }
        columsTotalWidth += (actions ? (actions.Width ? actions.Width : 80) : 0);
        delta = tableWidth - columsTotalWidth;
    }
    const fieldsAtrray = Object.keys(columnConfigs);
    const lastField = fieldsAtrray[fieldsAtrray.length - 1];

    const resizeEvents = config.resizable
        ? {
            onResizeStop: onResizeStop,
            onResize: onResize,
            onResizeStart: onResizeStart
        }
        : {};

    for (const field in columnConfigs) {
        const column = columnConfigs[field];
        if (!column) {
            continue;
        }
        let width = column.Width;// ? column.Width : 80;
        if (columnSettings && columnSettings[field] && columnSettings[field].hidden) {
            continue;
        }
        if (columnSettings && columnSettings[field] && columnSettings[field].width) {
            width = columnSettings[field].width;
        }
        if (!width && column.Type !== ColumnType.Empty) {
            width = approximateColumnWidth;
        }
        if (delta > 0 && field === lastField) {
            width = delta;
        }

        columns.push({
            title: <wrap style={{ justifyContent: 'center' }}><text>{column.Title}</text></wrap>,
            width: width,
            dataIndex: field.split('.'),
            key: field,
            sorter: !column.NoSort,
            sortOrder: (sorts && sorts.length && sorts[0].Member === field) ? (sorts[0].SortDirection === 0 ? 'ascend' : 'descend') : false,
            defaultSortOrder: !sorts && column.Sorted ? column.Sorted : false,
            onHeaderCell: () => ({
                width: width,
                field: field,
                column: column,
                ...resizeEvents
            }),
            render: (text, record) => {
                let columnType = column.Type;
                if (resizeMode) {
                    return columnType === ColumnType.Empty ? '' : '...';
                }

                let classes = null;
                let dataTip = null;
                if (typeof (columnType) === 'function') {
                    [columnType, classes] = column.Type(text, record);
                }

                
                if (column.DataTitle && column.DataTitleField) {
                    dataTip = record[column.DataTitleField];
                }

                const attributes = getNormalizedAttributes(column.Attributes, record, text);

                if (column.Display && typeof column.Display === 'function' && !column.Display(record)) {
                    if (config.List.Coloring) {
                        const bgColor = config.List.Coloring(record, field);
                        if (bgColor) {
                            return {
                                props: {
                                    style: {
                                        backgroundColor: bgColor,
                                        color: InvertColor(bgColor, true)
                                    },
                                },
                                children: ''
                            };
                        }
                        return '';
                    }
                    else {
                        return {
                            props: {
                                style: classes?.join(' ') === 'empty' ? { background: 'rgb(255, 240, 86)' } : null,
                            },
                            children: ''
                        }
                    }
                }
                let children;
                const configKey = config.ItemKey ?? config.Key;
                const displayText = column.RenderText && typeof column.RenderText === 'function' ? column.RenderText(text, record) : text;
                switch (columnType) {
                    case ColumnType.Date: children = GenerateColumnDate(displayText, column.LinkAppPage, column.DataTitle, resource, record, configKey, classes, attributes); break;
                    case ColumnType.Time: children = GenerateColumnTime(displayText, column.LinkAppPage, column.DataTitle, resource, record, configKey, classes, attributes); break;
                    case ColumnType.DateTime: children = GenerateColumnDateTime(displayText, column.LinkAppPage, column.DataTitle, resource, record, configKey, classes, attributes); break;
                    case ColumnType.Number: children = GenerateColumnNumber(displayText, column.LinkAppPage, column.DataTitle, resource, record, configKey, classes, attributes); break;
                    case ColumnType.Currency: children = GenerateColumnCurrency(displayText, column.LinkAppPage, column.DataTitle, resource, record, configKey, classes, attributes); break;
                    case ColumnType.Bool: children = GenerateColumnBool(displayText, column.LinkAppPage, column.DataTitle, resource, record, configKey, classes, attributes); break;
                    case ColumnType.SimpleIndicator: children = GenerateSimpleColumnIndicator(record, column.DataTitle, attributes); break;
                    case ColumnType.Indicator: children = GenerateLatestCommentIndicator(record, column.DataTitle, attributes); break;
                    case ColumnType.StatusIndicator: children = GenerateStatusIndicator(displayText, column.DataTitle, attributes); break;
                    case ColumnType.CommentIndicator: children = GenerateCommentIndicator(displayText, attributes); break;
                    case ColumnType.CancelIndicator: children = GenerateCancelIndicator(record, column.DataTitle, attributes); break;
                    case ColumnType.Badge: children = GenerateColumnBadge(displayText, column.DataTitle, attributes); break;
                    case ColumnType.Button: children = GenerateColumnButton(record, column, dataSource, record, setDataSource, attributes); break;
                    case ColumnType.Decimal: children = GenerateColumnDecimal(displayText, dataTip, column.LinkAppPage, column.DataTitle, resource, record, configKey, classes, attributes); break;
                    case ColumnType.Phone: children = GenerateColumnPhone(displayText, dataTip, column.LinkAppPage, column.DataTitle, resource, record, configKey, classes, attributes); break;
                    case ColumnType.File: children = GenerateColumnFile(displayText, column.FileConfig.FileFieldName, column.FileConfig.Width, column.FileConfig.Height, column.FileConfig.DownloadUrl, record, classes, attributes); break;
                    case ColumnType.Empty: children = null; break;
                    case ColumnType.MultilineText: children = GenerateColumnMultilineText(displayText, dataTip, column.LinkAppPage, column.DataTitle, resource, record, configKey, classes, attributes); break;
                    case ColumnType.Text:
                    default: children = GenerateColumnText(displayText, dataTip, column.LinkAppPage, column.DataTitle, resource, record, configKey, classes, attributes, column.Symbol); break;
                }
                if (record.isNeedToClarify && window.location.pathname === '/loadplanning/order') {
                    return {
                        props: {
                            style: { background: '#DDC1C1' },
                        },
                        children: children
                    }
                }
                if (record.isTbd) {
                    return {
                        props: {
                            style: { border: '1px solid orangeRed' },
                        },
                        children: 
                        <Tooltip placement="bottomRight" title={'TBD order'}>
                            <div>{children}</div>
                        </Tooltip>
                    }
                }
                if (record.direct) {
                    return {
                        children: 
                        <Tooltip placement="bottomRight" title={'Direct'}>
                            <div>{children}</div>
                        </Tooltip>
                    }
                }
                if (config.List.Coloring) {
                    const bgColor = config.List.Coloring(record, field);
                    if (bgColor) {
                        return {
                            props: {
                                style: {
                                    backgroundColor: bgColor,
                                    color: InvertColor(bgColor, true)
                                },
                            },
                            children: children
                        };
                    }
                    return children;
                }
                else {
                    return {
                        props: {
                            style: classes?.join(' ') === 'empty' ? { background: 'rgb(255, 240, 86)' } : null,
                        },
                        children,
                    }
                }
            }
        });

        if (column.Sorted) {
            defaultSorts.push({
                Member: field,
                SortDirection: column.Sorted === 'ascend' ? 0 : 1
            });
        }
    }
    if (actions && actions.Items && actions.Items.length > 0) {
        if (actions.Items.includes('NeedToClarify')) {
            columns.unshift({
                key: Defines.DefaultActionKey,
                render: (record) => {
                    const attributes = getNormalizedAttributes(actions.Attributes, record);
                    return <div className={`${record.isNeedToClarify === true ? "flag-container-red" : "flag-container-white"}`}>
                        {GenerateColumnAction(record, [actions.Items[0]], resource, onAction, attributes, actions.hasReference)}
                    </div>
                }
            });
            columns.push({
                title: GenerateColumnTitle(CommonLabels.Text.Title.Actions),
                width: actions.Width ? actions.Width : 80,
                key: Defines.DefaultActionKey,
                render: (record) => {
                    const attributes = getNormalizedAttributes(actions.Attributes, record);
                    return <div>
                        {GenerateColumnAction(record, [actions.Items[1], actions.Items[2]], resource, onAction, attributes, actions.hasReference)}
                    </div>
                }
            });
        } 
        if (actions.Items.includes('CreateGroup')) {
            columns.unshift({
                key: Defines.DefaultActionKey,
                render: (record) => {
                    const attributes = getNormalizedAttributes(actions.Attributes, record);
                    return <div>
                        {GenerateColumnAction(record, [actions.Items[1]], resource, onAction, attributes, actions.hasReference)}
                    </div>
                }
            });
        }
    }
    return [columns, defaultSorts];
};

export const getCriteriaQuery = history => {
    return getCriteriaQueryFromLocation(history.location);
    //const search = history.location.search;
    //const searchCriteria = new URLSearchParams(search).get('criteria');
    //if (searchCriteria) {
    //    const decodedSearchCriteria = decodeURI(searchCriteria);
    //    return decodedSearchCriteria;
    //}
    //return null;
};

export const getCriteriaQueryFromLocation = location => {
    const search = location.search;
    const searchCriteria = new URLSearchParams(search).get('criteria');
    if (searchCriteria) {
        const decodedSearchCriteria = decodeURI(searchCriteria);
        return decodedSearchCriteria;
    }
    return null;
};

export const buildListFilterQuery = (searchCriteria, filter) => {
    const criteria = ParseJson(searchCriteria) || {};
    const newFilter = builFilterQueryParams(filter);
    criteria.filter = newFilter;
    if (!criteria.page && !criteria.pageSize && !criteria.sort && !criteria.filter) {
        return '';
    }
    criteria.current = 1;
    return `?criteria=${encodeURI(JSON.stringify(createNewCriteria(criteria)))}`;
};

export const buildListSortPaginationQuery = (searchCriteria, sorts, current, pageSize, defaultPageSize) => {
    const criteria = ParseJson(searchCriteria) || {};
    const newSorts = builFilterQueryParams(sorts);
    criteria.sorts = newSorts;
    criteria.current = current;
    criteria.pageSize = pageSize;
    if (!criteria.page && !criteria.pageSize && !criteria.sorts && !criteria.filter) {
        return '';
    }
    return `?criteria=${encodeURI(JSON.stringify(createNewCriteria(criteria)))}`;
};

const createNewCriteria = criteria => {
    const newCriteria = {};
    if (criteria.filter !== null) {
        newCriteria.filter = criteria.filter;
    }
    if (criteria.sorts !== null) {
        newCriteria.sorts = criteria.sorts;
    }
    if (criteria.current !== null) {
        newCriteria.current = criteria.current;
    }
    if (criteria.pageSize !== null) {
        newCriteria.pageSize = criteria.pageSize;
    }
    return newCriteria;
};

export const getListCriteria = (searchCriteria) => {
    const criteria = ParseJson(searchCriteria);
    if (criteria === null) {
        return {
            current: null,
            pageSize: null,
            sorts: null,
            filter: {}
        };
    }
    if (criteria.current === undefined) {
        criteria.current = null;
    }
    if (criteria.pageSize === undefined) {
        criteria.pageSize = null;
    }
    if (criteria.sorts === undefined) {
        criteria.sorts = null;
    }
    if (criteria.filter === undefined) {
        criteria.filter = {};
    }

    return criteria;
};

export const getListFilter = (searchCriteria) => {
    return getListCriteria(searchCriteria).filter;
};

const builFilterQueryParams = inputO => {
    let outputO = null;
    if (Array.isArray(inputO)) {
        outputO = [];
        for (let i in inputO) {
            const r = builFilterQueryParams(inputO[i]);
            if (r !== null) {
                outputO.push(r);
            }
        }
        if (outputO.length === 0) {
            outputO = null;
        }
    }
    else if (typeof (inputO) === 'object') {
        if (inputO) {
            outputO = {};
            let replaceNull = true;
            for (let k in Object.keys(inputO)) {
                const ok = Object.keys(inputO)[k];
                const o = inputO[ok];
                const r = builFilterQueryParams(o);
                if (r !== null) {
                    outputO[ok] = builFilterQueryParams(o);
                    replaceNull = false;
                }
            }
            if (replaceNull) {
                outputO = null;
            }
        }
    }
    else {
        if (inputO !== null && String(inputO) !== '') {
            outputO = inputO;
        }
    }
    return outputO;
};