import React from 'react';

import ThumbnailImage from './thumbnailImage';
import AuthenticatedLink from './authenticatedLink';
import { ApiUrl } from '../../project/Pages';

const Download = props => {
    return <AuthenticatedLink url={`${ApiUrl.Download}/${props.guid}`} filename={props.filename}>
        <ThumbnailImage guid={props.guid} width={props.width} height={props.height} originalName={props.filename} />
    </AuthenticatedLink>;
};

export default Download;