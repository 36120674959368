export const GET_ACCOUNT_RECEIVABLE_LOADING = 'GET_ACCOUNT_RECEIVABLE_LOADING';
export const GET_ACCOUNT_RECEIVABLE = 'GET_ACCOUNT_RECEIVABLE';
export const ACCOUNT_RECEIVABLE_ERROR = 'ACCOUNT_RECEIVABLE_ERROR';
export const RELOAD_ON_ERROR_ACCOUNT_RECEIVABLE = 'RELOAD_ON_ERROR_ACCOUNT_RECEIVABLE';
export const RESET_ERROR = 'RESET_ERROR';
export const SET_DISPLAY_STATE = 'SET_DISPLAY_STATE';
export const SET_HEADER_WIDTH_ACCOUNT_RECEIVABLE = 'SET_HEADER_WIDTH_ACCOUNT_RECEIVABLE';
export const REACTED_ASSIGNED_AR = 'REACTED_ASSIGNED_AR';
export const SET_ACCOUNT_RECEIVABLE_DATA = 'SET_ACCOUNT_RECEIVABLE_DATA';
export const SORT_ACCOUNT_RECEIVABLE = 'SORT_ACCOUNT_RECEIVABLE';
export const GET_CHANGE_REQUEST_DATA_LOADING = 'GET_CHANGE_REQUEST_DATA_LOADING';
export const GET_CHANGE_REQUEST_DATA = 'GET_CHANGE_REQUEST_DATA';
export const GET_CHANGE_REQUEST_DATA_ERROR = 'GET_CHANGE_REQUEST_DATA_ERROR';
export const SET_ORDER_ID = 'SET_ORDER_ID';
export const SET_CHECKED = 'SET_CHECKED';
export const UPDATE_PRICE_LOADING = 'UPDATE_PRICE_LOADING';
export const UPDATE_PRICE = 'UPDATE_PRICE';
export const UPDATE_PRICE_ERROR = 'UPDATE_PRICE_ERROR';
export const RECALCULATE_PRICE_LOADING = 'RECALCULATE_PRICE_LOADING';
export const RECALCULATE_PRICE = 'RECALCULATE_PRICE';
export const RECALCULATE_PRICE_ERROR = 'RECALCULATE_PRICE_ERROR';
export const SET_PRICE_AS_CALCULATED = 'SET_PRICE_AS_CALCULATED';

export const getActionType = (resource, key) => {
    return ACCOUNT_RECEIVABLE_BUILD[key];
};

export const ACCOUNT_RECEIVABLE_BUILD = {
    GET_ACCOUNT_RECEIVABLE: 'GET_ACCOUNT_RECEIVABLE',
    GET_ACCOUNT_RECEIVABLE_LOADING: 'GET_ACCOUNT_RECEIVABLE_LOADING',
    ACCOUNT_RECEIVABLE_ERROR: 'ACCOUNT_RECEIVABLE_ERROR',
    SORT_ACCOUNT_RECEIVABLE: 'SORT_ACCOUNT_RECEIVABLE',
    RELOAD_ON_ERROR_ACCOUNT_RECEIVABLE: 'RELOAD_ON_ERROR_ACCOUNT_RECEIVABLE',
    SET_HEADER_WIDTH_ACCOUNT_RECEIVABLE: 'SET_HEADER_WIDTH_ACCOUNT_RECEIVABLE',
    SET_DISPLAY_STATE: 'SET_DISPLAY_STATE',
    REACTED_ASSIGNED_AR: 'REACTED_ASSIGNED_AR',
    SET_ACCOUNT_RECEIVABLE_DATA: 'SET_ACCOUNT_RECEIVABLE_DATA',
    RESET_ERROR: 'RESET_ERROR',
    GET_CHANGE_REQUEST_DATA_LOADING: 'GET_CHANGE_REQUEST_DATA_LOADING',
    GET_CHANGE_REQUEST_DATA: 'GET_CHANGE_REQUEST_DATA',
    GET_CHANGE_REQUEST_DATA_ERROR: 'GET_CHANGE_REQUEST_DATA_ERROR',
    SET_ORDER_ID: 'SET_ORDER_ID',
    SET_CHECKED: 'SET_CHECKED',
    UPDATE_PRICE_LOADING: 'UPDATE_PRICE_LOADING',
    UPDATE_PRICE: 'UPDATE_PRICE',
    UPDATE_PRICE_ERROR: 'UPDATE_PRICE_ERROR',
    RECALCULATE_PRICE_LOADING: 'RECALCULATE_PRICE_LOADING',
    RECALCULATE_PRICE: 'RECALCULATE_PRICE',
    RECALCULATE_PRICE_ERROR: 'RECALCULATE_PRICE_ERROR',
    SET_PRICE_AS_CALCULATED: 'SET_PRICE_AS_CALCULATED',
};
