import React, { useRef } from 'react';

import FormField from '../formField';
import { ElementContent } from '../../../../../../helper/element';
import { useElementChange } from '../../../../../../hook/action/useElementChange';
import { useElementOut } from '../../../../../../hook/action/useElementOut';
import { useValidation } from '../../../../../../hook/data/useValidation';
import { useElementDefaultFocus, useElementValidationFocus } from '../../../../../../hook/action/useElementFocus';

const Element = props => {
    const ref = useRef();
    const title = props.Title;//`${props.Title} ${messageIdentity}`;
    const messageIdentity = props.messageIdentityPrefix ? `${props.messageIdentityPrefix}_${props.type}` : props.type;
    useElementValidationFocus(props.messages, ref, messageIdentity);
    useElementDefaultFocus(ref, props.Focus);
    const valudationMessages = props.messages[messageIdentity];
    const validate = useValidation(props.messageDispatch, props.Validations, props.Title, messageIdentity, props.data, props.Warnings);

    const change = (event, data, field) => {
        if (props.Check && !props.Check(event.source, data)) {
            return;
        }

        const type = field ? field : props.type;

        props.dispatch({ type: type, value: data, dependencies: props.Dependencies, outSource: event.source });
    };

    const fieldChangeHandler = useElementChange(validate, change);
    const fieldOutHandler = useElementOut(validate);

    const findValue = (data) => {
        const type = props.type.split('.');
        for (const p of type) {
            if (Array.isArray(data[p])) {
                data = data[p].find(i => i[props.keyField] === props.data[props.keyField]);
            } else {
                data = data[p];
            }
        }

        return data;
    };

    let value = '';
    if (props.data && props.type) {
        if (props.object && -1 !== props.type.indexOf('.')) {
            value = findValue(props.object);
        } else {
            if (props.data && props.data.hasOwnProperty(props.type)) {
                value = props.data[props.type];
            } else if (props.object && props.object.hasOwnProperty(props.type)) {
                value = props.object[props.type];
            }
        }
    }
    //if (props.Title === "Customer") {
    //    return null;
    //}
    const field = ElementContent({
        ...props,
        val: value,
        object: props.data || '',
        fieldChange: fieldChangeHandler,
        fieldOut: fieldOutHandler,
        ref: ref,
        valudationMessages: valudationMessages,
        warningMessages: props.messages.warnings?.[messageIdentity] || [],
        clicked: props.clicked
    });

    return (field &&
        <FormField
            title={title}
            formFieldClass={props.fieldClasses || []}
            titleClass={props.titleClasses || ((title && title !== '') ? [] : null)}>
            {field}
        </FormField>
    );
};

export default Element;