import React, { useEffect, useRef, useCallback, useMemo, useState } from 'react';
import { connect } from "react-redux";
import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeList } from 'react-window';
import { Dropdown, Space, message, Menu } from 'antd';
import './index.css'
import HeaderRow from './headerRow';
import Row from './row';
import Loading from "../../screen/loading";
import { getLastOrders, sortBy } from '../../../../store/actions/customerLastOrders/customerLastOrders';
import { getContentWidth } from '../../../../store/reducers/customerLastOrders/helper/general';
import { useTidioScript } from "../../../hook/data/useTidioScript";

const CustomerLastOrders = props => {
    const { getLastOrders, loading, haseError, resetError, actionMessage, state, total, sortBy } = props;
    const resource = 'customerLastOrders';
    const defRowHeight = 40;
    const scrollSize = 0;
    const lastOrdersRef = useRef(null);
    const listReference = useRef(null);
    const listHeaderReference = useRef(null);
    const lastOrdersHeaderReference = useRef(null);
    const mainScroll = useRef(null);
    const startOnScroll = width => mainScroll.current.scrollLeft = width;
    const headers = state[resource].headers;
    const width = getContentWidth(headers) + scrollSize;
    const [sortValue, setSortValue] = useState('');

    useTidioScript();

    const onScrollX = e => {
        if (listHeaderReference.current && listReference.current && e.target) {
            listHeaderReference.current.scrollLeft =
            listReference.current.scrollLeft =
            e.target.scrollLeft;
        }
        if (lastOrdersHeaderReference.current && listReference.current && e.target) {
            lastOrdersHeaderReference.current.scrollLeft =
            listReference.current.scrollLeft =
            e.target.scrollLeft;
        }
    }

    useEffect(() => {
        getLastOrders('customerOrder', 'mypastorderorderlist');
    }, [resource, getLastOrders]);

    useEffect(() => {
        if (haseError) {
            message.error(actionMessage);
            resetError(resource);
        }
    }, [haseError, actionMessage, resetError, resource]);

    let myCustomerLastOrders = [];

    if (sortValue) {
        myCustomerLastOrders = state[resource]?.sortedCustomerLastOrders;
    } else {
        myCustomerLastOrders = state[resource]?.customerLastOrders;
    };

    const header = useMemo(() => <div ref={listHeaderReference} style={{
        width: '100%',
        overflow: 'hidden',
        borderRadius: '12px 12px 0 0',
        borderLeft: '1px solid #29CABA',
    }}>
        <HeaderRow resource={resource} />
    </div>, [resource]);

    const getMyCustomerLastOrdersItemSize = useCallback(
        index => myCustomerLastOrders?.[index] ? myCustomerLastOrders[index].rowState.height : defRowHeight,
        [myCustomerLastOrders]);

    const RenderLastOrdersRow = useCallback(({ data, index, style }) => (
        (index !== undefined && data[index])
            ? <Row
                listRef={lastOrdersRef}
                data={data[index]}
                itemSize={getMyCustomerLastOrdersItemSize(index)}
                style={style}
                resource={resource}
                startOnScroll={startOnScroll}
                index={index}
            />
            : null
    ), [getMyCustomerLastOrdersItemSize, resource]);

    const allMyCustomerLastOrders = useMemo(() => myCustomerLastOrders &&
    <AutoSizer disableWidth>
        {({ height }) => (
            <>
                <VariableSizeList
                    ref={lastOrdersRef}
                    outerRef={el => { listReference.current = el }}
                    style={{
                        overflowX: 'hidden',
                        overflowY: 'auto',
                        borderBottom: '1px solid #dedede',
                        borderLeft: '1px solid #dedede',
                        borderRight: '1px solid #dedede',
                        borderRadius: '0px 0px 12px 12px',
                    }} 
                    itemData={myCustomerLastOrders}
                    itemCount={myCustomerLastOrders.length}
                    height={myCustomerLastOrders && (myCustomerLastOrders.length * 29) > height - 1 ? height - 14 : myCustomerLastOrders.length * 28.2}
                    itemSize={getMyCustomerLastOrdersItemSize}>
                    {RenderLastOrdersRow}
                </VariableSizeList>
            </>)}
    </AutoSizer>
    , [myCustomerLastOrders, getMyCustomerLastOrdersItemSize, RenderLastOrdersRow]);

    const handleSelectChange = e => {
        sortBy('customerOrder', e.key);
        setSortValue(e.key);
    };
    
    const menu = (
        <Menu className='customer-order__menu' onClick={handleSelectChange}>
            <Menu.Item key="byStatuses">By statuses</Menu.Item>
            <Menu.Item key="byDate">By date</Menu.Item>
            <Menu.Item key="byDirection">By direction</Menu.Item>
        </Menu>
    );
    
    const content = (<>
        <div className='customer-order__upper-header'>
            <div className='customer-order__upper-header__left last_orders_left'>
            <div className='customer-order__upper-header__sort'>
                <div className='sort_icon'></div>
                <Dropdown className='customer-order__dropdown' overlay={menu} placement="bottomLeft">
                    <span onClick={(e) => e.preventDefault()}>
                    <Space>Sort</Space>
                    </span>
                </Dropdown>
            </div>
            <separator className={'customerOrder__separator'} vertical=''></separator>
            <div className='customer-order__upper-header__orders-count_last'>
                Past Orders: <span>{total}</span>
            </div>
            </div>
        </div>
        <div className='headerContainer'>{header}</div>
        <div style={{ flexGrow: 1, width: 'calc(100% - 1px)' }}>{allMyCustomerLastOrders}</div>
        <div style={{
            width: 'calc(100% + 8px)',
            overflowX: 'scroll',
            flexShrink: 0,
            marginBottom: '3px',
            position: 'absolute',
            bottom: '0px',
            height: '7px',
        }}
            ref={mainScroll}
            onScroll={e => onScrollX(e)}>
            <div
                className='table-body-cell'
                style={{
                    maxWidth: `${width}px`,
                    width: `${width}px`,
                    minWidth: `${width}px`,
                }} />
        </div>
    </>);

    return <>{loading ? <Loading /> : content}</>;
};

const mapStateToProps = state => ({
    state: state,
    loading: state.customerLastOrders.loading,
    total: state.customerLastOrders.total
});

const mapDispatchToProps = (dispatch) => ({
    getLastOrders: (resource, endPoint) => dispatch(getLastOrders(resource, endPoint)),
    sortBy: (resource, sortValue) => dispatch(sortBy(resource, sortValue)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomerLastOrders);