export const createSeparator = (itemDefaultSize, headers, type, ind, loadId, regionId) => ({
    row: [{
        key: headers[0].key,
        value: '',
        editable: false,
        height: itemDefaultSize
    }],
    rowState: {
        type: 'separator',
        id: `${type}_separator_${ind}`,
        loadId,
        regionId,
        visible: true,
        reacted: false,
        available: true,
        height: 4
    }
});