import React from 'react';
// import { useCallback } from 'react';
import { connect } from "react-redux";
import { message } from 'antd';

import axios from "../../../axios/axios";
import { openPopup, closePopup, closePopupAsync } from "../../../../store/actions/popup/popup";
import { PopupTypes } from "../../custom/popup/popup";
import { CloneObject } from "../../../helper/common";
import AuthenticatedLink from '../../../elements/authenticatedLink';

import errorHandler from '../../../error/errorHandler';

import RateConfirmationConfig from '../../../screens/RateConfirmation/config';
import SendMail from '../../../screens/RateConfirmation/send';
// import { ComponentTypes } from '../../../config/index';

function RateConfirmation(props) {
  const onEdit = () => {
    const windowKey = "Rate Confirmation";
    const config = CloneObject(RateConfirmationConfig);
    const title = `Edit ${config.Title}`;
    config.screen = config;
    config.screen.Form.DefaultId = props.data[props.keyFieldName];

    props.openPopup({
      windowKey,
      fullScreen: false,
      title: title,
      type: PopupTypes.ConfigForm,
      bodyProps: { windowKey },
      config: config,
      beforeSaveAction: (data, additionalData, updateData, setDefaultData) => {
        onSend(data, additionalData, setDefaultData);
      },
      saveAction: (data, close) => {
        if (close) {
          props.closePopupAsync(windowKey);
        }
      },
      closeAction: () => {
        props.closePopup(windowKey);
      },
    });
  };

  const onSend = (data, additionalData, setDefaultData) => {
    const windowKey = 'Mail To';

    const config = CloneObject(SendMail);
    // config.Form.View.Components.pdf.Component = ComponentTypes.RateConfirmationForm();
    const title = `${config.Title}`;
    config.screen = config;
    config.screen.Form.DefaultId = props.data[props.keyFieldName];

    const save = additionalData?.save;
    const sectionData = {
      loadId: props.data[props.keyFieldName],
      rateConfirmationData: data ? {
        ...data
      } : null,
      ccAddresses: '',
      save: additionalData?.save
    };

    props.openConfirm({
      windowKey: windowKey,
      fullScreen: false,
      title: title,
      type: PopupTypes.ConfigSection,
      bodyProps: { windowKey },
      data: sectionData,
      config: config.Form,
      onSave: (data, setUpdating, close) => {
        data.save = save;
        
        setUpdating && setUpdating(true);
        axios.put(SendMail.Resource, data)
          .then(response => {
            if (response) {
              const result = response.data;
              if (result) {
                if (result.hasError) {
                  message.error(result.message);

                  return;
                }
                message.success(result.message);
                data = data.rateConfirmationData || result.rateConfirmationData;
                if (setDefaultData) {
                  setDefaultData(data);
                }
                close && close();
              }
            }
          })
          .finally(() => {
              !close && setUpdating && setUpdating(false);
          });
        // setUpdating && setUpdating(true);
        // setTimeout(() => {
        //   setUpdating && setUpdating(false);
        //   props.closePopup(windowKey);
        // }, 3000);
      }
    });
  };

  return ((props.data.hasRateConfirmation) ?
    <div className="field_group">
      <div className="form_fields ">
        <div className="section_title">
          <text><b>Rate Confirmation</b></text>
        </div>
        <div className="form_separator"></div>
      </div>
      <div className="form_fields">
        <demospace compact="">
          <icon>file</icon>
          <group compact="">
            <badge filled="">PDF</badge>
          </group>
        </demospace>
        <div className="input_cont">
          <div className="data_help">Configure settings when and how You'll get notified for content changes</div>
        </div>
        <toolbar transparent="">
          <action>
            <div className="button accent" onClick={onEdit}>Edit</div>
            <div className="button accent">
              <AuthenticatedLink url={`${RateConfirmationConfig.Resource}/download/${props.data[props.keyFieldName]}`} filename={'Rate Confirmation.pdf'}>
                Download
              </AuthenticatedLink>
            </div>
            <div className="button accent" onClick={e => { onSend() }}>Send</div>
          </action>
        </toolbar>
      </div>
    </div>
    : null
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    openPopup: (data) => dispatch(openPopup(data)),
    openConfirm: (data) => dispatch(openPopup(data)),
    closePopup: (key) => dispatch(closePopup(key)),
    closePopupAsync: (key) => dispatch(closePopupAsync(key))
  };
};

export default connect(null, mapDispatchToProps)(errorHandler(RateConfirmation, axios));