import React, { useEffect, useCallback } from 'react';
import $ from 'jquery';
import { useDispatch } from 'react-redux';
import { PopupTypes } from './codeless/components/custom/popup/popup';
import { openPopup } from './store/actions/popup/popup';
import moment from 'moment';
import { DatePicker, TimePicker, InputNumber, Select, message } from 'antd';
const { Option } = Select;


export function TemplateDetails(props) {
    const children = [];
    for (let i = 10; i < 36; i++) {
        children.push(<Option key={i.toString(36) + i}>{i.toString(36) + i}</Option>);
    }

    return <div id="windowContent" className="window_cont">
        <form>
            <toolbar><button type="submit" className="button primary"><text>Test Form Submit</text></button> <separator vertical=""></separator> <div className="button accent"><text>Secondary Action</text></div><separator vertical=""></separator>    <div id="disableControls" effect="material" className="button"><text>Disable Controls</text></div> </toolbar>
            <div className="form_container">
                <div className="section_group">


                    <div className="sections ">
                        <div className="form_fields"><div className="data_label">Sample Input</div><input type="text" placeholder="Nice Placeholder" defaultValue="" /></div>

                        <div className="form_fields"><div className="data_label">Sample Disabled Input</div><input type="text" placeholder="Nice Placeholder" defaultValue="" /></div>

                        <div className="form_fields"><div className="data_label">Sample Password Icon</div><div className="input_cont"><input id="txtPassword1" type="password" placeholder="Password" autoComplete="new-password" defaultValue="" /><i></i></div></div>

                        <div className="form_fields"><div className="data_label">Sample Password Text</div><div className="input_cont"><input id="txtPassword2" type="password" placeholder="Password" autoComplete="new-password" defaultValue="" /><i></i></div></div>

                        <div className="form_fields"><div className="data_label">Fixed Label</div><label className="input_cont"><text>Fixed Label</text><input type="text" placeholder="Placeholder" autoComplete="new-password" defaultValue="" /><i></i></label></div>


                        <div className="form_fields"><div className="section_title"><text>Sample Nice Title Goes Here</text></div><div className="form_separator"></div></div>





                        <div className="form_fields">
                            <div className="data_label">Radio Switcher</div>
                            <div id="RadioSwitcher" role-type="checklist" className="option_bar">
                                <label>
                                    <input type="radio" value="value1" name="RS" />
                                    <wrap effect="material"><text>Star Lord</text> </wrap>
                                </label>
                                <label>
                                    <input type="radio" value="value2" name="RS" />
                                    <wrap effect="material"><text>Yondu Udonta</text></wrap>
                                </label>
                                <label>
                                    <input type="radio" value="value3" name="RS" />
                                    <wrap effect="material"><icon>photo</icon> <text>Rocket Raccoon</text> </wrap>
                                </label>
                            </div>
                        </div>

                        <div className="form_fields">
                            <div className="data_label">Checkbox Switcher</div>
                            <div id="CheckboxSwitcher" role-type="checklist" className="option_bar">
                                <label>
                                    <input type="checkbox" value="value1" />
                                    <wrap effect="material"><text>Star Lord</text> </wrap>
                                </label>
                                <label><input type="checkbox" value="value2" />
                                    <wrap effect="material"><text>Yondu Udonta</text></wrap>
                                </label>
                                <label>
                                    <input type="checkbox" value="value3" />
                                    <wrap effect="material"><text>Rocket Raccoon</text> </wrap>
                                </label>
                            </div>
                        </div>


                    </div>




                    <div className="sections ">
                        <div className="form_fields">
                            <div className="data_label">Input Container With Action</div>
                            <div className="input_cont">
                                <icon>unlock</icon>
                                <input required="required" type="text" placeholder="Placeholder"/>
                                <div effect="material" className="button"><text>Action</text></div>
                                <i></i>
                            </div>
                        </div>
                        <div className="form_fields">
                            <div className="data_label">Sample Password Icon</div>
                            <div className="input_cont">
                                <input id="" type="password" required="required" placeholder="Password" autoComplete="new-password" password="password" />
                                <div className="button micro"><icon>eye</icon></div>
                                <i></i>
                            </div>
                        </div>
                        <div className="form_fields"><div className="data_label">Readonly Container</div><div className="data_read"><text>Sample Readonly Container</text></div></div>
                        <div className="form_fields"><div className="data_label">Text Area<span>Optional</span></div><textarea placeholder="Fixed Height"></textarea></div>
                        <div className="form_fields"><div className="data_label">Cell Phone<span>Optional</span></div><input placeholder="Phone" type="text" defaultValue="" /><input placeholder="Other phone" type="text" defaultValue="" /></div><div className="field_group">
                        <div className="form_fields large"><div className="data_label">City<tip>Small Help Tip</tip></div><input type="text" defaultValue="" /></div>
                        <div className="form_fields"><div className="data_label">Postal Code</div><input name="ZipPostal" type="text" defaultValue="" /></div></div>
                        <div className="form_fields"><div className="form_separator"></div></div>



                        <div className="form_fields">
                            <div className="data_label">Notifications</div>
                            <div className="data_help">Configure settings when and how You'll get notified for content changes</div>
                            <div className="option_group">
                                <div className="option_bar">
                                    <div title=""><text>New Content</text></div>
                                    <label data-tip="Get Notified via Email" effect="material">
                                        <input type="checkbox" value="value1" />
                                        <wrap><icon>mail</icon></wrap>
                                    </label>
                                    <label data-tip="Get Notified via Notification Center" effect="material">
                                        <input type="checkbox" value="value2" />
                                        <wrap><icon>bell</icon></wrap>
                                    </label>
                                </div>
                            </div>
                        </div>

                    </div>


                    <div className="sections">
                        <div className="form_fields">
                            <div className="section_title"><text>Sample Nice Title Goes Here</text></div>
                            <div className="form_separator"></div>
                        </div>
                        <div className="form_fields">
                            <div className="data_label">Drop Down List</div>
                            <Select placeholder="Select Character" id="ddlAntd" size="default" defaultValue="a1">
                                {children}
                            </Select>
                        </div>

                        <div className="form_fields">
                            <div className="data_label">Combo Box</div>
                            <Select
                                showSearch
                                placeholder="Select Character" id="ddlgAntd"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {children}
                            </Select>
                        </div>

                        <div className="form_fields">
                            <div className="data_label">Multi Select</div>
                            <Select
                                mode="multiple"
                                size="default"
                                placeholder="Please select"
                                defaultValue={['a10', 'c12']}>
                                {children}
                            </Select>
                        </div>

                        <div className="form_fields">
                            <div className="data_label">Date Picker</div>
                            <DatePicker name="dtpAntd" defaultValue={moment('2015/01/01', 'YYYY/MM/DD')} format='MM/DD/YY' />
                        </div>

                        <div className="form_fields">
                            <div className="data_label">Time Picker</div>
                            <TimePicker name="tpAntd" defaultValue={moment('12:08:23', 'HH:mm:ss')} />
                        </div>

                        <div className="form_fields">
                            <div className="data_label">Numeric</div>
                            <InputNumber min={1} max={100000} defaultValue={3} />
                        </div>

                        <div className="form_fields">
                            <div className="data_label">Date/Time Picker<span>Optional</span></div>
                            <DatePicker name="dtpAntd" defaultValue={moment('2015/01/01', 'YYYY/MM/DD')} format='MM/DD/YY' />
                            <TimePicker name="tpAntd" defaultValue={moment('12:08:23', 'HH:mm:ss')} />
                        </div>
                    </div>

                    <div className="sections">

                        <div className="form_fields input-validation-error">
                            <div className="data_label">Uploader</div>
                            <span className="ant-upload-picture-card-wrapper">
                                <div className="ant-upload ant-upload-select ant-upload-select-picture-card">
                                    <span tabindex="0" className="ant-upload">
                                        <div>
                                            <span role="img" aria-label="plus" className="anticon anticon-plus">
                                                <svg viewBox="64 64 896 896" focusable="false" data-icon="plus" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                                                    <defs></defs>
                                                    <path d="M482 152h60q8 0 8 8v704q0 8-8 8h-60q-8 0-8-8V160q0-8 8-8z"></path>
                                                    <path d="M176 474h672q8 0 8 8v60q0 8-8 8H176q-8 0-8-8v-60q0-8 8-8z"></path>
                                                </svg>
                                            </span>
                                            <div className="ant-upload-text">Upload</div>
                                        </div>
                                    </span>
                                </div>
                            </span>
                            <div className="input_cont">
                                <icon yellow="yellow">warning</icon>
                                <div className="data_help">Configure settings when and how You'll get notified for content changes</div>
                            </div>


                        </div>
                        <div className="form_fields">
                            <div className="data_label">Text Area<span>Optional</span></div>
                            <textarea placeholder="Fixed Height" className="input-validation-error"></textarea>
                        </div>

                        <div className="form_fields">
                            <div className="data_label">Sample Input Icon</div>
                            <div className="input_cont">
                                <input id="" className="input-validation-error" type="text" required="" placeholder="Input" />
                                <div className="button micro"><icon>eye</icon></div>
                                <i></i>
                            </div>
                        </div>



                        <div className="form_fields">
                            <div className="data_label">Drop Down List</div>
                            <Select className="input-validation-error" placeholder="Select Character" id="ddlAntd" size="default" defaultValue="a1">
                                {children}
                            </Select>
                        </div>

                        <div className="form_fields">
                            <div className="data_label">Combo Box</div>
                            <Select
                                className="input-validation-error"
                                showSearch
                                placeholder="Select Character" id="ddlgAntd"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {children}
                            </Select>
                        </div>

                        <div className="form_fields">
                            <div className="data_label">Multi Select</div>
                            <Select
                                className="input-validation-error"
                                mode="multiple"
                                size="default"
                                placeholder="Please select"
                                defaultValue={['a10', 'c12']}>
                                {children}
                            </Select>
                        </div>

                        <div className="form_fields">
                            <div className="data_label">Date Picker</div>
                            <DatePicker className="input-validation-error" name="dtpAntd" defaultValue={moment('2015/01/01', 'YYYY/MM/DD')} format='MM/DD/YY' />
                        </div>

                        <div className="form_fields">
                            <div className="data_label">Time Picker</div>
                            <TimePicker className="input-validation-error" name="tpAntd" defaultValue={moment('12:08:23', 'HH:mm:ss')} />
                        </div>

                        <div className="form_fields">
                            <div className="data_label">Numeric</div>
                            <InputNumber className="input-validation-error" min={1} max={100000} defaultValue={3} />
                        </div>

                        <div className="form_fields">
                            <div className="data_label">Date/Time Picker<span>Optional</span></div>
                            <DatePicker className="input-validation-error" name="dtpAntd" defaultValue={moment('2015/01/01', 'YYYY/MM/DD')} format='MM/DD/YY' />
                            <TimePicker className="input-validation-error" name="tpAntd" defaultValue={moment('12:08:23', 'HH:mm:ss')} />
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
};

export const Template = () => {
    const dispatch = useDispatch();

    const loadData = useCallback(() => {
        $(document).ready(function () {
            function counter() {
                $("[counter]").each(function () {
                    var count = $(this).find('count')
                    var data = $(this).find('wrap').children('item:visible').length,
                        countTo = data;
                    if (data < 1) {
                        $(this).addClass('no_result');
                    }
                    else {
                        $(this).removeClass('no_result');
                    }
                    $({ countNum: count.text() }).animate({
                        countNum: countTo
                    },
                        {
                            duration: 250,
                            easing: 'linear',
                            step: function () {
                                count.text(Math.floor(this.countNum));
                            },
                            complete: function () {
                                count.text(this.countNum);
                            }
                        });
                });

            }
            $(".tab_strip").each(function (index) {
                var tab = $(this);
                $(tab.find("li")[0]).addClass("selected");
                $(tab.find(">div>div")[0]).addClass("selected");

                tab.find("li").each(function (idx) {
                    var tab_li = $(this);
                    tab_li.on("click", function (e) {
                        tab.find("li").removeClass("selected");
                        tab.find(">div>div").removeClass("selected");
                        tab_li.addClass("selected");
                        $(tab.find(">div>div")[idx]).addClass("selected");
                    })
                })
            });

            var icon_count = $("[icons]").find('item').length;
            $(".icon_search").attr("placeholder", "Search " + icon_count + " Icons");
            $(".icon_search").on("keyup", function () {
                counter();
                var value = $(this).val().toLowerCase();
                $("grid wrap item").filter(function () {
                    return $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
                });
            });

            var item = $("view[icons]").find("item").on("click", function () {
                item.not(this).removeClass('selected');
                $(this).toggleClass('selected');
                message.success("Icon Name Copied to Clipboard");
                var $temp = $("<input>");
                $("body").append($temp);
                $temp.val($(this).find("icon").text()).select();
                document.execCommand("copy");
                $temp.remove();
            });
            counter();
            $("#btnOpenWindow").click(function () {
                dispatch(openPopup({
                    windowKey: "key1",
                    fullScreen: false,
                    title: $(this).text(),
                    type: PopupTypes.TemplateDetails,
                }));
            });

            $("#btnOpenFullscreenWindow").click(function () {
                dispatch(openPopup({
                    windowKey: "key2",
                    fullScreen: true,
                    title: $(this).text(),
                    type: PopupTypes.TemplateDetails,
                }));
            });

            $("#btnOpenNoHeaderWindow").click(function () {
                dispatch(openPopup({
                    windowKey: "key3",
                    fullScreen: false,
                    showHeader: false,
                    title: $(this).text(),
                    type: PopupTypes.TemplateDetails,
                }));
            });

        });
    }, [dispatch]);

    useEffect(() => {
        loadData();
    }, [loadData]);

        const children = [];
        for (let i = 10; i < 36; i++) {
            children.push(<Option key={i.toString(36) + i}>{i.toString(36) + i}</Option>);
        }

        return (
            <>
                <div className="tab_strip">

                    <wrap>
                        <ul>
                            <li effect="material"><text>Icons & Badges</text></li>
                            <li effect="material"><text>Buttons & Switchers</text></li>
                            <li effect="material"><text>Windows & Popups</text></li>
                            <li effect="material"><text>Inputs & Forms</text></li>
                            <li effect="material"><text>Overlay Toolbar</text></li>
                            <li effect="material"><text>List View</text></li>
                            <li effect="material"><text>Epandable Table</text></li>
                            <li effect="material"><text>Table view</text></li>
                            <li effect="material"><text>Dashboard</text></li>
                        </ul>
                    </wrap>
                    <div>
                        <div>
                            <container>
                                <view icons="true">
                                    <toolbar className="srch" effect="material"><icon>search</icon> <input className="icon_search" type="text" placeholder="Search Here" /></toolbar>
                                    <scroller>
                                        <grid counter="">
                                            <toolbar><count data-count="1024">0</count>  <separator vertical=""></separator><div className="button">Section Title</div></toolbar>
                                            <wrap>
                                                <item effect="material"> <tag></tag> <icon>calc</icon><text>Calculator</text></item>
                                                <item effect="material"> <tag>transcode</tag> <icon>convert</icon><text>Convert</text></item>
                                                <item effect="material"> <tag>tools</tag> <icon>wrench</icon><text>Wrench</text></item>
                                                <item effect="material"> <tag>plane chat</tag> <icon>send</icon><text>Plane</text></item>
                                                <item effect="material"> <tag>house</tag> <icon>home</icon><text>Home</text></item>
                                                <item effect="material"> <tag>message social bubble</tag> <icon>chat</icon><text>Chat</text></item>
                                                <item effect="material"> <tag>device monitor</tag> <icon>display</icon><text>Display</text></item>
                                                <item effect="material"> <tag>device monitor</tag> <icon>tall_display</icon><text>Display</text></item>
                                                <item effect="material"> <tag>idea info</tag> <icon>bulb</icon><text>Bulb</text></item>
                                                <item effect="material"> <tag>warning</tag> <icon>error</icon><text>Error</text></item>
                                                <item effect="material"> <tag>social save bookmark</tag> <icon>heart</icon><text>Heart</text></item>
                                                <item effect="material"> <tag>social save bookmark</tag> <icon>heart_filled</icon><text>Heart</text></item>
                                                <item effect="material"> <tag>social</tag> <icon>twitter</icon><text>Twitter</text></item>
                                                <item effect="material"> <tag>error</tag> <icon>warning</icon><text>Warning</text></item>
                                                <item effect="material"> <tag></tag> <icon>question</icon><text>Question Mark</text></item>
                                                <item effect="material"> <tag>done</tag> <icon>success</icon><text>Success</text></item>
                                                <item effect="material"> <tag>archive box</tag> <icon>box</icon><text>Archive</text></item>
                                                <item effect="material"> <tag>photo picture</tag> <icon>photo</icon><text>Photo</text></item>
                                                <item effect="material"> <tag></tag> <icon>book</icon><text>Book</text></item>
                                                <item effect="material"> <tag>pencil</tag> <icon>edit</icon><text>Pencil</text></item>
                                                <item effect="material"> <tag></tag> <icon>expand</icon><text>Expand</text></item>
                                                <item effect="material"> <tag></tag> <icon>eye</icon><text>Eye</text></item>
                                                <item effect="material"> <tag></tag> <icon>file</icon><text>File</text></item>
                                                <item effect="material"> <tag></tag> <icon>filter</icon><text>Filter</text></item>
                                                <item effect="material"> <tag></tag> <icon>flag</icon><text>Flag</text></item>
                                                <item effect="material"> <tag>file </tag> <icon>folder</icon><text>Folder</text></item>
                                                <item effect="material"> <tag></tag> <icon>full_left</icon><text>End</text></item>
                                                <item effect="material"> <tag>notificaion</tag> <icon>bell</icon><text>Bell</text></item>
                                                <item effect="material"> <tag></tag> <icon>bookmark</icon><text>Bookmark</text></item>
                                                <item effect="material"> <tag></tag> <icon>bookmark_filled</icon><text>Bookmark</text></item>
                                                <item effect="material"> <tag></tag> <icon>calendar</icon><text>Calendar</text></item>
                                                <item effect="material"> <tag>arrow</tag> <icon>down</icon><text>Down</text></item>
                                                <item effect="material"> <tag>arrow</tag> <icon>download</icon><text>Download</text></item>
                                                <item effect="material"> <tag></tag> <icon>drink</icon><text>Drink</text></item>
                                                <item effect="material"> <tag>photo picture</tag> <icon>camera</icon><text>Camera</text></item>
                                                <item effect="material"> <tag></tag> <icon>cancel</icon><text></text>Cancel</item>
                                                <item effect="material"> <tag></tag> <icon>cast</icon><text>Cast</text></item>
                                                <item effect="material"> <tag></tag> <icon>clock</icon><text>Clock</text></item>
                                                <item effect="material"> <tag></tag> <icon>close</icon><text>Close</text></item>
                                                <item effect="material"> <tag></tag> <icon>collapse</icon><text>Collapse</text></item>
                                                <item effect="material"> <tag>view</tag> <icon>column</icon><text>Column</text></item>
                                                <item effect="material"> <tag>view</tag> <icon>list</icon><text>Grid</text></item>
                                                <item effect="material"> <tag></tag> <icon>cube</icon><text>Cube</text></item>
                                                <item effect="material"> <tag>configuration control dashboard setting speed system tool</tag> <icon>dashboard</icon>Dashboard<text></text></item>
                                                <item effect="material"> <tag>remove</tag> <icon>delete</icon><text>Delete</text></item>
                                                <item effect="material"> <tag>success</tag> <icon>done</icon><text>Done</text></item>
                                                <item effect="material"> <tag></tag><icon>woven</icon><text>Woven</text></item>
                                                <item effect="material"> <tag></tag><icon>woven_filled</icon><text>Woven</text></item>
                                                <item effect="material"> <tag>clipboard</tag><icon>paste</icon><text>Paste</text></item>
                                                <item effect="material"> <tag></tag><icon>rss</icon><text>Rss</text></item>
                                                <item effect="material"> <tag></tag><icon>copy</icon><text>Copy</text></item>
                                                <item effect="material"> <tag>Paint</tag><icon>fill</icon><text>Fill</text></item>
                                                <item effect="material"> <tag></tag><icon>code</icon><text>Code</text></item>
                                                <item effect="material"> <tag>code</tag><icon>embed</icon><text>Embed</text></item>
                                                <item effect="material"> <tag>delete</tag><icon>remove</icon><text>Remove</text></item>
                                            </wrap>
                                        </grid>
                                        <grid counter="">
                                            <toolbar><count>0</count>  <separator vertical=""></separator><div className="button">Section Title</div></toolbar>
                                            <wrap>
                                                <item effect="material"> <tag>arrow</tag> <icon>revert</icon><text>Revert</text></item>
                                                <item effect="material"> <tag>arrow</tag> <icon>undo</icon><text>Undo</text></item>
                                                <item effect="material"> <tag>refresh reload load</tag> <icon>reload</icon><text>Reload</text></item>
                                                <item effect="material"> <tag>refresh reload load</tag> <icon>refresh</icon><text>Refresh</text></item>
                                                <item effect="material"> <tag>refresh reload load</tag> <icon>load</icon><text>Load</text></item>
                                                <item effect="material"> <tag>refresh reload load</tag> <icon>process</icon><text>Process</text></item>
                                                <item effect="material"> <tag></tag> <icon>full_right</icon><text>Start</text></item>
                                                <item effect="material"> <tag></tag> <icon>fullscreen_exit</icon><text>Exit Fullscreen</text></item>
                                                <item effect="material"> <tag></tag> <icon>fullscreen</icon><text></text>Fullscreen</item>
                                                <item effect="material"> <tag>menu navigation</tag> <icon>grid</icon><text>Grid</text></item>
                                                <item effect="material"> <tag>picture photo</tag> <icon>image</icon><text>Image</text></item>
                                                <item effect="material"> <tag></tag> <icon>info</icon><text>Info</text></item>
                                                <item effect="material"> <tag>arrow</tag> <icon>left</icon><text>Left</text></item>
                                                <item effect="material"> <tag></tag> <icon>link</icon><text>Link</text></item>
                                                <item effect="material"> <tag></tag> <icon>lock</icon><text>Lock</text></item>
                                                <item effect="material"> <tag>envelope</tag> <icon>mail</icon><text>Mail</text></item>
                                                <item effect="material"> <tag>navigation</tag> <icon>menu</icon><text>Menu</text></item>
                                                <item effect="material"> <tag> delete remove</tag> <icon>minus</icon><text>Minus</text></item>
                                                <item effect="material"> <tag>dark weather</tag> <icon>moon</icon><text>Moon</text></item>
                                                <item effect="material"> <tag>menu</tag> <icon>more</icon><text>More</text></item>
                                                <item effect="material"> <tag>menu</tag> <icon>options</icon><text>Option</text></item>
                                                <item effect="material"> <tag>map</tag> <icon>pin</icon><text>Pin</text></item>
                                                <item effect="material"> <tag></tag> <icon>play_filled</icon><text>Play</text></item>
                                                <item effect="material"> <tag></tag> <icon>play</icon><text>Play</text></item>
                                                <item effect="material"> <tag></tag> <icon>Pause</icon><text>Pause</text></item>
                                                <item effect="material"> <tag></tag> <icon>volume_up</icon><text>Speaker</text></item>
                                                <item effect="material"> <tag></tag> <icon>volume_off</icon><text>Speaker</text></item>
                                                <item effect="material"> <tag>volume</tag> <icon>mute</icon><text>Speaker</text></item>
                                                <item effect="material"> <tag>add add</tag> <icon>plus</icon><text>Plus</text></item>
                                                <item effect="material"> <tag>add add</tag> <icon>create</icon><text>Create</text></item>
                                                <item effect="material"> <tag>arrow</tag> <icon>right</icon><text>Right</text></item>
                                                <item effect="material"> <tag>disk</tag> <icon>save</icon><text></text>Save</item>
                                                <item effect="material"> <tag>find</tag> <icon>search</icon><text>Search</text></item>
                                                <item effect="material"> <tag>gear</tag> <icon>settings</icon><text>Setting</text></item>
                                                <item effect="material"> <tag></tag> <icon>share</icon><text>Share</text></item>
                                            </wrap>
                                        </grid>
                                        <grid counter="">
                                            <toolbar><count>0</count>  <separator vertical=""></separator><div className="button">Section Title</div></toolbar>
                                            <wrap>
                                                <item effect="material"> <tag>filter</tag> <icon>sort</icon><text>Filter</text></item>
                                                <item effect="material"> <tag></tag> <icon>star_filled</icon><text>Star</text></item>
                                                <item effect="material"> <tag></tag> <icon>star</icon><text></text>Star</item>
                                                <item effect="material"> <tag></tag> <icon>table</icon><text>Table</text></item>
                                                <item effect="material"> <tag></tag> <icon>tag</icon><text>Tag</text></item>
                                                <item effect="material"> <tag></tag> <icon>tag_filled</icon><text>Tag</text></item>
                                                <item effect="material"> <tag>view</tag> <icon>thumbnail</icon><text>Thumbnail</text></item>
                                                <item effect="material"> <tag></tag> <icon>ticket</icon><text>Ticket</text></item>
                                                <item effect="material"> <tag>lock</tag> <icon>unlock</icon><text>Lock</text></item>
                                                <item effect="material"> <tag>arrow</tag> <icon>up</icon><text>Up</text></item>
                                                <item effect="material"> <tag>arrow</tag> <icon>upload</icon><text>Upload</text></item>
                                                <item effect="material"> <tag>account</tag> <icon>user</icon><text>User</text></item>
                                            </wrap>
                                        </grid>
                                        <grid counter="">
                                            <toolbar><count data-count="1024">0</count>  <separator vertical=""></separator><div className="button">Video</div></toolbar>
                                            <wrap>
                                                <item effect="material"> <tag></tag> <icon>720</icon><text>720</text></item>
                                                <item effect="material"> <tag>full hd </tag> <icon>1080</icon><text>1080</text></item>
                                                <item effect="material"> <tag></tag> <icon>hd</icon><text>High Definition</text></item>
                                                <item effect="material"> <tag></tag> <icon>fhd</icon><text>Full HD</text></item>
                                                <item effect="material"> <tag></tag> <icon>uhd</icon><text>Ultra HD</text></item>
                                                <item effect="material"> <tag></tag> <icon>hdr</icon><text>High Dinamic Range</text></item>
                                                <item effect="material"> <tag></tag> <icon>2k</icon><text>2K</text></item>
                                                <item effect="material"> <tag></tag> <icon>4k</icon><text>4K</text></item>
                                                <item effect="material"> <tag></tag> <icon>shd</icon><text>Super HD</text></item>
                                            </wrap>
                                        </grid>
                                        <grid counter="">
                                            <toolbar><count>0</count>  <separator vertical=""></separator><div className="button">Editor</div></toolbar>
                                            <wrap>
                                                <item effect="material"> <tag></tag><icon>center</icon><text>Center</text></item>
                                                <item effect="material"> <tag></tag><icon>align_left</icon><text>Align Left</text></item>
                                                <item effect="material"> <tag></tag><icon>align_right</icon><text>Align Right</text></item>
                                                <item effect="material"> <tag></tag><icon>align_top</icon><text>Align Top</text></item>
                                                <item effect="material"> <tag></tag><icon>align_bottom</icon><text>Align Bottom</text></item>
                                                <item effect="material"> <tag></tag><icon>align_middle</icon><text>Align Middle</text></item>
                                                <item effect="material"> <tag></tag><icon>bold</icon><text></text>Bold</item>
                                                <item effect="material"> <tag></tag><icon>underline</icon><text>Underline</text></item>
                                                <item effect="material"> <tag></tag><icon>italic</icon><text>Italic</text></item>
                                                <item effect="material"> <tag></tag><icon>bullets</icon><text>bullets</text></item>
                                                <item effect="material"> <tag></tag><icon>strikethrough</icon><text>Strikethrough</text></item>
                                                <item effect="material"> <tag></tag><icon>t_text</icon><text>Text</text></item>
                                                <item effect="material"> <tag></tag><icon>text_shadow</icon><text>Text Effect</text></item>
                                                <item effect="material"> <tag></tag><icon>text_transform</icon><text>Uppercase</text></item>
                                                <item effect="material"> <tag></tag><icon>text</icon><text>Text</text></item>
                                                <item effect="material"> <tag></tag><icon>left_text</icon><text>Text</text></item>
                                                <item effect="material"> <tag></tag><icon>right_text</icon><text>Text</text></item>
                                                <item effect="material"> <tag></tag><icon>uppercase</icon><text>Text</text></item>
                                                <item effect="material"> <tag></tag><icon>lowercase</icon><text>Text</text></item>
                                            </wrap>
                                        </grid>
                                        <grid counter="">
                                            <toolbar><count data-count="1024">0</count>  <separator vertical=""></separator><div className="button">Playlist Layout</div></toolbar>
                                            <wrap>
                                                <item effect="material"> <tag></tag> <icon>layout_9</icon><text>Layout</text></item>
                                                <item effect="material"> <tag></tag> <icon>layout_9_1</icon><text>Layout</text></item>
                                                <item effect="material"> <tag></tag> <icon>layout_10_2</icon><text>Layout</text></item>
                                                <item effect="material"> <tag></tag> <icon>layout_10_1</icon><text>Layout</text></item>
                                                <item effect="material"> <tag></tag> <icon>layout_11</icon><text>Layout</text></item>
                                                <item effect="material"> <tag></tag> <icon>layout_1_1</icon><text>Layout</text></item>
                                                <item effect="material"> <tag></tag> <icon>layout_1_2</icon><text>Layout</text></item>
                                                <item effect="material"> <tag></tag> <icon>layout_1</icon><text>Layout</text></item>
                                                <item effect="material"> <tag></tag> <icon>layout_2_1</icon><text>Layout</text></item>
                                                <item effect="material"> <tag></tag> <icon>layout_2_2</icon><text>Layout</text></item>
                                                <item effect="material"> <tag></tag> <icon>layout_2</icon><text>Layout</text></item>
                                                <item effect="material"> <tag></tag> <icon>layout_3_1</icon><text>Layout</text></item>
                                                <item effect="material"> <tag></tag> <icon>layout_3_2</icon><text>Layout</text></item>
                                                <item effect="material"> <tag></tag> <icon>layout_3_3</icon><text>Layout</text></item>
                                                <item effect="material"> <tag></tag> <icon>layout_3</icon><text>Layout</text></item>
                                                <item effect="material"> <tag></tag> <icon>layout_4_1</icon><text>Layout</text></item>
                                                <item effect="material"> <tag></tag> <icon>layout_4_2</icon><text>Layout</text></item>
                                                <item effect="material"> <tag></tag> <icon>layout_4_3</icon><text>Layout</text></item>
                                                <item effect="material"> <tag></tag> <icon>layout_4</icon><text>Layout</text></item>
                                                <item effect="material"> <tag></tag> <icon>layout_5_1</icon><text>Layout</text></item>
                                                <item effect="material"> <tag></tag> <icon>layout_5_2</icon><text>Layout</text></item>
                                                <item effect="material"> <tag></tag> <icon>layout_5_3</icon><text>Layout</text></item>
                                                <item effect="material"> <tag></tag> <icon>layout_5</icon><text>Layout</text></item>
                                                <item effect="material"> <tag></tag> <icon>layout_6_1</icon><text>Layout</text></item>
                                                <item effect="material"> <tag></tag> <icon>layout_6_2</icon><text>Layout</text></item>
                                                <item effect="material"> <tag></tag> <icon>layout_6_3</icon><text>Layout</text></item>
                                                <item effect="material"> <tag></tag> <icon>layout_6</icon><text>Layout</text></item>
                                                <item effect="material"> <tag></tag> <icon>layout_7_1</icon><text>Layout</text></item>
                                                <item effect="material"> <tag></tag> <icon>layout_7_2</icon><text>Layout</text></item>
                                                <item effect="material"> <tag></tag> <icon>layout_7_3</icon><text>Layout</text></item>
                                                <item effect="material"> <tag></tag> <icon>layout_7</icon><text>Layout</text></item>
                                                <item effect="material"> <tag></tag> <icon>layout_8_1</icon><text>Layout</text></item>
                                                <item effect="material"> <tag></tag> <icon>layout_8_2</icon><text>Layout</text></item>
                                                <item effect="material"> <tag></tag> <icon>layout_8_3</icon><text>Layout</text></item>
                                                <item effect="material"> <tag></tag> <icon>layout_8</icon><text>Layout</text></item>
                                                <item effect="material"> <tag></tag> <icon>layout_portrait</icon><text>Layout</text></item>
                                                <item effect="material"> <tag></tag> <icon>layout</icon><text>Layout</text></item>
                                            </wrap>
                                        </grid>
                                    </scroller>
                                </view>
                            </container>

                        </div>
                        <div>
                            <view vertical="">
                                <view>
                                    <toolbar scroll=""><wrap><div className="button">Button & Toggles</div></wrap></toolbar>

                                    <list demo="">
                                        <demo_wrap>
                                            <div effect="material" className="button primary"><text>Primary Button</text><icon>star</icon></div>
                                            <div effect="material" className="button accent"><icon>search</icon><text>Accent Button</text></div>
                                            <div effect="material" className="button"><text>Simple Button</text></div>
                                            <a effect="material" href="https://www.google.com/" className="button"><icon>heart</icon><text>Anchor Button</text></a>
                                            <div effect="material" data-tip="This Is Responsive Button, Resize Browser Window" className="button adaptive white user_name"><icon>pin</icon><text>Responsive button</text></div>
                                        </demo_wrap>
                                        <demo_wrap>
                                            <div effect="material" className="button error"><tip>Reconfiguring ...</tip><separator vertical=""></separator><text>Cancel</text></div>
                                        </demo_wrap>
                                        <demo_wrap>
                                            <div effect="material" data-tip="This Is Mini Button" className="button primary mini"><icon>bulb</icon></div>
                                            <div effect="material" data-tip="This Is Mini Button" className="button accent mini"><icon>photo</icon></div>
                                            <div effect="material" data-tip="This Is Mini Button" className="button mini"><icon>share</icon></div>
                                        </demo_wrap>
                                        <demo_wrap>
                                            <div effect="material" className="button primary large"><icon>play</icon><text>Large Button</text></div>
                                            <div effect="material" className="button accent large"><icon>mail</icon><text>Large Button</text></div>
                                            <div effect="material" className="button large"><icon>edit</icon><text>Large Button</text></div>
                                        </demo_wrap>
                                        <demo_wrap>
                                            <label classic=""><input type="checkbox" /><box><check></check></box><text>Simple Checkbox</text></label>
                                            <label classic=""><input type="checkbox" /><box><check></check></box><text>Simple Checkbox</text></label>
                                            <separator vertical=""></separator>
                                            <label classic=""><input defaultChecked={true} type="checkbox" disabled="disabled" /><box><check></check></box><text>Simple Checkbox</text></label>
                                            <label classic=""><input type="checkbox" /><box><check></check></box><text><separator vertical=""></separator></text>Simple Checkbox</label>
                                            <separator vertical=""></separator>
                                            <label classic=""><input type="checkbox" /><text>Simple Checkbox</text><separator vertical=""></separator><box><check></check></box></label>
                                        </demo_wrap>

                                        <demo_wrap>
                                            <label checkbox=""><input type="checkbox" /><box><check></check></box><text>Checkbox</text></label>
                                            <label checkbox=""><input disabled="disabled" defaultChecked={true} type="checkbox" /><box><check></check></box><text>Disabled Checkbox</text></label>
                                            <label checkbox="" simple=""><input type="checkbox" /><box><check></check></box><text></text></label>
                                            <separator vertical=""></separator>
                                            <label checkbox=""><input defaultChecked={true} type="checkbox" /><box><check></check></box><text>Checkbox</text></label>
                                        </demo_wrap>


                                        <demo_wrap>
                                            <label data-tip="This can be used only to activate section, examples on <b>Input Forms</b> Section" simple="" checkbox="">
                                                <name><b>Section Title</b></name>
                                                <separator vertical=""></separator>
                                                <input type="checkbox" />
                                                <tip></tip>
                                            </label>

                                        </demo_wrap>



                                        <demo_wrap>
                                            <label radio=""><input name="sample" defaultChecked={true} type="radio" /><box><check></check></box><text>Radio Button Title</text></label>
                                            <label radio=""><input name="sample" type="radio" /><box><check></check></box><text>Radio Button Title</text></label>
                                            <separator vertical=""></separator>
                                            <label radio=""><input disabled="disabled" defaultChecked={true} name="sample" type="radio" /><box><check></check></box><text>Disabled Radio Button</text></label>
                                        </demo_wrap>
                                    </list>

                                </view>
                                <view>
                                    <toolbar><div className="caption"><text><b>Badges</b></text></div></toolbar>
                                    <list demo="">

                                        <demo_wrap>
                                            <group>
                                                <badge>Simple Outline Badge</badge>
                                                <separator vertical=""></separator>
                                                <badge accent="" filled="">Accent Color Badge</badge>
                                            </group>
                                        </demo_wrap>

                                        <demo_wrap>
                                            <group>

                                                <badge error="" filled="">Error</badge>
                                                <badge new="" filled="">New Item</badge>
                                                <badge private="" filled="">Private Item</badge>
                                                <badge process="" filled="">Yellow Item</badge>
                                            </group>
                                        </demo_wrap>


                                        <demo_wrap>
                                            <group>

                                                <badge error="">Error</badge>
                                                <badge new="">New Item</badge>
                                                <badge private="">Private Item</badge>
                                            </group>
                                        </demo_wrap>

                                    </list>
                                </view>
                            </view>
                        </div>

                        <div>
                            <container>

                                <view>
                                    <toolbar>
                                        <div id="btnActionControl" data-tip="This Will Open Context menu" effect="material" className="button mini"><icon>options</icon></div>
                                        <separator vertical=""></separator>
                                        <div style={{ marginLeft: "auto" }} id="btnSort" effect="material" className="button mini"><icon>sort</icon></div>
                                    </toolbar>

                                    <dashboard demo="">
                                        <wrap>
                                            <item accent="">
                                                <div id="btnOpenWindow" effect="material" className="button accent"><text>Open Window</text></div>
                                                <text>This Will Open Regular Window</text>

                                            </item>
                                            <item><div id="btnOpenFullscreenWindow" effect="material" className="button accent"><text>Open Fullscreen Window</text></div>  <text> Some Description</text></item>

                                        </wrap>
                                    </dashboard>


                                </view>

                            </container>
                        </div>
                        <div>
                            <TemplateDetails />
                        </div>

                        <div>
                            <view>
                                <div className="alert_contianer">
                                    <alert warning="warning">
                                        <icon>info</icon>
                                        <text marquee="marquee"><span>Your email address is not verified. Verify it to receive emails from us.</span></text>
                                        <action>
                                            <div className="button accent"><text>Verify</text></div>
                                            <separator vertical="vertical"></separator>
                                            <div className="button mini close"><icon>close</icon></div>
                                        </action>
                                    </alert>
                                </div>

                                <view vertical="vertical">
                                    <switchable micro="micro" overlay="overlay" filter="filter" className="">
                                        <switch>
                                            <icon>grid</icon>
                                            <wrap>
                                                <text><tip><span>Collapse</span><b>·</b></tip>Orders Filters</text>
                                            </wrap>
                                        </switch>
                                        <view className="filter_container static">
                                            <div className="filter_content">

                                                <div className="filter_block">
                                                    <span className="title">
                                                        <span>Created</span>
                                                        <button type="button" className="button"><text>Clear</text></button>
                                                    </span>
                                                    <div className="data_cell">
                                                        <wrap block="block">
                                                            <div className="form_fields line">
                                                                <TimePicker name="tpAntd"  placeholder="From" />
                                                            </div>
                                                        </wrap>
                                                        <wrap block="block">
                                                            <div className="form_fields line">
                                                                <DatePicker name="dtpAntd" format='MM/DD/YY' placeholder="To"/>
                                                            </div>
                                                        </wrap>
                                                    </div>
                                                </div>
                                                <div className="filter_block">
                                                    <div className="data_cell"><wrap block="block">
                                                        <div className="form_fields line">
                                                            <Select
                                                                showSearch
                                                                placeholder="Created by" id="ddlgAntd"
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) =>
                                                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                }
                                                            >
                                                                {children}
                                                            </Select>
                                                        </div>
                                                    </wrap>
                                                    </div>
                                                </div>

                                                <div className="filter_block">
                                                    <span className="title">
                                                        <span>Region</span>
                                                        <button type="button" className="button"><text>Clear</text></button>
                                                    </span>
                                                    <div className="data_cell">
                                                        <wrap block="">
                                                            <label className="input_cont">
                                                                <input type="text" className="" placeholder="Menu Name" name="menu_name" value="" />
                                                                <i></i>
                                                            </label>
                                                        </wrap>
                                                    </div>
                                                </div>
                                                <div className="filter_block">
                                                    <div className="data_cell">
                                                        <wrap block="">
                                                            <label className="input_cont">
                                                                <input type="text" placeholder="Code" name="code" value=""/>
                                                                <separator vertical=""></separator>
                                                                <label simple="" checkbox="">
                                                                    <name>Empty</name>
                                                                    <input type="checkbox" name="code-empty" />
                                                                    <text></text>
                                                                </label>
                                                                <i></i>
                                                            </label>
                                                        </wrap>
                                                    </div>
                                                </div>


                                                <div className="filter_block filtered">
                                                    <span className="title">
                                                        <span>Checkbox</span>
                                                        <button type="button" className="button"><text>Clear</text></button>
                                                    </span>
                                                    <div className="data_cell">
                                                        <wrap block="">
                                                            <div className="options" name="DraftFlag">
                                                                <label>
                                                                    <input type="checkbox" value="false" />
                                                                    <wrap effect="material"><text>Active</text> </wrap>
                                                                </label>
                                                                <label>
                                                                        <input type="checkbox" value="false" />
                                                                    <wrap effect="material"><text>Shipped</text> </wrap>
                                                                </label>
                                                                    <label>
                                                                        <input type="checkbox" value="false" checked="checked" />
                                                                    <wrap effect="material"><text>Delivered</text> </wrap>
                                                                </label>
                                                                <label>
                                                                        <input type="checkbox" value="false" />
                                                                    <wrap effect="material"><text>Pending</text> </wrap>
                                                                </label>
                                                            </div>
                                                        </wrap>
                                                    </div>
                                                </div>


                                                <div className="filter_block">
                                                    <span className="title">
                                                        <span>Radio button<tip>sec</tip></span>
                                                        <button type="button" className="button"><text>Clear</text></button>
                                                    </span>
                                                    <div className="data_cell">
                                                        <wrap block="">
                                                            <div large="" className="options" name="DraftFlag">
                                                                <label>
                                                                    <input type="radio" name="active" value="1" />
                                                                    <wrap effect="material"><text>Yes</text> </wrap>
                                                                </label>
                                                                <label>
                                                                    <input type="radio" name="active" value="0" />
                                                                    <wrap effect="material"><text>No</text> </wrap>
                                                                </label>
                                                                <label>
                                                                    <input type="radio" name="active" value="0" />
                                                                    <wrap effect="material"><text><b>All</b></text></wrap>
                                                                </label>
                                                            </div>
                                                        </wrap>
                                                    </div>
                                                </div>


                                                <div className="filter_block">
                                                    <span className="title">
                                                        <span>Switcher</span>
                                                        <button type="button" className="button"><text>Clear</text></button>
                                                    </span>
                                                    <div className="data_cell">
                                                        <wrap block="">
                                                            <div className="option_group">
                                                                <div className="option_bar" name="DraftFlag">
                                                                    <label>
                                                                        <input type="radio" name="active" value="1" />
                                                                        <wrap effect="material"><text>Yes</text> </wrap>
                                                                    </label>
                                                                    <label>
                                                                        <input type="radio" name="active" value="0" />
                                                                        <wrap effect="material"><text>No</text> </wrap>
                                                                    </label>
                                                                    <label>
                                                                        <input type="radio" name="active" value="0" />
                                                                        <wrap effect="material"><text><b>All</b></text></wrap>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </wrap>
                                                    </div>
                                                </div>
                                            </div>
                                        </view>
                                     </switchable>
                                    <view>
                                        <toolbar scroll="scroll">
                                            <wrap>
                                                <div effect="material" className="button primary adaptive"><icon>save</icon><text>Save Changes</text></div>

                                                <separator vertical=""></separator>

                                                <div effect="material" className="button "><text>Delete Overlay</text></div>

                                                <separator vertical=""></separator>

                                                <div className="button mini  " data-tip="Create New Object"><icon>plus</icon></div>
                                                <separator vertical=""></separator>
                                                <div className="button mini " data-tip="Copy"><icon>copy</icon></div>
                                                <button disabled className="button mini" data-tip="paste "><icon>Paste</icon></button>
                                                <separator vertical=""></separator>
                                                <div className="button mini " data-tip="Remove Selected Item"><icon>delete</icon></div>

                                            </wrap>
                                        </toolbar>
                                        <toolbar scroll="scroll" details="details" >
                                            <wrap>
                                                <action>
                                                    <wrap>
                                                        <div className="form_fields line">
                                                            <div className="data_label">Feed URL</div>
                                                            <input name="FeedURL" type="text" setvalue="" />
                                                        </div>
                                                        <separator vertical=""></separator>
                                                        <div className="form_fields line small">
                                                            <div className="data_label">Durations<tip>sec</tip></div>
                                                            <input name="Duration" type="text" />
                                                        </div>
                                                    </wrap>
                                                </action>
                                            </wrap>
                                        </toolbar>
                                        <toolbar scroll="scroll" details="details">
                                            <wrap>
                                                <action>
                                                        <div className="form_fields line">
                                                            <div className="data_label">Drop Down List</div>
                                                            <Select placeholder="Select Character" id="ddlAntd" size="default" defaultValue="a1">
                                                                {children}
                                                            </Select>
                                                        </div>

                                                        <div className="form_fields line">
                                                            <div className="data_label">Combo Box</div>
                                                            <Select
                                                                showSearch
                                                                placeholder="Select Character" id="ddlgAntd"
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) =>
                                                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                }
                                                            >
                                                                {children}
                                                            </Select>
                                                        </div>

                                                        <div className="form_fields line">
                                                            <div className="data_label">Numeric</div>
                                                            <InputNumber min={1} max={100000} defaultValue={3} />
                                                        </div>

                                                        <div className="form_fields line">
                                                            <div className="data_label">Multi Select</div>
                                                            <Select
                                                                mode="multiple"
                                                                size="default"
                                                                placeholder="Please select"
                                                                defaultValue={['a10', 'c12']}>
                                                                {children}
                                                            </Select>
                                                        </div>
                                                        <div className="form_fields line">
                                                            <div className="data_label">Time Picker</div>
                                                            <TimePicker name="tpAntd" defaultValue={moment('12:08:23', 'HH:mm:ss')} />
                                                        </div>

                                                        <div className="form_fields line">
                                                            <div className="data_label">Date Picker</div>
                                                            <DatePicker name="dtpAntd" defaultValue={moment('2015/01/01', 'YYYY/MM/DD')} format='MM/DD/YY' />
                                                        </div>
                                                </action>
                                            </wrap>
                                        </toolbar>
                                        <view highlight="highlight">
                                            <div className="form_container">
                                                <div className="section_group">
                                                    <div className="sections">
                                                        <div className="form_fields input-validation-error">
                                                            <div className="input_cont">
                                                                <icon yellow="yellow">warning</icon>
                                                                <div className="data_help">Configure settings when and how You'll get notified for content changes</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="fab_container bottom right"><div effect="material" className="button fab primary"><icon>plus</icon></div></div>
                                        </view>
                                    </view>

                                </view>

                            </view>
                        </div>

                        <div>

                                <view vertical="">
                                    <view>
                                    <div className="list_view_wrapper " view="block">
                                            <list template="line">
                                                <wrap>
                                                    <block horizontal="">
                                                        <item>
                                                            <section horizontal="" effect="material">
                                                                <thumb select="">
                                                                    <icon>pin</icon>
                                                                </thumb>
                                                                <wrap>
                                                                    <detail>
                                                                        <text marquee="">
                                                                            <b>
                                                                                John Smith
                                                                            </b>
                                                                        </text>
                                                                        <text marquee="">
                                                                            <span>
                                                                                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                                                                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                                                            </span>
                                                                        </text>
                                                                    </detail>
                                                                    <action right="">
                                                                        <group>
                                                                            <badge>Manager</badge>
                                                                        </group>
                                                                        <separator vertical=""></separator>
                                                                    <div className="button mini"><icon>options</icon></div>
                                                                    </action>
                                                                </wrap>
                                                            </section>
                                                        </item>
                                                    </block>
                                                    <block horizontal="">
                                                        <item>
                                                            <section horizontal="">
                                                                <thumb select="">
                                                                    <icon>pin</icon>
                                                                </thumb>
                                                                <wrap>
                                                                    <detail grow="">
                                                                        <text marquee="">
                                                                            <span>
                                                                            Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                                                            Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                                                            </span>
                                                                        </text>
                                                                    </detail>
                                                                    <action right="">
                                                                        <group>
                                                                        <badge filled="" accent="">Private</badge>
                                                                        </group>
                                                                        <separator vertical=""></separator>
                                                                    <div className="button mini"><icon>edit</icon></div>
                                                                    <div className="button mini"><icon>delete</icon></div>
                                                                    </action>
                                                                </wrap>
                                                            </section>
                                                        </item>
                                                    </block>
                                                    <block horizontal="">
                                                        <item>
                                                            <section horizontal="" effect="material">
                                                                <thumb select="">
                                                                    <icon>link</icon>
                                                                </thumb>
                                                                <wrap>
                                                                    <action>
                                                                    <div className="button mini">
                                                                            <icon>star</icon>
                                                                        </div>
                                                                    <div className="button mini">
                                                                            <icon>play</icon>
                                                                        </div>
                                                                    </action>
                                                                    <detail grow="">
                                                                        <b>16:40</b>
                                                                        <text marquee="">
                                                                            <span>
                                                                                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                                                                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                                                            </span>
                                                                        </text>
                                                                    </detail>
                                                                    <action right="">
                                                                        <group>
                                                                            <badge filled="" private="">Private</badge>
                                                                        </group>
                                                                        <separator vertical=""></separator>
                                                                    <div className="button mini"><icon>options</icon></div>
                                                                    </action>
                                                                </wrap>
                                                            </section>
                                                        </item>
                                                    </block>
                                                </wrap>
                                            </list>
                                        </div>
                                    </view>
                                    <view>
                                    <div className="list_view_wrapper" view="grid">
                                            <list template="common">
                                                <wrap>
                                                    <block highlight="" space="">
                                                        <item>
                                                            <section group="">
                                                                <text marquee>
                                                                    <span>Group Title Goes Here</span>
                                                                </text>
                                                                <text marquee="" light="">
                                                                    <span>5 Items</span>
                                                                </text>
                                                            <div className="media_cont">
                                                                    <action group="">
                                                                        <panel bottom="">
                                                                            <group right="">
                                                                                <badge filled="" private="">Private</badge>
                                                                            </group>
                                                                        </panel>
                                                                    </action>
                                                                    <picture media="">
                                                                        <cover>
                                                                        <thumb className="dark"  ratio="16:9">
                                                                                <wrap><picture></picture></wrap>
                                                                            </thumb>
                                                                        </cover>
                                                                        <cover>
                                                                            <thumb ratio="16:9">
                                                                                <wrap>
                                                                                    <picture ></picture>
                                                                                </wrap>
                                                                            </thumb>
                                                                        </cover>
                                                                        <cover>
                                                                            <thumb ratio="16:9">
                                                                                <wrap>
                                                                                    <picture></picture>
                                                                                </wrap>
                                                                            </thumb>
                                                                        </cover>
                                                                        <cover>
                                                                            <thumb ratio="16:9">
                                                                                <wrap>
                                                                                    <picture></picture>
                                                                                </wrap>
                                                                            </thumb>
                                                                        </cover>
                                                                    </picture>
                                                                </div>
                                                            </section>
                                                            <toolbar>
                                                                <action>
                                                                <div className="button mini" data-tip="Bookmark"><icon>star</icon></div>
                                                                <div className="button mini"><icon>play</icon></div>
                                                                </action>
                                                                <action right>
                                                                <div className="button mini"><icon>options</icon></div>
                                                                </action>
                                                            </toolbar>
                                                        </item>
                                                    </block>
                                                    <block highlight="" space="">
                                                        <item no_reaction="">
                                                            <section group>
                                                                <text marquee="">
                                                                    <span>Group Title Goes Here</span>
                                                                </text>
                                                                <text marquee="" light="">
                                                                    <span>5 Items</span>
                                                                </text>
                                                            <div className="media_cont">
                                                                    <action group="">
                                                                        <panel bottom="">
                                                                            <group right="">
                                                                                <badge filled="" private="">Private</badge>
                                                                            </group>
                                                                        </panel>
                                                                    </action>
                                                                    <picture media="">
                                                                        <cover>
                                                                            <thumb ratio="16:9">
                                                                                    <wrap><picture></picture></wrap>
                                                                            </thumb>
                                                                        </cover>
                                                                        <cover>
                                                                            <thumb ratio="16:9">
                                                                                <wrap></wrap>
                                                                            </thumb>
                                                                        </cover>
                                                                        <cover>
                                                                            <thumb ratio="16:9">
                                                                                <wrap></wrap>
                                                                            </thumb>
                                                                        </cover>
                                                                        <cover>
                                                                            <thumb ratio="16:9">
                                                                                <wrap></wrap>
                                                                            </thumb>
                                                                        </cover>
                                                                    </picture>
                                                                </div>

                                                            </section>
                                                            <toolbar>
                                                                <action>
                                                                    <div className="button mini" data-tip="Bookmark"><icon>star</icon></div>
                                                                    <div className="button mini"><icon>play</icon></div>
                                                                </action>
                                                                <action right="">
                                                                    <div className="button mini"><icon>options</icon></div>
                                                                </action>
                                                            </toolbar>
                                                        </item>
                                                    </block>
                                                    <block highlight="" space="">
                                                        <item>
                                                            <section group="">
                                                                <text marquee="">
                                                                    <span>Group Title Goes Here</span>
                                                                </text>
                                                                <text marquee="" light="">
                                                                    <span>5 Items</span>
                                                                </text>
                                                                <div className="media_cont">
                                                                    <action group="">
                                                                        <panel bottom="">
                                                                            <group right="">
                                                                                <badge filled="" private="">Private</badge>
                                                                            </group>
                                                                        </panel>
                                                                    </action>
                                                                    <picture media="">
                                                                        <cover>
                                                                            <thumb ratio="16:9">
                                                                                <wrap><picture></picture></wrap>
                                                                            </thumb>
                                                                        </cover>
                                                                        <cover>
                                                                            <thumb ratio="16:9">
                                                                                <wrap></wrap>
                                                                            </thumb>
                                                                        </cover>
                                                                        <cover>
                                                                            <thumb ratio="16:9">
                                                                                <wrap></wrap>
                                                                            </thumb>
                                                                        </cover>
                                                                        <cover>
                                                                            <thumb ratio="16:9">
                                                                                <wrap></wrap>
                                                                            </thumb>
                                                                        </cover>
                                                                    </picture>
                                                                </div>

                                                            </section>
                                                            <toolbar>
                                                                <action>
                                                                    <div className="button mini" data-tip="Bookmark"><icon>star</icon></div>
                                                                    <div className="button mini"><icon>play</icon></div>
                                                                </action>
                                                                <action right="">
                                                                    <div className="button mini"><icon>options</icon></div>
                                                                </action>
                                                            </toolbar>
                                                        </item>
                                                    </block>
                                                </wrap>
                                            </list>
                                        </div>
                                    </view>
                                </view>
                        </div>

                        <div>
                            
                            
                        </div>

                        <div>
                            <view horizontal="">
                                <view vertical="">
                                    <view>
                                        <toolbar><text>Simple Table Markup (<b>Fixed</b>)</text></toolbar>
                                        <view space="" scroll="">
                                            <div className="table_view">
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th><text>Sample Title</text></th>
                                                            <th><text>Another Title</text></th>
                                                            <th><text>Title</text></th>
                                                            <th><text>Sample Title</text></th>
                                                            <th><text>Another Title</text></th>
                                                            <th><text>Title</text></th>
                                                            <th action=""> </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td><input type="text" /></td>
                                                            <td><input type="text" /></td>
                                                            <td><input type="text" /></td>
                                                            <td><input type="text" /></td>
                                                            <td><input type="text" /></td>
                                                            <td><input type="text" /></td>
                                                            <td><div effect="material" className="button extra"><icon>delete</icon></div></td>
                                                        </tr>
                                                        <tr>
                                                            <td><input type="text" /></td>
                                                            <td><input type="text" /></td>
                                                            <td><input type="text" /></td>
                                                            <td><input type="text" /></td>
                                                            <td><input type="text" /></td>
                                                            <td><input type="text" /></td>
                                                            <td><div effect="material" className="button extra"><icon>delete</icon></div></td>
                                                        </tr>
                                                        <tr>
                                                            <td><input type="text" /></td>
                                                            <td><input type="text" /></td>
                                                            <td><input type="text" /></td>
                                                            <td><input type="text" /></td>
                                                            <td><input type="text" /></td>
                                                            <td><input type="text" /></td>
                                                            <td><div effect="material" className="button extra"><icon>delete</icon></div></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="fab_container bottom right"><div effect="material" className="button fab primary"><icon>plus</icon></div></div>
                                        </view>
                                    </view>
                                    <view horizontal="">
                                        <view>
                                            <toolbar><text>Simple Table Markup (<b>Adaptive</b>)</text></toolbar>
                                            <view space="">
                                                <div className="table_view adaptive">
                                                    <table>
                                                        <thead>
                                                            <tr>
                                                                <th className="large"><text>Sample Title</text></th>
                                                                <th><text>Another Title</text></th>
                                                                <th><text>Title</text></th>
                                                                <th action=""> </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td><input type="text" /></td>
                                                                <td><input type="text" /></td>
                                                                <td><input type="text" /></td>
                                                                <td><div effect="material" className="button extra"><icon>delete</icon></div></td>
                                                            </tr>
                                                            <tr>
                                                                <td><input type="text" /></td>
                                                                <td><input type="text" /></td>
                                                                <td><input type="text" /></td>
                                                                <td><div effect="material" className="button extra"><icon>delete</icon></div></td>
                                                            </tr>
                                                            <tr>
                                                                <td><input type="text" /></td>
                                                                <td><input type="text" /></td>
                                                                <td><input type="text" /></td>
                                                                <td><div effect="material" className="button extra"><icon>delete</icon></div></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </view>

                                        </view>
                                        <view>
                                            <toolbar><text>Simple Table Markup (with <b>Colgroup</b>)</text></toolbar>
                                            <view space="">
                                                <div className="table_view">
                                                    <table>
                                                        <colgroup>
                                                            <col width="100px" />
                                                            <col width="200px" />
                                                            <col />
                                                            <col width="40px" />
                                                        </colgroup>
                                                        <thead>
                                                            <tr>
                                                                <th><text>Sample Title</text></th>
                                                                <th><text>Another Title</text></th>
                                                                <th><text>Title</text></th>
                                                                <th action=""> </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td><input type="text" /></td>
                                                                <td><input type="text" /></td>
                                                                <td><input type="text" /></td>
                                                                <td><div effect="material" className="button extra"><icon>delete</icon></div></td>
                                                            </tr>
                                                            <tr>
                                                                <td><input type="text" /></td>
                                                                <td><input type="text" /></td>
                                                                <td><input type="text" /></td>
                                                                <td><div effect="material" className="button extra"><icon>delete</icon></div></td>
                                                            </tr>
                                                            <tr>
                                                                <td><input type="text" /></td>
                                                                <td><input type="text" /></td>
                                                                <td><input type="text" /></td>
                                                                <td><div effect="material" className="button extra"><icon>delete</icon></div></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </view>
                                        </view>
                                    </view>
                                </view>
                                <view auto_height="" small="">
                                    <toolbar><text><b>Advanced</b> Table Markup (Adaptive)</text></toolbar>
                                    <view space="">
                                        <div className="table_view adaptive">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th className="large"><label simple="" checkbox=""><name><b>Text Title</b></name><separator vertical=""></separator><input type="checkbox" /><tip></tip></label></th>
                                                        <th action=""> </th>
                                                        <th><label simple="" checkbox=""><name><b>TimePicker Title</b></name><separator vertical=""></separator><input type="checkbox" checked="checked" /><tip></tip></label></th>
                                                        <th><label simple="" checkbox=""><name><b>DatePicker Title</b></name><separator vertical=""></separator><input type="checkbox" /><tip></tip></label></th>
                                                        <th><label simple="" checkbox=""><name><b>Select  Title</b></name><separator vertical=""></separator><input type="checkbox" /><tip></tip></label></th>
                                                        <th><label simple="" checkbox=""><name><b>Input Title</b></name><separator vertical=""></separator><input type="checkbox" /><tip></tip></label></th>
                                                        <th action=""> </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td><text>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</text></td>
                                                        <td><wrap><label classic=""><input type="checkbox" /><box><check></check></box></label></wrap></td>
                                                        <td>
                                                            <wrap>
                                                                <TimePicker name="tpAntd" placeholder="From" className="input-validation-error" />
                                                                <DatePicker name="dtpAntd" format='MM/DD/YY' placeholder="To" />
                                                            </wrap>
                                                        </td>
                                                        <td>
                                                            <wrap>
                                                                <DatePicker name="dtpAntd" format='MM/DD/YY' placeholder="To" />
                                                            </wrap>
                                                        </td>
                                                        <td>
                                                            <wrap>
                                                                <Select
                                                                    showSearch
                                                                    placeholder="Created by" id="ddlgAntd"
                                                                    optionFilterProp="children"
                                                                    filterOption={(input, option) =>
                                                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                    }
                                                                >
                                                                    {children}
                                                                </Select>
                                                            </wrap>
                                                        </td>
                                                        <td><wrap><input type="text" /></wrap></td>
                                                        <td><div effect="material" className="button extra"><icon>delete</icon></div></td>
                                                    </tr>
                                                    <tr>
                                                        <td></td>
                                                        <td><wrap><label classic=""><input type="checkbox" /><box><check></check></box></label></wrap></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td><div effect="material" className="button extra"><icon>delete</icon></div></td>
                                                    </tr>
                                                    <tr>
                                                        <td></td>
                                                        <td><wrap><label classic=""><input type="checkbox" /><box><check></check></box></label></wrap></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td><div effect="material" className="button extra"><icon>delete</icon></div></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </view>
                                </view>
                            </view>
                        </div>


                        <div>
                            <view dashboard="">
                                <dashboard>

                                    <wrap>
                                        <item dwidget="" small="">
                                            <toolbar border="bottom">
                                                <text caption="" data-weight="700"><span>Most Played <dot></dot> TSTManager </span></text>
                                                <action right="">
                                                    <div className="button mini right"><icon>options</icon></div>
                                                </action>
                                            </toolbar>
                                            <wrap>
                                                <view intro="">
                                                    <wrap>
                                                        <icon large="" light="">dashboard</icon>
                                                        <info>
                                                            <text subtitle="" data-weight="700"> No Data to Display</text>
                                                            <text wrap="" light="">Most Played Content Within TST</text>
                                                        </info>
                                                    </wrap>
                                                </view>
                                            </wrap>
                                        </item>






                                        <item dwidget="" round="" small="">
                                            <toolbar border="bottom">
                                                <text caption=""  data-weight="700"><span>Daily Onlines</span></text>
                                                <action right="">
                                                    <div className="button mini hide right"><icon>edit</icon></div>
                                                </action>
                                            </toolbar>
                                            <info>
                                                <text wrap="" light="">Daily online devices from December 19 - 29</text>
                                            </info>
                                            <wrap chart="">
                                                <canvas ></canvas>
                                            </wrap>
                                        </item>
                                    </wrap>

                                    <separator horizontal=""></separator>

                                    <wrap template="large">
                                        <item dwidget="" round="" small="">
                                            <toolbar border="bottom">
                                                <text caption="" marquee="" data-weight="700"><span>Daily Onlines</span></text>
                                                <action right="">
                                                    <div className="button mini hide right"><icon>options</icon></div>
                                                </action>
                                            </toolbar>
                                            <info>
                                                <text wrap="" light="">Daily online devices from December 19 - 29</text>
                                            </info>

                                            <wrap chart="">
                                                <canvas></canvas>
                                            </wrap>
                                        </item>
                                    </wrap>
                                </dashboard>
                            </view>
                        </div>
                    </div>
                </div>
            </>
        );
   
};
