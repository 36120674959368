export const translateIntoCamelCase = textInUnderScore => {
  if (textInUnderScore === 'inboundPickAppointment') {
    return 'inbound_pick_appointment';
  } else if (textInUnderScore === 'outboundPickAppointment') {
    return 'outbound_pick_appointment';
  } else if (textInUnderScore === 'inboundDropAppointment') {
    return 'inbound_drop_appointment';
  } else if (textInUnderScore === 'outboundDropAppointment') {
    return 'outbound_drop_appointment';
  } else if (textInUnderScore === 'poNumber') {
    return 'po_number';
  }
};