import React from 'react';
import {
    ElementTypes,
    ElementValidations,
    Ability,
    ElementConditions
} from '../../config';

const ChangePassword = {
    Title: 'Please enter your new password below',
    Text: (<React.Fragment>
        To keep your account safe, use a mix of letters, numbers,
        <br />
         and symbols in your password.
    </React.Fragment>),
    Resource: 'ChangePassword',
    Master: '',
    Key: '',
    DefaultState: {
        password: '',
        confirmPassword: ''
    },
    Form: {
        Wrap: [
            {
                Fields: {
                    password: {
                        Title: 'Password',
                        Field: ElementTypes.PasswordField(true, Ability.Displayability, Ability.Editability, [], {}, ElementConditions.Enable, ' '),
                        Validations: [ElementValidations.Required('Please fill in password')]
                    },
                    confirmPassword: {
                        Title: 'Repeat',
                        Field: ElementTypes.PasswordField(false, Ability.Displayability, Ability.Editability, [], {}, ElementConditions.Enable, ' '),
                        Validations: [ElementValidations.Required('Please fill in repeat'), ElementValidations.PasswordConfirm('password')]
                    }
                }
            }
        ]
    }
}; 

export default ChangePassword;