import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useDispatch } from "react-redux";
import { InputNumber, Tooltip } from 'antd';
import { SearchOutlined, CaretDownOutlined } from '@ant-design/icons';
import Select, { components } from 'react-select';
import { setItemData, setItemDisplayState } from '../../../../store/actions/customers/customers';
import { CustomerPriceSelectors } from '../../../../store/selectors/customerPriceSelectors';
import { enabled } from 'store/dist/store.legacy';
import { GenerateMessages } from '../../../helper/element';
import DeletePriceTemplateModal from './DeletePriceTemplateModal';
import { SelectPriceStyles } from './SelectPriceStyle'
import './index.css';

const Cell = ({
        cellData,
        itemSize,
        rowState,
        resource,
        priceTemplateType,
        customerId,
        mainScroll,
        }) => {
    const dispatch = useDispatch();
    const { state, customersPriceList, allLocationsDoorsList, favoriteLocationsDoorsList, perPalletType } = CustomerPriceSelectors();
    const loadArea = state[resource];
    const redonly = 1;
    const editable = 2;
    const header = loadArea.headers.find(h => h.key === cellData.key);
    const width = header.width;
    const lastSelectId = loadArea.lastSelected.id;
    const lastSelectHeaderKey = loadArea.lastSelected.headerKey;
    const lastSelectenable = loadArea.lastSelected.enable;
    const rowStateId = rowState.id;
    const headerKey = header.key;
    const [displayState, setDisplayState] = useState(redonly);
    const [tempData, setTempData] = useState(null);
    const [deletePriceTemplateModalIsOpen, setDeletePriceTemplateModalIsOpen] = useState(false);
    const [pickLocation, setPickLocation] = useState('');
    const [dropLocation, setDropLocation] = useState('');

    useEffect(() => {
        if (lastSelectId === rowStateId && lastSelectHeaderKey === headerKey && lastSelectenable) {
            setDisplayState(enabled);
        }
    }, [lastSelectId, lastSelectHeaderKey, lastSelectenable, rowStateId, headerKey]);

    const data = cellData.value;
    const cellStyle = cellData.cellStyle;
    const color = rowState.type === 'load' ? 'white' : '#101C1C';

    const priceRange = {
        '1': 1,
        '2-4': 4,
        '5-8': 8,
        '9-12': 12,
        '13-16': 16,
        '17-21': 21,
    };

    const pricePall = {
        '1': 0,
        '2': 1,
        '3': 2,
        '4': 3,
        '5': 4,
        '6': 5,
        '7': 6,
        '8': 7,
        '9': 8,
        '10': 9,
        '11': 10,
        '12': 11,
        '13': 12,
        '14': 13,
        '15': 14,
        '16': 15,
        '17': 16,
        '18': 17,
        '19': 18,
        '20': 19,
        '21': 20,
    };

    const isPricePerPall = Object.keys(pricePall).includes(header.key);
    const priceHeader = isPricePerPall ? pricePall[headerKey] + 1 : priceRange[headerKey]

    const priceOverToMax = Number(tempData) * priceHeader > Number(rowState.data?.maxPrice) && 
        headerKey !== 'pickLocation' && headerKey !== 'dropLocation';

    const backgroundColor = priceOverToMax  ? '#DDC1C1' : headerKey === 'delete' ? '#449996' : 'inherit';

    let tooltip = '';
    if (priceTemplateType === 'PricePerPall' && !perPalletType) {
        tooltip = `Total $${Math.round(Number(tempData) * Number(header.key))}`;
    } else if (priceTemplateType === 'PricePerPall' && !!perPalletType) {
        tooltip = `$${Math.round(Number(tempData))}/pallet`;
    }
    if (priceTemplateType === 'PricePerPall' && (header.key === 'tl' || header.key === 'delete')) {
        tooltip = tempData && Math.round(Number(tempData));
    }
    if (priceTemplateType !== 'PricePerPall') {
        tooltip = tempData && Math.round(Number(tempData));
    }
    if (priceTemplateType === 'PricePerRange' && header.key !== 'tl' && header.key !== 'delete' && tempData) {
        tooltip = tempData && `$ ${Math.round(Number(tempData))}/pallet`;
    }
    if (priceTemplateType === 'PricePerPound' && header.key === 'perWeight') {
        tooltip = tempData && `$ ${tempData}/pound`;
    }
    if (priceTemplateType === 'PricePerPound' && (header.key === 'tl' || header.key === 'minPrice')) {
        tooltip = tempData && `$ ${tempData}`;
    }
    if (header.key === 'tl' && priceTemplateType !== 'PricePerPound') {
        tooltip = tempData && `$ ${Math.round(Number(tempData))}`;
    }
    if (priceOverToMax) {
        tooltip = 'LTL price exceeds TL price';
    }
    if (header.key === 'pickLocation' || header.key === 'dropLocation') {
        tooltip = '';
    }

    useEffect(() => {
        setTempData(data);
    }, [data]);

    useEffect(() => {
        if (rowState.id === 0) {
            setDisplayState(editable);
        }
    }, [cellData.key, resource, rowState.id]);

    const allLocationsDoorsListOptions = allLocationsDoorsList?.map(item => {
        return { ...item, 'label': item.text };
    });
    const favoriteLocationsDoorsListOptions = favoriteLocationsDoorsList?.map(item => {
        return { ...item, 'label': item.text };
    });
    
    const getTextFromValueAllLocations = useCallback(value => {
        return allLocationsDoorsListOptions?.length ? allLocationsDoorsListOptions?.filter(item => item.value === value)[0]?.text : [];
    }, [allLocationsDoorsListOptions]);

    const getTextFromValueFavoriteLocations = useCallback(value => {
        return favoriteLocationsDoorsListOptions?.length ? favoriteLocationsDoorsListOptions?.filter(item => item.value === value)[0]?.text : [];
    }, [favoriteLocationsDoorsListOptions]);

    const inputReference = useRef(null);
    const dataCompare = (newData, data) => {
        switch (cellData.fieldType) {
            case 'date':
            case 'dateTime':
                if (newData instanceof Date && data instanceof Date) {
                    return newData.getTime() === data.getTime();
                }
                return newData === data;
            default:
                return newData === data;
        }
    }

    const tlIsEmpty = cellData.key === 'tl' && !cellData.value;

    const bothSameLocation = customersPriceList && (customersPriceList[0].rowState.data.pickLocationId === customersPriceList[0].rowState.data.dropLocationId) &&
                    (customersPriceList[0].rowState.data.pickLocationId !== '' ||
                    customersPriceList[0].rowState.data.dropLocationId !== '');

    const firstPickId = customersPriceList && customersPriceList[0].rowState.data.pickLocationId
    const pickLocationsIndexes = customersPriceList.map((item, index) => item.rowState.data.pickLocationId === firstPickId && index).filter(item => item !== false);
    const correspondingDropLocations = customersPriceList.filter((item, index) => pickLocationsIndexes.includes(index));
    const correspondingDropLocationsIds = correspondingDropLocations.map(item => item.rowState.data.dropLocationId);
    const isDuplicate = new Set(correspondingDropLocationsIds).size !== correspondingDropLocationsIds.length;

    const setData = value => {
        onSetBlur();
        if (!dataCompare(value, tempData)) {
            setTempData(value);
            setTimeout(() => {
                dispatch(setItemData(resource, Number(value), rowState, header, priceTemplateType));
            }, 100);
        }
    };

    const handleChangePick = (option) => {
        setPickLocation(option.label);
        setData(option.value);
    };

    const handleChangeDrop = (option) => {
        setDropLocation(option.label);
        setData(option.value);
    };

    const handleDelete = () => {
        setDeletePriceTemplateModalIsOpen(true);
    };

    const getData = () => {
        if (tempData !== '') {
            switch (cellData.fieldType) {
                case 'integer':
                    if (priceTemplateType === 'PricePerPall' && perPalletType === true && header.key !== 'tl') {
                        return tempData ? `$ ${Math.round(Number(tempData) * Number(header.key))}` : '$';
                    } else if (priceTemplateType === 'PricePerPound')  {
                        return tempData ? `$ ${Number(tempData)}` : '$';
                    } else {
                        return tempData ? `$ ${Math.round(Number(tempData))}` : '$';
                    }
                case 'selectPickLocation':
                    return typeof tempData === 'number' ? getTextFromValueFavoriteLocations(tempData) : tempData;
                case 'selectDropLocation':
                    return typeof tempData === 'number' ? getTextFromValueAllLocations(tempData) : tempData;
                case 'delete':
                    return (
                        <div className='price_delete_icons' onClick={handleDelete}>
                            <svg width="8" height="10" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.1365 6V16H3.13647V6H11.1365ZM9.63647 0H4.63647L3.63647 1H0.136475V3H14.1365V1H10.6365L9.63647 0ZM13.1365 4H1.13647V16C1.13647 17.1 2.03647 18 3.13647 18H11.1365C12.2365 18 13.1365 17.1 13.1365 16V4Z" fill="white"/>
                            </svg>
                        </div>
                    );
                default:
                return tempData;
            }
        } else if (tempData === '' && headerKey !== 'pickLocation' && headerKey !== 'dropLocation') {
            return '$';
        }
        return tempData
    };

    const DropdownIndicator = props => {
        return (
            components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
                {props.selectProps.menuIsOpen ? <SearchOutlined /> : <CaretDownOutlined />}
            </components.DropdownIndicator>
            )
        );
    };

    const getInput = () => {
        switch (cellData.fieldType) {
            case 'integer':
                return <InputNumber type="number" min={0}
                    ref={inputReference}
                    value={tempData && priceTemplateType === 'PricePerRange' ? `$ ${Math.round(Number(tempData))}` :
                            tempData && priceTemplateType === 'PricePerPound' ? `$ ${Number(tempData)}` :
                            tempData && priceTemplateType === 'PricePerPall' && perPalletType === true && header.key !== 'tl' ? `$ ${Math.round(Number(tempData) * Number(header.key))}`  
                            : ''}
                    defaultValue={tempData && priceTemplateType !== 'PricePerPall' ? tempData : 
                                    tempData && priceTemplateType === 'PricePerPall' && header.key === 'tl' ? tempData : 
                                    tempData && priceTemplateType === 'PricePerPall' && perPalletType === false && header.key !== 'tl' ? Math.round(Number(tempData)) :
                                    tempData && priceTemplateType === 'PricePerPall' && perPalletType === true && header.key !== 'tl' ? Math.round(Number(tempData) * Number(header.key)) : ''}
                    className={`spreadsheet-number-input 
                                ${priceOverToMax && 'spreadsheet-number-input__max'}
                                ${tlIsEmpty && 'dublicate'} `}
                    placeholder='$'
                    onBlur={(e) => {
                        if (inputReference.current) {
                            setDisplayState(redonly);
                        }
                        setData(perPalletType === true && priceTemplateType === 'PricePerPall' ? e.target.value / (header.key === 'tl' ? 1 : Number(header.key)) : e.target.value)
                    }}
                />
            case 'selectPickLocation':
                return <Select
                    options={favoriteLocationsDoorsListOptions}
                    value={{ label: pickLocation }}
                    className={'price-select'}
                    onChange={option => handleChangePick(option)}
                    onFocus={() => mainScroll.current.scrollLeft = 0}
                    styles={SelectPriceStyles(isDuplicate || bothSameLocation)}
                    components={{ DropdownIndicator }}
                />
            case 'selectDropLocation':
                return <Select
                    options={allLocationsDoorsListOptions}
                    value={{ label: dropLocation }}
                    className={'price-select'}
                    onChange={option => handleChangeDrop(option)}
                    onFocus={() => mainScroll.current.scrollLeft = 0}
                    styles={SelectPriceStyles(isDuplicate || bothSameLocation)}
                    components={{ DropdownIndicator }}
                />
            default: return <></>;
        }
    };

    const getDataWithTooltip = () => tooltip ? (<Tooltip title={GenerateMessages([tooltip])}>
        {displayState === redonly ? getData() : getInput()}
    </Tooltip>) : displayState === redonly ? getData() : getInput();

    const dataDisplay = () =>  getDataWithTooltip();

    const cellDefaultStyle = {
        maxWidth: `${width}px`,
        width: `${width}px`,
        minWidth: `${width}px`,
        height: `${itemSize}px`,
        lineHeight: 'inherit',
    };

    const onSetBlur = () => {
        dispatch(setItemDisplayState(resource, rowState, header, false));
    };

    const onSetFocus = () => {
        if (cellData.editable) {
            setDisplayState(editable);
        }
    };

    const editableFunction = (cellData) => (
        cellData.editable ? setDisplayState(editable) : null
    );

    return (<>
        <div
            onFocus={() => onSetFocus()}
            onClick={() => { editableFunction(cellData)}}
            className='table-body-cell'
            style={{ ...cellDefaultStyle, ...cellStyle, color: color, backgroundColor, padding: '0 2px' }}
            tabIndex={displayState === redonly ? 0 : -1}
            >
            {dataDisplay()}
        </div>
        {deletePriceTemplateModalIsOpen && 
            <DeletePriceTemplateModal
                deletePriceTemplateModalIsOpen={deletePriceTemplateModalIsOpen}
                setDeletePriceTemplateModalIsOpen={setDeletePriceTemplateModalIsOpen}
                priceTemplateType={priceTemplateType}
                resource={resource}
                customerPriceId={rowState.id}
                customerId={customerId}
            />
        }
    </>);
};

export default Cell;