import React, { useEffect } from 'react';
import Modal from 'react-modal';
import { connect } from "react-redux";
import { message } from 'antd';
import './index.css';
import { resetError, acceptChangeRequest, setChecked } from '../../../../store/actions/accountReceivableActions/accountReceivableActions';
import Loading from "../../screen/loading";

const ChangeRequestAcceptModal = ({ 
      changeRequestAcceptModalIsOpen,
      setChangeRequestAcceptModalIsOpen,
      hasError,
      actionMessage,
      loading,
      resetError,
      resource,
      acceptChangeRequest,
      orderId,
      setAllIsChecked,
      setChecked,
    }) => {

const closeModal = () => {
  setChangeRequestAcceptModalIsOpen(false);
  setAllIsChecked(false);
  setChecked(resource, []);
};

useEffect(() => {
  if (hasError) {
      message.error(actionMessage);
      resetError(resource);

  }
}, [hasError, actionMessage, resetError, resource]);

const handleAccept = () => {
  acceptChangeRequest(resource, 'inform', orderId);
  setChangeRequestAcceptModalIsOpen(false);
  setAllIsChecked(false);
};

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      minWidth: '450px',
      minHeight: '150px',
      maxWidth: '400px',
      borderRadius: '8px',
      padding: loading ? '100px 0' : '6px 0',
    },
  };

  return (
    <Modal
        isOpen={changeRequestAcceptModalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        ariaHideApp={false}
        >
        <div className='comment_modal__container'>
          {loading ? <Loading /> : <>
            <button onClick={closeModal} className='revision__x-button'>
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10 0C4.47 0 0 4.47 0 10C0 15.53 4.47 20 10 20C15.53 20 20 15.53 20 10C20 4.47 15.53 0 10 0ZM14.3 14.3C13.91 14.69 13.28 14.69 12.89 14.3L10 11.41L7.11 14.3C6.72 14.69 6.09 14.69 5.7 14.3C5.31 13.91 5.31 13.28 5.7 12.89L8.59 10L5.7 7.11C5.31 6.72 5.31 6.09 5.7 5.7C6.09 5.31 6.72 5.31 7.11 5.7L10 8.59L12.89 5.7C13.28 5.31 13.91 5.31 14.3 5.7C14.69 6.09 14.69 6.72 14.3 7.11L11.41 10L14.3 12.89C14.68 13.27 14.68 13.91 14.3 14.3Z" fill="#678482"/>
              </svg>
            </button>
            <div className='change-request-accept__text'>Change request accepted?</div>
            <div className='change-request-accept__buttons-container'>
                <button className='change-request__btn-cancel' onClick={closeModal}>CANCEL</button>
                <button className='change-request__btn-accept' onClick={handleAccept}>ACCEPT</button>        
            </div>
          </>}
        </div>  
    </Modal>
  );
};

const mapStateToProps = state => ({
  state: state,
  orderId: state.AccountReceivable.orderId,
  loading: state.AccountReceivable.loading,
  hasError: state.AccountReceivable.hasError,
  actionMessage: state.AccountReceivable.message,
});

const mapDispatchToProps = (dispatch) => ({
  resetError: resource => dispatch(resetError(resource)),
  acceptChangeRequest: (resource, endPoint, orderId) => dispatch(acceptChangeRequest(resource, endPoint, orderId)),
  setChecked: (resource, orderId) => dispatch(setChecked(resource, orderId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangeRequestAcceptModal);
