import React from 'react';
import { connect } from "react-redux";
import TrafficControlSpreadsheet from '../../../custom/spreadsheetTrafficControl/spreadsheet';
import { getContentWidth } from '../../../../../store/reducers/trafficControl/helper/general';

const TrafficControlLoadBuld = props => {
    const { state, upcomingUrl } = props;
    const scrollSize = 30;
    const resource= 'traffic';
    const headers = state[resource].headers;
    const width = getContentWidth(headers) + scrollSize;
    const innerWidth = window.innerWidth;

    return (
        <div style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: `${width > innerWidth ? 'stretch': 'center'}`,
            margin: '0 12px',
            alignSelf: `${width < innerWidth ? "center" : "normal"}`,
            width: `${width > innerWidth ? `calc(100% - 35px)` : 'auto'}`,
        }}>
            <TrafficControlSpreadsheet resource='traffic' upcomingUrl={upcomingUrl} />
        </div>
    );
}
const mapStateToProps = state => ({
    state: state,
});

export default connect(mapStateToProps)(TrafficControlLoadBuld);