export const useAppointment = setStops => {
    const updatePickAppointment = (newStops, result) => {
        const changedStop = newStops.find(s => s.id === result.id);
        if (changedStop) {
            changedStop.pickAppointment = result.appointment;
            changedStop.pickStatus = result.status;
            const newOrders = [...changedStop.orders];
            newOrders.forEach(o => {
                o.inboundPickAppointment = changedStop.pickAppointment;
                o.inbioundAppointmentStatus = changedStop.pickStatus;
            });
            changedStop.orders = newOrders;
            setStops(newStops);
        }
        else {
            console.error(`Stop not found, id - ${result.id}`)
        }
    };

    const updateDropAppointment = (newStops, result) => {
        newStops.forEach(s => {
            if (s.loadId === result.loadId) {
                if ((result.warehouseId && s.warehouseId) ||
                    (!result.warehouseId && !s.warehouseId && s.dropLocationId === result.dropLocationId)) {
                    s.dropAppointment = result.dropAppointment;
                    s.dropStatus = result.dropStatus;

                    const newOrders = [...s.orders];
                    newOrders.forEach(o => {
                        o.inboundDropAppointment = s.dropAppointment;
                        o.dropInboundAppointmentStatus = s.dropStatus;
                    });
                    s.orders = newOrders;
                }
            }
        });
        setStops(newStops);
    };
    return [updatePickAppointment, updateDropAppointment];
};