import React, { useRef, useState } from 'react';

import { ElementContent } from '../../../helper/element';
import { useValidation } from '../../../hook/data/useValidation';
import { useElementChange } from '../../../hook/action/useElementChange';
import { useElementOut } from '../../../hook/action/useElementOut';
import { useElementValidationFocus } from '../../../hook/action/useElementFocus';
import { ColumnType } from '../../../config/list.config';
import { InvertColor } from '../../../helper/common';
import { getNormalizedAttributes } from '../../../config/element.config';

const Sheet2Cell = props => {
    const ref = useRef();
    const [field2, setField2] = useState("");
    const messageIdentity = props.messageIdentityPrefix ? `${props.messageIdentityPrefix}_${props.field}` : props.field;
    useElementValidationFocus(props.messages, ref, messageIdentity);
    const valudationMessages = props.messages && props.messages[messageIdentity];
    const validate = useValidation(props.messageDispatch, props.column.Validations, props.column.Title, messageIdentity, props.object, props.column.Warnings);

    const change = (event, data) => {
        
        props.onChange({
            nativeEvent: event,
            column: props.column,
            field: event.target.field || props.field,
            dataField: props.dataField,
            keyField: props.keyField,
            parentKeyField: props.parentKeyField,
            newData: data
        });
    };

    const attributes = getNormalizedAttributes(props.column.Attributes, props.object, props.data);

    const fieldChangeHandler = useElementChange(validate, change);
    const fieldOutHandler = useElementOut(validate, change);

    if(props.column.Type === ColumnType.Empty) {
        return <td><wrap></wrap></td>;
    }

    const field = ElementContent({
        ...props.column,
        object: props.object,
        val: props.data,
        fieldChange: fieldChangeHandler,
        fieldOut: fieldOutHandler,
        ref: ref,
        valudationMessages: valudationMessages,
        warningMessages: props?.messages?.warnings?.[messageIdentity] || [],
        onActionRecord: props.onActionRecord,
        parentData: props.parentData,
        firstTimeAction: selectedObject => {
            const landingValue = selectedObject ? selectedObject[props.field2Name] : "";
            const secondaryLandingValue = selectedObject && !!selectedObject[props.field3Name] ? selectedObject[props.field3Name] : "";
            let landingText1 = "";
            let landingText2 = "";
            switch (landingValue) {
                case 1:
                    landingText1 = 'MON';
                    break;
                case 2:
                    landingText1 = 'TUES';
                    break;
                case 3:
                    landingText1 = 'WED';
                    break;
                case 4:
                    landingText1 = 'THURS';
                    break;
                case 5:
                    landingText1 = 'FRI';
                    break;
                case 6:
                    landingText1 = 'SAT';
                    break;
                case 7:
                    landingText1 = 'SUN';
                    break;
                default:
                    landingText1 = '';
                    break;
            }
            switch (secondaryLandingValue) {
                case 1:
                    landingText2 = 'MON';
                    break;
                case 2:
                    landingText2 = 'TUES';
                    break;
                case 3:
                    landingText2 = 'WED';
                    break;
                case 4:
                    landingText2 = 'THURS';
                    break;
                case 5:
                    landingText2 = 'FRI';
                    break;
                case 6:
                    landingText2 = 'SAT';
                    break;
                case 7:
                    landingText2 = 'SUN';
                    break;
                default:
                    landingText2 = '';
                    break;
            }
            const landingText = !!landingText2 ? `${landingText1}/${landingText2}` : landingText1;
            setField2(landingText);
        }
    });

    const color = props.color;

    return (<>
        <td key='field1' {...attributes.td} style={props.coloring ? {
                backgroundColor: color,
                color: InvertColor(color, true)
        } : null}>
            <wrap {...attributes.wrap} id="landingId">
                {field2}
                </wrap>
            </td>
        <td key='field2' {...attributes.td} style={props.coloring ? {
                backgroundColor: color,
                color: InvertColor(color, true)
            } : null}>
                <wrap {...attributes.wrap}>
                    {field}
                </wrap>
            </td>
        </>
    );
};

export default React.memo(Sheet2Cell);