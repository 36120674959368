import React from 'react';
import Draggable from 'react-draggable';

import FormView from './view/formView';
import Popup from './popup/popup';

const FormScreen = props =>
    props.showPopup ?
        <Draggable handle="strong">
            <div className="box no-cursor">
                {/* {props.showHeader && */}
                <header>
                    {props.fullScreen &&
                        <React.Fragment>
                            <div effect='material' className='button white mini back' command='close' onClick={props.onCancel ? props.onCancel : null}>
                                <icon>left</icon>
                            </div>
                            <separator vertical=""></separator>
                        </React.Fragment>}
                    <div style={{ width: 'calc(100% - 20px)' }}>
                        <strong className="cursor"><div><p>{props.title}</p></div></strong>
                    </div>
                    {!props.fullScreen &&
                        <div effect="material" className="button white mini close" command="close" onClick={props.onCancel ? props.onCancel : null}>
                            <icon>close</icon>
                        </div>}
                </header>
                {/* } */}
                <div>
                    <Popup isLoading={props.isLoading}>
                        {props.children}
                    </Popup>
                </div>
            </div>
        </Draggable>
        :
        <FormView isLoading={props.isLoading} backUrl={props.backUrl} search={props.search} title={props.title} handleBack={props.handleBack} >
            {props.children}
        </FormView>

export default FormScreen;