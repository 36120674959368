import React from 'react';
import { useDispatch } from "react-redux";
import { Resizable } from 'react-resizable';
import { setHeaderWidth, sortBy } from '../../../../store/actions/factoringCompany/factoringCompany';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { FactoringCompanySelectors } from '../../../../store/selectors/factoringCompanySelectors';

const HeaderCell = ({ ind, resource }) => {
    const { state, sortType } = FactoringCompanySelectors();
    const dispatch = useDispatch();
    const factoringCompany = state[resource];
    const data = factoringCompany?.headers[ind]
    let cellStyle = {
        maxWidth: `${data.width}px`,
        width: `${data.width}px`,
        minWidth: `${data.width}px`,
    };

    const onResize = s => {
        dispatch(setHeaderWidth(resource, ind, s.size.width));
    };

    const upArrowColor = sortType === 'asc' ? '#3E8A86' : '';
    const downArrowColor = sortType === 'desc' ? '#3E8A86' : '';

    let headerCall =
        <div className='table-header-cell table-header-cell-factory' style={cellStyle}>
            {data.title}
            {data.sortable &&
                <div className='factoring__sorting-arrows'>
                    <UpOutlined onClick={() => dispatch(sortBy(resource, 'asc'))} style={{ color: upArrowColor }} />
                    <DownOutlined onClick={() => dispatch(sortBy(resource, 'desc'))} style={{ color: downArrowColor }} />
                </div>
            }
        </div>;

    const resizableHeaderCall = (
        <Resizable
            width={data.width}
            height={0}
            handle={
                <span
                    className="react-resizable-handle"
                    onClick={e => { e.stopPropagation()}}
                />
            }
            onResize={(_, s) => onResize(s)}
        >
            {headerCall}
        </Resizable>
    );

    return resizableHeaderCall;
};

export default HeaderCell;