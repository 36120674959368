import React from 'react';
import { connect } from "react-redux";
import Modal from 'react-modal';

import { setItemData, sendToDelivered } from '../../../../store/actions/callCheck/callCheck';
import './index.css';

const DeliveredModal = ({ resource, rowState, deliveredModalIsOpen, setDeliveredModalIsOpen, loadsAssignedOrders, sendToDelivered }) => {
  const closeModal = () => {
    setDeliveredModalIsOpen(false);
  };

  const handleConfirm = () => {
    sendToDelivered(resource, rowState.loadData.id);
    setDeliveredModalIsOpen(false);
  };

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      minWidth: '360px',
      minHeight: '140px',      
      borderRadius: '8px',
      padding: '6px 12px',
    },
  };

  return (
    <Modal
        isOpen={deliveredModalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        ariaHideApp={false}
        >
        <div className='delivered_modal__container'>
          <div className='delivered_modal_text'>This Load has PODs pending. Confirm Delivery?</div>
          <div className='delivered-buttons-container'>
            <button className='delivered-modal_button' onClick={handleConfirm}>Confirm</button>
            <div className='delivered-modal_button' onClick={closeModal}>Close</div>
          </div>
        </div>  
    </Modal>
  );
};

const mapStateToProps = state => ({
  state: state,
});

const mapDispatchToProps = (dispatch) => ({
  setItemData: (resource, value, rowState, header) => dispatch(setItemData(resource, value, rowState, header)),
  sendToDelivered: (resource, loadId) => dispatch(sendToDelivered(resource, loadId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeliveredModal);
