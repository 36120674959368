import React, { useEffect } from 'react';
import { message } from 'antd';

const errorHandler = (OriginalComponent, axios) => {
    return props => {
        const reqInterceptor = axios.interceptors.request.use(req => {
            return req;
        });

        const resInterceptor = axios.interceptors.response.use(
            res => {
                if (res && res.data && res.data.hasError) {
                    message.error(res.data.message);
                        if (res?.data.hasError && res?.data?.duplicatePoNumbers?.length > 0) {
                            setTimeout(() => {
                                const allInputs = document.getElementsByTagName("input");
                                const allInputsValues = Array.from(allInputs)?.map(input => input.value);
                                allInputsValues.forEach((inputValue, index) => {
                                    if (res?.data?.duplicatePoNumbers.includes(inputValue)) {
                                        allInputs[index].classList.add("input-validation-error");
                                        allInputs[index].scrollIntoView({ behavior: "smooth" });;
                                    }
                                })
                            }, 1000);
                        }
                    return null;
                }
                return res;
            },
            err => {
                let error = err;
                console.error(error);
                let errorMessage = 'Something go wrong!';
                if (err.response) {
                    switch (err.response.status) {
                        case 401:
                            errorMessage = 'Unauthorized';
                            break;
                        case 403:
                            errorMessage = 'Access denied!';
                            break;
                        default:
                            errorMessage = 'Something go wrong!';
                            break;
                    }
                }
                message.error(errorMessage);
            }
        );

        useEffect(() => {
            return () => {
                axios.interceptors.request.eject(reqInterceptor);
                axios.interceptors.response.eject(resInterceptor);
            };
        }, [reqInterceptor, resInterceptor]);
        return <OriginalComponent {...props} />;
    };
};

export default errorHandler;