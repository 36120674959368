import axios from 'axios';

import store from '../../store';

const instance = axios.create({
    baseURL: '/api/'
});

instance.defaults.headers.common['Authorization'] = 'Bearer ' + store.getState().auth.userToken;

export default instance;