export const RELOAD_ON_ERROR_LOAD_DETAILS = 'RELOAD_ON_ERROR_LOAD_DETAILS';
export const RESET_ERROR = 'RESET_ERROR';
export const SET_LOAD_DETAILS = 'SET_LOAD_DETAILS';
export const GET_LOAD_DETAILS = 'GET_LOAD_DETAILS';
export const LOAD_DETAILS_LOADING = 'LOAD_DETAILS_LOADING';
export const LOAD_DETAILS_ERROR = 'LOAD_DETAILS_ERROR';
export const GET_CARRIER_LIST = 'GET_CARRIER_LIST';
export const GET_TRUCKS_LIST = 'GET_TRUCKS_LIST';
export const GET_TRAILERS_LIST = 'GET_TRAILERS_LIST';
export const GET_DRIVERS_LIST = 'GET_DRIVERS_LIST';
export const CLEAR_LOAD_DETAILS_DATA = 'CLEAR_LOAD_DETAILS_DATA';
export const GET_EMPTY_EFS = 'GET_EMPTY_EFS';
export const EMPTY_EFS_LOADING = 'EMPTY_EFS_LOADING';
export const EMPTY_EFS_ERROR = 'EMPTY_EFS_ERROR';
export const GET_EFS = 'GET_EFS';
export const GET_EFS_LOADING = 'GET_EFS_LOADING';
export const GET_EFS_ERROR = 'GET_EFS_ERROR';
export const EFS_LIST_LOADING = 'EFS_LIST_LOADING';
export const GET_EFS_LIST = 'GET_EFS_LIST';
export const EFS_LIST_ERROR = 'EFS_LIST_ERROR';
export const SET_EFS_LOADING = 'SET_EFS_LOADING';
export const SET_EFS = 'SET_EFS';
export const SET_EFS_ERROR = 'SET_EFS_ERROR';
export const UPDATE_EFS_LOADING = 'UPDATE_EFS_LOADING';
export const UPDATE_EFS = 'UPDATE_EFS';
export const UPDATE_EFS_ERROR = 'UPDATE_EFS_ERROR';

export const getActionType = (resource, key) => {
    return LOAD_DETAILS_BUILD[key];
};

export const LOAD_DETAILS_BUILD = {
    RELOAD_ON_ERROR_LOAD_DETAILS: 'RELOAD_ON_ERROR_LOAD_DETAILS',
    RESET_ERROR: 'RESET_ERROR',
    SET_LOAD_DETAILS: 'SET_LOAD_DETAILS',
    GET_LOAD_DETAILS: 'GET_LOAD_DETAILS',
    LOAD_DETAILS_LOADING: 'LOAD_DETAILS_LOADING',
    LOAD_DETAILS_ERROR: 'LOAD_DETAILS_ERROR',
    GET_CARRIER_LIST: 'GET_CARRIER_LIST',
    GET_TRUCKS_LIST: 'GET_TRUCKS_LIST',
    GET_TRAILERS_LIST: 'GET_TRAILERS_LIST',
    GET_DRIVERS_LIST: 'GET_DRIVERS_LIST',
    CLEAR_LOAD_DETAILS_DATA: 'CLEAR_LOAD_DETAILS_DATA',
    GET_EMPTY_EFS: 'GET_EMPTY_EFS',
    EMPTY_EFS_LOADING: 'EMPTY_EFS_LOADING',
    EMPTY_EFS_ERROR: 'EMPTY_EFS_ERROR',
    GET_EFS: 'GET_EFS',
    GET_EFS_LOADING: 'GET_EFS_LOADING',
    GET_EFS_ERROR: 'GET_EFS_ERROR',
    EFS_LIST_LOADING: 'EFS_LIST_LOADING',
    GET_EFS_LIST: 'GET_EFS_LIST',
    EFS_LIST_ERROR: 'EFS_LIST_ERROR',
    SET_EFS_LOADING: 'SET_EFS_LOADING',
    SET_EFS: 'SET_EFS',
    SET_EFS_ERROR: 'SET_EFS_ERROR',
    UPDATE_EFS_LOADING: 'UPDATE_EFS_LOADING',
    UPDATE_EFS: 'UPDATE_EFS',
    UPDATE_EFS_ERROR: 'UPDATE_EFS_ERROR',
};
