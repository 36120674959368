import React from "react";

export default (data) => (
  <table className="all_border">
    <thead>
      <tr>
        <th><text>Carrier</text></th>
        <th><text>Phone #</text></th>
        <th><text>Email</text></th>
        <th><text>Equipment</text></th>
        <th><text>Agreed Amount</text></th>
        <th><text>Load Status</text></th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td><wrap left=""><text>{data.carrierName}</text></wrap></td>
        <td><wrap left=""><text>{data.phone}</text></wrap></td>
        <td><wrap left=""><text>{data.accountingEmail}</text></wrap></td>
        <td><wrap left=""><text>{data.equipment}</text></wrap></td>
        <td><wrap left=""><text>{data.rateAmount}</text></wrap></td>
        <td><wrap left=""><text>{data.status}</text></wrap></td>
      </tr>
    </tbody>
  </table>
);