import React from 'react';
import LoaderSmall from './loaderSmall';
import { Tooltip } from 'antd';

const ToolBarButton = ({
    primary,
    text,
    title,
    inProcess,
    onClick,
    clickParams,
    className,
}) => {
    const buttonStyle = inProcess ? { paddingLeft: '5px' } : null;

    const tryToProcess = clickParams => {
        if (inProcess) {
            return;
        }
        onClick(clickParams);
    };

    if (primary) {
        return (
            <button className={`primary button_actions ${className}`} onClick={() => tryToProcess(clickParams)}>
                {inProcess ? <LoaderSmall/> : null }
                <text style={buttonStyle}>
                    {text}
            </text>
        </button>);
    }
    return (<Tooltip title={title} >
        <div
            onClick={() =>
                tryToProcess(clickParams)}
            className="accent button_actions"
        >
            {inProcess ? <LoaderSmall /> : null}
            <text style={buttonStyle}>{text}</text>
        </div>
    </Tooltip>);
};

export default ToolBarButton;