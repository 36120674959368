import { ElementTypes, GenerateToolBars } from '../../config';

const Manifest = {
    Title: 'Manifest',
    Resource: 'Manifest',
    //Master: 'formattedAddress',
    Key: 'id',
    IsRest: true,

    Form: {
        SectionGroups: [
            {
                Sections: [
                    {
                        Fields: {
                            week: {
                                Title: 'Select Week',
                                Field: ElementTypes.Generic(ElementTypes.LabelField, {
                                    dataSource: [],
                                    selectedValueType: `week`,
                                    parentValueType: 'week',
                                }),
                            },
                        }
                    }
                ]
            }
        ],
        ToolBars: GenerateToolBars(['SaveClose', 'Cancel'])
    }
};

export default Manifest;