import React, { useMemo, useCallback } from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';

import { Project } from '../../../project/Project';

import Router from './router';

const Content = props => {
    const routeList = useCallback(() => Project.createRoute(props.menu), [props.menu]);

    return <>{useMemo(() => <Router
                                routeList={routeList}
                            />, [routeList])}</>;
};

export default connect(state => ({ menu: state.auth.userMenu, }))(withRouter(Content));