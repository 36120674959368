import React from 'react';

import Section from './section/section';

const SectionGroup = props => (<div className='section_group'>
    {props.sections && props.sections.map((s, i) =>
        <Section
            classes={s.classes}
            messageIdentityPrefix={props.messageIdentityPrefix}
            componentRefs={props.componentRefs}
            key={i}
            title={s.Title}
            extra={s.Extra}
            fields={s.Fields}
            components={s.Components}
            messages={props.messages}
            messageDispatch={props.messageDispatch}
            dispatch={props.dispatch}
            data={props.data}
            customEvents={props.customEvents}
            clicked={props.clicked} />)}
            
    {props.components
        && props.data
        && Object.keys(props.components).map((c, i) =>
            <React.Fragment key={c + i}>
                {props.components[c].Component(
                    c,
                    props.data.hasOwnProperty(c) ? props.data[c] : props.data,
                    props.dispatch,
                    props.messages,
                    props.messageDispatch,
                    props.componentRefs, 
                    props.messageIdentityPrefix,
                    props.data)}
            </React.Fragment>)}
</div>);

export default SectionGroup;