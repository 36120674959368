import axios from 'axios';
import store from '../..';
import * as actionTypes from './loadDetailsActionTypes';

const buildUrl = (resource, id) => `/api/${resource}/${id}`;
const buildUrlCarrier = (resource, url, carrierId) => (carrierId ? `/api/${resource}/${url}?selectedId=${carrierId}` : `/api/${resource}/${url}`);
const buildUrlDetails = (resource, id, carriedId) => `/api/${resource}/${id}/${carriedId}`;
const buildUrlEFS = (resource, id) => (id ? `/api/${resource}/${id}` : `/api/${resource}`);

const axiosConfig = () => ({
  headers: {
    Authorization: 'Bearer ' + store.getState().auth.userToken,
  },
});

export const getLoadDetails = (resource, id, noLoading) => dispatch => {
  if (noLoading !== 'noLoading') {
    dispatch({ type: actionTypes.getActionType(resource, actionTypes.LOAD_DETAILS_LOADING) });
  }
  axios
    .get(buildUrl(resource, id), axiosConfig())
    .then(response => dispatch({ type: actionTypes.getActionType(resource, actionTypes.GET_LOAD_DETAILS), data: response }))
    .catch(err => {
      console.error(err);
      console.log(err.response);
      dispatch({ type: actionTypes.getActionType(resource, actionTypes.LOAD_DETAILS_ERROR) });
    });
};

export const setLoadDetails = (resource, loadDetails, loadId) => dispatch => {
  dispatch({
    type: actionTypes.getActionType(resource, actionTypes.SET_LOAD_DETAILS),
    data: {
      loadDetails,
    },
  });

  dispatch({ type: actionTypes.getActionType(resource, actionTypes.LOAD_DETAILS_LOADING) });
  axios
    .put(buildUrlEFS(resource), loadDetails, axiosConfig())
    .then(response => {
      if (response.data.hasError) {
        dispatch(errorAction(resource, true, response.data.message, loadId));
        console.error(response.data.message);
      }
      dispatch(reloadAfterUpdate(resource, loadId));
    })
    .catch(err => {
      dispatch(errorAction(resource, true));
      console.error(err);
      console.log(err.response);
    });
};

export const resetError = resource => ({ type: actionTypes.getActionType(resource, actionTypes.RESET_ERROR) });

export const clearLoadDetailsData = resource => ({ type: actionTypes.getActionType(resource, actionTypes.CLEAR_LOAD_DETAILS_DATA) });

const errorAction = (resource, reload, errorText, loadId) => dispatch => {
  if (reload) {
    axios
      .get(buildUrl(resource, loadId), axiosConfig())
      .then(responce =>
        dispatch({
          type: actionTypes.getActionType(resource, actionTypes.RELOAD_ON_ERROR_LOAD_DETAILS),
          data: {
            responce,
            errorText,
          },
        }),
      )
      .catch(err => {
        console.error(err);
        console.log(err.response);
        dispatch({ type: actionTypes.getActionType(resource, actionTypes.LOAD_DETAILS_ERROR) });
      });
  }
};

const reloadAfterUpdate = (resource, loadId) => dispatch => {
  axios
    .get(buildUrl(resource, loadId), axiosConfig())
    .then(responce => dispatch({ type: actionTypes.getActionType(resource, actionTypes.GET_LOAD_DETAILS), data: responce }))
    .catch(err => {
      console.error(err);
      console.log(err.response);
      dispatch({ type: actionTypes.getActionType(resource, actionTypes.LOAD_DETAILS_ERROR) });
    });
};

export const getCarrierList = (resource, carrierId) => dispatch => {
  // dispatch({ type: actionTypes.getActionType(resource, actionTypes.LOAD_DETAILS_LOADING) });
  axios
    .get(buildUrlCarrier(resource, 'expirationdatevaluelistempty', carrierId), axiosConfig())
    .then(responce => dispatch({ type: actionTypes.getActionType(resource, actionTypes.GET_CARRIER_LIST), data: responce }))
    .catch(err => {
      console.error(err);
      console.log(err.response);
      dispatch({ type: actionTypes.getActionType(resource, actionTypes.LOAD_DETAILS_ERROR) });
    });
};

export const getTrucksList = (resource, carrierId) => dispatch => {
  // dispatch({ type: actionTypes.getActionType(resource, actionTypes.LOAD_DETAILS_LOADING) });
  axios
    .get(buildUrlDetails(resource, 'truckvaluelist', carrierId), axiosConfig())
    .then(responce => dispatch({ type: actionTypes.getActionType(resource, actionTypes.GET_TRUCKS_LIST), data: responce }))
    .catch(err => {
      console.error(err);
      console.log(err.response);
      dispatch({ type: actionTypes.getActionType(resource, actionTypes.LOAD_DETAILS_ERROR) });
    });
};

export const getDriversList = (resource, carrierId) => dispatch => {
  // dispatch({ type: actionTypes.getActionType(resource, actionTypes.LOAD_DETAILS_LOADING) });
  axios
    .get(buildUrlDetails(resource, 'phonevaluelistempty', carrierId), axiosConfig())
    .then(responce => dispatch({ type: actionTypes.getActionType(resource, actionTypes.GET_DRIVERS_LIST), data: responce }))
    .catch(err => {
      console.error(err);
      console.log(err.response);
      dispatch({ type: actionTypes.getActionType(resource, actionTypes.LOAD_DETAILS_ERROR) });
    });
};

export const getTrailersList = (resource, carrierId) => dispatch => {
  // dispatch({ type: actionTypes.getActionType(resource, actionTypes.LOAD_DETAILS_LOADING) });
  axios
    .get(buildUrlDetails(resource, 'trailervaluelistempty', carrierId), axiosConfig())
    .then(responce => dispatch({ type: actionTypes.getActionType(resource, actionTypes.GET_TRAILERS_LIST), data: responce }))
    .catch(err => {
      console.error(err);
      console.log(err.response);
      dispatch({ type: actionTypes.getActionType(resource, actionTypes.LOAD_DETAILS_ERROR) });
    });
};

export const getEmptyEFS = resource => dispatch => {
  dispatch({ type: actionTypes.getActionType(resource, actionTypes.EMPTY_EFS_LOADING) });
  axios
    .get(buildUrlEFS(resource, '0'), axiosConfig())
    .then(responce => dispatch({ type: actionTypes.getActionType(resource, actionTypes.GET_EMPTY_EFS), data: responce }))
    .catch(err => {
      console.error(err);
      console.log(err.response);
      dispatch({ type: actionTypes.getActionType(resource, actionTypes.EMPTY_EFS_ERROR) });
    });
};

export const getEFS = (resource, efsId) => dispatch => {
  dispatch({ type: actionTypes.getActionType(resource, actionTypes.GET_EFS_LOADING) });
  axios
    .get(buildUrlEFS(resource, efsId), axiosConfig())
    .then(responce => dispatch({ type: actionTypes.getActionType(resource, actionTypes.GET_EFS), data: responce }))
    .catch(err => {
      console.error(err);
      console.log(err.response);
      dispatch({ type: actionTypes.getActionType(resource, actionTypes.GET_EFS_ERROR) });
    });
};

export const getEFSList = (resource, loadId) => dispatch => {
  const data = {
    params: {
      // request: {
      //     Page: 1,
      //     PageSize: 100,
      //     Sorts: null
      // },
      filterData: { LoadId: loadId },
    },
    headers: axiosConfig().headers,
  };

  dispatch({ type: actionTypes.getActionType(resource, actionTypes.EFS_LIST_LOADING) });
  axios
    .get(buildUrlEFS(resource), data, axiosConfig())
    .then(responce => dispatch({ type: actionTypes.getActionType(resource, actionTypes.GET_EFS_LIST), data: responce }))
    .catch(err => {
      console.error(err);
      console.log(err.response);
      dispatch({ type: actionTypes.getActionType(resource, actionTypes.EFS_LIST_ERROR) });
    });
};

export const setEFS = (resource, newEFSData) => dispatch => {
  dispatch({ type: actionTypes.getActionType(resource, actionTypes.SET_EFS_LOADING) });
  axios
    .post(buildUrlEFS(resource), newEFSData, axiosConfig())
    .then(responce => dispatch({ type: actionTypes.getActionType(resource, actionTypes.SET_EFS), data: responce }))
    .catch(err => {
      console.error(err);
      console.log(err.response);
      dispatch({ type: actionTypes.getActionType(resource, actionTypes.SET_EFS_ERROR) });
    });
};

export const updateEFS = (resource, newEFSData) => dispatch => {
  dispatch({ type: actionTypes.getActionType(resource, actionTypes.UPDATE_EFS_LOADING) });
  axios
    .put(buildUrlEFS(resource), newEFSData, axiosConfig())
    .then(responce => dispatch({ type: actionTypes.getActionType(resource, actionTypes.UPDATE_EFS), data: responce }))
    .catch(err => {
      console.error(err);
      console.log(err.response);
      dispatch({ type: actionTypes.getActionType(resource, actionTypes.UPDATE_EFS_ERROR) });
    });
};
