import React, { useCallback, useRef, useEffect, useState } from 'react';
import { connect } from "react-redux";
import Cell from './cell';
import LeftActionCall from './leftActionCall';
import RightActionCell from './rightActionCell';
import RegionCell from './regionCell';
import RegionContentCell from './regionContentCell';
import { reactOnAssignedItem, reactOnAvailableItem } from '../../../../store/actions/loadArea/loadArea';
import { getContentWidth } from '../../../../store/reducers/loadArea/helper/general';
import { Draggable } from "react-beautiful-dnd";

const Row = (
    {
        data,
        style,
        itemSize,
        reactOnAssignedItem,
        reactOnAvailableItem,
        state,
        listRef,
        dndRowIndex,
        startOnScroll,
        resource,
        onDrugStartMouseX,
        selectedOrder,
    }) => {
    const rowRef = useRef({});
    const [isTBD, setTBD] = useState(false);
    const id = window.location.search && window.location.search.substring(4);
    const loadArea = state[resource];
    let color =     data.rowState.type === 'load' || data.rowState.type === 'separator' ? '#65A7A5' : 
                    id === data.rowState.orderObjectId ? 'lightGray' :
                    data.rowState.reacted ? 'lightGray' :
                    data.rowState.isSub ? '#b4d5bc'
                    : 'inherit';
    
    const borderColor = data.rowState.type === 'order' && 
                        ((resource === 'load' && data.rowState.orderData.loadId) || 
                        (resource === 'outboundload' && data.rowState.orderData.outboundLoadId)) && 
                        data.rowState.orderData.isTbd && 
                        isTBD && '1px solid orangeRed';

    const allOrders = loadArea.loadsAssignedOrders?.filter(order => !order.ordersData && order.rowState.type !== 'separator');
    const allLoads = loadArea.loadsAssignedOrders?.filter(order => order.ordersData && order.rowState.type !== 'separator')
    let loadId = data.rowState.loadObjectId;
    let load = allLoads.filter(load => load?.rowState.loadId === loadId)[0];
    let order = '';
    
    const orderGroupIndex = data.rowState?.orderData?.orderGroupIndex;
    const relatedHardBatchOrders = allOrders.filter(order => order.rowState?.orderData?.orderGroupIndex === orderGroupIndex && order.rowState.orderData?.batchType === 'Hard');
    let orderDropLocationIsDisabled = false;
    relatedHardBatchOrders.forEach(order => {
        if (!!order.rowState.isSub) {
            orderDropLocationIsDisabled = true;
        }
    });

    // Del validation
    let delOrPickNumberColor = '';
    const yellow = '#D4CE39';
    const black = 'white';
    if (data.rowState.type === 'order' && !data.rowState.available ) {
        order = data;
        let orderDeliverNumber = order.rowState.orderData.outDeliverNummber;
        let orderPickUpNumber = order.rowState.orderData.inPickNummber;
        let orderDeliverLocation = order.rowState.orderData.dropLocation;
        let orderPickLocation = order.rowState.orderData.pickLocation;
        const orderAppointment = resource === 'outboundload' ? order.rowState.orderData.outboundDropAppointment : order.rowState.orderData.inboundPickAppointment;
        const isSub = order.rowState.isSub === true;
        let parentOrder = '';
        let parentOrderLoad = '';
        let orderObjectId = '';
        if (isSub) {
            orderObjectId = order.rowState.orderObjectId;
            parentOrder = allOrders.filter(order => order.rowState.orderObjectId === orderObjectId && order.rowState.isSub === false)[0];
            parentOrderLoad = allLoads.filter(load => load?.rowState?.loadId === parentOrder?.rowState?.loadObjectId)[0];
            load = parentOrderLoad;
            orderDeliverNumber = parentOrder?.rowState?.orderData.outDeliverNummber;
            orderPickUpNumber = parentOrder?.rowState?.orderData.inPickNummber;
            loadId = parentOrderLoad?.rowState?.loadId;
        };

        let selectedOrdersByLoc = [];
        let selectedOrdersByDel = [];
        let selectedOrdersByAppt = [];
        let selectedOrdersByPickNum = [];

        if (resource === 'outboundload') {
            if (!isSub) {
                selectedOrdersByLoc = load?.ordersData.filter(order => order.rowState.orderData.dropLocation === orderDeliverLocation);
                selectedOrdersByDel = load?.ordersData.filter(order => order.rowState.orderData.outDeliverNummber === orderDeliverNumber);
                selectedOrdersByAppt = load?.ordersData.filter(order => order.rowState.orderData.outboundDropAppointment?.toString().substring(0, 16) === orderAppointment?.toString().substring(0, 16));
            } else {
                const parentOrder = parentOrderLoad?.ordersData.filter(order => order?.rowState.orderObjectId === orderObjectId)[0];
                orderDeliverLocation = parentOrder?.rowState?.orderData.dropLocation;

                selectedOrdersByLoc = load?.ordersData.filter(order => order?.rowState.orderData.dropLocation === orderDeliverLocation);
                selectedOrdersByDel = load?.ordersData.filter(order => order?.rowState.orderData.outDeliverNummber === orderDeliverNumber);
                selectedOrdersByAppt = load?.ordersData.filter(order => order?.rowState.orderData.outboundDropAppointment?.toString().substring(0, 16) === orderAppointment?.toString().substring(0, 16));
            }
        } else {
            if (!isSub) {
                selectedOrdersByLoc = load?.ordersData?.filter(order => order.rowState.orderData?.pickLocation === orderPickLocation);
                selectedOrdersByPickNum = load?.ordersData.filter(order => order.rowState.orderData?.inPickNummber === orderPickUpNumber);
                selectedOrdersByAppt = load?.ordersData.filter(order => order.rowState.orderData?.inboundPickAppointment?.toString().substring(0, 16) === orderAppointment?.toString().substring(0, 16));
            } else {
                const parentOrder = parentOrderLoad?.ordersData.filter(order => order.rowState.orderObjectId === orderObjectId)[0];
                orderPickLocation = parentOrder.rowState?.orderData.pickLocation;

                selectedOrdersByLoc = load?.ordersData.filter(order => order.rowState.orderData?.pickLocation === orderPickLocation);
                selectedOrdersByPickNum = load?.ordersData.filter(order => order.rowState.orderData?.inPickNummber === orderPickUpNumber);
                selectedOrdersByAppt = load?.ordersData.filter(order => order.rowState.orderData?.inboundPickAppointment?.toString().substring(0, 16) === orderAppointment?.toString().substring(0, 16));
            }
        }

        if (resource === 'outboundload') {
            for (let i = 0; i < selectedOrdersByLoc?.length; i++) {
                if (selectedOrdersByLoc.length === 1 && selectedOrdersByDel.length === 1) {
                    delOrPickNumberColor = black
                } else if ((selectedOrdersByLoc.length === 1 && selectedOrdersByDel.length !== 1) ||
                    (selectedOrdersByLoc.length !== 1 && selectedOrdersByDel.length === 1)) {
                        delOrPickNumberColor = yellow
                } else if (selectedOrdersByLoc[i]?.rowState.id === selectedOrdersByDel[i]?.rowState.id && 
                            selectedOrdersByAppt[i]?.rowState.id === selectedOrdersByDel[i]?.rowState.id &&
                            (selectedOrdersByLoc.length === selectedOrdersByAppt.length)) {
                                delOrPickNumberColor = black;
                } else {
                    // delOrPickNumberColor = yellow;
                }
            };
        }

        if (resource === 'load') {
            for (let i = 0; i < selectedOrdersByLoc.length; i++) {
                if (selectedOrdersByLoc.length === 1 && selectedOrdersByPickNum.length === 1) {
                    delOrPickNumberColor = black
                } else if ((selectedOrdersByLoc.length === 1 && selectedOrdersByPickNum.length !== 1) ||
                    (selectedOrdersByLoc.length !== 1 && selectedOrdersByPickNum.length === 1)) {
                        delOrPickNumberColor = yellow
                } else if (selectedOrdersByLoc[i]?.rowState.id === selectedOrdersByPickNum[i]?.rowState.id && 
                            selectedOrdersByAppt[i]?.rowState.id === selectedOrdersByPickNum[i]?.rowState.id &&
                            (selectedOrdersByLoc.length === selectedOrdersByAppt.length)) {
                                delOrPickNumberColor = black;
                } else {
                    // delOrPickNumberColor = yellow;
                }
            };
        }

        if (resource === 'outboundload' && (orderDeliverNumber === null || orderDeliverNumber === '')) {
            delOrPickNumberColor = yellow;
        }
        if (resource === 'load' && (orderPickUpNumber === null || orderPickUpNumber === '')) {
            delOrPickNumberColor = yellow;
        }

        const orderDeliverNumberIsOnlyInOneLoad = [];
        for (let i = 0; i < allOrders.length; i++) {
            if (allOrders[i].rowState.orderData?.outDeliverNummber === orderDeliverNumber 
                && allOrders[i].rowState.loadObjectId !== loadId
                && allOrders[i].rowState.orderObjectId !== order.rowState.orderObjectId
                && allOrders[i].rowState.isSub === false
                ) {
                orderDeliverNumberIsOnlyInOneLoad.push(orderDeliverNumber)
            }
        }
        if (resource === 'outboundload' && orderDeliverNumberIsOnlyInOneLoad.length > 0) {
            delOrPickNumberColor = yellow;
        };

        const orderPickNumberIsOnlyInOneLoad = [];
        for (let i = 0; i < allOrders.length; i++) {
            if (allOrders[i].rowState.orderData?.inPickNummber === orderPickUpNumber 
                && allOrders[i].rowState.loadObjectId !== loadId
                && allOrders[i].rowState.orderObjectId !== order.rowState.orderObjectId
                && allOrders[i].rowState.isSub === false
                ) {
                    orderPickNumberIsOnlyInOneLoad.push(orderPickUpNumber)
            }
        }
        if (resource === 'load' && orderPickNumberIsOnlyInOneLoad.length > 0) {
            delOrPickNumberColor = yellow;
        };

        const sameDelNumShouldHaveSameLocation = [];
        for (let i = 0; i < allOrders.length; i++) {
            if (allOrders[i].rowState.orderData?.inPickNummber === orderPickUpNumber 
                && allOrders[i].rowState.loadObjectId === loadId
                && allOrders[i].rowState.orderObjectId !== order.rowState.orderObjectId
                && allOrders[i].rowState.orderData?.pickLocation !== order.rowState.orderData?.pickLocation
                && allOrders[i].rowState.isSub === false
                ) {
                    sameDelNumShouldHaveSameLocation.push(orderPickUpNumber)
            }
        }
        if (resource === 'load' && sameDelNumShouldHaveSameLocation.length > 0) {
            delOrPickNumberColor = yellow;
        };

        const samePickNumShouldHaveSameLocation = [];
        for (let i = 0; i < allOrders.length; i++) {
            if (allOrders[i].rowState.orderData?.outDeliverNummber === orderDeliverNumber 
                && allOrders[i].rowState.loadObjectId === loadId
                && allOrders[i].rowState.orderObjectId !== order.rowState.orderObjectId
                && allOrders[i].rowState.orderData?.dropLocation !== order.rowState.orderData?.dropLocation
                && allOrders[i].rowState.isSub === false
                ) {
                    samePickNumShouldHaveSameLocation.push(orderDeliverNumber)
            }
        }
        if (resource === 'outboundload' && samePickNumShouldHaveSameLocation.length > 0) {
            delOrPickNumberColor = yellow;
        };

    }
    // end of del validation

    useEffect(() => {
        if (rowRef.current) {
            listRef.current.resetAfterIndex(0);
        }
    }, [rowRef, listRef]);

    const onClickRow = useCallback(() => {
        if (data.rowState.available) {
            reactOnAvailableItem(resource, data.rowState.id, data.rowState.type);
        }
        else {
            reactOnAssignedItem(resource, data.rowState.id, data.rowState.type);
        }
    }, [reactOnAssignedItem, reactOnAvailableItem, data, resource]);

    style.width = 'max-content';
    const rowStyle = { ...style, ...{ height: `${itemSize}px`, backgroundColor: color, fontSize: '10px' } };
    
    const cellContent = (i, ind) => {
        if (data.rowState.type === 'separator') {
            let contentWidth = getContentWidth(loadArea.headers, 'booking');
            return <div key={ind}
                style={{
                    width: `${contentWidth}px`,
                    height: '4px',
                    backgroundColor: '#325353',
                    borderBottom: '1px solid darkgray'
                }} />
        };

        // if (data.rowState.type === 'emptyRow') {
        //     let contentWidth = getContentWidth(loadArea.headers);
        //     return <div key={ind}
        //         style={{
        //             width: `${contentWidth}px`,
        //             height: '8px',
        //             backgroundColor: '#EAF1FB',
        //             border: '1px solid #EEEEF2',
        //         }} />
        // };

        const header = loadArea.headers.find(h => h.key === i.key) || loadArea.regionHeaders.find(h => h.key === i.key);
        let bgColor, reactedBgColor;

        if (header.leftAction) {
            if (data.rowState.type === 'order') {
                bgColor = 'inherit';
                reactedBgColor = 'inherit';
            }
            if (data.rowState.type === 'load') {
                bgColor = 'lightgrey';
                reactedBgColor = 'grey';
            }
            if (data.rowState.type === 'region') {
                bgColor = 'inherit';
                reactedBgColor = 'inherit';
            }
            if (id === data.rowState.orderObjectId) {
                bgColor = 'lightGray';
                reactedBgColor = 'lightGray';
            }
            bgColor = color;

            return (<LeftActionCall
                innerRef={rowRef}
                key={ind}
                type={data.rowState.type}
                cellData={i}
                reacted={data.rowState.reacted}
                itemSize={itemSize}
                onClickRow={onClickRow}
                bgColor={bgColor}
                data={data}
                reactedBgColor={reactedBgColor}
                resource={resource}
                isSub={data.rowState.isSub}
            />);
        };

        if (header.rightAction) {
            return (<RightActionCell
                key={ind}
                cellData={i}
                reacted={data.rowState.reacted}
                itemSize={itemSize}
                rowState={data.rowState}
                resource={resource}
                isTBD={isTBD}
                setTBD={setTBD}
            />);
        };

        if (data.rowState.type === 'region') {
            if (i.value) {
                return (<RegionContentCell
                    key={ind}
                    cellData={i}
                    itemSize={itemSize}
                    onClickRow={onClickRow}
                    resource={resource}
                />);
            }
            return (<RegionCell
                key={ind}
                cellData={i}
                itemSize={itemSize}
                onClickRow={onClickRow}
                resource={resource}
            />);
        }

        return (<Cell
            key={ind}
            cellData={i}
            reacted={data.rowState.reacted}
            type={data.rowState.type}
            itemSize={itemSize}
            onClickRow={onClickRow}
            startOnScroll={startOnScroll}
            rowState={data.rowState}
            resource={resource}
            delOrPickNumberColor={delOrPickNumberColor}
            selectedOrder={selectedOrder}
            orderDropLocationIsDisabled={orderDropLocationIsDisabled}
            borderColor={borderColor}
        />);
    };

    const myOnMouseDown = e => {
        onDrugStartMouseX.current = e.screenX;
    };

    const content = (
        <Draggable 
            key={data.rowState.id}
            draggableId={data.rowState.id}
            index={dndRowIndex}
            isDragDisabled={data.rowState.type !== 'order' ||
                (data.rowState.loadId && data.rowState.subId !== undefined) || 
                data.rowState.isSub}>
            {provided => {
                

                return (
                    <div
                        onMouseDown={e => myOnMouseDown(e)}
                        className='resp-table-row'
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={{ ...rowStyle, ...provided.draggableProps.style, display:'flex' }}
                    >
                        {data.row.map(cellContent)}
                    </div>
                );
            }}
        </Draggable>
    );

    return data.rowState.visible ? content : null;
};

export default connect(state => ({
    state: state
}), dispatch => ({
    reactOnAssignedItem: (resource, id, type) => dispatch(reactOnAssignedItem(resource, id, type)),
    reactOnAvailableItem: (resource, id, type) => dispatch(reactOnAvailableItem(resource, id, type)),
}))(Row);