import React, { useState } from 'react';
import { message, Upload } from 'antd';

import axios from '../axios/axios';
import errorHandler from '../error/errorHandler';
import { ApiUrl } from '../../project/Pages';

const Uploader = props => {
    const [fileCont, setFileCont] = useState(null);
    const { disabled, className, listType, name, setLoading, setGuid, setOriginalName, restrictions, onChange } = props;

    const beforeUpload = file => {
        if (restrictions && typeof restrictions === 'function' && !restrictions(file)) {
            return false;
        }
        setFileCont(file);
        setGuid && typeof setGuid === 'function' && setGuid(null);
        return true;
    };

    const handleChange = info => {
        if (info.file.status === 'uploading') {
            setLoading(true);
            return;
        }
    };

    const handleUpload = () => {
        const formData = new FormData();
        formData.append('file', fileCont);
        axios.post(ApiUrl.Upload,
            formData
            , { headers: { 'Content-Type': 'multipart/form-data' } }).then(response => {
                if (response && response.data) {
                    const result = response.data;
                    if (result.hasError) {
                        message.error(result.message || "File not uploaded!");
                        setLoading(false);
                        return;
                    }
                    setGuid && typeof setGuid === 'function' && setGuid(result.guId);
                    setOriginalName && typeof setOriginalName === 'function' && setOriginalName(result.originalName);
                    onChange && typeof onChange === 'function' && onChange(result);
                }
            }).finally(() => { setLoading(false); });
    };
    return (
        <Upload
            name={name}
            disabled={disabled}
            listType={listType || 'text'}
            className={className}
            showUploadList={false}
            customRequest={handleUpload}
            beforeUpload={beforeUpload}
            onChange={handleChange}>
            {props.children}
        </Upload>
    );
};

export default errorHandler(Uploader, axios);