import React from 'react';
import { Link } from 'react-router-dom';
import { HomeFilled } from '@ant-design/icons';

const Logo = () => (
    <div className="button mini logo">
        <Link to="/">
            <HomeFilled style={{ fontSize: '18px', color: '#fff' }} />
        </Link>
    </div>
);

export default Logo;