import React from 'react';
import Modal from 'react-modal';
import { useDispatch } from "react-redux";
import { deleteCustomerPrice } from '../../../../store/actions/customers/customers';

const DeletePriceTemplateModal = ({ 
    deletePriceTemplateModalIsOpen,
    setDeletePriceTemplateModalIsOpen,
    customerPriceId,
    customerId,
    priceTemplateType,
    resource,
  }) => {
  const dispatch = useDispatch();

  const closeModal = () => {
    setDeletePriceTemplateModalIsOpen(false);
  };

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      minWidth: '250px',
      maxWidth: '320px',
      minHeight: '180px',      
      borderRadius: '8px',
      padding: '6px 12px',
      textAlign: 'center',
    },
  };

  const confirm = () => {    
    dispatch(deleteCustomerPrice(resource, priceTemplateType, customerPriceId, customerId));
    setDeletePriceTemplateModalIsOpen(false);
  }

  return (
    <Modal
        isOpen={deletePriceTemplateModalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        ariaHideApp={false}
        >
        <div className='price_modal__container'>
          <div className='price_modal_text'>This action can't be undone. Do you want to continue?</div>
          <div>
            <button className='price-modal-button-confirm' type="submit" onClick={confirm}>Confirm</button>
            <button className='price-modal-button-cancel' type="submit" onClick={closeModal}>Cancel</button>
          </div>
        </div>  
    </Modal>
  );
};

export default DeletePriceTemplateModal;