import React from 'react';

import ForgotPasswordFormView from './view/forgotPasswordFormView';

const ForgotPasswordFormScreen = props => (
    <ForgotPasswordFormView isLoading={props.isLoading} title={props.title} text={props.text}>
        {props.children}
    </ForgotPasswordFormView>
);

export default ForgotPasswordFormScreen;