import React from 'react';
import * as Yup from 'yup';
import { phoneRegExp } from '../../../../codeless/helper/common';
import { message } from 'antd';
import { Field, ErrorMessage } from 'formik';

export const countryStateListURL = 'Location/statekeyvaluelist';
export const countryIdUSA = 234;
export const countryIdCanada = 40;

export const getCSVData = factoringCompany => {
  let CSVData = [];
  for (let i = 0; i < factoringCompany?.length; i++) {
      let object = {
          Name: factoringCompany[i]?.rowState.factoringCompanyData.companyName,
          Terms: factoringCompany[i]?.rowState.factoringCompanyData.terms,
          'Mailing Address': factoringCompany[i]?.rowState.factoringCompanyData.mailingAddress,
          'Additional Address': factoringCompany[i]?.rowState.factoringCompanyData.additionalAddress,
          City: factoringCompany[i]?.rowState.factoringCompanyData.city,
          State: factoringCompany[i]?.rowState.factoringCompanyData.state,
          'Zip Code': factoringCompany[i]?.rowState.factoringCompanyData.zipCode,
          Email: factoringCompany[i]?.rowState.factoringCompanyData.email,
          Phone: factoringCompany[i]?.rowState.factoringCompanyData.phone,
          Notes: factoringCompany[i]?.rowState.factoringCompanyData.notes,
      }
      CSVData.push(object);
  };

  return CSVData;
}

export const terms = [
  { label: 'NET 15', text: 'NET 15', value: 'NET 15' },
  { label: 'NET 21', text: 'NET 21', value: 'NET 21' },
  { label: 'NET 30', text: 'NET 30', value: 'NET 30' },
  { label: 'NET 45', text: 'NET 45', value: 'NET 45' },
  { label: 'DD', text: 'DD', value: 'DD' },
];

export const countries = [
  { label: 'USA', text: 'USA', value: countryIdUSA },
  { label: 'Canada', text: 'Canada', value: countryIdCanada },
];

export const validationSchema = Yup.object().shape({
  companyName: Yup.string().required('Company name is required').nullable(),
  terms: Yup.string().nullable(),
  mailingAddress: Yup.string().required('Mailing Address is required').nullable(),
  additionalAddress: Yup.string().nullable(),
  city: Yup.string().required('City is required').nullable(),
  stateId: Yup.string().required('State is required').nullable(),
  zipCode: Yup.number().typeError('Zip code should be number').required('Zip code is required').nullable(),
  countryId: Yup.string().required('Country is required').nullable(),
  email: Yup.string().email('Invalid email').nullable(),
  phone: Yup.string().matches(phoneRegExp, 'Phone number is not valid').nullable(),
  ext: Yup.number().typeError('Ext should be number').nullable(),
  notes: Yup.string().nullable(),
  active: Yup.bool().nullable(),
});

const max = 5;
export const restrictions = file => {
  const isLt = file.size / 1024 / 1024 < max;
  if (!isLt) {
    message.error(`Image must be smaller than ${max}MB!`);
    return false;
  }
  const extensions = ['jpeg', 'jpg', 'png', 'heif', 'pdf'];
  const dot = file.name.lastIndexOf('.') + 1;
  const fileExtension = file.name.substr(dot, file.name.length).toLowerCase();
  if (!extensions.includes(fileExtension)) {
    message.error(`Image must be in jpeg, png, pdf or heif format!`);
    return false;
  }
  return true;
};

export const InputField = (touched, errors, values, label, name) => {
  return (
    <div className='factoring-company__field'>
      <div className='factoring-company__field__label'>{label}</div>
      <Field
        name={name}
        style={touched[name] && errors[name] && { border: '1px solid red' }}
        className={`factoring-company__input ${(values[name])}`}
      />
      {errors && <div className='factoring-error-msg'>{errors[name]}</div>}
    </div>
  )
}