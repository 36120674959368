import React from 'react';
import { connect } from "react-redux";
import { Resizable } from 'react-resizable';
import {setHeaderWidth, sortBy} from '../../../../store/actions/deliveredLoads/deliveredLoads';
import { CaretUpOutlined , CaretDownOutlined} from '@ant-design/icons';

const HeaderCell = ({state, ind, setHeaderWidth, resource, sortBy}) => {
    const loadArea = state[resource];
    const data = loadArea.headers[ind];
    let cellStyle = {
        maxWidth: `${data.width}px`,
        width: `${data.width}px`,
        minWidth: `${data.width}px`,
    };

    if (data.leftAction) {
        cellStyle = {
            ...cellStyle, ...{
                position: 'sticky',
                left: 0,
                maxWidth: `${data.width-1}px`,
                width: `${data.width-1}px`,
                minWidth: `${data.width-1}px`,
            }
        };
    }

    const onResizeStart = (e, s) => {
        console.log('onResizeStart', e, s);
    };

    const onResize = s => {
        setHeaderWidth(resource, ind, s.size.width);
    };

    const onResizeStop = (e, s) => {
        console.log('onResizeStop', e, s);
    };
    const sortableClick = () => {
        if(state.deliveredLoads.sortType && state.deliveredLoads.sortType.sort && state.deliveredLoads.sortType.sort[0] === "ask"){
            sortBy(resource, {type: data.key, ind, sort: ['desc']})
        } else if(state.deliveredLoads.sortType &&  state.deliveredLoads.sortType.sort === null){
            sortBy(resource, {type: data.key, ind, sort: ['ask']})
        } else {
            sortBy(resource, {type: data.key, ind, sort: null})
        }
    }
    const headerCall =  (
        <div
            className='table-header-cell'
            style={cellStyle}
            onClick={()=> data.sortable && sortableClick()
        }>
            <div style={{ display: 'flex', alignItems: 'center', width: "100%", justifyContent: 'center'}}>
                {data.title}
                {data.sortable &&
                    <div style={{display: 'inline-flex', flexDirection: 'column', marginLeft: '3px'}}>
                        {state.deliveredLoads.sortType && state.deliveredLoads.sortType.sort && state.deliveredLoads.sortType.sort[0] === "ask" &&
                            <CaretDownOutlined size={10} />
                        }
                        {state.deliveredLoads.sortType && state.deliveredLoads.sortType.sort && state.deliveredLoads.sortType.sort[0] === "desc" &&
                                <CaretUpOutlined size={10} />
                        }
                        {state.deliveredLoads.sortType && !state.deliveredLoads.sortType.sort &&
                            <>
                                <CaretUpOutlined size={10} /><CaretDownOutlined size={10} />
                            </>
                        }
                    </div>
                }
            </div>
        </div>
    );

    const resizableHeaderCall = (
        <Resizable
            width={data.width}
            height={0}
            handle={
                <span
                    className="react-resizable-handle"
                    onClick={e => {
                        e.stopPropagation();
                    }}
                />
            }
            onResizeStart={(_, s) => onResizeStart(s)}
            onResize={(_, s) => onResize(s)}
            onResizeStop={(_, s) => onResizeStop(s)}
            draggableOpts={{ enableUserSelectHack: false }}
        >
            {headerCall}
        </Resizable>
    );
    return data.leftAction ? headerCall : resizableHeaderCall;
};

export default connect(state => ({
    state: state
}), dispatch => ({
    setHeaderWidth: (resource, ind, width) => dispatch(setHeaderWidth(resource, ind, width)),
    sortBy: (resource, sortValue) => dispatch(sortBy(resource, sortValue))
}))(HeaderCell);