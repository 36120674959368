import React from 'react';

import LoginFormView from './view/loginFormView';

const LoginFormScreen = props => (
    <LoginFormView isLoading={props.isLoading} title={props.title}>
        {props.children}
    </LoginFormView>
);

export default LoginFormScreen;