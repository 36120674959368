import React from 'react';

import axios from '../../../axios/axios';
import errorHandler from '../../../error/errorHandler';

export function AuthenticatedLink({ url, filename, children }) {
    const link = document.createElement('a');

    const handleAction = async (event) => {
        axios.get(url, {
            responseType: 'blob',
            timeout: 30000,
        }).then(response => {
            new File([response.data], filename);
            const href = window.URL.createObjectURL(response.data);
            link.download = filename;
            link.href = href;
            link.click();            
        });
        event.preventDefault();
    };
    return (<a role='button' href={filename} ref={link} onClick={handleAction}>{children}</a>);
};

export default errorHandler(AuthenticatedLink, axios);