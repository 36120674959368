import React from 'react';

import ListView from './view/listView';

const ListScreen = props => {

    const listView = <ListView
        title={props.title}
        showTitle={props.showTitle}
        allowNewButton={props.allowNewButton}
        allowCreateGroupButton={props.allowCreateGroupButton}
        hideSettings={props.hideSettings }
        search={props.search}
        showRefreshButton={props.showRefreshButton}
        isHiddenSwitch={props.isHiddenSwitch}
        loadData={props.loadData}
        screen={props.screen}
        columns={props.columns}
        download={props.download}
        importGSheet={props.importGSheet}
        exportGSheet={props.exportGSheet}
        columnConfig={props.columnConfig}
        addButtonText={props.addButtonText}
        showTitleOnGrid={props.showTitleOnGrid}
        onNewClick={props.onNewClick}
        onCreateGroupClick={props.onCreateGroupClick}
        HasSubNavigation={props.HasSubNavigation}>
        {props.children}
    </ListView>;

    const formList = <div>
        {listView}
    </div>;

    const list = <view className='grid-list'>
        {listView}
    </view>;

    return (
        <>
            {props.isFormList ? formList : list}
        </>
    );
};

export default ListScreen;