import React, { useState } from 'react';

import { PopupTypes } from "../../custom/popup/popup";
import { CommonLabels } from "../../../../project/Defines";

import { BaseData } from "./baseData";

const MAX_PALLET = 30;
const MAX_WEIGHT = 42800;

export const useSelection = (stops, availableOrders, groupBy, groupType, groupTypeValue, openAlert, createKey) => {
    const [selectedOrders, setSelectedOrders] = useState([]);
    const [truckNotes, setTruckNotes] = useState();
    const [availableSelection, setAvailableSelection] = useState(false);

    const getStopOrdersByKey = orderKey => {
        const stop = stops.find(s => s.key === orderKey);
        if (!stop) {
            throw new Error(`Not found stop for key - ${orderKey}`);
        }
        return stop.orders;
    };

    const getOrderActiveState = orderKey => orderKey === BaseData.defaultKey ? availableOrders : getStopOrdersByKey(orderKey);

    const findOtherSameGroupHardBatchOrders = (orderKey, selectedOrder) => getOrderActiveState(orderKey).filter(o => o[groupBy] === selectedOrder[groupBy] && o.id !== selectedOrder.id);

    const removeOtherSameGroupHardBatchOrders = (orderKey, selectedOrder, addedOrders) => {
        const otherSameGroupHardBatchOrders = findOtherSameGroupHardBatchOrders(orderKey, selectedOrder);
        otherSameGroupHardBatchOrders && otherSameGroupHardBatchOrders.forEach(ho => {
            addedOrders.splice(addedOrders.indexOf(ho), 1);
        });
    };

    const updateSelectedOrders = (selectedData, orderKey) => {
        setSelectedOrders([...selectedData]);
        setAvailableSelection(orderKey === BaseData.defaultKey && selectedData && selectedData.length > 0);
        if (orderKey === BaseData.defaultKey) {
            updateTruckNotes(selectedData);
        }
    };

    const addSelected = (orderKey, order, addedOrders) => {
        if (!addedOrders.find(o => o.id === order.id)) {
            addedOrders.push(order);
        }
        if (order[groupType] === groupTypeValue) {
            const otherSameGroupHardBatchOrders = findOtherSameGroupHardBatchOrders(orderKey, order);//ordersState.filter(o => o['orderGroupIndex'] === order['orderGroupIndex'] && o.id !== order.id);
            otherSameGroupHardBatchOrders && otherSameGroupHardBatchOrders.forEach(ho => {
                if (!addedOrders.find(o => o.id === ho.id)) {
                    addedOrders.push(ho);
                }
            });
        }
        return addedOrders;
    };

    const updateTruckNotes = (data) => {
        let totalSpace = 0;
        let totalPallet = 0;
        let totalWeight = 0;
        let avgPallet = 0;
        let avgWeight = 0;
        let stateList = [];

        for (const item of data) {
            totalSpace += item.space;
            totalPallet += item.palletCount;
            totalWeight += item.weight;
            item.pickStateName && !stateList.includes(item.pickStateName) && stateList.push(item.pickStateName);
        }

        avgPallet = Math.ceil(totalPallet / data.length);
        avgWeight = Math.ceil(totalWeight / data.length);

        setTruckNotes({
            totalSpace,
            totalPallet,
            totalWeight,
            stateList,
            avgPallet,
            avgWeight
        });
    };

    const handleCheck = (order) => {
        const orderKey = createKey(order);
        let addedOrders = [];
        if (selectedOrders.find(o => createKey(o) === orderKey)) {
            addedOrders = [...selectedOrders];
        }
        const selectedOrder = addedOrders.find(o => o.id === order.id);
        if (selectedOrder) {
            addedOrders.splice(addedOrders.indexOf(selectedOrder), 1);
            if (selectedOrder[groupType] === groupTypeValue) {
                removeOtherSameGroupHardBatchOrders(orderKey, selectedOrder, addedOrders);
            }
        }
        else {
            addedOrders = addSelected(orderKey, order, addedOrders);
        }
        updateSelectedOrders(addedOrders, orderKey);
    };

    const handleUncheck = () => {
        updateSelectedOrders([], '');
    };

    const onAfterMouseOrdersSelect = (orderData) => {
        if (orderData && orderData.length > 0) {
            const orderKey = createKey(orderData[0]);
            let addedOrders = [];
            if (selectedOrders.find(o => createKey(o) === orderKey)) {
                addedOrders = [...selectedOrders];
            }
            orderData.forEach(o => {
                addedOrders = addSelected(orderKey, o, addedOrders);
            });
            updateSelectedOrders(addedOrders, orderKey);
        }
    };

    const handleSuggest = () => {
        const trucksNeed = Math.max(
            Math.ceil(truckNotes.totalPallet / MAX_PALLET),
            Math.ceil(truckNotes.totalWeight / MAX_WEIGHT)
        );

        openAlert({
            windowKey: "wndConfirmClose",
            type: PopupTypes.Alert,
            title: 'Truck Notes',
            classes: 'truck',
            html: <div>
                <text>Total Weight: {truckNotes.totalWeight?.toLocaleString()}</text>
                <text>Total Pallets: {truckNotes.totalPallet}</text>
                <text>Total Trucks: {trucksNeed}</text>
                <text>States: <ul>{truckNotes.stateList.map((i, index) => <li key={index}>{i}</li>)}</ul></text>
                <text>Average Weight: {truckNotes.avgWeight?.toLocaleString()}</text>
                <text>Average Pallet: {truckNotes.avgPallet}</text>
            </div>,
            buttonYesText: CommonLabels.Buttons.Close,
        });
    };

    return [selectedOrders, truckNotes, availableSelection,
        handleCheck, handleUncheck, onAfterMouseOrdersSelect,
        handleSuggest, updateSelectedOrders];
};