import React, { useEffect, useState, useCallback, useRef } from 'react';
import { connect } from "react-redux";

import { openPopup, closePopup, closePopupAsync } from "../../../../../store/actions/popup/popup";
import axios from '../../../../axios/axios';
import InLoadOnApptsConfig from '../../../../screens/LoadArea/InboundLoad/inLoadInAppts';

import { CloneObject } from '../../../../helper/common';
import errorHandler from '../../../../error/errorHandler';
import Loading from "../../../screen/loading";

import Filter from "../../../list/filter/filter";

import Toolbar from '../generalToolbar';
import { Data } from "./data";
import { useFiltering } from '../useFiltering';
import Stops from '../stops';
import { useAppointment } from './useAppointment';
import { useStopNotes } from '../useStopNotes';

const assignedOrders = "AssignedAppointmentOrders";
const groupBy = 'orderGroupIndex';
const groupType = 'batchType';
const groupTypeValue = 'Hard';
const subGroup = 'pickRegionName';

const InLoadInAppts = (props) => {
    const getOrderConfig = useCallback(() => CloneObject(InLoadOnApptsConfig.Pages.Order), []);
    const getStopConfig = useCallback(() => CloneObject(InLoadOnApptsConfig.Pages.Stop), []);
    const getLoadResource = useCallback(() => CloneObject(InLoadOnApptsConfig), []);
    const getOrderForm = useCallback(() => CloneObject(InLoadOnApptsConfig.Pages.OrderForm), []);
    const getAssignedOrderGroups = useCallback(() => CloneObject(InLoadOnApptsConfig.Pages.AssignedOrderGroups), []);
    const getFilterConfig = useCallback(() => CloneObject(InLoadOnApptsConfig.Pages.Filter), []);

    const [stops, setStops] = useState(null);
    const [loads, setLoads] = useState(null);
    const [loading, setLoading] = useState(false);

    const lastStop = useRef(null);

    const load = useCallback(() => {
        setLoading(true);
        axios({
            url: getLoadResource().AreaResource,
            method: 'GET'
        })
            .then(response => {
                //assigned
                setLoads(response.data.loads.data);
                const d = new Data(response.data.assignedOrders.data, response.data.loads.data);
                setStops(d.stops);
                setLoading(false);
            })
            .catch(error => {
                console.error(error);
                setLoading(false);
            });
    }, [getLoadResource]);

    const getAllStopOrders = lookingStops => lookingStops.reduce((r, a) => {
        a.orders.forEach(o => {
            r.push(o);
        });
        return r;
    }, []);

    const initStops = (data, loadsList) => {
        const d = new Data(data, loadsList);
        setStops(d.stops);
    };

    const [updatePickAppointment, updateDropAppointment] = useAppointment(setStops);
    const [updateInboundStopNotes] = useStopNotes(setStops);

    const [filterData, getFilteredSortedOrders, getFilteredStops] = useFiltering(
        stops,
        [],
        InLoadOnApptsConfig.Pages.Filter,
        null,
        assignedOrders,
        subGroup,
        'pickAppointment'
    );

    useEffect(() => {
        load();
    }, [load]);

    const stopsComponent = <container horizontal="" s_space="" key={'c_1'}>
        <view light=''>
            <Stops
                colgroup
                grouping
                groupBy={groupBy}
                groupType={groupType}
                groupTypeValue={groupTypeValue}
                getFilteredSortedOrders={getFilteredSortedOrders}
                getOrderForm={getOrderForm}
                getAssignedOrderGroups={getAssignedOrderGroups}
                apiResource={'Order/assignloadorderitem'}
                openPopup={props.openPopup}
                stops={stops}
                setStops={setStops}
                closePopupAsync={props.closePopupAsync}
                closePopup={props.closePopup}
                getOrderConfig={getOrderConfig}
                resource={assignedOrders}
                stopResource={InLoadOnApptsConfig.Pages.Stop.Resource}
                getFilteredStops={getFilteredStops}
                lastStop={lastStop}
                getStopConfig={getStopConfig}
                loads={loads}
                getAllStopOrders={getAllStopOrders}
                setLoads={setLoads}
                initStops={initStops}
                getLoadResource={getLoadResource}
                setLoading={setLoading}
                load={load}
                updatePickAppointment={updatePickAppointment}
                updateDropAppointment={updateDropAppointment}
                updateInboundStopNotes={updateInboundStopNotes}
            />
        </view>
    </container>;

    return (
        <>
            <Toolbar
                assignedOrders={assignedOrders}
                getOrderConfig={getOrderConfig}
                getLoadResource={getLoadResource}
                load={load}
            />
            {stops && !loading ?
                <view vertical="">
                    {InLoadOnApptsConfig.Pages.Filter ? (
                        <Filter filtersConfig={getFilterConfig()} data={filterData} />
                    ) : null}
                    <view>
                        {stopsComponent}
                    </view>
                </view> : <Loading />}
        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        openPopup: (data) => dispatch(openPopup(data)),
        closePopup: (key) => dispatch(closePopup(key)),
        closePopupAsync: (key) => dispatch(closePopupAsync(key)),
        openAlert: (key) => dispatch(openPopup(key))
    };
};

export default connect(null, mapDispatchToProps)(errorHandler(InLoadInAppts, axios));