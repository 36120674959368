import { useDispatch } from 'react-redux';

import { PopupTypes } from '../../components/custom/popup/popup';

import { openPopup } from '../../../store/actions/popup/popup';

export const useSectionPopup = (title, windowKey, config) => {
    const dispatch = useDispatch();
    const open = (data, onSave, onCancel) => dispatch(openPopup({
        windowKey: { windowKey },
        type: PopupTypes.ConfigSection,
        title: title,
        config: config,
        data: data,
        onSave: onSave,
        onCancel: onCancel
    }));
    return open;
};