import React from 'react';
// import { TimePicker } from 'antd';
import moment from 'moment';

const week_days = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];
const full_week_days = ['Monday', 'Tuesday', 'Wednesday', 'Thuesday', 'Friday', 'Saturday', 'Sunday'];

function WorkingDays(props) {
    const onChange = (name, value) => {
        let newData = { ...props.data };
        newData[name] = value;

        props.dispatch({ type: props.type, value: newData });
    };

    return (
        <view space="" transparent="">
            <div className="table_view adaptive middle">
                <table>
                    <thead>
                        <tr>
                            {week_days.map((item, i) =>
                                <th key={i}>
                                    <label simple="" checkbox="">
                                        <name><b>{full_week_days[i]}</b></name>
                                        <separator vertical=""></separator>
                                        <input type="checkbox" checked={props.data[item + 'Working']} onChange={(e) => onChange(item + 'Working', e.target.checked)} />
                                        <tip></tip>
                                    </label>
                                </th>
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            {week_days.map(i => {
                                return (
                                    <td key={i}>
                                        <wrap>
                                            <input
                                                type='time'
                                                placeholder='From'
                                                value={props.data[i + 'From'] ? moment(props.data[i + 'From'], 'HH:mm').format('HH:mm') : ''}
                                                onChange={(e) => onChange(i + 'From', e.nativeEvent.target.value)}
                                            />
                                            <input
                                                type='time'
                                                placeholder='To'
                                                value={props.data[i + 'To'] ? moment(props.data[i + 'To'], 'HH:mm').format('HH:mm') : ''}
                                                onChange={(e) => onChange(i + 'To', e.nativeEvent.target.value)}
                                            />
                                            {/* <TimePicker
                                                format={'HH:mm'}
                                                minuteStep={15}
                                                use12Hours={false}
                                                placeholder='From'
                                                timeField={i + 'From'}
                                                dataItem={props.data}
                                                value={props.data[i + 'From'] ? moment(props.data[i + 'From'], 'HH:mm') : null}
                                                onChange={(e, value) => onChange(i + 'From', value)}
                                                onBlur={(e, value) => onChange(i + 'From', e.nativeEvent.target.value)} />
                                            <TimePicker
                                                format={'HH:mm'}
                                                minuteStep={15}
                                                use12Hours={false}
                                                placeholder="To"
                                                timeField={i + 'To'}
                                                dataItem={props.data}
                                                value={props.data[i + 'To'] ? moment(props.data[i + 'To'], 'HH:mm') : null}
                                                onChange={(e, value) => onChange(i + 'To', value)}
                                                onBlur={(e, value) => onChange(i + 'To', e.nativeEvent.target.value)} /> */}
                                        </wrap>
                                    </td>
                                );
                            })}
                        </tr>
                    </tbody>
                </table>
            </div>
        </view>);
}

export default WorkingDays;