import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch } from "react-redux";
import { withRouter, useHistory } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import Select from 'react-select';
import InputMask from 'react-input-mask';
import { message, Upload, Checkbox } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import axios from '../../../axios/axios';
import { ApiUrl } from '../../../../project/Pages';
import { getFactoringCompanyDetails, 
        getFactoringCompanyList,
        addFactoringCompany, 
        updateFactoringCompany,
        deleteFactoringCompany, 
        resetError, 
        getStatesListByCountryId,
        emptyFactoringCompanyDetails,
} from '../../../../store/actions/factoringCompany/factoringCompany';
import { FactoringCompanySelectors } from '../../../../store/selectors/factoringCompanySelectors';
import { customStyles, customStylesForCountries } from './customStyles';
import ThumbnailImage from './thumbnailImage';
import AuthenticatedLink from './authenticatedLink';
import Loading from "../../screen/loading";
import WarningModal from './warningModal';
import { terms, countries, validationSchema, restrictions, countryStateListURL, countryIdUSA, countryIdCanada, InputField } from './helper';

const AddFactoringCompanyDetails = ({ location }) => {
  const dispatch = useDispatch();
  const { loading, factoringCompanyDetails, factoringCompanyList, messageText, hasError, statesList, newFactoryAdded, factoryCompanyUpdated, submitActinType } = FactoringCompanySelectors();
  const resource = 'factoringCompany';
  const id = location.pathname.split('/')[3];
  const history = useHistory();
  let submitAction = '';
  const [ buttonDisabled, setButtonDisabled ] = useState(false);
  const [ fileName, setFileName ] = useState(factoringCompanyDetails?.fileName || '');
  const [ guid, setGuid ] = useState(factoringCompanyDetails?.guid || '');
  const [ countryLocal, setCountry ] = useState('');
  const [ warningModalIsOpen, setWarningModalIsOpen ] = useState(false);
  const [ warningModalText, setWarningModalText ] = useState('');
  const [ fileCont, setFileCont ] = useState(null);

  useEffect(() => {
    if (Number(id) !== 0) dispatch(getFactoringCompanyDetails(resource, id));
    return () => dispatch(emptyFactoringCompanyDetails(resource));
  }, [resource, id, dispatch]);

  useEffect(() => {
    setGuid(factoringCompanyDetails?.guid);
  }, [factoringCompanyDetails]);

  useEffect(() => {
    dispatch(getFactoringCompanyList(resource));
}, [dispatch, resource]);

  useEffect(() => {
    if (hasError) {
      message.error(messageText);
      dispatch(resetError(resource));
    }
  }, [dispatch, hasError, messageText, resource]);

  useEffect(() => {
    if (factoryCompanyUpdated && messageText) {
      message.success(messageText);
      dispatch(resetError(resource));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [factoryCompanyUpdated]);
  

  useEffect(() => {
    if (newFactoryAdded && messageText) {
      message.success(messageText);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newFactoryAdded]);

  useEffect(() => {
    if (submitActinType === 'SAVE & NEW' && newFactoryAdded && factoringCompanyDetails?.id) {
        setTimeout(() => window.location.replace('/otr/factoringDetails/0'), 2500);
    } else if (submitActinType === 'SAVE & NEW' && factoryCompanyUpdated) {
        setTimeout(() => window.location.replace('/otr/factoringDetails/0'), 2500);
    } else if (newFactoryAdded && factoringCompanyDetails?.id) {
        setTimeout(() => history.replace(`/otr/factoringDetails/${factoringCompanyDetails.id}`), 2500);
    }
  }, [history, factoringCompanyDetails, submitActinType, dispatch, newFactoryAdded, factoryCompanyUpdated]);

  useEffect(() => {
    if (Number(factoringCompanyDetails?.countryId) === countryIdCanada) {
      dispatch(getStatesListByCountryId(countryStateListURL, countryIdCanada));
    } else if (Number(factoringCompanyDetails?.countryId) === countryIdUSA) {
      dispatch(getStatesListByCountryId(countryStateListURL, countryIdUSA));
    }
  }, [dispatch, factoringCompanyDetails]);

  useEffect(() => {
    if (Number(countryLocal) === countryIdCanada) {
      dispatch(getStatesListByCountryId(countryStateListURL, countryIdCanada));
    } else if (Number(countryLocal) === countryIdUSA) {
      dispatch(getStatesListByCountryId(countryStateListURL, countryIdUSA));
    }
  }, [dispatch, factoringCompanyDetails, countryLocal]);


  const beforeUpload = file => {
    if (!restrictions(file)) {
      return false;
    }
    setFileCont(file);
    return true;
  };

  const handleUpload = () => {
    const formData = new FormData();
    formData.append('file', fileCont);
    axios.post(ApiUrl.Upload, formData, { headers: { 'Content-Type': 'multipart/form-data' } }).then(response => {
      if (response && response.data) {
        const result = response.data;
        if (result.hasError) {
          message.error(result.message || 'File not uploaded!');
          return;
        }
        onChange(result);
      }
    });
  };

  const onChange = result => {
    setFileName(result.originalName);
    setGuid(result.guId);
  };

  const onDelete = (id) => {
    setGuid(null);
    setFileName(null);
  }

  const states = statesList?.map(state => ({ ...state, label: state.text }));

  const getTextFromValues = useCallback((value, list) => {
    return list?.length ? list?.filter(item => item.value === value)[0]?.text : [];
  }, []);

  const getIdFromValues = useCallback((value, list) => {
    return list?.length ? list?.filter(item => Number(item.value) === Number(value))[0]?.text : [];
  }, []);
  
  const handleSubmitSave = (values, submitAction) => {
    const updatedFactoringCompany = {
      ...factoringCompanyDetails,
      companyName: values.companyName,
      terms: values.terms,
      mailingAddress: values.mailingAddress,
      additionalAddress: values.additionalAddress,
      city: values.city,
      stateId: values.stateId,
      zipCode: values.zipCode,
      countryId: values.countryId,
      email: values.email,
      phone: values.phone,
      ext: values.ext,
      fileName: fileName,
      guid: guid,
      notes: values.notes,
      active: values.active,
    };

    let companyNameIsExist = false;
    let companyNameIsExistAndNotCurrent = false;
    if (factoringCompanyList?.filter(factory => factory.rowState.factoringCompanyData.companyName === values.companyName).length > 0) {
      companyNameIsExist = true;
    };
    if (factoringCompanyList?.filter(factory => 
          factory.rowState.factoringCompanyData.companyName === values.companyName &&
          values.companyName !== factoringCompanyDetails?.companyName).length > 0) {
            companyNameIsExistAndNotCurrent = true;
    };

    // add FactoringCompany
    if (Number(id) === 0 && companyNameIsExist) {
      setWarningModalIsOpen(true);
      setWarningModalText("Company name should be unique");
    } else if (Number(id) === 0 && !companyNameIsExist) {
        dispatch(addFactoringCompany(resource, updatedFactoringCompany, submitAction ))
    }

    // update FactoringCompany
    if (Number(id) !== 0 && companyNameIsExistAndNotCurrent) {
      setWarningModalIsOpen(true);
      setWarningModalText("Company name should be unique");
    } else if (Number(id) !== 0 && !companyNameIsExistAndNotCurrent) {
      dispatch(updateFactoringCompany(resource, updatedFactoringCompany, submitAction));
    }

    setButtonDisabled(false);
  };

  const handleSetFieldValue = (fieldName, value, setFieldValue, setFieldTouched) => {
    setFieldValue(fieldName, value);    
    setTimeout(() => setFieldTouched(fieldName, true));
    if (fieldName === 'countryId') {
      setCountry(value)
    }
  };

  const handleDelete = () => {
    dispatch(deleteFactoringCompany(resource, id));
  }

  if (loading) {
    return <div style={{ marginTop: '18% '}}><Loading /></div>
  };

  return (
      <div className='factoring-company__container'>
        <div className='factoring-company__header'>
          <div className='factoring-company__header-text'>
            <span onClick={() => history.replace(`/otr/factoringCompany`)} className='factoring-company__header-back'>{'<'}</span>
            <span className='vertical-separator'></span>
            {factoringCompanyDetails?.companyName ? factoringCompanyDetails?.companyName : 'New Factoring Company'}
          </div>
        </div>
        <Formik
          initialValues={{
            companyName: factoringCompanyDetails?.companyName,
            terms: factoringCompanyDetails?.terms,
            mailingAddress: factoringCompanyDetails?.mailingAddress,
            additionalAddress: factoringCompanyDetails?.additionalAddress,
            city: factoringCompanyDetails?.city,
            stateId: factoringCompanyDetails?.stateId,
            zipCode: factoringCompanyDetails?.zipCode,
            countryId: factoringCompanyDetails?.countryId,
            email: factoringCompanyDetails?.email,
            phone: factoringCompanyDetails?.phone,
            ext: factoringCompanyDetails?.ext,
            fileName: factoringCompanyDetails?.fileName,
            guid: factoringCompanyDetails?.guid,
            notes: factoringCompanyDetails?.notes,
            active: factoringCompanyDetails?.active,
          }}
          validationSchema={validationSchema}
          enableReinitialize={true}
          onSubmit={(values, { resetForm, setSubmitting, setFieldValue, errors, touched }) => {
              setButtonDisabled(true);
              setTimeout(() => {
                setSubmitting(false);
                if (
                  factoringCompanyDetails?.companyName === values.companyName &&
                  factoringCompanyDetails?.terms === values.terms &&
                  factoringCompanyDetails?.mailingAddress === values.mailingAddress &&
                  factoringCompanyDetails?.additionalAddress === values.additionalAddress &&
                  factoringCompanyDetails?.city === values.city &&
                  factoringCompanyDetails?.stateId === values.stateId &&
                  factoringCompanyDetails?.zipCode === values.zipCode &&
                  factoringCompanyDetails?.countryId === values.countryId &&
                  factoringCompanyDetails?.email === values.email &&
                  factoringCompanyDetails?.phone === values.phone &&
                  factoringCompanyDetails?.ext === values.ext &&
                  factoringCompanyDetails?.fileName === fileName &&
                  factoringCompanyDetails?.guid === guid &&
                  factoringCompanyDetails?.notes === values.notes &&
                  factoringCompanyDetails?.active === values.active
                ) {
                  setWarningModalIsOpen(true);
                  setWarningModalText("You didn't make any changes");
                  setButtonDisabled(false);
                } else {
                  handleSubmitSave(values, submitAction);
                }
              }, 400);
          }}>
          {({ isSubmitting, isValid, values, setFieldValue, setFieldTouched, errors, touched, handleSubmit }) => {
            return (
              <Form>
                <div className='factoring-company_formik_container'>
                  <div className='form__factoring-company'>
                    <div className='formik_form_factoring-company modal_formik_one_column'>
                      
                      {InputField(touched, errors, values, 'Company Name', 'companyName')}

                      <div className='factoring-company__select'>
                        <div className='factoring-company__select__label'>Terms</div>
                        <Select
                          options={terms}
                          name='terms'
                          value={{ label: getTextFromValues(values.terms, terms) }}
                          className='factoring-company__select__select'
                          onChange={option => handleSetFieldValue('terms', option.value, setFieldValue, setFieldTouched)}
                          styles={customStyles}
                          isSearchable={false}
                          components={{ DropdownIndicator:() => <DownOutlined />, IndicatorSeparator:() => null }}
                        />
                      </div>
                      
                      {InputField(touched, errors, values, 'Mailing Address', 'mailingAddress')}
                      {InputField(touched, errors, values, 'Additional Address', 'additionalAddress')}

                      <div className='factoring-company__select'>
                        <div className='factoring-company__select__label'>Country</div>
                        <Select
                          options={countries}
                          name='countryId'
                          value={{ label: getTextFromValues(values.countryId, countries) }}
                          className={`factoring-company__select__select ${touched.countryId && errors.countryId ? 'dropdown_error' : ''} `}
                          onChange={option => handleSetFieldValue('countryId', option.value, setFieldValue, setFieldTouched)}
                          styles={customStylesForCountries}
                          isSearchable={false}
                          components={{ DropdownIndicator:() => <DownOutlined />, IndicatorSeparator:() => null }}
                        />
                        {errors && <div className='factoring-error-msg'>{errors.countryId}</div>}
                      </div>

                      <div className='factoring-company__select'>
                        <div className='factoring-company__select__label'>State</div>
                        <Select
                          options={states}
                          name='stateId'
                          value={{ label: getIdFromValues(values.stateId, states) }}
                          className={`factoring-company__select__select ${touched.stateId && errors.stateId ? 'dropdown_error' : ''} `}
                          onChange={option => handleSetFieldValue('stateId', option.value, setFieldValue, setFieldTouched)}
                          styles={customStyles}
                          components={{ DropdownIndicator:() => <DownOutlined />, IndicatorSeparator:() => null }}
                        />
                        {errors && <div className='factoring-error-msg'>{errors.stateId}</div>}
                      </div>

                      {InputField(touched, errors, values, 'City', 'city')}
                      {InputField(touched, errors, values, 'Zip Code', 'zipCode')}
                  </div>

                  <div className='formik_form_factoring-company modal_formik_one_column'>

                  {InputField(touched, errors, values, 'Email', 'email')}

                    <div className='factoring-company__field'>
                        <div className='factoring-company__field__label'>Phone</div>
                        <InputMask
                          mask='999-999-9999'
                          maskChar={' '}
                          name='phone'
                          country='US'
                          defaultCountry='US'
                          type='text'
                          value={values.phone}
                          onChange={e => setFieldValue('phone', e.target.value)}
                          className='factoring-company__input'
                        />
                        {errors.phone ? <div className='error-msg'>Phone number is not valid</div>: ''}
                    </div>

                    {InputField(touched, errors, values, 'Ext', 'ext')}

                    <div className='factoring-company__upload-btn'>
                      <Upload 
                        className='factoring-company__avatar-uploader' 
                        name='file' 
                        customRequest={handleUpload} 
                        beforeUpload={beforeUpload} 
                        showUploadList={false}>
                        {guid ? <ThumbnailImage guid={guid} originalName={fileName} width={70} height={70} /> : 
                          <div className='factoring-company__upload-empty'>
                            <div className='factoring-company__upload-empty-plus'>+</div>
                            <div>Upload</div>
                          </div>}
                      </Upload>
                      {guid ? 
                        <div className='factoring-company__upload__icons'>
                            <div onClick={onDelete}>
                                <icon>delete</icon>
                            </div>
                            <AuthenticatedLink url={`${ApiUrl.Download}/${guid}`} filename={fileName}>
                                <icon>download</icon>
                            </AuthenticatedLink>
                        </div>
                      : null}
                    </div>

                    <div className='factoring-company__field'>
                      <div className='factoring-company__field__label'>Notes</div>
                      <Field
                        as='textarea'
                        name='notes'
                        style={touched.notes && errors.notes && { border: '1px solid red' }}
                        className='factoring-company__notes'
                      />
                    </div>

                    <div className={`factoring-company__active`}>
                      <Field
                        name='active'
                        type='checkbox'
                        onChange={e => setFieldValue('active', e.target.checked)}
                        component={Checkbox}
                        defaultValue={false}
                        checked={values.active}>
                        <div className='factoring-company__text'>Active</div>
                      </Field>
                    </div>

                    </div>
                  </div>

                  <div className='factoring-company__buttons-container'>
                    <button className='factoring-company__button' type='submit'
                          disabled={!isValid || isSubmitting || buttonDisabled}
                          onClick={() => handleSubmit()}>
                          SAVE
                    </button>
                    <button className='factoring-company__button' type='submit' 
                          disabled={!isValid || isSubmitting || buttonDisabled}
                          onClick={() => {
                            submitAction = 'SAVE & NEW'
                            handleSubmit() }}>
                          SAVE & NEW
                    </button>
                    <button className='factoring-company__button' type='submit' 
                        onClick={() => history.replace(`/otr/factoringCompany`)}>CANCEL
                    </button>
                    <button className='factoring-company__button' type='submit' disabled={Number(id) === 0} 
                        onClick={() => handleDelete()}>DELETE
                    </button>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
        {warningModalIsOpen && 
        <WarningModal 
            warningModalIsOpen={warningModalIsOpen} 
            setWarningModalIsOpen={setWarningModalIsOpen}
            text={warningModalText}
        />}
      </div>
  );
};

export default withRouter(AddFactoringCompanyDetails);
