import React, { useState } from 'react';
import { connect } from "react-redux";
import Modal from 'react-modal';
import moment from 'moment';
import { Upload, message } from 'antd';

import axios from '../../../axios/axios';
import { setItemData } from '../../../../store/actions/deliveredTrucksActions/deliveredTrucks';

import { ApiUrl } from '../../../../project/Pages';
import store from '../../../../store';
import './index.css';

const UploadModal = ({ state, rowState, setItemData, uploadModalIsOpen, setUploadModalIsOpen, setIsVerified, isVerified }) => {
  const closeModal = () => {
    setUploadModalIsOpen(false);
  };

  const resource = 'AccountPayable';
  const loadArea = state[resource];
  const header = loadArea.headers.find(h => h.key === 'podDoc');

  const [fileCont, setFileCont] = useState(null);
  const orderGuid = rowState.loadData?.loadType === 1 ? rowState.orderData.callCheckInAttachment?.guid : rowState.loadData?.loadType === 3 ? rowState.orderData.callCheckOutAttachment?.guid : '';
  const [guid, setGuid] = useState(orderGuid);

  const max = 5;
  const restrictions = file => {
    const isLt = file.size / 1024 / 1024 < max;
    if (!isLt) {
      message.error(`Image must be smaller than ${max}MB!`);
      return false;
    }

    const extensions = ['jpeg', 'jpg', 'png', 'heif', 'pdf'];
    const dot = file.name.lastIndexOf(".") + 1;
    const fileExtension = file.name.substr(dot, file.name.length).toLowerCase();
    if (!extensions.includes(fileExtension)) {
      message.error(`Image must be in jpeg, png, pdf or heif format!`);
      return false;
    }
    return true;
  };

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      minWidth: '360px',
      minHeight: '140px',      
      borderRadius: '8px',
      padding: '6px 12px',
    },
  };

  const beforeUpload = file => {
    if (!restrictions(file)) {
      return false;
    }
    setFileCont(file);
    return true;
  };

  const handleUpload = () => {
    closeModal();
    const formData = new FormData();
    formData.append('file', fileCont);
    axios.post(ApiUrl.Upload, formData, { headers: { 'Content-Type': 'multipart/form-data' } }).then(response => {
      if (response && response.data) {
        const result = response.data;
        if (result.hasError) {
          message.error(result.message || 'File not uploaded!');
          return;
        }
        setGuid(result.guId);
        onChange(result);
      }
    });
  };

  const onChange = result => {
    const mDate = moment(new Date());
    let callCheckAttachment;
    if (result === null) {
      const imageId = rowState.loadData.loadType === 1 ? rowState.orderData.callCheckInAttachment.id : rowState.loadData.loadType === 3 ? rowState.orderData.callCheckOutAttachment.id : '';
      callCheckAttachment = {
        id: imageId,
        guid: guid,
        isNew: false,
        deleted: true,
      }
    } else {
      callCheckAttachment = {
        hasError: result.hasError,
        message: result.message,
        guid: result.guId,
        fileName: result.originalName,
        fileType: result.fileType,
        size: result.size,
        originalFileName: result.originalName,
        fullName: `${store.getState().auth.userFirstName} ${store.getState().auth.userLastName}`,
        createDate: mDate.format(),
        comments: '',
        isNew: true,
        deleted: false,
        external: true,
    };
  }
    setItemData(resource, callCheckAttachment, rowState, header);
  };
  
  return (
    <Modal
        isOpen={uploadModalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        ariaHideApp={false}
        >
        <div className='space_modal__container'>
          <div className='upload_modal_text'>Did you verify all case counts on this load? {isVerified}</div>
          <div className='buttons-container'>
            <Upload name='file' 
                customRequest={handleUpload} 
                beforeUpload={beforeUpload}
                showUploadList={false}
                >
                <div className='upload-modal_button'>Verified</div>
            </Upload>
            <div className='upload-modal_button' onClick={closeModal}>Close</div>
          </div>
        </div>  
    </Modal>
  );
};

const mapStateToProps = state => ({
  state: state,
});

const mapDispatchToProps = (dispatch) => ({
  setItemData: (resource, value, rowState, header) => dispatch(setItemData(resource, value, rowState, header)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadModal);
