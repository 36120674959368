import { NullOrUndefined } from '../../helper/common';

import {
    ElementTypes,
    ComponentTypes,
    ElementValidations
} from '../../config';

import { GenerateToolBars } from '../../config/element.config';
import { statusColor, appointmentStatusColor } from '../Order/colorConfig';
import { InvertColor, HexToRGBAFormat } from '../../helper/common';

const OrderForm = {
    Title: 'OrderForm',
    Resource: 'ReceivingLoad/receivingloadorderitem',
    Master: 'OrderForm',
    Key: 'id',
    IsRest: true,
    Form: {
        Title: 'OrderForm',
        SectionGroups: [
            {
                Sections: [
                    {
                        classes: 'wide',
                        Fields: {
                            Group1: [
                                {
                                    useSeparator: false,
                                    Fields: {
                                        inboundLoadNumber: {
                                            Title: 'Load Number',
                                            Field: ElementTypes.LabelField()
                                        },
                                        truckNumber: {
                                            Title: 'Truck Number',
                                            Field: ElementTypes.LabelField()
                                        },
                                        carrierName: {
                                            Title: 'Carrier',
                                            Field: ElementTypes.LabelField()
                                        }
                                    }
                                }
                            ],
                            Group2: [
                                {
                                    useSeparator: false,
                                    Fields: {
                                        driverName: {
                                            Title: 'Driver',
                                            Field: ElementTypes.LabelField()
                                        },
                                        driverPhone: {
                                            Title: 'Driver Phone',
                                            Field: ElementTypes.Phone()
                                        }
                                    }
                                }
                            ],
                            Group3: [
                                {
                                    useSeparator: false,
                                    Fields: {
                                        inboundPickAppointment: {
                                            Title: 'Pick Appointment',
                                            Field: ElementTypes.DateTimeLabel()
                                        },
                                        inboundDropAppointment: {
                                            Title: 'Drop Appointment',
                                            Field: ElementTypes.DateTimeLabel()
                                        }
                                    }
                                }
                            ],
                            Group4: [
                                {
                                    useSeparator: false,
                                    Fields: {
                                        inbioundAppointmentStatus: {
                                            Title: 'Pick Appointment Status',
                                            Field: ElementTypes.Generic(ElementTypes.LabelField,
                                                {
                                                    style: (_, data) => {
                                                        return {
                                                            color: InvertColor(HexToRGBAFormat(appointmentStatusColor(data), 100), true),
                                                            backgroundColor: appointmentStatusColor(data),
                                                            fontWeight: 'bold',
                                                            width: '100px',
                                                            textAlign: 'center'
                                                        };
                                                    }
                                                })
                                        },
                                        dropInboundAppointmentStatus: {
                                            Title: 'Drop Appointment Status',
                                            Field: ElementTypes.Generic(ElementTypes.LabelField,
                                                {
                                                    style: (_, data) => {
                                                        return {
                                                            color: InvertColor(HexToRGBAFormat(appointmentStatusColor(data), 100), true),
                                                            backgroundColor: appointmentStatusColor(data),
                                                            fontWeight: 'bold',
                                                            width: '100px',
                                                            textAlign: 'center'
                                                        };
                                                    }
                                                })
                                        }
                                    }
                                }
                            ],
                            Group5: [
                                {
                                    useSeparator: false,
                                    Fields: {
                                        inboundIn: {
                                            Title: 'In Time',
                                            Field: ElementTypes.DateTimeLabel()
                                        },
                                        inboundOut: {
                                            Title: 'Out Time',
                                            Field: ElementTypes.DateTimeLabel()
                                        }
                                    }
                                }
                            ],
                            Group6: [
                                {
                                    useSeparator: false,
                                    Fields: {
                                        receiveUser: {
                                            Title: 'Received By',
                                            Field: ElementTypes.LabelField()
                                        },
                                        receiveDate: {
                                            Title: 'Received Date',
                                            Field: ElementTypes.DateTimeLabel()
                                        }
                                    }
                                }
                            ],
                            customerCodeOrName: {
                                Title: 'Customer',
                                Field: ElementTypes.LabelField()

                            },
                            pickLocation: {
                                Title: 'Pick Location',
                                Field: ElementTypes.LabelField()
                            },
                            dropLocation: {
                                Title: 'Drop Off',
                                Field: ElementTypes.LabelField()
                            },
                            Group7: [
                                {
                                    useSeparator: false,
                                    Fields: {
                                        pickRegionCode: {
                                            Title: 'Pick Region',
                                            Field: ElementTypes.LabelField()
                                        },
                                        dropRegionCode: {
                                            Title: 'Drop Region',
                                            Field: ElementTypes.LabelField()
                                        },
                                        batchType: {
                                            Title: 'Batch',
                                            Field: ElementTypes.Generic(ElementTypes.LabelField)
                                        },
                                        orderStatus: {
                                            Title: 'Order Status',
                                            Field: ElementTypes.Generic(ElementTypes.LabelField,
                                                {
                                                    style: (_, data) => {
                                                        return {
                                                            color: InvertColor(HexToRGBAFormat(statusColor(data), 100), true),
                                                            backgroundColor: statusColor(data),
                                                            fontWeight: 'bold'
                                                        };
                                                    }
                                                }
                                            )
                                        }
                                    }
                                }
                            ]
                        }
                    },
                    {
                        Fields: {
                            Group1: [
                                {
                                    useSeparator: false,
                                    Fields: {
                                        puDate: {
                                            Title: 'Pu Date',
                                            Field: ElementTypes.LabelFieldDate()
                                        },
                                        referenceNumber: {
                                            Title: 'Row',
                                            Field: ElementTypes.LabelField(),
                                            Width: 60
                                        }
                                    }
                                }
                            ],
                            dueDate: {
                                Title: 'Due Date',
                                Field: ElementTypes.LabelFieldDate()
                            },
                            dropDate: {
                                Title: 'Drop date ',
                                Field: ElementTypes.LabelFieldDate()
                            },
                            Group2: [
                                {
                                    useSeparator: false,
                                    Fields: {
                                        poNumber: {
                                            Title: 'PO#',
                                            Field: ElementTypes.LabelField()
                                        },
                                        soNumber: {
                                            Title: 'SO#',
                                            Field: ElementTypes.LabelField()
                                        },
                                    }
                                }
                            ],
                            routeId: {
                                Title: 'Route',
                                Field: ElementTypes.Generic(ElementTypes.LabelField, {
                                    display: (_, data) => {
                                        switch (data) {
                                            case 1:
                                                return 'WHS';
                                            case 2:
                                                return 'DIR';
                                            case 3:
                                                return 'Both';
                                            default:
                                                return;
                                        }
                                    }
                                })
                            }
                        }
                    },
                    {
                        Fields: {
                            palletCount: {
                                Title: 'Pallets',
                                Attributes: {
                                    wrap: {
                                        purple: (o, data) => {
                                            if (o && NullOrUndefined(data)) {
                                                return o.space < data ? '' : null;
                                            }

                                            return null;
                                        }
                                    }
                                },
                                Field: ElementTypes.Generic(ElementTypes.LabelField, {
                                    editability: (o) => o['batch'] === 3
                                })
                            },
                            space: {
                                Title: 'Space',
                                Field: ElementTypes.LabelField()
                            },
                            weight: {
                                Title: 'Weight',
                                Field: ElementTypes.LabelDecimalField()
                            },
                            cases: {
                                Title: 'Cases',
                                Field: ElementTypes.LabelDecimalField()
                            },
                            price: {
                                Title: 'Price',
                                Field: ElementTypes.Generic(ElementTypes.LabelFieldCurrency, {
                                    editability: (o) => o['batch'] === 3
                                })
                            },
                            loadingSequencesName: {
                                Title: 'Loading Sequence',
                                Field: ElementTypes.LabelField()
                            }
                        }
                    }
                ]
            },
            {
                Components: {
                    attachments: {
                        Component: ComponentTypes.Attachments('guid', {
                            List: {
                                Columns: {
                                    guid: {
                                        Title: 'Download',
                                        Field: ElementTypes.Download('fileName'),
                                        Width: 80,
                                        Attributes: {
                                            wrap: {
                                                left: ''
                                            }
                                        }
                                    },
                                    fileName: {
                                        Title: 'File Name',
                                        Field: ElementTypes.TextField(),
                                        Validations: [ElementValidations.Required()],
                                        Width: 250,
                                        Attributes: {
                                            wrap: {
                                                left: ''
                                            }
                                        }
                                    },
                                    originalFileName: {
                                        Title: 'Original File Name',
                                        Field: ElementTypes.TableLabelField(),
                                        Width: 250,
                                        Attributes: {
                                            wrap: {
                                                left: ''
                                            }
                                        }
                                    },
                                    size: {
                                        Title: 'File Size',
                                        Field: ElementTypes.Generic(ElementTypes.TableFileSizeLabelField),
                                        Width: 80,
                                        Attributes: {
                                            wrap: {
                                                left: ''
                                            }
                                        }
                                    },
                                    createDate: {
                                        Title: 'Created Date',
                                        Field: ElementTypes.ColumnDate((o, data) => o && data),
                                        Width: 80,
                                        Attributes: {
                                            wrap: {
                                                left: ''
                                            }
                                        }
                                    },
                                    fullName: {
                                        Title: 'Created By',
                                        Field: ElementTypes.TableLabelField(),
                                        Width: 80,
                                        Attributes: {
                                            wrap: {
                                                left: ''
                                            }
                                        }
                                    },
                                    external: {
                                        Title: 'External',
                                        Field: ElementTypes.ColumnBool(),
                                        Width: 60,
                                        Attributes: {
                                            wrap: {
                                                left: ''
                                            }
                                        }
                                    }
                                }
                            },
                            Popup: {
                                Title: 'Attachment Comment',
                                SectionGroups: [
                                    {
                                        Sections: [
                                            {
                                                Fields: {
                                                    fileName: {
                                                        Title: 'File Name',
                                                        Field: ElementTypes.LabelField()
                                                    },
                                                    originalFileName: {
                                                        Title: 'Original File Name',
                                                        Field: ElementTypes.LabelField()
                                                    },
                                                    size: {
                                                        Title: 'File Size',
                                                        Field: ElementTypes.FileSizeLabelField()
                                                    },
                                                    external: {
                                                        Field: ElementTypes.CheckBox('External Document')
                                                    },
                                                    comments: {
                                                        Title: 'File Comment',
                                                        Field: ElementTypes.Comment(),
                                                        Focus: true
                                                    }
                                                }
                                            }
                                        ]
                                    }
                                ],
                                ToolBars: GenerateToolBars(['Save', 'Cancel'])
                            }
                        })
                    }
                }
            },
            {
                Components: {
                    comments: {
                        Component: ComponentTypes.CommentsSheet('id', {
                            createDate: {
                                Title: 'Date Time',
                                Field: ElementTypes.ColumnDateTime((o, data) => o && data),
                                Width: 140
                            },
                            user: {
                                Title: 'Created By',
                                Field: ElementTypes.TableLabelField(),
                                Width: 150,
                                Attributes: {
                                    wrap: {
                                        left: ''
                                    }
                                }
                            },
                            comment: {
                                Title: 'Comment',
                                Field: ElementTypes.TableLabelField(),
                                Attributes: {
                                    wrap: {
                                        left: ''
                                    }
                                }
                            }
                        },
                        'table_view extra')
                    }
                }
            },
            {
                Sections: [
                    {
                        classes: 'wide',
                        Fields: {
                            comment: {
                                Title: 'Comment',
                                Field: ElementTypes.Comment(),
                                Attributes: {
                                    textarea: {
                                        note: ''
                                    }
                                }
                            }
                        }
                    }
                ]
            }
        ],
        ToolBars: GenerateToolBars(['SaveClose', 'Cancel'])
    }
};

export default OrderForm;