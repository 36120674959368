import React from 'react';
import Company from './company';
import LoadInfo from './loadInfo';
import Carrier from './carrier';
import StopList from './stopList';

export default function RateConfirmationForm(props) {
  const stopListProps = {...props};
  stopListProps.type = 'stopList';

  return (
    <div className="form_container">
      <view space="" intro="">
        <div className="section_group">
          <div className="sections extra">
            <div className="pdf table_view extra">
              <table>
                <caption>Rate & Load Confirmation</caption>
                <tbody>
                  <tr className="no_border">
                    <td>
                      {Company(props.data.company)}
                    </td>
                    <td className="right" colSpan="2">
                      {LoadInfo(props.data.loadInfo)}
                    </td>
                  </tr>
                  <tr className="separator">
                    <th></th>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      {Carrier(props.data.carrierData)}
                    </td>
                  </tr>
                  <tr className="separator">
                    <th></th>
                  </tr>
                  {StopList(stopListProps, props.data.stopList)}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </view>
    </div>
  );
};