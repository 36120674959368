import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getLocationsList } from '../../../../store/actions/customers/customers';
import Select from 'react-select';
import { colorStyles } from './css_ant-reset';
import Loading from '../../screen/loading';
import { CustomerSelectors } from '../../../../store/selectors/customerSelectors';
import './index.css';

const Locations = ({ type, data, dispatch: innerDispatch }) => {
  const dispatch = useDispatch();
  const { locations, loading } = CustomerSelectors();
  const resource = 'location';

  useEffect(() => {
    dispatch(getLocationsList(resource, data?.id));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, resource]);

  const defaultEmptyLocation = [
    {
      favLocationId: 0,
    },
  ];

  const activeLocationsIDsList = [];
  for (let i = 0; i < locations?.length; i++) {
    for (let j = 0; j < data?.favLocations.length; j++) {
      if (locations[i].value === data.favLocations[j].favLocationId) {
        activeLocationsIDsList.push(locations[i].value);
      };
    };
  };

  const defaultLocation = data?.favLocations?.length ? data?.favLocations?.filter(loc => activeLocationsIDsList.includes(loc.favLocationId)) : defaultEmptyLocation;
  const [selectedLocations, setSelectedLocations] = useState();
  const [showNewSelect, setShowNewSelect] = useState(false);
  const updatedLocations = locations?.map(loc => activeLocationsIDsList.includes(loc.value))

  useEffect(() => {
    setSelectedLocations(defaultLocation);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.favLocations, locations]);

  updatedLocations?.length && updatedLocations[0].value !== '' && updatedLocations.unshift({ value: '', label: '' });
  locations?.length && locations[0].value !== '' && locations.unshift({ value: '', label: '' });

  const optionsFavoriteLocations =
    locations &&
    locations.map(item => {
      return { ...item, label: item.text };
    });

  const getTextFromValueFavoriteLocations = value => {
    return selectedLocations?.length ? selectedLocations?.filter(item => item.favLocationId === value)[0]?.favLocationName : [];
  };

  const onChangeHandle = (value, index) => {
    const selectedLocation = locations?.length && locations?.filter(item => item.value === value)[0];
    const updatedLocation = {
      favLocationId: selectedLocation.value,
      favLocationName: selectedLocation.text,
    };
    const updatedLocationsWithNewAdded = [...selectedLocations.slice(0, index), ...[updatedLocation], ...selectedLocations.slice(index + 1)];
    const updatedLocationsWithOutEmpty = updatedLocationsWithNewAdded.filter(location => location.favLocationId !== '');
    innerDispatch({ type: 'favLocations', value: updatedLocationsWithOutEmpty });
  };

  const showHideNewLocation = () => {
    setShowNewSelect(!showNewSelect);
  };

  const onAddNewLocation = value => {
    const selectedLocation = locations?.length && locations?.filter(item => item.value === value)[0];
    const newLocation = {
      favLocationId: selectedLocation.value,
      favLocationName: selectedLocation.text,
    };
    const updatedLocations = [...selectedLocations, newLocation];
    setShowNewSelect(!showNewSelect);
    innerDispatch({ type: 'favLocations', value: updatedLocations });
  };

  return ( 
    loading ? <div><Loading /></div> :
    <>
      <div className='locations__title'>Locations</div>
      {selectedLocations?.map((location, index) => {
        return (
          <Select
            options={optionsFavoriteLocations}
            name={`favLocation${index}`}
            value={{ label: getTextFromValueFavoriteLocations(location?.favLocationId ? location?.favLocationId : location?.value) }}
            onChange={option => onChangeHandle(option.value, index)}
            styles={colorStyles}
            menuPlacement='auto'
          />
        );
      })}
      {showNewSelect && (
        <Select
          options={optionsFavoriteLocations}
          name={`favLocation`}
          onChange={option => onAddNewLocation(option.value)}
          styles={colorStyles}
          menuPlacement='auto'
        />
      )}
      <div className='button__add-location' onClick={showHideNewLocation}>
        {showNewSelect ? 'Remove empty row' : 'Add new location'}
      </div>
    </>
  );
};

export default Locations;
