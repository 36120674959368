import { CloneObject } from '../../../helper/common';
import {
    Action,
    ListDefaults,
    ColumnType,
    FilterType,
    DefaultPagination,
    InlineTable
} from '../../../config';

import DefaultStopColumns from '../defaultStopColumns';
import DefaultOrderColumns from './defaultOrderColumns';
import OrderForm, { OutboundGroup1, OutboundGroup2, OutboundGroup3, OutboundGroup4, OutboundGroup5, Group1, Group2, Group3, Group4, Group5 } from '../orderFormConfig';
import Dispatch from './dispatchConfig';

const tableConfig = CloneObject(InlineTable);
tableConfig.Style.pagination = false;

export const AppointmentStatus = [
    { value: 'Open', text: 'Open' },
    { value: 'ReOpen', text: 'ReOpen' },
    { value: 'Request', text: 'Request' },
    { value: 'Requested', text: 'Requested' },
    { value: 'Confirmed', text: 'Confirmed' },
];

const InTransit = {
    Title: 'InTransit',
    Resource: 'OutboundInTransit',
    AreaResource: 'OutboundInTransit/area',
    LoadResource: 'OutboundInTransit',
    Master: 'code',
    Key: 'id',
    IsRest: true,
    ShowRefreshButton: true,
    Pages: {
        Filter: {
            loadNumber: {
                Title: 'Load Number',
                Type: FilterType.Text
            },
            truckId: {
                Title: 'Truck Number',
                Type: FilterType.Select,
                Valuelist: 'truck'
            },
            carrierId: {
                Title: 'Carrier Name',
                Type: FilterType.Select,
                Valuelist: 'carrier'
            },
            pickStatus: {
                Title: 'Pick Status',
                Type: FilterType.Select,
                Valuelist: AppointmentStatus
            },
            dropStatus: {
                Title: 'Drop Status',
                Type: FilterType.Select,
                Valuelist: AppointmentStatus
            }
        },
        Order: {
            Key: 'id',
            List: {
                Defaults: ListDefaults,
                ShowRefreshButton: true,
                Pagination: DefaultPagination,
                Columns: {
                    ...DefaultOrderColumns
                },
                Actions: {
                    Width: 60,
                    Items: [
                        Action.Edit
                    ]
                }
            }
        },
        OrderForm,
        AssignedOrderGroups: [
            OutboundGroup1, OutboundGroup2, OutboundGroup3, OutboundGroup4, OutboundGroup5,
            Group1, Group2, Group3, Group4, Group5
        ],
        Stop: {
            Title: 'Load',
            Master: 'loadNumber',
            Resource: 'OutboundInTransit',
            Key: 'id',
            IsRest: true,
            List: {
                Defaults: {
                    View: {
                        AutoHeight: null,
                        Small: null,
                    },
                    Table: tableConfig
                },
                Coloring: record => record.color,
                Columns: {
                    ...DefaultStopColumns(null, null),
                    carrierName: {
                        Title: 'Carrier',
                        NoSort: true,
                        Display: o => o.isFirst,
                        Type: ColumnType.Text,
                        Width: 100
                    },
                    rate: {
                        Title: 'Rate',
                        NoSort: true,
                        Display: o => o.isFirst,
                        Type: ColumnType.Text,
                        Width: 100
                    },
                    driverName: {
                        Title: 'Driver',
                        NoSort: true,
                        Display: o => o.isFirst,
                        Type: ColumnType.Text,
                        Width: 100
                    },
                    driverPhone: {
                        Title: 'Phone #',
                        NoSort: true,
                        Display: o => o.isFirst,
                        Type: ColumnType.Phone,
                        Width: 100
                    },
                    truckNumber: {
                        Title: 'Truck #',
                        NoSort: true,
                        Display: o => o.isFirst,
                        Type: ColumnType.Text,
                        Width: 80
                    },
                    trailer: {
                        Title: 'Trailer #',
                        NoSort: true,
                        Display: o => o.isFirst,
                        Type: ColumnType.Text,
                        Width: 80
                    },
                    in: {
                        Title: 'Check In',
                        NoSort: true,
                        Type: ColumnType.DateTime,
                        Width: 120
                    },
                    out: {
                        Title: 'Check Out',
                        NoSort: true,
                        Type: ColumnType.DateTime,
                        Width: 120
                    }
                },
                Actions: {
                    Width: 60,
                    Attributes: {
                        action: {
                            left: ''
                        }
                    },
                    Items: [
                        Action.Dispatch,
                        r => r.isFirst ? Action.Done : null
                    ]
                },
                expandedRowKeys: [0, '']
            }
        },
        Dispatch
    }
};

export default InTransit;