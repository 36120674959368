import React from 'react';
import HeaderCell from './headerCell'
import { FactoringCompanySelectors } from '../../../../store/selectors/factoringCompanySelectors';

const HeaderRow = ({ resource }) => {
    const { state } = FactoringCompanySelectors();
    const headers = state[resource].headers;
    return (
        <div className='resp-table-header header-factory'>
            {headers.map((_, ind) => {
                return <HeaderCell key={ind} ind={ind} resource={resource} />
            })}
        </div>
    );
};

export default HeaderRow;