import React, { useRef, useEffect } from 'react';
import { connect } from "react-redux";
import Cell from './cell';

const Row = (
    {
        data,
        style,
        itemSize,
        listRef,
        state,
        startOnScroll,
        resource,
        index,
    }) => {
    const rowRef = useRef({});
    const color = index % 2 === 0 ? '#F9FFFF' : '#FFFFFF';

    useEffect(() => {
        if (rowRef.current) {
            listRef.current.resetAfterIndex(0);
        }
    }, [rowRef, listRef]);

    style.width = 'max-content';
    const rowStyle = { 
        ...style, 
        ...{ 
            height: `${itemSize}px`, 
            backgroundColor: color,
            color: '#080E0E',
        }
    };
    
    const cellContent = (i, ind) => {
        return (<Cell
                key={ind}
                cellData={i}
                type={data.rowState.type}
                itemSize={itemSize}
                startOnScroll={startOnScroll}
                rowState={data.rowState}
                resource={resource}
            />);
    };

    return data ? 
        <div className='resp-table-row lastRowBorder lastRowBorder_customer' style={{ ...rowStyle, display:'flex' }}>
            {data.row.map(cellContent)}
        </div> : null;
    };

export default connect(state => ({ state: state}))(Row);