import { notification } from 'antd';
import moment from 'moment';

import { CloneObject } from '../../../helper/common';
import {
    Action,
    ListDefaults,
    ColumnType,
    FilterType,
    DefaultPagination,
    InlineTable,
    ElementTypes,
    ComponentTypes,
    ElementValidations
} from '../../../config';
import { GenerateToolBars } from '../../../config/element.config';

import { Defines } from '../../../../../src/project/Defines';
import DefaultStopColumns from '../defaultStopColumns';
import DefaultOrderColumns from './defaultOrderColumns';
import OrderForm, { OutboundGroup1, OutboundGroup2, OutboundGroup3, OutboundGroup4, OutboundGroup5, Group1, Group2, Group3, Group4, Group5 } from '../orderFormConfig';
import PickAppointment from './pickAppointmentConfig';
import DropAppointment from './dropAppointmentConfig';

import TruckConfig from '../../Truck/config';
import TrailerConfig from '../../Trailer/config';
import DriverConfig from '../../Driver/config';
import CarrierConfig from '../../Carrier/config';
import StopNotes from './stopNotesForm';

const CarrierForm = CloneObject(CarrierConfig);
CarrierForm.Form = CarrierForm.Form.Tabstrip[0].Form;

const beforeUpdate = (data, parentVal) => {
    return { ...data, carrierId: parentVal };
};

const tableConfig = CloneObject(InlineTable);
tableConfig.Style.pagination = false;

const truckConfig = CloneObject(TruckConfig);
delete truckConfig.Form.SectionGroups[0].Sections[0].Fields.carrierId;

truckConfig.beforeUpdate = beforeUpdate;

const trailerConfig = CloneObject(TrailerConfig);
delete trailerConfig.Form.SectionGroups[0].Sections[0].Fields.carrierId;

trailerConfig.beforeUpdate = beforeUpdate;

const driverConfig = CloneObject(DriverConfig);
delete driverConfig.Form.SectionGroups[0].Sections[0].Fields.carrierId;

driverConfig.beforeUpdate = beforeUpdate;

export const AppointmentStatus = [
    { value: 'Open', text: 'Open' },
    { value: 'ReOpen', text: 'ReOpen' },
    { value: 'Request', text: 'Request' },
    { value: 'Requested', text: 'Requested' },
    { value: 'Confirmed', text: 'Confirmed' },
];

const Appointment = {
    Title: 'OutLoadOutAppts',
    Resource: 'OutboundAppointment',
    AreaResource: 'OutboundAppointment/area',
    LoadResource: 'OutboundAppointment',
    Master: 'code',
    Key: 'id',
    IsRest: true,
    ShowRefreshButton: true,
    Pages: {
        Filter: {
            loadNumber: {
                Title: 'Load Number',
                Type: FilterType.Text
            },
            truckId: {
                Title: 'Truck Number',
                Type: FilterType.Select,
                Valuelist: 'truck'
            },
            carrierId: {
                Title: 'Carrier Name',
                Type: FilterType.Select,
                Valuelist: 'carrier'
            },
            pickStatus: {
                Title: 'Pick Status',
                Type: FilterType.Select,
                Valuelist: AppointmentStatus
            },
            dropStatus: {
                Title: 'Drop Status',
                Type: FilterType.Select,
                Valuelist: AppointmentStatus
            }
        },
        Order: {
            Key: 'id',
            List: {
                Defaults: ListDefaults,
                ShowRefreshButton: true,
                Pagination: DefaultPagination,
                Columns: {
                    ...DefaultOrderColumns
                },
                Actions: {
                    Width: 60,
                    Items: [
                        Action.Edit
                    ]
                }
            }
        },
        OrderForm,
        AssignedOrderGroups: [OutboundGroup1, OutboundGroup2, OutboundGroup3, OutboundGroup4, OutboundGroup5, Group1, Group2, Group3, Group4, Group5],
        Stop: {
            Title: 'Load',
            Master: 'loadNumber',
            Resource: 'OutboundLoad',
            Key: 'id',
            IsRest: true,
            List: {
                Defaults: {
                    View: {
                        AutoHeight: null,
                        Small: null,
                    },
                    Table: tableConfig
                },
                Coloring: record => record.color,
                Columns: {
                    ...DefaultStopColumns(PickAppointment, DropAppointment),
                    carrierName: {
                        Title: 'Carrier',
                        NoSort: true,
                        Display: o => o.isFirst,
                        Type: ColumnType.Text,
                        Width: 100
                    },
                    rate: {
                        Title: 'Rate',
                        NoSort: true,
                        Display: o => o.isFirst,
                        Type: ColumnType.Text,
                        Width: 100
                    },
                    driverName: {
                        Title: 'Driver',
                        NoSort: true,
                        Display: o => o.isFirst,
                        Type: ColumnType.Text,
                        Width: 100
                    },
                    driverPhone: {
                        Title: 'Phone #',
                        NoSort: true,
                        Display: o => o.isFirst,
                        Type: ColumnType.Phone,
                        Width: 100
                    },
                    truckNumber: {
                        Title: 'Truck #',
                        NoSort: true,
                        Display: o => o.isFirst,
                        Type: ColumnType.Text,
                        Width: 80
                    },
                    trailer: {
                        Title: 'Trailer #',
                        NoSort: true,
                        Display: o => o.isFirst,
                        Type: ColumnType.Text,
                        Width: 80
                    },
                    readyToBook: {
                        Title: 'Booked',
                        NoSort: true,
                        Type: ColumnType.Bool,
                        Width: 80
                    },
                    // stopNotes: {
                    //     Title: 'Notes',
                    //     NoSort: true,
                    //     Type: ColumnType.SimpleIndicator,
                    //     Width: 80
                    // }
                    outboundStopNotes: {
                        Title: 'Notes',
                        NoSort: true,
                
                        Type: ColumnType.Button,
                        Icon: 'chat',
                        PopupConfig: {
                            Resource: '',
                            Form: StopNotes
                        },
                        Action: 'OpenForm',
                        ActionType: 'OutboundStopNotes',
                
                        Display: o => !o.isEmpty,
                
                        Width: 60
                    }
                },
                Actions: {
                    Display: o => o.isFirst,
                    Width: 60,
                    Items: [
                        Action.Edit,
                        (o) => o.inTransit ? Action.RemoveInTransit : Action.InTransit
                    ]
                },
                expandedRowKeys: [0, '']
            },
            Form: {
                Title: 'Truck',
                SectionGroups: [
                    {
                        Sections: [
                            {
                                Fields: {
                                    loadNumber: {
                                        Title: 'Load Number',
                                        Field: ElementTypes.LabelField(o => o.id > 0)
                                    },
                                    Group1: [
                                        {
                                            Fields: {
                                                rate: {
                                                    Title: 'Rate',
                                                    Field: ElementTypes.CurrencyField(),
                                                },
                                                color: {
                                                    Title: 'Color',
                                                    Field: ElementTypes.Color(),
                                                }   
                                            }
                                        }
                                    ],
                                    // file:{
                                    //     Title: 'Rate Confirmation',
                                    //     Field: ElementTypes.File(),
                                    // },
                                    Components: {
                                        rateConfirmation: {
                                            Component: ComponentTypes.RateConfirmation('id')
                                        }
                                    },
                                    notes: {
                                        Title: 'Notes',
                                        Field: ElementTypes.Comment(),
                                        Attributes: {
                                            textarea: {
                                                note: ''
                                            }
                                        }
                                    }
                                 }
                            },
                            {
                                Fields: {
                                    carrierId: {
                                        Title: 'Carrier',
                                        //Field: ElementTypes.ComboAdd('/api/carrier/expirationdatevaluelistempty', {
                                        //    PopupConfig: CarrierForm
                                        //}),
                                        Field: ElementTypes.Generic(ElementTypes.ComboAdd, {
                                            dataSource: '/api/carrier/expirationdatevaluelistempty',
                                            addparams: {
                                                PopupConfig: CarrierForm
                                            },
                                            selectedValueType: 'carrierName'
                                        }),
                                        Check: (source, data) => {
                                            const selectedSource = source.find(carrier => carrier['value'] === data);
                                            const expirationDate = moment(selectedSource.expirationDate).format(Defines.Format.MomentDate);

                                            switch (selectedSource.expirationDateNum) {
                                                case 1:
                                                    notification.error({
                                                        description: `The following ${selectedSource.text} Insurance is set to expire on ${expirationDate}.
                                                        Update Insurance Information with the most current information to continue.`,
                                                        duration: 60,
                                                        style: {
                                                            padding: '30px'
                                                        }
                                                    });

                                                    return false;

                                                case 2:
                                                    notification.error({
                                                        description: `The following ${selectedSource.text} Insurance has expired on the ${expirationDate}.
                                                        Update Insurance Information with the most current information to continue.`,
                                                        duration: 60,
                                                        style: {
                                                            padding: '30px'
                                                        }
                                                    });

                                                    return false;

                                                default:
                                                    return true;
                                            }
                                        },
                                        Dependencies: ['truckId', 'driverId', 'trailerId', 'trailerTypeName', 'trailerSize', 'driverPhone']
                                    },
                                    truckId: {
                                        Title: 'Truck Number',
                                        Validations: [ElementValidations.Required()],
                                        Field: ElementTypes.Generic(ElementTypes.ComboAdd, {
                                            dataSource: '/api/truck/truckvaluelist/',
                                            addparams: {
                                                TitlePrefix: '',
                                                PopupConfig: truckConfig
                                            },
                                            parentValueType: 'carrierId',
                                            reloadOnParentChange: true,
                                            selectedValueType: 'truckNumber',
                                            editability: o => o && o['carrierId'] > 0
                                        }),
                                    },
                                    trailerId: {
                                        Title: 'Trailer',
                                        Validations: [ElementValidations.Required()],
                                        Field: ElementTypes.Generic(ElementTypes.ComboAdd, {
                                            dataSource: '/api/trailer/trailervaluelistempty/',
                                            addparams: {
                                                TitlePrefix: '',
                                                PopupConfig: trailerConfig
                                            },
                                            parentValueType: 'carrierId',
                                            reloadOnParentChange: true,
                                            selectedValueType: 'trailer',
                                            editability: o => o && o['carrierId'] > 0
                                        }),
                                        Dependencies: [
                                            //['trailer', 'text'],
                                            ['trailerTypeName', 'trailerTypeName'],
                                            ['trailerSize', 'trailerSize']
                                        ]
                                    },
                                    Group1: [
                                        {
                                            Title: 'Trailer',
                                            Fields: {
                                                trailerTypeName: {
                                                    Title: 'Type',
                                                    Field: ElementTypes.LabelField(o => o.id > 0),
                                                    Dependencies: ['trailerId']
                                                },
                                                trailerSize: {
                                                    Title: 'Size',
                                                    Field: ElementTypes.LabelField(o => o.id > 0),
                                                    Dependencies: ['trailerId']
                                                }
                                            }
                                        }
                                    ]
                                }
                            },
                            {
                                Fields: {
                                    driverId: {
                                        Title: 'Driver',
                                        Field: ElementTypes.Generic(ElementTypes.ComboAdd, {
                                            dataSource: '/api/driver/phonevaluelistempty/',
                                            addparams: {
                                                TitlePrefix: '',
                                                PopupConfig: driverConfig
                                            },
                                            parentValueType: 'carrierId',
                                            reloadOnParentChange: true,
                                            selectedValueType: 'driverName',
                                            editability: o => o && o['carrierId'] > 0
                                        }),
                                        Dependencies: [['driverPhone', 'driverPhone']]
                                    },
                                    driverPhone: {
                                        Title: 'Driver Phone',
                                        Field: ElementTypes.Phone(),
                                        Dependencies: ['driverId']
                                    }
                                }
                            }
                        ]
                    },
                    {
                        Sections: [
                            {
                                classes: 'extra',
                                Components: {
                                    attachments: {
                                        Component: ComponentTypes.Attachments('guid', {
                                            classes: 'compact',
                                            List: {
                                                Columns: {
                                                    guid: {
                                                        Title: 'Download',
                                                        Field: ElementTypes.Download('fileName'),
                                                        Width: 80,
                                                        Attributes: {
                                                            wrap: {
                                                                left: ''
                                                            }
                                                        }
                                                    },
                                                    fileName: {
                                                        Title: 'File Name',
                                                        Field: ElementTypes.TextField(),
                                                        Validations: [ElementValidations.Required()],
                                                        Width: 250,
                                                        Attributes: {
                                                            wrap: {
                                                                left: ''
                                                            }
                                                        }
                                                    },
                                                    originalFileName: {
                                                        Title: 'Original File Name',
                                                        Field: ElementTypes.TableLabelField(),
                                                        Width: 250,
                                                        Attributes: {
                                                            wrap: {
                                                                left: ''
                                                            }
                                                        }
                                                    },
                                                    size: {
                                                        Title: 'File Size',
                                                        Field: ElementTypes.Generic(ElementTypes.TableFileSizeLabelField),
                                                        Width: 80,
                                                        Attributes: {
                                                            wrap: {
                                                                left: ''
                                                            }
                                                        }
                                                    },
                                                    createDate: {
                                                        Title: 'Created Date',
                                                        Field: ElementTypes.ColumnDate((o, data) => o && data),
                                                        Width: 80,
                                                        Attributes: {
                                                            wrap: {
                                                                left: ''
                                                            }
                                                        }
                                                    },
                                                    fullName: {
                                                        Title: 'Created By',
                                                        Field: ElementTypes.TableLabelField(),
                                                        Width: 80,
                                                        Attributes: {
                                                            wrap: {
                                                                left: ''
                                                            }
                                                        }
                                                    },
                                                    external: {
                                                        Title: 'External',
                                                        Field: ElementTypes.ColumnBool(),
                                                        Width: 60,
                                                        Attributes: {
                                                            wrap: {
                                                                left: ''
                                                            }
                                                        }
                                                    }
                                                }
                                            },
                                            Popup: {
                                                Title: 'Attachment Comment',
                                                SectionGroups: [
                                                    {
                                                        Sections: [
                                                            {
                                                                Fields: {
                                                                    fileName: {
                                                                        Title: 'File Name',
                                                                        Field: ElementTypes.LabelField()
                                                                    },
                                                                    originalFileName: {
                                                                        Title: 'Original File Name',
                                                                        Field: ElementTypes.LabelField()
                                                                    },
                                                                    size: {
                                                                        Title: 'File Size',
                                                                        Field: ElementTypes.Generic(ElementTypes.FileSizeLabelField)
                                                                    },
                                                                    comments: {
                                                                        Title: 'File Comment',
                                                                        Field: ElementTypes.Comment(),
                                                                        Focus: true
                                                                    }
                                                                }
                                                            }
                                                        ]
                                                    }
                                                ],
                                                ToolBars: GenerateToolBars(['Save', 'Cancel'])
                                            }
                                        })
                                    }
                                }
                            }
                        ]
                    }
                ],
                ToolBars: GenerateToolBars(['Save', 'SaveClose', 'Cancel'])
            }
        }
    }
};

export default Appointment;