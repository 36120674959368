import React from 'react';

const GroupFilterField = props => (
    <div className="filter_block">
        {props.Title && <span className={'title'}><span>{props.Title}</span></span>}
        <div className="data_cell">
            <wrap block="">
                <label className="input_cont">
                    {props.children}
                </label>
            </wrap>
        </div>
    </div>
    );

export default GroupFilterField;