export const ERROR = 'ERROR';
export const SET_HEADER_WIDTH_LAST_ORDERS = 'SET_HEADER_WIDTH_LAST_ORDERS';
export const SET_IN_LOADING = 'SET_IN_LOADING';
export const RELOAD_ON_ERROR = 'RELOAD_ON_ERROR';
export const RESET_ERROR = 'RESET_ERROR';
export const SET_DISPLAY_STATE = 'SET_DISPLAY_STATE';
export const CUSTOMER_LAST_ORDERS_LOADING = 'CUSTOMER_LAST_ORDERS_LOADING';
export const GET_CUSTOMER_LAST_ORDERS = 'GET_CUSTOMER_LAST_ORDERS';
export const SORT_LAST_ORDERS = 'SORT_LAST_ORDERS';

export const getActionType = (recource, key) => {
    return CUSTOMER_ORDERS_BUILD[key]
};

export const CUSTOMER_ORDERS_BUILD = {
    RELOAD_ON_ERROR: 'RELOAD_ON_ERROR',
    ERROR: 'ERROR',
    SET_HEADER_WIDTH_LAST_ORDERS: 'SET_HEADER_WIDTH_LAST_ORDERS',
    RESET_ERROR: 'RESET_ERROR',
    GET_CUSTOMER_LAST_ORDERS: 'GET_CUSTOMER_LAST_ORDERS',
    CUSTOMER_LAST_ORDERS_LOADING: 'CUSTOMER_LAST_ORDERS_LOADING',
    SORT_LAST_ORDERS: 'SORT_LAST_ORDERS',
};
