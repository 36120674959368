import React from 'react';
import { connect } from "react-redux";
import { Resizable } from 'react-resizable';
import { setHeaderWidth } from '../../../../store/actions/customerRejectedOrders/customerRejectedOrders';

const HeaderCell = (
    {
        state,
        ind,
        setHeaderWidth,
        resource,
        trafficSubHeader,
    }) => {
    const traffic = state[resource];
    const data = trafficSubHeader ? traffic.subHeaders[ind] : traffic.headers[ind]
    let cellStyle = {
        maxWidth: `${data.width}px`,
        width: `${data.width}px`,
        minWidth: `${data.width}px`,
        backgroundColor: '#29CABA',
        color: '#FFFFFF',
    };

    const onResizeStart = (e, s) => {
        console.log('onResizeStart', e, s);
    };

    const onResize = s => {
        resource === 'customerRejectedOrders' && ind !== 13 && s.size.width < 300 && setHeaderWidth(resource, ind, s.size.width);
        console.log('OnResize', ind);
    };

    const onResizeStop = (e, s) => {
        console.log('onResizeStop', e, s);
    };

    let headerCall =
        <div
            className='table-header-cell table-header-cell-traffic rejected-order__header'
            style={cellStyle}>
            {data.title}
        </div>;

    const resizableHeaderCall = (
        <Resizable
            width={data.width}
            height={0}
            handle={
                <span
                    className="react-resizable-handle"
                    onClick={e => {
                        e.stopPropagation();
                    }}
                />
            }
            onResizeStart={(_, s) => onResizeStart(s)}
            onResize={(_, s) => onResize(s)}
            onResizeStop={(_, s) => onResizeStop(s)}
            draggableOpts={{ enableUserSelectHack: false }}
        >
            {headerCall}
        </Resizable>
    );

    return resizableHeaderCall;
};

const mapStateToProps = state => ({
    state: state,
    trafficUpcoming: state.trafficUpcoming,
});

const mapDispatchToProps = (dispatch) => ({
    setHeaderWidth: (resource, ind, width) => dispatch(setHeaderWidth(resource, ind, width)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderCell);