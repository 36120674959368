export const useElementChange = (validate, onChange) => {
    const fieldChangeHandler = event => {
        let data = event.target.value;
        if (event.target.type === 'checkbox') {
            data = event.target.checked;
        }
        if (event.target.type === 'radio' && !event.target.field) {
            data = event.target.checked;
        }
        if (event.target.type === 'multiple_select') {
            data = event.target.value ? event.target.value.join(',') : '';
        }
        if (event.target.type === 'number') {
            if (event.target.value === '') {
                data = null;
            }
            else {
                data = +event.target.value;
            }
        }
        onChange(event, data, event.target.field);
        validate(data);
    };
    return fieldChangeHandler;
};