import React from 'react';

import Loading from '../loading';
import CommonFormView from './commonFormView';
import LoginInfo from '../info/loginInfo';

const LoginFormView = props => (
    <CommonFormView>
        <LoginInfo title={props.title} />
        {props.isLoading ? <Loading /> : props.children}
    </CommonFormView>
);

export default LoginFormView;