import React from 'react';
import HeaderCell from './headerCell';
import { CustomerPriceSelectors } from '../../../../store/selectors/customerPriceSelectors';

const HeaderRow = ({ resource }) => {
    const { state } = CustomerPriceSelectors();
    const loadArea = state[resource];
    
    return (
        <div className='resp-table-header'
            style={{ height: '32px', fontSize: '12px' }}>
            {loadArea.headers.map((_, ind) => {
                return <HeaderCell key={ind} ind={ind} resource={resource} />
            })}
        </div>
    );
};

export default HeaderRow;