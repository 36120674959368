import React from 'react';

import Sheet from '../components/custom/sheet/sheet';
import { CloneObject } from '../helper/common';
import { ElementTypes } from '../config/element.config';

const DragableTable = () => {
    const [data, setData] = React.useState([
        {
            id: 1,
            column1: 'column11',
            column2: 'column21',
            column3: 'column31'
        },
        {
            id: 2,
            column1: 'column12',
            column2: 'column22',
            column3: 'column32'
        },
        {
            id: 3,
            column1: 'column13',
            column2: 'column23',
            column3: 'column33'
        },
        {
            id: 4,
            column1: 'column14',
            column2: 'column24',
            column3: 'column34'
        },
        {
            id: 5,
            column1: 'column15',
            column2: 'column25',
            column3: 'column35'
        }
    ]);

    const list = {
        Columns: {
            column1: {
                Title: 'Column 1',
                Field: ElementTypes.TableLabelField()
            },
            column2: {
                Title: 'Column 2',
                Field: ElementTypes.TableLabelField()
            },
            column3: {
                Title: 'Column 3',
                Field: ElementTypes.TableLabelField()
            }
        }
    };


    const moveRow = (dragIndex, hoverIndex) => {
        const dragRecord = data[dragIndex];
        const newData = CloneObject(data);
        newData.splice(dragIndex, 1);
        newData.splice(hoverIndex, 0, dragRecord);
        setData(newData);
    };

    return (<>
        <h1>Dragable Table</h1>
        <Sheet
            messageIdentityPrefix='development'
            keyFieldName='id'
            columns={list.Columns}
            data={data}
            onMoveRow={moveRow}
        />
    </>
    );
};

export default DragableTable;