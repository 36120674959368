export const getActionArgument = (list, value, mode, nativeEvent) => {
    return {
        nativeEvent: nativeEvent,
        source: list,
        target: {
            value: value,
            type: mode === 'multiple' ? 'multiple_select' : 'select'
        }
    };
};

