import React from 'react';
import { Link } from 'react-router-dom';

import Loading from '../loading';

import HideShowMenuSwitch from '../../layout/hideShowMenuSwitch';

const FormView = props =>
    <view>
        <toolbar>
            {props.backUrl
                ? <>
                    <div effect='material' className='button white mini back'>
                        <Link to={{ pathname: props.backUrl, search: props.search }} onClick={props.handleBack}><icon>left</icon></Link>
                    </div>
                    <separator vertical=""></separator>
                </>
                : null
            }
            <div>
                <strong><text>{props.title || ''}</text></strong>
            </div>
            { !props.isHiddenSwitch && <HideShowMenuSwitch /> }
        </toolbar>
        {props.isLoading ? <Loading /> : props.children}
    </view>

export default FormView;