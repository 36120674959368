import axios from 'axios';
import store from '../..';
import * as actionTypes from './revisionOrderAdminActionTypes';

const buildUrl = (resource) => `/api/${resource}`;
const buildPutUrl = (resource, endPoint) => `/api/${resource}/${endPoint}`;

const axiosConfig = () => ({
  headers: {
    Authorization: 'Bearer ' + store.getState().auth.userToken,
  },
});

export const getRevisionOrdersAdmin = (resource, loadingType) => dispatch => {
  if (loadingType !== 'withoutLoading') {
    dispatch({ type: actionTypes.getActionType(resource, actionTypes.GET_REVISION_ORDER_ADMIN_LOADING) });
  }
  axios
    .get(buildUrl(resource), axiosConfig())
    .then(responce => dispatch({ type: actionTypes.getActionType(resource, actionTypes.GET_REVISION_ORDER_ADMIN), data: responce }))
    .catch(err => {
      console.error(err);
      console.log(err.response);
      dispatch({ type: actionTypes.getActionType(resource, actionTypes.ERROR) });
    });
};

export const acceptRevision = (resource, endPoint, data) => dispatch => {
  dispatch({ type: actionTypes.getActionType(resource, actionTypes.ACCEPT_REVISION_ORDER_LOADING) });
  axios
    .put(buildPutUrl(resource, endPoint), data, axiosConfig())
    .then(responce => dispatch({ type: actionTypes.getActionType(resource, actionTypes.ACCEPT_REVISION_ORDER), data: responce }))
    .catch(err => {
      console.error(err);
      console.log(err.response);
      dispatch({ type: actionTypes.getActionType(resource, actionTypes.ERROR), data: err });
    });
};

export const rejectRevision = (resource, endPoint, data) => dispatch => {
  dispatch({ type: actionTypes.getActionType(resource, actionTypes.REJECT_REVISION_ORDER_LOADING) });
  axios
    .put(buildPutUrl(resource, endPoint), data, axiosConfig())
    .then(responce => dispatch({ type: actionTypes.getActionType(resource, actionTypes.REJECT_REVISION_ORDER), data: responce }))
    .catch(err => {
      console.error(err);
      console.log(err.response);
      dispatch({ type: actionTypes.getActionType(resource, actionTypes.ERROR), data: err });
    });
};

export const setHeaderWidth = (resource, ind, width) => ({
  type: actionTypes.getActionType(resource, actionTypes.SET_HEADER_WIDTH_ADMIN_REVISION),
  data: { ind, width },
});

export const setItemDisplayState = (resource, rowState, header, enable) => ({
  type: actionTypes.getActionType(resource, actionTypes.SET_DISPLAY_STATE),
  data: {
    rowState,
    header,
    enable,
  },
});

export const resetError = resource => ({ type: actionTypes.getActionType(resource, actionTypes.RESET_ERROR) });
