import React from 'react';
import { connect } from "react-redux";
import HeaderCell from './headerCell'

const HeaderRow = ({state, resource }) => {
    const loadArea = state[resource];
    return (
        <div className='resp-table-header'
            style={{ height: '32px', fontSize: '12px' }}>
            {loadArea.headers.map((_, ind) => {
                return <HeaderCell key={ind} ind={ind} resource={resource} />
            })}
        </div>
    );
};

export default connect(state => ({
    state: state
}))(HeaderRow);