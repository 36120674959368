import React from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from "react-redux";
import HeaderCell from './headerCell'

const HeaderRow = (
    {
        state,
        resource
    }) => {
    const loadArea = state[resource];
    const location = useLocation();
    return (
        <div className='resp-table-header' style={{ height: '32px'}}>
            {loadArea.headers.map((header, ind) => {
                if (location.pathname.split('/')[1] === 'booking')
                {
                    return <HeaderCell key={ind} ind={ind} resource={resource} headerKey={header.key} />
                }
                else if (!header.hideForLoadPlanning === true && location.pathname.split('/')[1] === 'loadplanning')
                {
                    return <HeaderCell key={ind} ind={ind} resource={resource} headerKey={header.key} />
                }
                else 
                    return null;
            })}
        </div>
    );
};

export default connect(state => ({
    state: state
}))(HeaderRow);