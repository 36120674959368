  import React from 'react';
  import List from '../../../list/list';
  
  const AdminOrderBuild = props => {
    return (
      <List screen={props} download columnConfig />
    )
  };
  
  export default AdminOrderBuild;

