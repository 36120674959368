import React from 'react';

import SheetRow from './sheetRow';

import { createSelectable } from '../reactSelectable';
import { ArrayGroup } from '../../../helper/common';

const SelectableComponent = createSelectable(SheetRow);

const SheetBody = props => {
    const Component = props.selectable ? SelectableComponent : SheetRow;
   
    let rows = [];
    const createRowsData = (items, pId, isChild, parentKeyField) => {
        if (!items || !Array.isArray(items)) {
            return;
        }

        const groupBy = props.groupBy;
        const groupType = props.groupType;
        const groupTypeValue = props.groupTypeValue;
        const subGroup = props.subGroup;

        let groupIndex = '';
        let groupChanged = false;

        items = items.filter((item) => !item.deleted);
        const groupedItems = [];
        if(subGroup) {
            const subGroupData = ArrayGroup(items, subGroup);
            let subGroupValue = '';
            subGroupData.forEach(groupItem => {
                groupItem.items.map(i => {
                    if(i[subGroup] !== subGroupValue) {
                        i.subGroupChanged = true;
                    } else {
                        i.subGroupChanged = false;
                    }
                    subGroupValue = i[subGroup];

                    return i
                });

                groupedItems.push(...groupItem.items);
            });

            items = groupedItems;
        }

        items.forEach((item, index, arr) => {
            if (props.grouping && groupBy) {
                if (item[groupType] === groupTypeValue) {
                    groupChanged = groupIndex !== item[groupBy];
                    if (groupChanged) {
                        groupIndex = item[groupBy];
                    }
                } else if (arr[index - 1]?.[groupType] === groupTypeValue) {
                    groupChanged = groupIndex !== item[groupBy];
                    if (groupChanged) {
                        groupIndex = item[groupBy];
                    }
                } else {
                    groupChanged = false;
                }
            }

            rows.push({
                key: item[props.keyFieldName],
                index: pId !== 0 ? `${pId}_${index}` : index,
                keyFieldName: props.keyFieldName,
                keyField: item[props.keyFieldName],
                parentKeyField: parentKeyField,
                columns: props.columns,
                isChild: isChild,
                isParent: !isChild,
                data: item,
                object: props.data,
                onChange: props.onChange,
                messageIdentityPrefix: props.messageIdentityPrefix == null ? `${index}` : `${props.messageIdentityPrefix}_${index}`,
                messageDispatch: props.messageDispatch,
                messages: props.messages,
                moveRow: props.moveRow,
                action: props.action,
                onActionRecord: props.onActionRecord,
                minRowsCanDelete: props.minRowsCanDelete,
                groupChanged: groupChanged,
                subGroupChanged: item.subGroupChanged
            });

            if (item.items) {
                createRowsData(item.items, index, true, item[props.keyFieldName]);
            }
        });
    };
    createRowsData(props.data, 0, false, null);
    return (
        <tbody>
            {
                rows.map((item, i) => {
                    return <Component
                        key={item.key}
                        index={item.index}
                        keyFieldName={item.keyFieldName}
                        keyField={item.keyField}
                        parentKeyField={item.parentKeyField}
                        columns={item.columns}
                        profileColumns={props.profileColumns}
                        isChild={item.isChild}
                        isParent={item.isParent}
                        data={item.data}
                        object={item.object}
                        parentData={props.parentData}
                        onChange={item.onChange}
                        messageIdentityPrefix={item.messageIdentityPrefix}
                        messageDispatch={item.messageDispatch}
                        messages={item.messages}
                        moveRow={item.moveRow}
                        action={item.action}
                        actions={props.actions}
                        onActionRecord={item.onActionRecord}
                        minRowsCanDelete={item.minRowsCanDelete}
                        draggable={props.draggable}
                        handleCheck={props.handleCheck}
                        selectable={props.selectable}
                        selectedRows={props.selectedRows}
                        colgroup={props.colgroup}
                        selectableKey={item.data}
                        resizable={props.resizable}
                        resizeMode={props.resizeMode}
                        tableWidth={props.tableWidth}
                        resource={props.resource}
                        disableDrag={props.disableDrag}
                        providedPlaceholder={props.providedPlaceholder}
                        coloring={props.coloring}
                        grouping={props.grouping}
                        groupBy={props.groupBy}
                        groupType={props.groupType}
                        groupTypeValue={props.groupTypeValue}
                        groupChanged={item.groupChanged}
                        subGroup={props.subGroup}
                        subGroupChanged={item.subGroupChanged}
                        addIndex={props.addIndex}
                    />
                })
            }
            {props.providedPlaceholder}
        </tbody>
    );
};

export default SheetBody;