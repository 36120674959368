import React, { useState, useEffect, useCallback, useRef } from "react";
import Split from "react-split-it";
import { connect } from "react-redux";
import { CSVLink } from "react-csv";
import { Tooltip } from "antd";
import { useHistory, useLocation } from "react-router-dom";

import HideShowMenuSwitch from '../../layout/hideShowMenuSwitch';

import axios from "../../../axios/axios";

import { openPopup, closePopup } from "../../../../store/actions/popup/popup";

import { useMessage } from "../../../hook/data/useMessage";
import { DefaultDataTypes } from "../../../hook/actions";
import errorHandler from '../../../error/errorHandler';
import { getListCriteria, getCriteriaQuery } from "../../../helper/list";

import Filter from "../../list/filter/filter";
import Expandable from "../../custom/table/expandable";
import Loading from "../../screen/loading";
import Scroll from "../../scroll/scroll";
import Settings from "../../list/settings";
import { useOrderData } from './useOrderData';

import ReceivingOrders from "./receivingOrders";
import OrderForm from "../../../screens/ReceivingLoad/orderConfig";
import { saveScreenSwap } from '../../../../store/actions/authentication/authentication';

export const GridContext = React.createContext();
const mainResource = "LoadOrders";
const ReceivingLoad = (props) => {
    const truckConfig = props.Pages.ReceivingLoad;
    const truckMessageIdentityPrefix = "";

    //Load
    const [isLoading, setLoading] = useState(true);
    const truckComponentRefs = useRef({});
    const [truckMessages, truckMessageDispatch] = useMessage(props.Pages.ReceivingLoad);
    const [reloadOrder, setReloadOrder] = useState(1);
    const [reloadTruck, setReloadTruck] = useState(1);

    const location = useLocation();
    const history = useHistory();
    const criteriaQuery = getCriteriaQuery(history);
    const criteria = useCallback(() => getListCriteria(criteriaQuery), [criteriaQuery]);
    const [data, dispatchData, truckData, truckDispatch] = useOrderData();

    const setSwap = swap => {
        props.saveScreenSwap(swap);
    };

    const loadReceivingOrderData = () => {
        let r = reloadOrder;
        setReloadOrder(++r);
    };

    const loadTruckData = () => {
        let r = reloadTruck;
        setReloadTruck(++r);
    };


    const swapContainers = () => {
        const firstContainer = <container horizontal="" s_space="" key={'c_1'}>
            {isLoading || !truckData ? (
                <Loading />
            ) : (
                    <Expandable
                        ref={(ref) => {
                            truckComponentRefs.current[
                                truckMessageIdentityPrefix == null
                                    ? `ReceivingLoad`
                                    : `${truckMessageIdentityPrefix}_ReceivingLoad`
                            ] = ref;
                        }}
                        resource={"LoadOrders"}
                        messageIdentityPrefix={truckMessageIdentityPrefix}
                        config={truckConfig}
                        keyFieldName="id"
                        type={DefaultDataTypes.INIT}
                        messages={truckMessages}
                        messageDispatch={truckMessageDispatch}
                        data={truckData}
                        dispatch={truckDispatch}
                        object={truckData}
                        OrderForm={OrderForm}
                        OrderConfig={props.Pages.Order}
                        dispatchDropData={dispatchData}
                        dragDropData={data}
                    />
                )}
        </container>;

        const secondContainer = <container horizontal="" s_space="" key={'c_2'}>
            <ReceivingOrders
                {...props.Pages.Order}
                resource="ReceivingOrders"
                data={data}
                dispatchData={dispatchData}
                reload={reloadOrder}
                OrderForm={OrderForm}
            />
        </container>;

        return props.swap ? [secondContainer, firstContainer] : [firstContainer, secondContainer]

    };

    useEffect(() => {
        let isMounted = true;
        const fetchData = async (isMounted) => {
            if (isMounted) {
                setLoading(true);
            }
            const response = await axios(
                "ReceivingLoad",
                {
                    params: {
                        request: {},
                        filterData: JSON.stringify(criteria().filter)
                    }
                }
            ).finally(() => {
                if (isMounted) {
                    setLoading(false);
                }
            });
            if (isMounted) {
                if (response) {
                    const result = response.data;
                    if (result) {
                        const data = result.data;
                        for (var i = 0; i < data.length; i++) {
                            if (!data[i].key) {
                                data[i].key = data[i][props.Pages.ReceivingLoad.Key || "id"];
                            }
                        }
                        truckDispatch({ type: DefaultDataTypes.INIT, data: data });
                        setLoading(false);
                    }
                }
            }
        };
        setLoading(true);
        fetchData(isMounted);
        return () => {
            isMounted = false;
        };
    }, [criteria, props.Pages.ReceivingLoad.Key, truckDispatch, reloadTruck, location]);

    return <>
        <toolbar scroll="">
            <Scroll>
                <wrap>
                    <action>
                        <wrap>
                            <Settings screen={mainResource} columns={props.Pages.Order.List.Columns} >
                                <text>Load Order</text>
                            </Settings>
                        </wrap>
                        <separator vertical=""></separator>
                        <wrap>
                            <Settings screen="ReceivingOrders" columns={props.Pages.Order.List.Columns}>
                                <text>Receiving Orders</text>
                            </Settings>
                        </wrap>
                        <separator vertical=""></separator>
                        {props.Pages.Order.List.ShowRefreshButton ? (
                            <>
                                <Tooltip title="Swap">
                                    <div
                                        className="button accent"
                                        onClick={() => setSwap(!props.swap)}
                                    >
                                        <text>Swap Tables</text>
                                    </div>
                                </Tooltip>
                                <Tooltip title="Refresh Orders">
                                    <div
                                        className="button accent"
                                        onClick={loadReceivingOrderData}
                                    >
                                        <text>Refresh Orders</text>
                                    </div>
                                </Tooltip>
                                <Tooltip title="Refresh Loads">
                                    <div
                                        className="button accent"
                                        onClick={loadTruckData}
                                    >
                                        <text>Refresh Loads</text>
                                    </div>
                                </Tooltip>
                            </>
                        ) : null}
                        <Download
                            screen={truckConfig.Resource}
                            columns={truckConfig.List.Columns}
                            filter={criteria().filter}
                        />
                    </action>
                </wrap>
            </Scroll>
            { !props.isHiddenSwitch && <HideShowMenuSwitch /> }
        </toolbar>
        <view vertical="">
            {props.Pages.ReceivingLoad.List.Filter ? (
                <Filter filtersConfig={props.Pages.ReceivingLoad.List.Filter} />
            ) : null}
            <view>
                <Split sizes={[0.7, 0.3]} direction="vertical" gutterSize={3}>
                    {swapContainers()}
                </Split>
            </view>
        </view>
    </>
};

const Download = (props) => {
    const [dataSource, setDataSource] = useState([]);
    const [header, setHeader] = useState([]);
    const headerArr = [];
    const csvElRef = useRef(null);

    const downloadGrid = () => {
        axios.get(props.screen).then((result) => {
            setDataSource(result.data.data);

            for (let label in props.columns) {
                headerArr.push({ label: props.columns[label].Title, key: label });
            }

            setHeader(headerArr);
            csvElRef.current.link.click();
        });
    };

    return (
        <>
            <Tooltip title="Download Truck">
                <div className="button accent" onClick={downloadGrid}>
                    <text>Download Truck</text>
                </div>
            </Tooltip>

            <CSVLink
                headers={header}
                data={dataSource}
                filename={`${props.screen}.csv`}
                ref={csvElRef}
            />
        </>
    );
};

const mapStateToProps = state => {
    return {
        swap: state.auth.profileSettings && state.auth.profileSettings.screens
            && state.auth.profileSettings.screens[mainResource] ? state.auth.profileSettings.screens[mainResource].swap : false
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        openPopup: (data) => dispatch(openPopup(data)),
        closePopup: (key) => dispatch(closePopup(key)),
        openAlert: (key) => dispatch(openPopup(key)),
        saveScreenSwap: (swap) => dispatch(saveScreenSwap(mainResource, swap))

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(errorHandler(ReceivingLoad, axios));
