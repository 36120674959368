export const LOGIN = 'USERLOGIN';
export const REFRESH = 'USERREFRESH';
export const PROFILE = 'PROFILE';
export const LOGOUT = 'USERLOGOUT';
export const AUTH_START = 'AUTH_START';
export const AUTH_FAIL = 'AUTH_FAIL';
export const LIST_COLUMN_VISIBILITY = 'LIST_COLUMN_VISIBILITY';
export const LIST_COLUMN_VISIBILITY_AND_INDEX = 'LIST_COLUMN_VISIBILITY_AND_INDEX';
export const LIST_FILTER_VISIBILITY_AND_INDEX = 'LIST_FILTER_VISIBILITY_AND_INDEX';
export const LIST_COLUMN_WIDTH_TEMP = 'LIST_COLUMN_WIDTH_TEMP';
export const LIST_COLUMN_WIDTH_FROM_TEMP = 'LIST_COLUMN_WIDTH_FROM_TEMP';
export const LIST_COLUMN_WIDTH_UPDATE_TEMP = 'LIST_COLUMN_WIDTH_UPDATE_TEMP';
export const LIST_COLUMN_WIDTH = 'LIST_COLUMN_WIDTH';
export const SCREEN_SWAP = 'SCREEN_SWAP';