export const useElementKeyPress = (action, type) => {
    const fieldChangeHandler = event => {
        if (action) {
            action({
                nativeEvent: event,
                elementType: type 
            });
        }
    };
    return fieldChangeHandler;
};