export const ERROR = 'ERROR';
export const SET_HEADER_WIDTH_NEW_ORDERS = 'SET_HEADER_WIDTH_NEW_ORDERS';
export const SET_IN_LOADING = 'SET_IN_LOADING';
export const RELOAD_ON_ERROR = 'RELOAD_ON_ERROR';
export const RESET_ERROR = 'RESET_ERROR';
export const SET_DISPLAY_STATE = 'SET_DISPLAY_STATE';
export const GET_CUSTOMER_ORDERS_NEW_ORDERS = 'GET_CUSTOMER_ORDERS_NEW_ORDERS';
export const CUSTOMER_ORDERS_LOADING = 'CUSTOMER_ORDERS_LOADING'; 
export const SORT_ORDERS_NEW_ORDERS = 'SORT_ORDERS_NEW_ORDERS';
export const DELETE_CUSTOMER_ORDER_LOADING = 'DELETE_CUSTOMER_ORDER_LOADING';
export const DELETE_CUSTOMER_ORDER = 'DELETE_CUSTOMER_ORDER';
export const ADD_CUSTOMER_ORDER_LOADING = 'ADD_CUSTOMER_ORDER_LOADING';
export const ADD_CUSTOMER_ORDER = 'ADD_CUSTOMER_ORDER';
export const EDIT_CUSTOMER_ORDER_LOADING = 'EDIT_CUSTOMER_ORDER_LOADING';
export const EDIT_CUSTOMER_ORDER = 'EDIT_CUSTOMER_ORDER';
export const SET_FORM_VALUES = 'SET_FORM_VALUES';
export const RESET_ORDER_ADDED = 'RESET_ORDER_ADDED';

export const getActionType = (recource, key) => {
    return CUSTOMER_ORDERS_BUILD[key]
};

export const CUSTOMER_ORDERS_BUILD = {
    RELOAD_ON_ERROR: 'RELOAD_ON_ERROR',
    ERROR: 'ERROR',
    SET_HEADER_WIDTH_NEW_ORDERS: 'SET_HEADER_WIDTH_NEW_ORDERS',
    RESET_ERROR: 'RESET_ERROR',
    GET_CUSTOMER_ORDERS_NEW_ORDERS: 'GET_CUSTOMER_ORDERS_NEW_ORDERS',
    CUSTOMER_ORDERS_LOADING: 'CUSTOMER_ORDERS_LOADING',
    SORT_ORDERS_NEW_ORDERS: 'SORT_ORDERS_NEW_ORDERS',
    DELETE_CUSTOMER_ORDER_LOADING: 'DELETE_CUSTOMER_ORDER_LOADING',
    DELETE_CUSTOMER_ORDER: 'DELETE_CUSTOMER_ORDER',
    ADD_CUSTOMER_ORDER_LOADING: 'ADD_CUSTOMER_ORDER_LOADING',
    ADD_CUSTOMER_ORDER: 'ADD_CUSTOMER_ORDER',
    EDIT_CUSTOMER_ORDER_LOADING: 'EDIT_CUSTOMER_ORDER_LOADING',
    EDIT_CUSTOMER_ORDER: 'EDIT_CUSTOMER_ORDER',
    SET_FORM_VALUES: 'SET_FORM_VALUES',
    RESET_ORDER_ADDED: 'RESET_ORDER_ADDED',
};
