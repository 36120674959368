import './theme.less';
import './ant_reset.css'; // variables to override above
import './picker_reset.css'; // variables to override above

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from "react-redux";
import store from './store';
import { BrowserRouter } from 'react-router-dom';




import App from './App';
import registerServiceWorker, { unregister } from './registerServiceWorker';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

/*eslint no-extend-native: ["error", { "exceptions": ["Object"] }]*/
var _toString = Object.prototype.toString;
// toString override added to prototype of Foo class
Object.prototype.toString = function () {
    try {
        switch (this.tagName) {
            case "LOGIN":
            case "CONT":
            case "MESSAGE":
            case "VIEW":
            case "ICON":
            case "SEPARATOR":
            case "LOGO":
            case "INFO":
            case "CONTAINER":
            case "HORIZONAL_WRAP":
            case "CONTENT_WRAP":
            case "TEXT":
            case "CHECK":
            case "BOX":
            case "WRAP":
            case "LIST":
            case "ITEM":
            case "OVERLAY":
            case "GRID":
            case "SCROLLER":
            case "BLOCK":
            case "SPACE":
            case "PICTURE":
            case "DEMOSPACE":
            case "NAME":
            case "BADGE":
            case "GROUP":
            case "DASHBOARD":
            case "SWITCH":
            case "SWITCHABLE":
            case "PLAYER":
            case "PLAYLIST":
            case "MEDIA":
            case "CIRCLE":
            case "LINE":
            case "ROW":
            case "INFOBAR":
            case "THUMB":
            case "DETAIL":
            case "COVER":
            case "CONTROLS":
            case "DECOR":
            case "DOT":
            case "GAP":
            case "PIE":
            case "TIP":
            case "COL":
            case "COUNT":
            case "TEST":
            case "ZONE":
            case "LAYOUT":
            case "TAG":
            case "ACTION":
            case "ELASTIC":
            case "TOOLBAR":
            case "PANEL":
            case "WRAPPER":
            case "DEMO_WRAP":
            case "APPLICATION":
            case "THUMBS":
            case "POINT":
            case "HOLDER":
            case "COLORPICKER":
            case "ALERT":
                return "[object div]";
            default:
                break;
        }
    } catch{ }
    return _toString.call(this);
}
unregister();

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter basename={baseUrl}>
            <input type="hidden" id="api_url" value={process.env.REACT_APP_API_URL} />
            <App />
        </BrowserRouter>
    </Provider>, rootElement);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
registerServiceWorker();

