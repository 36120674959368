import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { logout } from '../../store/actions/authentication/authentication';
import { AppPages } from '../../project/Pages';

const Logout = props => {
    useEffect(() => {
        props.doLogout();
    });
    return <Redirect to={AppPages.Login} />;
};

const mapDispatchToProps = dispatch => {
    return {
        doLogout: () => dispatch(logout())
    };
};

export default connect(null, mapDispatchToProps)(Logout);