/**
 * the formats for different types of data
 */

export const CommonLabels = {
    Messages: {
        ConfirmDeleteTitle: (title, master) => `Delete ${title} ${master || ''}`, 
        ConfirmDeleteText: 'This action cannot be undone. Are you sure you want to continue?',
        ApiError: 'API Error',
        UnsupportedMediaType: 'Unsupported Media Type'
    },
    Text: {
        Title: {
            Actions: 'Actions'
        },
        Filters: 'Filters',
        From: 'From',
        To: 'To',
        Upload: 'Upload',
        ClearAll: 'Clear All',
        Apply: 'Apply',
        Empty: 'Empty',
        Refresh: 'Refresh'
    },
    Buttons: {
        Refresh: 'Refresh',
        Save: 'Save',
        Add: 'Add',
        Edit: 'Edit',
        Delete: 'Delete',
        Cancel: 'Cancel',
        Download: 'Download',
        Import: 'Import',
        Export: 'Export',
        Yes: 'Yes',
        No: 'No',
        ClearAll: 'Clear All',
        Apply: 'Apply',
        Close: 'Close',
        Confirm: 'Confirm',
        CreateGroup: 'Create Group',
    }
};

export const Defines = {
    Format: {
        MomentDate: "YYYY/MM/DD",
        //MomentDate: "dddd, MMMM Do YYYY",
        MomentDateTime: "YYYY/MM/DD HH:mm",
        MomentTime: "HH:mm",
        MomentFullTime: "HH:mm:ss",

        PrintDateLong: "MM/DD/YYYY", //"MM/dd/yyyy",
        PrintDate: "MM/DD/YY", //"MM/dd/yyyy",
        PrintDateTime: "MM/DD/YY HH:mm",
        FileNameDate: "yyyyMMdd",
        FileNameDateTime: "yyyy_MM_dd_HH-mm",

        ExpirationDate: "MMddyy",

        InputDate: "MM/DD/yyyy",
        InputTime: "h:mm A",

        Date: "MM/DD/YY", 
        Time: "HH:mm",

        MonthDate: "MMMM, yyyy",

        Number0: "n0"
    },
    Messages: {
        confirmDelete: "Delete",
        cancelDelete: "Cancel",
        Yes: "Yes",
        No: "No",
    },
    DetailsComponentSufix: 'Details',
    DetailsPathSufix: 'details/:id',
    DefaultActionKey: 'action',
    DefaultCreateKey: 'create',
    DefaultCreateKeyClone: 'clone'
};

/**
 * basic user roles for proejctthe url endings for each action of api
 */
export const UserRoles = {
    WAREHOUSE: "Warehouse",
    OPERATION: "Operation",
    ADMIN: "Admin",
    PUBLIC: "Public"
}

/**
 * basic Schedule Types for the proejct services
 */
export const ScheduleType = {
    SECOND: 'second',
    MINUTE: 'minute',
    HOUR: 'hour',
    DAILY: 'day',
    WEEKLY: 'week',
    MONTHLY: 'month',
    YEARLY: 'year'
}

export const UniversConfig = {
    sauter:{
      name: "sauter",
      color: "#75af4a"
    },
    therpac:{
      name: "therpac",
      color: "grey",
    },
    default:{
      name: "sauter",
      color: "#75af4a"
    },
};

/*eslint no-extend-native: ["error", { "exceptions": ["Date"] }]*/

Date.prototype.getWeek = function () {
    var date = new Date(this.getTime());
    date.setHours(0, 0, 0, 0);
    // Thursday in current week decides the year.
    date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
    // January 4 is always in week 1.
    var week1 = new Date(date.getFullYear(), 0, 4);
    // Adjust to Thursday in week 1 and count number of weeks from date to week1.
    return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000
        - 3 + (week1.getDay() + 6) % 7) / 7);
};

// Returns the four-digit year corresponding to the ISO week of the date.
Date.prototype.getWeekYear = function () {
    var date = new Date(this.getTime());
    date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
    return date.getFullYear();
};

export const bytesToSize = bytes => {
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return '0 Byte';
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
}