import React from 'react';
import { connect } from "react-redux";
import Cell from './cell';

const Row = ({ data, style, itemSize, resource, setCheckedList, checked }) => {
    const color = 'white';
    style.width = 'max-content';
    const rowStyle = { ...style, ...{ height: `${itemSize}px`, backgroundColor: color } };
    
    const cellContent = (i, ind) => {
        return (<Cell
            key={ind}
            cellData={i}
            itemSize={itemSize}
            resource={resource}
            checked={checked}
            setCheckedList={setCheckedList}
            rowState={data.rowState}
        />);
    };

    return data ?
        <div className='resp-table-row' style={{ ...rowStyle, display:'flex' }}>
            {data.row.map(cellContent)}
        </div> : null;
    };

export default connect(state => ({
    state: state
}), dispatch => ({
}))(Row);