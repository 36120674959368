import React, { useEffect, useRef, useCallback, useMemo } from 'react';
import { useDispatch } from "react-redux";
import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeList } from 'react-window';
import { message } from 'antd';
import { FactoringCompanySelectors } from '../../../../store/selectors/factoringCompanySelectors';
import './index.css'
import HeaderRow from './headerRow';
import Row from './row';
import Loading from "../../screen/loading";
import { getFactoringCompanyList, resetError, sortBy } from '../../../../store/actions/factoringCompany/factoringCompany';
import { getContentWidth } from '../../../helper/common';

const FactoringCompany = ({ resource, filteredFactoringData }) => {
    const dispatch = useDispatch();
    const { state, loading, messageText, hasError } = FactoringCompanySelectors();
    const defRowHeight = 20;
    const scrollSmall = 18;
    const factoringCompanyRef = useRef(null);
    const listReference = useRef(null);
    const listHeaderReference = useRef(null);
    const factoringCompanyHeaderReference = useRef(null);
    const mainScroll = useRef(null);
    const headers = state[resource].headers;
    const width = getContentWidth(headers);

    const onScrollX = e => {
        if (listHeaderReference.current && listReference.current && e.target) {
            listHeaderReference.current.scrollLeft =
            listReference.current.scrollLeft =
            e.target.scrollLeft;
        }
        if (factoringCompanyHeaderReference.current && listReference.current && e.target) {
            factoringCompanyHeaderReference.current.scrollLeft =
            listReference.current.scrollLeft =
            e.target.scrollLeft;
        }
    }

    useEffect(() => {
        dispatch(getFactoringCompanyList(resource));
    }, [dispatch, resource]);

    useEffect(() => {
        dispatch(sortBy(resource, 'asc'))
    }, [dispatch, resource]);
    
    useEffect(() => {
        if (hasError) {
            message.error(messageText, 3);
            setTimeout(() => dispatch(resetError(resource), 3000));
        }
    }, [hasError, resource, messageText, dispatch]);

    const header = useMemo(() => <div ref={listHeaderReference} className='factoring__header-wrapper'>
        <HeaderRow resource={resource} />
    </div>, [resource]);

    const getFactoringCompanyListItemSize = useCallback(
        index => filteredFactoringData?.[index] ? filteredFactoringData[index].rowState.height : defRowHeight,
        [filteredFactoringData]);

    const RenderFactoryCompanyListRow = useCallback(({ data, index, style }) => (
        (index !== undefined && data[index])
            ? <Row
                data={data[index]}
                itemSize={getFactoringCompanyListItemSize(index)}
                style={style}
                resource={resource}
            />
            : null
    ), [getFactoringCompanyListItemSize, resource]);

    const allFactoringCompanyList = useMemo(() => filteredFactoringData &&
    <AutoSizer disableWidth>
        {({ height }) => (
            <VariableSizeList
                ref={factoringCompanyRef}
                outerRef={el => { listReference.current = el }}
                style={{ overflowX: 'hidden', overflowY: 'scroll', borderRight: '1px solid #F0F0F0' }}
                itemData={filteredFactoringData}
                itemCount={filteredFactoringData.length}
                height={filteredFactoringData && (filteredFactoringData.length * defRowHeight) > height ? height: filteredFactoringData.length * defRowHeight}
                itemSize={getFactoringCompanyListItemSize}>
                {RenderFactoryCompanyListRow}
            </VariableSizeList>)}
    </AutoSizer>
    , [filteredFactoringData, getFactoringCompanyListItemSize, RenderFactoryCompanyListRow]);

    const content = (<>
        <div style={{ display: 'flex' }}>
            {header}
            <div style={{
                width: `${scrollSmall}px`,
                maxWidth: `${scrollSmall}px`,
                minWidth: `${scrollSmall}px`,
                height: '20px',
                backgroundColor: 'lightgrey',
            }} />
        </div>
        <div className='factoring__main-list'>{allFactoringCompanyList}</div>
        <div style={{
            width: '100%',
            height: `${18}px`,
            overflow: 'scroll',
            flexShrink: 0,
        }}
            ref={mainScroll}
            onScroll={e => onScrollX(e)}>
            <div className='resp-table-row'>
                <div
                    className='table-body-cell'
                    style={{
                        maxWidth: `${width}px`,
                        width: `${width}px`,
                        minWidth: `${width}px`,
                    }} />
            </div>
        </div>
    </>);

    return <>{loading ? <Loading /> : <>{content}</>}</>;
};

export default (FactoringCompany);