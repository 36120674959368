import axios from 'axios';
import store from '../..';
import * as actionTypes from './trafficControlUpcomingActionTypes';

const buildUrl = (resource = 'traffic', endPoint) => `/api/traffic/${endPoint}`;

const axiosConfig = () => ({
  headers: {
    Authorization: 'Bearer ' + store.getState().auth.userToken,
  },
});

export const trafficControlUpcoming = (resource, loadingType) => dispatch => {
  if (loadingType !== 'withoutLoading') {
    dispatch({ type: actionTypes.getActionType(resource, actionTypes.TRAFFIC_UPCOMING_LOADING) });
  }
  axios
    .get(buildUrl(resource, 'getupcomingtraffic'), axiosConfig())
    .then(responce => dispatch({ type: actionTypes.getActionType(resource, actionTypes.GET_TRAFFIC_CONTROL_UPCOMING), data: responce }))
    .catch(err => {
      console.error(err);
      console.log(err.response);
      dispatch({ type: actionTypes.getActionType(resource, actionTypes.ERROR) });
    });
};

export const filterByDateUpcoming = (resource, filteredOrdersList) => dispatch => {
  dispatch({
      type: actionTypes.getActionType(resource, actionTypes.FILTER_BY_DATE_UPCOMING), data: { filteredOrdersList }
  });
};

const updateDataUpcoming = (dispatch, resource, url, value) => {
  axios.put(buildUrl(resource, url), value,
      axiosConfig())
      .then(response => {
          if (response.data.hasError) {
              dispatch(errorAction(resource, true));
              console.error(response.data.message);
          }
      }).catch(err => {
          dispatch(errorAction(resource, true));
          console.error(err);
          console.log(err.response);
      });
};

export const setItemDataUpcoming = (resource, value, rowState, header, subType) => dispatch => {
  dispatch({
      type: actionTypes.getActionType(resource, actionTypes.SET_DATA_UPCOMING), data: {
          value,
          rowState,
          header,
          subType,
      }
  });

  const createQueryParam = (param, query) => query ? `${param}?loadId=${rowState.loadId}&${query}=${value}` : `${param}?loadId=${rowState.loadId}`;

  switch (header.key) {
    case 'status':
      if (subType === 'priority') {
        updateDataUpcoming(dispatch, resource, createQueryParam('priority'));
      }
  break;
      case 'trafficNote':
        updateDataUpcoming(dispatch, resource, createQueryParam('addtrafficnote', 'note'));
      break;
      default:
      break;
  }
};

export const setHeaderWidthUpcomingTraffic = (resource, ind, width) => ({
  type: actionTypes.getActionType(resource, actionTypes.SET_HEADER_WIDTH_TRAFFIC_UPCOMING),
  data: { ind, width },
});

export const setItemDisplayState = (resource, rowState, header, enable) => ({
  type: actionTypes.getActionType(resource, actionTypes.SET_DISPLAY_STATE),
  data: {
    rowState,
    header,
    enable,
  },
});

export const resetError = resource => ({ type: actionTypes.getActionType(resource, actionTypes.RESET_ERROR) });

const errorAction = (resource, reload) => dispatch => {
  if (reload) {
      axios.get(buildUrl(resource, 'getTraffic'),
          axiosConfig()
      )
          .then(responce => dispatch({ type: actionTypes.getActionType(resource, actionTypes.RELOAD_ON_ERROR), data: responce }))
          .catch((err) => {
              console.error(err);
              console.log(err.response);
              dispatch({ type: actionTypes.getActionType(resource, actionTypes.ERROR) });
          });
  }
};