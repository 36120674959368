import * as valueListActionTypes from '../../actions/valueList/valueListActionTypes';

import { CloneObject } from '../../../codeless/helper/common';

const defaultValueListState = {
    list: {}
};

const addNewValueList = (state, action) => {
    //const newState = { ...state };
    const newState = CloneObject(state);
    newState.list[action.valueKey] = action.valueList;
    return newState;
};

const removeValueList = (state, action) => {
    //const newState = { ...state };
    const newState = CloneObject(state);
    newState.list[action.valueKey] = null;
    return newState;
};

export default (state = defaultValueListState, action) => {
    switch (action.type) {
        case valueListActionTypes.ADD_VALUE_LIST:
            return addNewValueList(state, action);
        case valueListActionTypes.REMOVE_VALUE_LIST:
            return removeValueList(state, action);
        default:
            return state;
    }
}; 