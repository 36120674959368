export const SelectPriceStyles = (isDuplicate) => ({
        control: (base, state) => ({
        ...base,
        borderStyle: 'none',
        minWidth: 'unset',
        minHeight: '22px',
        paddingLeft: '4px',
        height: '16px',
        boxShadow: '0 0 0 0 #ffffff',
        alignContent: 'center',
    }),
    menu: (base, state) => ({
        ...base,
    }),
    menuList: () => ({
        position: 'fixed',
        height: '350px!important',
        overflowY: 'auto',
    }),
    input: (base, state) => ({
        ...base,
        height: 0,
        position: 'absolute',
        top: '0',
        marginTop: '-2px',
    }),
    valueContainer: (base, state) => ({
        display: 'flex',
        alignItems: 'self-end',
        paddingLeft: '0',
        maxWidth: '144px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        color: isDuplicate ? 'red' : 'inherit'
    }),
    container: (base, state) => ({
        ...base,
        pointerEvents: 'auto',
    }),
    singleValue: (base, state) => ({
        maxWidth: '144px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        margin: '4px 0',
        fontWeight: 'bold',
    }),
    option: (defaultStyles, state) => ({
        ...defaultStyles,
        backgroundColor: state.isSelected ? "lightGrey" : "#fff",
        border: '1px solid lightGray',
        borderRadius: '3px',
        padding: '4px',
        minHeight: '24px',
    }),
    indicatorsContainer: () => ({
        height: 'max-content',
    }),
    dropdownIndicator: (defaultStyles) => ({
        ...defaultStyles,
        padding: '0 2px 0 0!important',
    }),
});