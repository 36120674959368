import { TRAFFIC_CONTROL_BUILD as actionTypes } from '../../actions/trafficControlUpcoming/trafficControlUpcomingActionTypes';
import * as trafficControlUpcoming from './trafficControlUpcomingReducers';

const defaultState = {
  headers: [
    {
      key: 'loadNum',
      title: 'Load #',
      width: 120,
    },
    {
      key: 'carrierName',
      title: 'CARRIER',
      width: 80,
    },
    {
      key: 'driverName',
      title: 'DRIVER NAME',
      width: 90,
    },
    {
      key: 'driverPhone',
      title: 'DRIVER #',
      width: 80,
    },
    {
      key: 'st',
      title: 'ST',
      width: 50,
    },
    {
      key: 'palIn',
      title: 'PAL IN',
      width: 60,
      visible: true,
      pickGroup: true,
    },
    {
      key: 'palOut',
      title: 'PAL OUT',
      width: 60,
    },
    {
      key: 'day',
      title: 'DAY',
      width: 60,
      visible: true,
    },
    {
      key: 'time',
      title: 'WH TIME',
      width: 60,
      visible: true,
    },
    {
      key: 'firstDrop',
      title: '1ST DROP',
      width: 100,
      visible: true,
    },
    {
      key: 'trafficNote',
      title: 'ADD NOTES',
      width: 100,
      visible: true,
    },
    {
      key: 'status',
      title: 'STATUS',
      width: 70,
      visible: true,
    },
  ],
  subHeaders: [
    {
      key: 'filter1',
      title: '',
      width: 540,
      visible: true,
    },
    {
      key: 'sanFernando',
      title: 'SAN FERNANDO',
      width: 120,
      visible: true,
    },
    {
      key: 'empty',
      title: '',
      width: 270,
      visible: true,
      pickGroup: true,
    },
  ],
  loading: true,
  hasError: false,
  message: '',
  total: 0,
  existing: 0,
  upcomingTrafficList: null,
  filteredUpcomingTrafficList: null,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.SET_IN_LOADING:
      return trafficControlUpcoming.setInLoading(state);
    case actionTypes.SET_HEADER_WIDTH_TRAFFIC_UPCOMING:
      return trafficControlUpcoming.setHeaderWidth(state, action.data.ind, action.data.width);
    case actionTypes.RESET_ERROR:
      return trafficControlUpcoming.resetError(state);
    case actionTypes.RELOAD_ON_ERROR:
      return trafficControlUpcoming.loadContent(state, action.data, true, 'Something go wrong, data reloaded!');
    case actionTypes.SET_DATA_UPCOMING:
      return trafficControlUpcoming.setDataUpcoming(state, action.data.value, action.data.rowState, action.data.header, action.data.subType);
    case actionTypes.GET_TRAFFIC_CONTROL_UPCOMING:
      return trafficControlUpcoming.setTrafficControlUpcoming(state, action.data);
    case actionTypes.FILTER_BY_DATE_UPCOMING:
      return trafficControlUpcoming.setFilterByDate(state, action.data.filteredOrdersList);
    default:
      return state;
  }
};
