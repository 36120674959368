export const ERROR = 'ERROR';
export const RESET_ERROR = 'RESET_ERROR';
export const SET_HEADER_WIDTH_ORDER_ADMIN = 'SET_HEADER_WIDTH_ORDER_ADMIN';
export const SET_DISPLAY_STATE = 'SET_DISPLAY_STATE';
export const GET_CUSTOMER_ORDER_ADMIN = 'GET_CUSTOMER_ORDER_ADMIN';
export const CUSTOMER_ORDER_ADMIN_LOADING = 'CUSTOMER_ORDER_ADMIN_LOADING';
export const FILTER_BY_CUSTOMER = 'FILTER_BY_CUSTOMER';
export const GENERATE_CUSTOMER_ORDER = 'GENERATE_CUSTOMER_ORDER';
export const GENERATE_CUSTOMER_ORDER_LOADING = 'GENERATE_CUSTOMER_ORDER_LOADING';
export const SORT_ORDERS_ADMIN = 'SORT_ORDERS_ADMIN';
export const REJECT_CUSTOMER_ORDER_LOADING = 'REJECT_CUSTOMER_ORDER_LOADING';
export const REJECT_CUSTOMER_ORDER = 'REJECT_CUSTOMER_ORDER';
export const NEW_REQUEST_ORDERS_COUNT = 'NEW_REQUEST_ORDERS_COUNT';

export const getActionType = (recource, key) => {
    return CUSTOMER_ORDERS_BUILD[key]
};

export const CUSTOMER_ORDERS_BUILD = {
    ERROR: 'ERROR',
    RESET_ERROR: 'RESET_ERROR',
    SET_HEADER_WIDTH_ORDER_ADMIN: 'SET_HEADER_WIDTH_ORDER_ADMIN',
    GET_CUSTOMER_ORDER_ADMIN: 'GET_CUSTOMER_ORDER_ADMIN',
    CUSTOMER_ORDER_ADMIN_LOADING: 'CUSTOMER_ORDER_ADMIN_LOADING',
    FILTER_BY_CUSTOMER: 'FILTER_BY_CUSTOMER',
    GENERATE_CUSTOMER_ORDER: 'GENERATE_CUSTOMER_ORDER',
    GENERATE_CUSTOMER_ORDER_LOADING: 'GENERATE_CUSTOMER_ORDER_LOADING',
    SORT_ORDERS_ADMIN: 'SORT_ORDERS_ADMIN',
    REJECT_CUSTOMER_ORDER_LOADING: 'REJECT_CUSTOMER_ORDER_LOADING',
    REJECT_CUSTOMER_ORDER: 'REJECT_CUSTOMER_ORDER',
    NEW_REQUEST_ORDERS_COUNT: 'NEW_REQUEST_ORDERS_COUNT',

};
