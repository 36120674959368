import React from 'react';
import { useHistory } from 'react-router-dom';
import { FactoringCompanySelectors } from '../../../../store/selectors/factoringCompanySelectors';

const Cell = ({ cellData, itemSize, resource, rowState }) => {
  const history = useHistory();
  const { state } = FactoringCompanySelectors();
  const header = state[resource].headers.find(h => h.key === cellData.key);
  const width = header.width;
  let data = cellData.value;
  const id = rowState.factoringCompanyData.id;

  const getData = () => {
    if (data !== '' && data !== null) {
      switch (cellData.fieldType) {
        case 'text':
          return <span>{data}</span>;
        case 'link':
          const path = `factoringDetails/${id}`; 
          return (
            <div onClick={() => history.replace(path)} className='factoring__link-name'>{data}</div>
          );
        default:
          return data;
      }
    }
    return data;
  };

  const cellDefaultStyle = {
    maxWidth: `${width}px`,
    width: `${width}px`,
    minWidth: `${width}px`,
    height: `${itemSize}px`,
    backgroundColor: cellData.backgroundColor,
  };

  return (
    <>
      <div
        className='table-body-cell factoring__table-cell'
        style={{ ...cellDefaultStyle }}>
        {getData()}
      </div>
    </>
  );
};

export default Cell;
