import React, { useState, useEffect } from 'react';

import axios from '../../../axios/axios';

import errorHandler from '../../../error/errorHandler';

import Orders from './orders';

const ReceivingOrders = props => {
    const {
        List,
        Resource,
        OrderForm,
        Key,
        data,
        dispatchData,
        reload
    } = props;
    const columns = List.Columns;

    const [isLoading, setLoading] = useState(true);
    useEffect(() => {
        let isMounted = true;
        const fetchData = async (isMounted) => {
            if (isMounted) {
                setLoading(true);
            }
            const response = await axios(
                Resource
            ).finally(() => {
                if (isMounted) {
                    setLoading(false);
                }
            });
            if (isMounted) {
                if (response) {
                    const result = response.data;
                    if (result) {
                        const data = result.data;
                        for (var i = 0; i < data.length; i++) {
                            if (!data[i].key) {
                                data[i].key = data[i]['id'];
                            }
                        };
                        dispatchData({ type: "orders", value: data });
                        setLoading(false);
                    }
                }
            }
        };
        setLoading(true);
        fetchData(isMounted);
        return () => { isMounted = false };
    }, [Resource, dispatchData, reload]);

    return <Orders
        isLoading={isLoading}
        resource={props.resource}
        OrderForm={OrderForm}
        keyFieldName={Key}
        columns={columns}
        dropId={"orders"}
        data={data?.["orders"]?.data || []}
        dispatchData={dispatchData}
    />;
};

export default errorHandler(ReceivingOrders, axios);