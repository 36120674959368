export const LOAD = 'LOAD';
export const ERROR = 'ERROR';
export const SET_HEADER_WIDTH_TRAFFIC = 'SET_HEADER_WIDTH_TRAFFIC';
export const SET_IN_LOADING = 'SET_IN_LOADING';
export const RELOAD_ON_ERROR_TRAFFIC = 'RELOAD_ON_ERROR_TRAFFIC';
export const RESET_ERROR = 'RESET_ERROR';
export const UPDATE_LOAD_DATA = 'UPDATE_LOAD_DATA';
export const RE_ORDER_LOADS = 'RE_ORDER_LOADS';

export const GET_TRAFFIC_CONTROL = 'GET_TRAFFIC_CONTROL';
export const TRAFFIC_LOADING = 'TRAFFIC_LOADING';
export const SET_DATA = 'SET_DATA';
export const SET_DISPLAY_STATE = 'SET_DISPLAY_STATE';
export const UPDATE_LOAD_STATUS = 'UPDATE_LOAD_STATUS';
export const SET_LOAD_NEED_ID = 'SET_LOAD_NEED_ID';

export const getActionType = (recource, key) => {
    return TRAFFIC_CONTROL_BUILD[key]
};

export const TRAFFIC_CONTROL_BUILD = {
    TRAFFIC_LOADING: 'TRAFFIC_LOADING',
    GET_TRAFFIC_CONTROL: 'GET_TRAFFIC_CONTROL',
    SET_DATA: 'SET_DATA',
    SET_DISPLAY_STATE: 'SET_DISPLAY_STATE',
    UPDATE_LOAD_STATUS: 'UPDATE_LOAD_STATUS',
    RE_ORDER_LOADS: 'RE_ORDER_LOADS',
    RELOAD_ON_ERROR_TRAFFIC: 'RELOAD_ON_ERROR_TRAFFIC',
    ERROR: 'ERROR',
    RESET_ERROR: 'RESET_ERROR',
    SET_HEADER_WIDTH_TRAFFIC: 'SET_HEADER_WIDTH_TRAFFIC',
    SET_LOAD_NEED_ID: 'SET_LOAD_NEED_ID'
};
