import React, { useMemo, useRef, useEffect, useCallback, useState } from 'react';
import { useLocation} from 'react-router-dom';
import { connect } from "react-redux";
import { VariableSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { CSVLink  } from "react-csv";
import { orderBy } from "lodash";
import Select from 'react-select'
import { load, resetError, filterDeliveredTrucks } from '../../../../store/actions/deliveredTrucksActions/deliveredTrucks';
import { getContentWidth } from '../../../../store/reducers/deliveredTrucksReducer/helper/general';
import Row from './row';
import HeaderRow from './headerRow';
import { createSeparator } from '../../../../store/reducers/deliveredTrucksReducer/helper/separator';
import { Dropdown, Space, message, Menu, Input } from 'antd';
import Loading from "../../screen/loading";
import HideShowMenuSwitch from '../../layout/hideShowMenuSwitch';
import UploadModal from './UploadModal'; 
import CommentsModal from './CommentsModal';
import { useClientHubOrder } from "../../../hook/hub/useClientHubOrder";
import { getCSVData } from './helper'

const Spreadsheet = props => {
    const { 
        state,
        loadsAssignedOrders,
        resetError,
        haseError,
        actionMessage,
        loading,
        load,
        resource,
        filterDeliveredTrucks,
        filterType,
        sortType,
        userId
    } = props;

    const scrollSizeSmall = 18;
    const scrollSize = 16;
    const defRowHeight = 20;
    const assignedRef = useRef({});
    const [uploadRowState, setUploadRowState] = useState('');
    const [searchInput, setSearchInput] = useState('');
    const [uploadModalIsOpen, setUploadModalIsOpen] = useState(false);
    const [commentsModalIsOpen, setCommentsModalIsOpen] = useState(false);
    const { pathname } = useLocation();
    const [PONumber, setPONumber] = useState('All');
    const [loadNumberData, setLoadNumber] = useState('All');

    let method = pathname === '/ap/deliveredtrucks' ? 'area' : 'readytopay'
    if(pathname === '/ap/deliveredtrucks'){
        method = 'area';
    } else if(pathname === '/ap/readytopay'){
        method = 'readytopay'
    } else if(pathname === '/ap/paid'){
        method = 'payed'
    }

    useClientHubOrder((message) => {
        if (userId !== message.byId) {
            load(resource, method, 'withoutLoading');
        }
    }, 'notification');

    useEffect(() => {
        load(resource, method);
    }, [load, method, resource]);

    useEffect(() => {
        filterDeliveredTrucks(resource, filterType)
    }, [filterDeliveredTrucks, filterType, resource])

    useEffect(() => {
        if (haseError) {
            message.error(actionMessage);
            resetError(resource, method);
        }
    }, [haseError, actionMessage, resetError, resource, method]);

    const listReference = useRef(null);
    const listHeaderReference = useRef(null);
    const mainScroll = useRef(null);
    const startOnScroll = width => mainScroll.current.scrollLeft = width;

    const onScrollX = e => {
        if (listHeaderReference.current && listReference.current && e.target) {
            listHeaderReference.current.scrollLeft =
            listReference.current.scrollLeft = e.target.scrollLeft;
        }
    };

    useEffect(() => {
        if (listHeaderReference && listHeaderReference?.current && listHeaderReference?.current?.scrollLeft) {
            listReference.current.scrollLeft = listHeaderReference?.current?.scrollLeft
        }
    }, [searchInput, PONumber, loadNumberData]);

    const header = useMemo(() =>
        <div style={{ overflow: 'hidden' }}>
            <div
                style={{
                    width: '36px',
                    position: 'absolute',
                    background: '#449996',
                    height: '31px',
                    zIndex: '1',
                    borderRight: '1px solid #325353',
                }}>
            </div>
            <div
                ref={listHeaderReference}
                style={{
                    width: '100%',
                    overflow: 'hidden'
                }}>
                <HeaderRow resource={resource} />
            </div>
        </div>,
    [resource]);
    
    const width = getContentWidth(state[resource].headers) + scrollSize;
    const extraScrollVisibility = window.innerWidth > width ? 'none' : 'flex';

    const byPODStatusIN = (loadsAssignedOrders, status) => {
        const filteredList = [];
        for (let i = 0; i < loadsAssignedOrders?.length; i++) {
            if(loadsAssignedOrders[i].ordersData) {
                for (let j = 0; j < loadsAssignedOrders[i].ordersData.length; j++) {
                    if (loadsAssignedOrders[i].ordersData[j].rowState?.orderData?.inPodRequestStatus === status) {
                        filteredList.push(loadsAssignedOrders[i]);
                        break;
                    }
                }
            } else {
                if (loadsAssignedOrders[i].rowState?.orderData?.inPodRequestStatus === status) {
                    filteredList.push(loadsAssignedOrders[i]);
                }
            }
        }
        return filteredList.filter(item => item.rowState.loadData.loadType === 1);
    };

    const byPODStatusOUT = (loadsAssignedOrders, status) => {
        const filteredList = [];
        for (let i = 0; i < loadsAssignedOrders?.length; i++) {
            if(loadsAssignedOrders[i].ordersData) {
                for (let j = 0; j < loadsAssignedOrders[i].ordersData.length; j++) {
                    if (loadsAssignedOrders[i].ordersData[j].rowState?.orderData?.outPodRequestStatus === status) {
                        filteredList.push(loadsAssignedOrders[i]);
                        break;
                    }
                }
            } else {
                if (loadsAssignedOrders[i].rowState?.orderData?.outPodRequestStatus === status) {
                    filteredList.push(loadsAssignedOrders[i]);
                }
            }
        }
        return filteredList.filter(item => item.rowState.loadData.loadType === 3);
    };
    
    const byPODStatus = (loadsAssignedOrders, type) => {
        return [...byPODStatusIN(loadsAssignedOrders, type), ...byPODStatusOUT(loadsAssignedOrders, type)];
    };

    let filteredData = loadsAssignedOrders;

    if (filterType === 'byIn') {
        filteredData = loadsAssignedOrders?.filter(item => item.rowState.loadData.loadType === 1);
    } else if (filterType === 'byOut') {
        filteredData = loadsAssignedOrders?.filter(item => item.rowState.loadData.loadType === 3);
    } else if (filterType === 'byPODReceived') {
        filteredData = byPODStatus(loadsAssignedOrders, 1);
    } else if (filterType === 'byPODRequested') {
        filteredData = byPODStatus(loadsAssignedOrders, 2);
    } else if (filterType === 'all') {
        filteredData = loadsAssignedOrders;
    }
    if(sortType && sortType.sort !== null){
        let newList = [];
        let newListTwo = [];
        const newArr = [...filteredData]
        newArr.forEach((item, index)=> {
            if(item.rowState.type === "load"){
                newListTwo = orderBy(newListTwo, [c => c.row[sortType.ind].value], sortType.sort);
                newList = [...newList, ...newListTwo, item]
                newListTwo.length = 0;
            } else if(index === newArr.length - 1){
                newListTwo.push(item)
                newListTwo = orderBy(newListTwo, [c => c.row[sortType.ind].value], sortType.sort);
                newList = [...newList, ...newListTwo]
            } else {
                newListTwo.push(item)
            }
        })
        filteredData = newList;
    }
    const displayLoadsAssignedOrders = useMemo(() =>
        !filteredData || filteredData.length === 0
            ? [createSeparator(defRowHeight, state[resource].headers, 'load', 0)] : filteredData, [filteredData, state, resource]);
    
    const getLoadNumberData = order => {
        if (order?.rowState?.loadData?.loadType === 1) {
            return (order?.ordersData?.filter(order => 
                order.rowState?.orderData?.inboundLoadNumber?.includes(loadNumberData) || loadNumberData === 'All').length > 0) ||
                order.rowState?.orderData?.inboundLoadNumber?.includes(loadNumberData) || loadNumberData === 'All'
        } else {
            return (order?.ordersData?.filter(order => 
                order.rowState?.orderData?.outboundLoadNumber?.includes(loadNumberData) || loadNumberData === 'All').length > 0) ||
                order.rowState?.orderData?.outboundLoadNumber?.includes(loadNumberData) || loadNumberData === 'All'
        }
    }

    const myLoadsAssignedOrders = useMemo(() => displayLoadsAssignedOrders
        .filter(order => order.rowState.visible)        
        .filter(order => (order?.ordersData?.filter(order => 
                order.rowState?.orderData?.poNumber?.includes(PONumber) || PONumber === 'All').length > 0) ||
                order.rowState?.orderData?.poNumber?.includes(PONumber) || PONumber === 'All')               
        .filter(getLoadNumberData)
        .filter(order => (order?.ordersData?.filter(order => 
                        order.rowState?.orderData?.poNumber?.toLowerCase().includes(searchInput) ||
                        order.rowState?.orderData?.soNumber?.toLowerCase().includes(searchInput)).length > 0) ||
                (order.ordersData?.length === 0 && !searchInput) ||
                (order.ordersData?.length === 0 && order.rowState?.loadData?.loadNumber?.toLowerCase().includes(searchInput)) ||
                order.rowState?.orderData?.poNumber?.toLowerCase().includes(searchInput) ||
                order.rowState?.orderData?.soNumber?.toLowerCase().includes(searchInput))
        // eslint-disable-next-line react-hooks/exhaustive-deps
        , [displayLoadsAssignedOrders, searchInput, PONumber, loadNumberData]
    );

    const getAsignedItemSize = useCallback(
        index => myLoadsAssignedOrders[index] ? myLoadsAssignedOrders[index].rowState.height : defRowHeight,
        [myLoadsAssignedOrders]);

    const RenderAssignedRow = useCallback(({ data, index, style }) => (
        (index !== undefined && data[index])
            ? <Row
                listRef={assignedRef}
                data={data[index]}
                itemSize={getAsignedItemSize(index)}
                style={style}
                dndRowIndex={index}
                startOnScroll={startOnScroll}
                resource={resource}
                uploadModalIsOpen={uploadModalIsOpen}
                setUploadModalIsOpen={setUploadModalIsOpen}
                setUploadRowState={setUploadRowState}
                setCommentsModalIsOpen={setCommentsModalIsOpen}
            />
            : null
    ), [getAsignedItemSize, resource, uploadModalIsOpen]);

    const allOrdersList = useMemo(() => myLoadsAssignedOrders &&
        <AutoSizer disableWidth>
            {({ height }) => (
                <>
                    <VariableSizeList
                        ref={assignedRef}
                        outerRef={el => {
                            listReference.current = el;
                        }}
                        style={{ overflowX: 'hidden', overflowY: 'scroll' }}
                        itemData={myLoadsAssignedOrders}
                        itemCount={myLoadsAssignedOrders.length}
                        height={height}
                        itemSize={getAsignedItemSize}>
                        {RenderAssignedRow}
                    </VariableSizeList>
                </>
            )}
        </AutoSizer>
        , [myLoadsAssignedOrders, getAsignedItemSize, RenderAssignedRow]);

    const content = (<>
        <div style={{ display: 'flex' }}>
            {header}
            <div style={{
                width: `${scrollSizeSmall}px`,
                maxWidth: `${scrollSizeSmall}px`,
                minWidth: `${scrollSizeSmall}px`,
                height: '32px',
                backgroundColor: 'lightgrey',
                display: extraScrollVisibility,
            }} />
        </div>
        <div style={{ flexGrow: 1, width: 'calc(100%)' }}>{allOrdersList}</div>
        <div style={{
            backgroundColor: 'red',
            width: '100%',
            height: `${scrollSize}px`,
            overflowX: 'scroll',
            flexShrink: 0,
        }}
            ref={mainScroll}
            onScroll={e => onScrollX(e)}>
            <div className='resp-table-row'>
                <div
                    className='table-body-cell'
                    style={{
                        maxWidth: `${width}px`,
                        width: `${width}px`,
                        minWidth: `${width}px`,
                        boxSizing: 'border-box',
                        backgroundColor: 'black',
                    }} />
            </div>
        </div>
    </>);

    const handleSelectChange = e => {
        filterDeliveredTrucks(resource, e.key);
    };

    const menu = (
        <Menu className='customer-order__menu callBack-filter__menu' onClick={handleSelectChange}>
            <Menu.Item key="all">All</Menu.Item>
            <Menu.Item key="byIn">IN</Menu.Item>
            <Menu.Item key="byOut">OUT</Menu.Item>
        </Menu>
    );
    const refreshPage = e => {
        load(resource, method);
    };

    const CSVHeaders = state[resource].headers.map(column => ({
        label: column.title,
        key: column.title,
    }));
    const CSVData = getCSVData(myLoadsAssignedOrders);
    const styles = {
        control: (base, state) => ({
            ...base,
            borderRadius: '8px',
            boxShadow: 'none',
            minHeight: '22px',
            minWidth: '150px',
            paddingLeft: '10px',
            height: '24px',
            alignContent: 'center',
        }),
        menu: (base, state) => ({
            ...base,
            boxShadow: '0',
            top: 20,
        }),
        input: (base, state) => ({
            ...base,
            height: 0,
            position: 'absolute',
            top: '0',
            marginTop: '-2px',
        }),
        valueContainer: (base, state) => ({
            height: '-webkit-fill-available',
        }),
        singleValue: (base, state) => ({
            maxWidth: '170px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        }),
        // menuList: (base, state) => ({
        //     position: 'fixed',
        // }),
        menuList: (base) => ({
            ...base,
            position: 'fixed',
            maxHeight: "-webkit-fill-available",
            padding: 0,
            "::-webkit-scrollbar": {
                width: "4px",
                height: "0px",
            },
            "::-webkit-scrollbar-track": {
                background: "#f1f1f1"
            },
            "::-webkit-scrollbar-thumb": {
                background: "#888"
            },
            "::-webkit-scrollbar-thumb:hover": {
                background: "#555"
            }
        }),
        option: (defaultStyles, state) => ({
            ...defaultStyles,
            color: state.isSelected ? "#000" : "#000",
            backgroundColor: state.isSelected ? "lightGrey" : "#fff",
            border: '1px solid lightGray'
        }),
        indicatorsContainer: () => ({
            height: 'max-content',
        }),
        indicatorContainer: () => ({
            padding: '0 8px!important',
        }),
    };

    const PONumbers = loadsAssignedOrders && loadsAssignedOrders.map(order => { 
        return {value: order?.rowState?.type === 'order' ? order?.rowState?.orderData?.poNumber : '', 'label': order?.rowState?.type === 'order' ? order?.rowState?.orderData?.poNumber : '' }
    });

    const loadNumbers = loadsAssignedOrders && loadsAssignedOrders.map(order => {
        if (order?.rowState?.loadData?.loadType === 1) {
            return {value: order?.rowState?.type === 'order' ? order?.rowState?.orderData?.inboundLoadNumber : '', 'label': order?.rowState?.type === 'order' ? order?.rowState?.orderData?.inboundLoadNumber : '' }
        } else {
            return {value: order?.rowState?.type === 'order' ? order?.rowState?.orderData?.outboundLoadNumber : '', 'label': order?.rowState?.type === 'order' ? order?.rowState?.orderData?.outboundLoadNumber : '' }
        }
    });    
    
    const uniquePONumbers = PONumbers?.filter((order, index, self) =>
            index === self.findIndex((t) => t.label === order.label && t.value === order.value))
        .filter(order => order.value !== '')
        .concat( { value: 'All', label: 'All' });
    
    const uniqueLoadNumbers = loadNumbers?.filter((order, index, self) =>
            index === self.findIndex((t) => t.label === order.label && t.value === order.value))
        .filter(order => order.value !== '')
        .concat( { value: 'All', label: 'All' });
        
    const handleChangePoNumber = option => {
        setPONumber(option.value);
    };

    const handleChangeLoadNumber = option => {
        setLoadNumber(option.value);
    };

    const subHeader = (
        <div className='toolbar__container'>
            <div className='toolbar__container_left'>
                <div className='filter_call sort_icon'></div>
                <Dropdown className='customer-order__dropdown' overlay={menu} placement="bottomLeft">
                    <span onClick={(e) => e.preventDefault()}>
                        <Space>Filter</Space>
                    </span>
                </Dropdown>
                <div 
                    className="button accent" 
                    style={{ alignSelf: 'center' }}
                    onClick={refreshPage}>
                    Refresh
                </div>
                <CSVLink 
                    headers={CSVHeaders}
                    data={CSVData}
                    filename={`Delivered trucks.csv`}
                    className='btn__download__ar'
                    disabled={!myLoadsAssignedOrders?.length}
                >
                    Download
                </CSVLink>
                <div className='filters_container'>
                    <div className='filter_container__select'>
                        <div className='filter_title'>FILTER</div>
                        <Select
                            options={uniquePONumbers}
                            name="PONumber"
                            placeholder = "PO#"
                            value={PONumber && {value: PONumber, label: PONumber}}
                            noOptionsMessage={() => null}
                            onChange={option => handleChangePoNumber(option)}
                            styles={styles}
                            components={{
                                IndicatorSeparator: () => null
                            }}
                        />
                    </div>
                    <div className='filter_container__select'>
                        <Select
                            options={uniqueLoadNumbers}
                            name="loadNumber"
                            placeholder = "Load#"
                            value={loadNumberData && {value: loadNumberData, label: loadNumberData}}
                            noOptionsMessage={() => null}
                            onChange={option => handleChangeLoadNumber(option)}
                            styles={styles}
                            components={{
                                IndicatorSeparator: () => null
                            }}
                        />
                    </div>
                </div>
                <Input
                    className='input__search'
                    onChange={e => setSearchInput(e.target.value)}
                    placeholder="Search"
                    type="text"
                />
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <HideShowMenuSwitch />
            </div>
            {uploadModalIsOpen && 
                <UploadModal
                    rowState={uploadRowState}
                    uploadModalIsOpen={uploadModalIsOpen}
                    setUploadModalIsOpen={setUploadModalIsOpen}
                />
            }
            {commentsModalIsOpen && 
                <CommentsModal
                    commentsModalIsOpen={commentsModalIsOpen}
                    setCommentsModalIsOpen={setCommentsModalIsOpen}
                    rowState={uploadRowState}
                />
            }
        </div>
    )
    return <>
        {subHeader}{loading ? <Loading /> : <>{content}</>}</>;
};

const mapStateToProps = state => ({
    state: state,
    loadsAssignedOrders: state.AccountPayable.loadsAssignedOrders,
    loading: state.AccountPayable.loading,
    haseError: state.AccountPayable.hasError,
    actionMessage: state.AccountPayable.message,
    filterType: state.AccountPayable.filterType,
    sortType: state.AccountPayable.sortType,
    userId: state.auth.userId,
});

const mapDispatchToProps = (dispatch) => ({
    load: (resource, method, loadingType) => dispatch(load(resource, method, loadingType)),
    resetError: (resource, method) => dispatch(resetError(resource, false, method)),
    filterDeliveredTrucks: (resource, key) => dispatch(filterDeliveredTrucks(resource, key)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Spreadsheet);