import { useSelector } from "react-redux";

export const FactoringCompanySelectors = () => {
  const state = useSelector(state => state);
  const loading = useSelector(state => state.factoringCompany.loading);
  const factoringCompanyList = useSelector(state => state.factoringCompany.factoringCompanyList);
  const factoringCompanyDetails = useSelector(state => state.factoringCompany.factoringCompanyDetails);
  const sortType = useSelector(state => state.factoringCompany.sortType);
  const messageText = useSelector(state => state.factoringCompany.messageText);
  const hasError = useSelector(state => state.factoringCompany.hasError);
  const statesList = useSelector(state => state.factoringCompany.statesList);
  const newFactoryAdded = useSelector(state => state.factoringCompany.newFactoryAdded);
  const factoryCompanyUpdated = useSelector(state => state.factoringCompany.factoryCompanyUpdated);
  const submitActinType = useSelector(state => state.factoringCompany.submitActinType);

  return { 
    state, 
    loading,
    factoringCompanyList, 
    factoringCompanyDetails, 
    sortType, 
    messageText, 
    hasError, 
    statesList, 
    newFactoryAdded,
    factoryCompanyUpdated,
    submitActinType,
  };
};