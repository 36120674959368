import React, { useEffect, useRef, useCallback, useMemo, useState } from 'react';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeList } from 'react-window';
import { message, Input } from 'antd';
import './index.css'
import HeaderRow from './headerRow';
import Row from './row';
import Loading from "../../screen/loading";
import { trafficControlUpcoming, resetError, } from '../../../../store/actions/trafficControlUpcoming/trafficControlUpcoming';
import { getContentWidth, getContentWidthSomePartOnly } from '../../../../store/reducers/trafficControl/helper/general';
import { useClientHubOrder } from "../../../hook/hub/useClientHubOrder";

const SpreadsheetUpcoming = props => {
    const { userId, trafficControlUpcoming, loading, resource, haseError, resetError, actionMessage, state, trafficUrl } = props;
    const defRowHeight = 40;
    const scrollSize = 0;
    const upcomingTrafficRef = useRef(null);
    const listReference = useRef(null);
    const pallInSummaryReference = useRef(null);
    const listHeaderReference = useRef(null);
    const listTrafficHeaderReference = useRef(null);
    const mainScroll = useRef(null);
    const startOnScroll = width => mainScroll.current.scrollLeft = width;
    const [searchInput, setSearchInput] = useState('');
    const [selectedOrder, setSelectedOrder] = useState([]);

    const onScrollX = e => {
        if (listHeaderReference.current && listReference.current && e.target) {
            listHeaderReference.current.scrollLeft =
            listReference.current.scrollLeft =
            e.target.scrollLeft;
        }
        if (listTrafficHeaderReference.current && listReference.current && e.target) {
            listTrafficHeaderReference.current.scrollLeft =
            listReference.current.scrollLeft =
            e.target.scrollLeft;
        }
        if (pallInSummaryReference.current && listReference.current && e.target) {
            pallInSummaryReference.current.scrollLeft =
            listReference.current.scrollLeft =
            e.target.scrollLeft;
        }
    }

    useClientHubOrder((message) => {
        if (userId !== message.byId) {
            trafficControlUpcoming(resource, 'withoutLoading')
        }
    }, 'notification');

    useEffect(() => {
        trafficControlUpcoming(resource);
    }, [resource, trafficControlUpcoming]);

    useEffect(() => {
        if (haseError) {
            message.error(actionMessage);
            resetError(resource);
        }
    }, [haseError, actionMessage, resetError, resource]);

    const upcomingTrafficList = state[resource]?.filteredUpcomingTrafficList ? state[resource]?.filteredUpcomingTrafficList : state[resource]?.upcomingTrafficList;

    const headers = state[resource].headers;
    const width = getContentWidth(headers) + scrollSize;
    const palletSummaryWidthBefore = getContentWidthSomePartOnly(headers, 5);
    const palletSummaryWidth = headers[5].width;

    const header = useMemo(() => <div ref={listHeaderReference} style={{
        width: '100%',
        overflow: 'hidden'
    }}>
        <HeaderRow resource={resource} />
    </div>, [resource]);

    const subHeader = useMemo(() => <div ref={listTrafficHeaderReference} style={{
        overflow: 'hidden',
        borderRadius: '12px 12px 0 0',
        width: `calc(100% - 0px)`,
    }}>
        <HeaderRow resource={resource} trafficSubHeader/>
    </div>, [resource]);

    const getIndex = (selectedOrderAssigned, selectedOrderAssignedIndex, listType) => {
        if (upcomingTrafficRef?.current?.scrollToItem) {
            const loadsList = upcomingTrafficList.filter(load => load.rowState.loadData);
            const selectedOrderId = selectedOrderAssigned[0].rowState.loadId;
            let selectedLoadIndex = 0;
            for (let i = 0; i < loadsList?.length; i++) {
                for (let j = 0; j < loadsList[i].length; j++) {
                    if (loadsList[i].rowState.loadId === selectedOrderId) {
                        selectedLoadIndex = i;
                    }
                }
            }
            const finalIndex = Math.round(selectedOrderAssignedIndex && selectedOrderAssignedIndex + selectedLoadIndex );
            selectedOrderAssignedIndex && upcomingTrafficRef.current.scrollToItem(finalIndex)
        }
    }

    const searchLoads = (loadList, searchInput) => {
        const searchedOrders = loadList?.filter(load => 
            (load.rowState?.loadData?.carrierName?.toLowerCase().includes(searchInput) && searchInput !== '') ||
            (load.rowState?.loadData?.driverName?.toLowerCase().includes(searchInput) && searchInput !== '') ||
            (load.rowState?.loadData?.customer?.toLowerCase().includes(searchInput) && searchInput !== '') ||
            (load.rowState?.loadData?.loadvIdentifier?.toLowerCase().includes(searchInput) && searchInput !== '')
        );

        const searchedOrdersByField = searchedOrders?.map(load => {
            if (load.rowState?.loadData?.carrierName?.toLowerCase().includes(searchInput)) {
                load.rowState.loadData.field = 'carrierName'
                return load;
            } else if (load.rowState?.loadData?.driverName?.toLowerCase().includes(searchInput)) {
                load.rowState.loadData.field = 'driverName'
                return load;
            } else if (load.rowState?.loadData?.customer?.toLowerCase().includes(searchInput)) {
                load.rowState.loadData.field = 'customer'
                return load;
            } else if (load.rowState?.loadData?.loadvIdentifier?.toLowerCase().includes(searchInput)) {
                load.rowState.loadData.field = 'loadvIdentifier'
                return load;
            }
        })

        const selectedOrderAssignedIndex = loadList?.map((load, index) => {
            if ((load.rowState?.loadData?.carrierName?.toLowerCase().includes(searchInput) && searchInput !== '') ||
                (load.rowState?.loadData?.driverName?.toLowerCase().includes(searchInput) && searchInput !== '') ||
                (load.rowState?.loadData?.customer?.toLowerCase().includes(searchInput) && searchInput !== '') ||
                (load.rowState?.loadData?.loadvIdentifier?.toLowerCase().includes(searchInput) && searchInput !== '')
            ) {
                return index;
            }
        }).filter(item => item !== undefined)[0];

        if (searchedOrders?.length) {
            setSelectedOrder(searchedOrdersByField);
            getIndex(searchedOrders, selectedOrderAssignedIndex)
        } else {
            setSelectedOrder('')
        }
    };

    useEffect(() => {
        searchLoads(upcomingTrafficList, searchInput);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [upcomingTrafficRef, searchInput]);

    const getUpcomingTrafficItemSize = useCallback(
        index => upcomingTrafficList?.[index] ? upcomingTrafficList[index].rowState.height : defRowHeight,
        [upcomingTrafficList]);

    const RenderUpcomingTrafficRow = useCallback(({ data, index, style }) => (
        (index !== undefined && data[index])
            ? <Row
                listRef={upcomingTrafficRef}
                data={data[index]}
                itemSize={getUpcomingTrafficItemSize(index)}
                style={style}
                resource={resource}
                startOnScroll={startOnScroll}
                selectedOrder={selectedOrder}
            />
            : null
    ), [getUpcomingTrafficItemSize, resource, selectedOrder]);

    const getPallInSummary = useMemo(() => {
        let summary = 0;
        for (let i = 0; i < upcomingTrafficList?.length; i++) {
            for (let j = 0; j < upcomingTrafficList[i].row.length; j++) {
                if (upcomingTrafficList[i]?.row[j].key === 'palIn') {
                    summary = Number(summary) + Number(upcomingTrafficList[i]?.row[j].value);
                }
            }
        }
        return Number(summary);
    }, [upcomingTrafficList]);

    const allUpcomingTrafficList = useMemo(() => upcomingTrafficList &&
    <AutoSizer disableWidth>
        {({ height }) => (
            <>
                <VariableSizeList
                    ref={upcomingTrafficRef}
                    outerRef={el => { listReference.current = el }}
                    style={{ overflowX: 'hidden', overflowY: 'auto', height: upcomingTrafficList.length * 20 }}
                    itemData={upcomingTrafficList}
                    itemCount={upcomingTrafficList.length}
                    height={height}
                    itemSize={getUpcomingTrafficItemSize}>
                    {RenderUpcomingTrafficRow}
                </VariableSizeList>
                <div ref={pallInSummaryReference} className='palInSummary'>
                    <span className='palInSummarySpanOneUpcoming' style={{ width: palletSummaryWidthBefore, maxWidth: palletSummaryWidthBefore, minWidth: palletSummaryWidthBefore }}></span>
                    <span className='palInSummarySpanTwoUpcoming' style={{ width: palletSummaryWidth, maxWidth: palletSummaryWidth, minWidth: palletSummaryWidth }}>{getPallInSummary}</span>
                    <span className='palInSummarySpanThreeUpcoming'></span>
                </div>
            </>)}
    </AutoSizer>
    , [upcomingTrafficList, getUpcomingTrafficItemSize, RenderUpcomingTrafficRow, getPallInSummary, palletSummaryWidthBefore, palletSummaryWidth]);

    const content = (<>
        <div className='title'>
            <div style={{display: 'flex'}}>
                <div>UPCOMING</div>
                <div className='traffic__search_container__upcoming'>
                    <Input
                        className='load__input_search'
                        onChange={e => setSearchInput(e.target.value.toLowerCase())}
                        placeholder="Search"
                        type="text"
                    /> 
                </div>
            </div>
            <Link to={trafficUrl} className='upcomingButton title_btn'>DONE</Link>
        </div>
        <div>{subHeader}</div>
        <div>{header}</div>
        <div style={{ flexGrow: 1 }}>{allUpcomingTrafficList}</div>
        <div style={{
            width: '100%',
            overflowX: 'scroll',
            flexShrink: 0
        }}
            ref={mainScroll}
            onScroll={e => onScrollX(e)}>
            <div
                className='table-body-cell'
                style={{
                    maxWidth: `${width}px`,
                    width: `${width}px`,
                    minWidth: `${width}px`,
                }} />
        </div>
    </>);

    return <>{loading ? <Loading /> : content}</>;
};

const mapStateToProps = state => ({
    state: state,
    trafficUpcoming: state.trafficUpcoming,
    haseError: state.trafficUpcoming.hasError,
    loading: state.trafficUpcoming.loading,
    actionMessage: state.trafficUpcoming.message,
    userId: state.auth.userId,
});

const mapDispatchToProps = (dispatch) => ({
    trafficControlUpcoming: (resource, loadingType) => dispatch(trafficControlUpcoming(resource, loadingType)),
    resetError: resource => dispatch(resetError(resource)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SpreadsheetUpcoming);