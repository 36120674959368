import React, { forwardRef, useImperativeHandle } from 'react';

import { CloneObject } from '../../../helper/common';
import Sheet from '../sheet/sheet';
import { setValue } from '../../../hook/data/useData';
import { useFormValidation } from '../../../hook/data/useFormValidation';
import { MappingFields } from '../../../mapping/mapping';
import { ValidateFields } from '../../../helper/validation';

const ManagedSheet = forwardRef((props, ref) => {
    const title = props.config.Title;
    const columns = props.config.List.Columns;
    const data = props.data;
    const presentationData = CloneObject(data);

    const validate = useFormValidation(
        props.messageDispatch,
        props.data,
        MappingFields,
        ValidateFields,
        columns,
        null,
        props.messageIdentityPrefix,
        null,
        props.type,
        null);

    useImperativeHandle(ref, () => ({
        validate() {
            const [passValidation] = validate();
            return passValidation;
        }
    }));

    const onChangeHandler = (event) => {
        changeRecordData(event.keyField, event.field, event.newData, event.column, event.nativeEvent.source);
    };

    const changeRecordData = (keyField, field, newValue, column, outSource) => {
        const newData = CloneObject(props.data);        
        if ((field === 'landing' || field === 'secondaryLanding') && newValue === true) {
            newData.map(item => {
                setValue(item, field, {
                    value: !newValue,
                    dependencies: column.Dependencies,
                    outSource: outSource
                });
                return props.dispatch({ type: props.type, value: newData });
            })
        }

        const item = newData.find(i => i[props.keyFieldName] === keyField);
        if (item) {
            setValue(item, field, {
                value: newValue,
                dependencies: column.Dependencies,
                outSource: outSource
            });
            props.dispatch({ type: props.type, value: newData });
        }

        // empty all secondaryLanding days if no one landing day is selected
        if (field === 'landing') {
            const landingDayExist = newData.some(item => item.landing);
            if (!landingDayExist) {
                newData.map(item => {
                    setValue(item, 'secondaryLanding', {
                        value: false,
                        dependencies: column.Dependencies,
                        outSource: outSource,
                    });
                    return props.dispatch({ type: props.type, value: newData });
                })
            }
        }
    };
    return (<div>
        {title && <div className="form_fields"><div className="section_title">
            <text>
                {title}
            </text>
        </div><div className="form_separator"></div></div>}
        <Sheet
            messageIdentityPrefix={props.messageIdentityPrefix}
            keyFieldName={props.keyFieldName}
            columns={columns}
            data={presentationData}
            parentData={presentationData}
            onChange={onChangeHandler}
            messageDispatch={props.messageDispatch}
            messages={props.messages}
            dispatch={props.dispatch}
            type={props.type}
            classes={'table_view compact'}
            colgroup
        />
    </div>);
});

export default ManagedSheet;