import React from 'react';

import Loading from '../loading';
import CommonFormView from './commonFormView';
import ForgotPasswordInfo from '../info/forgotPasswordInfo';

const ForgotPasswordFormView = props => (
    <CommonFormView>
        <ForgotPasswordInfo title={props.title} text={props.text} />
        {props.isLoading ? <Loading /> : props.children}
    </CommonFormView>
);

export default ForgotPasswordFormView;