import React, { Fragment } from 'react';
import { connect } from "react-redux";

import { setProfileColumnWidth, saveProfileColumnWidth } from '../../../../store/actions/authentication/authentication';

import ColGroup from './colGroup';

import ResizableTitle from '../../list/resizableTitle';

const SheetHeader = props => {
    if (props.resizable && !props.resource) {
        console.warn('Resizable sheet should hav a resource');
    }
    const profileColumns = props.profileSettings?.screens?.[props.resource]?.list?.columns;
    const columns = props.columns;

    const columnsWithZeroWidth = Object.values(columns).filter(column => column.Width === 0);
    columnsWithZeroWidth.map(column => delete columns[column.field]);

    if (!columns) {
        return null;
    }

    const resizablTitle = (c, i, width, column) => (<ResizableTitle
        key={c + i}
        onResize={props.onResize}
        onResizeStop={props.onResizeStop}
        onResizeStart={props.onResizeStart}
        width={width}
        className='sheet-table-header'
        field={c}
        onClick={props.onSort ? props.onSort.bind(this, c) : null}
    >
        {props.onSort
            ? <wrap sort="">
                <text>{column.Title}</text>
                <div sort="">
                    <span className={props.sort.some(obj => obj.field === c && obj.direction === 'asc') ? 'select' : null}><icon>up</icon></span>
                    <span className={props.sort.some(obj => obj.field === c && obj.direction === 'desc') ? 'select' : null}><icon>down</icon></span>
                </div>
            </wrap>
            : <text>{column.Title}</text>
        }
    </ResizableTitle>);

    const doubleResizablTitle = (c, i, width, column) => (
        <Fragment key={`f${c + i}`}>
            <th key={`d${c + i}`} onClick={props.onSort ? props.onSort.bind(this, c) : null}>
                <text>{column.Title2}</text>
            </th>
            <ResizableTitle
                key={c + i}
                onResize={props.onResize}
                onResizeStop={props.onResizeStop}
                onResizeStart={props.onResizeStart}
                width={width}
                className='sheet-table-header'
                field={c}
                onClick={props.onSort ? props.onSort.bind(this, c) : null}
            >
            {props.onSort
                ? <wrap sort="">
                    <text>{column.Title}</text>
                    <div sort="">
                        <span className={props.sort.some(obj => obj.field === c && obj.direction === 'asc') ? 'select' : null}><icon>up</icon></span>
                        <span className={props.sort.some(obj => obj.field === c && obj.direction === 'desc') ? 'select' : null}><icon>down</icon></span>
                    </div>
                </wrap>
                : <text>{column.Title}</text>
            }
            </ResizableTitle>
        </Fragment>);

    const defTitle = (c, i, column) => (<th
        key={c + i}
        onClick={props.onSort ? props.onSort.bind(this, c) : null}
    >
        {props.onSort
            ? <wrap sort="">
                <text>{column.Title}</text>
                <div sort="">
                    <span className={props.sort.field === c && props.sort.direction === 'asc' ? 'select' : null}><icon>up</icon></span>
                    <span className={column.Sorted === 'descend' || (props.sort.field === c && props.sort.direction === 'desc') ? 'select' : null}><icon>down</icon></span>
                </div>
            </wrap>
            : <text>{column.Title}</text>
        }
    </th>);

    const doubleDefTitle = (c, i, column) => (<Fragment key={`f${c + i}`}>
        <th key={`d${c + i}`} onClick={props.onSort ? props.onSort.bind(this, c) : null}>
            <text>{column.Title2}</text>
        </th>
        <th key={c + i} onClick={props.onSort ? props.onSort.bind(this, c) : null}>
        {
            props.onSort
            ? <wrap sort="">
                <text>{column.Title}</text>
                <div sort="">
                    <span className={props.sort.field === c && props.sort.direction === 'asc' ? 'select' : null}><icon>up</icon></span>
                    <span className={column.Sorted === 'descend' || (props.sort.field === c && props.sort.direction === 'desc') ? 'select' : null}><icon>down</icon></span>
                </div>
            </wrap>
            : <text>{column.Title}</text>
        }
        </th>
    </Fragment>);

    return <>
        {props.colgroup &&
            <ColGroup
                columns={columns}
                resource={props.resource}
                action={props.action}
                draggable={props.draggable}
                selectable={props.selectable}
                resizable={props.resizable}
                tableWidth={props.tableWidth}
                addIndex={props.addIndex}
            />
        }

        

        {props.header !== false &&
            <thead>
                <tr>
                    {props.draggable ? <th></th> : null}
                    {props.selectable ? <th></th> : null}
                    {props.addIndex ? <th>Number</th> : null}
                    {Object.keys(columns).map((c, i) => {
                        const column = columns[c];
                        let width = column.Width;
                        if (profileColumns && profileColumns[c] && profileColumns[c].width) {
                            width = profileColumns[c].width;
                        }
                        if (column.IsAction) {
                            return (
                                <th action='' key={c + i}>
                                    <text>{column.Title}</text>
                                </th>
                            );
                        }
                        else {
                            return (props.resizable ?
                                (column.IsDouble ? doubleResizablTitle(c, i, width, column) : resizablTitle(c, i, width, column)) :
                                (column.IsDouble ? doubleDefTitle(c, i, column) : defTitle(c, i, column))
                            );
                        }
                    })}
                    {props.resizable && <th></th>}
                    {props.action && <th action=''>Actions</th>}
                </tr>
            </thead>
        }
    </>
};

const mapStateToProps = state => {
    return {
        profileSettings: state.auth.profileSettingsTemp
    }
};


const mapDispatchToProps = dispatch => {
    return {
        setColumnWidth: (resource, columns) => dispatch(setProfileColumnWidth(resource, columns)),
        saveColumnWidth: (resource, columns) => dispatch(saveProfileColumnWidth(resource, columns)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(SheetHeader));