import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { Radio, Input } from 'antd';
import Loading from '../../screen/loading';
import SpaceWarningModal from "./modalSpace";

const { TextArea } = Input;

const AcceptModal = ({ 
    setIsOpen,
    modalIsOpen,
    rejectModalIsOpen,
    generateCustomerOrder,
    resource,
    checkedList,
    generateCustomerOrderLoading,
    rejectCustomerOrderLoading,
    getCustomerOrderAdmin,
    total,
    orderGenerated,
    orderRejected,
    setCheckedList,
    filterByCustomer,
    rejectCustomerOrder,
    setRejectModalIsOpen,
    customerrderAdminList,
  }) => {

  const [batchValue, setBatchValue] = useState(3);
  const [rejectReasonText, setRejectReasonText] = useState('');

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: checkedList.length === 1 || rejectModalIsOpen ? '372px' : '580px',
      minHeight: '244px',
      borderRadius: '18px',
      padding: '0',
    },
  };

  const [warningSpaceModalIsOpen, setWarningSpaceModalIsOpen] = useState(false);

  const closeModal = () => {
    setIsOpen(false);
    setRejectModalIsOpen(false);
  }
  
  const acceptedLoadList = customerrderAdminList.filter(row => checkedList.includes(row.rowState.loadData.id));
  const spaceCountIsOver30 = acceptedLoadList.reduce((acc, load) => acc + load.rowState.loadData.space, 0) > 30;

  const handleConfirm = () => {
    if (batchValue === 1 && spaceCountIsOver30) {
      setWarningSpaceModalIsOpen(true);
    } else {
      setCheckedList([]);
      generateCustomerOrder(resource, 'generateorderitem', { ids: checkedList, batchType: batchValue });
      setRejectModalIsOpen(false);
    }
  };

  const handleReject = () => {
    setCheckedList([]);
    rejectCustomerOrder(resource, 'declinerequestedorder', { ids: checkedList, rejectReason: rejectReasonText });
  };

  useEffect(() => {
    if (!rejectCustomerOrderLoading && orderRejected) {
      setCheckedList([]);
      filterByCustomer(resource, null);
      setIsOpen(false);
      setRejectModalIsOpen(false);
      getCustomerOrderAdmin(resource);
    }
  }, [filterByCustomer, rejectCustomerOrderLoading, getCustomerOrderAdmin, resource, orderRejected, setCheckedList, setIsOpen, total, setRejectModalIsOpen]);

  useEffect(() => {
    if (!generateCustomerOrderLoading && orderGenerated) {
      setCheckedList([]);
      filterByCustomer(resource, null);
      setIsOpen(false);
      setRejectModalIsOpen(false);
      getCustomerOrderAdmin(resource);
    }
  }, [filterByCustomer, generateCustomerOrderLoading, getCustomerOrderAdmin, resource, orderGenerated, setCheckedList, setIsOpen, total, setRejectModalIsOpen]);

  if (generateCustomerOrderLoading || rejectCustomerOrderLoading) {
    return (
      <Modal
        isOpen={modalIsOpen}
        style={customStyles}
        >
        <div style={{ marginTop: checkedList.length === 1 ? '20%' : '12%'}}>
          <Loading />
        </div>
      </Modal>
    )
  };

  if (warningSpaceModalIsOpen) {
    return (
      <SpaceWarningModal
          warningSpaceModalIsOpen={warningSpaceModalIsOpen}
          setWarningSpaceModalIsOpen={setWarningSpaceModalIsOpen}
      />
  )}

  const onChange = e => {
    setBatchValue(e.target.value);
  };

  return (
    rejectModalIsOpen ?
      <Modal
        isOpen={modalIsOpen}
        rejectModalIsOpen={rejectModalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        ariaHideApp={false}
        >
        <div className='accept_modal__container'>
          <button onClick={closeModal} className='accept__x-button'>
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10 0C4.47 0 0 4.47 0 10C0 15.53 4.47 20 10 20C15.53 20 20 15.53 20 10C20 4.47 15.53 0 10 0ZM14.3 14.3C13.91 14.69 13.28 14.69 12.89 14.3L10 11.41L7.11 14.3C6.72 14.69 6.09 14.69 5.7 14.3C5.31 13.91 5.31 13.28 5.7 12.89L8.59 10L5.7 7.11C5.31 6.72 5.31 6.09 5.7 5.7C6.09 5.31 6.72 5.31 7.11 5.7L10 8.59L12.89 5.7C13.28 5.31 13.91 5.31 14.3 5.7C14.69 6.09 14.69 6.72 14.3 7.11L11.41 10L14.3 12.89C14.68 13.27 14.68 13.91 14.3 14.3Z" fill="#678482"/>
              </svg>
        </button>
          <div className='accept_modal_text reject-modal__text'>{checkedList.length > 1 ? 'Reject Orders ?' : 'Reject Order ?'}</div>
          <TextArea
            placeholder="Reject reason"
            autoSize={{ minRows: 2, maxRows: 6 }}
            className='reject-reason__input'
            status={"error"}
            onChange={e => setRejectReasonText(e.target.value)}
          />
          <div className='buttons-container__reject-modal'>
            <button className='cancel-modal-button' type="submit" onClick={closeModal}>CANCEL</button>
            <button className={`accept-modal-button ${!rejectReasonText && 'reject-btn_disabled'}`} type="submit" disabled={!rejectReasonText} onClick={handleReject}>CONFIRM</button>
          </div>
        </div>  
    </Modal> :
    checkedList.length === 1 && !rejectModalIsOpen
    ?
    <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        ariaHideApp={false}
        >
        <div className='accept_modal__container'>
          <button onClick={() => setIsOpen(false)} className='accept__x-button'>
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10 0C4.47 0 0 4.47 0 10C0 15.53 4.47 20 10 20C15.53 20 20 15.53 20 10C20 4.47 15.53 0 10 0ZM14.3 14.3C13.91 14.69 13.28 14.69 12.89 14.3L10 11.41L7.11 14.3C6.72 14.69 6.09 14.69 5.7 14.3C5.31 13.91 5.31 13.28 5.7 12.89L8.59 10L5.7 7.11C5.31 6.72 5.31 6.09 5.7 5.7C6.09 5.31 6.72 5.31 7.11 5.7L10 8.59L12.89 5.7C13.28 5.31 13.91 5.31 14.3 5.7C14.69 6.09 14.69 6.72 14.3 7.11L11.41 10L14.3 12.89C14.68 13.27 14.68 13.91 14.3 14.3Z" fill="#678482"/>
              </svg>
            </button>
          <div className='accept_modal_text'>Accept Order ?</div>
          <div>
            <button className='cancel-modal-button' type="submit" onClick={closeModal}>CANCEL</button>
            <button className='accept-modal-button' type="submit" onClick={handleConfirm}>CONFIRM</button>
          </div>
        </div>  
    </Modal> :
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      ariaHideApp={false}
      >
      <div className='accept_modal__container'>
        <button onClick={() => setIsOpen(false)} className='accept-grouping__x-button'>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10 0C4.47 0 0 4.47 0 10C0 15.53 4.47 20 10 20C15.53 20 20 15.53 20 10C20 4.47 15.53 0 10 0ZM14.3 14.3C13.91 14.69 13.28 14.69 12.89 14.3L10 11.41L7.11 14.3C6.72 14.69 6.09 14.69 5.7 14.3C5.31 13.91 5.31 13.28 5.7 12.89L8.59 10L5.7 7.11C5.31 6.72 5.31 6.09 5.7 5.7C6.09 5.31 6.72 5.31 7.11 5.7L10 8.59L12.89 5.7C13.28 5.31 13.91 5.31 14.3 5.7C14.69 6.09 14.69 6.72 14.3 7.11L11.41 10L14.3 12.89C14.68 13.27 14.68 13.91 14.3 14.3Z" fill="#678482"/>
            </svg>
          </button>
        <div className='accept-grouping-title__modal-text'>Grouping Order</div>
        <div className='accept-grouping-subTitle__modal-text'>Choose batch type</div>
        <Radio.Group className='customerOrderAdin-modal__radio-batch' onChange={onChange} value={batchValue}>
          <Radio value={2}>Soft Batch</Radio>
          <Radio value={1}>Hard Batch</Radio>
        </Radio.Group>
        <div className='customerOrderAdmin__button-container'>
          <button className='cancel-modal-button grouping-cancel__modal-button' type="submit" onClick={closeModal}>CANCEL</button>
          <button className='accept-modal-button' type="submit" onClick={handleConfirm}>CONFIRM</button>
        </div>
      </div>  
    </Modal>
  );
};

export default AcceptModal;