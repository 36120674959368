import React from 'react';
import { Tooltip } from 'antd';
import Scroll from "../../scroll/scroll";
import Settings from "../../list/settings";

import HideShowMenuSwitch from '../../layout/hideShowMenuSwitch';

const GeneralToolbar = props => {
    const { assignedOrders,
        getOrderConfig,
        getLoadResource,
        load
    } = props;

    return (<toolbar scroll="">
        <Scroll>
            <wrap>
                <action>
                    <wrap>
                        <Settings screen={assignedOrders} columns={getOrderConfig().List.Columns} />
                    </wrap>
                    <separator vertical=""></separator>
                    {getLoadResource().ShowRefreshButton ? (
                        <>
                            <Tooltip title="Refresh Orders">
                                <div
                                    className="button accent"
                                    onClick={load}
                                >
                                    <text>Refresh</text>
                                </div>
                            </Tooltip>
                        </>
                    ) : null}
                    {/*<Download*/}
                    {/*    screen={truckConfig.Resource}*/}
                    {/*    columns={truckConfig.List.Columns}*/}
                    {/*    filter={criteria().filter}*/}
                    {/*/>*/}
                </action>
            </wrap>
        </Scroll>
        { !props.isHiddenSwitch && <HideShowMenuSwitch />}
    </toolbar>);
};

export default GeneralToolbar;