import React, { useContext, useState } from 'react';
import { Tooltip, message } from 'antd';

import { GridContext } from '../list';
import axios from '../../../axios/axios';
import { CommonLabels } from '../../../../project/Defines';
import Loading from '../../screen/loading';

const ExportToGSheet = () => {
    const [isLoading, setIsLoading] = useState(false);
    const data = useContext(GridContext);

    const exportToGsheet = () => {
        setIsLoading(true);
        axios.post(`${data.screen}/exporttogsheet`).then((response, err) => {
            setIsLoading(false);
            if (response.data?.hasError) {
                message.error(response.data.message, 5);
                return;
            }
            message.success(response.data?.message, 5);
        });
    };

    return (
        <>
            <Tooltip title={CommonLabels.Buttons.Export}>
                <div className='button accent' onClick={exportToGsheet}><text>{CommonLabels.Buttons.Export}</text></div>
            </Tooltip>
            {isLoading ? <div className='gsheet-action-loading'>
                <Loading />
            </div> : null}
        </>
    )


};

export default ExportToGSheet;
