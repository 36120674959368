export const getEfsColor = status => {
    let cellStyle = {};
    switch (status) {
        case 1:
            cellStyle = { color: '#E71717', fontWeight: 'bold' };
            break;
        case 2:
            cellStyle = { color: '#0C78B5', fontWeight: 'bold' };
            break;
        case 3:
            cellStyle = { color: '#000', fontWeight: 'bold' };
            break;
        case 4:
            cellStyle = { color: '#67A7A5', fontWeight: 'bold' };
            break;
        default:
            break;
    }
    return cellStyle;
};

export const getEfsBGColor = status => {
    let cellStyle = {};
    switch (status) {
        case 1:
            cellStyle = { backgroundColor: '#E71717', fontWeight: 'bold' };
            break;
        case 2:
            cellStyle = { backgroundColor: '#0C78B5', fontWeight: 'bold' };
            break;
        case 3:
            cellStyle = { backgroundColor: '#000', fontWeight: 'bold' };
            break;
        case 4:
            cellStyle = { backgroundColor: '#67A7A5', fontWeight: 'bold' };
            break;
        default:
            break;
    }
    return cellStyle;
};

export const getEfsStatusTooltip = status => {
    switch (status) {
        case 1:
            return "New Request";
        case 2:
            return "Issued";
        case 3:
            return "Rejected";
        case 4:
            return "Sent";
        default:
            return null;
    }
};