export const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '400px',
    minHeight: '200px',
    borderRadius: '8px',
    padding: 0,
  },
};

export const customStyleLoadDetailsPage = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '968px',
    minHeight: '600px',
    borderRadius: '8px',
    padding: '0',
    width: '90%',
    maxWidth: '968px',
    height: '-webkit-fill-available',
    overflowY: 'scroll',
  },
};

export const customStylesLoadDetailsPage = {
  control: (base, state) => ({
    ...base,
    borderColor: state.isFocused ? 'lightGray' : 'lightGray',
    borderRadius: '8px',
    boxShadow: 'none',
    minHeight: '37px',
    width: '100%',
    cursor: 'notAllowed',
  }),
  menu: (base, state) => ({
    ...base,
    zIndex: '20',
  }),
  menuList: () => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    borderRadius: 'none!important',
  }),
  valueContainer: (base, state) => ({
    display: 'flex',
    alignItems: 'self-end',
    paddingLeft: '5px',
    maxWidth: '224px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  }),
  container: (base, state) => ({
    ...base,
    pointerEvents: 'auto',
  }),
  singleValue: (base, state) => ({
    maxWidth: '224px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  }),
  options: (base, state) => ({
    padding: '4px 8px',
    cursor: 'pointer',
    borderRadius: 'unset',
  }),
};