import moment from 'moment';

export const ArrayEquals = (a, b) =>
        Array.isArray(a) &&
            Array.isArray(b) &&
            a.length === b.length &&
    a.every((val, index) => val === b[index]);

export const AddMonths = (date, months) => {
    var d = date.getDate();
    date.setMonth(date.getMonth() + +months);
    if (date.getDate() !== d) {
        date.setDate(0);
    }
    return date;
};

export const CloneObject = (inputO, exceptList) => {
    let outputO = null;
    if (Array.isArray(inputO)) {
        outputO = [];
        for (let i in inputO) {
            outputO.push(CloneObject(inputO[i]));
        }
    }
    else if (typeof (inputO) === 'object') {
        if (inputO) {
            outputO = {};
            for (let k in Object.keys(inputO)) {
                const ok = Object.keys(inputO)[k];
                const o = inputO[ok];
                outputO[ok] = CloneObject(o);
            }
        }
    }
    else {
        outputO = inputO;
    }
    return outputO;
};

export const CloneTopObject = (inputO) => {
    let outputO = null;
    if (typeof (inputO) === 'object') {
        if (inputO) {
            outputO = {};
            for (let k in Object.keys(inputO)) {
                const ok = Object.keys(inputO)[k];
                const o = inputO[ok];
                outputO[ok] = o;
            }
        }
    }

    return outputO;
};

export const CreateGuid = () => {
    function _p8(s) {
        var p = (Math.random().toString(16) + "000000000").substr(2, 8);
        return s ? "-" + p.substr(0, 4) + "-" + p.substr(4, 4) : p;
    }
    return _p8() + _p8(true) + _p8(true) + _p8();
};

export const FindParentValue = (object, parentValueType) => {
    if (object && parentValueType) {
        return object[parentValueType];
    }
    return '';
};

export const FindSelectedValueText = FindParentValue;

export const Compare = (val1, val2, op) => {
    // val1 = +val1;
    // val2 = +val2;
    
    if(op === '===') {
        return val1 === val2;
    } else if(op === '==') {
        return val1 === val2;
    } else if(op === '!==') {
        return val1 !== val2;
    } else if(op === '!=') {
        return val1 !== val2;
    } else if(op === '>') {
        return val1 > val2;
    } else if(op === '>=') {
        return val1 >= val2;
    } else if(op === '<') {
        return val1 < val2;
    } else if(op === '<=') {
        return val1 <= val2;
    }
};

export const NullOrUndefined = data => data !== null && data !== undefined;

export const ArraySort = (data, propName) => data.sort(function(a, b) { return a[propName] - b[propName]; });

export const ArraySum = (data, propName) => {
    if (!data.length) {
        return 0;
    }

    if (propName) {
        return data.reduce(function (sum, item) { return sum + (item[propName] ? +item[propName] : 0); }, 0);
    } else {
        return data.reduce(function (sum, item) { return sum + +item; });
    }
}

export const ArrayGroup = (data, propName) => {
    var groupNames = [],
        groups = [];

    if (!data) {
        return [];
    }

    data.forEach(function(item) {
        var groupName = item[propName],
            index = groupNames.indexOf(groupName);

        if(index === -1) {
            groupNames.push(groupName);
            index = groups.push({ name: groupName, items: [] }) - 1;
        }

        groups[index].items.push(item);
    });

    return groups;
}

export const ParseJson = str => {
    try {
        return JSON.parse(str);
    }
    catch{
        return null;
    }
};

export const HexToRGB = (h) => {
    let r = 0, g = 0, b = 0;
    // 3 digits
    if (h.length === 4) {
        r = `0x${h[1]}${h[1]}`;
        g = `0x${h[2]}${h[2]}`;
        b = `0x${h[3]}${h[3]}`;
        // 6 digits
    } else if (h.length === 7) {
        r = `0x${h[1]}${h[2]}`;
        g = `0x${h[3]}${h[4]}`;
        b = `0x${h[5]}${h[6]}`;
    }
    return [+r, +g, +b];
};

export const InvertColor = (rgbaFormat, bw) => {
    const rgba = RGBAFormatToRGB(rgbaFormat);
    if (bw) {
        return (+rgba[0] * 0.299 + +rgba[1] * 0.587 + +rgba[2] * 0.114) > 186 || +rgba[3] < 0.6
            ? 'rgba(0, 0, 0, 1)'
            : 'rgba(255, 255, 255, 1)';
    }
    const invertRgb = [255 - +rgba[0], 255 - +rgba[1], 255 - +rgba[2]];
    return `rgba(${invertRgb[0]}, ${invertRgb[1]}, ${invertRgb[2]}, 1)`;
};

export const HexToRGBAFormat = (h, alpha) => {
    const rgb = HexToRGB(h);
    return `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, ${alpha / 100})`;
};

export const RGBAFormatToRGB = rgba => {
    const sep = rgba.indexOf(',') > -1 ? ',' : ' ';
    rgba = rgba.substr(5).split(')')[0].split(sep);
    if (rgba.indexOf('/') > -1) {
        rgba.splice(3, 1);
    }
    for (const R in rgba) {
        const r = rgba[R];
        if (r.indexOf('%') > -1) {
            let p = r.substr(0, r.length - 1) / 100;
            if (R < 3) {
                rgba[R] = Math.round(p * 255);
            } else {
                rgba[R] = p;
            }
        }
    }
    return rgba;
};

export const RGBAToHexA = rgba => {
    if (!rgba) {
        return ['#FFFFFF', 100];
    }
    if (rgba.indexOf('#') > -1) {
        return [rgba, 100];
    }
    rgba = RGBAFormatToRGB(rgba);
    let r = (+rgba[0]).toString(16),
        g = (+rgba[1]).toString(16),
        b = (+rgba[2]).toString(16);
    if (r.length === 1) {
        r = '0' + r;
    }
    if (g.length === 1) {
        g = '0' + g;
    }
    if (b.length === 1) {
        b = '0' + b;
    }
    return [`#${r}${g}${b}`, +rgba[3] * 100];
};

export const FunctionArgumentNames = func => {
    const str = func.toString().replace(/\/\*[\s\S]*?\*\//g, '')
        .replace(/\/\/(.)*/g, '')
        //.replace(/{[\s\S]*}/, '')
        .replace(/=>/g, '')
        .trim();
    const result = str.slice(str.indexOf('(') + 1, str.indexOf(')')).split(", ");
    const params = [];
    result.forEach(element => {
        element = element.replace(/=[\s\S]*/g, '').trim();
        if (element.length > 0) {
            params.push(element);
        }
    });
    return params;
}

export const formatPhoneNumber = (str) => {
    //Filter only numbers from the input
    let cleaned = ('' + str).replace(/\D/g, '');

    //Check if the input is of correct
    let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

    if (match) {
      //Remove the matched extension code
      //Change this to format for any country code.
        return [match[2], '-', match[3], '-', match[4]].join('')
    }
    return str;
};

export const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

export const localDate = (date, format) => {
    return date ? moment.utc(date).local().utc(true).format(format) : ''
};

export const getContentWidth = headers => {
    return ((headers && headers.length > 0) ?
        headers.reduce((a, b) => a + b.width, 0) :
        0);
};