import { useJsApiLoader } from '@react-google-maps/api';

export const GetPlace = (place, action) => {    
    const placesAutocompleteService = new window.google.maps.places.AutocompleteService();
    const placesService = new window.google.maps.places.PlacesService(document.createElement('div'));
    
    if (place.geometry) {
        locationHandler(place, action);
    } else {
        placesAutocompleteService.getPlacePredictions({
            input: place.name,
            componentRestrictions: { country: ["us", "ca", "mx"] }
        }, function (result, status) {
            if (result && result.length) {
                placesService.getDetails({ placeId: result[0].place_id }, function (result, status) {
                    if (result) {
                        locationHandler(result, action);
                    }
                });
            }
        });
    }

    return null;
};

export const locationHandler = (place, action) => {
    if (place && action) {
        let latValue = place.geometry.location.lat,
            lngValue = place.geometry.location.lng;
        if (typeof (latValue) === 'function') {
            latValue = latValue();
        }
        if (typeof (lngValue) === 'function') {
            lngValue = lngValue();
        }
        const _addressComponents = {
            value: place.formatted_address,
            country: '',
            stateCode: '',
            city: '',
            street: '',
            streetNumber: '',
            countryId: 0,
            zip: '',
            lat: latValue,
            lng: lngValue
        };
        for (let ac in place.address_components) {
            const addressComponent = place.address_components[ac];
            if (addressComponent.types.indexOf('country') !== -1) {
                _addressComponents.country = addressComponent.short_name;
            }
            if (addressComponent.types.indexOf('administrative_area_level_1') !== -1) {
                _addressComponents.stateCode = addressComponent.short_name;
            }
            if (addressComponent.types.indexOf('locality') !== -1) {
                _addressComponents.city = addressComponent.long_name;
            }
            if (addressComponent.types.indexOf('route') !== -1) {
                _addressComponents.street = addressComponent.long_name;
            }
            if (addressComponent.types.indexOf('street_number') !== -1) {
                _addressComponents.streetNumber = addressComponent.short_name;
            }
            if (addressComponent.types.indexOf('postal_code') !== -1) {
                _addressComponents.zip = addressComponent.short_name;
            }
        }
        const counryIds = {
            CA: 40,
            MX: 143,
            US: 234
        };
        _addressComponents.countryId = counryIds[_addressComponents.country];
        const outeSource = [];
        outeSource.push(_addressComponents);
        action(
            {
                nativeEvent: null,
                source: outeSource,
                target: {
                    value: _addressComponents.value,
                    type: 'AddressField'
                }
            });
    }
};

const libraries = ['places'];

export const useGoogleApiLoader = googleApiKey => useJsApiLoader({
    libraries,
    id: 'google-map-script',
    googleMapsApiKey: `${googleApiKey}&loading=async`,
});