import React from 'react';
import { connect } from "react-redux";

import { openPopup, closePopup } from '../../store/actions/popup/popup';
import { PopupTypes } from '../components/custom/popup/popup';
import axios from '../axios/axios';
import { ElementConditions } from '../config/condition.config';
import { GenerateToolBars } from '../config/element.config';
import { CreateGuid, CloneObject } from '../helper/common';
import { useValueListWithCache } from '../hook/data/useValueList';
import { addValueList } from '../../store/actions/valueList/valueList';
import DropDownElement from './dropDownElement';
import DropDownNewItem from './dropDownNewItem';

// import { useCacheHub } from '../hook/hub/useClientHub';

const ComboAddWithCache = props => {
    const { firstTimeAction, data, dataSource, classes, condition, action,
        valudationMessages, style, parentValue, selectedValue, addparams,
        openPopup, closePopup, reloadOnParentChange, valueList, addValueList, mode } = props;

    const hash = require('object-hash');

    const valueKey = typeof dataSource === 'string' ? dataSource + (parentValue ? parentValue : '') : hash(dataSource);

    useValueListWithCache(data, dataSource, parentValue, addValueList, valueList, reloadOnParentChange, valueKey);

    const windowKey = CreateGuid();
    const config = CloneObject(addparams.PopupConfig);
    const title = `Add ${addparams.TitlePrefix ? addparams.TitlePrefix : ''}${config.Title}`;
    const actionValueName = config.ValueField || addparams.ValueField || 'id';

    const onAddClickHandler = () => {
        document.getElementById(windowKey).blur();
        config.Form.DefaultId = 0;
        config.Form.ToolBars = GenerateToolBars(['SaveClose', 'Cancel']);
        openPopup({
            windowKey,
            fullScreen: false,
            title: title,
            type: PopupTypes.ConfigForm,
            bodyProps: { windowKey },
            config: config,
            saveAction: (objectResult) => {
                closePopup(windowKey);
                axios({
                    method: 'GET',
                    baseURL: dataSource + (parentValue ? parentValue : '')
                })
                    .then(response => {
                        if (response) {
                            const result = response.data;
                            if (result) {
                                const newList = result.filter(i => (!i.archived && !i.archiveDate && !i.inactive) || result === i.value);
                                addValueList(valueKey, newList);
                                action(
                                    {
                                        nativeEvent: null,
                                        source: newList,
                                        target: {
                                            value: objectResult[actionValueName],
                                            type: (mode === 'multiple' ? 'multiple_select' : 'select')
                                        }
                                    });
                            }
                        }
                    }).catch(error => {
                        console.error(error);
                    });

            },
            closeAction: () => {
                closePopup(windowKey);
            }
        });
    };

    return condition !== ElementConditions.Hidden &&
        valueList[valueKey] ?
        <DropDownElement
            firstTimeAction={firstTimeAction}
            mode={mode}
            showSearch={true}
            data={data}
            classes={classes}
            valudationMessages={valudationMessages}
            style={style}
            condition={condition}
            id={windowKey}
            dropdownRender={menu => <DropDownNewItem menu={menu} onAddClickHandler={onAddClickHandler} title={title} />}
            valueList={valueList[valueKey]}
            action={action}
            selectedValue={selectedValue}
        /> : null;




    //return condition !== ElementConditions.Hidden &&
    //    valueList[valueKey] ?
    //    (<Select
    //        {...restprops}
    //        showSearch
    //        value={restprops.mode === 'multiple' ? (!data || data.trim() === '' ? [] : data.split(',').map(Number)) : ((data !== null && data !== undefined) ? data : '')}
    //        className={CssErrorClassHandling(ValidationErrorClass, classes, valudationMessages).join(' ')}
    //        style={style}
    //        disabled={!condition}
    //        id={windowKey}
    //        dropdownRender={menu => {
    //            return <React.Fragment>
    //                {menu}
    //                <Divider style={{ margin: '4px 0' }} />
    //                <span
    //                    style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer', backgroundColor: '#029b96', color: '#fff' }}
    //                    onClick={onAddClickHandler}>
    //                    <PlusOutlined /> {title}
    //                </span>
    //            </React.Fragment>
    //        }}
    //        onChange={
    //            (value, event) => action(
    //                {
    //                    nativeEvent: event,
    //                    source: valueList[valueKey],
    //                    target: {
    //                        value: value,
    //                        type: (restprops.mode === 'multiple' ? 'multiple_select' : 'select')
    //                    }
    //                })}
    //        optionFilterProp="children"
    //        filterOption={(input, option) => {
    //            let item = option.children;
    //            if (typeof item === 'object') {
    //                item = item.props.children;
    //            }
    //            return item.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    //        }
    //        }>
    //        {
    //            valueList[valueKey].map((item, i) =>
    //                <Option key={i} value={item.value} text={item.text}>
    //                    {item.favourite ? <b>{item.text}</b> : item.text}
    //                </Option>
    //            )
    //        }
    //    </Select>) : null;

};

const mapStateToProps = state => {
    return {
        valueList: state.valueList.list
    }
};

const mapDispatchToProps = dispatch => {
    return {
        openPopup: (data) => dispatch(openPopup(data)),
        closePopup: (key) => dispatch(closePopup(key)),
        addValueList: (valueKey, valueList) => dispatch(addValueList(valueKey, valueList))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ComboAddWithCache);