import React, { useState } from 'react';


import { CommonLabels } from '../../../../project/Defines';

const Switchable = props => {
    const [show, setShow] = useState(!props.hide);

    return (
        <switchable
            overlay=""
            rounded={props.rounded && ""}
            micro=""
            className={show ? "" : "hide"}>
            {!props.left && props.children}
            <switch onClick={() => setShow(!show)}>
                <icon>grid</icon>
                <wrap>
                    <text>
                        <tip><span>{show ? 'Collapse' : 'Expande'}</span><b>·</b></tip>
                        {props.name || CommonLabels.Text.Filters}
                    </text>
                </wrap>
            </switch>
            {props.left && props.children}
        </switchable>);
};

export default Switchable;