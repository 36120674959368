import React from 'react';
import { Resizable } from 'react-resizable';
import { ColumnType } from '../../config/list.config';

const ResizableTitle = props => {
    const { onResize, onResizeStop, onResizeStart, width, field, column, className, ...restProps } = props;

    if (!width || (column && column.Type === ColumnType.Empty)) {
        return <th {...restProps} />;
    }
    return (
        <Resizable
            width={width}
            height={0}
            handle={
                <span
                    className="react-resizable-handle"
                    onClick={e => {
                        e.stopPropagation();
                    }}
                />
            }
            onResizeStart={(e, s) => onResizeStart(s, field, e)}
            onResize={(e, s) => onResize(s, field, e)}
            onResizeStop={(e, s) => onResizeStop(s, field, e)}
            draggableOpts={{ enableUserSelectHack: false }}
        >
            <th className={className ? className : 'ant-table-header'} {...restProps} />
        </Resizable>
    );
};

export default ResizableTitle;