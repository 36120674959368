import { Stop } from "../stop";
import { ArraySum } from '../../../../helper/common';
import { BaseData } from '../baseData';

export class Data extends BaseData {
    static createKey(order) {
        let createKey = Data.defaultKey;
        if (order.outboundLoadId) {
            //createKey = `${order.outboundLoadId}_${order.customerId}_${order.dropLocationId}`;
            createKey = `${order.outboundLoadId}_${order.dropLocationId}`;
        }
        else if (order.receiveDate && !order.warehouseId && order.loadId) {
            createKey = `${order.loadId}_${order.customerId}_${order.dropLocationId}`;
            //createKey = `${order.loadId}_${order.dropLocationId}`;
        }
        return createKey;
    };

    constructor(list, loads) {
        super();
        this.list = list;
        this.group = this.list.reduce((r, o) => {
            const groupBy = Data.createKey(o);
            r[groupBy] = [...r[groupBy] || [], o];
            return r;
        }, {});

        Object.keys(this.group).forEach(go => {
            const oG = this.group[go];
            const stop = new Stop();
            if (oG.length > 0) {
                const firstO = oG[0];
                
                let load = loads.find(l => l.id === firstO.outboundLoadId);

                if (!load && firstO.receiveDate && !firstO.warehouseId) {
                    load = loads.find(l => l.id === firstO.loadId);
                    if (!load) {
                        //load not exits for this screen
                        return;
                    }
                    stop.id = stop.key = go;
                    stop.isFirst = this.stops.find(s => s.loadId === firstO.loadId) === undefined;
                    stop.customer = firstO.customerCodeOrName;
                    stop.customerTooltip = firstO.customerCodeOrName;
                    stop.loadNumber = firstO.inboundLoadNumber;
                    stop.loadId = firstO.loadId;
                    stop.customerId = firstO.customerId;

                    stop.pickLocationId = firstO.pickLocationId;
                    stop.pickAppointment = firstO.inboundPickAppointment;
                    stop.pickStatus = firstO.inbioundAppointmentStatus;
                    stop.pickCity = firstO.pickCity;
                    stop.pickState = firstO.pickState;
                    stop.pickWarehouse = firstO.pickLocationName;

                    stop.dropLocationId = firstO.dropLocationId;
                    stop.dropAppointment = firstO.inboundDropAppointment;
                    stop.dropStatus = firstO.dropInboundAppointmentStatus;
                    stop.dropCity = firstO.warehouse ? firstO.drop1City : firstO.drop2City;
                    stop.dropState = firstO.warehouse ? firstO.drop1State : firstO.drop2State;
                    stop.dropWarehouse = firstO.warehouse ? firstO.drop1WH : firstO.drop2WH;

                    stop.isWarehouse = firstO.warehouse;
                    stop.warehouseId = firstO.warehouseId;
                    stop.sequencesName = firstO.inboundLoadingSequenceName;
                    stop.inboundStopNotes = firstO.inboundStopNotes;

                    stop.in = firstO.outboundIn;
                    stop.out = firstO.outboundOut;

                    stop.color = load.color;
                    stop.truckNumber = load.truckNumber;
                    stop.driverName = load.driverName;
                    stop.driverPhone = load.driverPhone;
                    stop.rate = load.rate;
                    stop.carrierName = load.carrierName;
                    stop.trailer = load.trailer;
                    stop.truckId = load.truckId;
                    stop.carrierId = load.carrierId;

                    stop.readyToBook = load.readyToBook;
                    stop.inTransit = load.inTransit;
                    stop.isInbound = true;

                    stop.orders = oG;
                    this.stops.push(stop);
                    return;
                }
                if (!load) {
                    //load not exits for this screen
                    return;
                }
                stop.id = stop.key = go;
                stop.isFirst = this.stops.find(s => s.loadId === firstO.outboundLoadId) === undefined;
                const custList = oG.reduce((r, o) => {
                    const groupBy = o.customerCodeOrName;
                    r[groupBy] = [...r[groupBy] || [], o];
                    return r;
                }, {});
                stop.customer = Object.keys(custList).length;// firstO.customerCodeOrName;
                stop.customerTooltip = Object.keys(custList).join(', ');
                stop.loadNumber = firstO.outboundLoadNumber;
                stop.loadId = firstO.outboundLoadId;
                stop.customerId = firstO.customerId;

                stop.pickLocationId = firstO.pickLocationId;//???
                stop.pickAppointment = firstO.outboundPickAppointment;
                stop.pickStatus = firstO.pickOutboundAppointmentStatus;
                stop.pickCity = firstO.drop1City;
                stop.pickState = firstO.drop1State;
                stop.pickWarehouse = firstO.drop1WH;

                stop.dropLocationId = firstO.dropLocationId;
                stop.dropAppointment = firstO.outboundDropAppointment;
                stop.dropStatus = firstO.outboundAppointmentStatus;
                stop.dropCity = firstO.drop2City;
                stop.dropState = firstO.drop2State;
                stop.dropWarehouse = firstO.drop2WH;

                stop.isWarehouse = firstO.warehouse;
                stop.warehouseId = firstO.warehouseId;
                stop.sequencesName = firstO.outboundLoadingSequenceName;
                stop.outboundStopNotes = firstO.outboundStopNotes;

                stop.in = firstO.outboundIn;
                stop.out = firstO.outboundOut;

                stop.color = load.color;

                stop.truckNumber = load.truckNumber;
                stop.driverName = load.driverName;
                stop.driverPhone = load.driverPhone;
                stop.rate = load.rate;
                stop.carrierName = load.carrierName;
                stop.trailer = load.trailer;
                stop.truckId = load.truckId;
                stop.carrierId = load.carrierId;

                stop.readyToBook = load.readyToBook;
                stop.inTransit = load.inTransit;

                stop.orders = oG;
                this.stops.push(stop);
            }
        });
        this.stops.forEach(s => {
            s.loadTotalPallets = ArraySum(this.stops.filter(si => si.loadId === s.loadId), 'totalPallets');
            s.loadTotalWeight = ArraySum(this.stops.filter(si => si.loadId === s.loadId), 'totalWeight');
        });

        const firstStops = this.stops.filter(s => s.isFirst);
        loads.forEach(l => {
            if (!firstStops.find(fs => fs.loadId === l.id)) {
                const newStop = new Stop();
                newStop.key = `${l.id}_empty`;
                newStop.isEmpty = true;
                newStop.isFirst = true;
                newStop.loadId = l.id;
                newStop.loadNumber = l.loadNumber;
                newStop.truckNumber = l.truckNumber;
                newStop.driverName = l.driverName;
                newStop.driverPhone = l.driverPhone;
                newStop.rate = l.outboundRate;
                newStop.carrierName = l.carrierName; //shoukld add in backend
                newStop.color = l.color;
                this.stops.push(newStop);
            }
        });
    }
}