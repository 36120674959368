export const getComments = (state, response, hasError = false, message = '') => {
    const data = response.data || [];
    return {
        ...state, ...{
            comments: data.comments,
            attachments: data.attachments,
            loading: false,
            hasError,
            message,
        }    
    }
};

export const setComment = (state, comment) => {
    return {
        ...state, ...{
            comments: [...state.comments, comment],
        }    
    }
};

export const setError = (state, err) => ({
    ...state,
    ...{
        hasError: true,
        message: 'Something went wrong, please try again',
    },
});

export const resetError = state => ({
    ...state,
    ...{
        hasError: false,
        message: '',
    }
});

export const setInLoading = state => ({
    ...state, ...{
        loading: true
    }
});
