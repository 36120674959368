import { ValidateElement } from '../../helper/validation';
import { DefaultMessageTypes } from '../actions'

export const useValidation = (messageDispatch, validations, title, messageIdentity, object, warnings) => {
    if (!messageDispatch) {
        return null;
    }
    const validate = (data) => {
        messageDispatch({ type: DefaultMessageTypes.START_FOCUSE, messages: false });
        if (validations && validations.length > 0) {
            const invalMessages = ValidateElement(validations, data, title, object);
            messageDispatch({ type: messageIdentity, messages: invalMessages });
        }
        if (warnings && warnings.length > 0) {
            const invalMessages = ValidateElement(warnings, data, title, object);
            messageDispatch({ type: messageIdentity, messages: invalMessages, messageType: 'warning' });
        }
    };
    return validate;
}