import React, { useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import CustomerPriceSpreadsheet from '../../CustomerPrice/spreadsheet';
import { getCustomersList } from '../../../../../store/actions/customers/customers';
import './index.css';

const CustomerOrderAdminBuild = props => {
  const { getCustomersList, customersList } = props;
  const resource = 'customer';
  const query = props.location.search;
  const customerId = query.substring(4);
  const customerName = customersList?.data?.filter(customer => Number(customer.id) === Number(customerId))[0]?.name || customersList?.name;

  useEffect(() => {
    getCustomersList(resource, '');
  }, [getCustomersList]);

  return (
    <div
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        margin: '0 12px 6px',
        alignSelf: 'center',
        width: '100%',
      }}
      className="pane"
      >
      <div className='price__main-container'>
        <header style={{ display: 'flex'}}>
          <Link className='price__left-btn' to={'/loadplanning/customer'}>
            <icon>left</icon>
          </Link>
          <div className='price-page__vertical-line'></div>
          {customerName ? <span className='customer-number'>Customer {customerName}</span> : ''}
        </header>
        <div style={{margin: '24px 0 9px'}}>
          <Link className='customer-text' to={`/loadplanning/customerdetails/${customerId}`}>Customer Details</Link>
          <span className='price-text'>Price</span>
        </div>
      </div>
      <div>
        <hr className='price-page__hr' />
      </div>
      <CustomerPriceSpreadsheet {...props} customerId={customerId} />
    </div>
  );
};

const mapStateToProps = state => ({
  state: state,
  customersList: state.customers.customersList,
  loading: state.customers.loading,
});

const mapDispatchToProps = (dispatch) => ({
  getCustomersList: (resource, endPoint) => dispatch(getCustomersList(resource, endPoint)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomerOrderAdminBuild));
