import React, { useEffect } from 'react';
import { connect } from "react-redux";
import { message, Tooltip } from 'antd';

import HideShowMenuSwitch from '../../layout/hideShowMenuSwitch';

import axios from '../../../axios/axios';
import { openPopup } from "../../../../store/actions/popup/popup";
import { saveScreenSwap } from '../../../../store/actions/authentication/authentication';
import Scroll from "../../scroll/scroll";
import Settings from "../../list/settings";
import { CommonLabels } from "../../../../project/Defines";
import { PopupTypes } from "../../custom/popup/popup";

const LoadCreationToolbar = props => {
    const { mainResource,
        getOrderConfig,
        saveScreenSwap,
        screens,
        getLoadResource,
        load,
        availableSelection,
        truckNotes,
        handleUncheck,
        assignedOrders,
        handleSuggest,
        loads,
        initStops,
        getAllStopOrders,
        setLoads,
        stops,
        createLoad,
        disableAdd,
        setDisableAdd,
        loadAdded,
        setLoadAdded,
        openAlert
    } = props;

    const addLoad = () => {
        setDisableAdd(true);
        setLoadAdded(false);
        axios({
            url: getLoadResource().LoadResource,
            method: 'POST',
            data: {
                id: 0
            }
        })
            .then(result => {
                if (result?.data) {
                    const newLoadList = [...loads, result.data];
                    setLoads(newLoadList);
                    initStops([...getAllStopOrders(stops)], newLoadList);

                    openAlert({
                        windowKey: "wndConfirmClose",
                        type: PopupTypes.Alert,
                        title: 'Load Added',
                        classes: 'truck',
                        text: `${result.data.loadNumber}`,
                        buttonYesText: CommonLabels.Buttons.Close,
                    });
                } else {
                    props.openAlert({
                        windowKey: "wndConfirmClose",
                        type: PopupTypes.Alert,
                        title: 'Error',
                        text: 'No result for Update!',
                        buttonYesText: CommonLabels.Buttons.Close,
                    });
                }
            })
            .finally(() => {
                setDisableAdd(false);
                setLoadAdded(true);
            });
    };

    useEffect(() => {
        if(loadAdded && document?.getElementsByClassName) {
            const tableEl = document.getElementsByClassName('ant-table-body')[0];
            tableEl.scrollTop = tableEl.scrollHeight - tableEl.clientHeight;
        }
    }, [loadAdded]);

    const swap = screens && screens[mainResource] ? screens[mainResource].swap : false;

    useEffect(() => {
        if(!truckNotes) {
            return;
        }
        if(truckNotes.totalPallet > 30) {
            message.warning('The total pallet is greater than 30')
        }
        if(truckNotes.totalWeight > 42800) {
            message.warning('The total weight is greater than 42800')
        }
    }, [truckNotes])

    return (<toolbar scroll="">
        <Scroll>
            <wrap>
                <action>
                    <wrap>
                        <Settings screen={mainResource} columns={getOrderConfig().List.Columns}>
                            <text>Available</text>
                        </Settings>
                    </wrap>
                    <separator vertical=""></separator>
                    <wrap>
                        <Settings screen={assignedOrders} columns={getOrderConfig().List.Columns} >
                            <text>Assigned</text>
                        </Settings>
                    </wrap>
                    <separator vertical=""></separator>
                    <Tooltip title="Swap">
                        <div
                            className="button accent"
                            onClick={() => saveScreenSwap(mainResource, !swap)}>
                            <text>Swap Tables</text>
                        </div>
                    </Tooltip>
                    {/* <text>Orders</text> */}
                    {getLoadResource().ShowRefreshButton ? (
                        <>
                            <Tooltip title="Refresh Orders">
                                <div
                                    className="button accent"
                                    onClick={load}
                                >
                                    <text>Refresh</text>
                                </div>
                            </Tooltip>
                        </>
                    ) : null}
                    {/*<Download*/}
                    {/*    screen={truckConfig.Resource}*/}
                    {/*    columns={truckConfig.List.Columns}*/}
                    {/*    filter={criteria().filter}*/}
                    {/*/>*/}
                    <button className="button primary" onClick={addLoad} disabled={disableAdd}>
                        <text>Add New Load</text>
                    </button>
                </action>
                <action right="">
                    {
                        availableSelection > 0 && truckNotes && (<>
                            <div className="button primary" onClick={createLoad} disabled={disableAdd}>
                                <text>Create New Load</text>
                            </div>
                            <div className="line small form_fields">
                                <div className="data_label">Space Count</div>
                                <div className="data_read">
                                    <text>{truckNotes.totalSpace}</text>
                                </div>
                            </div>
                            <div className="line small form_fields">
                                <div className="data_label">Pallet Count</div>
                                <div className="data_read">
                                   <b><text red={truckNotes.totalPallet > 30 ? '' : null}>{truckNotes.totalPallet}</text></b>
                                </div>
                            </div>
                            <div className="line small form_fields">
                                <div className="data_label">Total Weight</div>
                                <div className="data_read">
                                    <b><text red={truckNotes.totalWeight > 42800 ? '' : null}>{truckNotes.totalWeight?.toLocaleString()}</text></b>
                                </div>
                            </div>
                            <div className="button primary" onClick={handleSuggest}>
                                <text>Truck Notes</text>
                            </div>
                            <div className="button accent" onClick={handleUncheck}>
                                <text>Uncheck Selected</text>
                            </div>
                        </>)
                    }
                </action>
            </wrap>
        </Scroll>
        { !props.isHiddenSwitch && <HideShowMenuSwitch />}
    </toolbar>);
};

const mapStateToProps = state => {
    return {
        screens: state.auth.profileSettings && state.auth.profileSettings.screens
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        openAlert: (key) => dispatch(openPopup(key)),
        saveScreenSwap: (resource, swap) => dispatch(saveScreenSwap(resource, swap))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoadCreationToolbar);