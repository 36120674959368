export const createSeparator = (itemDefaultSize, headers, type) => ({
    row: [{
        key: headers[0].key,
        value: '',
        editable: false,
        height: itemDefaultSize
    }],
    rowState: {
        type: 'separator',
        id: `${type}_separator`,
        visible: true,
        height: 4
    }
});