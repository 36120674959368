import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Checkbox, Tooltip } from 'antd';
import { enabled } from 'store/dist/store.legacy';
import { Defines } from '../../../../project/Defines';
import { filterByCustomer, setItemDisplayState } from '../../../../store/actions/customerOrderAdmin/customerOrderAdmin';
import CustomModal from "../CustomerOrderInProgress/modal";

const Cell = ({
  state,
  cellData,
  itemSize,
  rowState,
  resource,
  startOnScroll,
  setItemData,
  setItemDisplayState,
  checked,
  setCheckedList,
  filterByCustomer,
}) => {
  const customerOrderAdmin = state[resource];
  const redonly = 1;
  const editable = 2;
  const header = customerOrderAdmin.headers.find(h => h.key === cellData.key);
  // const headerIndex = customerOrderAdmin.headers.findIndex(h => h.key === cellData.key);
  const [modalIsOpen, setIsOpen] = useState(false);

  const width = header.width;
//   const offsetWidth = customerOrderAdmin.headers.reduce((r, h, index) => {
//     if (h.visible && index < (headerIndex - 3)) {
//         r += h.width;
//     }
//     return r;
// }, 0);

const lastSelectId = customerOrderAdmin.lastSelected?.id;
const lastSelectHeaderKey = customerOrderAdmin.lastSelected?.headerKey;
const lastSelectenable = customerOrderAdmin.lastSelected?.enable;
const rowStateId = rowState.loadId;
const headerKey = header.key;
const [displayState, setDisplayState] = useState(redonly);
const [tempData, setTempData] = useState(null);

useEffect(() => {
  if (lastSelectId === rowStateId && lastSelectHeaderKey === headerKey && lastSelectenable) {
      setDisplayState(enabled);
  }
}, [lastSelectId, lastSelectHeaderKey, lastSelectenable, rowStateId, headerKey]);

const cellStyle = cellData.cellStyle;
let data = (cellData.valueText && cellData.fieldType === 'text') ? cellData.valueText : cellData.value;
  useEffect(() => {
    setTempData(data);
  }, [data, cellData.priorityValue, cellData.delayedValue, rowState.loadStatus]);

  const inputReference = useRef(null);

  const dataCompare = (newData, data) => {
    switch (cellData.fieldType) {
      case 'date':
      case 'dateTime':
        if (newData instanceof Date && data instanceof Date) {
          return newData.getTime() === data.getTime();
        }
        return newData === data;
      default:
        return newData === data;
    }
  };

  const setData = (value, subType) => {
    onSetBlur();
    let newValue = value;
    if (value !== '') {
      switch (cellData.fieldType) {
        case 'date':
          newValue = new Date(value);
          break;
        case 'dateTime':
          newValue = new Date(value);
          break;
        case 'text':
          newValue = value;
          break;
        case 'textInput':
          newValue = value;
          break;
        case 'select':
          newValue = value;
          break;
        case 'checkbox':
          newValue = value;
          break;
        default:
          newValue = value;
          break;
      }
    }
    if (!dataCompare(newValue, tempData)) {
      setTimeout(() => {
        setItemData(resource, newValue, rowState, header, subType);
      }, 100);
      setTimeout(() => {
        setTempData(newValue);
      }, 100);
    }
  };

  const onChange = e => {
    let customerIdList = [];
    setCheckedList(oldArray => {
      if (!oldArray.includes(rowState.loadData.id)) {
          customerIdList = [...oldArray, rowState.loadData.id];
        return customerIdList;
      } else {
          customerIdList = oldArray.filter(item => item !== rowState.loadData.id);
        return customerIdList;
      }
    });    
    setTimeout(() => {
      if (customerIdList.length === 0) {
        filterByCustomer(resource, null);
      } else {
        filterByCustomer(resource, rowState.loadData.customerId);
      }
  }, 100);
  };

  useEffect(() => {
    if (inputReference && inputReference.current) {
      inputReference.current.focus();
    }
  }, [displayState]);

  const getData = () => {
    if (tempData !== '' && tempData !== null) {
      switch (cellData.fieldType) {
        case 'date':
          return moment(tempData).format(Defines.Format.PrintDate);
        case 'dateTime':
          if (cellData.key === 'dueDate') {
            const indicator = rowState?.loadData?.orderIndicator;
            if (rowState?.orderData?.isHardDate) {
              return (
                <Tooltip title='Hard Date'>
                  <span style={{ color: 'rgb(96, 0, 128)' }}>{tempData ? moment(tempData).format(Defines.Format.PrintDateLong) : ''}</span>
                </Tooltip>
              )
            } else if (indicator === 2) {
              return (
                <Tooltip title='Data Change'>
                  <span style={{ color: '#EF2112' }}>{tempData ? moment(tempData).format(Defines.Format.PrintDateLong) : ''}</span>
                </Tooltip>
              )
            } else {
              return (
                <Tooltip title='Standard'>
                  <span style={{ color: '#0000FF' }}>{tempData ? moment(tempData).format(Defines.Format.PrintDateLong) : ''}</span>
                </Tooltip>
              )
            }
          } else {
            return <>{tempData ? moment(tempData).format(Defines.Format.PrintDateLong) : ''}</>;
          }
        case 'checkbox':
          return <div className='customer-order-admin-checkbox__container'>
            <Checkbox onChange={onChange} checked={checked} className='customer-order-admin__checkbox'></Checkbox>
            {tempData}
          </div>
        default:
          return tempData;
      }
    }
    return tempData;
  };

  const getInput = () => {
    switch (cellData.fieldType) {
      case 'textInput':
        return (
          <input
            defaultValue={tempData ? tempData : ''}
            ref={inputReference}
            onBlur={value => {
              setData(value.currentTarget.value);
              if (inputReference.current) {
                setTimeout(() => {
                  setDisplayState(redonly);
                }, 100);
              }
            }}
            format={Defines.Format.PrintDate}
            style={{ paddingLeft: '4px' }}
          />
        );
      default:
        return (
          <input
            style={{ backgroundColor: 'yellow', boxSizing: 'inherit', width: '100%' }}
            value={getData()}
            ref={inputReference}
            onChange={event => setData(event.target.value)}
            onBlur={() => {
              if (inputReference.current) {
                setDisplayState(redonly);
              }
            }}
          />
        );
    }
  };

  const dataDisplay = () => displayState === redonly ? getData() : getInput();

  const cellDefaultStyle = {
      maxWidth: `${width}px`,
      width: `${width}px`,
      minWidth: `${width}px`,
      height: `${itemSize}px`,
      color: cellData.color,
      padding: '4px 2px',
  };

  const onSetFocus = () => {
    // startOnScroll && startOnScroll(offsetWidth);
      if (cellData.editable) {
          setDisplayState(editable);
      }
      // setItemDisplayState(resource, rowState, header, cellData.editable);
  };

  const onSetBlur = () => {
      setItemDisplayState(resource, rowState, header, false);
  };

  const editableFunction = (cellData) => cellData.editable ? setDisplayState(editable) : null;

  return (
    <>
      <div  onFocus={() => onSetFocus()}
            onClick={() => { editableFunction(cellData)} }
            className='table-body-cell table-borders last-order-row'
            style={{ ...cellDefaultStyle, ...cellStyle }}
            tabIndex={displayState === redonly ? 0 : -1}>
          {dataDisplay()}
      </div>
      {modalIsOpen && 
        <CustomModal
            modalIsOpen={modalIsOpen}
            setIsOpen={setIsOpen}
            editMode
            data={rowState.loadData}
        />
      }
      </>
    );
  };

export default connect(state => ({
  state: state
  }), dispatch => ({
    filterByCustomer: (resource, customerId) => dispatch(filterByCustomer(resource, customerId)),
    setItemDisplayState: (resource, rowState, header, enable) => dispatch(setItemDisplayState(resource, rowState, header, enable)),
}))(Cell);
