import React from 'react';
import AccountReceivableSpreadsheet from '../../AccountReceivable/spreadsheet';

const AccountReceivableBuild = () => (
  <div
    style={{
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
    }}>
    <AccountReceivableSpreadsheet resource='AccountReceivable' />
  </div>
);

export default AccountReceivableBuild;
