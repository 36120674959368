import React from 'react';
import { connect } from "react-redux";
import HeaderCell from './headerCell'

const HeaderRow = (
    {
        state,
        resource,
        trafficSubHeader,
    }) => {
    const headers = trafficSubHeader ? state[resource].subHeaders: state[resource].headers;
    return (
        <div className={`resp-table-header header-traffic ${trafficSubHeader ? 'subHeader' : ''}`}>
            {headers.map((_, ind) => {
                return <HeaderCell key={ind} ind={ind} resource={resource} trafficSubHeader={trafficSubHeader} />
            })}
        </div>
    );
};

export default connect(state => ({
    state: state
}))(HeaderRow);