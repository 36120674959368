import {
  GenerateToolBars
} from '../../config';
import { GenerateRateConfirmationForm } from '../../helper/component';
// import { RateConfirmationForm } from '../../config/component.config';
export const RateConfirmationForm = (keyFieldName, config) => (type, data, dispatch, messages, messageDispatch, componentRefs, messageIdentityPrefix, object) => GenerateRateConfirmationForm(type, keyFieldName, config, data, dispatch, messages, messageDispatch, componentRefs, messageIdentityPrefix, object);

const RateConfirmation = {
  Title: 'Rate Confirmation',
  Resource: 'PDFGenerator',
  Master: 'loadNumber',
  IsRest: true,
  Key: 'loadId',
  Form: {
      View: {
        Components: {
          data: {
              Component: RateConfirmationForm('rowId')
          }
        }
      },
      ToolBars: GenerateToolBars(['Save', 'Send', 'SaveSend', 'SaveClose', 'Cancel'])
  }
}; 

export default RateConfirmation;