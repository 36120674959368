import React, {
    useRef,
    useState
} from 'react';
import { message } from 'antd';

import ForgotPasswordScreen from '../screen/forgotPasswordScreen';
import Content from './content';
import { Mapping } from '../../mapping/mapping';
import { ValidateScreen } from '../../helper/validation';
import { useData } from '../../hook/data/useData';
import { useMessage } from '../../hook/data/useMessage';
import { DefaultMessageTypes } from '../../hook/actions';
import { useFormValidation } from '../../hook/data/useFormValidation';
import ForgotPasswordToolbare from './toolbar/forgetPasswordToolbar';
import axios from '../../axios/axios';


import { ApiUrl } from '../../../project/Pages';



const ForgotPasswordForm = props => {
    const componentRefs = useRef({});
    //state
    const [data, dispatch] = useData(props.screen.DefaultState);
    const [messages, messageDispatch] = useMessage(props.screen.Form);
    const [isLoading, setLoading] = useState(false);
    //end state

    const validate = useFormValidation(messageDispatch,
        data,
        Mapping,
        ValidateScreen,
        props.screen.Form,
        componentRefs);


    const formValidation = () => {
        messageDispatch({ type: DefaultMessageTypes.START_FOCUSE, messages: true });
        const [passValidation] = validate();
        return passValidation;
    };

    const resetPasswordHandler = () => {
        resetPassword();
    };

    const resetPassword = () => {
        if (!formValidation()) {
            return;
        }
        setLoading(true);
        axios.post(ApiUrl.Forgot,
            {
                email: data.email
            })
            .then(response => {
                setLoading(false);
                if (response) {
                    const result = response.data;
                    if (result) {
                        message[result.hasError ? 'error' : 'success'](result.message);
                    }
                }
            }).catch(error => {
                setLoading(false);
                message.error(error.response.message);
            });
    };

    const actionFromElement = event => {
        if (event.nativeEvent.key === "Enter") {
            resetPassword();
        }
    };

    return (
        <ForgotPasswordScreen
            isLoading={isLoading}
            title={props.screen.Title}
            text={props.screen.Text}>
            <form autoComplete="off">
                <Content
                    config={props.screen.Form}
                    data={data}
                    dispatch={dispatch}
                    messages={messages}
                    messageDispatch={messageDispatch}
                    componentRefs={componentRefs}
                    actionFromElement={actionFromElement}
                />
                <ForgotPasswordToolbare resetPassword={resetPasswordHandler} />
            </form>
        </ForgotPasswordScreen>);
};

export default ForgotPasswordForm;

