import React, { useCallback, useRef, useEffect } from 'react';
import { useDispatch } from "react-redux";
import Cell from './cell';
import { reactOnAssignedItem } from '../../../../store/actions/accountReceivableActions/accountReceivableActions';

const Row = ({
        data,
        style,
        itemSize,
        listRef,
        startOnScroll,
        resource,
        priceTemplateType,
        customerId,
        mainScroll,
        }) => {
    const dispatch = useDispatch();
    const rowRef = useRef({});
    const color = data.rowState.type === 'load' ? '#65A7A5' : data.rowState.type === 'stop' ?  '#D9EBEB' : 'white';

    useEffect(() => {
        if (rowRef.current) {
            listRef.current.resetAfterIndex(0);
        }
    }, [rowRef, listRef]);

    const onClickRow = useCallback(() => {
        dispatch(reactOnAssignedItem(resource, data.rowState.id, data.rowState.type));
    }, [dispatch, resource, data.rowState.id, data.rowState.type]);

    style.width = 'max-content';
    const rowStyle = { ...style, ...{ height: `${itemSize}px`, backgroundColor: color } };
    
    const cellContent = (i, ind) => {
        return (<Cell
            key={ind}
            cellData={i}
            reacted={data.rowState.reacted}
            type={data.rowState.type}
            itemSize={itemSize}
            onClickRow={onClickRow}
            startOnScroll={startOnScroll}
            rowState={data.rowState}
            resource={resource}
            priceTemplateType={priceTemplateType}
            customerId={customerId}
            mainScroll={mainScroll}
        />);
    };

    return data ?
        <div className='resp-table-row' style={{ ...rowStyle, display:'flex' }}>
            {data.row.map(cellContent)}
        </div> : null;
    };

export default Row;