import React from 'react';
import {
    ElementTypes,
    ElementValidations,
    Ability,
    ElementConditions
} from '../../config';

const ForgotPassword = {
    Title: 'Forgot Password?',
    Text: (<React.Fragment>
        Enter the email address you used when you joined and 
        <br/> 
        we’ll send you instructions to reset your password.
        </React.Fragment>),
    Resource: 'ForgotPassword',
    Master: '',
    Key: '',
    DefaultState: {
        email: ''
    },
    Form: {
        Wrap: [
            {
                Fields: {
                    email: {
                        Title: 'Email',
                        Field: ElementTypes.TextField(Ability.Displayability, Ability.Editability, [], {}, ElementConditions.Enable, ' '),
                        Validations: [ElementValidations.Required(),
                            ElementValidations.Email('Incorrect email.'),
                            ElementValidations.MaxLength(50)]
                    }
                }
            }
        ]
    }
}; 

export default ForgotPassword;