import React from 'react';
import Cell from './cell';

const Row = ({ data, itemSize, resource, style }) => {
    const cellContent = (i, ind) => {
    return (<Cell
            key={ind}
            cellData={i}
            itemSize={itemSize}
            rowState={data.rowState}
            resource={resource}
        />);
    };

    return data ? 
        <div className='resp-table-row factoring-row' style={style}>
            {data.row.map(cellContent)}
        </div> : null;
    };

    export default Row;