import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { connect } from "react-redux";
import { Input, message, Upload } from 'antd';
import moment from 'moment';
import './index.css';
import { getComments, setComment, resetError } from '../../../../store/actions/comments/comments';
import Loading from "../../screen/loading";
import axios from '../../../axios/axios';
import { ApiUrl } from '../../../../project/Pages';
import store from '../../../../store';
import Download from '../../../elements/download';

const { TextArea } = Input;

const CommentsModal = ({ 
      setCommentsModalIsOpen,
      commentsModalIsOpen,
      rowState,
      hasError,
      actionMessage,
      getComments,
      setComment,
      comments,
      loading,
      resetError,
      attachments,
    }) => {

const resource = "OrderResources";
const orderId = rowState?.orderData?.id;

  const closeModal = () => {
    setCommentsModalIsOpen(false);
  };

  useEffect(() => {
    getComments(resource, orderId);
}, [getComments, resource, orderId]);

useEffect(() => {
  if (hasError) {
      message.error(actionMessage);
      resetError(resource);
  }
}, [hasError, actionMessage, resetError, resource]);

  const [commentsList, setCommentsList] = useState([]);
  const [attachmentsList, setAttachmentsList] = useState([]);
  const [newComment, setNewComment] = useState('');

  useEffect(() => {
    setCommentsList(comments);
    setAttachmentsList(attachments);
}, [setCommentsList, comments, attachments]);

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      minWidth: '600px',
      minHeight: '300px',
      maxWidth: '600px',
      maxHeight: '400px',  
      borderRadius: '8px',
      padding: loading ? '100px 0' : '6px 0',
    },
  };

  const saveComment = () => {
    setComment(resource, orderId, newComment);
    setNewComment('');
  };


  const [fileCont, setFileCont] = useState(null);
  const max = 5;
  const restrictions = file => {
    const isLt = file.size / 1024 / 1024 < max;
    if (!isLt) {
      message.error(`Image must be smaller than ${max}MB!`);
      return false;
    }

    const extensions = ['jpeg', 'jpg', 'png', 'heif', 'pdf'];
    const dot = file.name.lastIndexOf(".") + 1;
    const fileExtension = file.name.substr(dot, file.name.length).toLowerCase();
    if (!extensions.includes(fileExtension)) {
      message.error(`Image must be in jpeg, png, pdf or heif format!`);
      return false;
    }
    return true;
  };

  const beforeUpload = file => {
    if (!restrictions(file)) {
      return false;
    }
    setFileCont(file);
    return true;
  };

  const handleUpload = () => {
    const formData = new FormData();
    formData.append('file', fileCont);
    axios.post(ApiUrl.Upload, formData, { headers: { 'Content-Type': 'multipart/form-data' } }).then(response => {
      if (response && response.data) {
        const result = response.data;
        if (result.hasError) {
          message.error(result.message || 'File not uploaded!');
          return;
        }
        onChange(result);
      }
    });
  };

  const onChange = result => {
    const mDate = moment(new Date());
    const attachment = {
          hasError: result.hasError,
          message: result.message,
          guid: result.guId,
          fileName: result.originalName,
          fileType: result.fileType,
          size: result.size,
          originalFileName: result.originalName,
          fullName: `${store.getState().auth.userFirstName} ${store.getState().auth.userLastName}`,
          createDate: mDate.format(),
          isNew: true,
    };
    setComment(resource, orderId, '', [attachment]);
  };

  return (
    <Modal
        isOpen={commentsModalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        ariaHideApp={false}
        >
        <div className='comment_modal__container'>
          {loading ? <Loading /> : <>
            <div className='comment_modal__x-btn' onClick={closeModal}>X</div>
            <div className='comment_modal_text'>Uploads</div>
            <table className='comment-modal__table'>
              <tr className='comment-modal__tr'>
                <th className='comment-modal__th'>Download</th>
                <th className='comment-modal__th'>File name</th>
                <th className='comment-modal__th'>Date and Time</th>
                <th className='comment-modal__th'>Created By</th>
              </tr>
              {attachmentsList?.map(attachment => (
                <tr className='comment-modal__tr' key={attachment.id}>
                  <td className='comment-modal__td'>
                    <Download
                      guid={attachment.guid}
                      width={60}
                      height={40}
                      filename={attachment.fileName}
                    />
                  </td>
                  <td className='comment-modal__td'>{attachment.fileName}</td>
                  <td className='comment-modal__td'>{moment.utc(attachment.createDate).utcOffset(4).format("MM/DD/YY HH:mm")}</td>
                  <td className='comment-modal__td'>{attachment.fullName}</td>
                </tr>
              ))}
            </table>
            <div className='upload-attachment__container'>
              <Upload name='file' 
                  customRequest={handleUpload} 
                  beforeUpload={beforeUpload}
                  showUploadList={false}
                  >
                  <div className='add_new_comment'>Click to Upload</div>
              </Upload>
            </div>
            <hr className='upload-resources__hr'></hr>
            <div className='comment_modal_text'>Comments</div>
            <table className='comment-modal__table'>
              <tr className='comment-modal__tr'>
                <th className='comment-modal__th'>Date and Time</th>
                <th className='comment-modal__th'>Created By</th>
                <th className='comment-modal__th'>Comment</th>
              </tr>
              {commentsList?.map(comment => (
                <tr className='comment-modal__tr' key={comment.id}>
                  <td className='comment-modal__td'>{moment.utc(comment.createDate).utcOffset(4).format("MM/DD/YY HH:mm")}</td>
                  <td className='comment-modal__td'>{comment.user}</td>
                  <td className='comment-modal__td'>{comment.comment}</td>
                </tr>
              ))}
            </table>
            <div className='add-comment__container'>
              <div className='add_new_comment'>Add new comment</div>
              <TextArea
                placeholder="Add new comment"
                autoSize={{ minRows: 2, maxRows: 6 }}
                className='reject-reason__input'
                status={"error"}
                onChange={e => setNewComment(e.target.value)}
                value={newComment}
              />
            </div>        
            <div className='commnet__add-btn__container'>
              <button disabled={!newComment} className={`${!newComment && 'disabled__add-btn'} comment-modal-button`} type="submit" onClick={saveComment}>Add</button>
            </div>
          </>}
        </div>  
    </Modal>
  );
};

const mapStateToProps = state => ({
  state: state,
  comments: state.comments.comments,
  attachments: state.comments.attachments,
  loading: state.comments.loading,
  hasError: state.comments.hasError,
  actionMessage: state.comments.message,
});

const mapDispatchToProps = (dispatch) => ({
  getComments: (resource, id) => dispatch(getComments(resource, id)),
  setComment: (resource, orderId, comment, attachment) => dispatch(setComment(resource, orderId, comment, attachment)),
  resetError: resource => dispatch(resetError(resource)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CommentsModal);
