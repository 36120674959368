import React from 'react';

import DropDownElement from './dropDownElement';
import { useValueList } from '../hook/data/useValueList';
import { ElementConditions } from '../config/condition.config';

const SelectElement = props => {
    const { dataSource, classes, condition, data, action, valudationMessages,
        style, parentValue, reloadOnParentChange, selectedValue, mode,
        showSearch, name, allowClear, placeholder, filterOption, defaultValue } = props;
    const [list] = useValueList(data, dataSource, parentValue, reloadOnParentChange);
    return condition !== ElementConditions.Hidden ? //&& list ?
        <DropDownElement
            allowClear={allowClear}
            name={name}
            placeholder={placeholder}
            showSearch={showSearch}
            mode={mode}
            data={data === false ? 'No' : data}
            classes={classes}
            valudationMessages={valudationMessages}
            style={style}
            condition={condition}
            valueList={list}
            action={action}
            filterOption={filterOption}
            defaultValue={defaultValue}
            selectedValue={selectedValue}
        />
        : null;
};


export default SelectElement;