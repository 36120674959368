import React, { useCallback, useEffect, useState } from 'react';
import ReactDOM from 'react-dom'
import moment from 'moment';
import { DatePicker, message } from 'antd';
import axios from '../../../axios/axios';
import { Defines } from '../../../../project/Defines';

const statuses = ["", 'red', 'green', 'blue'];

export const ApptField = props => {
  const [value, setValue] = useState(null);
  const [locationInfo, setLocationInfo] = useState(null);
  const [node, setNode] = useState(null);
  const { data, setDataSource, dispatchAction } = props;

  const sendRequest = (e) => {
    if (!e.target.value) {
      return;
    }

    const pick = dispatchAction === 'PickAppointment' ? 'pick' : '';
    const drop = dispatchAction === 'DropAppointment' ? 'drop' : '';
    let url = data.isInbound ? `Load/${drop}loadcustomergroupitem/request` : `OutboundLoad/${pick}outboundloadcustomergroupitem/request`;

    axios({
      url: url,
      method: 'put',
      data: {
        id: data.id,
        appointment: e.target.value
      }
    })
      .then((response) => {
        if (typeof (setDataSource) === 'function') {
          if (response?.data) {
            const responseData = response.data;

            if (responseData.hasError) {
              message.error(responseData.message);

              return;
            }
          }

          setDataSource(response.data, dispatchAction);
          message.success("Appointment was successfully requested");
        }
      });
  };

  const fillInfo = useCallback(() => {
    if(!node) {
      return;
    }
    
    setTimeout(() => {
      let holder = node.getElementsByTagName('holder')[1];
      if (holder) {
        ReactDOM.render(<ApptInfo data={locationInfo} />, holder);

        return;
      }

      const parentElement = node.getElementsByClassName('ant-picker-panel-container')[0];
      parentElement.classList.add('custom');
      const children = parentElement.getElementsByClassName('ant-picker-panel')[0];

      const horizontalHolder = document.createElement('holder');
      horizontalHolder.setAttribute('horizontal', '');
      holder = document.createElement('holder');

      ReactDOM.render(<ApptInfo data={locationInfo} />, holder);
      horizontalHolder.prepend(holder);
      holder.prepend(children);

      parentElement.append(horizontalHolder);
    }, 50);
  }, [locationInfo, node]);

  const getInfo = () => {
    const pick = dispatchAction === 'PickAppointment' ? 'pick' : '';
    const drop = dispatchAction === 'DropAppointment' ? 'drop' : '';

    if ((data.isInbound && drop && data.warehouseId) || (!data.isInbound && pick)) {
      return;
    }

    axios({
      url: `Location/info/${data[`${pick || drop}LocationId`]}`,
      method: 'get'
    })
      .then((response) => {
        let responseData = null;
        if (response?.data) {
          responseData = response.data;

          if (responseData.hasError) {
            message.error(responseData.message);

            return;
          }
        } else {
          return;
        }

        setLocationInfo(responseData);
      });
  };

  useEffect(() => {
    if (locationInfo && node) {
      fillInfo();
    }
  }, [locationInfo, node, fillInfo]);

  return <DatePicker
    ref={props.ref}
    showTime={{
      minuteStep: 15
    }}
    showNow={false}
    suffixIcon={null}
    value={value ? moment(new Date(value), Defines.Format.PrintDateTime) : null}
    format={Defines.Format.PrintDateTime}
    onChange={(e, value) => setValue(value)}
    onBlur={e => sendRequest(e)}
    getPopupContainer={(node) => {
      setTimeout(() => {
        setNode(node.parentElement);
      }, 10)

      return node.parentElement;
    }}
    onOpenChange={function (open) {
      if (open) {
        getInfo();
      }
    }}
    renderExtraFooter={() => {
      if (locationInfo) {
        const attribute = locationInfo.status ? { [statuses[locationInfo.status]]: '' } : {};

        return (
          <div>Average Wait Time: <text {...attribute}>{locationInfo.avarageWaitTimeStr}</text></div>
        );
      }
    }}
  />;
};

const ApptInfo = props => {
  const data = props.data;

  return (
    <div className="form_container">
      <div className="section_group">
        {<div className="sections mini">
          <div className="form_fields">
            <div className="data_label">Warehouse Quick Glimpse</div>
            <div className="data_help"><text>{data.loactionName}</text></div>
          </div>
          <div className="form_fields"><div className="form_separator"></div></div>
          <div className="form_fields">
            <div className="data_label">Previouse Dates</div>
            {data.locationPastInfoList?.length
                          ? data.locationPastInfoList.map((i, index) =>
                              <div className="data_help" key={index}>Date: {moment(i.inboundPickAppointment, Defines.Format.MomentTime).format(Defines.Format.MomentDateTime)}</div>
              )
              : <div className="data_help">No Data </div>
            }
          </div>
          <div className="form_fields"><div className="form_separator"></div></div>
          <div className="form_fields">
            <div className="data_label">Upcoming Appointments Information</div>
          </div>
          {data.locationFutureInfoList?.length
            ? data.locationFutureInfoList.map((i, index) =>
              <div className="form_fields" key={index}>
                    <div className="data_help">
                        Date: {moment(i.inboundPickAppointment, Defines.Format.MomentTime).format(Defines.Format.MomentDateTime)}
                </div>
                <div className="data_help">
                  Status: {i.statusStr}
                </div>
                <div className="data_help">
                  Totals: Pallet - {i.totalPalletCount} / Weight - {i.totalWeight}
                </div>
              </div>
            )
            : <div className="form_fields">
              <div className="data_help">No Data</div>
            </div>}
        </div>
        }
      </div>
    </div >
  );
}