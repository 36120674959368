import React from 'react';
import APDeliveredTrucksSpreadsheet from '../../APDeliveredTrucks/spreadsheet';

const APDeliveredTrucksBuild = () => (
  <div
    style={{
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
    }}>
    <APDeliveredTrucksSpreadsheet resource='AccountPayable' />
  </div>
);

export default APDeliveredTrucksBuild;
