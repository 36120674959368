import React from 'react';
import { connect } from "react-redux";
import TrafficControlUpcomingSpreadsheet from '../../../custom/spreadsheetTrafficControl/spreadsheetUpcoming';
import { getContentWidth } from '../../../../../store/reducers/trafficControlUpcoming/helper/general';

const TrafficControlLoadBuld = props => {
    const { state, trafficUrl } = props;
    const scrollSize = 30;
    const resource= 'trafficUpcoming';
    const headers = state[resource].headers;
    const width = getContentWidth(headers) + scrollSize;
    const innerWidth = window.innerWidth;

    return (
        <div style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            margin: '0 12px',
            alignSelf: `${width < innerWidth ? "center" : "normal"}`,
            width: `${width > innerWidth ? `calc(100% - 35px)` : 'auto'}`,
        }}>
            <TrafficControlUpcomingSpreadsheet resource='trafficUpcoming' trafficUrl={trafficUrl} />
        </div>
    );
}
const mapStateToProps = state => ({
    state: state,
});

export default connect(mapStateToProps)(TrafficControlLoadBuld);