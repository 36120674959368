export const Mapping = (screen, data) => {
    if (!data) {
        return screen;
    }
    if (!screen) {
        return screen;
    }
    if (screen.ScreenToolbar) {
        MappingScreenToolbar(screen.ScreenToolbar, data);
    }
    if (screen.Wrap) {
        MappingWrap(screen.Wrap, data);
    }
    if (screen.SectionGroups) {
        MappingSectionGroups(screen.SectionGroups, data);
    }
    if (screen.Tabstrip) {
        MappingTabstrip(screen.Tabstrip, data);
    }
    return screen;
};

export const MappingTabstrip = (tabstrip, data) => {
    for (const ti in tabstrip) {
        const t = tabstrip[ti];
        if (t && t.Form && t.Form.SectionGroups) {
            MappingSectionGroups(t.Form.SectionGroups, data);
        }
    }
};

export const MappingScreenToolbar = (screenToolbar, data) => {
    for (const st in screenToolbar) {
        MappingFields(screenToolbar[st].Fields, data);
    }
    MappingFields(screenToolbar.Fields, data);
};

export const MappingWrap = (wrap, data) => {
    for (const st in wrap) {
        MappingFields(wrap[st].Fields, data);
    }
    MappingFields(wrap.Fields, data);
};

export const MappingSectionGroups = (sectionGroups, data) => {
    if (!data) {
        return sectionGroups;
    }
    for (const sg in sectionGroups) {
        const secrionGroup = sectionGroups[sg];
        MappingSections(secrionGroup.Sections, data);
    }
    return sectionGroups;
};

export const MappingSections = (sections, data) => {
    if (!data) {
        return sections;
    }
    for (const s in sections) {
        const section = sections[s];
        MappingFields(section.Fields, data);
    }
    return sections;
};

export const MappingFields = (fields, data) => {
    if (!data) {
        return fields;
    }
    for (const f in fields) {
        if (Array.isArray(fields[f])) {
            for (const gf in fields[f]) {
                MappingFields(fields[f][gf].Fields, data);
            }
        }
        else if (fields[f].Data !== data[f]) {
            fields[f].Data = data[f];
        }
    }
    return fields;
};