import React, { useState, useEffect, useCallback, forwardRef, useImperativeHandle } from 'react';
import { connect } from "react-redux";
import { Menu, Dropdown } from 'antd';
import { Droppable, Draggable, DragDropContext } from 'react-beautiful-dnd';

import { setProfileColumnVisibilityAndIndex } from '../../../store/actions/authentication/authentication';
import { CloneObject } from '../../helper/common';
import { ColumnType } from '../../config/list.config';
import { sorColumnsByIndex } from '../../helper/list';

const Settings = forwardRef((props, ref) => {
    let { screen, columns, profileSettings, setProfileColumnVisibilityAndIndex } = props;
    const [visible, setVisible] = useState(false);
    const [changed, setChanged] = useState(false);

    const isColumnVisible = useCallback(columnName => {
        if (profileSettings) {
            if (profileSettings.screens) {
                const selectedScreen = profileSettings.screens[screen];
                if (selectedScreen && selectedScreen.list) {
                    if (selectedScreen.list.columns) {
                        if (selectedScreen.list.columns[columnName]) {
                            return !selectedScreen.list.columns[columnName].hidden;
                        }
                    }
                }
            }
        }
        return true;
    }, [profileSettings, screen]);

    const getColumnIndex = useCallback(columnName => {
        if (profileSettings) {
            if (profileSettings.screens) {
                const selectedScreen = profileSettings.screens[screen];
                if (selectedScreen && selectedScreen.list) {
                    if (selectedScreen.list.columns) {
                        if (selectedScreen.list.columns[columnName]) {
                            return selectedScreen.list.columns[columnName].index;
                        }
                    }
                }
            }
        }
        return null;
    }, [profileSettings, screen]);

    const getSettingsMenu = useCallback(() => {
        const menuSettings = {};
        for (const k in Object.keys(columns)) {
            const cn = Object.keys(columns)[k];
            const c = columns[cn];
            if (!c || c.LinkAppPage || c.Type === ColumnType.Empty || !c.Title) {
                continue;
            }
            menuSettings[cn] = {
                title: c.Title,
                visible: isColumnVisible(cn),
                index: getColumnIndex(cn) ?? +k
            };
        }
        return menuSettings;
    }, [columns, isColumnVisible, getColumnIndex]);

    const [columnSettings, setColumnSettings] = useState(getSettingsMenu());

    useEffect(() => {
        !changed && setColumnSettings(sorColumnsByIndex(getSettingsMenu()));
    }, [getSettingsMenu, changed])

    const handleVisibleChange = (flag) => {
        setVisible(flag);
        if (flag && changed) {
            setColumnSettings(sorColumnsByIndex(getSettingsMenu()));
            setChanged(false);
        }
    };

    const columnVisibilityHandler = (e, c) => {
        const newColumnSettings = CloneObject(columnSettings);
        newColumnSettings[c].visible = e.target.checked;
        setColumnSettings(sorColumnsByIndex(newColumnSettings));
        setChanged(true);
    };

    const saveColumnSettingsHandler = () => {
        const selectedColumns = [];
        for (const k in Object.keys(columnSettings)) {
            const cn = Object.keys(columnSettings)[k];
            const c = columnSettings[cn];
            selectedColumns.push({
                name: cn,
                hidden: !c.visible,
                index: c.index,
            });
        }

        setProfileColumnVisibilityAndIndex(screen, selectedColumns);
        setChanged(false);
        setVisible(false);
    };

    useImperativeHandle(
        ref,
        () => {
            return {
                saveColumnSettingsHandler: saveColumnSettingsHandler
            }
        }
    );

    const resetColumnSettingsHandler = () => {
        const selectedColumns = [];

        for (const k in Object.keys(columnSettings)) {
            const cn = Object.keys(columnSettings)[k];

            selectedColumns.push({
                name: cn,
                hidden: false,
                index: null
            });
        }

        setProfileColumnVisibilityAndIndex(screen, selectedColumns);
        setVisible(false);
    };

    const onDragEnd = result => {
        if (result && result.destination && result.source) {
            if (result.destination.index === result.source.index) {
                return;
            }

            const newColumnSettings = CloneObject(columnSettings);
            const arrayOfNewColumnSettings = Object.entries(newColumnSettings);
            const newIndex = arrayOfNewColumnSettings[result.destination.index][1].index;
            const item = arrayOfNewColumnSettings[result.source.index][1];
            item.index = +newIndex;
            if (result.destination.index > result.source.index) {
                for (let i = result.source.index + 1; i <= result.destination.index; i++) {
                    arrayOfNewColumnSettings[i][1].index = arrayOfNewColumnSettings[i][1].index - 1;
                }
            }
            else if (result.destination.index < result.source.index) {
                for (let i = result.destination.index; i < result.source.index; i++) {
                    arrayOfNewColumnSettings[i][1].index = +arrayOfNewColumnSettings[i][1].index + 1;
                }
            }
            const sortedList = arrayOfNewColumnSettings.sort((a, b) =>
                a[1].index - b[1].index
            );
            let ret = {};
            for (const a in sortedList) {
                ret[sortedList[a][0]] = sortedList[a][1];
            }
            setColumnSettings(ret);
            setChanged(true);
        }

        props.applyHandlerForRegions && props.applyHandlerForRegions({ name: result.draggableId, index: result.destination.index });
    };

    const sortedColumnSettings = columnSettings => sorColumnsByIndex(columnSettings);

    const menu = (
        <Menu>
            <Menu.ItemGroup style={{height: '400px', overflow: 'auto',}}>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId={"droppable"}>
                        {(provided) => (
                            <div {...provided.droppableProps} ref={provided.innerRef}>
                                {
                                    Object.keys(sortedColumnSettings(columnSettings)).map((c, index) =>
                                        <Draggable key={columnSettings[c].title}
                                            draggableId={columnSettings[c].title} index={index}>
                                            {(provided) => (
                                                <li ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    className="ant-dropdown-menu-item ant-dropdown-menu-item-only-child"                                                >
                                                    {/*<Menu.Item>*/}
                                                    
                                                        <label checkbox="">
                                                            <input type="checkbox" checked={columnSettings[c].visible}
                                                                onChange={e => columnVisibilityHandler(e, c)} />
                                                            <box>
                                                                <check></check>
                                                            </box>
                                                            <text>{columnSettings[c].title}</text>
                                                        </label>
                                                        
                                                    {/*</Menu.Item>*/}
                                                </li>
                                            )}
                                        </Draggable>
                                    )
                                }
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </Menu.ItemGroup>
            {changed && 
                <Menu.Item key='_ok' onClick={saveColumnSettingsHandler}>
                    <div effect="material" className="button">
                        <text>Save</text>
                    </div>
                </Menu.Item>
            }  
                <Menu.Item key='reset' onClick={resetColumnSettingsHandler}>
                    <div effect="material" className="button">
                        <text>Reset</text>
                    </div>
                </Menu.Item>
            

        </Menu>
    );
    return <>
        {columnSettings && Object.keys(columnSettings).length > 0
            ? <Dropdown
                overlay={menu}
                trigger={['hover']}
                onVisibleChange={handleVisibleChange}
                visible={visible}>
                <span style={{ cursor: 'pointer' }} className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                    {props.children}
                    <icon>down</icon>
                </span>
            </Dropdown>
            : null
        }
    </>
});

const mapStateToProps = state => {
    return {
        profileSettings: state.auth.profileSettings
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setProfileColumnVisibilityAndIndex: (resource, columns) => dispatch(setProfileColumnVisibilityAndIndex(resource, columns))
    };
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Settings);