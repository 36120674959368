export const RELOAD_ON_ERROR_NOTES = 'RELOAD_ON_ERROR_NOTES';
export const RESET_ERROR = 'RESET_ERROR';
export const SET_NOTES = 'SET_NOTES';
export const GET_NOTES = 'GET_NOTES';
export const NOTES_LOADING = 'NOTES_LOADING';
export const NOTES_ERROR = 'NOTES_ERROR';

export const getActionType = (resource, key) => {
    return NOTES_BUILD[key];
};

export const NOTES_BUILD = {
    RELOAD_ON_ERROR_NOTES: 'RELOAD_ON_ERROR_NOTES',
    RESET_ERROR: 'RESET_ERROR',
    SET_NOTES: 'SET_NOTES',
    GET_NOTES: 'GET_NOTES',
    NOTES_LOADING: 'NOTES_LOADING',
    NOTES_ERROR: 'NOTES_ERROR',
};
