import {
    ElementTypes,
    ElementValidations,
    GenerateToolBars
} from '../../config';

const Profile = {
    Title: 'User',
    Resource: 'UserProfile',
    Master: 'loginName',
    Key: 'id',
    IsRest: true,
    Form: {
        SectionGroups: [
            {
                Sections: [
                    {
                        Fields: {
                            loginName: {
                                Title: 'Login',
                                Field: ElementTypes.TextField(),
                                Validations: [ElementValidations.Required(), ElementValidations.MinLength(5)]
                            },
                            firstName: {
                                Title: 'First Name',
                                Field: ElementTypes.TextField(),
                                Validations: [ElementValidations.Required()]
                            },
                            lastName: {
                                Title: 'Last Name',
                                Field: ElementTypes.TextField()
                            },
                            email: {
                                Title: 'Email',
                                Field: ElementTypes.TextField(),
                                Validations: [ElementValidations.Required(), ElementValidations.Email()]
                            }
                        }
                    },
                    {
                        Fields: {
                            avatarGuid: {
                                Title: 'Avatar',
                                Field: ElementTypes.Avatar('avatarFileName'),
                                Dependencies: [
                                    ['avatarFileName', 'originalName']
                                ]
                            },
                            mobilePhone: {
                                Title: 'Mobile Phone',
                                Field: ElementTypes.Phone()
                            },
                            workPhone: {
                                Title: 'Work Phone',
                                Field: ElementTypes.Phone()
                            }
                        }
                    }
                ]
            }
        ],
        ToolBars: GenerateToolBars(['ChangePassword', 'SaveClose', 'Cancel'])
    }
}

const ChangePassword = {
    Title: 'User',
    Resource: 'UserProfile',
    Master: 'loginName',
    Key: 'id',
    IsRest: true,
    Form: {
        SectionGroups: [
            {
                Sections: [
                    {
                        Fields: {
                            oldPassword: {
                                Title: 'Old Password',
                                Field: ElementTypes.PasswordField(false),
                                ChangeRunTime: true,
                                Validations: [ElementValidations.Required()]
                            },
                            password: {
                                Title: 'New Password',
                                Field: ElementTypes.PasswordField(false),
                                ChangeRunTime: true,
                                Validations: [ElementValidations.Required()]
                            },
                            confirmPassword: {
                                Title: 'Repeat Password',
                                Field: ElementTypes.PasswordField(false, o => o && o['password'] && o['password'].trim() !== ''),//o => o && o['password'] && o['password'].trim() != ''
                                Validations: [ElementValidations.Required(), ElementValidations.PasswordConfirm('password')]
                            }
                        }
                    }
                ]
            }
        ],
        ToolBars: GenerateToolBars(['SaveClose', 'Cancel'])
    }
}

export { Profile, ChangePassword };
