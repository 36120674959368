import React, { Fragment } from 'react';
import { connect } from "react-redux";

const ColGroup = props => {
    if (!props.tableWidth || props.tableWidth === 0) {
        return null;
    }
    let delta = 0;
    const indexCellWidth = 70;
    const draggableCellWidth = 40;
    const selectableCellWidth = 40;
    let columnSettings = null;
    const actionWidth = props.action ? (props.action.Width ? props.action.Width : 80) : 0;
    if (props.resizable && props.resource) {
        columnSettings = props.profileSettings?.screens?.[props.resource]?.list?.columns;
        let columsTotalWidth = 0;
        for (const field in props.columns) {
            const column = props.columns[field];
            let width = column.Width;
            if (columnSettings && columnSettings[field] && columnSettings[field].hidden) {
                continue;
            }
            if (columnSettings && columnSettings[field] && columnSettings[field].width && columnSettings[field].width > 0) {
                width = columnSettings[field].width;
            }
            if (width) {
                columsTotalWidth += width;
            }
        }
        columsTotalWidth += (props.action ? actionWidth : 0);
        const allCellWidth = (columsTotalWidth + draggableCellWidth + selectableCellWidth + 17);
        delta = props.tableWidth - allCellWidth;
        if (delta < 1) {
            delta = 0;
        }
        if (delta >= 100) {
            delta = 100;
        }
    } else if (props.resource === 'AssignedOrders') { // Custom Case
        columnSettings = props.profileSettings?.screens?.[props.resource]?.list?.columns;
    }
    return (
        <colgroup>
            {props.draggable && <col style={{ width: `${draggableCellWidth}px`, minWidth: `${draggableCellWidth}px` }} />}
            {props.selectable && <col style={{ width: `${selectableCellWidth}px`, minWidth: `${selectableCellWidth}px` }} />}
            {props.addIndex && <col style={{ width: `${indexCellWidth}px`, minWidth: `${indexCellWidth}px` }} />}
            {Object.keys(props.columns).map((c) => {
                const isDouble = props.columns[c].IsDouble;
                let width = props.columns[c].Width;
                if (columnSettings && columnSettings[c] && columnSettings[c].width) {
                    width = columnSettings[c].width;
                }
                if (isDouble) {
                    var width2 = props.columns[c].Width2
                    return <Fragment key={`f${c}`}>
                        <col id={`d${c}`} key={`d${c}`} style={width2 ? { width2: `${width2}px`, minWidth: `${width2}px` } : null} />
                        <col id={c} key={c} style={width ? { width: `${width}px`, minWidth: `${width}px` } : null} />
                    </Fragment>;
                }
                return <col id={c} key={c} style={width ? { width: `${width}px`, minWidth: `${width}px` } : null} />;
            })}
            {props.resizable ? <col style={{ width: `${delta}px`, minWidth: `${delta}px` }} /> : null}
            {props.action && <col style={{ width: `${actionWidth}px`, minWidth: `${actionWidth}px` }} />}
        </colgroup>
    )
};

const mapStateToProps = state => {
    return {
        profileSettings: state.auth.profileSettingsTemp
    }
};

export default connect(mapStateToProps)(React.memo(ColGroup));