import {
    ElementTypes,
    ElementValidations,
    ComponentTypes,
    Ability
} from '../../../config';
import { GenerateToolBars } from '../../../config/element.config';
import {OutboundAppointmentFields} from '../appointmentFieldsConfig';

const DropAppointment = {
    Title: 'Drop Appointment',
    Resource: 'OutboundLoad/outboundloadcustomergroupitem',
    Key: 'id',
    IsRest: true,
    Form: {
        Title: 'Drop Appointment',
        SectionGroups: [
            {
                Sections: [
                    {
                        classes: 'wide',
                        Fields: {
                            ...OutboundAppointmentFields,
                            Components: {
                                workingDays: {
                                    Component: ComponentTypes.ManagedSheet('name', {
                                        Title: 'Hours',
                                        List: {
                                            Columns: {
                                                name: {
                                                    Title: 'Day',
                                                    Field: ElementTypes.TableLabelField(),
                                                    Attributes: {
                                                        wrap: {
                                                            left: ''
                                                        }
                                                    }
                                                },
                                                working: {
                                                    Title: 'Work',
                                                    Field: ElementTypes.CheckBoxModern(null, null, Ability.Displayability, () => false)
                                                },
                                                from: {
                                                    Title: 'From',
                                                    Field: ElementTypes.TableLabelField((o) => o.working),
                                                    Width: 236
                                                },
                                                to: {
                                                    Title: 'To',
                                                    Field: ElementTypes.TableLabelField((o) => o.working),
                                                    Width: 236
                                                },
                                                comment: {
                                                    Title: 'Comment',
                                                    Field: ElementTypes.TableLabelField(),
                                                    Width: 236
                                                }
                                            }
                                        }
                                    })
                                }
                            },
                            Group3: [
                                {
                                    useSeparator: false,
                                    Fields: {
                                        outboundDropAppointment: {
                                            Title: 'Appt Date',
                                            Field: ElementTypes.Generic(ElementTypes.DateFieldViewOrEdit, {
                                                condition: object => !object.outboundAppointmentStatus || object.outboundAppointmentStatus === 1 || object.outboundAppointmentStatus === 2
                                            }),
                                            Validations: [ElementValidations.Required()]
                                        }
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        ],
        ToolBars: GenerateToolBars(['Request', 'Close'], 'outboundAppointmentStatus')
    }
};

export default DropAppointment;