import React, { useRef } from 'react';
import { connect } from "react-redux";

import FormScreen from '../../../../components/screen/formScreen';
import Content from '../../../../components/form/content';
import { Action } from '../../../../config/action.config';
import { Mapping } from '../../../../mapping/mapping';
import { ValidateScreen } from '../../../../helper/validation';
import { DefaultMessageTypes } from '../../../../hook/actions';
import { useData } from '../../../../hook/data/useData';
import { useMessage } from '../../../../hook/data/useMessage';
import { useFormValidation } from '../../../../hook/data/useFormValidation';

import { openPopup } from '../../../../../store/actions/popup/popup';

const Prompt = props => {
    const { config,
        sectionData,
        closeAction,
        saveAction } = props;
    //state
    const componentRefs = useRef({});
    const [data, dispatch] = useData(sectionData);
    const [messages, messageDispatch] = useMessage();
    const validate = useFormValidation(messageDispatch,
        data,
        Mapping,
        ValidateScreen,
        config,
        componentRefs);

    const sectionValidation = () => {
        messageDispatch({ type: DefaultMessageTypes.START_FOCUSE, messages: true });
        const [passValidation] = validate();
        return passValidation;
    };

    const dataAction = (action) => {
        switch (action) {
            case Action.Save:
                if (!sectionValidation()) {
                    return;
                }

                saveAction && saveAction(data);

                break;
            case Action.SaveClose:
                if (!sectionValidation()) {
                    return;
                }

                saveAction && saveAction(data);
                closeAction();

                break;
            case Action.Close:
                closeAction();

                break;
            default:
                break;
        }
    };

    return <FormScreen showPopup={true} title={config.Title} isLoading={false} fullScreen={props.fullScreen} onCancel={() => dataAction(Action.Close)}>
        <form>
            <Content
                config={config}
                data={data}
                dispatch={dispatch}
                messages={messages}
                messageDispatch={messageDispatch}
                componentRefs={componentRefs}
                dataAction={dataAction}
            />
            <div className="alert_contianer">
                <alert warning="warning">
                    <icon>info</icon>
                    <text marquee="marquee">
                        <span>This action can't be undone. Do you want to continue?</span>
                    </text>
                    <action>
                        <div className="button accent" onClick={() => dataAction(Action.Save)}><text>Confirm</text></div>
                        <separator vertical="vertical"></separator>
                        <div className="button mini close" onClick={() => dataAction(Action.Close)}><icon>close</icon></div>
                    </action>
                </alert>
            </div>
        </form>
    </FormScreen>;
};

const mapDispatchToProps = dispatch => {
    return {
        openConfirm: (data) => dispatch(openPopup(data)),
        openAlert: (data) => dispatch(openPopup(data))
    };
};

export default connect(null, mapDispatchToProps)(Prompt);