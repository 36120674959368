import React from 'react';

const CommonFormView = props => (
    <view intro="">
        <login>
            <cont>
                <wrap id="container" multiple="multiple">
                    <logo>
                        <svg xmlns="http://www.w3.org/2000/svg" width="172.25" height="45.875" viewBox="0 0 172.25 45.875">
                            <path fill="#00b7b1" d="M2388.6,589.6a1.044,1.044,0,0,0,1.45-.224l8.69-11.711h1.01l8.69,11.711a1.055,1.055,0,0,0,1.46.224l7.87-5.677a1.043,1.043,0,0,0,.23-1.461l-8.44-11.443,0.38-1.128,12.48-4.419a1.042,1.042,0,0,0,.64-1.324l-3.22-9.2a1.044,1.044,0,0,0-1.33-.641l-12.34,4.3-1.01-.376V544.971a1.045,1.045,0,0,0-1.05-1.041h-9.73a1.037,1.037,0,0,0-1.04,1.041v13.253l-1.14.376-12.22-4.292a1.045,1.045,0,0,0-1.33.64l-3.21,9.2a1.037,1.037,0,0,0,.63,1.324l12.49,4.419,0.37,1.128-8.43,11.443a1.043,1.043,0,0,0,.23,1.461Z" transform="translate(-2375.38 -543.938)" />
                            <path d="M2450.38,550.057v-1.44H2446V544.5l-1.5.441v3.674h-3.23v1.44h3.23v9.554c0,3.351,2.03,4.291,5.88,3.7v-1.322c-3,.264-4.38.205-4.38-2.381v-9.554h4.38Zm5,1.441v-2.881h-1.47v14.7h1.47v-8.171c0-3.763,2.38-5.321,4.91-5.321v-1.411A4.946,4.946,0,0,0,2455.38,551.5Zm18.46-2.881v7.76c0,3.968-2.26,5.85-5.14,5.85-2.77,0-4.32-1.764-4.32-4.468v-9.142h-1.5v9.142c0,3.556,2.2,5.879,5.7,5.879a5.539,5.539,0,0,0,5.26-3.116v2.792h1.47v-14.7h-1.47Zm12.61-.323a7.672,7.672,0,0,0,.12,15.344,7.252,7.252,0,0,0,6.41-3.322l-1.3-.764a5.581,5.581,0,0,1-5.08,2.675,5.981,5.981,0,0,1-6.23-5.5h13.31c0-.235.03-0.47,0.03-0.706C2493.71,552,2490.92,548.294,2486.45,548.294Zm0,1.411a5.7,5.7,0,0,1,5.73,5.585h-11.81A5.918,5.918,0,0,1,2486.45,549.705Zm14.23,3.2c0-.53.47-0.824,1.23-0.824a2.134,2.134,0,0,1,1.97,1.294l3.77-2.028a6.451,6.451,0,0,0-5.74-3.146c-3.05,0-5.76,1.617-5.76,4.821,0,5.085,7.23,4.233,7.23,5.85,0,0.588-.53.911-1.58,0.911a2.409,2.409,0,0,1-2.5-1.734l-3.82,2.175c1.14,2.381,3.38,3.5,6.32,3.5,3.17,0,6.11-1.441,6.11-4.821C2507.91,553.467,2500.68,554.584,2500.68,552.909Zm17.61-.059v-4.233h-3.03V544.5l-4.41,1.322v2.793h-2.35v4.233h2.35v5.2c0,4.115,1.68,5.849,7.44,5.261v-4c-1.94.117-3.03,0-3.03-1.264v-5.2h3.03Zm12.87-4.233V550a5.52,5.52,0,0,0-4.38-1.793c-3.85,0-7.02,3.381-7.02,7.761s3.17,7.76,7.02,7.76a5.52,5.52,0,0,0,4.38-1.793v1.381h4.41v-14.7h-4.41Zm-3.5,10.935a3.588,3.588,0,1,1,3.5-3.586A3.355,3.355,0,0,1,2527.66,559.552Zm15.55-8.319v-2.616h-4.41v14.7h4.41v-6.643c0-2.91,2.59-3.645,4.41-3.351v-5A4.419,4.419,0,0,0,2543.21,551.233Z" transform="translate(-2375.38 -543.938)" />
                            <path fill="#00b7b1" d="M2451.8,578.867v-4.233h-3.02v-4.116l-4.41,1.323v2.793h-2.35v4.233h2.35v5.2c0,4.116,1.67,5.85,7.43,5.262v-4c-1.94.118-3.02,0-3.02-1.264v-5.2h3.02Zm7.21-1.617v-2.616h-4.41v14.7h4.41v-6.643c0-2.91,2.58-3.645,4.4-3.351v-5A4.4,4.4,0,0,0,2459.01,577.25Zm16.69-2.616v1.381a5.52,5.52,0,0,0-4.38-1.793c-3.85,0-7.02,3.381-7.02,7.76s3.17,7.761,7.02,7.761a5.52,5.52,0,0,0,4.38-1.793v1.381h4.41v-14.7h-4.41Zm-3.5,10.935a3.588,3.588,0,1,1,3.5-3.587A3.355,3.355,0,0,1,2472.2,585.569Zm19.76-11.347a5.051,5.051,0,0,0-4.21,1.793v-1.381h-4.41v14.7h4.41v-8.025c0-2.087,1.12-3.027,2.74-3.027a2.426,2.426,0,0,1,2.56,2.675v8.377h4.4v-9.024C2497.45,576.339,2494.93,574.222,2491.96,574.222Zm12.7,4.7c0-.529.47-0.823,1.23-0.823a2.133,2.133,0,0,1,1.97,1.294l3.76-2.029a6.423,6.423,0,0,0-5.73-3.145c-3.06,0-5.76,1.617-5.76,4.821,0,5.085,7.23,4.233,7.23,5.85,0,0.587-.53.911-1.59,0.911a2.425,2.425,0,0,1-2.5-1.735l-3.82,2.176c1.15,2.381,3.38,3.5,6.32,3.5,3.18,0,6.12-1.441,6.12-4.821C2511.89,579.484,2504.66,580.6,2504.66,578.925Zm11.64-5.585a2.646,2.646,0,1,0-2.65-2.645A2.668,2.668,0,0,0,2516.3,573.34Zm-2.21,15.991h4.41v-14.7h-4.41v14.7Zm16.26-10.464v-4.233h-3.03v-4.116l-4.41,1.323v2.793h-2.35v4.233h2.35v5.2c0,4.116,1.68,5.85,7.44,5.262v-4c-1.94.118-3.03,0-3.03-1.264v-5.2h3.03Z" transform="translate(-2375.38 -543.938)" />
                        </svg>
                    </logo>
                    {props.children}
                </wrap>
            </cont>
        </login>
    </view>
);

export default CommonFormView;