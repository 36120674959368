export const useElementOut = (validate) => {
    const fieldOutHandler = event => {
        const data = event.target.value;
        if (validate && typeof validate === 'function') {
            validate(data);
        }
        //if (!changeRunTime) {
        //    onBlur(event, data);
        //}
    };
    return fieldOutHandler
};