import React from 'react';
import { connect } from "react-redux";

import { openPopup, closePopup, closePopupAsync } from '../../../store/actions/popup/popup';
import { PopupTypes } from '../custom/popup/popup';
import { CreateGuid, CloneObject } from '../../helper/common';
import { DefaultDataTypes } from '../../hook/actions';
import { ButtonActionTypes } from '../../config/action.config';
import { message } from 'antd';

const templates = {
    Table: props => {
        let Field = null;

        if (props.field) {
            Field = props.field;

            if (typeof (Field) === 'function') {
                Field = Field();
            }
        } else {
            Field = props.label && <text>{props.label}</text>;
        }

        return <elastic>
            <action {...props.attributes.action}>
                {Field}
                <div
                    className="button micro"
                    onClick={props.action}>
                    <icon>{props.icon}</icon>
                </div>
            </action>
        </elastic>
    },
    FormField: props => (
        <div className="input_cont">
            <div className="data_read">
                <text>{props.data}</text>
            </div>
            <div
                effect="material"
                className="button"
                onClick={props.action}>
                <text>More</text>
            </div>
            <i></i>
        </div>
    ),
    CarrierPacket: props => {
        const attributes = {
            filled: '',
            [props.data ? 'accent' : 'error']: ''
        };

        return props.data === null || props.data === ''
            ? <>
                <div className="data_help">Actions will update form data</div>
                <action>
                    <div className="button primary" onClick={props.action.bind(this, true)}>Approve</div>
                    <div className="button error" onClick={props.action.bind(this, false)}>Deny</div>
                </action>
            </>
            : <group>
                <badge {...attributes}>{props.data ? 'Approved' : 'Denied'}</badge>
            </group>
    }
};
const mapDispatchToProps = dispatch => {
    return {
        openPopup: (data) => dispatch(openPopup(data)),
        closePopup: (key) => dispatch(closePopup(key)),
        closePopupAsync: (key) => dispatch(closePopupAsync(key))
    };
};

const ButtonAction = props => {
    const {
        openPopup,
        closePopup,
        id,
        icon,
        popupConfig,
        action,
        setDataSource,
        data,
        value,
        onActionRecord,
        parentData,
        actionType,
        attributes,
        field,
        template,
        clicked
    } = props;
    const { BeforeStateChange, onUpdate } = popupConfig;
    const buttonTemplate = template || popupConfig.Template || ButtonActionTypes.Table;
    let label = props.label;

    if (typeof (label) === 'function') {
        label = label(data);
    }

    if (popupConfig.Resource) {
        popupConfig.Form.Resource = popupConfig.Resource;
    }

    const ReceiveHandler = () => {
        const windowKey = CreateGuid();
        const config = CloneObject(popupConfig.Form);
        const title = `Receive ${config.Title}`;

        config.Form.DefaultId = id;
        openPopup({
            windowKey,
            fullScreen: false,
            title: title,
            type: PopupTypes.ConfigForm,
            bodyProps: { windowKey },
            config: config,
            saveAction: (response) => {
                // Works for Expandable Component
                // Change List Component for List data update
                typeof (setDataSource) === 'function' ? setDataSource({
                    type: DefaultDataTypes.INIT,
                    value: response,
                })
                    : console.warning("Update List Data did'nt set");

                // const newData = [...dataSource];
                // const row = {...newData.find(i => i.id = response.id)};
                // newData.splice(newData.indexOf(row), 1, response);

                // setDataSource(newData);

                closePopupAsync(windowKey);
            },
            closeAction: () => {
                closePopup(windowKey);
            }
        });
    };

    const RequestAppointment = () => {
        const windowKey = CreateGuid();
        const config = CloneObject(popupConfig.Form);
        config.Form.DefaultId = id;
        openPopup({
            windowKey,
            fullScreen: false,
            title: config.Title,
            type: PopupTypes.ConfigForm,
            bodyProps: { windowKey },
            config: config,
            beforeSaveAction: (data, additionalData, updateData, setDefaultData) => {
                const newData = { ...data };
                Object.keys(additionalData).forEach((i) => newData[i] = additionalData[i]);

                updateData((result) => {
                    setDefaultData(result);
                    if (parentData) {
                        const dispatchData = { ...parentData };

                        const customer = dispatchData.loadCustomerGroup?.find(i => i.id === result.id);

                        if (customer) {
                            const customerIndex = dispatchData.loadCustomerGroup.indexOf(customer);
                            customerIndex > -1 && dispatchData.loadCustomerGroup.splice(customerIndex, 1, result);
                        }

                        if (BeforeStateChange) {
                            BeforeStateChange(result, dispatchData.loadCustomerGroup);
                        }

                        if (onActionRecord) {
                            onActionRecord({ type: DefaultDataTypes.INIT, value: dispatchData });
                        }
                    }
                    else if (setDataSource) {
                        setDataSource(result, actionType);
                    }
                }, newData);
            },
            closeAction: () => {
                closePopup(windowKey);
            }
        });
    };

    const OpenForm = () => {
        const windowKey = CreateGuid();
        let formConfig = popupConfig.Form;

        if ('function' === typeof (formConfig)) {
            formConfig = formConfig(data);
        }
        const config = CloneObject(formConfig);

        let key = data[popupConfig.Key || config.Key];
        if ('function' === typeof (popupConfig.Key)) {
            key = popupConfig.Key(data);
        }

        config.Form.DefaultId = key;

        openPopup({
            windowKey,
            fullScreen: false,
            title: config.Title,
            type: PopupTypes.ConfigForm,
            bodyProps: { windowKey },
            config: config,
            saveAction: (result, close) => {
                let dispatchData = { ...parentData };

                if (!parentData) {
                    dispatchData = result;
                }

                if (onUpdate) {
                    onUpdate(result, dispatchData.loadCustomerGroup);
                }

                if (onActionRecord) {
                    onActionRecord({ type: DefaultDataTypes.INIT, value: dispatchData });
                }

                typeof (setDataSource) === 'function'
                    ? setDataSource(result, actionType)
                    : console.warning("Update List Data didn't set");

                if (close &&
                    (actionType === 'InboundLoadSequence' ||
                        actionType === 'OutboundLoadSequence' ||
                        actionType === 'InboundStopNotes' ||
                        actionType === 'OutboundStopNotes')
                ) {
                    setTimeout(() => {
                        closePopup(windowKey);
                    }, 10);
                }
            },
            closeAction: () => {
                closePopup(windowKey);
            }
        });
    };

    const RequestCarrierPacket = isApprove => {
        if(!data.packageUploadGuid) {
            message.warning('Carrier Package Required!');

            return;
        }

        const newData = {...data};
        newData.isApproved = isApprove;

        clicked('ForceUpdate', newData);
    };

    const Actions = {
        ReceiveHandler,
        RequestAppointment,
        OpenForm,
        RequestCarrierPacket
    };

    return templates[buttonTemplate]({
        data: value,
        o: data,
        action: Actions[action],
        label,
        field,
        icon,
        attributes
    });
}

export default connect(null, mapDispatchToProps)(ButtonAction);