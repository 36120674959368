import React from 'react';
import { connect } from "react-redux";
import { Group1, Group2, Group3, Group4 } from '../../../screens/Load/assignedOrderTime';
import { PopupTypes } from '../../custom/popup/popup';
import { openPopup, closePopup, closePopupAsync } from '../../../../store/actions/popup/popup';
import Sheet from '../../custom/sheet/sheet';
import { CloneObject } from '../../../helper/common';
import { Action } from '../../../config/action.config';
import Loading from '../../screen/loading';
// import ResizableTable from '../../custom/resizeableTable';

const Orders = props => {
    const onActionRecordHandler = (record, action) => {
        switch (action) {
            case Action.Edit:
                editOrder(record.recordId, props.dropId === 'orders');
                break;
            default:
                break;
        }
    };

    const editOrder = (id, available) => {
        const windowKey = 'Order';
        const config = CloneObject(props.OrderForm);
        const title = `Edit ${config.Title}`;
        config.screen = config;
        config.screen.Form.DefaultId = id;
        if (!available) {
            config.screen.Form.SectionGroups[0].Sections[0].Fields.Group1 = Group1;
            config.screen.Form.SectionGroups[0].Sections[0].Fields.Group2 = Group2;
            config.screen.Form.SectionGroups[0].Sections[0].Fields.Group3 = Group3;
            config.screen.Form.SectionGroups[0].Sections[0].Fields.Group4 = Group4;
            config.screen.Resource = 'Order/assignloadorderitem';
        }
        props.openPopup({
            windowKey,
            fullScreen: false,
            title: title,
            type: PopupTypes.ConfigForm,
            bodyProps: { windowKey },
            config: config,
            saveAction: (result) => {
                const data = [...props.data];
                const index = data.indexOf(data.find(i => i.id === result.id));
                data.splice(index, 1, result);
                props.dispatchDropData({ type: props.dropId, value: data });

                props.closePopupAsync(windowKey);
            },
            closeAction: () => {
                props.closePopup(windowKey);
            }
        });
    };

    return (props.isLoading
        ? <Loading />
        : <Sheet
            colgroup
            resource={props.resource}
            droppableId={props.dropId}
            keyFieldName={props.keyFieldName}
            minRowsCanDelete={Number.MAX_SAFE_INTEGER}
            columns={props.columns}
            data={props.data}
            type={props.dropType}
            classes='table_view extra'
            onActionRecord={onActionRecordHandler}
            selectable={props.selectable}
            handleCheck={props.handleCheck}
            selectedRows={props.selectedRows}
            setSelectedRows={props.setSelectedRows}
            onAfterMouseSelect={props.onAfterMouseSelect}
            action
            draggable
            resizable
            grouping
            groupBy="orderGroupIndex"
            groupType="batchType"
            groupTypeValue="Hard"
            subGroup={props.subGroup}
        />
    );
};

const mapDispatchToProps = dispatch => {
    return {
        openPopup: (data) => dispatch(openPopup(data)),
        closePopup: (key) => dispatch(closePopup(key)),
        closePopupAsync: (key) => dispatch(closePopupAsync(key))
    };
};

export default connect(null, mapDispatchToProps)(Orders);