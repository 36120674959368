import { CUSTOMER_ORDERS_BUILD as actionTypes } from '../../actions/customerRejectedOrders/customerRejectedOrdersActionTypes';
import * as customerOrder from './customerRejectedOrdersReducers';

const defaultState = {
  headers: [
    {
      key: 'readyDate',
      title: 'READY DATE',
      width: 80,
    },
    {
      key: 'dueDate',
      title: 'DUE DATE',
      width: 80,
    },
    {
      key: 'pickLocation',
      title: 'PICK LOCATION',
      width: 100,
    },
    {
      key: 'dropLocation',
      title: 'DELIVERY WH',
      width: 100,
    },
    {
      key: 'poNumber',
      title: 'PO#',
      width: 60,
    },
    {
      key: 'soNumber',
      title: 'SO#',
      width: 60,
    },
    {
      key: 'palletCount',
      title: 'PAL',
      width: 40,
    },
    {
      key: 'spa',
      title: 'SPA',
      width: 40,
    },
    {
      key: 'lbs',
      title: 'LBS',
      width: 50,
    },
    {
      key: 'temperature',
      title: 'TEMP',
      width: 60,
    },
    // {
    //   key: 'freight',
    //   title: 'FREIGHT VALUE',
    //   width: 60,
    // },
    {
      key: 'rejectNotes',
      title: 'REJECT NOTES',
      width: 70,
    },
    {
      key: 'orderCycle',
      title: 'ORDER CYCLE',
      width: 70,
    },
    {
      key: 'actions',
      title: '',
      width: 90,
    },
  ],
  loading: false,
  hasError: false,
  message: '',
  total: 0,
  totalOrders: 0,
  existing: 0,
  customerOrder: null,
  customerRejectedOrders: null,
  totalRejectedOrders: 0,
  loadingRejectedOrders: false,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.SET_HEADER_WIDTH_REJECTED_ORDERS:
      return customerOrder.setHeaderWidth(state, action.data.ind, action.data.width);
    case actionTypes.RESET_ERROR:
      return customerOrder.resetError(state);
    case actionTypes.RELOAD_ON_ERROR:
      return customerOrder.loadContent(state, action.data, true, 'Something go wrong, data reloaded!');
    case actionTypes.GET_CUSTOMER_ORDERS_REJECTED:
      return customerOrder.customerOrdersRejected(state, action.data);
    case actionTypes.CUSTOMER_ORDERS_REJECTED_LOADING:
      return customerOrder.setInLoadingRejectedOrders(state, action.data);
    case actionTypes.SORT_ORDERS_REJECTED_ORDERS:
      return customerOrder.sortBy(state, action.data);
    default:
      return state;
  }
};
