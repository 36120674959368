export const setIsLoadingPickLocation = state => ({
  ...state,
  ...{
    pickLocationLoading: true,
  },
});

export const setIsLoadingFavoriteDoorsList = state => ({
  ...state,
  ...{
    favoriteDoorsLocationsLoading: true,
  },
});

export const setIsLoadingAllDoorsList = state => ({
  ...state,
  ...{
    allDoorsLocationsLoading: true,
  },
});

export const getFavoriteLocationDoorList = (state, responce, hasError = false, message = '') => {
  const { data } = responce;
  return {
    ...state,
    ...{
      hasError,
      message,
      favoriteLocationsDoorsList: data,
      total: data.length,
      favoriteDoorsLocationsLoading: false,
    },
  };
};

export const getAllLocationDoorList = (state, responce, hasError = false, message = '') => {
  const { data } = responce;
  return {
    ...state,
    ...{
      hasError,
      message,
      allLocationsDoorsList: data,
      total: data.length,
      allDoorsLocationsLoading: false,
    },
  };
};
