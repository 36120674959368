import * as googleActionTypes from '../../actions/google/googleActionTypes';

const defaultState = {
    isLoaded: false
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case googleActionTypes.GOOGLE_LOADED:
            return { ...state, isLoaded: true }
        default:
            return state;
    }
};