import axios from 'axios';
import store from '../..';
import * as actionTypes from './ordersActionTypes';

const buildUrl = (resource, endPoint) => endPoint ? `/api/${resource}/${endPoint}` : `/api/${resource}`;

const axiosConfig = () => ({
    headers: {
        'Authorization': 'Bearer ' + store.getState().auth.userToken
    }
});

export const getOrdersByCustomer = (resource, endpoint, customerId) => dispatch => {
    const data  = {
        params: {
            filterData: { customerId: customerId},
        },
        headers: axiosConfig().headers,
    };
    dispatch({ type: actionTypes.getActionType(resource, actionTypes.GET_ORDERS_LIST_BY_ID_LOADING) });
    axios.get(buildUrl(resource, endpoint), data,
        axiosConfig())
        .then(responce => dispatch({ type: actionTypes.getActionType(resource, actionTypes.GET_ORDERS_LIST_BY_ID), data: responce }))
        .catch((err) => {
            console.error(err);
            console.log(err.response);
            dispatch({ type: actionTypes.getActionType(resource, actionTypes.GET_ORDERS_LIST_BY_ID_ERROR) });
        })
};

export const createNewGroup = (resource, endpoint, ids, batchType) => dispatch => {
    const data = {
        'ids': ids,
        'batchType': batchType,
    };
    dispatch({ type: actionTypes.getActionType(resource, actionTypes.CREATE_NEW_GROUP_LOADING) });
    axios.put(buildUrl(resource, endpoint), data,
        axiosConfig())
        .then(responce => dispatch({ type: actionTypes.getActionType(resource, actionTypes.CREATE_NEW_GROUP), data: responce }))
        .catch((err) => {
            console.error(err);
            console.log(err.response);
            dispatch({ type: actionTypes.getActionType(resource, actionTypes.CREATE_NEW_GROUP_ERROR) });
        })
};

export const resetError = (resource) => dispatch => {
    dispatch({ type: actionTypes.getActionType(resource, actionTypes.RESET_ERROR) })
};

export const setBatchType = (resource, batchType) => dispatch => {
    dispatch({
        type: actionTypes.getActionType(resource, actionTypes.SET_BATCH_TYPE), data: batchType
    })
};
