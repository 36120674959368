import React, { useState } from 'react';
import { LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import { message, Button } from 'antd';

import axios from '../axios/axios';
import errorHandler from '../error/errorHandler';
import { CloneObject } from '../helper/common';
import { ElementTypes } from '../config/element.config';
import Sheet from '../components/custom/sheet/sheet';
import Uploader from '../elements/uploader';

const UploaderTable = () => {
    const [data, setData] = React.useState([]);
    const [loading, setLoading] = useState(false);

    const list = {
        Columns: {
            guId: {
                Title: 'Type',
                Field: ElementTypes.Download('originalFileName')
            },
            fileName: {
                Title: 'File Name',
                Field: ElementTypes.TextField()
            },
            originalFileName: {
                Title: 'Original File Name',
                Field: ElementTypes.TableLabelField()
            }
        }
    };
    const max = 5;
    const restriction = file => {
        const isLt = file.size / 1024 / 1024 < max;
        if (!isLt) {
            message.error(`Image must be smaller than ${max}MB!`);
            return false;
        }
        return true;
    };

    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <Button icon={<UploadOutlined />}>Click to Upload</Button>}
        </div>
    );

    const onChange = result => {
        const newData = CloneObject(data);
        newData.push({
            guId: result.guId,
            fileName: result.originalName,
            originalFileName: result.originalName
        });
        setData(newData);
    };

    return (<>
        <h1>Uploader Table</h1>
        <Sheet
            messageIdentityPrefix='development'
            keyFieldName='guId'
            columns={list.Columns}
            data={data}
            action
        />
        <Uploader
            name='file'
            setLoading={setLoading}
            restrictions={restriction}
            onChange={onChange}
            disabled={false}>
            {uploadButton}
        </Uploader>
    </>);
};

export default errorHandler(UploaderTable, axios);