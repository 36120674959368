import { COMMENTS_BUILD as actionTypes } from '../../actions/comments/commentsActionTypes';
import * as loadArea from './commentsReducer';

const defaultState = {
    loading: true,
    hasError: false,
    message: '',
    comments: null,
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case actionTypes.COMMENTS_LOADING:
            return loadArea.setInLoading(state);
        case actionTypes.GET_COMMENTS:
            return loadArea.getComments(state, action.data);
        case actionTypes.RELOAD_ON_ERROR_COMMENTS:
            return loadArea.getComments(state, action.data.responce, true, action.data.errorText ? action.data.errorText : 'Something go wrong, data reloaded!');
        case actionTypes.COMMENTS_ERROR:
            return loadArea.setError(state, action.data);
        case actionTypes.RESET_ERROR:
            return loadArea.resetError(state);
        case actionTypes.SET_COMMENT:
            return loadArea.setComment(state, action.data.value, action.data.rowState, action.data.header);
        default:
            return state;
    }
};