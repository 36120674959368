import React, { useState } from 'react';
import { connect } from "react-redux";
import Modal from 'react-modal';
import CurrencyInput from 'react-currency-input-field';
import { setItemData } from '../../../../store/actions/loadArea/loadArea';

const LumperModal = ({ 
    state,
    resource,
    setItemData,
    lumperModalIsOpen,
    setLumperModalIsOpen,
    selectedAssignedOrders,
  }) => {
  const [lumperCountText, setLumperCount] = useState('');
  const header = state[resource].headers.find(h => h.key === 'outboundLumper');

  const closeModal = () => {
    setLumperModalIsOpen(false);
  };

  const selectedOrdersLoadsIds = selectedAssignedOrders?.map(order => order.rowState.loadData.id);
  const areOrdersFromDifferentLoads = selectedAssignedOrders.length > 1 && new Set(selectedOrdersLoadsIds).size !== 1;
  const availableForLumper = selectedAssignedOrders?.filter(order => order.rowState.orderData?.outboundDropIn);
  const loadId = selectedAssignedOrders[0].rowState.loadData.id;
  const allOrdersForSelectedLoad = state[resource].loadsAssignedOrders.filter(load => load.rowState.loadData.id === loadId)[0].ordersData;
  const dropLocation = selectedAssignedOrders[0].rowState.orderData.dropLocation;
  const allOrdersWithSameDropLocation = allOrdersForSelectedLoad.filter(order => order.rowState.orderData.dropLocation === dropLocation);
  const thereIsOrderWithOutDropIn = selectedAssignedOrders.length > availableForLumper.length;
  const deliveryLocations = availableForLumper.map(order => order.rowState.orderData?.dropLocation);
  const delInDates = availableForLumper.map(order => {
    const tempDate = new Date(order.rowState.orderData?.outboundDropIn);
    return new Date(tempDate.setHours(tempDate.getHours() + 4 - 24)).toDateString();
  });
  const isNegative = lumperCountText < 0 || !'-' || !'-$';
  const areOrderWithDiffDelLocations = selectedAssignedOrders.length > 1 && new Set(deliveryLocations).size !== 1;
  const areOrderWithDiffDelIndates = selectedAssignedOrders.length > 1 && new Set(delInDates).size !== 1;

  const handleSave = () => {
    allOrdersWithSameDropLocation.forEach((order, i) => {
      setItemData(resource, '', order.rowState, header);
    });
    setTimeout(() => {
      setItemData(resource, lumperCountText, selectedAssignedOrders[0].rowState, header);
    }, 100);
    closeModal();
  }

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      minWidth: '400px',
      minHeight: '200px',      
      borderRadius: '8px',
      padding: 0,
    },
  };

  return (
    <Modal
        isOpen={lumperModalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        ariaHideApp={false}
        >
        <div className='reject_modal__container'>
          <button onClick={closeModal} className='revision__x-button'>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10 0C4.47 0 0 4.47 0 10C0 15.53 4.47 20 10 20C15.53 20 20 15.53 20 10C20 4.47 15.53 0 10 0ZM14.3 14.3C13.91 14.69 13.28 14.69 12.89 14.3L10 11.41L7.11 14.3C6.72 14.69 6.09 14.69 5.7 14.3C5.31 13.91 5.31 13.28 5.7 12.89L8.59 10L5.7 7.11C5.31 6.72 5.31 6.09 5.7 5.7C6.09 5.31 6.72 5.31 7.11 5.7L10 8.59L12.89 5.7C13.28 5.31 13.91 5.31 14.3 5.7C14.69 6.09 14.69 6.72 14.3 7.11L11.41 10L14.3 12.89C14.68 13.27 14.68 13.91 14.3 14.3Z" fill="#678482"/>
            </svg>
          </button>
          <div className='reject_modal_text'>Lumper Amount</div>
          {selectedAssignedOrders.length &&
            !areOrdersFromDifferentLoads &&
            !thereIsOrderWithOutDropIn &&
            !areOrderWithDiffDelIndates &&
            !areOrderWithDiffDelLocations ?
              <CurrencyInput
                    placeholder="Enter Lumper Amount"
                    className='reject-reason__input'
                    defaultValue={lumperCountText}
                    thousandseparator="true"
                    prefix="$"
                    style={{ marginBottom: '4px!important' }}
                    onValueChange={value => setLumperCount(value) }
                    disabled={!selectedAssignedOrders?.length || thereIsOrderWithOutDropIn || areOrderWithDiffDelLocations || areOrderWithDiffDelIndates}
                    >
              </CurrencyInput> : null
          }
          {!selectedAssignedOrders.length && <div className='lumper-error-text'>For Lumper adding, please choose an order</div>}
          {thereIsOrderWithOutDropIn && <div className='lumper-error-text'>The order/orders should have delivery in date</div>}
          {areOrdersFromDifferentLoads && <div className='lumper-error-text'>You can't add Lumper for orders with different loads</div>}
          {!thereIsOrderWithOutDropIn && areOrderWithDiffDelLocations ? <div className='lumper-error-text'>You can't add Lumper for orders with different delivery locations</div> : ''}
          {!thereIsOrderWithOutDropIn && areOrderWithDiffDelIndates && !areOrderWithDiffDelLocations? <div className='lumper-error-text'>You can't add Lumper for orders with different delivery dates</div> : ''}
          {isNegative ? <div className='lumper-error-text'>You can't add negative value</div> : ''}
          <div className='buttons-container__reject-modal'>
            <button className='cancel-modal-button' type="submit" onClick={closeModal}>CANCEL</button>
            <button className={`accept-modal-button ${(!lumperCountText || isNegative) && 'reject-btn_disabled'}`} type="submit" disabled={(!lumperCountText || isNegative)} onClick={handleSave}>CONFIRM</button>
          </div>
        </div>
    </Modal>
  );
};

const mapStateToProps = state => ({
  state: state,
});

const mapDispatchToProps = dispatch => ({
  setItemData: (resource, value, rowState, header) => dispatch(setItemData(resource, value, rowState, header)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LumperModal);