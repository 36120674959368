export const GET_RATE_CONFIRMATION_LOADING = 'GET_RATE_CONFIRMATION_LOADING';
export const GET_RATE_CONFIRMATION_DATA = 'GET_RATE_CONFIRMATION_DATA';
export const GET_RATE_CONFIRMATION_ERROR = 'GET_RATE_CONFIRMATION_ERROR';
export const GET_RATE_CONFIRMATION_PDF_URL = 'GET_RATE_CONFIRMATION_PDF_URL';
export const RESET_ERROR = 'RESET_ERROR';
export const GET_CARRIER_RATE_CONFIRMATION_DATA = 'GET_CARRIER_RATE_CONFIRMATION_DATA';
export const GET_CARRIER_RATE_CONFIRMATION_ERROR = 'GET_CARRIER_RATE_CONFIRMATION_ERROR';

export const getActionType = (resource, key) => {
    return RATE_CONFIRMATION_BUILD[key];
};

export const RATE_CONFIRMATION_BUILD = {
    GET_RATE_CONFIRMATION_LOADING: 'GET_RATE_CONFIRMATION_LOADING',
    GET_RATE_CONFIRMATION_DATA: 'GET_RATE_CONFIRMATION_DATA',
    GET_RATE_CONFIRMATION_ERROR: 'GET_RATE_CONFIRMATION_ERROR',
    GET_RATE_CONFIRMATION_PDF_URL: 'GET_RATE_CONFIRMATION_PDF_URL',
    RESET_ERROR: 'RESET_ERROR',
    GET_CARRIER_RATE_CONFIRMATION_DATA: 'GET_CARRIER_RATE_CONFIRMATION_DATA',
    GET_CARRIER_RATE_CONFIRMATION_ERROR: 'GET_CARRIER_RATE_CONFIRMATION_ERROR',
};
