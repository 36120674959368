export const getContentWidth = headers => {
    return ((headers && headers.length > 0) ?
        headers.reduce((a, b) => a + b.width, 0) :
        0);
};

export const getContentWidthExceptLeftAction = headers => {
    return ((headers && headers.length > 0) ?
        headers.reduce((a, b) => b.leftAction ? a + 0 : a + b.width, 0) :
        0);
};

export const getContentWidthSomePartOnly = (headers, count) => {
    var sum = 0;
    for (let i = 0; i < count; i++) {
        sum += headers[i]?.width;
    }
    return sum;
};