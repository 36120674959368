import React from 'react';
import { connect } from "react-redux";
import Split from "react-split-it";
import CustomerOrderInProgressSpreadsheet from '../CustomerOrderInProgress/CustomerOrderInProgressSpreadsheet';
import CustomerOrderNewOrdersSpreadsheet from '../CustomerOrderNewOrders/CustomerOrderNewOrdersSpreadsheet';
import { getContentWidth } from '../../../../../src/store/reducers/customerOrderNewOrders/helper/general';

const CustomerOrders = ({ state, loadingCustomerOrderNewOrders, loadingCustomerOrderInProgress }) => {    
    const resource = 'customerOrderNewOrders';
    const headers = state[resource].headers;
    const width = getContentWidth(headers);
    const innerWidth = window.innerWidth;

    const smallScreen = width < innerWidth;
    const content = (
        <div className={`splitter custom-scrollBar spreadsheet__margins ${smallScreen ? 'small-spreedsheet__center__active-orders' : '' }`}>
            <Split
                sizes={[50, 50]}
                direction="vertical"
                gutterSize={(loadingCustomerOrderNewOrders && loadingCustomerOrderInProgress) ? 0: 10}>
                <CustomerOrderInProgressSpreadsheet />
                <CustomerOrderNewOrdersSpreadsheet />
            </Split>
        </div>
    );
    return content;
};

const mapStateToProps = state => ({
    state: state,
    loadingCustomerOrderNewOrders: state.customerOrderNewOrders.loading,
    loadingCustomerOrderInProgress: state.customerOrderInProgress.loading,
});

export default connect(mapStateToProps)(CustomerOrders);