export const GET_DELIVERED_TRUCKS_LOADING = 'GET_DELIVERED_TRUCKS_LOADING';
export const GET_DELIVERED_TRUCKS = 'GET_DELIVERED_TRUCKS';
export const FILTER_DELIVERED_TRUCKS = 'FILTER_DELIVERED_TRUCKS';
export const DELIVERED_TRUCKS_ERROR = 'DELIVERED_TRUCKS_ERROR';
export const RELOAD_ON_ERROR_DELIVERED_TRUCKS = 'RELOAD_ON_ERROR_DELIVERED_TRUCKS';
export const RESET_ERROR = 'RESET_ERROR';
export const SET_DISPLAY_STATE_DELIVERED_TRUCKS = 'SET_DISPLAY_STATE_DELIVERED_TRUCKS';
export const SET_HEADER_WIDTH_DELIVERED_TRUCKS = 'SET_HEADER_WIDTH_DELIVERED_TRUCKS';
export const REACTED_ASSIGNED_DELIVERED_TRUCKS = 'REACTED_ASSIGNED_DELIVERED_TRUCKS';
export const SET_DELIVERED_TRUCKS_DATA = 'SET_DELIVERED_TRUCKS_DATA';
export const SORT_DELIVERED_TRUCKS = 'SORT_DELIVERED_TRUCKS';
export const GET_HISTORY_DELIVERED_TRUCKS_LOADING = 'GET_HISTORY_DELIVERED_TRUCKS_LOADING';
export const GET_HISTORY_DELIVERED_TRUCKS = 'GET_HISTORY_DELIVERED_TRUCKS';

export const getActionType = (resource, key) => {
    return DELIVERED_TRUCKS_BUILD[key];
};

export const DELIVERED_TRUCKS_BUILD = {
    GET_DELIVERED_TRUCKS: 'GET_DELIVERED_TRUCKS',
    GET_DELIVERED_TRUCKS_LOADING: 'GET_DELIVERED_TRUCKS_LOADING',
    FILTER_DELIVERED_TRUCKS: 'FILTER_DELIVERED_TRUCKS',
    DELIVERED_TRUCKS_ERROR: 'DELIVERED_TRUCKS_ERROR',
    SORT_DELIVERED_TRUCKS: 'SORT_DELIVERED_TRUCKS',
    RELOAD_ON_ERROR_DELIVERED_TRUCKS: 'RELOAD_ON_ERROR_DELIVERED_TRUCKS',
    SET_HEADER_WIDTH_DELIVERED_TRUCKS: 'SET_HEADER_WIDTH_DELIVERED_TRUCKS',
    SET_DISPLAY_STATE_DELIVERED_TRUCKS: 'SET_DISPLAY_STATE_DELIVERED_TRUCKS',
    REACTED_ASSIGNED_DELIVERED_TRUCKS: 'REACTED_ASSIGNED_DELIVERED_TRUCKS',
    SET_DELIVERED_TRUCKS_DATA: 'SET_DELIVERED_TRUCKS_DATA',
    RESET_ERROR: 'RESET_ERROR',
    GET_HISTORY_DELIVERED_TRUCKS_LOADING: 'GET_HISTORY_DELIVERED_TRUCKS_LOADING',
    GET_HISTORY_DELIVERED_TRUCKS: 'GET_HISTORY_DELIVERED_TRUCKS',
};
