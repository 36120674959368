import React, { useCallback } from 'react';
import { connect } from "react-redux";
import { Resizable } from 'react-resizable';
import { CaretDownOutlined, CaretUpOutlined} from '@ant-design/icons';
import { setHeaderWidth, 
        setSortLoadsByCarriar, 
        setSortLoadsByPickAppts, 
        setSortLoadsByPickLocation, 
        setSortLoadsByDropLocation, 
        setSortLoadsByDeliverNumber } 
    from '../../../../store/actions/loadArea/loadArea';

const HeaderCell = (
    {
        state,
        ind,
        setHeaderWidth,
        resource,
        headerKey,
        setSortLoadsByCarriar,
        setSortLoadsByPickAppts,
        setSortLoadsByPickLocation,
        setSortLoadsByDropLocation,
        setSortLoadsByDeliverNumber,
    }) => {
    const loadArea = state[resource];
    const data = loadArea.headers[ind];
    let cellStyle = {
        maxWidth: `${data.width}px`,
        width: `${data.width}px`,
        minWidth: `${data.width}px`,
        fontSize: '12px',
    };

    if (data.leftAction) {
        cellStyle = {
            ...cellStyle, ...{
                position: 'sticky',
                left: 0
            }
        };
    }
    else if (data.rightAction) {
        cellStyle = {
            ...cellStyle, ...{
                position: 'sticky',
                backgroundColor: '#449996',
                color: '#FFF',
                right: 0,
                borderLeft: '1px solid #000'
            }
        };
    }

    const onResizeStart = (e, s) => {
        console.log('onResizeStart', e, s);
    };

    const onResize = s => {
        setHeaderWidth(resource, ind, s.size.width);
        //console.log('onResize', e, s);
    };

    const onResizeStop = (e, s) => {
        console.log('onResizeStop', e, s);
    };

    const isSorted = resource === 'load' ? state.load.loadsAreSorted : state.outboundload.loadsAreSorted;
    const currentSortedBy = resource === 'load' ? state.load.loadsSortedBy : state.outboundload.loadsSortedBy;
    let arrowType = false;

    if (headerKey === 'carrier') {
        if (isSorted && currentSortedBy === 'carrier') {
            arrowType = isSorted;
        } else {
            arrowType = false
        }
    } else if (headerKey === 'inboundPickAppointment' || headerKey === 'outboundPickAppointment') {
        if (isSorted && currentSortedBy === 'pickAppts') {
            arrowType = isSorted;
        } else {
            arrowType = false
        }
    } else if (headerKey === 'pickLocation') {
        if (isSorted && currentSortedBy === 'pickLocation') {
            arrowType = isSorted;
        } else {
            arrowType = false
        }
    } else if (headerKey === 'dropLocation') {
        if (isSorted && currentSortedBy === 'dropLocation') {
            arrowType = isSorted;
        } else {
            arrowType = false
        }
    } else if (headerKey === 'inDeliverNummber' || headerKey === 'outDeliverNummber') {
        if (isSorted && currentSortedBy === 'deliverNummber') {
            arrowType = isSorted;
        } else {
            arrowType = false
        }
    };

    const handleSortLoadsByCarrier = useCallback(() => {
        if (currentSortedBy !== 'carrier') {
            setSortLoadsByCarriar(resource, true);
        } else {
            setSortLoadsByCarriar(resource, !isSorted);
        }
    }, [resource, setSortLoadsByCarriar, isSorted, currentSortedBy]);

    const handleSortLoadsByPickAppts = useCallback(() => {
        if (currentSortedBy !== 'pickAppts') {
            setSortLoadsByPickAppts(resource, true);
        } else {
            setSortLoadsByPickAppts(resource, !isSorted);
        }
    }, [resource, setSortLoadsByPickAppts, isSorted, currentSortedBy]);

    const handleSortLoadsByPickLocation = useCallback(() => {
        if (currentSortedBy !== 'pickLocation') {
            setSortLoadsByPickLocation(resource, true);
        } else {
            setSortLoadsByPickLocation(resource, !isSorted);
        }
    }, [resource, setSortLoadsByPickLocation, isSorted, currentSortedBy]);

    const handleSortLoadsByDropLocation = useCallback(() => {
        if (currentSortedBy !== 'dropLocation') {
            setSortLoadsByDropLocation(resource, true);
        } else {
            setSortLoadsByDropLocation(resource, !isSorted);
        }
    }, [resource, setSortLoadsByDropLocation, isSorted, currentSortedBy]);

    const handleSortLoadsByDeliverNumber = useCallback(() => {
        if (currentSortedBy !== 'deliverNummber') {
            setSortLoadsByDeliverNumber(resource, true);
        } else {
            setSortLoadsByDeliverNumber(resource, !isSorted);
        }
    }, [resource, setSortLoadsByDeliverNumber, isSorted, currentSortedBy]);

    let headerCall;
    let headerCallFunction = sortingFunction => {
        return (
            <div
                className='table-header-cell'
                style={cellStyle}>
                <>{data.title}<span style={{ marginLeft: '4px', cursor: 'pointer'}} onClick={sortingFunction}>{arrowType ? <CaretUpOutlined /> : <CaretDownOutlined />}</span></>
            </div>
        )
    }

    if (headerKey === 'carrier') {
        headerCall = headerCallFunction(handleSortLoadsByCarrier);
    } else if (headerKey === 'inboundPickAppointment' || headerKey === 'outboundPickAppointment') {
        headerCall = headerCallFunction(handleSortLoadsByPickAppts);
    } else if (headerKey === 'pickLocation') {
        headerCall = headerCallFunction(handleSortLoadsByPickLocation);
    } else if (headerKey === 'dropLocation') {
        headerCall = headerCallFunction(handleSortLoadsByDropLocation);
    } else if (headerKey === 'inDeliverNummber' || headerKey === 'outDeliverNummber') {
        headerCall = headerCallFunction(handleSortLoadsByDeliverNumber);
    } else {
        headerCall = (
            <div
                className='table-header-cell'
                style={cellStyle}>
                {data.title}
            </div>
        )
    };

    const resizableHeaderCall = (
        <Resizable
            width={data.width}
            height={0}
            handle={
                <span
                    className="react-resizable-handle"
                    onClick={e => {
                        e.stopPropagation();
                    }}
                />
            }
            onResizeStart={(_, s) => onResizeStart(s)}
            onResize={(_, s) => onResize(s)}
            onResizeStop={(_, s) => onResizeStop(s)}
            draggableOpts={{ enableUserSelectHack: false }}
        >
            {headerCall}
        </Resizable>
    );
    return data.leftAction || data.rightAction ? headerCall : resizableHeaderCall;
};

export default connect(state => ({
    state: state
}), dispatch => ({
    setHeaderWidth: (resource, ind, width) => dispatch(setHeaderWidth(resource, ind, width)),
    setSortLoadsByCarriar: (resource, value) => dispatch(setSortLoadsByCarriar(resource, value)),
    setSortLoadsByPickAppts: (resource, value) => dispatch(setSortLoadsByPickAppts(resource, value)),
    setSortLoadsByPickLocation: (resource, value) => dispatch(setSortLoadsByPickLocation(resource, value)),
    setSortLoadsByDropLocation: (resource, value) => dispatch(setSortLoadsByDropLocation(resource, value)),
    setSortLoadsByDeliverNumber: (resource, value) => dispatch(setSortLoadsByDeliverNumber(resource, value)),
}))(HeaderCell);