import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useHistory } from "react-router-dom";
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { VariableSizeList } from 'react-window';
import { message } from 'antd';
import { getOrdersByCustomer, resetError, createNewGroup } from '../../../../store/actions/orders/orders';
import Loading from '../../screen/loading';
import HeaderRow from './headerRow';
import Row from './row';
import './index.css';

const GroupOrdersListModal = ({
  setGroupOrdersListModalIsOpen,
  groupOrdersListModalIsOpen,
  setGroupOrdersModalIsOpen,
  loading,
  loadsAssignedOrders,
  getOrdersByCustomer,
  actionMessage,
  hasError,
  values,
  createNewGroup,
  batchType,
  newGroupCreated,
  resetError,
  newGroupId,
}) => {
  const resource = 'orders';
  const defRowHeight = 20;
  const createGroupEndPoint = 'createnewgroup';
  const [orders, setOrders] = useState([]);
  const [data, setData] = useState();
  const [checkedList, setCheckedList] = useState([]);
  const history = useHistory();

  useEffect(() => {
    setData(values);
  }, [values]);

  useEffect(() => {
    if (data?.customer) getOrdersByCustomer('order', 'availableorderlist', data?.customer);
  }, [getOrdersByCustomer, data]);
  
  useEffect(() => {
    setGroupOrdersModalIsOpen(false);
  }, [setGroupOrdersModalIsOpen]);

  useEffect(()=>{
    if(loadsAssignedOrders){
      setOrders(loadsAssignedOrders)
    }
  },[loadsAssignedOrders])

  const closeModal = () => {
    setGroupOrdersListModalIsOpen(false);
  };

  const customStyle = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      minWidth: '800px',
      minHeight: '600px',
      maxWidth: '1000px',
      borderRadius: '8px',
      padding: loading ? '0 0 8px 0' : '0',
    },
  };

  const handleSubmit = () => {
    createNewGroup('order', createGroupEndPoint, checkedList, batchType);
  };

  useEffect(() => {
      if (hasError) {
          message.error(actionMessage);
          resetError(resource);
      }
  }, [hasError, actionMessage, resetError, resource]);

  useEffect(() => {
    if (newGroupCreated) {
        message.success(actionMessage, 3);
        resetError(resource);
        setGroupOrdersListModalIsOpen(false);
        const path = `orderdetails/${newGroupId}`; 
        history.push(path);
      }
  }, [newGroupCreated, actionMessage, history, newGroupId, resetError, setGroupOrdersListModalIsOpen]);

  const header = useMemo(() =>
      <div style={{ overflow: 'hidden' }}>
          <div
              style={{
                  width: '100%',
                  overflow: 'hidden'
              }}>
              <HeaderRow resource={resource} />
          </div>
      </div>,
  [resource]);

  const getAsignedItemSize = useCallback(() => {
      return index => orders[index] ? orders[index].rowState.height : defRowHeight;
  }, [orders]);

  const RenderAssignedRow = useCallback(({ data, index, style }) => {
    return (index !== undefined && data[index])
      ? <Row
          data={data[index]}
          itemSize={getAsignedItemSize(index)}
          style={style}
          resource={resource}
          setCheckedList={setCheckedList}
          checked={checkedList && checkedList.includes(data[index]?.rowState?.orderObjectId)}
      />
      : null
    }, [getAsignedItemSize, resource, checkedList]);      

  const allOrdersList = useMemo(() => orders && 
        <VariableSizeList
            style={{ overflowX: 'hidden', overflowY: 'auto' }}
            itemData={orders}
            itemCount={orders.length}
            height={orders.length * defRowHeight + 2}
            itemSize={() => defRowHeight}>
            {RenderAssignedRow}
        </VariableSizeList>
    , [orders, RenderAssignedRow]);

  if (loading) {
    return (
      <Modal isOpen={groupOrdersListModalIsOpen} style={customStyle}>
        <div style={{ marginTop: '35% ' }}>
          <Loading />
        </div>
      </Modal>
    );
  }

  return (
    <Modal isOpen={groupOrdersListModalIsOpen} onRequestClose={closeModal} style={customStyle} ariaHideApp={false}>
      <div className='create-group-modal__container'>
        <div className='group_modal__title-container'>Group Orders</div>
        <div style={{ display: 'flex' }}>{header}</div>
        <div style={{ flexGrow: 1, width: '100%' }}>{allOrdersList}</div>
        <div className='modal-form__load-details formik_form__load-details group-orders-btn__container'>
          <button 
              disabled={checkedList.length < 2} 
              className={`formik_btn__create-group ${checkedList.length < 2 && 'create-group-disabled'} `} 
              type='submit' 
              onClick={handleSubmit}>
              GENERATE NEW GROUP</button>
          <button className='formik_btn__create-group' type='submit' onClick={closeModal}>CANCEL</button>
        </div>
      </div>
    </Modal>
  )
};

const mapStateToProps = state => ({
  state: state,
  loadsAssignedOrders: state.orders.loadsAssignedOrders,
  loading: state.orders.loading,
  hasError: state.orders.hasError,
  actionMessage: state.orders.message,
  batchType: state.orders.batchType,
  newGroupCreated: state.orders.newGroupCreated,
  newGroupId: state.orders.newGroupId,
});

const mapDispatchToProps = dispatch => ({
  getOrdersByCustomer: (resource, endpoint, customerId) => dispatch(getOrdersByCustomer(resource, endpoint, customerId)),
  createNewGroup: (resource, endPoint, ids, batchType) => dispatch(createNewGroup(resource, endPoint, ids, batchType)),
  resetError: (resource) => dispatch(resetError(resource)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupOrdersListModal);
