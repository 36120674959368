import React from 'react';

import Sheet from '../components/custom/sheet/sheet';
import Permission from '../components/project/permission/permission';
import Order from '../components/project/order/order';
import WorkingDays from '../components/project/workingDays/WorkingDays';
import Expandable from '../components/custom/table/expandable';
import Attachments from '../components/project/attachments/attachments';
import ManagedSheet from '../components/custom/managedSheet/managedSheet';
import RateConfirmationForm from '../components/project/rateConfirmation/form';
import RateConfirmation from '../components/project/rateConfirmation';
import CarrierPackage from '../components/project/carrierPackage';
import MultipleCheckbox from '../components/project/MultipleCheckbox';
import Locations from '../components/project/Locations';

export const GenerateSheet = (type, keyFieldName, columns, data, dispatch, messages, messageDispatch, classes) =>
    <Sheet type={type}
        keyFieldName={keyFieldName}
        columns={columns}
        data={data}
        dispatch={dispatch}
        messages={messages}
        messageDispatch={messageDispatch}
        classes={classes}
    />;

export const GenerateRateConfirmationForm = (type, keyFieldName, columns, data, dispatch, messages, messageDispatch, classes) =>
    <RateConfirmationForm type={type}
        keyFieldName={keyFieldName}
        columns={columns}
        data={data}
        dispatch={dispatch}
        messages={messages}
        messageDispatch={messageDispatch}
        classes={classes}
    />;

export const GenerateRateConfirmation = (type, keyFieldName, columns, data, dispatch, messages, messageDispatch, classes) =>
    <RateConfirmation type={type}
        keyFieldName={keyFieldName}
        columns={columns}
        data={data}
        dispatch={dispatch}
        messages={messages}
        messageDispatch={messageDispatch}
        classes={classes}
    />;

export const GenerateCarrierPackage = (type, keyFieldName, columns, data, dispatch, messages, messageDispatch, classes) =>
    <CarrierPackage type={type}
        keyFieldName={keyFieldName}
        columns={columns}
        data={data}
        dispatch={dispatch}
        messages={messages}
        messageDispatch={messageDispatch}
        classes={classes}
    />;

export const GenerateCommentsSheet = (type, keyFieldName, columns, data, dispatch, messages, messageDispatch, classes) =>
    <Sheet type={type}
        colgroup
        keyFieldName={keyFieldName}
        columns={columns}
        data={data}
        dispatch={dispatch}
        messages={messages}
        messageDispatch={messageDispatch}
        classes={classes}
    />;

export const GeneratePermissionSheet = (type, keyFieldName, columns, data, dispatch, messages, messageDispatch) =>
    <Permission type={type}
        keyFieldName={keyFieldName}
        columns={columns}
        data={data}
        dispatch={dispatch}
        messages={messages}
        messageDispatch={messageDispatch}
    />;
export const GenerateWorkingDays = (type, keyFieldName, columns, data, dispatch, messages, messageDispatch, componentRefs, messageIdentityPrefix, object) =>
    <WorkingDays
        type={type}
        keyFieldName={keyFieldName}
        columns={columns}
        data={data}
        dispatch={dispatch}
        messages={messages}
        messageDispatch={messageDispatch}
        object={object}
    />;
export const GenerateExpandeble = (type, config, keyFieldName, data, dispatch, messages, messageDispatch, componentRefs, messageIdentityPrefix, object) =>
    <Expandable ref={ref => { componentRefs.current[messageIdentityPrefix == null ? `${type}` : `${messageIdentityPrefix}_${type}`] = ref; }} // TODO: Avoide Imperative ref usage
        messageIdentityPrefix={messageIdentityPrefix}
        config={config}
        keyFieldName={keyFieldName}
        type={type}
        data={data}
        dispatch={dispatch}
        messages={messages}
        messageDispatch={messageDispatch}
        object={object}
    />;
export const GenerateOrder = (type, keyFieldName, config, data, dispatch, messages, messageDispatch, componentRefs, messageIdentityPrefix, object) =>
    <Order ref={ref => { componentRefs.current[messageIdentityPrefix == null ? `${type}` : `${messageIdentityPrefix}_${type}`] = ref; }}
        messageIdentityPrefix={messageIdentityPrefix}
        config={config}
        keyFieldName={keyFieldName}
        type={type}
        data={data}
        dispatch={dispatch}
        messages={messages}
        messageDispatch={messageDispatch}
        object={object}
    />;
export const GenerateManagedSheet = (type, keyFieldName, config, data, dispatch, messages, messageDispatch, componentRefs, messageIdentityPrefix, object) =>
    <ManagedSheet ref={ref => { componentRefs.current[messageIdentityPrefix == null ? `${type}` : `${messageIdentityPrefix}_${type}`] = ref; }}
        messageIdentityPrefix={messageIdentityPrefix}
        config={config}
        keyFieldName={keyFieldName}
        type={type}
        data={data}
        dispatch={dispatch}
        messages={messages}
        messageDispatch={messageDispatch}
        object={object}
    />;
export const GenerateAttachment = (type, keyFieldName, config, data, dispatch, messages, messageDispatch, componentRefs, messageIdentityPrefix, object) =>
    <Attachments ref={ref => { componentRefs.current[messageIdentityPrefix == null ? `${type}` : `${messageIdentityPrefix}_${type}`] = ref; }}
        messageIdentityPrefix={messageIdentityPrefix}
        config={config}
        keyFieldName={keyFieldName}
        type={type}
        data={data}
        dispatch={dispatch}
        messages={messages}
        messageDispatch={messageDispatch}
        object={object}
    />;

// export const GenerateLoad = (type, keyFieldName, config, data, dispatch, messages, messageDispatch, componentRefs, messageIdentityPrefix, object) =>
//     <Load ref={ref => { componentRefs.current[messageIdentityPrefix == null ? `${type}` : `${messageIdentityPrefix}_${type}`] = ref; }}
//         messageIdentityPrefix={messageIdentityPrefix}
//         config={config}
//         keyFieldName={keyFieldName}
//         type={type}
//         data={data}
//         dispatch={dispatch}
//         messages={messages}
//         messageDispatch={messageDispatch}
//         object={object}
//     />;

export const GenerateCheckBoxMultiple = (type, data, dispatch) => {
    return <MultipleCheckbox
        type={type}
        data={data}
        dispatch={dispatch}
    />;
}

export const GenerateLocations = (type, data, dispatch) => {
    return <Locations
        type={type}
        data={data}
        dispatch={dispatch}
    />;
}