import React, { useState, useEffect, useCallback, useRef } from 'react';
import { connect } from "react-redux";
import ResizeObserver from 'rc-resize-observer';
import { Table, message } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import axios from '../../axios/axios';
import ListScreen from '../screen/listScreen';
import Filter from './filter/filter';
import { Action } from '../../config';
import { useDelete, useDeleteReason, useCheckReference, useSONUData } from '../../hook/action/delete';
import { Columns, getListCriteria, getCriteriaQuery, buildListSortPaginationQuery } from '../../helper/list';
import { setProfileColumnWidth, saveProfileColumnWidth } from '../../../store/actions/authentication/authentication';
import { Project } from '../../../project/Project';
import { AppPages } from '../../../project/Pages';
import { Defines } from '../../../project/Defines';
import { openPopup, closePopup, closePopupAsync } from '../../../store/actions/popup/popup';
import { PopupTypes } from '../custom/popup/popup';
import GroupOrdersModal from './GroupOrder/GroupOrdersModal';
import GroupOrdersListModal from './GroupOrder/GroupOrdersListModal';
import { useClientHubOrder } from "../../hook/hub/useClientHubOrder";

export const GridContext = React.createContext();

const List = props => {
    const config = props.screen;
    const { DisableDownload, ImportGSheet, ExportGSheet } = config.List;
    const screenKey = config.Key;
    const screenTitle = config.Title;
    const master = config.Master;
    const resourceItem = config.ResourceItem;
    const resource = config.Resource;
    const openPopup = props.openPopup;
    const closePopup = props.closePopup;

    const history = useHistory();
    const criteriaQuery = getCriteriaQuery(history);
    const location = useLocation();

    const viewConfig = config.List.Defaults.View;
    const tableConfig = config.List.Defaults.Table;
    const hasPaging = !!props.screen.List.Pagination;
    const pagination = props.screen.List.Pagination;
    const paginationCurrent = props.screen.List.Pagination?.Current;
    const paginationPageSize = props.screen.List.Pagination?.PageSize;
    const usePopup = config.UsePopup;
    const parentField = config.ParentField;
    const parentValue = config.ParentValue;

    const [dataSource, setDataSource] = useState([]); // Change to useData
    const [total, setTotal] = useState(0);
    const [isLoading, setLoading] = useState(true);
    const [isDeleting, setDeleting] = useState(false);
    const [resizeMode, ] = useState(false);
    const [tableWidth, setTableWidth] = useState(0);
    const [hasFormListScreen, setHasFormListScreen] = useState(props.screen.List.hasFormListScreen);
    const criteria = useCallback(() => getListCriteria(criteriaQuery), [criteriaQuery]);
    const [groupOrdersModalIsOpen, setGroupOrdersModalIsOpen] = useState(false);
    const [groupOrdersListModalIsOpen, setGroupOrdersListModalIsOpen] = useState(false);
    const [createGroupValues, setCreateGroupValues] = useState();

    useClientHubOrder((message) => {
        if (props.userId !== message.byId) {
            loadData(true);
        }
    }, 'notification');

    const historyStateManager = useCallback(() => {
        const historyState = history.location.state;

        if (historyState) {
            history.replace({ pathname: location.pathname, search: location.search, state: null });
            if (historyState?.message && historyState?.message === 'OrderHeader has been successfully canceled!') {
                message.success('Order canceled successfully!');
            } else {
                historyState?.message && message.success(historyState.message);
            }
        }
    }, [history, location]);

    const updateGrid = useCallback(response => {
        if (response) {
            const result = response.data;
            if (result) {
                const data = result.data;
                const total = result.total;
                for (var i = 0; i < data.length; i++) {
                    if (!data[i].key) {
                        data[i].key = data[i][screenKey || 'id'];
                    }
                };
                setDataSource(data);
                setTotal(total);
            }
        }
    }, [screenKey]);

    const loadData = useCallback((hideLoading) => {
        historyStateManager();
        setLoading(!hideLoading);
        const criteriaResult = criteria();
        const page = criteriaResult.current;
        const pageSize = criteriaResult.pageSize;
        const sorts = criteriaResult.sorts;
        const filterJson = criteriaResult.filter;

        if (parentField) {
            filterJson[parentField] = parentValue;
        }

        const filterData = JSON.stringify(filterJson);
        axios.get(resource, {
            params: {
                request: {
                    Page: page ? page : 1,
                    PageSize: pageSize ? pageSize : 100,
                    Sorts: sorts
                },
                filterData: filterData
            }
        })
            .then(response => {
                updateGrid(response);
            }).finally(() => {
                setLoading(false);
            });
    }, [criteria, historyStateManager, resource, updateGrid, parentField, parentValue]);

    const deleteData = useDelete(screenTitle, resourceItem || resource, history.location.pathname, history.location.search, setDeleting, loadData);
    const deleteReasonData = useDeleteReason(screenTitle, props.screen.ReasonConfig?.ResourceItem || props.screen.ReasonConfig?.Resource, props.screen.ReasonConfig, history.location.pathname, history.location.search, setDeleting, loadData);
    const checkReference = useCheckReference(deleteData, resource);
    const SONUData = useSONUData(screenTitle, props.screen.ReasonConfig?.ResourceItem || props.screen.ReasonConfig?.Resource, props.screen.ReasonConfig, history.location.pathname, history.location.search);

    const onAction = useCallback((data, action, hasReference, setGroupOrdersModalIsOpen) => {
        switch (action) {
            case Action.Edit:
            case Action.View:
                // const detailUrl = Project.getPageUrl(AppPages[resource + Defines.DetailsComponentSufix]);
                // history.push({
                //     pathname: detailUrl + '/' + data.id,
                //     state: {
                //         search: history.location.search
                //     }
                // });
                config.Form.DefaultId = data.id;
                const windowKey = 'Details';
                openPopup({
                    windowKey,
                    title: "Order",
                    type: PopupTypes.ConfigForm,
                    bodyProps: { windowKey },
                    config: config,
                    saveAction: (response, close) => {
                        const newSourceData = [...dataSource];
                        const originalItem = newSourceData.find(i => i.id === response.id);
                        const index = newSourceData.indexOf(originalItem);
                        response.key = response.id;
                        if (index !== -1) {
                            newSourceData.splice(index, 1, response);
                            setDataSource(newSourceData);
                        } else {
                            loadData();
                        }
                        if (close) {
                            setTimeout(() => {
                                closePopup(windowKey);
                            }, 10);
                        }
                    },
                    closeAction: () => {
                        closePopup(windowKey);
                    }
                });
                break;
            case Action.Cancel:
                deleteReasonData(data[master], data[screenKey], data);
                break;
            case Action.Delete:
                //deleteData(data[master], data[screenKey]);
                checkReference(data[master], data[screenKey], data.id, hasReference);
                break;
            case Action.Invoice:
                axios({
                    url: `${resource}/invoice`,
                    method: 'PUT',
                    data: {
                        id: data.id
                    }
                })
                    .then((response) => {
                        if (response.data.hasError) {
                            message.error(response.data.message);
                            return;
                        }
                        message.success(response.data.message);
                        const newSourceData = [...dataSource];
                        const originalItem = newSourceData.find(i => i.id === response.data.id);
                        const index = newSourceData.indexOf(originalItem);
                        newSourceData.splice(index, 1);
                        setDataSource(newSourceData);
                    });
                break;
            case Action.NeedToClarify:
                axios({
                    url: `${resource}/needtoclarify?orderId=${data.id}`,
                    method: 'PUT',
                })
                    .then((response) => {
                        if (response.data.hasError) {
                            message.error(response.data.message);
                            return;
                        }
                        // message.success(response.data.message);
                        const newSourceData = [...dataSource];
                        const originalItem = newSourceData.find(i => i.id === data.id);
                        const index = newSourceData.indexOf(originalItem);
                        originalItem.isNeedToClarify = !originalItem.isNeedToClarify
                        newSourceData[index] = originalItem;
                        setDataSource(newSourceData);
                    });
                break;
            case Action.SONUCancelation:
                SONUData(data[master], data[screenKey], data);
                break;
            case Action.Complete:
                axios({
                    url: `${resource}/complete`,
                    method: 'PUT',
                    data: {
                        id: data.id
                    }
                })
                    .then((response) => {
                        if (response.data.hasError) {
                            message.error(response.data.message);
                            return;
                        }
                        message.success(response.data.message);
                        const newSourceData = [...dataSource];
                        const originalItem = newSourceData.find(i => i.id === response.data.id);
                        const index = newSourceData.indexOf(originalItem);
                        newSourceData.splice(index, 1);
                        setDataSource(newSourceData);
                    });
                break;
            default:
                break;
        }
    }, [config, openPopup, deleteReasonData, master, screenKey, checkReference, resource, SONUData, dataSource, loadData, closePopup]);

    const onResizeStart = useCallback(() => {
        // setResizeMode(true);
    }, []);

    const onResize = useCallback(({ size }, field) => {
        const selectedColumns = [];
        selectedColumns.push({
            name: field,
            width: size.width
        });
        props.setColumnWidth(resource, selectedColumns);
    }, [props, resource]);

    const onResizeStop = useCallback(({ size }, field) => {
        // setResizeMode(false);
        const selectedColumns = [];
        selectedColumns.push({
            name: field,
            width: Math.floor(size.width)
        });
        props.saveColumnWidth(resource, selectedColumns);
    }, [props, resource]);

    const columnsSort = useCallback(() =>
        Columns({ resizable: true, ...props.screen },
            onAction,
            null, null,
            //dataSource, setDataSource,
            criteria().sorts,
            props.profileSettings, onResize, onResizeStop, onResizeStart, resizeMode, tableWidth),
        [criteria, props.screen, onAction, props.profileSettings, onResize, onResizeStop, onResizeStart, resizeMode, tableWidth]);

    const columns = columnsSort()[0];

    const sorts = useRef(columnsSort()[1]);

    useEffect(() => {
        let isMounted = true;
        const fetchData = async () => {
            const criteriaResult = criteria();
            const page = criteriaResult.current;
            const pageSize = criteriaResult.pageSize;
            const sorts = criteriaResult.sorts;
            const filterJson = criteriaResult.filter;

            if (parentField) {
                filterJson[parentField] = parentValue;
            }

            const filterData = JSON.stringify(filterJson);
            historyStateManager();
            if (isMounted && !props.screen.changesCount) {
                setLoading(true);
            }
            const response = await axios(
                resource,
                {
                    params: {
                        request: {
                            Page: page ? page : 1,
                            PageSize: pageSize ? pageSize : 100,
                            Sorts: sorts
                        },
                        filterData: filterData
                    }
                }
            ).finally(() => {
                if (isMounted) {
                    setLoading(false);

                }
            });
            if (isMounted) {
                updateGrid(response);
            }
        };
        if ((hasPaging && criteria().current === null) || hasFormListScreen) {

            history.replace({
                search: buildListSortPaginationQuery(criteriaQuery,
                    sorts.current,
                    paginationCurrent,
                    paginationPageSize,
                    paginationPageSize)
            });
            hasFormListScreen && setHasFormListScreen(false);
        }
        else {
            fetchData();
        }
        return () => { isMounted = false };
    }, [criteriaQuery, updateGrid, criteria, history, historyStateManager,
        hasPaging,
        paginationCurrent,
        paginationPageSize,
        resource, sorts, props.screen.changesCount,
        hasFormListScreen,
        parentField, parentValue]);

    const onNewClickHandler = () => {
        if (usePopup) {
            onAction({ id: 0 }, Action.Edit)
            return;
        }
        
        const detailUrl = Project.getPageUrl(AppPages[resource + Defines.DetailsComponentSufix]);
        history.push({
            pathname: detailUrl + '/' + Defines.DefaultCreateKey,
            state: {
                search: history.location.search
            }
        });        
    };

    const onCreateGroupClickHandle = () => {
        setGroupOrdersModalIsOpen(true);
    };

    if (hasPaging && criteria().current === null) {
        return null;
    }

    let pageSizeOptions = pagination ? [...pagination?.PageSizeOptions] : [];
    let newPageSizeOptions = ['100', '500'];

    if (total > pageSizeOptions[pageSizeOptions.length - 1]) {
        switch (true) {
            case total > 40 && total < 100:
                pageSizeOptions.push(total);
                break;
            case total >= 100 && total < 500:
                pageSizeOptions.push('100', total);
                break;
            case total >= 500 && total < 1000:
                pageSizeOptions = pageSizeOptions.concat(newPageSizeOptions, total);
                break;
            case total >= 1000 && total < 10000:
                pageSizeOptions = pageSizeOptions.concat(newPageSizeOptions, '1000', total);
                break;
            default:
                pageSizeOptions = pageSizeOptions.concat(newPageSizeOptions, '1000', '10000', total);
        }
    }

    const filterJson = criteria().filter;
    if (parentField) {
        filterJson[parentField] = parentValue;
    }

    const listContent = <ResizeObserver
        onResize={(e) => {
            setTableWidth(e.width);
        }}
    >
        <Table
            loading={isLoading || isDeleting}
            style={{ overflow: 'scroll'}}
            dataSource={dataSource}
            columns={columns}
            pagination={hasPaging && {
                current: Math.min(Math.floor(total / criteria().pageSize) + 1, criteria().current),
                pageSizeOptions: pageSizeOptions,
                pageSize: criteria().pageSize,
                showSizeChanger: props.screen.List.Pagination.ShowSizeChanger,
                total: total,
                hideOnSinglePage: false,
                size: 'default'
            }}
            onChange={(pagination, filters, sorter) => {
                if (resizeMode) {
                    return;
                }
                setDataSource([]);
                const sort = (sorter.column &&
                    [
                        {
                            Member: sorter.field[0],
                            SortDirection: sorter.order === 'ascend' ? 0 : 1
                            // SortDirection: sorter.field[0] === 'outPodRequestStatus' ? 1 : sorter.order === 'ascend' ? 0 : 1
                        }
                    ]) || null;
                if (hasPaging) {
                    history.push({
                        search: buildListSortPaginationQuery(criteriaQuery, sort, pagination.current, pagination.pageSize, props.screen.List.Pagination.PageSize)
                    });
                }
            }}
            sorts={criteria().sorts}
            {...tableConfig.Style}
        />
    </ResizeObserver>;

    const tableContent = props.screen.List.formList ? <>
        {listContent}
    </> : <view test1=""
        auto_height={viewConfig.AutoHeight && ''}
        small={viewConfig.Small && ''}>
        {listContent}
    </view>;

    const filterableTable = <view test3="" vertical=''>
        {props.screen.List.Filter ?
            <Filter
                filtersConfig={props.screen.List.Filter}
                data={dataSource}
            /> : null}
        <view test2="">
            {tableContent}
        </view>
    </view>;

    return (
        <>
            <GridContext.Provider value={{ screen: resource, columns: props.screen.List.Columns, filter: filterJson, sorts: criteria().sorts}}>
            <ListScreen isFormList={props.screen.List.formList}
                loadData={loadData}
                hideSettings={viewConfig.HideSettings}
                allowNewButton={viewConfig.AllowNewButton}
                allowCreateGroupButton={viewConfig.AllowCreateGroupButton}
                search={props.screen.List.Search}
                showRefreshButton={props.screen.List.ShowRefreshButton}
                isHiddenSwitch={props.screen.List.isHiddenSwitch}
                screen={resource}
                addButtonText={props.screen.List.AddButtonText}
                columns={props.screen.List.Columns}
                title={props.screen.Title}
                showTitleOnGrid={props.screen.ShowTitleOnGrid}
                showTitle={props.screen.ShowTitle }
                download={props.download && !DisableDownload}
                importGSheet={ImportGSheet}
                exportGSheet={ExportGSheet}
                columnConfig={props.columnConfig}
                onNewClick={onNewClickHandler}
                onCreateGroupClick={onCreateGroupClickHandle}
                HasSubNavigation={props.screen.HasSubNavigation}
                >
                {
                    props.screen.List.Filter ? filterableTable : tableContent
                }
            </ListScreen>
        </GridContext.Provider>
        {groupOrdersModalIsOpen && 
            <GroupOrdersModal
                groupOrdersModalIsOpen={groupOrdersModalIsOpen}
                setGroupOrdersModalIsOpen={setGroupOrdersModalIsOpen}
                groupOrdersListModalIsOpen={groupOrdersListModalIsOpen}
                setGroupOrdersListModalIsOpen={setGroupOrdersListModalIsOpen}
                setCreateGroupValues={setCreateGroupValues}
            />
        }
        {groupOrdersListModalIsOpen && 
            <GroupOrdersListModal
                groupOrdersListModalIsOpen={groupOrdersListModalIsOpen}
                setGroupOrdersListModalIsOpen={setGroupOrdersListModalIsOpen}
                setGroupOrdersModalIsOpen={setGroupOrdersModalIsOpen}
                values={createGroupValues}
            />
        }
        </>
    )
};

const mapStateToProps = state => {
    return {
        profileSettings: state.auth.profileSettingsTemp,
        userId: state.auth.userId
    }
};

const mapDispatchToProps = dispatch => {
    return {
        openPopup: data => dispatch(openPopup(data)),
        closePopup: key => dispatch(closePopup(key)),
        setColumnWidth: (resource, columns) => dispatch(setProfileColumnWidth(resource, columns)),
        saveColumnWidth: (resource, columns) => dispatch(saveProfileColumnWidth(resource, columns)),
        openConfirm: data => dispatch(data),
        closePopupAsync: key => dispatch(closePopupAsync(key)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(List);