import axios from 'axios';
import store from '../..';
import * as actionTypes from './customerOrderActionTypes';

const buildUrl = (resource = 'CustomerOrder', endPoint) => `/api/${resource}/${endPoint}`;
const buildDeleteUrl = (resource = 'CustomerOrder', id) => `/api/${resource}?id=${id}`;
const buildPostUrl = (resource = 'CustomerOrder') => `/api/${resource}`;
const buildPutUrl = (resource = 'CustomerOrder') => `/api/${resource}`;

const axiosConfig = () => ({
  headers: {
    Authorization: 'Bearer ' + store.getState().auth.userToken,
  },
});

export const customerOrderNewOrders = (resource, endPoint) => dispatch => {
  dispatch({ type: actionTypes.getActionType(resource, actionTypes.CUSTOMER_ORDERS_LOADING) });
  axios
    .get(buildUrl(resource, endPoint), axiosConfig())
    .then(responce => dispatch({ type: actionTypes.getActionType(resource, actionTypes.GET_CUSTOMER_ORDERS_NEW_ORDERS), data: responce }))
    .catch(err => {
      console.error(err);
      console.log(err.response);
      dispatch({ type: actionTypes.getActionType(resource, actionTypes.ERROR) });
    });
};

export const deleteCustomerOrder = (resource, data) => dispatch => {
  dispatch({ type: actionTypes.getActionType(resource, actionTypes.DELETE_CUSTOMER_ORDER_LOADING) });
  axios
    .delete(buildDeleteUrl(resource, data), axiosConfig())
    .then(responce => dispatch({ type: actionTypes.getActionType(resource, actionTypes.DELETE_CUSTOMER_ORDER), data: responce }))
    .catch(err => {
      console.error(err);
      console.log(err.response);
      dispatch({ type: actionTypes.getActionType(resource, actionTypes.ERROR) });
    });
};

export const addCustomerOrder = (resource, data) => dispatch => {
  dispatch({ type: actionTypes.getActionType(resource, actionTypes.ADD_CUSTOMER_ORDER_LOADING) });
  axios
    .post(buildPostUrl(resource), data, axiosConfig())
    .then(responce => dispatch({ type: actionTypes.getActionType(resource, actionTypes.ADD_CUSTOMER_ORDER), data: responce }))
    .catch(err => {
      console.error(err);
      console.log(err.response);
      dispatch({ type: actionTypes.getActionType(resource, actionTypes.ERROR) });
    });
};

export const editCustomerOrder = (resource, data) => dispatch => {
  dispatch({ type: actionTypes.getActionType(resource, actionTypes.EDIT_CUSTOMER_ORDER_LOADING) });
  axios
    .put(buildPutUrl(resource), data, axiosConfig())
    .then(responce => dispatch({ type: actionTypes.getActionType(resource, actionTypes.EDIT_CUSTOMER_ORDER), data: responce }))
    .catch(err => {
      console.error(err);
      console.log(err.response);
      dispatch({ type: actionTypes.getActionType(resource, actionTypes.ERROR) });
    });
};

export const setFormValues = (resource, formValues) => dispatch => {
  dispatch({
      type: actionTypes.getActionType(resource, actionTypes.SET_FORM_VALUES), data: formValues
  });
}

export const sortBy = (resource, sortValue) => dispatch => {
  dispatch({
      type: actionTypes.getActionType(resource, actionTypes.SORT_ORDERS_NEW_ORDERS), data: sortValue
  });
}

export const setHeaderWidth = (resource, ind, width) => ({
  type: actionTypes.getActionType(resource, actionTypes.SET_HEADER_WIDTH_NEW_ORDERS),
  data: { ind, width },
});

export const setItemDisplayState = (resource, rowState, header, enable) => ({
  type: actionTypes.getActionType(resource, actionTypes.SET_DISPLAY_STATE),
  data: {
    rowState,
    header,
    enable,
  },
});

export const resetError = resource => ({ type: actionTypes.getActionType(resource, actionTypes.RESET_ERROR) });

export const resetOrderAdded = resource => ({ type: actionTypes.getActionType(resource, actionTypes.RESET_ORDER_ADDED) });
