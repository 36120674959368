import React from 'react';
import { Link } from 'react-router-dom';

const LoginToolBar = props => (
    <React.Fragment>
        <toolbar none="">
            <button className="button primary large wide " type="button" command="login" onClick={props.doLogin}>
                <text>Login</text>
            </button>
        </toolbar>
        <toolbar none="">
            <Link className="button link " to="/forgot"><text>Forgot Password?</text></Link>
        </toolbar>
        <separator horizontal="" login=""></separator>
        <toolbar none="">
            <label className="checkbox" classic="">
                <input type="checkbox" name="Remember" checked={props.remember} onChange={props.setRemember} />
                <box><check></check></box><text>Keep me Signed in</text></label>
        </toolbar>
    </React.Fragment>
);

export default LoginToolBar;