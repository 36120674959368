import { formatPhoneNumber } from "../../../codeless/helper/common";

const itemDefaultSize = 20;

export const createWebOrder = (oData, headers) => {
  const data = { ...oData };
  const loadStatus = data.loadStatus;
  const orderWebData = {
    row: [],
    rowState: {
      id: `load_${data.loadId}`,
      loadId: data.loadId,
      type: data.loadType,
      loadData: data,
      height: itemDefaultSize,
      loadStatus,
    },  
  };

  for (let headerIndex = 0; headerIndex < headers.length; headerIndex++) {
    const headerItem = headers[headerIndex];
    let value = null;
    let color = '#080E0E';
    let fieldType = 'text';
    let editable = false;
    let isBold = false;
    let cellStyle = {};
    let subType = null;
    let statusValue = null;
    let priorityValue = null;
    let delayedValue = null;
    let valueText = null;
    let date = null;
    switch (headerItem.key) {
      case 'loadNum':
        value = data?.loadvIdentifier;
        color = '#FFACAC';
        break;
      case 'carrierName':
        value = data.carrierName;
        break;
      case 'driverPhone':
        value = formatPhoneNumber(data.driverPhone);
        break;
      case 'driverName':
        value = data.driverName;
        break;
      case 'st':
        value = data.state;
        fieldType = 'text';
        break;
      case 'palIn':
        value = data.loadType === 1 ? data.palletCountSum : '';
        fieldType = 'text';
        break;
      case 'palOut':
        value = data.loadType === 3 ? data.palletCountSum : '';
        fieldType = 'text';
        break;
      case 'day':
        value = data.whDt;
        fieldType = 'date';
        break;
      case 'time':
        value = data.whDt;
        fieldType = 'date';
        break;
      case 'customer':
        value = data.customer;
        fieldType = 'text';
        break;
      case 'in':
        value = data.previousIn;
        fieldType = 'date';
        break;
      case 'firstDrop':
        value = data.firstDrop;
        fieldType = 'text';
        break;
      case 'trafficNote':
        value = data.trafficNote;
        fieldType = 'textInput';
        editable = true;
        break;
      case 'status':
        fieldType = 'checkbox';
        editable = true;
        priorityValue = data.isPriority;
        value = loadStatus;
        break;
      default:
        break;
    }
    orderWebData.row.push({
      key: headerItem.key,
      value,
      color,
      fieldType,
      displayState: 1,
      isBold,
      editable,
      cellStyle,
      subType,
      statusValue,
      priorityValue,
      delayedValue,
      valueText,
      date,
    });
  }
  return orderWebData;
};

export const createWebSubHeader = (currentAndNextHeaders, subHeaderName) => {
  const subHeaderData = {
    row: [],
    ordersData: [],
    rowState: {
      type: 'subHeader',
      id: subHeaderName,
      visible: true,
      height: itemDefaultSize,
    },
  };
  for (let Index = 0; Index < currentAndNextHeaders.length; Index++) {
    const regionHeader = currentAndNextHeaders[Index];
    subHeaderData.row.push({
      key: regionHeader.key,
      value: Index === 0 ? subHeaderName : '',
      editable: false,
    });
  }
  return subHeaderData;
};

const createUpcomingTrafficList = (state, upcomingTrafficList) => upcomingTrafficList.map(data => createWebOrder(data, state.headers));

const allTrafficListJoined = (state, upcomingTrafficList) => createUpcomingTrafficList(state, upcomingTrafficList);

export const setTrafficControlUpcoming = (state, responce, hasError = false, message = '') => {
  const { data } = responce;
  const upcomingTrafficListState = allTrafficListJoined(state, [...data.nextTrafficList]);

  return {
    ...state,
    ...{
      loading: false,
      hasError,
      message,
      upcomingTrafficList: upcomingTrafficListState.sort((load1, load2) => load1.rowState.loadId - load2.rowState.loadId),
    },
  };
};

export const setFilterByDate = (state, filteredOrdersList) => {
  return {
    ...state,
    ...{
      filteredUpcomingTrafficList: filteredOrdersList,
    },
  };
};

export const setDataUpcoming = (state, newValue, rowState, header, subType) => {
  if (rowState.loadData) {
    const trafficList = [...state.upcomingTrafficList];
  
    const changedLoad = trafficList.find(o => o.rowState.id === rowState.id);
    if (changedLoad) {
      changedLoad.row.forEach(row => {
        if (row.key === header.key && header.key === 'status') {
          if (subType === 'priority') {
            row.priorityValue = newValue;
          }
        }
        if (row.key === header.key) {
          return (row.value = newValue);
        }
      });
    }
    return state;
  } else {
    return state;
  }
};

export const setDisplayState = (state, rowState, header, enable) => ({
  ...state,
  ...{
    lastSelected: {
      id: rowState.id,
      headerKey: header.key,
      enable: enable,
    },
  },
});

export const setHeaderWidth = (state, ind, width) => {
  const header = state.headers[ind];
  header.width = width;

  let index = 0;
  let len = 0;
  let start = 0;
  if (ind < 7) { // length of first subHeader
    index = 0;
    len = 7;
  } else if (ind >= 7 && ind < 9) {
    index = 1;
    len = 9; // length of first  and second subHeaders
    start = 7;
  } else if (ind >= 9 && ind < 12) {
    index = 2;
    len = 12;
    start = 9;
  }

  const subHeader = state.subHeaders[index];
  subHeader.width = 0;

  for (let i = start; i < len; i++) {
    if (i === ind) {
      subHeader.width = subHeader.width + width;
    } else {
      subHeader.width = subHeader.width + state.headers[i].width;
    }
  }

  return {
    ...state,
    ...{
      headers: [...state.headers.slice(0, ind), ...[header], ...state.headers.slice(ind + 1)],
      subHeaders: [...state.subHeaders.slice(0, index), ...[subHeader], ...state.subHeaders.slice(index+1)],
    },
  };
};

export const loadContent = (state, response, hasError = false, message = '') => {
  return setTrafficControlUpcoming(state, response, hasError, message);
};

export const resetError = state => ({
  ...state,
  ...{
    hasError: false,
    message: '',
  },
});

export const setInLoading = state => ({
  ...state,
  ...{
    loading: true,
  },
});
