import React from 'react';
import { connect } from "react-redux";

const HeaderCell = ({ state, ind, resource }) => {
    const loadArea = state[resource];
    const data = loadArea.headers[ind];
    let cellStyle = {
        maxWidth: `${data.width}px`,
        width: `${data.width}px`,
        minWidth: `${data.width}px`,
        fontSize: '12px',
    };

    let headerCall = (
        <div
            className='table-header-cell'
            style={cellStyle}>
            {data.title}
        </div>
    )
    return headerCall;
};

export default connect(state => ({
    state: state
}), dispatch => ({
}))(HeaderCell);