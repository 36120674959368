import React from 'react';
import { useSelector, useDispatch } from "react-redux";
import { sortBy } from '../../../../store/actions/accountReceivableActions/accountReceivableActions';

const HeaderCell = ({ ind, resource }) => {
    const dispatch = useDispatch();
    const state = useSelector((state) => state);

    const loadArea = state[resource];
    const data = loadArea.headers[ind];

    let cellStyle = {
        maxWidth: `${data.width}px`,
        width: `${data.width}px`,
        minWidth: `${data.width}px`,
    };

    if (data.leftAction) {
        cellStyle = {
            ...cellStyle, ...{
                position: 'sticky',
                left: 0,
                maxWidth: `${data.width-1}px`,
                width: `${data.width-1}px`,
                minWidth: `${data.width-1}px`,
            }
        };
    }

    const sortableClick = () => {
        if(state.AccountReceivable.sortType && state.AccountReceivable.sortType.sort && state.AccountReceivable.sortType.sort[0] === "ask"){
            dispatch(sortBy(resource, {type: data.key, ind, sort: ['desc']}))
        } else if(state.AccountReceivable.sortType &&  state.AccountReceivable.sortType.sort === null){
            dispatch(sortBy(resource, {type: data.key, ind, sort: ['ask']}))
        } else {
            dispatch(sortBy(resource, {type: data.key, ind, sort: null}))
        }
    }
    const headerCall =  (
        <div
            className='table-header-cell'
            style={cellStyle}
            onClick={()=> data.sortable && sortableClick()  }
        >
            <div style={{ display: 'flex', alignItems: 'center', width: "100%", justifyContent: 'center'}}>
                {data.title}
            </div>
        </div>
    );

    return headerCall;
};

export default HeaderCell;