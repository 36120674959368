import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { GoogleMap, Marker, InfoWindow } from '@react-google-maps/api';
import Geocode from "react-geocode";

import { locationHandler } from './util/location';
import { CssErrorClassHandling } from '../helper/element';
import { ValidationErrorClass } from '../helper/validation';


const defaultPosition = {
    lat: 34.0522,
    lng: -118.2437
};

const MapField = props => {
    const { isLoaded, googleApiKey, type, condition, ref, data, object,
        classes, action, valudationMessages,
        placeholder, name } = props;

    Geocode.setApiKey(googleApiKey);

    const [position, setPosition] = useState(defaultPosition);
    const [infoWindowState, setInfoWindowState] = useState(false);

    useEffect(() => {
        if (object && object.lat && object.lng) {
            setPosition({
                lat: object.lat,
                lng: object.lng
            });
        }
    }, [object]);
    

    const onDragEndHandler = event => {
        Geocode.fromLatLng(event.latLng.lat(), event.latLng.lng()).then(
            response => {
                if (response && response.status === "OK" && response.results && response.results.length > 0) {
                    locationHandler(response.results[0], action);
                }
                else {
                    console.error(response);
                }
            },
            error => {
                console.log('no address', error);
            });
    };

    const infoWindowCloseHandler = () => {
        setInfoWindowState(false);
    };

    const infoWindowOpenHandler = () => {
        setInfoWindowState(true);
    };

    useEffect(() => {
            setInfoWindowState(data !== null);
    }, [data]);

    return isLoaded ? (
        <GoogleMap
            map='map'
            mapContainerClassName='map'
            ref={ref}
            id={name}
            placeholder={placeholder}
            className={CssErrorClassHandling(ValidationErrorClass, classes, valudationMessages).join(' ')}
            mapTypeId={type}
            center={position}
            zoom={10}
            options={{
                keyboardShortcuts: false
            }}
            >
            <Marker
                position={position}
                draggable={condition}
                onClick={infoWindowOpenHandler}
                onDragEnd={onDragEndHandler} />
            {infoWindowState &&
                <InfoWindow onCloseClick={infoWindowCloseHandler}
                    position={position}
                    options={{ pixelOffset: { width: -1, height: -45 } }}>
                    <div>
                        <span>{data}</span>
                    </div>
                </InfoWindow>}
        </GoogleMap>
    ) : <></>
};

const mapStateToProps = state => {
    return {
        googleApiKey: state.auth.googleApiKey,
        isLoaded: state.google.isLoaded
    }
};

export default connect(mapStateToProps)(React.memo(MapField));