import React, { useState, useEffect, useMemo } from "react";
import { message } from "antd";

import { ResponsiveBar } from "@nivo/bar";
import axios from "../../../axios/axios";
import { ArraySort } from "../../../helper/common";

const BarChart = props => {
    const [data, setData] = useState([]);
    const [maxValue, setMaxValue] = useState(0);
    const resource = props.Resource;
    const changesCount = props.changesCount;
    const labels = useMemo(() => {
        return {
            openCount: 'Open',
            inboundProgressCount: 'Inbound Progress',
            receivedCount: 'Received',
            outboundProgressCount: 'Outbound Progress',
            deliveredCount: 'Delivered'
        }
    }, []);

    useEffect(() => {
        let isMounted = true;
        const fetchData = async () => {
            const responseOrderStatus = await axios(
                resource
            );
            if (isMounted) {
                if (responseOrderStatus) {
                    const result = responseOrderStatus.data;
                    if (result) {
                        if (result.hasError) {
                            message.error(result.message);

                            return;
                        }

                        let max = 0
                        result.data.forEach(o => {
                            Object.keys(labels).forEach(i => {
                                if (o[i] > max) {
                                    max = o[i];
                                }
                            });
                        });
                        max = Math.ceil((max + 1) / 10) * 10;
                        setMaxValue(max);
                        setData(result.data);
                    }
                }
            }
        };
        fetchData();
        return () => {
            isMounted = false;
        };
    }, [resource, labels, changesCount]);

    return (
        <ResponsiveBar
            data={ArraySort(data, 'week')}
            keys={Object.keys(labels)}
            indexBy='week'
            margin={{ top: 50, right: 200, bottom: 50, left: 60 }}
            padding={0.05}
            innerPadding={3}
            maxValue={maxValue}
            groupMode="grouped"
            valueScale={{ type: 'linear' }}
            indexScale={{ type: 'band', round: true }}
            valueFormat={{ format: ' >-', enabled: false }}
            colors={['#b3e5fc', '#29b6f6', '#039be5', '#0277bd', '#01579b']}
            borderColor={{ from: 'color', modifiers: [['darker', '0']] }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'Week',
                legendPosition: 'middle',
                legendOffset: 32
            }}
            axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'Orders',
                legendPosition: 'middle',
                legendOffset: -40
            }}
            enableLabel={false}
            legendLabel={e => labels[e.id]}
            tooltip={({ id, value, color }) => (
                <div
                    style={{
                        padding: 12,
                        color,
                        background: '#222222',
                    }}
                >
                    <strong>
                        {labels[id]}: {value}
                    </strong>
                </div>
            )}
            labelTextColor={{ from: 'color', modifiers: [['darker', '0']] }}
            legends={[
                {
                    dataFrom: 'keys',
                    anchor: 'top-right',
                    direction: 'column',
                    justify: false,
                    translateX: 120,
                    translateY: 0,
                    itemsSpacing: 2,
                    itemWidth: 100,
                    itemHeight: 20,
                    itemDirection: 'left-to-right',
                    itemOpacity: 0.85,
                    symbolSize: 20,
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemOpacity: 1
                            }
                        }
                    ]
                }
            ]}
        />
    );
};

export default BarChart;