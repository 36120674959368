import React, { useState, useCallback } from 'react';
import { connect } from "react-redux";
import ResizeObserver from 'rc-resize-observer';
import ResizableTitle from '../../../components/list/resizableTitle';
import { Table, message } from 'antd';

import axios from '../../../axios/axios';
import { Columns } from '../../../helper/list';
import { Action } from '../../../config/action.config';
import { PopupTypes } from '../../custom/popup/popup';
import { CommonLabels } from '../../../../project/Defines';
import { openPopup } from '../../../../store/actions/popup/popup';
import Orders from './orders';
import { setProfileColumnWidth, saveProfileColumnWidth } from '../../../../store/actions/authentication/authentication';

const Stops = props => {
    const {
        draggable,
        colgroup,
        selectable,
        handleCheck,
        selectedOrders,
        onAfterMouseOrdersSelect,
        grouping,
        groupBy,
        groupType,
        groupTypeValue,
        getFilteredSortedOrders,
        getOrderForm,
        getAssignedOrderGroups,
        // apiResource,
        openPopup,
        stops,
        setStops,
        availableOrders,
        setAvailableOrders,
        closePopupAsync,
        closePopup,
        getOrderConfig,
        resource,
        stopResource,
        getFilteredStops,
        lastStop,
        getStopConfig,
        loads,
        getAllStopOrders,
        setLoads,
        initStops,
        getLoadResource,
        setLoading,
        load,
        updatePickAppointment,
        updateDropAppointment,
        updateInboundLoadSequence,
        updateOutboundLoadSequence,
        updateInboundStopNotes,
        updateOutboundStopNotes,
        updateInOut,
        getDispatchConfig
    } = props;
    const apiInbound = 'Order/assignloadorderitem';
    const apiOutbound = 'Order/assignoutboundloadorderitem';

    const [resizeMode, setResizeMode] = useState(false);
    const [tableWidth, setTableWidth] = useState(0);

    const editLoad = useCallback(id => {
        const windowKey = "Truck";
        const config = getStopConfig();
        const title = `Edit ${config.Title}`;
        config.screen = config;
        config.screen.Form.DefaultId = id;
        props.openPopup({
            windowKey,
            fullScreen: false,
            title: title,
            type: PopupTypes.ConfigForm,
            bodyProps: { windowKey },
            config: config,
            saveAction: (result, isClose) => {
                if (result && !result.hasError) {
                    const newLoadList = [...loads];
                    const changedLoad = newLoadList.find(l => l.id === result.id);
                    changedLoad.color = result.color;
                    changedLoad.destinatinationLocation = result.destinatinationLocation;
                    changedLoad.driverName = result.driverName;
                    changedLoad.driverPhone = result.driverPhone;
                    changedLoad.inTransit = result.inTransit;
                    changedLoad.loadCustomerGroup = result.loadCustomerGroup;
                    changedLoad.palletCountSum = result.palletCountSum;
                    changedLoad.rate = result.rate;
                    changedLoad.readyToBook = result.readyToBook;
                    changedLoad.truckNumber = result.truckNumber;
                    changedLoad.weightSum = result.weightSum;
                    changedLoad.carrierName = result.carrierName;
                    changedLoad.trailer = result.trailer;
                    changedLoad.carrierId = result.carrierId;
                    changedLoad.truckId = result.truckId;
                    setLoads(newLoadList);
                    initStops([...getAllStopOrders(stops)], newLoadList);
                }
                else {
                    props.openAlert({
                        windowKey: "wndConfirmClose",
                        type: PopupTypes.Alert,
                        title: 'Error',
                        text: result.message ? result.message : 'No result for Update!',
                        buttonYesText: CommonLabels.Buttons.Close,
                    });
                }
                if (isClose) {
                    props.closePopupAsync(windowKey);
                }
            },
            closeAction: () => {
                props.closePopup(windowKey);
            },
        });
    }, [getAllStopOrders, getStopConfig, initStops, loads, props, setLoads, stops]);

    const readyToBook = useCallback((id, action) => {
        const stop = stops.find(s => s.loadId === id);
        if (!stop.orders || stop.orders.length === 0) {
            message.warning("You can't book empty load");
            return;
        }
        const newLoadList = [...loads];
        const changedLoad = newLoadList.find(l => l.id === id);
        changedLoad.readyToBook = !(action === Action.Booked);
        setLoads(newLoadList);
        initStops([...getAllStopOrders(stops)], newLoadList);
        axios({
            url: `${getLoadResource().LoadResource}/readytobook`,
            method: 'PUT',
            data: {
                id: id,
                readyToBook: !(action === Action.Booked)
            }
        })
            .then((result) => {
                if (!result) {
                    load();
                    return;
                }
                if (result.data.hasError) {
                    message.error(result.data.message);
                    load();
                    return;
                }
                message.success(`Load successfuly ${!(action === Action.Booked) ? 'booked' : 'un booked'}`);
            });
    }, [getAllStopOrders, getLoadResource, initStops, load, loads, setLoads, stops]);

    const setInTransit = useCallback((id, loadNumber, action) => {
        const inTransit = !(action === Action.RemoveInTransit);
        const title = `Are you sure that you want to send this load ${loadNumber} to In transit stage?`
        props.openConfirm({
            windowKey: 'wndConfirmClose',
            type: PopupTypes.Confirm,
            title: inTransit ? title : `Are you sure that you want to remove this load ${loadNumber} from In transit stage?`,
            text: '',
            buttonYesText: CommonLabels.Buttons.Yes,
            buttonNoText: CommonLabels.Buttons.No,
            yesCallback: () => {
                const newLoadList = [...loads];
                const changedLoad = newLoadList.find(l => l.id === id);
                changedLoad.inTransit = inTransit;
                setLoads(newLoadList);
                initStops([...getAllStopOrders(stops)], newLoadList);
                axios({
                    url: `${getLoadResource().LoadResource}/intransit`,
                    method: 'PUT',
                    data: {
                        id: id,
                        inTransit: inTransit
                    }
                })
                    .then((result) => {
                        if (!result) {
                            load();
                            return;
                        }
                        if (result.data.hasError) {
                            message.error(result.data.message);
                            load();
                            return;
                        }
                        message.success(`Load successfuly ${!(action === Action.RemoveInTransit) ? 'sent to in transit' : 'has got back from the transit'}`);
                    });
            }
        });
    }, [getAllStopOrders, getLoadResource, initStops, load, loads, props, setLoads, stops]);

    const setDispatch = useCallback(id => {
        const config = getDispatchConfig();
        const windowKey = `dispatch_${id}`;
        config.Form.DefaultId = id;
        config.Resource = `${getLoadResource().LoadResource}/stopdispatch`
        openPopup({
            windowKey,
            fullScreen: false,
            title: config.Title,
            type: PopupTypes.ConfigForm,
            bodyProps: { windowKey },
            config: config,
            saveAction: (result) => {
                updateInOut(result);
                // closePopupAsync(windowKey);
            },
            closeAction: () => {
                closePopup(windowKey);
            }
        });
    }, [closePopup, /* closePopupAsync, */ getDispatchConfig, getLoadResource, openPopup, updateInOut]);

    const setDone = useCallback((id, loadNumber, action) => {
        const title = `Are you sure that you want to compleate this load ${loadNumber} ?`
        props.openConfirm({
            windowKey: 'wndConfirmClose',
            type: PopupTypes.Confirm,
            title,
            text: '',
            buttonYesText: CommonLabels.Buttons.Yes,
            buttonNoText: CommonLabels.Buttons.No,
            yesCallback: () => {
                const newLoadList = [...loads];
                const changedLoadIndex = newLoadList.indexOf(newLoadList.find(l => l.id === id));
                newLoadList.splice(changedLoadIndex, 1)
                setLoads(newLoadList);
                initStops([...getAllStopOrders(stops)], newLoadList);
                axios({
                    url: `${getLoadResource().LoadResource}/completeload`,
                    method: 'PUT',
                    data: {
                        id: id
                    }
                })
                    .then((result) => {
                        if (!result) {
                            load();
                            return;
                        }
                        if (result.data.hasError) {
                            message.error(result.data.message);
                            load();
                            return;
                        }
                        message.success(`Load ${loadNumber} successfuly compleated`);
                    });
            }
        });
    }, [getAllStopOrders, getLoadResource, initStops, load, loads, props, setLoads, stops]);

    const deleteLoad = useCallback((id, data) => {
        const config = getStopConfig();
        props.openConfirm({
            windowKey: 'wndConfirmDelete',
            type: PopupTypes.Confirm,
            title: CommonLabels.Messages.ConfirmDeleteTitle(config.Title, data[config.Master]),
            text: CommonLabels.Messages.ConfirmDeleteText,
            buttonYesText: CommonLabels.Buttons.Delete,
            buttonNoText: CommonLabels.Buttons.Cancel,
            yesCallback: () => {
                setLoading(true);
                axios.delete(getLoadResource().LoadResource, {
                    params: {
                        id: id,
                    }
                })
                    .then((result) => {
                        if (!result) {
                            load();
                            return;
                        }
                        if (result && !result.data.hasError) {
                            message.info(result.data.message);
                        }
                        else {
                            message.error(result.data.message);
                        }
                        load();
                    });
            }
        });
    }, [getLoadResource, getStopConfig, load, props, setLoading]);

    const onAction = useCallback((data, action) => {
        switch (action) {
            case Action.Edit:
                editLoad(data.loadId);
                break;
            case Action.Delete:
                deleteLoad(data.loadId, data);
                break;
            case Action.Book:
            case Action.Booked:
                readyToBook(data.loadId, action);
                break;
            case Action.InTransit:
            case Action.RemoveInTransit:
                setInTransit(data.loadId, data.loadNumber, action);
                break;
            case Action.Dispatch:
                setDispatch(data.id, data.loadNumber, action);
                break;
            case Action.Done:
                setDone(data.loadId, data.loadNumber, action)
                break;
            default:
                break;
        }
    }, [deleteLoad, editLoad, readyToBook, setDispatch, setDone, setInTransit]);

    const onCustomAction = useCallback((result, actionType) => {
        if (result && !result.hasError) {
            const newStops = [...stops];
            switch (actionType) {
                case 'PickAppointment':
                    updatePickAppointment(newStops, result);
                    break;
                case 'DropAppointment':
                    updateDropAppointment(newStops, result);
                    break;
                case 'InboundLoadSequence':
                    updateInboundLoadSequence(newStops, result);
                    break;
                case 'OutboundLoadSequence':
                    updateOutboundLoadSequence(newStops, result);
                    break;
                case 'InboundStopNotes':
                    updateInboundStopNotes(newStops, result);
                    break;
                case 'OutboundStopNotes':
                    updateOutboundStopNotes(newStops, result);
                    break;
                default:
                    break;
            }
        }
        else {
            props.openAlert({
                windowKey: "wndConfirmClose",
                type: PopupTypes.Alert,
                title: 'Error',
                text: result.message ? result.message : 'No result for Update!',
                buttonYesText: CommonLabels.Buttons.Close,
            });
        }
    }, [props, stops, updateDropAppointment, updateInboundLoadSequence, updateInboundStopNotes, updateOutboundLoadSequence, updateOutboundStopNotes, updatePickAppointment]);

    const onResizeStart = useCallback(() => {
        setResizeMode(true);
    }, []);

    const onResize = useCallback(({ size }, field) => {
        const selectedColumns = [];
        selectedColumns.push({
            name: field,
            width: size.width
        });
        props.setColumnWidth(stopResource, selectedColumns);
    }, [props, stopResource]);

    const onResizeStop = useCallback(({ size }, field) => {
        setResizeMode(false);
        const selectedColumns = [];
        selectedColumns.push({
            name: field,
            width: Math.floor(size.width)
        });
        props.saveColumnWidth(stopResource, selectedColumns);
    }, [props, stopResource]);

    const columnsSort = useCallback(() =>
        Columns({ resizable: true, ...getStopConfig() },
            onAction,
            null, onCustomAction, //dataSource, setDataSource,
            null,
            props.profileSettings, onResize, onResizeStop, onResizeStart, resizeMode, tableWidth),
        [getStopConfig, onAction, onCustomAction, props.profileSettings, onResize, onResizeStop, onResizeStart, resizeMode, tableWidth]);

    const columns = columnsSort()[0];

    const Row = p => {
        const key = p['data-row-key'];
        const currentStops = getFilteredStops();
        const stop = currentStops.find(s => s.gridKey === key);
        if (!stop) {
            return (<tr {...p}>
                {p.children}
            </tr>);
        }
        if (currentStops.indexOf(stop) === 0) {
            lastStop.current = stop;
        }
        const showSeparator = !lastStop.current.color && lastStop.current.loadId !== stop.loadId;
        const out = (
            <>
                {showSeparator && <tr {...p}>
                    <td colSpan={columns.length + 1} style={{ height: 2, backgroundColor: '#000' }} />
                </tr>}
                <tr {...p}>
                    {p.children}
                </tr>

            </>);
        lastStop.current = stop;
        return out;
    };

    return (
        <>
            <ResizeObserver
                onResize={(e) => {
                    setTableWidth(e.width);
                }}
            >
                <Table
                    {...getStopConfig().List.Defaults.Table.Style}
                    components={{
                        body: {
                            row: Row
                        },
                        header: {
                            cell: ResizableTitle
                        }
                    }}
                    dataSource={getFilteredStops()}
                    rowKey={'gridKey'}
                    columns={columns}
                    expandable={{
                        expandedRowRender: (record) =>
                        (<Orders
                            data={record.orders}
                            draggable={draggable}
                            colgroup={colgroup}
                            droppableId={record.key}
                            recordId={record.id}
                            selectable={selectable}
                            handleCheck={handleCheck}
                            selectedOrders={selectedOrders}
                            onAfterMouseOrdersSelect={onAfterMouseOrdersSelect}
                            grouping={grouping}
                            groupBy={groupBy}
                            groupType={groupType}
                            groupTypeValue={groupTypeValue}
                            getFilteredSortedOrders={getFilteredSortedOrders}
                            getOrderForm={getOrderForm}
                            getAssignedOrderGroups={getAssignedOrderGroups}
                            apiResource={record.isInbound ? apiInbound : apiOutbound}
                            isInbound={record.isInbound}
                            openPopup={openPopup}
                            stops={stops}
                            setStops={setStops}
                            availableOrders={availableOrders}
                            setAvailableOrders={setAvailableOrders}
                            closePopupAsync={closePopupAsync}
                            closePopup={closePopup}
                            getOrderConfig={getOrderConfig}
                            resource={resource}
                            attributes={{
                                s_space: "",
                                style: {
                                    width: 1800
                                }
                            }}
                        />
                        )
                    }}
                />
            </ResizeObserver>
        </>
    )
};

const mapStateToProps = state => {
    return {
        profileSettings: state.auth.profileSettingsTemp
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        setColumnWidth: (resource, columns) => dispatch(setProfileColumnWidth(resource, columns)),
        saveColumnWidth: (resource, columns) => dispatch(saveProfileColumnWidth(resource, columns)),
        openConfirm: data => dispatch(openPopup(data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Stops));