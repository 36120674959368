export const styles = {
  control: (base, state) => ({
      ...base,
      borderRadius: '8px',
      boxShadow: 'none',
      minHeight: '20px',
      minWidth: '120px',
      paddingLeft: '10px',
      height: '20px',
      alignContent: 'center',
  }),
  menu: (base, state) => ({
      ...base,
      boxShadow: '0',
      top: 20,
  }),
  input: (base, state) => ({
      ...base,
      height: 0,
      position: 'absolute',
      top: '0',
      marginTop: '-2px',

  }),
  valueContainer: (base, state) => ({
      height: '-webkit-fill-available',
  }),
  singleValue: (base, state) => ({
      maxWidth: '170px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
  }),
  menuList: (base, state) => ({
      ...base,
      position: 'fixed',
      maxHeight: "-webkit-fill-available",
      padding: 0,
      "::-webkit-scrollbar": {
          width: "4px",
          height: "0px",
      },
      "::-webkit-scrollbar-track": {
          background: "#f1f1f1"
      },
      "::-webkit-scrollbar-thumb": {
          background: "#888"
      },
      "::-webkit-scrollbar-thumb:hover": {
          background: "#555"
      }
  }),
  option: (defaultStyles, state) => ({
      ...defaultStyles,
      color: state.isSelected ? "#000" : "#000",
      backgroundColor: state.isSelected ? "lightGrey" : "#fff",
      border: '1px solid lightGray',
      minHeight: '30px'

  }),
  indicatorsContainer: () => ({
      height: 'max-content',
  }),
  indicatorContainer: () => ({
      padding: '0 8px!important',
  }),
}; 