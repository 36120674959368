import {
    ElementTypes,
    GenerateToolBars
} from '../../../config';
import { OutboundLoadingSequenceFields as LoadSequenceFields } from '../loadSequenceFieldsConfig';

const LoadSequence = {
    Title: 'Load Sequence',
    Resource: 'OutboundLoad/loadcustomerloadingsequencegroupitem',
    Key: 'id',
    IsRest: true,
    Form: {
        Title: 'Load Sequence',
        SectionGroups: [
            {
                Sections: [
                    {
                        classes: 'wide',
                        Fields: {
                            ...LoadSequenceFields,
                            Group3: [
                                {
                                    useSeparator: false,
                                    classes: 'start',
                                    Fields: {
                                        outboundLoadingSequenceId: {
                                            fieldClasses: ['standart'],
                                            Title: 'Loading Sequence',
                                            //Field: ElementTypes.SelectWithCache('/api/loadingsequence/valuelist')
                                            Field: ElementTypes.Generic(ElementTypes.SelectWithCache, {
                                                dataSource: '/api/loadingsequence/valuelist',
                                                selectedValueType: 'outboundLoadingSequenceName'
                                            }),
                                        },
                                        outboundLoadingDescription: {
                                            Title: 'Loading Description',
                                            Field: ElementTypes.Comment(o => o.outboundLoadingSequenceId === 5)
                                        }
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        ],
        ToolBars: GenerateToolBars(['Save', 'SaveClose', 'Close'])
    }
};

export default LoadSequence;