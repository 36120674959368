import axios from 'axios';
import store from '../..';
import * as actionTypes from './customerOrderLocationsActionTypes';

const buildUrl = (resource, endPoint, id) => (id ? `/api/${resource}/${endPoint}/${id}` : `/api/${resource}/${endPoint}`);

const axiosConfig = () => ({
  headers: {
    Authorization: 'Bearer ' + store.getState().auth.userToken,
  },
});

export const getFavoriteLocationDoorList = (resource, endPoint, parentId) => dispatch => {
  dispatch({ type: actionTypes.getActionType(resource, actionTypes.GET_FAVORITE_LOCATION_DOORS_LOADING) });
  axios
    .get(buildUrl(resource, endPoint, parentId), axiosConfig())
    .then(responce => dispatch({ type: actionTypes.getActionType(resource, actionTypes.GET_FAVORITE_LOCATION_DOORS), data: responce }))
    .catch(err => {
      console.error(err);
      console.log(err.response);
      dispatch({ type: actionTypes.getActionType(resource, actionTypes.ERROR) });
    });
};

export const getAllLocationDoorList = (resource, endPoint) => dispatch => {
  dispatch({ type: actionTypes.getActionType(resource, actionTypes.GET_ALL_LOCATION_DOORS_LOADING) });
  axios
    .get(buildUrl(resource, endPoint), axiosConfig())
    .then(responce => dispatch({ type: actionTypes.getActionType(resource, actionTypes.GET_ALL_LOCATION_DOORS), data: responce }))
    .catch(err => {
      console.error(err);
      console.log(err.response);
      dispatch({ type: actionTypes.getActionType(resource, actionTypes.ERROR) });
    });
};
