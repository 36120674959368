import React, { forwardRef, useImperativeHandle, useState } from 'react';
import moment from 'moment';
import { LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import { message, Button } from 'antd';

import Sheet from '../../custom/sheet/sheet';
import { CloneObject } from '../../../helper/common';
import { ValidateFields } from '../../../helper/validation';
import { MappingFields } from '../../../mapping/mapping';
import { Action } from '../../../config/action.config';
import { useSectionPopup } from '../../../hook/action/sectionPopup';
import Uploader from '../../../elements/uploader';
import { setValue } from '../../../hook/data/useData';
import { useFormValidation } from '../../../hook/data/useFormValidation';
import store from '../../../../store';
import { useEffect } from 'react';

const Attachments = forwardRef((props, ref) => {
    const columns = props.config.List.Columns;
    const actions = props.config.List.Actions;
    const showUpload = props.config.Screen || props.config.Screen === undefined;

    useEffect(() => {
        if (!props.data) {
            props.dispatch({ type: props.type, value: [] });
        }
    }, [props]);

    const [loading, setLoading] = useState(false);
    const validate = useFormValidation(
        props.messageDispatch,
        props.data ? props.data.filter(o => !o.deleted) : [],
        MappingFields,
        ValidateFields,
        columns,
        null,
        props.messageIdentityPrefix,
        null,
        props.type,
        null);

    useImperativeHandle(ref, () => ({
        validate() {
            const [passValidation] = validate();
            return passValidation;
        }
    }));

    const sectionPopup = useSectionPopup(props.config.Popup.Title, 'attachmentComment', props.config.Popup);

    const onActionRecordHandler = (record, action) => {
        switch (action) {
            case Action.Delete:
                deleteRecord(record);
                break;
            case Action.Edit:
            case Action.View:
                const onSave = (data) => {
                    const newData = CloneObject(props.data);
                    const index = newData.findIndex(i => i.guid === data.guid)
                    newData[index] = CloneObject(data);
                    props.dispatch({ type: props.type, value: newData });
                };

                sectionPopup(props.data.filter(i => !i.deleted)[record.id], onSave);
                break;
            default:
                break;
        }
    };

    const deleteRecord = (record) => {
        
        const existingRecords = props.data.filter(r => !r.deleted);
        const recordData = existingRecords.find((i) => i[props.keyFieldName] === existingRecords[record.id][props.keyFieldName]);

        let newData;
        if (recordData.isNew) {
            newData = CloneObject(props.data);
            newData = newData.filter((i) => i[props.keyFieldName] !== recordData[props.keyFieldName]);
            props.dispatch({ type: props.type, value: newData });
        }
        else {
            newData = CloneObject(props.data);
            const deletedDataRecord = newData.find((i) => i[props.keyFieldName] === recordData[props.keyFieldName]);
            deletedDataRecord.deleted = true;
            props.dispatch({ type: props.type, value: newData });
        }
    };

    const onChangeHandler = (event) => {
        changeRecordData(event.keyField, event.field, event.newData, event.column, event.nativeEvent.source);
    };

    const changeRecordData = (keyField, field, newValue, column, outSource) => {
        const newData = CloneObject(props.data);
        const item = newData.find(i => i[props.keyFieldName] === keyField);
        if (item) {
            setValue(item, field, {
                value: newValue,
                dependencies: column.Dependencies,
                outSource: outSource
            });
            props.dispatch({ type: props.type, value: newData });
        }
    };

    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <Button icon={<UploadOutlined />}>Click to Upload</Button>}
        </div>
    );

    

    const onChange = result => {
        const newData = CloneObject(props.data);
        const mDate = moment(new Date());
        newData.push({
            isNew: true,
            guid: result.guId,
            fileName: result.originalName,
            originalFileName: result.originalName,
            fileType: result.fileType,
            size: result.size,
            createDate: mDate.format(),//moment(new Date()),//.format(Defines.Format.PrintDate),
            fullName: `${store.getState().auth.userFirstName} ${store.getState().auth.userLastName}`,
            comments: ''
        });
        props.dispatch({ type: props.type, value: newData });
    };

    const max = 5;
    const restriction = file => {
        const isLt = file.size / 1024 / 1024 < max;
        if (!isLt) {
            message.error(`Image must be smaller than ${max}MB!`);
            return false;
        }
        return true;
    };

    const sheetMessageIdentityPrefix = props.messageIdentityPrefix == null ? `${props.type}` : `${props.messageIdentityPrefix}_${props.type}`;

    const data = props.data;
    const uploader = (
        <toolbar>
            <Uploader
                name='file'
                setLoading={setLoading}
                restrictions={restriction}
                onChange={onChange}
                disabled={false}>
                {uploadButton}
            </Uploader>
        </toolbar>
    );
    return (<React.Fragment>
            <Sheet
                messageIdentityPrefix={sheetMessageIdentityPrefix}
                keyFieldName={props.keyFieldName}
                columns={columns}
                data={data}
                onChange={onChangeHandler}
                messageDispatch={props.messageDispatch}
                messages={props.messages}
                classes={`table_view ${props.config.classes || 'extra'}`}
                minRowsCanDelete={0}
                colgroup
                action
                actions={actions}
                onActionRecord={onActionRecordHandler}
            />
            {showUpload && uploader}
    </React.Fragment>);
});

export default Attachments;

//export default connect(state => ({
//    userFirstName: state.auth.userFirstName,
//    userLastName: state.auth.userLastName
//}))(Attachments);