import * as popupActionTypes from './popupActionTypes';

export const openPopup = payload => ({
    type: popupActionTypes.OPEN_POPUP,
    data: payload
});
export const closePopup = payload => ({
    type: popupActionTypes.CLOSE_POPUP,
    windowKey: payload
});
export const closePopupAsync = payload => dispatch => setTimeout(() => {
    dispatch(closePopup(payload));
}, 10);
export const closeAllPopups = () => ({
    type: popupActionTypes.CLOSE_ALL_POPUPS
});
