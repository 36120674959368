import React from 'react';

const ForgotPasswordInfo = props => (
    <info>
        <text className="info"><b>{props.title}</b></text>
        <space></space>
        <text>{props.text}</text>
    </info>
);

export default ForgotPasswordInfo;