import React from 'react';
import { connect } from "react-redux";

import { openPopup, closePopup } from '../../store/actions/popup/popup';
import { PopupTypes } from '../components/custom/popup/popup';
import axios from '../axios/axios';
import { ElementConditions } from '../config/condition.config';
import { GenerateToolBars } from '../config/element.config';
import { CreateGuid, CloneObject } from '../helper/common';
import { useValueList } from '../hook/data/useValueList';
import DropDownElement from './dropDownElement';
import DropDownNewItem from './dropDownNewItem';
import { getActionArgument } from './util/dropDown';

const ComboAdd = props => {
    const { 
        data,
        dataSource,
        classes,
        condition,
        action,
        valudationMessages,
        style,
        emptyOption,
        parentValue,
        selectedValue,
        addparams,
        openPopup,
        closePopup,
        reloadOnParentChange,
        mode,
        defaultValue,
        addNewElement,
    } = props;
    const windowKey = CreateGuid();
    const config = CloneObject(addparams.PopupConfig);
    const title = `Add ${addparams.TitlePrefix ? addparams.TitlePrefix : ''}${config.Title}`;
    const actionValueName = config.ValueField || addparams.ValueField || 'id';
    const [list, setList] = useValueList(data, dataSource, parentValue, reloadOnParentChange, emptyOption);

    const onAddClickHandler = () => {
        document.getElementById(windowKey).blur();
        config.Form.DefaultId = 0;
        config.Form.ToolBars = GenerateToolBars(['SaveClose', 'Cancel']);
        openPopup({
            windowKey,
            id: parentValue,
            fullScreen: false,
            title: title,
            type: PopupTypes.ConfigForm,
            bodyProps: { windowKey },
            config: config,
            saveAction: (objectResult) => {
                setTimeout(() => closePopup(windowKey), 100);
                axios({
                    method: 'GET',
                    baseURL: dataSource + (parentValue ? parentValue : '')
                })
                    .then(response => {
                        if (response) {
                            const result = response.data;
                            if (result) {
                                const newList = result.filter(i => (!i.archived && !i.archiveDate && !i.inactive) || result === i.value);
                                action(
                                    getActionArgument(newList,
                                        objectResult[actionValueName],
                                        mode, null));
                                setList(newList);
                            }
                        }
                    }).catch(error => {
                        console.error(error);
                    });
            },
            closeAction: () => {
                props.closePopup(windowKey);
            }
        });
    }; 

    return condition !== ElementConditions.Hidden ?// && list ?
        <DropDownElement
            defaultValue={defaultValue ? list?.[0].value || '' : ''}
            mode={mode}
            showSearch={true}
            data={data}
            classes={classes}
            valudationMessages={valudationMessages}
            style={style}
            condition={condition}
            id={windowKey}
            dropdownRender={addNewElement ? menu => <DropDownNewItem menu={menu} onAddClickHandler={onAddClickHandler} title={title} /> : ''}
            valueList={list}
            action={action}
            selectedValue={selectedValue}
        /> : null;
};

const mapDispatchToProps = dispatch => {
    return {
        openPopup: (data) => dispatch(openPopup(data)),
        closePopup: (key) => dispatch(closePopup(key))
    };
};

export default connect(null, mapDispatchToProps)(ComboAdd);