const pendingColor = '#FBCB00', readyColor = '#029B96', openColor = '#D5D5D5', canceledColor = '#FF6347';
export const statusColor = (status) => {
    switch (status) {
        case 'Ready to Build':
            return readyColor;
        case 'Pending':
            return pendingColor;
        case 'Canceled':
            return canceledColor;
        case 'Open':
        default:
            return openColor;
    }
};


export const appointmentStatusColor = (status) => {
    switch (status) {
        case 'Request':
            return '#FF6347';
        case 'Requested':
            return '#76A45D';
        case 'Confirmed':
            return '#029B96';
        default:
        case 'Open':
            return '#FFF';
    }
};