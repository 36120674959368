import { ORDERS_BUILD as actionTypes } from '../../actions/orders/ordersActionTypes';
import * as orders from './ordersReducer';

const defaultState = {
    headers: [
        {
            key: 'checkbox',
            title: '',
            width: 40,
        },
        {
            key: 'temperature',
            title: 'Temp',
            width: 60,
        },
        {
            key: 'readyDate',
            title: 'R/D',
            width: 70,
        },
        {
            key: 'dueDate',
            title: 'Due/D',
            width: 70,
        },
        {
            key: 'deliveryDate',
            title: 'DEL/D',
            width: 70,
        },
        {
            key: 'customer',
            title: 'Cust',
            width: 80,
        },
        {
            key: 'pickLocation',
            title: 'Pick Location',
            width: 160,
        },
        {
            key: 'drop2WH',
            title: 'Delivery WH',
            width: 160,
        },
        {
            key: 'route',
            title: 'Route',
            width: 50,
        },
        {
            key: 'poNumber',
            title: 'PO#',
            width: 60,
        },
        {
            key: 'soNumber',
            title: 'SO#',
            width: 50,
        },
        {
            key: 'palletCount',
            title: 'Pal',
            width: 50,
        },
        {
            key: 'space',
            title: 'Spa',
            width: 50,
        },
        {
            key: 'case',
            title: 'Case',
            width: 40,
        },
        {
            key: 'lbs',
            title: 'LBS',
            width: 40,
        },
        {
            key: 'batchType',
            title: 'Batch',
            width: 40,
        },
        // {
        //     key: 'freight',
        //     title: 'Freight',
        //     width: 50,
        // },
        {
            key: 'podStatus',
            title: 'POD',
            width: 50,
        },
        {
            key: 'status',
            title: 'Status',
            width: 60,
        },
        {
            key: 'group',
            title: 'Group',
            width: 60,
        },
    ],
    loading: true,
    hasError: false,
    message: '',
    orders: null,
    batchType: '',
    newGroupCreated: false,
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case actionTypes.RELOAD_ON_ERROR_LOAD_DETAILS:
            return orders.getOrdersList(state, action.data.responce, true, action.data.errorText ? action.data.errorText : 'Something go wrong, data reloaded!');
        case actionTypes.RESET_ERROR:
            return orders.resetError(state);
        case actionTypes.GET_ORDERS_LIST_BY_ID_LOADING:
            return orders.setInLoading(state);
        case actionTypes.GET_ORDERS_LIST_BY_ID:
            return orders.loadContent(state, action.data);
        case actionTypes.GET_ORDERS_LIST_BY_ID_ERROR:
            return orders.setError(state, action.data);
        case actionTypes.CREATE_NEW_GROUP:
            return orders.createGroup(state, action.data);
        case actionTypes.CREATE_NEW_GROUP_LOADING:
            return orders.setInLoading(state);
        case actionTypes.CREATE_NEW_GROUP_ERROR:
            return orders.setError(state, action.data);
        case actionTypes.SET_BATCH_TYPE:
            return orders.setBatchType(state, action.data); 
        default:
            return state;
    }
};