import React from 'react';
import { connect } from "react-redux";

import Container from './container/container';
// import Wrapper from './container/wrapper/wrapper';
import Confirm from './confirm/confirm';
import Prompt from './prompt/prompt';
import SONUCancelation from './prompt/SONUCancelation';
import Alert from './alert/alert';
import Details from '../../../pages/details';
import Section from '../../../pages/section';

import { closePopup } from './../../../../store/actions/popup/popup';

export const PopupTypes = {
    Confirm: 'Confirm',
    Prompt: 'Prompt',
    SONUModal: 'SONUModal',
    Alert: 'Alert',
    TemplateDetails: 'TemplateDetails',
    ConfigForm: 'ConfigForm',
    ConfigSection: 'ConfigSection'
};

export const PopupClasses = {
    Small: 'small',
    Medium: 'medium',
    Large: 'large'
};

const Popup = props => {
    return (
        <div className={(props.popups.length > 0) ? "window_container open" : "window_container"}>
            {
                ((props.popups && props.popups.filter(item => (item.fullScreen || false) === (props.fullScreen || false))) || []).map((item, i) => {
                    const closeOutside = false;
                    const { type, className, config, saveAction, closeAction, forgotAction, changeAction, beforeSaveAction } = item;
                    let open_class = type + (item.fullScreen ? " open fullscreen" : " open");
                    const onClose = () => {
                        props.closePopup(item.windowKey);
                    }
                    const skipCloseClassNames = '';
                    switch (type) {
                        case PopupTypes.Confirm:
                            return <Container key={type + i} className="open" onClose={onClose} closeOnOutsideClick={true}>
                                <Confirm {...item} onClose={onClose} />
                            </Container>;
                        case PopupTypes.Prompt:
                            return <Container key={type + i} className="open" onClose={onClose} closeOnOutsideClick={true}>
                                <Prompt {...item} onClose={onClose} />
                            </Container>;
                        case PopupTypes.Alert:
                            return <Container key={type + i} className="open" onClose={onClose} closeOnOutsideClick={true}>
                                <Alert {...item} onClose={onClose} />
                            </Container>;
                        case PopupTypes.SONUModal:
                            return <Container key={type + i} className="open" onClose={onClose} closeOnOutsideClick={true}>
                                <SONUCancelation {...item} onClose={onClose} />
                            </Container>;
                        case PopupTypes.ConfigSection:
                            //Section
                            return <Container
                                key={type + i}
                                className={`${open_class} ${className || ''}`}
                                onClose={onClose}
                                skipCloseClassNames={skipCloseClassNames}
                                closeOnOutsideClick={i === props.popups.length - 1 && closeOutside}>
                                {/* <Wrapper {...item} onClose={onClose} fullScreen={false}> */}
                                    {config ? <Section config={config}
                                        sectionData={item.data}
                                        close={onClose}
                                        onSave={item.onSave}
                                        onCancel={item.onCancel}
                                        fullScreen={false} /> : 'Config not found'}
                                {/* </Wrapper> */}
                            </Container>;
                        case PopupTypes.ConfigForm:
                        default:
                            return <Container
                                key={type + i}
                                className={`${open_class} ${className || ''}`}
                                onClose={onClose}
                                skipCloseClassNames={skipCloseClassNames}
                                closeOnOutsideClick={i === props.popups.length - 1 && closeOutside}>
                                {/* <Wrapper {...item} onClose={onClose}> */}
                                    {config ? Details(config, null, true, saveAction, closeAction, forgotAction, changeAction, beforeSaveAction, item.id) : 'Config not found'}
                                {/* </Wrapper> */}
                            </Container>;
                    }
                })
            }
        </div>
    )
};

export default connect(state => ({
    popups: state.popup.popups
}),
    dispatch => ({
        closePopup: (key) => dispatch(closePopup(key))
    })
)(Popup);
