import { useEffect } from 'react';

export const useOuterClickNotifier = (onOuterClick, innerRef, skipCloseClassNames, enable) => {
    useEffect(
        () => {
            

            const handleClick = (e) => {
                innerRef.current
                    && (innerRef.current === e.target || !innerRef.current.contains(e.target))
                    && !(document.getElementsByClassName(skipCloseClassNames)[0]
                    && document.getElementsByClassName(skipCloseClassNames)[0].contains(e.target))
                    && onOuterClick(e);
            }
            if (innerRef.current && enable) {
                document.addEventListener("click", handleClick);
            }
            return () => document.removeEventListener("click", handleClick);
        },
        [enable, innerRef, skipCloseClassNames, onOuterClick] // invoke again, if inputs have changed
    );
};