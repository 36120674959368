import moment from 'moment';
import { getEfsStatusTooltip, getEfsBGColor } from './dataGeneral';
export const itemDefaultSize = 20;
const toDateTime = value => (value ? moment.utc(value).toDate() : '');

const extractWebLoadsToList = state => loads => {
  let loadsAssignedOrders = [];
  loads.forEach(loadItem => {
    loadsAssignedOrders.push(loadItem);
    loadItem.stops.forEach(stop => {
      loadsAssignedOrders.push(stop);
      stop.ordersData.forEach(order => {
        loadsAssignedOrders.push(order);
      });
    });
  });
  return loadsAssignedOrders;
};

const createWebLoadArray = state => (loadsData, assignedOrders, headers, oldLoadsAssignedOrders) => {
  const loads = [];
  loadsData
    .filter(l => !l.parentLoadId)
    .forEach(l =>
      loads.push(
        createWebLoad(
          l,
          assignedOrders.filter(o => o['loadId'] === l.id || o['outboundLoadId'] === l.id),
          headers,
          (oldLoadsAssignedOrders || []).find(ol => ol.rowState.type === 'load' && ol.rowState.loadData.id === l.id),
          false,
        ),
      ),
    );
  if (assignedOrders.length !== loads.reduce((r, o) => (r += o.ordersData.length), 0)) {
    console.log('Some assigned orders not found there loads');
  }
  return loads;
};

export const updateOrdersLoadState = (
  state,
  orders,
  loads,
  subOrders,
  hasError = false,
  message = '',
  filterType = 'all',
  sortType = { type: '', ind: '', sort: null },
) => ({
  ...state,
  ...{
    orders,
    loads,
    subOrders,
    loading: false,
    hasError,
    message,
    filterType,
    sortType,
    loadsAssignedOrders: extractWebLoadsToList(state)(
      createWebLoadArray(state)(loads, orders, state.headers, state.loadsAssignedOrders),
      state.headers,
    ),
  },
});

export const loadContent = (state, response, hasError = false, message = '') => {
  const data = response.data || [];
  return updateOrdersLoadState(state, [...data.assignedOrders.data], data.loads.data, data.subOrders.data, hasError, message);
};

export const createWebLoad = (loadData, loadOrders, headers, oldWebLoad) => {
  const loadId = `load_${loadData.id}`;
  const loadWebData = {
    row: [],
    ordersData: [],
    stops: [],
    rowState: {
      type: 'load',
      id: loadId,
      loadId: loadData.id,
      loadData,
      visible: true,
      reacted: oldWebLoad !== undefined && oldWebLoad.rowState.reacted,
      isChecked: oldWebLoad !== undefined && oldWebLoad.rowState.reacted,
      available: false,
      createPickKey: order => {
        let createKey = '0';
        if (order.loadId) {
          createKey = `${order.loadId}_${order.pickLocationId}`;
        }
        return createKey;
      },
      createDropKey: order => {
        let createKey = '0';
        if (order.loadId) {
          createKey = `${order.loadId}`;
          if (order.warehouse) {
            createKey = `${createKey}_0_${order.warehouseId}`;
          } else {
            createKey = `${createKey}_${order.dropLocationId}_0`;
          }
        }
        return createKey;
      },
      groups: (orders, keyFunction) =>
        orders.reduce((r, o) => {
          const groupBy = keyFunction(o.rowState.orderData);
          r[groupBy] = [...(r[groupBy] || []), o.rowState.orderData];
          return r;
        }, {}),
      findOrderGroup: (groups, order, createKey) => {
        return Object.keys(groups).find(go => {
          const oG = groups[go];
          if (oG.some(e => e.id === order.id)) {
            return groups[go];
          }
          if (createKey && go === createKey(order)) {
            return groups[go];
          }
          return null;
        });
      },
      height: itemDefaultSize,
    },
  };
  loadOrders.forEach(lo => loadWebData.ordersData.push(createWebOrder(lo, headers, loadData, loadId, loadWebData.rowState.reacted)));
  loadWebData.ordersData = loadWebData.ordersData.sort(
    (a, b) => new Date(a.rowState.orderData.inboundPickAppointment) - new Date(b.rowState.orderData.inboundPickAppointment ?? ''),
  );

  const pickInboundGroups = loadWebData.rowState.groups(loadWebData.ordersData, loadWebData.rowState.createPickKey);

  let stopNumber = 0;

  Object.keys(pickInboundGroups).forEach(key => {
    stopNumber++;
    const groupOrders = pickInboundGroups[key];
    groupOrders.forEach(o => {
      loadWebData.ordersData.find(wo => wo.rowState.orderData.id === o.id).row.find(f => f.key === 'left_action').value = stopNumber;
    });
  });

  const stopsList =
    loadData?.loadType === 1
      ? [...new Set(loadOrders.map(order => order.pickLocationId))]
      : loadData?.loadType === 3
      ? [...new Set(loadOrders.map(order => order.dropLocationId))]
      : '';
  stopsList.forEach((stopNumber, i) => {
    loadWebData.stops.push(createStop(loadOrders, headers, loadData, loadId, loadWebData?.rowState.reacted, stopNumber, stopsList));
  });

  loadWebData.ordersData = loadWebData.ordersData.sort(
    (a, b) => +a.row.find(f => f.key === 'left_action').value - +b.row.find(f => f.key === 'left_action').value,
  );

  for (let hederIndex = 0; hederIndex < headers.length; hederIndex++) {
    const headerItem = headers[hederIndex];
    let value = null;
    let fieldType = 'text';
    let editable = false;
    let cellStyle = {};
    let tooltip = null;
    if (!headerItem.leftAction) {
      switch (headerItem.key) {
        case 'efs':
          value = loadData.efsSum ? `$${loadData.efsSum}` : '';
          fieldType = 'currency';
          cellStyle = getEfsBGColor(loadData.efsStatusMin);
          tooltip = getEfsStatusTooltip(loadData.efsStatusMin);
          break;
        case 'driver_truck_Trailer_VIN':
          value = `${loadData.driverName ? loadData.driverName : ''} ${loadData.truckNumber ? loadData.truckNumber : ''} ${
            loadData.trailer ? loadData.trailer : ''
          }`; //??? + vin
          fieldType = 'text';
          break;
        case 'pickLocation':
          if (loadWebData.ordersData.length !== 0) {
            const order = loadWebData.ordersData[0];
            if (order) {
              value = order.row.find(f => f.key === headerItem.key).value;
            }
          }
          break;
        case 'carrierName':
          value = loadData.carrierName;
          fieldType = 'text';
          break;
        case 'rate':
          value = loadData.rate ? loadData.rate : '';
          fieldType = 'currency';
          break;
        case 'instructions':
          value = loadData.notes ? loadData.notes : '';
          fieldType = 'currency';
          break;
        case 'pickInDate':
          value = loadData?.loadType === 1 ? `${Object.keys(pickInboundGroups).length}P` : '1P';
          break;
        case 'palletCount':
          if (loadWebData.ordersData.length > 0) {
            value = loadWebData.ordersData.reduce((a, b) => {
              return a + (b ? +b.row.find(f => f.key === headerItem.key).value : 0);
            }, 0);
          }
          fieldType = 'text';
          break;
        case 'cases':
          if (loadWebData.ordersData.length > 0) {
            value = loadWebData.ordersData.reduce((a, b) => {
              return a + (b ? +b.row.find(f => f.key === headerItem.key).value : 0);
            }, 0);
          }
          fieldType = 'text';
          break;
        case 'space':
          if (loadWebData.ordersData.length > 0) {
            value = loadWebData.ordersData.reduce((a, b) => {
              return a + (b ? +b.row.find(f => f.key === headerItem.key).value : 0);
            }, 0);
          }
          fieldType = 'text';
          break;
        case 'weight':
          if (loadWebData.ordersData.length > 0) {
            value = loadWebData.ordersData.reduce((a, b) => {
              return a + (b ? +b.row.find(f => f.key === headerItem.key).value : 0);
            }, 0);
          }
          fieldType = 'text';
          break;
        case 'loadNumber':
          value = loadData.loadNumber;
          break;
        case 'readyDate':
          value = '';
          if (loadWebData.ordersData.length !== 0) {
            const filterData = loadWebData.ordersData.filter(o => o.rowState.orderData[headerItem.key]);
            if (filterData && filterData.length > 0) {
              const maxDate = Math.max.apply(
                null,
                filterData.map(order => {
                  return new Date(order.rowState.orderData[headerItem.key]);
                }),
              );
              if (maxDate) {
                value = new Date(maxDate);
              }
            }
          }
          fieldType = 'date';
          break;
        case 'inboundPickAppointment':
          value = '';
          if (loadWebData.ordersData.length !== 0) {
            const order = loadWebData.ordersData[loadWebData.ordersData.length - 1];
            if (order) {
              value = order.row.find(f => f.key === headerItem.key).value;
            }
          }
          fieldType = 'dateTime';
          break;

        default:
          break;
      }
    } else {
      value = '';
      fieldType = 'text';
    }

    loadWebData.row.push({
      key: headerItem.key,
      value,
      fieldType,
      displayState: 1,
      editable,
      cellStyle,
      tooltip,
    });
  }

  return loadWebData;
};

export const createStop = (loadOrders, headers, loadData, loadId, isHidden, stopNumber, stopsList) => {
  const stop = loadData.stops.filter(stop => stop.locationId === stopNumber);
  const loadType = loadData.loadType;
  const stopData = {
    row: [],
    ordersData: [],
    rowState: {
      type: 'stop',
      id: `stop_${loadData.id}_${stopNumber}`,
      loadData,
      firstOrderData: {},
      loadId: loadId && loadId,
      visible: !isHidden,
      reacted: false,
      available: loadData === null || !loadData?.id,
      height: itemDefaultSize,
      stopNumber,
      notes: stop?.[0]?.notes,
    },
  };

  loadOrders.forEach(lo => {
    if (loadType === 1 && lo.pickLocationId === stopNumber) {
      stopData.ordersData.push(createWebOrder(lo, headers, loadData, loadId, stopData.rowState.reacted, stopData.rowState.id));
      stopData.ordersData.forEach(order => (order.row.find(row => row.key === 'left_action').value = stopsList.indexOf(stopNumber) + 1));
    } else if (loadType === 3 && lo.dropLocationId === stopNumber) {
      stopData.ordersData.push(createWebOrder(lo, headers, loadData, loadId, stopData.rowState.reacted, stopData.rowState.id));
      stopData.ordersData.forEach(order => (order.row.find(row => row.key === 'left_action').value = stopsList.indexOf(stopNumber) + 1));
    }
  });

  stopData.ordersData = stopData.ordersData.sort(
    (a, b) => new Date(a.rowState.orderData.inboundPickAppointment) - new Date(b.rowState.orderData.inboundPickAppointment ?? ''),
  );

  stopData.rowState.firstOrderData = stopData.ordersData[0].rowState.orderData;
  const firstOrderData = stopData.ordersData[0].rowState.orderData;

  for (let hederIndex = 0; hederIndex < headers.length; hederIndex++) {
    const headerItem = headers[hederIndex];
    let value = null;
    let fieldType = 'text';
    let editable = false;
    let cellStyle = {};
    let tooltip = null;

    if (!headerItem.leftAction) {
      switch (headerItem.key) {
        case 'pickInDate':
          value =
            loadData.loadType === 1
              ? toDateTime(firstOrderData.inboundPickIn)
              : loadData.loadType === 3
              ? toDateTime(firstOrderData.outboundPickIn)
              : '';
          fieldType = 'dateTime';
          break;
        case 'lumper':
          value = loadData.loadType === 1 ? firstOrderData.inboundLumper : loadData.loadType === 3 ? firstOrderData.outboundLumper : '';
          fieldType = 'currency';
          break;
        case 'driver_truck_Trailer_VIN':
          value = `${firstOrderData.driverName ? firstOrderData.driverName : ''} ${firstOrderData.truckNumber ? firstOrderData.truckNumber : ''} ${
            firstOrderData.trailer ? firstOrderData.trailer : ''
          }`; //??? + vin
          break;
        case 'carrierName':
          value = firstOrderData.carrierName;
          fieldType = 'text';
          break;
        case 'rate':
          value = firstOrderData.rate ? firstOrderData.rate : '';
          fieldType = 'currency';
          break;
        case 'space':
          value = firstOrderData.space;
          fieldType = 'text';
          break;
        case 'dellAppt':
          value =
            loadData.loadType === 1
              ? toDateTime(firstOrderData.inboundDropAppointment)
              : loadData.loadType === 3
              ? toDateTime(firstOrderData.outboundDropAppointment)
              : '';
          fieldType = 'dateTime';
          break;
        case 'pickOutDate':
          value =
            loadData.loadType === 1
              ? toDateTime(firstOrderData.inboundPickOut)
              : loadData.loadType === 3
              ? toDateTime(firstOrderData.outboundPickOut)
              : '';
          fieldType = 'dateTime';
          break;
        case 'readyDate':
          value = toDateTime(firstOrderData.readyDate);
          fieldType = 'date';
          break;
        case 'in_load':
          value = firstOrderData.inLoad;
          break;
        case 'out_load':
          value = firstOrderData.outLoad;
          break;
        case 'wk':
          value = firstOrderData.puDate && new Date(firstOrderData.puDate).getWeek();
          fieldType = 'text';
          break;
        case 'dueDate':
          value = toDateTime(firstOrderData.dueDate);
          fieldType = 'date';
          break;
        case 'dropInDate':
          value =
            loadData.loadType === 1
              ? toDateTime(firstOrderData.inboundDropIn)
              : loadData.loadType === 3
              ? toDateTime(firstOrderData.outboundDropIn)
              : '';
          fieldType = 'dateTime';
          break;
        case 'dropOutDate':
          value =
            loadData.loadType === 1
              ? toDateTime(firstOrderData.inboundDropOut)
              : loadData.loadType === 3
              ? toDateTime(firstOrderData.outboundDropOut)
              : '';
          fieldType = 'dateTime';
          break;
        case 'pickLocation':
          value = firstOrderData.pickLocation;
          fieldType = 'text';
          break;
        case 'dropLocation':
          value = 'San Fernando, CA (TST)'; //??? need to change
          fieldType = 'text';
          break;
        case 'inboundPickAppointment':
          value =
            loadData.loadType === 1
              ? toDateTime(stopData.inboundPickAppointment)
              : loadData.loadType === 3
              ? toDateTime(stopData.outboundPickAppointment)
              : '';
          fieldType = 'dateTime';
          break;
        case 'palletCount':
          value =
            stopData.ordersData.reduce((acc, order) => {
              return acc + order.rowState.orderData.palletCount;
            }, 0) || '';
          fieldType = 'text';
          break;
        case 'cases':
          value =
            stopData.ordersData.reduce((acc, order) => {
              return acc + order.rowState.orderData.cases;
            }, 0) || '';
          fieldType = 'text';
          break;
        case 'weight':
          value =
            stopData.ordersData.reduce((acc, order) => {
              return acc + order.rowState.orderData.weight;
            }, 0) || '';
          fieldType = 'text';
          break;
        case 'loadNumber':
          value = loadData ? loadData.loadNumber : '';
          fieldType = 'link';
          break;
        case 'notes':
          value = loadData.notes;
          break;
        case 'orderStatusByCall':
          value = loadData.loadType === 1 ? firstOrderData.inOrderStatusByCall : loadData.loadType === 3 ? firstOrderData.outOrderStatusByCall : '';
          fieldType = 'select';
          break;
        case 'delayedReason':
          value = loadData.loadType === 1 ? firstOrderData.inDelayedReason : loadData.loadType === 3 ? firstOrderData.outDelayedReason : '';
          fieldType = 'select';
          break;
        default:
          break;
      }
    } else {
      value = '';
      fieldType = 'text';
    }

    stopData.row.push({
      key: headerItem.key,
      value,
      fieldType,
      displayState: 1,
      editable,
      cellStyle,
      tooltip,
    });
  }

  return stopData;
};

export const createWebOrder = (oData, headers, loadData, loadId, isHidden, stopId) => {
  const orderData = { ...oData };

  const orderWebData = {
    row: [],
    rowState: {
      type: 'order',
      id: `order_${orderData.id}_${loadId}`,
      orderObjectId: orderData.id,
      loadObjectId: loadData && loadData.id,
      orderData: orderData,
      loadData,
      orderId: `order_${orderData.id}`,
      loadId: loadId && loadId,
      stopId: stopId && stopId,
      visible: !isHidden,
      reacted: false,
      available: loadData === null || !loadData.id,
      height: itemDefaultSize,
    },
  };
  const pathname = window.location.pathname;

  for (let hederIndex = 0; hederIndex < headers.length; hederIndex++) {
    const headerItem = headers[hederIndex];
    let value = null;
    let value2 = null;
    let fieldType = 'text';
    let editable = false;
    let cellStyle = {};
    let tooltip = null;
    if (!headerItem.leftAction) {
      switch (headerItem.key) {
        case 'id':
          value = orderData.id;
          break;
        case 'pickInDate':
          value = loadData.loadType === 1 ? toDateTime(orderData.inboundPickIn) : loadData.loadType === 3 ? toDateTime(orderData.outboundPickIn) : '';
          fieldType = 'dateTime';
          break;
        case 'lumper':
          value = loadData.loadType === 1 ? orderData.inboundLumper : loadData.loadType === 3 ? orderData.outboundLumper : '';
          fieldType = 'currency';
          break;
        case 'dellAppt':
          value =
            loadData.loadType === 1
              ? toDateTime(orderData.inboundDropAppointment)
              : loadData.loadType === 3
              ? toDateTime(orderData.outboundDropAppointment)
              : '';
          fieldType = 'dateTime';
          break;
        case 'pickOutDate':
          value =
            loadData.loadType === 1 ? toDateTime(orderData.inboundPickOut) : loadData.loadType === 3 ? toDateTime(orderData.outboundPickOut) : '';
          fieldType = 'dateTime';
          break;
        case 'readyDate':
          value = toDateTime(orderData.readyDate);
          fieldType = 'date';
          break;
        case 'in_load':
          value = orderData.inLoad;
          break;
        case 'out_load':
          value = orderData.outLoad;
          break;
        case 'loadNumber':
          value = loadData.loadType === 1 ? orderData.inboundLoadNumber : loadData.loadType === 3 ? orderData.outboundLoadNumber : '';
          break;
        case 'wk':
          value = orderData.puDate && new Date(orderData.puDate).getWeek();
          fieldType = 'text';
          break;
        case 'space':
          value = orderData.space;
          fieldType = 'text';
          break;
        case 'dueDate':
          value = toDateTime(orderData.dueDate);
          fieldType = 'date';
          break;
        case 'dropInDate':
          value = loadData.loadType === 1 ? toDateTime(orderData.inboundDropIn) : loadData.loadType === 3 ? toDateTime(orderData.outboundDropIn) : '';
          fieldType = 'dateTime';
          break;
        case 'dropOutDate':
          value =
            loadData.loadType === 1 ? toDateTime(orderData.inboundDropOut) : loadData.loadType === 3 ? toDateTime(orderData.outboundDropOut) : '';
          fieldType = 'dateTime';
          break;
        case 'customerCodeOrName':
          value = orderData.customerCodeOrName;
          fieldType = 'text';
          break;
        case 'dropLocation':
          value = orderData.dropLocation;
          fieldType = 'text';
          break;
        case 'pickLocation':
          value = orderData.pickLocation;
          fieldType = 'text';
          break;
        case 'inboundPickAppointment':
          value =
            loadData.loadType === 1
              ? toDateTime(orderData.inboundPickAppointment)
              : loadData.loadType === 3
              ? toDateTime(orderData.outboundPickAppointment)
              : '';
          fieldType = 'dateTime';
          break;
        case 'poNumber':
          value = orderData.poNumber;
          fieldType = 'text';
          break;
        case 'soNumber':
          value = orderData.soNumber;
          fieldType = 'textInput';
          break;
        case 'palletCount':
          value = orderData.palletCount;
          fieldType = 'integer';
          break;
        case 'cases':
          value = orderData.cases;
          fieldType = 'integer';
          break;
        case 'weight':
          value = orderData.weight;
          fieldType = 'integer';
          break;
        case 'podRequestStatus':
          let tempValue = loadData.loadType === 1 ? orderData.inPodRequestStatus : loadData.loadType === 3 ? orderData.outPodRequestStatus : '';
          if (pathname === '/ap/deliveredtrucks') {
            value = tempValue;
            fieldType = 'select';
          } else {
            const selectOptionsPOD = { null: '-', 0: '-', 1: 'Received', 2: 'Requested', 3: 'Don’t Split' };
            value = selectOptionsPOD[tempValue];
            fieldType = 'text';
          }
          tooltip =
            loadData.loadType === 1 && orderData.inPodRequestAuthorFullName.trim()
              ? `Last Changed: ${orderData.inPodRequestAuthorFullName}`
              : loadData.loadType === 1 && !orderData.inPodRequestAuthorFullName.trim()
              ? `No changes`
              : loadData.loadType === 3 && orderData.outPodRequestAuthorFullName.trim()
              ? `Last Changed: ${orderData.outPodRequestAuthorFullName}`
              : loadData.loadType === 3 && !orderData.outPodRequestAuthorFullName.trim()
              ? `No changes`
              : '';
          break;
        case 'podDoc':
          value =
            loadData.loadType === 1
              ? orderData.callCheckInAttachment?.fileName
              : loadData.loadType === 3
              ? orderData.callCheckOutAttachment?.fileName
              : '';
          tooltip =
            loadData.loadType === 1 && orderData.callCheckInAttachment?.fullName
              ? `Last Changed: ${orderData.callCheckInAttachment?.fullName}`
              : loadData.loadType === 1 && !orderData.callCheckInAttachment?.fullName
              ? `No changes`
              : loadData.loadType === 3 && orderData.callCheckOutAttachment?.fullName
              ? `Last Changed: ${orderData?.callCheckOutAttachment?.fullName}`
              : loadData.loadType === 3 && !orderData.callCheckOutAttachment?.fullName
              ? `No changes`
              : '';
          break;
        case 'orderNotesByCall':
          value2 = loadData.loadType === 1 ? orderData.inPodNotesHistory : loadData.loadType === 3 ? orderData.outPodNotesHistory : '';
          let tempValueOrderNotes = loadData.loadType === 1 ? orderData.inNotes : loadData.loadType === 3 ? orderData.outNotes : '';
          if (pathname === '/ap/deliveredtrucks') {
            value = tempValueOrderNotes;
            fieldType = 'select_and_popover';
          } else {
            const selectOptionsPODStatus = { Delivered: 'Delivered', Shortage: 'Shortage', Overage: 'Overage', Reject: 'Reject', 'Detoured': 'Detoured' };
            value = selectOptionsPODStatus[tempValueOrderNotes];
            fieldType = 'popover';
          }
          break;
        case 'actions':
          value = 'text';
          fieldType = 'actions';
          break;
        default:
          break;
      }
    } else {
      value = '';
      fieldType = 'text';
    }

    orderWebData.row.push({
      key: headerItem.key,
      value,
      value2,
      fieldType,
      displayState: 1,
      editable,
      cellStyle,
      tooltip,
    });
  }

  return orderWebData;
};

export const setHeaderWidth = (state, ind, width) => {
  const header = state.headers[ind];
  header.width = width;
  return {
    ...state,
    ...{
      headers: [...state.headers.slice(0, ind), ...[header], ...state.headers.slice(ind + 1)],
      loadsAssignedOrders: state.loadsAssignedOrders,
    },
  };
};

export const filterDeliveredLoads = (state, type) => {
  return {
    ...state,
    ...{
      filterType: type,
    },
  };
};
export const sortBy = (state, sortValue) => ({
  ...state,
  ...{
    sortType: sortValue,
  },
});

export const reactedAssigned = (state, type, id) => {
  const loadsAssignedOrders = state.loadsAssignedOrders;
  const index = loadsAssignedOrders.findIndex(i => i.rowState.id === id && i.rowState.type === type);
  const item = loadsAssignedOrders[index];

  if (item && item.rowState.type !== 'order') {
    item.rowState.reacted = !item.rowState.reacted;

    if (type === 'load') {
      const stops = loadsAssignedOrders.filter(i => i.rowState.loadId === id && i.rowState.type === 'stop');
      const orders = loadsAssignedOrders.filter(i => i.rowState.loadId === id && i.rowState.type === 'order');

      stops.forEach(stop => {
        if (stop.rowState.visible) {
          orders.forEach(o => (o.rowState.visible = false));
          stop.rowState.visible = false;
        } else {
          orders.forEach(o => (o.rowState.visible = true));
          stop.rowState.visible = true;
        }
      });

      return {
        ...state,
        ...{
          headers: state.headers,
          loadsAssignedOrders: [...state.loadsAssignedOrders.slice(0, index), ...[item], ...state.loadsAssignedOrders.slice(index + 1)],
        },
      };
    }
    if (type === 'stop') {
      const stopOrders = state.loadsAssignedOrders.filter(i => i.rowState.stopId === id && i.rowState.type === 'order');
      stopOrders.forEach(o => (o.rowState.visible = !o.rowState.visible));

      return {
        ...state,
        ...{
          headers: state.headers,
          loadsAssignedOrders: [...state.loadsAssignedOrders.slice(0, index), ...[item], ...state.loadsAssignedOrders.slice(index + 1)],
        },
      };
    }
  }
  return state;
};

export const updateLoadOrderGroupData = (state, loadData, changedOrder, header, newValue, newOrders) => {
  let stopsOrders;
  let orderLoad;

  const orderStops = state.loadsAssignedOrders.filter(order => order.rowState?.orderData?.id === changedOrder.id);
  const changedOrderLoadType = loadData.loadType;
  const orderStopId = orderStops.find(stop => stop.rowState.loadData.loadType === changedOrderLoadType).rowState.stopId;

  const stopOrdersState = state.loadsAssignedOrders.filter(order => order.rowState.stopId === orderStopId);
  const stopOrdersIds = stopOrdersState.map(order => order.rowState.orderData.id);
  stopsOrders = newOrders.filter(order => stopOrdersIds.includes(order.id));
  orderLoad = state.loadsAssignedOrders.find(l => l.rowState.type === 'load' && l.rowState.loadData.id === loadData.id);

  if (orderLoad) {
    stopsOrders.forEach(order => {
      if (header.key === 'orderStatusByCall') {
        if (orderLoad.rowState.loadData.loadType === 3) {
          order['outOrderStatusByCall'] = newValue;
          if (newValue === 0 || newValue === 1) {
            order['outDelayedReason'] = null;
          }
        } else if (orderLoad.rowState.loadData.loadType === 1) {
          if (newValue === 0 || newValue === 1) {
            order['inDelayedReason'] = null;
          }
          order['inOrderStatusByCall'] = newValue;
        }
      } else if (header.key === 'delayedReason') {
        if (orderLoad.rowState.loadData.loadType === 3) {
          order['outDelayedReason'] = newValue;
        } else if (orderLoad.rowState.loadData.loadType === 1) {
          order['inDelayedReason'] = newValue;
        }
      }
      order[header.key] = newValue;
    });
  }
};

export const setData = (state, newValue, rowState, header) => {
  if (rowState.orderData || rowState.firstOrderData) {
    const newOrders = [...state.orders];
    const changedOrder = rowState.firstOrderData
      ? newOrders.find(o => o.id === rowState.firstOrderData.id)
      : newOrders.find(o => o.id === rowState.orderData.id);
    if (changedOrder) {
      if (header.key === 'orderStatusByCall') {
        if (rowState.loadData.loadType === 3) {
          changedOrder['outOrderStatusByCall'] = newValue;
          if (newValue !== 2) {
            changedOrder['outDelayedReason'] = null;
          }
        } else if (rowState.loadData.loadType === 1) {
          changedOrder['inOrderStatusByCall'] = newValue;
          if (newValue !== 2) {
            changedOrder['inDelayedReason'] = null;
          }
        }
      } else if (header.key === 'delayedReason') {
        if (rowState.loadData.loadType === 3) {
          changedOrder['outDelayedReason'] = newValue;
        } else if (rowState.loadData.loadType === 1) {
          changedOrder['inDelayedReason'] = newValue;
        }
      } else if (header.key === 'orderNotesByCall') {
        if (rowState.loadData.loadType === 3) {
          changedOrder['outNotes'] = newValue;
        } else if (rowState.loadData.loadType === 1) {
          changedOrder['inNotes'] = newValue;
        }
      } else if (header.key === 'podRequestStatus') {
        if (rowState.loadData.loadType === 3) {
          changedOrder['outPodRequestStatus'] = newValue;
        } else if (rowState.loadData.loadType === 1) {
          changedOrder['inPodRequestStatus'] = newValue;
        }
      } else if (header.key === 'podDoc') {
        if (rowState.loadData.loadType === 3) {
          changedOrder['callCheckOutAttachment'] = newValue;
        } else if (rowState.loadData.loadType === 1) {
          changedOrder['callCheckInAttachment'] = newValue;
        }
      } else if (header.key === 'loadCallCheckNote') {
        if (rowState.loadData.loadType === 3) {
          const stop = rowState.loadData.stops.filter(stop => stop.locationId === changedOrder.dropLocationId);
          if (stop && stop[0]) {
            stop[0].notes = newValue;
          } else if (!stop[0]) {
            rowState.loadData.stops.push({ notes: newValue, locationId: changedOrder.dropLocationId });
          }
        } else if (rowState.loadData.loadType === 1) {
          const stop = rowState.loadData.stops.filter(stop => stop.locationId === changedOrder.pickLocationId);
          if (stop && stop[0]) {
            stop[0].notes = newValue;
          } else if (!stop[0]) {
            rowState.loadData.stops.push({ notes: newValue, locationId: changedOrder.pickLocationId });
          }
        }
      }
      changedOrder[header.key] = newValue;
    }
    updateLoadOrderGroupData(state, rowState?.loadData, changedOrder, header, newValue, newOrders);
    return updateOrdersLoadState(state, newOrders, state.loads, state.subOrders, state.hasError, state.message, state.filterType, state.sortType);
  } else {
    return state;
  }
};

export const setDisplayState = (state, rowState, header, enable) => ({
  ...state,
  ...{
    lastSelected: {
      id: rowState.id,
      headerKey: header.key,
      enable: enable,
    },
  },
});

export const setError = (state, err) => ({
  ...state,
  ...{
    hasError: true,
    message: 'Something went wrong, please try again',
  },
});

export const resetError = state => ({
  ...state,
  ...{
    hasError: false,
    message: '',
  },
});

export const setInLoading = state => ({
  ...state,
  ...{
    loading: true,
  },
});
