export const ElementConditions = {
    Enable: 'Enable',
    Hidden: 'Hidden'
};

export const Ability = {
    Displayability: () => true,
    Editability: () => true,
    NoEditability: () => false,
    NoDisplayability: () => false,
};