import axios from 'axios';
import store from '../..';
import * as actionTypes from './customerDashboardActionTypes';

const buildUrl = (resource = 'CustomerDashboard') => `/api/${resource}/landingdata`;

const axiosConfig = () => ({
    headers: {
        'Authorization': 'Bearer ' + store.getState().auth.userToken
    }
});

export const setCustomerDashboard = resource => dispatch => {
    dispatch({ type: actionTypes.getActionType(resource, actionTypes.CUSTOMER_DASHBOARD_LOADING) });
    axios.get(buildUrl(resource),
        axiosConfig())
        .then(responce => dispatch({ type: actionTypes.getActionType(resource, actionTypes.SET_CUSTOMER_DASHBOARD_DATA), data: responce.data }))
        .catch((err) => {
            console.error(err);
            console.log(err.response);
            dispatch({ type: actionTypes.getActionType(resource, actionTypes.ERROR) });
        });
};

export const resetError = resource =>
    ({ type: actionTypes.getActionType(resource, actionTypes.RESET_ERROR) });

