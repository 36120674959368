import React, { useState } from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import DragAndDrop from '../components/custom/dragDrop';

const DragDrop = () => {
    const [droppable1Data, setData] = useState([
        {
            id: 1,
            column1: "column11",
            column2: "column21",
            column3: "column31"
        },
        {
            id: 2,
            column1: "column12",
            column2: "column22",
            column3: "column32"
        },
        {
            id: 3,
            column1: "column13",
            column2: "column23",
            column3: "column33"
        },
        {
            id: 4,
            column1: "column14",
            column2: "column24",
            column3: "column34"
        },
        {
            id: 5,
            column1: "column15",
            column2: "column25",
            column3: "column35"
        }
    ]);

    const [droppable2Data, setData2] = useState([
        {
            id: 6,
            column1: "column111",
            column2: "column121",
            column3: "column131"
        },
        {
            id: 7,
            column1: "column112",
            column2: "column122",
            column3: "column132"
        },
        {
            id: 8,
            column1: "column113",
            column2: "column123",
            column3: "column133"
        }
    ]);

    // Can create multiple draggable sources
    // Usr props to get list of DroppableId, DroppableData
    const data = {
        droppable1: {
            data: droppable1Data,
            dispatch: setData
        },
        droppable2: {
            data: droppable2Data,
            dispatch: setData2
        }
    }

    return (
        <DragAndDrop {...data}>
            <h1>Draggable Between Table</h1>
            <div className="table_view adaptive small">
                <Droppable
                    droppableId="droppable1"
                    direction="vertical"
                >
                    {(provided, snapshot) => (
                        <table
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                        >
                            <thead>
                                <tr>
                                    <th></th>
                                    <th><text>Column 1</text></th>
                                    <th><text>Column 2</text></th>
                                    <th><text>Column 3</text></th>
                                </tr>
                            </thead>
                            <tbody>
                                {droppable1Data.map((item, index) => (
                                    <Draggable
                                        draggableId={item.id.toString()}
                                        key={item.id}
                                        index={index}
                                    >
                                        {(provided, snapshot) => (
                                            <tr
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                dragging={snapshot.isDragging ? '' : null}
                                            >
                                                <td
                                                    {...provided.dragHandleProps}
                                                ><icon>sort</icon></td>
                                                <td><text>{item.column1}</text></td>
                                                <td><text>{item.column2}</text></td>
                                                <td><text>{item.column3}</text></td>
                                            </tr>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </tbody>
                        </table>
                    )}
                </Droppable>
            </div>
            <div className="table_view adaptive small">
                <Droppable
                    droppableId="droppable2"
                    direction="vertical"
                >
                    {(provided, snapshot) => (
                        <table
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                        >
                            <thead>
                                <tr>
                                    <th></th>
                                    <th><text>Column 1</text></th>
                                    <th><text>Column 2</text></th>
                                    <th><text>Column 3</text></th>
                                </tr>
                            </thead>
                            <tbody>
                                {droppable2Data.map((item, index) => (
                                    <Draggable
                                        draggableId={item.id.toString()}
                                        key={item.id}
                                        index={index}
                                    >
                                        {(provided, snapshot) => (
                                            <tr
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                dragging={snapshot.isDragging ? '' : null}
                                            >
                                                <td><icon>sort</icon></td>
                                                <td><text>{item.column1}</text></td>
                                                <td><text>{item.column2}</text></td>
                                                <td><text>{item.column3}</text></td>
                                            </tr>
                                        )}
                                    </Draggable>))}
                                {provided.placeholder}
                            </tbody>
                        </table>
                    )}
                </Droppable>
            </div>
        </DragAndDrop>
    );
};

export default DragDrop