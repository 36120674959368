import React from 'react';

import { Tooltip } from 'antd';
import { CommonLabels } from '../../../project/Defines';


const NewButton = props => (<React.Fragment>
    {
        props.allowNewButton && <Tooltip title={props.addButtonText || CommonLabels.Buttons.Add}>
            <div className='button primary' onClick={props.onNewClick}>
                <text>{props.addButtonText || CommonLabels.Buttons.Add}</text>
            </div>
        </Tooltip>
    }
</React.Fragment>);

export default NewButton;
