import { CloneObject} from '../../../helper/common';
import {
    ElementTypes,
    Action,
    ListDefaults,
    FilterType,
    DefaultPagination,
    InlineTable,
    ComponentTypes,
    ElementValidations
} from '../../../config';
import { GenerateToolBars } from '../../../config/element.config';
import TruckConfig from '../../Truck/config';
import DriverConfig from '../../Driver/config';

import DefaultStopColumns from '../defaultStopColumns';
import DefaultOrderColumns from './defaultOrderColumns';
import OrderForm, { OutboundGroup1, OutboundGroup2, OutboundGroup3, OutboundGroup4, OutboundGroup5, Group1, Group2, Group3, Group4, Group5 } from '../orderFormConfig';
import PickAppointment from './pickAppointmentConfig';
import DropAppointment from './dropAppointmentConfig';
import LoadSequence from './loadSequenceForm';

const tableConfig = CloneObject(InlineTable);
tableConfig.Style.pagination = false;

const Load = {
    Title: 'Load',
    Resource: 'Load',
    AreaResource: 'load/area',
    LoadResource: 'Load',
    Master: 'code',
    Key: 'id',
    IsRest: true,
    ShowRefreshButton: true,
    Pages: {
        Filter: {
            applyFor: {
                Type: FilterType.Select,
                Title: 'Apply to orders',
                DefaultValue: "Available",
                ApplyOnChange: true,
                Valuelist: [
                    { "value": "Available", "text": "Available" },
                    { "value": "Assigned", "text": "Assigned" },
                    { "value": "Both", "text": "Both" }]
            },
            referenceNumber: {
                Title: 'Row',
                Type: FilterType.Text
            },
            pickLocationId: {
                Title: 'Pick Location',
                Type: FilterType.Select,
                Valuelist: 'pickLocation'
            },
            pickRegionCode: {
                Title: 'Pick Region',
                Type: FilterType.Select,
                Valuelist: 'pickRegion'
            },
            customerId: {
                Type: FilterType.MultiSelect,
                Title: 'Customer',
                Valuelist: 'customer'
            },
            batch: {
                Type: FilterType.Select,
                Title: 'Batch',
                Valuelist: [
                    { "value": 1, "text": "Hard" },
                    { "value": 2, "text": "Soft" },
                    { "value": 3, "text": "No" }]
            },
            dueDate: {
                Type: FilterType.Date,
                Title: 'Due-Date'
            },
            puDate: {
                Type: FilterType.Date,
                Title: 'Ready/Date'
            },
            dragDrop: {
                Type: FilterType.Draggable,
                Title: 'Regions',
                Valuelist: 'pickRegion',
                FieldSortResource: 'InboundFilterRegions',
                Assigned: 'Assigned'
            }
        },
        Order: {
            Key: 'id',
            List: {
                Defaults: ListDefaults,
                ShowRefreshButton: true,
                Pagination: DefaultPagination,
                Columns: {
                    ...DefaultOrderColumns
                },
                Actions: {
                    Width: 60,
                    Items: [
                        Action.Edit
                    ]
                }
            }
        },
        OrderForm,
        AssignedOrderGroups: [OutboundGroup1, OutboundGroup2, OutboundGroup3, OutboundGroup4, OutboundGroup5, Group1, Group2, Group3, Group4, Group5],
        Stop: {
            Title: 'Load',
            Master: 'loadNumber',
            Resource: 'Load',
            Key: 'id',
            IsRest: true,
            List: {
                Defaults: {
                    View: {
                        AutoHeight: null,
                        Small: null,
                    },
                    Table: tableConfig
                },
                Coloring: record => record.color,
                Columns: {
                    ...DefaultStopColumns(PickAppointment, DropAppointment, LoadSequence, 'InboundLoadSequence')
                },
                Actions: {
                    Display: o => o.isFirst,
                    Width: 120,
                    Items: [
                        Action.Edit,
                        Action.Delete,
                        (o) => o.readyToBook ? Action.Booked : Action.Book
                    ]
                },
                expandedRowKeys: [0, '']
            },
            Form: {
                Title: 'Truck',
                SectionGroups: [
                    {
                        Sections: [
                            {
                                Fields: {
                                    loadNumber: {
                                        Title: 'Load Number',
                                        Field: ElementTypes.LabelField(o => o.id > 0)
                                    },
                                    rate: {
                                        Title: 'Rate',
                                        Field: ElementTypes.Generic(ElementTypes.CurrencyFieldViewOrEdit, {
                                            condition: o => o.batch > 1
                                        })
                                    },
                                    color: {
                                        Title: 'Color',
                                        Field: ElementTypes.Color()
                                    }
                                }
                            },
                            {
                                Fields: {
                                    truckId: {
                                        Title: 'Truck Number',
                                        Field: ElementTypes.Generic(ElementTypes.ComboAddViewOrEdit, {
                                            dataSource: '/api/truck/valuelistempty',
                                            addparams: {
                                                PopupConfig: TruckConfig
                                            },
                                            condition: o => o.batch > 1,
                                            display: o => o.truckNumber
                                        })
                                    },
                                    driverId: {
                                        Title: 'Driver',
                                        Field: ElementTypes.Generic(ElementTypes.ComboAddViewOrEdit, {
                                            dataSource: '/api/driver/phonevaluelistempty',
                                            addparams: {
                                                PopupConfig: DriverConfig
                                            },
                                            condition: o => o.batch > 1,
                                            display: o => o.driverName
                                        }),
                                        Dependencies: ['driverPhone']
                                    },
                                    driverPhone: {
                                        Title: 'Driver Phone',
                                        Field: ElementTypes.Phone()
                                    }
                                }
                            },
                            {
                                Fields: {
                                    trailer: {
                                        Title: 'Trailer',
                                        Field: ElementTypes.LabelField()
                                    },
                                    notes: {
                                        Title: 'Notes',
                                        Field: ElementTypes.Comment(),
                                        Attributes: {
                                            textarea: {
                                                note: ''
                                            }
                                        }
                                    }
                                }
                            }
                        ]
                    }
                    ,
                    {
                        Sections: [
                            {
                                classes: 'extra',
                                Components: {
                                    attachments: {
                                        Component: ComponentTypes.Attachments('guid', {
                                            classes: 'compact',
                                            List: {
                                                Columns: {
                                                    guid: {
                                                        Title: 'Download',
                                                        Field: ElementTypes.Download('fileName'),
                                                        Width: 80,
                                                        Attributes: {
                                                            wrap: {
                                                                left: ''
                                                            }
                                                        }
                                                    },
                                                    fileName: {
                                                        Title: 'File Name',
                                                        Field: ElementTypes.TextField(),
                                                        Validations: [ElementValidations.Required()],
                                                        Width: 250,
                                                        Attributes: {
                                                            wrap: {
                                                                left: ''
                                                            }
                                                        }
                                                    },
                                                    originalFileName: {
                                                        Title: 'Original File Name',
                                                        Field: ElementTypes.TableLabelField(),
                                                        Width: 250,
                                                        Attributes: {
                                                            wrap: {
                                                                left: ''
                                                            }
                                                        }
                                                    },
                                                    size: {
                                                        Title: 'File Size',
                                                        Field: ElementTypes.Generic(ElementTypes.TableFileSizeLabelField),
                                                        Width: 80,
                                                        Attributes: {
                                                            wrap: {
                                                                left: ''
                                                            }
                                                        }
                                                    },
                                                    createDate: {
                                                        Title: 'Created Date',
                                                        Field: ElementTypes.ColumnDate((o, data) => o && data),
                                                        Width: 80,
                                                        Attributes: {
                                                            wrap: {
                                                                left: ''
                                                            }
                                                        }
                                                    },
                                                    fullName: {
                                                        Title: 'Created By',
                                                        Field: ElementTypes.TableLabelField(),
                                                        Width: 80,
                                                        Attributes: {
                                                            wrap: {
                                                                left: ''
                                                            }
                                                        }
                                                    },
                                                    external: {
                                                        Title: 'External',
                                                        Field: ElementTypes.ColumnBool(),
                                                        Width: 60,
                                                        Attributes: {
                                                            wrap: {
                                                                left: ''
                                                            }
                                                        }
                                                    }
                                                }
                                            },
                                            Popup: {
                                                Title: 'Attachment Comment',
                                                SectionGroups: [
                                                    {
                                                        Sections: [
                                                            {
                                                                Fields: {
                                                                    fileName: {
                                                                        Title: 'File Name',
                                                                        Field: ElementTypes.LabelField()
                                                                    },
                                                                    originalFileName: {
                                                                        Title: 'Original File Name',
                                                                        Field: ElementTypes.LabelField()
                                                                    },
                                                                    size: {
                                                                        Title: 'File Size',
                                                                        Field: ElementTypes.Generic(ElementTypes.FileSizeLabelField)
                                                                    },
                                                                    comments: {
                                                                        Title: 'File Comment',
                                                                        Field: ElementTypes.Comment(),
                                                                        Focus: true
                                                                    }
                                                                }
                                                            }
                                                        ]
                                                    }
                                                ],
                                                ToolBars: GenerateToolBars(['SaveClose', 'Cancel'])
                                            }
                                        })
                                    }
                                }
                            }
                        ]
                    }
                ],
                ToolBars: GenerateToolBars(['SaveClose', 'Cancel'])
            }
        }
    }
};

export default Load;