import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Modal from 'react-modal';
import moment from 'moment';
import { Checkbox } from 'antd';
import './index.css';
import { Defines } from '../../../../project/Defines';
import { assignOrdersToLoadAndUpdateNewLoad } from '../../../../store/actions/loadArea/loadArea';
import { customStyles } from './styles'

const AssignOrdersModalNewLoad = ({ 
    setAssignOrdersModalIsOpen,
    assignOrdersModalIsOpen,
    resource,
    allAvailableOrders,
    state,
  }) => {
  const dispatch = useDispatch();
  const assignedOrders = state && resource && state[resource]?.assignedOrders;
  const appointmentByLoadType = resource === 'load' ? 'inboundPickAppointment': 'outboundDropAppointment';
  const appointmentStatusByLoadType = resource === 'load' ? 'inboundAppointmentStatus': 'outboundAppointmentStatus';
  const location = resource === 'load' ? 'pickLocation' : 'dropLocation';
  const [locationObjects, setLocationObjects] = useState([]);
  const [checkedAppointments, setCheckedAppointments] = useState([]);
  const closeModal = () => {
    setAssignOrdersModalIsOpen(false);
    dispatch(assignOrdersToLoadAndUpdateNewLoad(resource, assignedOrders, allAvailableOrders, 'cancelModal', locationObjects));
  };

  const handleOnSave = () => {
    setAssignOrdersModalIsOpen(false);
    dispatch(assignOrdersToLoadAndUpdateNewLoad(resource, assignedOrders, allAvailableOrders, false, locationObjects))
  };

  const listOfLocationsSet = new Set(assignedOrders.map(order => order[location]));
  const listOfLocations = [...listOfLocationsSet];
  const [allAreChecked, setAllAreChecked] = useState(false);
  
  useEffect(() => {
    const tempLocationObjects = [];
    let apptStatus = '';
    let currentApptointment = '';
    for (let i = 0; i < listOfLocations.length; i++) {
      let object = {};
      let appointments = assignedOrders
        .filter(order => order[location] === listOfLocations[i] && order[appointmentByLoadType])
        .map(order => order[appointmentByLoadType]);

        let status = assignedOrders
        .filter(order => order[location] === listOfLocations[i] && order[appointmentByLoadType])
        .map(order => Number(order[appointmentStatusByLoadType]));

      object.appointments = appointments;
      object.status = status;
      object.checkedAppointment = currentApptointment
        ? currentApptointment
        : appointments.length === 1 || appointments.every(item => item === appointments[0])
        ? appointments[0]
        : '';
      object.checkedStatus = apptStatus ? apptStatus : status.length === 1 || status.every(item => item === status[0]) ? status[0] : '';
      object[location] = listOfLocations[i];
      object.orderIds = assignedOrders
        .filter(order => order[location] === listOfLocations[i])
        .map(order => order.id);
      tempLocationObjects.push(object);
    }
    const tempCheckedAppointments = [];
    tempLocationObjects.forEach(object => {
      const appt = `${object.checkedAppointment}_${object.checkedStatus}_${object[location]}`;
      object.checkedAppointment && object.checkedStatus && tempCheckedAppointments.push(appt)
    });
    setCheckedAppointments(tempCheckedAppointments);
    setLocationObjects(tempLocationObjects);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // case when all location's appointments checks automatically (only one order or all orders have the same location)
    let checked = false;
    let tempObject = {};
    locationObjects.forEach((object, index) => {
      if ((object.checkedAppointment && object.checkedStatus) || object.orderIds.length === 1) {
        tempObject[index] = true;
      } else {
        tempObject[index] = false;
      }
    });
    if (Object.values(tempObject)?.every(value => value === true)) {
      checked = true
    }

    const checkedAppointment = locationObjects.filter(object => !object.checkedAppointment).length === 0;
    const checkedStatus = locationObjects.filter(object => !object.checkedStatus).length === 0;
    const apptAndStatusAreChecked = locationObjects.length && (checkedAppointment && checkedStatus);
    setAllAreChecked(checked && apptAndStatusAreChecked)
    if (allAreChecked) {
      dispatch(assignOrdersToLoadAndUpdateNewLoad(resource, assignedOrders, allAvailableOrders, false, locationObjects))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationObjects, allAreChecked]);

  const handleOnChange = (e, status, selectedLocation) => {
    const clicked = `${e.target.value}_${status}_${selectedLocation}`;
    if (checkedAppointments.includes(clicked)) {
      const index = checkedAppointments.indexOf(clicked);
      checkedAppointments.splice(index, 1);
      setCheckedAppointments([...checkedAppointments]);
    } else {
      const test = checkedAppointments.filter(item => !item.includes(selectedLocation));
      setCheckedAppointments([...test, `${e.target.value}_${status}_${selectedLocation}`]);
    }

    locationObjects.forEach(object => {
      if (object?.[location] === selectedLocation) {
        object.checkedAppointment = e.target.value;
        object.checkedStatus = status;
      }
    });
  };

  const removeDuplicates = location => {
    const objectsList = [];
    for (let i = 0; i < location?.appointments?.length; i++) {
      const object = {
        appointment: location?.appointments[i],
        status: location?.status[i],
      };
      objectsList.push(object);
    }
    //  return Unique by all properties in array
    const arrUniq = [...new Map(objectsList.map(v => [JSON.stringify(v), v])).values()];
    return arrUniq;
  };

  const locationObjectsOnlyChecked = locationObjects.filter(obj => obj.checkedAppointment !== undefined);
  const finalListOfLocationObjects = locationObjects;

  return (
    <Modal isOpen={assignOrdersModalIsOpen && !allAreChecked} onRequestClose={closeModal} style={customStyles} ariaHideApp={false}>
      <div className='assign-orders__modal'>
        <div className='rate_modal__x-btn' onClick={closeModal}>
          X
        </div>
        <div className='assign-orders__container'>
          <div>Attention:</div>
          <div>{`The orders have different ${resource === 'load' ? 'pick' : 'drop'} appointments`}</div>
          <div>Please choose the correct one</div>
          {finalListOfLocationObjects.length &&
            // eslint-disable-next-line array-callback-return
            finalListOfLocationObjects.map((locationObj, i) => {
              if (!locationObj.appointments.length === 1 || 
                  !locationObj.appointments.every(item => item === locationObj.appointments[0]) ||
                  !locationObj.status.every(item => item === locationObj.status[0])
                  ) {
                return (
                  <>
                    <div className='assign-orders__location'>
                        {locationObj.appointments.length && resource === 'load' ? locationObj?.pickLocation :
                          locationObj.appointments.length && resource === 'outboundload' ? locationObj?.dropLocation : ''}
                    </div>
                    <div className='assign-orders__checkbox-container'>
                      {removeDuplicates(locationObj).map((appointmentObject, i) => {
                        return (
                          <Checkbox
                            key={`${appointmentObject.appointment}_${locationObj[location]}`}
                            style={{ width: '100%' }}
                            className={`status_${appointmentObject.status}`}
                            value={appointmentObject.appointment}
                            checked={checkedAppointments.includes(`${appointmentObject.appointment}_${appointmentObject.status}_${locationObj[location]}`)}
                            onChange={e => handleOnChange(e, appointmentObject.status, locationObj[location])}>
                            {moment.utc(appointmentObject.appointment).local().format(Defines.Format.PrintDateTime)}
                          </Checkbox>
                        );
                      })}
                    </div>
                  </>
                );
              }
            })}
        </div>
        <div className='assign-orders__button-container'>
          <button
            className={`assign-orders__button-save ${checkedAppointments.length !== locationObjectsOnlyChecked.length ? 'assign-orders__btn-disabled' : ''}`}
            disabled={checkedAppointments.length !== locationObjectsOnlyChecked.length}
            onClick={handleOnSave}>
            SAVE
          </button>
          <div className='assign-orders__button-cancel' onClick={closeModal}>
            CANCEL
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AssignOrdersModalNewLoad;
