import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import moment from 'moment';
import { Tooltip, Checkbox } from 'antd';
import { Defines } from '../../../../project/Defines';
import { GenerateMessages } from '../../../helper/element';
import './index.css';

const Cell = ({ 
        state,
        cellData,
        itemSize,
        resource,
        setCheckedList,
        checked,
        rowState,
    }) => {
    const loadArea = state[resource];
    const header = loadArea.headers.find(h => h.key === cellData.key);
    const width = header.width;
    const [tempData, setTempData] = useState(null);
    const data = cellData.value;
    const cellStyle = cellData.cellStyle;
    const tooltip = cellData.tooltip;
    const color = '#101C1C';

    useEffect(() => {
        setTempData(data);
    }, [data]);

    const onChange = e => {
        let customerIdList = [];
        setCheckedList(oldArray => {
            if (!oldArray.includes(rowState.orderObjectId)) {
                customerIdList = [...oldArray, rowState.orderObjectId];
                return customerIdList;
            } else {
                customerIdList = oldArray.filter(item => item !== rowState.orderObjectId);
                return customerIdList;
            }
        });    
    };

    const getData = () => {        
        if (tempData !== '') {
            switch (cellData.fieldType) {
                case 'date':
                    return moment(tempData).format(Defines.Format.PrintDate)
                case 'text':
                    return tempData;
                case 'checkbox':
                    return <Checkbox onChange={onChange} checked={checked}></Checkbox>
                    default:
                return tempData;
            }
        }
        return tempData
    };

    const getDataWithTooltip = () => tooltip ?
    <Tooltip title={GenerateMessages([tooltip])}>
        {getData()}
    </Tooltip> : getData();

    const dataDisplay = () =>  getDataWithTooltip();

    const cellDefaultStyle = {
        maxWidth: `${width}px`,
        width: `${width}px`,
        minWidth: `${width}px`,
        height: `${itemSize}px`,
        lineHeight: 'inherit',
    };

    return (
        <div
            className='table-body-cell'
            style={{ ...cellDefaultStyle, ...cellStyle, color: color}}
            >
            {dataDisplay()}
        </div>
    );
};

const mapStateToProps = state => ({
    state: state,
    loadsAssignedOrders: state.orders.loadsAssignedOrders,
});

export default connect(mapStateToProps)(Cell);