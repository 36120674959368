import * as popupActionTypes from '../../actions/popup/popupActionTypes';


const defaultState = {
    popups: []
};

export default (state = defaultState, action) => {
    const popups = [...state.popups];
    switch (action.type) {
        case popupActionTypes.OPEN_POPUP:
            popups.push(action.data);
            return {
                ...state,
                popups
            };
        case popupActionTypes.CLOSE_POPUP:
            const index = popups.findIndex(p => p.windowKey === action.windowKey);
            if (index >= 0) {
                popups.splice(index, 1);
            }
            return {
                ...state,
                popups
            };
        case popupActionTypes.CLOSE_ALL_POPUPS:
            return {
                ...state,
                popups: []
            };
        default:
            return state;
    }
};