export const colorStyles = {
  container: (base, state) => ({
    ...base,
    margin: '6px',
  }),
  control: (base, state) => ({
    ...base,
    borderColor: 'lightGray',
    borderRadius: '8px',
    boxShadow: 'none',
    minHeight: '28px',
  }),
  menu: (base, state) => ({
    ...base,
    zIndex: '200',
  }),
  valueContainer: (base, state) => ({
    display: 'flex',
    alignItems: 'self-end',
    paddingLeft: '5px',
  }),
  singleValue: (base, state) => ({
  }),
  indicatorsContainer: () => ({
    padding: '0 8px!important',
  }),
  dropdownIndicator: () => ({
    padding: '2px 0 0 0!important',
  }),
  input: () => ({
    padding: 0,
    margin: 0,
  }),
};