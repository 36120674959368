import { useEffect, useState } from 'react';
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import store from '../../../store';

export const useClientHubOrder = (onNotificationReceive, event) => {
    const [connection, setConnection] = useState(null);

    useEffect(() => {
        const token = store.getState().auth.userToken;
        if (!connection) {
            const newConnection = new HubConnectionBuilder()
                .withUrl(`/OrderUpdateNotificationHub`, {
                    accessTokenFactory: () => token
                })
                .configureLogging(LogLevel.Information)
                .withAutomaticReconnect()
                .build();
            setConnection(newConnection);
        }
        return () => {
            if (connection) {
                connection.stop();
            }
        };
    }, [connection]);

    useEffect(() => {
        let isMounted = true;
        const fetchConnection = () => {
            if (isMounted && connection) {
                if (connection.connectionState === 'Disconnected') {
                    connection.start()
                        .then(() => {
                            console.log('Client HUB for Order Connected!');
                            connection.on(event, message => {
                                onNotificationReceive(message);
                            });
                        })
                        .catch(e => console.error('Client HUB Connection failed: ', e));
                }
            }
        }
        fetchConnection();
        return () => {
            isMounted = false;
        };
    }, [connection, onNotificationReceive, event]);

    return null;
};
