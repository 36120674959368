import React from 'react';
import AdminCallCheckSpreadsheet from '../../AdminCallCheck/spreadsheet';

const AdminCallCheckBuld = () => (
  <div
    style={{
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
    }}>
    <AdminCallCheckSpreadsheet selectable resource='callCheck' />
  </div>
);

export default AdminCallCheckBuld;
