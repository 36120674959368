export const getLocations = (state, response, hasError = false, message = '') => {
    const data = response.data || [];
    return {
        ...state, ...{
            locations: data,
            loading: false,
            hasError,
            message,
        }    
    }
};

export const setError = (state, err) => ({
    ...state,
    ...{
        hasError: true,
        message: 'Something went wrong, please try again',
    },
});

export const setInLoading = state => ({
    ...state, ...{
        loading: true
    }
});
