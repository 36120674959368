import * as authenticationActionTypes from '../../actions/authentication/authenticationActionTypes';
import { updateStateFromStore, updateStoreFromState, removeStore } from '../../updateState';
import { CloneObject } from '../../../codeless/helper/common';

const storeAuthenticationKeys = {
    isLoggedIn: 'IS_LOGGEDIN',
    userToken: 'USER_TOKEN',
    userRefreshToken: 'USER_REFRESH_TOKEN',
    userRole: 'USER_ROLE',
    userMenu: 'USER_MENU',
    userLoginName: 'USER_LOGIN_NAME',
    userFirstName: 'USER_FIRST_NAME',
    userId: 'USER_ID',
    userLastName: 'USER_LAST_NAME',
    userEmail: 'USER_EMAIL',
    userTokenExpiration: 'USER_TOKEN_EXPIRATION_DATE',
    userRememberMeToken: 'USER_REMEMBER_ME_TOKEN',
    googleApiKey: 'GOOGLE_API_KEY',
    profileSettings: 'USER_SETTINGS',
    profileSettingsTemp: 'USER_SETTINGS_TEMP',
};

const defaultAuthenticationState = {
    isLoggedIn: false,
    loading: false,
    error: null,
    redirectUrl: null,
    userToken: '',
    userRefreshToken: '',
    userRole: '',
    userMenu: null,
    userLoginName: '',
    userFirstName: '',
    userLastName: '',
    userId: '',
    userEmail: '',
    userTokenExpiration: null,
    userRememberMeToken: '',
    googleApiKey: '',
    profileSettings: null,
    profileSettingsTemp: null
};

const updateAuthenticationState = (state, authData, isLoggedIn) => {
    const updatedState = {
        ...state,
        isLoggedIn: isLoggedIn,
        loading: false,
        error: null,
        redirectUrl: authData.redirectUrl,
        userToken: authData.userToken,
        userRefreshToken: authData.refreshToken,
        userRole: authData.role,
        userMenu: authData.userMenu,
        userLoginName: authData.loginName,
        userFirstName: authData.firstName,
        userLastName: authData.lastName,
        userId: authData.userId,
        userEmail: authData.email,
        userTokenExpiration: authData.exp,
        userRememberMeToken: authData.rememberMeToken,
        googleApiKey: authData.googleApiKey,
        profileSettings: authData.userProfile,
        profileSettingsTemp: CloneObject(authData.userProfile)
    }
    updateStoreFromState(updatedState, storeAuthenticationKeys);
    return updatedState;
};

const refreshAuthenticationState = (state, authData, isLoggedIn) => {
    const updatedState = {
        ...state,
        isLoggedIn: isLoggedIn,
        loading: false,
        error: null,
        redirectUrl: authData.redirectUrl,
        userToken: authData.userToken,
        userRefreshToken: authData.refreshToken,
        userRole: authData.role,
        //userMenu: authData.userMenu, //no need to update userMenu because it will take screen update
        userLoginName: authData.loginName,
        userFirstName: authData.firstName,
        userLastName: authData.lastName,
        userId: authData.userId,
        userEmail: authData.email,
        userTokenExpiration: authData.exp
    }
    updateStoreFromState(updatedState, storeAuthenticationKeys);
    return updatedState;
};

const updateProfileState = (state, newState, isLoggedIn) => {
    const updatedState = {
        ...state,
        isLoggedIn: isLoggedIn,
        loading: false,
        error: null,
        // userRole: newState.role,
        userLoginName: newState.loginName,
        userFirstName: newState.firstName,
        userLastName: newState.lastName,
        userEmail: newState.email,
        // Avatar info
    }

    updateStoreFromState(updatedState, storeAuthenticationKeys);
    return updatedState;
};

const removeAuthenticationState = () => {
    removeStore(storeAuthenticationKeys);
    return defaultAuthenticationState;
};

const createProfileDefaults = (profile, data) => {
    if (!profile) {
        profile = {
            screens: {}
        };
    }

    if (!profile.screens[data.resource]) {
        profile.screens[data.resource] = {};
    }
    if (!profile.screens[data.resource].list) {
        profile.screens[data.resource].list = {};
    }
    if (!profile.screens[data.resource].swap) {
        profile.screens[data.resource].swap = false;
    }
    if (!profile.screens[data.resource].list.columns) {
        profile.screens[data.resource].list.columns = {};
    }

    return profile;
};

const updateScreenSwap = (state, data) => {
    const updatedProfileSettings = createProfileDefaults(CloneObject(state.profileSettings), data);
    updatedProfileSettings.screens[data.resource].swap = data.swap;
    const updatedState = {
        ...state,
        profileSettings: updatedProfileSettings
    }
    updateStoreFromState(updatedState, storeAuthenticationKeys);
    return updatedState;
};

const updateListColumnSettings = (state, data, fields) => {
    const updatedProfileSettings = createProfileDefaults(CloneObject(state.profileSettings), data);
    for (const k in data.columns) {
        const c = data.columns[k];
        if (!updatedProfileSettings.screens[data.resource].list.columns[c.name]) {
            updatedProfileSettings.screens[data.resource].list.columns[c.name] = {};
        }
        let field = null;
        if (fields && Array.isArray(fields)) {
            for (const f in fields) {
                field = fields[f];
                updatedProfileSettings.screens[data.resource].list.columns[c.name][field] = c[field];
            }
        }
        else {
            field = fields;
            updatedProfileSettings.screens[data.resource].list.columns[c.name][field] = c[field];
        }


    }
    const updatedState = {
        ...state,
        profileSettings: updatedProfileSettings
    }
    updateStoreFromState(updatedState, storeAuthenticationKeys);
    return updatedState;
};

const updateListFilterSettings = (state, data) => {
    const updatedProfileSettings = createProfileDefaults(CloneObject(state.profileSettings), data);

    updatedProfileSettings.screens[data.resource].list.columns = data.columns;

    const updatedState = {
        ...state,
        profileSettings: updatedProfileSettings
    }

    updateStoreFromState(updatedState, storeAuthenticationKeys);

    return updatedState;
};


const updateListColumnSettingsTemp = (state, data, field) => {
    const updatedProfileSettings = createProfileDefaults(CloneObject(state.profileSettingsTemp), data);
    for (const k in data.columns) {
        const c = data.columns[k];
        if (!updatedProfileSettings.screens[data.resource].list.columns[c.name]) {
            updatedProfileSettings.screens[data.resource].list.columns[c.name] = {};
        }
        updatedProfileSettings.screens[data.resource].list.columns[c.name][field] = c[field];
    }
    const updatedState = {
        ...state,
        profileSettingsTemp: updatedProfileSettings
    }
    updateStoreFromState(updatedState, storeAuthenticationKeys);
    return updatedState;
};

const updateListColumnSettingsFromTemp = state => {
    const updatedState = {
        ...state,
        profileSettings: CloneObject(state.profileSettingsTemp)
    }
    updateStoreFromState(updatedState, storeAuthenticationKeys);
    return updatedState;
};

const updateListColumnTempSettings = state => {
    const updatedState = {
        ...state,
        profileSettingsTemp: CloneObject(state.profileSettings)
    }
    updateStoreFromState(updatedState, storeAuthenticationKeys);
    return updatedState;
};

export default (state = updateStateFromStore(defaultAuthenticationState, storeAuthenticationKeys), action) => {
    switch (action.type) {
        case authenticationActionTypes.AUTH_START:
            return { ...state, error: null, loading: true };
        case authenticationActionTypes.AUTH_FAIL:
            return { ...state, error: action.error, loading: false }
        case authenticationActionTypes.LOGIN:
            return updateAuthenticationState(state, action.authData, true);
        case authenticationActionTypes.REFRESH:
            return refreshAuthenticationState(state, action.authData, true);
        case authenticationActionTypes.PROFILE:
            return updateProfileState(state, action.data, true);
        case authenticationActionTypes.LIST_COLUMN_VISIBILITY:
            return updateListColumnSettings(state, action.data, 'hidden');
        case authenticationActionTypes.LIST_COLUMN_VISIBILITY_AND_INDEX:
            return updateListColumnSettings(state, action.data, ['hidden', 'index']);
        case authenticationActionTypes.LIST_FILTER_VISIBILITY_AND_INDEX:
            return updateListFilterSettings(state, action.data);
        case authenticationActionTypes.LIST_COLUMN_WIDTH:
            return updateListColumnSettings(state, action.data, 'width');
        case authenticationActionTypes.LOGOUT:
            return removeAuthenticationState();
        case authenticationActionTypes.LIST_COLUMN_WIDTH_TEMP:
            return updateListColumnSettingsTemp(state, action.data, 'width');
        case authenticationActionTypes.LIST_COLUMN_WIDTH_FROM_TEMP:
            return updateListColumnSettingsFromTemp(state);
        case authenticationActionTypes.LIST_COLUMN_WIDTH_UPDATE_TEMP:
            return updateListColumnTempSettings(state);
        case authenticationActionTypes.SCREEN_SWAP:
            return updateScreenSwap(state, action.data);
        default:
            return state;
    }
};