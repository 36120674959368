import React, { useEffect, useState, useCallback, useRef } from 'react';
import { connect } from "react-redux";
//import { message } from 'antd';

import { openPopup, closePopup, closePopupAsync } from "../../../../../store/actions/popup/popup";
import axios from '../../../../axios/axios';
import InTransitConfig from '../../../../screens/LoadArea/OutboundLoad/inTransitConfig';

import { CloneObject } from '../../../../helper/common';
import errorHandler from '../../../../error/errorHandler';
import Loading from "../../../screen/loading";


import Filter from "../../../list/filter/filter";

import Toolbar from '../generalToolbar';
import { Data } from "./data";
import { useFiltering } from '../useFiltering';
import Stops from '../stops';

const assignedOrders = "AssignedInTransitOrders";
const groupBy = 'orderGroupIndex';
const groupType = 'batchType';
const groupTypeValue = 'Hard';
const subGroup = 'pickRegionName';


const InTransit = (props) => {
    const getOrderConfig = useCallback(() => CloneObject(InTransitConfig.Pages.Order), []);
    const getStopConfig = useCallback(() => CloneObject(InTransitConfig.Pages.Stop), []);
    const getLoadResource = useCallback(() => CloneObject(InTransitConfig), []);
    const getOrderForm = useCallback(() => CloneObject(InTransitConfig.Pages.OrderForm), []);
    const getAssignedOrderGroups = useCallback(() => CloneObject(InTransitConfig.Pages.AssignedOrderGroups), []);
    const getFilterConfig = useCallback(() => CloneObject(InTransitConfig.Pages.Filter), []);
    const getDispatchConfig = useCallback(() => CloneObject(InTransitConfig.Pages.Dispatch), []);

    const [stops, setStops] = useState(null);
    const [loads, setLoads] = useState(null);
    const [loading, setLoading] = useState(false);

    const lastStop = useRef(null);

    const load = useCallback(() => {
        setLoading(true);
        axios({
            url: getLoadResource().AreaResource,
            method: 'GET'
        })
            .then(response => {
                //assigned
                setLoads(response.data.outboundLoads.data);
                const d = new Data([...response.data.outboundAssignedOrders.data, ...response.data.inboundDirectAssignedAssignedOrders.data], response.data.outboundLoads.data);
                setStops(d.stops);
                setLoading(false);
            })
            .catch(error => {
                console.error(error);
                setLoading(false);
            });
    }, [getLoadResource]);

    const getAllStopOrders = lookingStops => lookingStops.reduce((r, a) => {
        a.orders.forEach(o => {
            r.push(o);
        });
        return r;
    }, []);

    const initStops = (data, loadsList) => {
        const d = new Data(data, loadsList);
        setStops(d.stops);
    };

    const [filterData, getFilteredSortedOrders, getFilteredStops] = useFiltering(
        stops,
        [],
        InTransitConfig.Pages.Filter,
        null,
        assignedOrders,
        subGroup,
        'dropAppointment'
    );

    useEffect(() => {
        load();
    }, [load]);

    const updateInOut = result => {
        const newStops = [...stops];
        const stop = newStops.find(s => s.id === result.id);
        if (stop) {
            stop.in = result.dropInDate;
            stop.out = result.dropOutDate;
            const newOrders = [...stop.orders];
            newOrders.forEach(o => {
                o.outboundIn = stop.in;
                o.outboundOut = stop.out;
            });
            stop.orders = newOrders;
            setStops(newStops);
        }
    };

    const stopsComponent = <container horizontal="" s_space="" key={'c_1'}>
        <view light=''>
            <Stops
                colgroup
                grouping
                groupBy={groupBy}
                groupType={groupType}
                groupTypeValue={groupTypeValue}
                getFilteredSortedOrders={getFilteredSortedOrders}
                getOrderForm={getOrderForm}
                getAssignedOrderGroups={getAssignedOrderGroups}
                apiResource={'Order/assignoutboundloadorderitem'}
                openPopup={props.openPopup}
                stops={stops}
                setStops={setStops}
                closePopupAsync={props.closePopupAsync}
                closePopup={props.closePopup}
                getOrderConfig={getOrderConfig}
                resource={assignedOrders}
                stopResource={InTransitConfig.Pages.Stop.Resource}
                getFilteredStops={getFilteredStops}
                lastStop={lastStop}
                getStopConfig={getStopConfig}
                loads={loads}
                getAllStopOrders={getAllStopOrders}
                setLoads={setLoads}
                initStops={initStops}
                getLoadResource={getLoadResource}
                setLoading={setLoading}
                load={load}
                getDispatchConfig={getDispatchConfig}
                updateInOut={updateInOut}
            />
        </view>
    </container>;

    return (
        <>
            <Toolbar
                assignedOrders={assignedOrders}
                getOrderConfig={getOrderConfig}
                getLoadResource={getLoadResource}
                load={load}
            />
            {stops && !loading ? 
                <view vertical="">
                    {InTransitConfig.Pages.Filter ? (
                        <Filter filtersConfig={getFilterConfig()} data={filterData} />
                    ) : null}
                    <view>
                        {stopsComponent}
                    </view>
                </view>: <Loading />}
        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        openPopup: (data) => dispatch(openPopup(data)),
        closePopup: (key) => dispatch(closePopup(key)),
        closePopupAsync: (key) => dispatch(closePopupAsync(key)),
        openAlert: (key) => dispatch(openPopup(key))
    };
};

export default connect(null, mapDispatchToProps)(errorHandler(InTransit, axios));