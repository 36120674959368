export const setCustomerDashboard = (state, data) => ({
  ...state,
  hasError: data.hasError,
  message: data.message,
  loading: false,
  ...data,
});

export const setInLoading = state => ({
  ...state,
  ...{
    loading: true,
  },
});

export const resetError = state => ({
  ...state,
  ...{
    hasError: false,
    message: '',
  },
});