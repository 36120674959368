import React from 'react';
import Modal from 'react-modal';
// import './index.css';
// import '../CustomerOrderNewOrders/index.css';

const SpaceWarningModal = ({ setIsOpen, warningSpaceModalIsOpen, setWarningSpaceModalIsOpen }) => {
  
  const closeModal = () => {
    setWarningSpaceModalIsOpen(false);
  };

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      minWidth: '400px',
      minHeight: '200px',      
      borderRadius: '8px',
      padding: '6px 12px',
    },
  };

  return (
    <Modal
        isOpen={warningSpaceModalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        ariaHideApp={false}
        >
        <div className='space_modal__container'>
          <div className='warning-space_modal_text'>Total amount of Space can’t exceed 30 when grouping as a “Hard Batch”</div>
          <div className='warning-space_modal_text'>Please choose either a soft batch or no batch.</div>
          <div className='btn-center space-modal-button warning-space_modal_text' onClick={closeModal}>Close</div>
        </div>  
    </Modal>
  );
};

export default SpaceWarningModal;