import React from 'react';
import { connect } from "react-redux";

const RegionContentCell = (
    {
        state,
        cellData,
        itemSize,
        onClickRow,
        resource
    }) => {
    const loadArea = state[resource];
    const width = loadArea.regionHeaders.find(h => h.key === cellData.key).width(loadArea.headers);

    const dataDisplay = () => {
        return <span style={{
            fontWeight: 'bold',
            paddingLeft: '30px'
        }}>{cellData.value}</span>;
    };

    let cellStyle = {
        maxWidth: `${width}px`,
        width: `${width}px`,
        minWidth: `${width}px`,
        height: `${itemSize}px`,
        position: 'sticky',
        left: '20px',
        backgroundColor: '#65A7A5',
        color: '#FFFFFF'
    };

    return (
        <div onClick={() => onClickRow()}
            className='table-body-cell'
            style={cellStyle}>
            {dataDisplay()}
        </div>
    );
};
export default connect(state => ({
    state: state
}))(RegionContentCell);