import React from 'react';
import Spreadsheet from '../../../custom/spreadsheet/spreadsheet';

const OutboundLoadBuld = () =>
(<div style={{
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch'
}}>
    <Spreadsheet selectable resource='outboundload' />
</div>);


export default OutboundLoadBuld;