import React, { useEffect, useRef, useState, useCallback } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import ScrollContainer from 'react-indiana-drag-scroll';

const Scroll = props => {
    const [showButton, setShowButton] = useState(false);
    const [showLeftButton, setShowLeftButton] = useState(true);
    const [showRightButton, setShowRightButton] = useState(true);
    const allowScroll = useRef(true);
    const isMounted = useRef(true);

    const container = useRef(null);

    const resizeObserver = useRef(null);
    const {
        scrollSpeed,
        scrollSpeedOnWeel,
        isScrollOnWeel,
        leftButtonClass,
        rightButtonClass,
        useScrollButtons,
        scrollToElementClass
    } = props;

    const scrollToElement = useCallback(() => {
        if (!isMounted || !isMounted.current || !container) return;
        const domElement = ReactDOM.findDOMNode(container.current);
        if (domElement) {
            const element = domElement.getElementsByClassName(scrollToElementClass)[0];
            element && element.scrollIntoView();
            setButtons();
        }
    }, [container, scrollToElementClass]);

    const checkShowButton = useCallback(() => {
        if (!isMounted || !isMounted.current || !container) return;
        if (useScrollButtons) {
            const domElement = ReactDOM.findDOMNode(container.current);
            if (domElement) {
                setShowButton(domElement.offsetWidth < domElement.scrollWidth);
                setButtons();
                scrollToElementClass && scrollToElement();
            }
        }
    }, [useScrollButtons, container, scrollToElement, scrollToElementClass]);

    const scrollLeft = useCallback((speed) => {
        if (!isMounted || !isMounted.current || !container) return;
        ReactDOM.findDOMNode(container.current).scrollLeft -= (speed || scrollSpeed);
        setButtons()
    }, [container, scrollSpeed]);

    const scrollRight = useCallback((speed) => {
        if (!isMounted || !isMounted.current || !container) return;
        ReactDOM.findDOMNode(container.current).scrollLeft += (speed || scrollSpeed);
        setButtons()
    }, [container, scrollSpeed]);

    const scrollOnWeel = useCallback(() => {
        if (!isMounted || !isMounted.current || !container) return;
        const domElement = ReactDOM.findDOMNode(container.current);
        const wheelHandler = (e) => {
            e.deltaY < 0 ? scrollLeft(scrollSpeedOnWeel) : scrollRight(scrollSpeedOnWeel);
        }
        let supportsPassive = false;
        try {
            const opts = Object.defineProperty({}, 'passive', {
                get: () => {
                    supportsPassive = true;
                    return supportsPassive;
                }
            });
            window.addEventListener("testPassive", null, opts);
            window.removeEventListener("testPassive", null, opts);
        } catch (e) { }
        domElement.addEventListener('wheel', wheelHandler, supportsPassive ? { passive: true } : false);
        return () => {
            domElement.removeEventListener('wheel', wheelHandler, supportsPassive ? { passive: true } : false);
        };
    }, [container, scrollLeft, scrollRight, scrollSpeedOnWeel]);

    

    useEffect(() => {
        if (useScrollButtons && isMounted.current) {
            const domElement = ReactDOM.findDOMNode(container.current);
            if (resizeObserver && resizeObserver.curent) {
                resizeObserver.curent.unobserve(domElement);
            }
            
            if (checkShowButton) {
                const resizeObserverOrPolyfill = ResizeObserver;
                resizeObserver.current = new resizeObserverOrPolyfill(checkShowButton);
                if (domElement && resizeObserver && resizeObserver.current) {
                    resizeObserver.current.observe(domElement);
                }
            }

            let cleanScrollOnWell;
            isScrollOnWeel && (cleanScrollOnWell = scrollOnWeel());
            return () => {
                resizeObserver.current.unobserve(domElement);
                isScrollOnWeel && cleanScrollOnWell();
                container.current = null;
                allowScroll.current = null;
                isMounted.current = false;
                resizeObserver.current = null;
            };
        }
    }, [checkShowButton, isScrollOnWeel, scrollOnWeel, useScrollButtons, props.showButtons]);

    const setButtons = () => {
        if (!isMounted || !isMounted.current || !container) return;
        const domElement = ReactDOM.findDOMNode(container.current);
        const _scrollLeft = domElement.scrollLeft;
        setShowLeftButton(domElement.scrollLeft > 0);
        allowScroll.current = false;
        domElement.scrollLeft += 1;
        if (_scrollLeft === domElement.scrollLeft) {
            setShowRightButton(false)
        } else {
            domElement.scrollLeft -= 1;
            setShowRightButton(true)
        }
    };

    useEffect(() => {
        setButtons();
    }, [props.parentMenu]);

    const scrollHandler = (scroll) => {
        if (!isMounted || !isMounted.current || !container) return;
        let interval;
        const mouseUpHandler = () => {
            document.removeEventListener('mouseup', mouseUpHandler);
            clearInterval(interval)
        };
        document.addEventListener('mouseup', mouseUpHandler);
        interval = setInterval(() => scroll())
    };

    const onScroll = () => {
        if (!isMounted || !isMounted.current || !container) return;
        if (isMounted.current && allowScroll.current) {
            setButtons();
        }
    }

    return (
        <React.Fragment>
            {showButton &&
                <div
                    className={leftButtonClass + (showLeftButton ? "" : " hidden")}
                    onMouseDown={() => scrollHandler(scrollLeft)}>
                    <icon>left</icon>
                </div>
            }
            {isMounted.current && <ScrollContainer 
                onEndScroll={() => { if (isMounted.current) allowScroll.current = false; }}
                onStartScroll={() => { if (isMounted.current) allowScroll.current = true; }}
                onScroll={onScroll}
                ref={container}
                {...props}>
                {props.children}
            </ScrollContainer>}
            {showButton &&
                <div
                    className={rightButtonClass + (showRightButton ? "" : " hidden")}
                    onMouseDown={() => scrollHandler(scrollRight)}>
                    <icon>right</icon>
                </div>
            }
        </React.Fragment>
    )
}

Scroll.defaultProps = {
    vertical: false,
    useScrollButtons: true,
    isScrollOnWeel: true,
    leftButtonClass: 'button left micro white btn-scroll',
    rightButtonClass: 'button right micro white btn-scroll',
    scrollSpeed: 2,
    scrollSpeedOnWeel: 20,
    scrollToElementClass: 'selected'
}

Scroll.propTypes = {
    vertical: PropTypes.bool,
    useScrollButtons: PropTypes.bool,
    isScrollOnWeel: PropTypes.bool,
    leftButtonClass: PropTypes.string,
    rightButtonClass: PropTypes.string,
    scrollSpeed: PropTypes.number,
    scrollSpeedOnWeel: PropTypes.number,
    scrollToElementClass: PropTypes.string
}

export default Scroll;