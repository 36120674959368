import React from 'react';
import { Draggable } from 'react-beautiful-dnd';

import SheetCell from './sheetCell';
import Sheet2Cell from './sheet2Cell';
import ColGroup from './colGroup';
import { GenerateColumnAction } from '../../../helper/list';
import { Action } from '../../../config/action.config';

import { InvertColor } from '../../../helper/common';

const SheetRow = props => {
    const minRowsCanDelete = props.minRowsCanDelete || 0;
    const groupBy = props.groupBy;
    const groupType = props.groupType;
    const groupTypeValue = props.groupTypeValue;
    let colSpan = 1;

    colSpan += props.subGroup ? Object.keys(props.columns).length : 0;
    props.action && ++colSpan;
    props.selectedRows && ++colSpan;
    props.draggable && ++colSpan;

    const tRow = (rowData, isDragging) => {
        const color = (props.parentData ? props.parentData.color : rowData.color) || 'rgba(255, 255, 255, 1)';

        let actions = props.actions
        ?.filter(item => {
            let i = item;
    
            if(typeof(i) === 'function') {
                i = item(rowData);
            }
    
            return i;
        }).map(item => {
            let i = item;
    
            if(typeof(i) === 'function') {
                i = item(rowData);
            }
    
            return i;
        });

        return <>
            {props.addIndex &&
                <td style={props.coloring ? {
                    backgroundColor: color,
                    color: InvertColor(color, true)
                } : null}>
                    <text>{props.index + 1}</text>
                </td>
            }
            {props.selectable &&
                <td>
                    <wrap>
                        {isDragging && props.selectedRows?.length > 1
                            ? <group compact="">
                                <badge accent="" filled="">{props.selectedRows.length}</badge>
                            </group>
                            : <label className='checkbox' classic="">
                                <input
                                    type='checkbox'
                                    onChange={(e) => props.handleCheck(props.data, e)}
                                    checked={props.selectedRows.find(i => i.id === props.data.id) ? true : false}
                                />
                                <box><check></check></box>
                            </label>
                        }
                    </wrap>
                </td>
            }
            {Object.keys(props.columns).map((c, i) => {
                if (props.resizeMode) {
                    return <td key={c + i}><wrap>...</wrap></td>;
                }

                const column = props.columns[c];
                let data = column.DataFieldName ? rowData[column.DataFieldName] : rowData[c];
                data = column.TrueValue ? column.TrueValue(data) : data;
                return column && (column.IsDouble ?
                    <Sheet2Cell key={c + i}
                    field={c}
                        dataField={column.DataFieldName || c}
                        field2Name={column.Field2Name}
                        field3Name={column.Field3Name}
                    column={column}
                    data={data}
                    parentData={props.parentData}
                    keyFieldName={props.keyFieldName}
                    keyField={props.keyField}
                    parentKeyField={props.parentKeyField}
                    isChild={props.isChild}
                    isParent={!props.isChild}
                    object={rowData}
                    onChange={props.onChange}
                    messageDispatch={props.messageDispatch}
                    messages={props.messages}
                    messageIdentityPrefix={props.messageIdentityPrefix}
                    resizeMode={props.resizeMode}
                    coloring={props.coloring}
                    color={color}
                    onActionRecord={props.onActionRecord}
                    />
                    :
                    <SheetCell key={c + i}
                    field={c}
                    dataField={column.DataFieldName || c}
                    column={column}
                    data={data}
                    parentData={props.parentData}
                    keyFieldName={props.keyFieldName}
                    keyField={props.keyField}
                    parentKeyField={props.parentKeyField}
                    isChild={props.isChild}
                    isParent={!props.isChild}
                    object={rowData}
                    onChange={props.onChange}
                    messageDispatch={props.messageDispatch}
                    messages={props.messages}
                    messageIdentityPrefix={props.messageIdentityPrefix}
                    resizeMode={props.resizeMode}
                    coloring={props.coloring}
                    color={color}
                    onActionRecord={props.onActionRecord}
                />)
            })}
            {props.resizable && <td></td>}
            {props.action && <td>
                {GenerateColumnAction(
                    { record: props.columns, id: props.index, recordId: props.keyField },
                    props.object.reduce((a, b) => a + (b.deleted ? 0 : 1), 0) > minRowsCanDelete ? actions || [Action.Edit, Action.Delete] :
                        [Action.Edit], null, props.onActionRecord)}
            </td>}
        </>
    };

    return (props.draggable
        ? (
            <Draggable
                draggableId={props.keyField + ''}
                key={props.keyField}
                index={props.index}
                isDragDisabled={props.disableDrag}
                isClone={true}
            >
                {(provided, snapshot) => (<>{props.subGroup && props.subGroupChanged &&
                    <tr>
                        <td colSpan={Object.keys(props.columns).length + colSpan} group=''>
                            <text>{props.data[props.subGroup]}</text>
                        </td>
                        {/* Need to check colSpan count */}
                    </tr>}
                    <tr
                        key={props.screen_id}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        dragging={snapshot.isDragging ? '' : null}
                        group_start={props.groupChanged ? '' : null}
                        select={props.selectedRows && props.selectedRows.find(i => i.id === props.data.id) ? '' : null}
                    >
                        {snapshot.isDragging
                            ? <td>
                                <table width={props.tableWidth}>
                                    <ColGroup
                                        columns={props.columns}
                                        action={props.action}
                                        draggable={props.draggable}
                                        selectable={props.selectable}
                                        resource={props.resource}
                                        resizable={props.resizable}
                                        tableWidth={props.tableWidth}
                                        addIndex={props.addIndex}
                                    />
                                    <tbody>
                                        {true && props.selectedRows?.length // 'true' can be changed as Configurable to show Table or Count
                                            ? props.selectedRows.map(data =>
                                                <tr key={data.id}>
                                                    <td key={`td_${data.id}`} {...provided.dragHandleProps}>
                                                        <wrap>
                                                            <icon>sort</icon>
                                                        </wrap>
                                                    </td>
                                                    {tRow(data)}
                                                </tr>
                                            )
                                            : (props.grouping && groupBy && props.data[groupType] === groupTypeValue)
                                                ? props.object
                                                    .filter(data => data[groupBy] === props.data[groupBy])
                                                    .map(data =>
                                                        <tr key={data.id}>
                                                            <td key={`td_${data.id}`} {...provided.dragHandleProps}>
                                                                <wrap>
                                                                    <icon>sort</icon>
                                                                </wrap>
                                                            </td>
                                                            {tRow(data)}
                                                        </tr>
                                                    )
                                                : <tr>
                                                    <td {...provided.dragHandleProps}>
                                                        <wrap>
                                                            <icon>sort</icon>
                                                        </wrap>
                                                    </td>
                                                    {tRow(props.data, snapshot.isDragging)}
                                                </tr>
                                        }
                                    </tbody>
                                </table>
                            </td>
                            : <>
                                <td {...provided.dragHandleProps}>
                                    <wrap>
                                        <icon>sort</icon>
                                    </wrap>
                                </td>
                                {tRow(props.data, snapshot.isDragging)}
                            </>
                        }
                    </tr>
                </>)}
            </Draggable>
        )
        : (<>{props.subGroup && props.subGroupChanged &&
            <tr>
                <td colSpan={Object.keys(props.columns).length + colSpan} group=''>
                    <text>{props.data[props.subGroup]}</text>
                </td>
                {/* Need to check colSpan count */}
            </tr>}
            <tr key={props.screen_id} group_start={props.groupChanged ? '' : null}>
                {tRow(props.data)}
            </tr>
        </>)
    );
};

export default SheetRow;