import {
    ElementTypes,
    GenerateToolBars
} from '../../../config';

const StopNotes = {
    Title: 'Stop Notes',
    Resource: 'Load/stopnotes',
    Key: 'id',
    IsRest: true,
    Form: {
        Title: 'Stop Notes',
        SectionGroups: [
            {
                Sections: [
                    {
                        classes: 'wide',
                        Fields: {
                            inboundStopNotes: {
                                fieldClasses: ['note'],
                                Title: 'Notes',
                                Field: ElementTypes.Comment()
                            },
                        }
                    }
                ]
            }
        ],
        ToolBars: GenerateToolBars(['Save', 'SaveClose','Close'])
    }
};

export default StopNotes;