import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { DatePicker, Tooltip } from 'antd';
import { enabled } from 'store/dist/store.legacy';
import { Defines } from '../../../../project/Defines';
import { setItemData, setItemDisplayState } from '../../../../store/actions/trafficControl/trafficControl';
import CustomModal from "../CustomerOrderInProgress/modal";
import EditWarningModal from '../CustomerOrderNewOrders/modalEditWarning';

const Cell = ({
  state,
  cellData,
  itemSize,
  rowState,
  resource,
  startOnScroll,
  setItemData,
  setItemDataUpcoming,
  setItemDisplayState,
}) => {
  const rejectedOrders = state[resource];
  const redonly = 1;
  const editable = 2;
  const header = rejectedOrders.headers.find(h => h.key === cellData.key);
  // const headerIndex = rejectedOrders.headers.findIndex(h => h.key === cellData.key);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [editWarningModaIsOpen, setEditWarningModaIsOpen] = useState(false);

  const width = header.width;
//   const offsetWidth = rejectedOrders.headers.reduce((r, h, index) => {
//     if (h.visible && index < (headerIndex - 3)) {
//         r += h.width;
//     }
//     return r;
// }, 0);

const lastSelectId = rejectedOrders.lastSelected?.id;
const lastSelectHeaderKey = rejectedOrders.lastSelected?.headerKey;
const lastSelectenable = rejectedOrders.lastSelected?.enable;
const rowStateId = rowState.orderId;
const headerKey = header.key;
const [displayState, setDisplayState] = useState(redonly);
const [tempData, setTempData] = useState(null);

useEffect(() => {
  if (lastSelectId === rowStateId && lastSelectHeaderKey === headerKey && lastSelectenable) {
      setDisplayState(enabled);
  }
}, [lastSelectId, lastSelectHeaderKey, lastSelectenable, rowStateId, headerKey]);

const cellStyle = cellData.cellStyle;
let data = (cellData.valueText && cellData.fieldType === 'text') ? cellData.valueText : cellData.value;
  useEffect(() => {
    setTempData(data);
  }, [data, cellData.priorityValue, cellData.delayedValue, rowState.loadStatus]);

  const inputReference = useRef(null);

  const dataCompare = (newData, data) => {
    switch (cellData.fieldType) {
      case 'date':
      case 'dateTime':
        if (newData instanceof Date && data instanceof Date) {
          return newData.getTime() === data.getTime();
        }
        return newData === data;
      default:
        return newData === data;
    }
  };

  const setData = (value, subType) => {
    onSetBlur();
    let newValue = value;
    if (value !== '') {
      switch (cellData.fieldType) {
        case 'date':
          newValue = new Date(value);
          break;
        case 'dateTime':
          newValue = new Date(value);
          break;
        case 'text':
          newValue = value;
          break;
        case 'textInput':
          newValue = value;
          break;
        case 'select':
          newValue = value;
          break;
        case 'checkbox':
          newValue = value;
          break;
        default:
          newValue = value;
          break;
      }
    }
    const valueForCompare = subType === 'priority' ? cellData.priorityValue : subType === 'delayed' ? cellData.delayedValue : tempData;
    if (!dataCompare(newValue, valueForCompare)) {
      setTimeout(() => {
          if (resource === 'traffic') {
            setItemData(resource, newValue, rowState, header, subType);
          }
          if (resource === 'trafficUpcoming') {
            setItemDataUpcoming(resource, newValue, rowState, header, subType);
          }
      }, 100);
      setTimeout(() => {
        setTempData(newValue);
      }, 100);
    }
  };

  const handleEdit = () => {
    setIsOpen(true);
  };

  useEffect(() => {
    if (inputReference && inputReference.current) {
      inputReference.current.focus();
    }
  }, [displayState]);

  const getData = () => {
    if (tempData !== '' && tempData !== null) {
      switch (cellData.fieldType) {
        case 'date':
          return moment(tempData).format(Defines.Format.PrintDate);
        case 'dateTime':
          if (cellData.key === 'dueDate') {
            const indicator = rowState?.orderData?.orderIndicator;
            if (rowState?.orderData?.isHardDate) {
              return (
                <Tooltip title='Hard Date'>
                  <span style={{ color: 'rgb(96, 0, 128)' }}>{tempData ? moment(tempData).format(Defines.Format.PrintDateLong) : ''}</span>
                </Tooltip>
              )
            } else if (indicator === 2) {
              return (
                <Tooltip title='Data Change'>
                  <span style={{ color: '#EF2112' }}>{tempData ? moment(tempData).format(Defines.Format.PrintDateLong) : ''}</span>
                </Tooltip>
              )
            } else {
              return (
                <Tooltip title='Standard'>
                  <span style={{ color: '#0000FF' }}>{tempData ? moment(tempData).format(Defines.Format.PrintDateLong) : ''}</span>
                </Tooltip>
              )
            }
          } else {
            return <>{tempData ? moment(tempData).format(Defines.Format.PrintDateLong) : ''}</>;
          }
        case 'actions':
          return (
            <div className='edit_icons'>
              <div onClick={handleEdit}>
                <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M11.1965 6.02L12.1165 6.94L3.05647 16H2.13647V15.08L11.1965 6.02ZM14.7965 0C14.5465 0 14.2865 0.1 14.0965 0.29L12.2665 2.12L16.0165 5.87L17.8465 4.04C18.2365 3.65 18.2365 3.02 17.8465 2.63L15.5065 0.29C15.3065 0.09 15.0565 0 14.7965 0ZM11.1965 3.19L0.136475 14.25V18H3.88647L14.9465 6.94L11.1965 3.19Z" fill="#3C8680"/>
                </svg>
              </div>
            </div>
          );
        default:
          return tempData;
      }
    }
    return tempData;
  };


  const getInput = () => {
    switch (cellData.fieldType) {
      case 'dateTime':
        return (
          <DatePicker
            open={true}
            ref={inputReference}
            onBlur={value => {
              setTimeout(() => {
                if (inputReference.current) {
                  setDisplayState(redonly);
                }
              }, 0);
              setData(value.currentTarget.value);
            }}
            onChange={value => {
              if (inputReference.current) {
                setDisplayState(redonly);
              }
              setData(value === null ? '' : value._d);
            }}
            suffixIcon={null}
            showTime
            className='spreadsheet-picker'
            value={tempData ? moment(tempData, Defines.Format.PrintDateTime) : ''}
            format={Defines.Format.PrintDateTime}
            onKeyDown={e => {
              if (e.key === 'Escape') {
                if (inputReference.current) {
                  setDisplayState(redonly);
                }
              }
            }}
            showToday={false}
          />
        );
      case 'date':
        return (
          <DatePicker
            open={true}
            ref={inputReference}
            onBlur={value => {
              if (inputReference.current) {
                setDisplayState(redonly);
              }
              setData(value.currentTarget.value);
            }}
            onChange={value => {
              if (inputReference.current) {
                setDisplayState(redonly);
              }
              setData(value === null ? '' : value._d);
            }}
            suffixIcon={null}
            className='spreadsheet-picker'
            value={tempData ? moment(tempData, Defines.Format.PrintDate) : ''}
            format={Defines.Format.PrintDate}
            onKeyDown={e => {
              if (e.key === 'Escape') {
                if (inputReference.current) {
                  setDisplayState(redonly);
                }
              }
            }}
            showToday={false}
          />
        );
      case 'textInput':
        return (
          <input
            defaultValue={tempData ? tempData : ''}
            ref={inputReference}
            onBlur={value => {
              setData(value.currentTarget.value);
              if (inputReference.current) {
                setTimeout(() => {
                  setDisplayState(redonly);
                }, 100);
              }
            }}
            format={Defines.Format.PrintDate}
            style={{ paddingLeft: '4px' }}
          />
        );
      default:
        return (
          <input
            style={{ backgroundColor: 'yellow', boxSizing: 'inherit', width: '100%' }}
            value={getData()}
            ref={inputReference}
            onChange={event => setData(event.target.value)}
            onBlur={() => {
              if (inputReference.current) {
                setDisplayState(redonly);
              }
            }}
          />
        );
    }
  };

  const dataDisplay = () => displayState === redonly ? getData() : getInput();

  const cellDefaultStyle = {
      maxWidth: `${width}px`,
      width: `${width}px`,
      minWidth: `${width}px`,
      height: `${itemSize}px`,
      color: cellData.color,
      padding: '4px 2px',
  };

  const onSetFocus = () => {
    // startOnScroll && startOnScroll(offsetWidth);
      if (cellData.editable) {
          setDisplayState(editable);
      }
      setItemDisplayState(resource, rowState, header, cellData.editable);
  };

  const onSetBlur = () => {
      setItemDisplayState(resource, rowState, header, false);
  };

  const editableFunction = (cellData) => cellData.editable ? setDisplayState(editable) : null;

  return (
    <>
      <div  onFocus={() => onSetFocus()}
            onClick={() => { editableFunction(cellData)} }
            className='table-body-cell table-borders customer-order-row'
            style={{ ...cellDefaultStyle, ...cellStyle }}
            tabIndex={displayState === redonly ? 0 : -1}>
          {dataDisplay()}
      </div>
      {modalIsOpen && 
        <CustomModal
            modalIsOpen={modalIsOpen}
            setIsOpen={setIsOpen}
            editMode
            data={rowState.orderData}
            setEditWarningModaIsOpen={setEditWarningModaIsOpen}
        />
      }
      {editWarningModaIsOpen &&
        <EditWarningModal
            editWarningModalIsOpen={editWarningModaIsOpen}
            setEditWarningModaIsOpen={setEditWarningModaIsOpen}
            setIsOpen={setIsOpen}
        />
      }
      </>
    );
  };

export default connect(state => ({
  state: state
  }), dispatch => ({
  setItemData: (resource, value, rowState, header, subType) => dispatch(setItemData(resource, value, rowState, header, subType)),
  setItemDisplayState: (resource, rowState, header, enable) => dispatch(setItemDisplayState(resource, rowState, header, enable)),
}))(Cell);
