import React, { useCallback, useRef, useEffect } from 'react';
import { connect } from "react-redux";
import Cell from './cell';
import LeftActionCall from './leftActionCall';
import { reactOnAssignedItem } from '../../../../store/actions/callCheck/callCheck';

const Row = (
    {
        data,
        style,
        itemSize,
        reactOnAssignedItem,
        state,
        listRef,
        startOnScroll,
        resource,
        uploadModalIsOpen,
        setUploadModalIsOpen,
        setUploadRowState,
        loadsAssignedOrders,
        selectedOrder,
        }) => {
    const rowRef = useRef({});
    const loadArea = state[resource];
    const isSubOrder = loadsAssignedOrders.filter(order => order.rowState.isSub && 
                        order.rowState.id === data.rowState.loadId &&
                        data.rowState.type === 'order')[0];
    const color =   data.rowState.reacted ? 'lightgray' :
                    isSubOrder ? '#b4d5bc' :
                    data.rowState.type === 'load' ? '#65A7A5' :
                    data.rowState.type === 'stop' ? '#D9EBEB' :
                    'white';

    const subOrdersOrderIDs = loadArea?.subOrders?.map(sub => sub.orderId);
    const hasSubOrder = data?.rowState.type === 'order' && subOrdersOrderIDs?.includes(data?.rowState.orderData?.id) && !isSubOrder;

    let searched_PONumber = [];
    let searched_SONumber = [];
    let searched_INLoadNumber = [];
    let searched_OUTLoadNumber = [];

    selectedOrder && selectedOrder.forEach(order => {
        if (order.rowState.orderData.field === 'poNumber') {
            if (order.rowState?.orderObjectId === data.rowState?.orderObjectId) {
                searched_PONumber.push(order);
            }
        } else if (order.rowState.orderData.field === 'soNumber') {
            if (order.rowState?.orderObjectId === data.rowState?.orderObjectId) {
                searched_SONumber.push(order);
            }
        } else if (order.rowState.orderData.field === 'inboundLoadNumber') {
            if (order.rowState?.orderObjectId === data.rowState?.orderObjectId) {
                searched_INLoadNumber.push(order);
            }
        } else if (order.rowState.orderData.field === 'outboundLoadNumber') {
            if (order.rowState?.orderObjectId === data.rowState?.orderObjectId) {
                searched_OUTLoadNumber.push(order);
            }
        }
    })

    useEffect(() => {
        if (rowRef.current) {
            listRef.current.resetAfterIndex(0);
        }
    }, [rowRef, listRef]);

    const onClickRow = useCallback(() => {
        reactOnAssignedItem(resource, data.rowState.id, data.rowState.type);
    }, [reactOnAssignedItem, data, resource]);

    style.width = 'max-content';
    const rowStyle = { ...style, ...{ height: `${itemSize}px`, backgroundColor: color } };
    
    const cellContent = (i, ind) => {
        const header = loadArea.headers.find(h => h.key === i.key);

        if (header.leftAction) {
            return (<LeftActionCall
                innerRef={rowRef}
                key={ind}
                type={data.rowState.type}
                cellData={i}
                reacted={data.rowState.reacted}
                itemSize={itemSize}
                onClickRow={onClickRow}
                bgColor={color}
                resource={resource}
            />);
        }

        return (<Cell
            key={ind}
            cellData={i}
            reacted={data.rowState.reacted}
            type={data.rowState.type}
            itemSize={itemSize}
            onClickRow={onClickRow}
            startOnScroll={startOnScroll}
            rowState={data.rowState}
            resource={resource}
            uploadModalIsOpen={uploadModalIsOpen}
            setUploadModalIsOpen={setUploadModalIsOpen}
            setUploadRowState={setUploadRowState}
            hasSubOrder={hasSubOrder}
            searched_PONumber={searched_PONumber}
            searched_SONumber={searched_SONumber}
            searched_INLoadNumber={searched_INLoadNumber}
            searched_OUTLoadNumber={searched_OUTLoadNumber}
        />);
    };

    return data ? 
        <div className='resp-table-row' style={{ ...rowStyle, display:'flex' }}>
            {data.row.map(cellContent)}
        </div> : null;
    };

export default connect(state => ({
    state: state
}), dispatch => ({
    reactOnAssignedItem: (resource, id, type) => dispatch(reactOnAssignedItem(resource, id, type)),
}))(Row);