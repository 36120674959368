import { useEffect } from "react";

export const useTidioScript = () => {
    useEffect(() => {
        const tidioScript = () => {
            let script = document.createElement("script");
            script.setAttribute("type","text/javascript");
            script.setAttribute("async","true");
            script.setAttribute("src", "//code.tidio.co/gclisdozkonukgfczzqeijus2gw0mdjg.js");
            script.setAttribute("id", "tidio-Id");
            document.body.appendChild(script);
        };

        tidioScript();

        const removeTidio = () => {
            let tidioDiv = document.getElementById('tidio-chat');
            let tidioScript = document.getElementById('tidio-Id');
            let tidioIframe = document.getElementById('tidio-chat-code');

            if (tidioDiv) {
                tidioScript.remove();
                tidioDiv.remove();
                tidioIframe.remove();
            }
        }
        return () => { removeTidio() }
    }, []);

    return null;
}
