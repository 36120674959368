import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { connect } from "react-redux";
import { Input, message } from 'antd';
import moment from 'moment';
import './index.css';
import { getNotes, setNotes, resetError } from '../../../../store/actions/notes/notes';
import { load } from '../../../../store/actions/callCheck/callCheck';
import Loading from "../../screen/loading";

const { TextArea } = Input;

const NotesModal = ({ 
      setNotesModalIsOpen,
      notesModalIsOpen,
      rowState,
      hasError,
      actionMessage,
      getNotes,
      setNotes,
      notes,
      loading,
      resetError,
      loadData,
      load,
    }) => {

  const loadId = rowState?.loadData?.id;
  const resource = rowState?.loadData.loadType === 1 ? "Load" : 'outboundLoad';

  const closeModal = () => {
    setNotesModalIsOpen(false);
    load('callCheck');
  };

  useEffect(() => {
    getNotes(resource, loadId);
  }, [getNotes, loadId, resource]);

  useEffect(() => {
    if (hasError) {
        message.error(actionMessage);
        resetError(resource);
    }
  }, [hasError, actionMessage, resetError, resource]);

  const [notesList, setNotesList] = useState([]);
  const [newNote, setNewNote] = useState('');

  useEffect(() => {
    setNotesList(notes);
  }, [setNotesList, notes]);

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      minWidth: '600px',
      minHeight: '300px',
      maxWidth: '600px',
      maxHeight: '400px',  
      borderRadius: '8px',
      padding: loading ? '100px 0' : '6px 0',
    },
  };

  const saveNote = () => {
    const updatedLoad = { ...loadData, notes: newNote};
    setNotes(resource, loadId, updatedLoad);
    setNewNote('');
  };

  return (
    <Modal
        isOpen={notesModalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        ariaHideApp={false}
        >
        <div className='comment_modal__container'>
          {loading ? <Loading /> : <>
            <div className='comment_modal__x-btn' onClick={closeModal}>X</div>
            <>
              <div className='comment_modal_text'>Notes</div>
              <table className='comment-modal__table'>
                <tr className='comment-modal__tr'>
                  <th className='comment-modal__th'>Date and Time</th>
                  <th className='comment-modal__th'>Created By</th>
                  <th className='comment-modal__th'>Note</th>
                </tr>
                {notesList?.map(note => (
                  <tr className='comment-modal__tr' key={note.id}>
                    <td className='comment-modal__td'>{moment.utc(note.createDate).utcOffset(4).format("MM/DD/YY HH:mm")}</td>
                    <td className='comment-modal__td'>{note.user}</td>
                    <td className='comment-modal__td'>{note.comment}</td>
                  </tr>
                ))}
              </table>
              <div className='add-comment__container'>
                <div className='add_new_comment'>Add new note</div>
                <TextArea
                  placeholder="Add new note"
                  autoSize={{ minRows: 2, maxRows: 6 }}
                  className='reject-reason__input'
                  status={"error"}
                  onChange={e => setNewNote(e.target.value)}
                  value={newNote}
                />
              </div>        
              <div className='commnet__add-btn__container'>
                <button disabled={!newNote} className={`${!newNote && 'disabled__add-btn'} comment-modal-button`} type="submit" onClick={saveNote}>Add</button>
              </div>
            </>
          </>}
        </div>  
    </Modal>
  );
};

const mapStateToProps = state => ({
  state: state,
  notes: state.notes.notes,
  loading: state.notes.loading,
  hasError: state.notes.hasError,
  actionMessage: state.notes.message,
  loadData: state.notes.loadData,
});

const mapDispatchToProps = (dispatch) => ({
  getNotes: (resource, id) => dispatch(getNotes(resource, id)),
  setNotes: (resource, loadId, note, attachment) => dispatch(setNotes(resource, loadId, note, attachment)),
  resetError: resource => dispatch(resetError(resource)),
  load: (resource) => dispatch(load(resource)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotesModal);
