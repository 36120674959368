import React from 'react';
// import { useCallback } from 'react';
import { connect } from "react-redux";
import { message } from 'antd';

import axios from "../../../axios/axios";
import { openPopup, closePopup, closePopupAsync } from "../../../../store/actions/popup/popup";
import { PopupTypes } from "../../custom/popup/popup";
import { CloneObject } from "../../../helper/common";
import AuthenticatedLink from '../../../elements/authenticatedLink';

import errorHandler from '../../../error/errorHandler';

import SendMail from '../../../screens/RateConfirmation/send';

function CarrierPackage(props) {
  const onSend = () => {
    const windowKey = 'Mail To';

    const config = CloneObject(SendMail);
    const title = `${config.Title}`;
    config.screen = config;
    config.screen.Form.DefaultId = props.data[props.keyFieldName];
    config.Resource = 'Carrier/sendpacket';

    const sectionData = {
      name: props.data.name,
      email: props.data.accountingEmail,
      ccAddresses: ''
    };

    props.openConfirm({
      windowKey: windowKey,
      fullScreen: false,
      title: title,
      type: PopupTypes.ConfigSection,
      bodyProps: { windowKey },
      data: sectionData,
      config: config.Form,
      onSave: (data, setUpdating, close) => {
        setUpdating && setUpdating(true);
        axios.put(config.Resource, data)
          .then(response => {
            if (response) {
              const result = response.data;
              if (result) {
                if (result.hasError) {
                  message.error(result.message);

                  return;
                }
                message.success(result.message);
                close && close();
              }
            }
          })
          .finally(() => {
              setUpdating && setUpdating(false);
              close && close();
          });
      }
    });
  };

  return (
    <div className="field_group vertical">
      <div className="form_fields">
        <div className="section_title">
          <text><b>Carrier Package</b></text>
        </div>
        <div className="form_separator"></div>
      </div>
      <div className="form_fields">
        <demospace compact="">
          <icon>file</icon>
          <group compact="">
            <badge filled="">PDF</badge>
          </group>
        </demospace>
        <div className="input_cont">
          <div className="data_help">Configure settings when and how You'll get notified for content changes</div>
        </div>
        <toolbar transparent="">
          <action>
            <div className="button accent">
              <AuthenticatedLink url={`Carrier/download/packet`} filename={'Carrier Package.pdf'}>
                Download
              </AuthenticatedLink>
            </div>
            <div className="button accent" onClick={e => { onSend() }}>Send</div>
          </action>
        </toolbar>
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    openPopup: (data) => dispatch(openPopup(data)),
    openConfirm: (data) => dispatch(openPopup(data)),
    closePopup: (key) => dispatch(closePopup(key)),
    closePopupAsync: (key) => dispatch(closePopupAsync(key))
  };
};

export default connect(null, mapDispatchToProps)(errorHandler(CarrierPackage, axios));