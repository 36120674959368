export const useStopNotes = setStops => {
    const updateInboundStopNotes = (newStops, result) => {
        const changedStop = newStops.find(s => s.id === result.id);
        if (changedStop) {
            changedStop.inboundStopNotes = result.inboundStopNotes;

            const newOrders = [...changedStop.orders];
            newOrders.forEach(o => {
                o.inboundStopNotes = result.inboundStopNotes;
            });
            changedStop.orders = newOrders;

            setStops(newStops);
        }
        else {
            console.error(`Stop not found, id - ${result.id}`)
        }
    };

    const updateOutboundStopNotes = (newStops, result) => {
        const changedStop = newStops.find(s => s.id === result.id);
        if (changedStop) {
            changedStop.outboundStopNotes = result.outboundStopNotes;

            const newOrders = [...changedStop.orders];
            newOrders.forEach(o => {
                o.outboundStopNotes = result.outboundStopNotes;
            });
            changedStop.orders = newOrders;

            setStops(newStops);
        }
        else {
            console.error(`Stop not found, id - ${result.id}`)
        }
    };
    return [updateInboundStopNotes, updateOutboundStopNotes];
};