import React from 'react';
import Modal from 'react-modal';
import { Button, Space, Typography } from 'antd';
import { Formik, Form, Field } from 'formik';
import { object, string } from 'yup';

import { CommonLabels } from '../../../../project/Defines';
import "./index.css";

const validationSchema = object().shape({
  sheetName: string().required('Required'),
});

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '400px',
    minHeight: '200px',
    borderRadius: '8px',
    padding: '6px 12px',
  },
  overlay: {
    backgroundColor: 'rgb(0 0 0 / 50%)'
  }
};

const GSheetModal = ({ isOpen, onCloseModal, onSubmit, isLoading }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onCloseModal}
      style={customStyles}
      ariaHideApp={false}
    >
      <Space direction="horizontal" align='start' className='gsheet-name_title'>
        <Typography.Title level={4}>Import From Google Sheet</Typography.Title>
        <div className="button mini" onClick={onCloseModal}>
          <icon>close</icon>
        </div>
      </Space>

      <Formik
        initialValues={{
          sheetName: '',
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => onSubmit(values.sheetName)}
      >
        {({ isValid, errors }) => {
          return (
            <Form >
              <Typography.Title level={5}>Google sheet name</Typography.Title>
              <Field
                name="sheetName"
                placeholder="Sheet name"
                type="string"
                className={`gsheet-name-input ${errors.sheetName ? 'gsheet-name-input__error' : ""}`}
              />
              <Space className='gsheet-name__action-btns'>
                <Button onClick={onCloseModal} >{CommonLabels.Buttons.Cancel}</Button>
                <Button type="primary" htmlType="submit" disabled={!isValid} loading={isLoading}>{CommonLabels.Buttons.Import}</Button>
              </Space>
            </Form>)
        }}
      </Formik>
    </Modal>
  );
};

export default GSheetModal;