import axios from 'axios';
import store from '../..';
import * as actionTypes from './customerRejectedOrdersActionTypes';

const buildUrl = (resource = 'CustomerOrder', endPoint) => `/api/${resource}/${endPoint}`;

const axiosConfig = () => ({
  headers: {
    Authorization: 'Bearer ' + store.getState().auth.userToken,
  },
});

export const getCustomerOrderRejected = (resource, endPoint) => dispatch => {
  dispatch({ type: actionTypes.getActionType(resource, actionTypes.CUSTOMER_ORDERS_REJECTED_LOADING) });
  axios
    .get(buildUrl(resource, endPoint), axiosConfig())
    .then(responce => dispatch({ type: actionTypes.getActionType(resource, actionTypes.GET_CUSTOMER_ORDERS_REJECTED), data: responce }))
    .catch(err => {
      console.error(err);
      console.log(err.response);
      dispatch({ type: actionTypes.getActionType(resource, actionTypes.ERROR) });
    });
};

export const sortBy = (resource, sortValue) => dispatch => {
  dispatch({
      type: actionTypes.getActionType(resource, actionTypes.SORT_ORDERS_REJECTED_ORDERS), data: sortValue
  });
}

export const setHeaderWidth = (resource, ind, width) => ({
  type: actionTypes.getActionType(resource, actionTypes.SET_HEADER_WIDTH_REJECTED_ORDERS),
  data: { ind, width },
});

export const setItemDisplayState = (resource, rowState, header, enable) => ({
  type: actionTypes.getActionType(resource, actionTypes.SET_DISPLAY_STATE),
  data: {
    rowState,
    header,
    enable,
  },
});

export const resetError = resource => ({ type: actionTypes.getActionType(resource, actionTypes.RESET_ERROR) });
