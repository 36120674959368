import {
    ElementTypes,
    ElementValidations,
    Action,
    ListDefaults,
    ColumnType,
    GenerateToolBars,
    DefaultPagination
} from '../../config';

const TrailerType = {
    Title: 'Trailer Type',
    Resource: 'TrailerType',
    Master: 'Name',
    Key: 'id',
    IsRest: true,
    List: {
        Defaults: ListDefaults,
        ShowRefreshButton: true,
        Search: '_all',
        Pagination: DefaultPagination,
        Columns: {
            name: {
                Title: 'Trailer Type Name',
                Type: ColumnType.Text,
                LinkAppPage: true,
                Sorted: 'ascend',//descend
                DataTitle: false,
                Width: 150
            }
        },
        Actions: {
            Width: 60,
            Items: [
                Action.Delete
            ]
        }
    },
    Form: {
        SectionGroups: [
            {
                Sections: [
                    {
                        Title: null,
                        Fields: {
                            name: {
                                Title: 'Trailer Type Name',
                                Field: ElementTypes.TextField(),
                                Validations: [ElementValidations.Required()]
                            }
                        }
                    }
                ]
            }
        ],
        ToolBars: GenerateToolBars()
    }
};

export default TrailerType;
