import { RATE_CONFIRMATION_BUILD as actionTypes } from '../../actions/rateConfirmation/rateConfirmationActionTypes';
import * as loadArea from './rateConfirmationReducer';

const defaultState = {
    loading: true,
    hasError: false,
    message: '',
    rateConfirmationData: null,
    rateConfirmationGUID: null,
    carrierRateConfirmationData: null,
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case actionTypes.GET_RATE_CONFIRMATION_LOADING:
            return loadArea.setInLoading(state);
        case actionTypes.GET_RATE_CONFIRMATION_DATA:
            return loadArea.getRateConfirmationData(state, action.data);
        case actionTypes.GET_RATE_CONFIRMATION_ERROR:
            return loadArea.setError(state, action.data);
        case actionTypes.RESET_ERROR:
            return loadArea.resetError(state);
        case actionTypes.GET_RATE_CONFIRMATION_PDF_URL:
            return loadArea.getRateConfirmationPDF_URL(state, action.data);
        case actionTypes.GET_CARRIER_RATE_CONFIRMATION_DATA:
            return loadArea.getCarrierRateConfirmationdata(state, action.data);            
        default:
            return state;
    }
};