import axios from 'axios';
import store from '../..';
import * as actionTypes from './factoringCompanyActionTypes';

const buildUrl = (resource, id) => id ? `/api/${resource}/${id}` : `/api/${resource}`;

const axiosConfig = () => ({
  headers: {
    Authorization: 'Bearer ' + store.getState().auth.userToken,
  },
});

export const getFactoringCompanyList = (resource, companyName, email) => dispatch => {
  const data = {
    params: {
      filterData: { companyName: companyName, email: email }
    },
    headers: axiosConfig().headers,
  };
  dispatch({ type: actionTypes.getActionType(resource, actionTypes.GET_FACTORING_COMPANY_LIST_LOADING) });
  axios
    .get(buildUrl(resource), data, axiosConfig())
    .then(response => dispatch({ type: actionTypes.getActionType(resource, actionTypes.GET_FACTORING_COMPANY_LIST), data: response }))
    .catch(err => {
      console.error(err);
      console.log(err.response);
      dispatch({ type: actionTypes.getActionType(resource, actionTypes.ERROR) });
    });
};

export const getFactoringCompanyDetails = (resource, id) => dispatch => {
  dispatch({ type: actionTypes.getActionType(resource, actionTypes.GET_FACTORING_COMPANY_DETAILS_LOADING) });
  axios
    .get(buildUrl(resource, id), axiosConfig())
    .then(response => dispatch({ type: actionTypes.getActionType(resource, actionTypes.GET_FACTORING_COMPANY_DETAILS), data: response }))
    .catch(err => {
      console.error(err);
      console.log(err.response);
      dispatch({ type: actionTypes.getActionType(resource, actionTypes.ERROR) });
    });
};

export const addFactoringCompany = (resource, factoringCompanyNewData, submitActinType) => dispatch => {
  dispatch({ type: actionTypes.getActionType(resource, actionTypes.ADD_FACTORING_COMPANY_LOADING) });
  axios
    .post(buildUrl(resource), factoringCompanyNewData, axiosConfig())
    .then(response => {
      if (response.data.hasError) {
        dispatch({ type: actionTypes.getActionType(resource, actionTypes.ERROR), data: response.data.message });
      } else {
        dispatch({ type: actionTypes.getActionType(resource, actionTypes.ADD_FACTORING_COMPANY), data: { response, submitActinType} })
      }
    })
    .catch(err => {
      console.error(err);
      console.log(err.response);
      dispatch({ type: actionTypes.getActionType(resource, actionTypes.ERROR) });
    });
};

export const updateFactoringCompany = (resource, factoringCompanyNewData, submitActinType) => dispatch => {
  dispatch({ type: actionTypes.getActionType(resource, actionTypes.UPDATE_FACTORING_COMPANY_LOADING) });
  axios
    .put(buildUrl(resource), factoringCompanyNewData, axiosConfig())
    .then(response => {
      if (response.data.hasError) {
        dispatch({ type: actionTypes.getActionType(resource, actionTypes.ERROR), data: response.data.message });
      } else {
        dispatch({ type: actionTypes.getActionType(resource, actionTypes.UPDATE_FACTORING_COMPANY), data: { response, submitActinType} })
      }
    })
    .catch(err => {
      console.error(err);
      console.log(err.response);
      dispatch({ type: actionTypes.getActionType(resource, actionTypes.ERROR) });
    });
};

export const deleteFactoringCompany = (resource, id) => dispatch => {
  const filterData  = {
    params: { id: id },
    headers: axiosConfig().headers,
}; 
  dispatch({ type: actionTypes.getActionType(resource, actionTypes.DELETE_FACTORING_COMPANY_LOADING) });
  axios
    .delete(buildUrl(resource), filterData, axiosConfig())
    .then(response => {
      if (response) {
        setTimeout(window.location.replace(`/otr/factoringCompany`), 1000)
      }
    })
    .catch(err => {
      console.error(err);
      console.log(err.response);
      dispatch({ type: actionTypes.getActionType(resource, actionTypes.ERROR) });
    });
};

export const emptyFactoringCompanyDetails = (resource) => dispatch => {
  dispatch({
      type: actionTypes.getActionType(resource, actionTypes.EMPTY_FACTORING_COMPANY_DETAILS),
  });
}

export const getStatesListByCountryId = (resource, id) => dispatch => {
  axios
    .get(buildUrl(resource, id), axiosConfig())
    .then(response => dispatch({ type: actionTypes.getActionType(resource, actionTypes.GET_STATES_LIST), data: response }))
    .catch(err => {
      console.error(err);
      console.log(err.response);
      dispatch({ type: actionTypes.getActionType(resource, actionTypes.ERROR) });
    });
};

export const sortBy = (resource, sortValue) => dispatch => {
  dispatch({
      type: actionTypes.getActionType(resource, actionTypes.SORT_FACTORING_COMPANY), data: sortValue
  });
}

export const setHeaderWidth = (resource, ind, width) => ({
  type: actionTypes.getActionType(resource, actionTypes.SET_HEADER_WIDTH_FACTORING_COMPANY),
  data: { ind, width },
});

export const setItemDisplayState = (resource, rowState, header, enable) => ({
  type: actionTypes.getActionType(resource, actionTypes.SET_DISPLAY_STATE),
  data: {
    rowState,
    header,
    enable,
  },
});

export const resetError = resource => ({ type: actionTypes.getActionType(resource, actionTypes.RESET_ERROR) });

// const errorAction = (resource, reload, errorText) => dispatch => {
//   if (reload) {
//       axios.get(buildUrl(resource),
//           axiosConfig()
//       )
//           .then(response => dispatch({ type: actionTypes.getActionType(resource, actionTypes.RELOAD_ON_ERROR), 
//             data: {
//               response, errorText,
//             }}))
//             .catch((err) => {
//                 console.error(err);
//                 console.log(err.response);
//                 dispatch({ type: actionTypes.getActionType(resource, actionTypes.ERROR) });
//           });
//   }
// };