export const RELOAD_ON_ERROR_COMMENTS = 'RELOAD_ON_ERROR_COMMENTS';
export const RESET_ERROR = 'RESET_ERROR';
export const SET_COMMENT = 'SET_COMMENT';
export const GET_COMMENTS = 'GET_COMMENTS';
export const COMMENTS_LOADING = 'COMMENTS_LOADING';
export const COMMENTS_ERROR = 'COMMENTS_ERROR';

export const getActionType = (resource, key) => {
    return COMMENTS_BUILD[key];
};

export const COMMENTS_BUILD = {
    RELOAD_ON_ERROR_COMMENTS: 'RELOAD_ON_ERROR_COMMENTS',
    RESET_ERROR: 'RESET_ERROR',
    SET_COMMENT: 'SET_COMMENT',
    GET_COMMENTS: 'GET_COMMENTS',
    COMMENTS_LOADING: 'COMMENTS_LOADING',
    COMMENTS_ERROR: 'COMMENTS_ERROR',
};
