import axios from 'axios';
import store from '../..';
import * as actionTypes from './locationsActionTypes';

const buildUrl = (resource, param) => `/api/${resource}/${param}`;

const axiosConfig = () => ({
    headers: {
        'Authorization': 'Bearer ' + store.getState().auth.userToken
    }
});

export const getLocations = (resource, param) => dispatch => {
    dispatch({ type: actionTypes.getActionType(resource, actionTypes.LOCATIONS_LOADING) });
    axios.get(buildUrl(resource, param),
        axiosConfig())
        .then(response => dispatch({ type: actionTypes.getActionType(resource, actionTypes.GET_LOCATIONS), data: response }))
        .catch((err) => {
            console.error(err);
            console.log(err.response);
            dispatch({ type: actionTypes.getActionType(resource, actionTypes.LOCATIONS_ERROR) });
        })
};