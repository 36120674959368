import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { Input, message } from "antd";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from '../../../../axios/axios';
import { openPopup } from '../../../../../store/actions/popup/popup';
import './styles.css';
import { values } from 'store/storages/all';
const _ = require("lodash");
const { TextArea } = Input;

const SONUCancelation = props => {
    const { sectionData, closeAction, saveAction } = props;
    const [ sonuRatePrice, setRatePrice ] = useState(sectionData.batch === 1 ? '' : sectionData.price || '');

    useEffect(() => {
        if (sectionData.batch === 1) {
            axios({
                url: `order/${sectionData.orderGroupIndex}`,
                method: 'GET'
            })
                .then(response => {
                    const orders = response.data.orders;
                    const summaryPrice = orders.reduce((acc, order) => acc + order.price, 0);
                    setRatePrice(summaryPrice)
                })
                .catch(error => {
                    console.error(error);
                });
        }
    }, [sectionData, sonuRatePrice]);


    const handleSubmitSave = values => {
        if (values.sonuType === 2 && !values.sonuPrice) {
            message.error("It is not possible to cancel an order with a '0' rate. Please unassign it before canceling");
        } else if (values.sonuType === 1 && sectionData.batch === 1) {
            message.error('Cancellation is only allowed with a partial SONU');
        } else if (values.sonuType === 2 && values.sonuPrice < values.sonuPartialPrice) {
            message.error('The partial SONU amount cannot exceed the total amount. Please adjust the values');
        } else {
            const requestData = {
                id: sectionData.id,
                amount: values.sonuPartialPrice ? values.sonuPartialPrice : sonuRatePrice ? sonuRatePrice : '',
                isPartialSonu: values.sonuType === 2,
                isSonu: values.sonuType === 1,
                cancelReason: values.sonuNote,
            }
            saveAction(requestData)
        }
    }

    const handleSonuType = (e, setFieldValue)  => {
        setFieldValue('sonuType', Number(e.target.value));
        setFieldValue('sonuPartialPrice', '');
    };

    const handleSonuPrice = (e, setFieldValue) => {
        setFieldValue('sonuType', 2)
        const value = (_.trimStart(e.target.value, 0)) || parseFloat(e.target,values) || '';
        setFieldValue('sonuPartialPrice', value);
    }

    const validationSchema = Yup.object().shape({
        sonuPrice: Yup.number().positive().nullable(),
        sonuPartialPrice: Yup.number().positive("The amount can't be negative or zero"),
        sonuType: Yup.number().required('Required'),
        sonuNote: Yup.string().nullable(),
    });

    return <div className='sonuModal__container'>
            <header className='SONU-Modal__header'>
                <p className='sonu-modal__header-text'>Select Cancellation Amount</p>
                <div effect="material" className='button white mini close SONU-Modal__header-closeBtn' command="close" onClick={closeAction}>
                    <icon>close</icon>
                </div>
            </header>

            <Formik
                initialValues={{
                    sonuPrice: sonuRatePrice || '',
                    sonuPartialPrice: values.sonuPartialPrice || '',
                    sonuType: 1,
                    sonuNote: '',
                }}
                validationSchema={validationSchema}
                enableReinitialize={true}
                onSubmit={(values, { resetForm, setSubmitting, setFieldValue, errors }) => {
                    setTimeout(() => {
                        setSubmitting(false);
                        handleSubmitSave(values);
                    }, 400);
                }}
            >
                {({ isSubmitting, isValid, values, setFieldValue, errors, touched, handleSubmit }) => {
                const disabledSonu = (values.sonuType === 1 && !sectionData.price) || (values.sonuType === 2 && !values.sonuPartialPrice) || errors?.sonuPartialPrice;
                
                return (
                <Form className='sonu-input__main-container'>
                        <div className='sonu__input-container'>
                            <Field onChange={e => handleSonuType(e, setFieldValue, values)} type="radio" name="sonuType" value={1} checked={values.sonuType === 1}/>
                            <span className='sonu-type__text'>Sonu</span>
                            <Field
                                name='sonuPrice'
                                className='sonu__input sonu-input__disabled'
                                type='number'
                                onChange={e => setRatePrice(e.target.value)}
                                disabled
                            />
                        </div>
                        <div className={`sonu__input-container`}>
                            <Field onChange={e => handleSonuType(e, setFieldValue, values)} type="radio" name="sonuType" value={2} checked={values.sonuType === 2}/>
                            <span className='sonu-type__text'>Partial Sonu</span>
                            <Field
                                name='sonuPartialPrice'
                                className='sonu__input'
                                type='number'
                                style={touched.sonuPartialPrice && errors.sonuPartialPrice && { border: '1px solid red', cursor: 'not-allowed' } }
                                onChange={e => handleSonuPrice(e, setFieldValue)}
                            />
                        </div>
                        <ErrorMessage name="sonuPartialPrice" component="div" className='sonu-error-container'/>
                        <div className='sonu-input-notes__container'>
                            <TextArea
                                name={'sonuNote'}
                                value={values.sonuNote}
                                placeholder="NOTES"
                                autoSize={{ minRows: 2, maxRows: 2 }}
                                onChange={e => setFieldValue('sonuNote', e.target.value)}
                            />
                        </div>
                        <div className='sonu-buttons__container'>
                            <button 
                                className="sonu-button" 
                                onClick={closeAction}>
                                CANCEL
                            </button>
                            <button 
                                disabled={disabledSonu} 
                                className={`sonu-button sonu-button-confirm ${disabledSonu && 'buttonDisabled'}`} 
                                onClick={handleSubmit}>
                                CONFIRM
                            </button>
                        </div>
                </Form>
                )}}
            </Formik>
        </div>
    ;
};

const mapDispatchToProps = dispatch => {
    return {
        openConfirm: (data) => dispatch(openPopup(data)),
        openAlert: (data) => dispatch(openPopup(data))
    };
};

export default connect(null, mapDispatchToProps)(SONUCancelation);