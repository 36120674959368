import React from 'react';
import { connect } from 'react-redux';
import CustomerOrders from '../../CustomerOrders/CustomerOrders';
import { getContentWidth } from '../../../../../store/reducers/customerOrderInProgress/helper/general';

const CustomerOrderBuild = props => {
  const { state } = props;
  const resource = 'customerOrderInProgress';
  const headers = state[resource].headers;
  const width = getContentWidth(headers);

  return (
    <div
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        margin: '0 12px 6px',
        alignSelf: 'center',
        width: '100%',
        maxWidth: width + 24,
      }}>
      <CustomerOrders {...props} />
    </div>
  );
};
const mapStateToProps = state => ({
  state: state,
});

export default connect(mapStateToProps)(CustomerOrderBuild);
