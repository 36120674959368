import React, { useEffect } from 'react';
import Modal from 'react-modal';
import { connect } from "react-redux";
import { Formik, Form, Field  } from 'formik';
import moment from 'moment'
import './index.css';
import { getRateConfirmationData, sendRateConfirmationData } from '../../../../store/actions/rateConfirmation/rateConfirmation';
import { getLoadDetails } from '../../../../store/actions/loadDetails/loadDetails';
import Loading from "../../screen/loading";

const RateConfirmationModal = ({
      setRateModalIsOpen,
      rateModalIsOpen,
      loading,
      EFSData,
      EFSLoading,
      loadId,
      getRateConfirmationData,
      sendRateConfirmationData,
      rateData,
      getLoadDetails,
    }) => {
  
  const resource = 'PDFGenerator';  
  const closeModal = () => {
    setRateModalIsOpen(false);
  };

  useEffect(() => {
    getRateConfirmationData(resource, loadId);
  }, [getRateConfirmationData, loadId]);

  const customStyle = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      minWidth: '90%',
      minHeight: '300px',
      maxWidth: '600px',
      maxHeight: '600px',  
      borderRadius: '8px',
      padding: loading ? '0 0 8px 0' : '0',
    },
  };

  const stops = rateData?.stopList?.map((stop, i) => (
    { notes: `notes_${i}` }
  ));
  const initialValuesList = stops && stops.map((note, index) => (
    { [Object.values(note)]: rateData?.stopList ? rateData?.stopList[index]?.stopData.notes : '' }
  ));
  const initialValues = initialValuesList?.reduce((prev, curr) => ({...prev, ...curr}) , {});
  
  const handleSubmitSave = values => {
    // eslint-disable-next-line no-unused-vars
    const updatedData = rateData?.stopList.map((stop, i) => {
      stop.stopData.notes = values[`notes_${i}`];

      const testDateUtc = moment.utc(stop.stopData.fullTime);
      const localDate = moment(testDateUtc).local();
      stop.stopData.time = stop.stopData.fullTime ? moment(localDate).format("HH:mm") : '';
      stop.stopData.date = stop.stopData.fullTime ? moment(localDate).format("DD/MM/YY"): '';
    });

    const updatedRateData = {
      hasError: rateData.hasError,
      message: rateData.message,
      loadId: rateData.loadId,
      rateConfirmationData: rateData,
      ccAddresses: rateData.carrierData?.accountingEmail,
      save: false,
    }

    closeModal();
    sendRateConfirmationData('PDFGenerator', rateData);
    sendRateConfirmationData('PDFGenerator', updatedRateData, 'sendPDF');
    setTimeout(() => {
      getLoadDetails('load', loadId, 'noLoading');
    }, 5000)
  };

  if (loading) {
    return (
      <Modal isOpen={rateModalIsOpen}>
        <div style={{ marginTop: '30% '}}>
          <Loading />
        </div>
      </Modal>
    )
  };

  const purchaseOrdersListStop = rateData?.stopList?.map(stop => stop.stopData.purchaseOrders);
  const purchaseOrdersListLocation = rateData?.orderList?.map(stop => stop.poNumber);
  
  let delNumbersList = [];
  for (let i = 0; i < purchaseOrdersListStop?.length; i++) {
    let delNumbers = [];
    for (let j = 0; j < purchaseOrdersListLocation?.length; j++) {
      if (rateData?.stopList[i].stopData.purchaseOrders === rateData?.orderList[j].poNumber ||
        rateData?.stopList[i].stopData.purchaseOrders.includes(rateData?.orderList[j].poNumber)) {
          if (rateData?.orderList[j].delNo !== '') {
            delNumbers.push(rateData?.orderList[j].delNo);
          }
      }
    }
    delNumbersList.push(delNumbers)
  };

  const toDateTime = value => {
    if (value) {
      const dateTime = moment.utc(value).toDate();
      return moment(dateTime);
    }
  };

  return (
    <Modal
        isOpen={rateModalIsOpen}
        onRequestClose={closeModal}
        style={customStyle}
        ariaHideApp={false}
        >
        <div className='comment_modal__container'>
          {EFSLoading ? <div style={{marginTop: '25%'}}><Loading /></div> : <>
            <div className='rate__title-container'>
              <div className='rate_modal__x-btn' onClick={closeModal}>X</div>
              <div className='rate-modal__title'>Rate & Load Confirmation</div>
            </div>
            <Formik
              initialValues={initialValues}
              enableReinitialize={true}
              onSubmit={(values, { resetForm, setSubmitting, setFieldValue, errors }) => {
                setTimeout(() => {
                  setSubmitting(false);
                  handleSubmitSave(values);
                }, 400);
                }}
            >
            {({ isSubmitting, isValid, values, setFieldValue, errors, touched, handleSubmit }) => {
              return (
              <Form style={{ width: '86%'}}>
              <div className=''>
                  <div className='load-rate__container'>
                    <div className='logo-container'>
                      <logo>
                        <svg xmlns="http://www.w3.org/2000/svg" width="172.25" height="45.875" viewBox="0 0 172.25 45.875">
                            <path fill="#00b7b1" d="M2388.6,589.6a1.044,1.044,0,0,0,1.45-.224l8.69-11.711h1.01l8.69,11.711a1.055,1.055,0,0,0,1.46.224l7.87-5.677a1.043,1.043,0,0,0,.23-1.461l-8.44-11.443,0.38-1.128,12.48-4.419a1.042,1.042,0,0,0,.64-1.324l-3.22-9.2a1.044,1.044,0,0,0-1.33-.641l-12.34,4.3-1.01-.376V544.971a1.045,1.045,0,0,0-1.05-1.041h-9.73a1.037,1.037,0,0,0-1.04,1.041v13.253l-1.14.376-12.22-4.292a1.045,1.045,0,0,0-1.33.64l-3.21,9.2a1.037,1.037,0,0,0,.63,1.324l12.49,4.419,0.37,1.128-8.43,11.443a1.043,1.043,0,0,0,.23,1.461Z" transform="translate(-2375.38 -543.938)" />
                            <path d="M2450.38,550.057v-1.44H2446V544.5l-1.5.441v3.674h-3.23v1.44h3.23v9.554c0,3.351,2.03,4.291,5.88,3.7v-1.322c-3,.264-4.38.205-4.38-2.381v-9.554h4.38Zm5,1.441v-2.881h-1.47v14.7h1.47v-8.171c0-3.763,2.38-5.321,4.91-5.321v-1.411A4.946,4.946,0,0,0,2455.38,551.5Zm18.46-2.881v7.76c0,3.968-2.26,5.85-5.14,5.85-2.77,0-4.32-1.764-4.32-4.468v-9.142h-1.5v9.142c0,3.556,2.2,5.879,5.7,5.879a5.539,5.539,0,0,0,5.26-3.116v2.792h1.47v-14.7h-1.47Zm12.61-.323a7.672,7.672,0,0,0,.12,15.344,7.252,7.252,0,0,0,6.41-3.322l-1.3-.764a5.581,5.581,0,0,1-5.08,2.675,5.981,5.981,0,0,1-6.23-5.5h13.31c0-.235.03-0.47,0.03-0.706C2493.71,552,2490.92,548.294,2486.45,548.294Zm0,1.411a5.7,5.7,0,0,1,5.73,5.585h-11.81A5.918,5.918,0,0,1,2486.45,549.705Zm14.23,3.2c0-.53.47-0.824,1.23-0.824a2.134,2.134,0,0,1,1.97,1.294l3.77-2.028a6.451,6.451,0,0,0-5.74-3.146c-3.05,0-5.76,1.617-5.76,4.821,0,5.085,7.23,4.233,7.23,5.85,0,0.588-.53.911-1.58,0.911a2.409,2.409,0,0,1-2.5-1.734l-3.82,2.175c1.14,2.381,3.38,3.5,6.32,3.5,3.17,0,6.11-1.441,6.11-4.821C2507.91,553.467,2500.68,554.584,2500.68,552.909Zm17.61-.059v-4.233h-3.03V544.5l-4.41,1.322v2.793h-2.35v4.233h2.35v5.2c0,4.115,1.68,5.849,7.44,5.261v-4c-1.94.117-3.03,0-3.03-1.264v-5.2h3.03Zm12.87-4.233V550a5.52,5.52,0,0,0-4.38-1.793c-3.85,0-7.02,3.381-7.02,7.761s3.17,7.76,7.02,7.76a5.52,5.52,0,0,0,4.38-1.793v1.381h4.41v-14.7h-4.41Zm-3.5,10.935a3.588,3.588,0,1,1,3.5-3.586A3.355,3.355,0,0,1,2527.66,559.552Zm15.55-8.319v-2.616h-4.41v14.7h4.41v-6.643c0-2.91,2.59-3.645,4.41-3.351v-5A4.419,4.419,0,0,0,2543.21,551.233Z" transform="translate(-2375.38 -543.938)" />
                            <path fill="#00b7b1" d="M2451.8,578.867v-4.233h-3.02v-4.116l-4.41,1.323v2.793h-2.35v4.233h2.35v5.2c0,4.116,1.67,5.85,7.43,5.262v-4c-1.94.118-3.02,0-3.02-1.264v-5.2h3.02Zm7.21-1.617v-2.616h-4.41v14.7h4.41v-6.643c0-2.91,2.58-3.645,4.4-3.351v-5A4.4,4.4,0,0,0,2459.01,577.25Zm16.69-2.616v1.381a5.52,5.52,0,0,0-4.38-1.793c-3.85,0-7.02,3.381-7.02,7.76s3.17,7.761,7.02,7.761a5.52,5.52,0,0,0,4.38-1.793v1.381h4.41v-14.7h-4.41Zm-3.5,10.935a3.588,3.588,0,1,1,3.5-3.587A3.355,3.355,0,0,1,2472.2,585.569Zm19.76-11.347a5.051,5.051,0,0,0-4.21,1.793v-1.381h-4.41v14.7h4.41v-8.025c0-2.087,1.12-3.027,2.74-3.027a2.426,2.426,0,0,1,2.56,2.675v8.377h4.4v-9.024C2497.45,576.339,2494.93,574.222,2491.96,574.222Zm12.7,4.7c0-.529.47-0.823,1.23-0.823a2.133,2.133,0,0,1,1.97,1.294l3.76-2.029a6.423,6.423,0,0,0-5.73-3.145c-3.06,0-5.76,1.617-5.76,4.821,0,5.085,7.23,4.233,7.23,5.85,0,0.587-.53.911-1.59,0.911a2.425,2.425,0,0,1-2.5-1.735l-3.82,2.176c1.15,2.381,3.38,3.5,6.32,3.5,3.18,0,6.12-1.441,6.12-4.821C2511.89,579.484,2504.66,580.6,2504.66,578.925Zm11.64-5.585a2.646,2.646,0,1,0-2.65-2.645A2.668,2.668,0,0,0,2516.3,573.34Zm-2.21,15.991h4.41v-14.7h-4.41v14.7Zm16.26-10.464v-4.233h-3.03v-4.116l-4.41,1.323v2.793h-2.35v4.233h2.35v5.2c0,4.116,1.68,5.85,7.44,5.262v-4c-1.94.118-3.03,0-3.03-1.264v-5.2h3.03Z" transform="translate(-2375.38 -543.938)" />
                        </svg>
                      </logo>
                      <div className='rate-address__container'>
                        <div>{rateData?.company?.address}</div>
                        <div>{rateData?.company?.addressLocation}</div>
                      </div>
                    </div>
                      <table className='rate-table__dispatcher-container'>
                        <tr className='rate-modal__tr'>
                          <td className='rate-modal__th rate-th__1'>Dispatcher:</td>
                          <td className='rate-modal__th rate-th__2'>{rateData?.loadInfo?.dispatcher}</td>
                          <td className='rate-modal__th rate-th__3 rate__load'>LOAD #</td>
                          <td className='rate-modal__th rate-th__4'>{rateData?.loadInfo?.loadNumber}</td>
                        </tr>
                        <tr className='rate-modal__tr'>
                          <td className='rate-modal__th rate-th__1'>Phone #:</td>
                          <td className='rate-modal__th rate-th__2'>{rateData?.loadInfo?.phone}</td>
                          <td className='rate-modal__th rate-th__3'>Ship Date:</td>
                          <td className='rate-modal__th rate-th__4'>{rateData?.loadInfo?.pickUpDate}</td>
                        </tr>
                        <tr className='rate-modal__tr'>
                          <td className='rate-modal__th rate-th__1'>Fax #:</td>
                          <td className='rate-modal__th rate-th__2'>{rateData?.loadInfo?.fax}</td>
                          <td className='rate-modal__th rate-th__3'>Today's Date:</td>
                          <td className='rate-modal__th rate-th__4'>{rateData?.loadInfo?.currentDate}</td>
                        </tr>
                        <tr className='rate-modal__tr'>
                          <td className='rate-modal__th rate-th__1'>Email:</td>
                          <td className='rate-modal__th rate-th__2' colspan="3">{rateData?.loadInfo?.email}</td>
                        </tr>
                      </table>
                  </div>
                  <div className='rate-carrier-container'>
                    <table className='rate-table-carrier-container'>
                      <tr className='rate-modal__tr rate-carrier-table__head-td'>
                        <td className='rate-modal__th rate-carrier__td'>Carrier:</td>
                        <td className='rate-modal__th rate-carrier__td'>MC#</td>
                        <td className='rate-modal__th rate-carrier__td'>Phone #</td>
                        <td className='rate-modal__th rate-carrier__td'>Equipment</td>
                        <td className='rate-modal__th rate-carrier__td'>Rate</td>
                        <td className='rate-modal__th rate-carrier__td'>Load Status</td>
                      </tr>
                      <tr className='rate-modal__tr'>
                        <td className='rate-modal__th rate-carrier__td rate-carrier-data'>{rateData?.carrierData?.carrierName}</td>
                        <td className='rate-modal__th rate-carrier__td rate-carrier-data'>{rateData?.carrierData?.mc}</td>
                        <td className='rate-modal__th rate-carrier__td rate-carrier-data'>{rateData?.carrierData?.phone}</td>
                        <td className='rate-modal__th rate-carrier__td rate-carrier-data'>{rateData?.carrierData?.equipment}</td>
                        <td className='rate-modal__th rate-carrier__td rate-carrier-data'>{rateData?.carrierData?.carrierRate ? `$${rateData?.carrierData?.carrierRate}` : ''}</td>
                        <td className='rate-modal__th rate-carrier__td rate-carrier-data'>{rateData?.carrierData?.status}</td>
                      </tr>
                    </table>
                  </div>
                  {rateData?.stopList?.length ?
                    rateData.stopList.map((stop, i) => (
                      <div className='rate-shipper-container'>
                        <table className='rate-table-carrier-container'>
                          <tr className='rate-modal__tr'>
                            <td className='rate-stop-modal__th rate-carrier__td stop_td_1'>{stop.locationData.name}</td>
                            <td className='rate-stop-modal__th rate-carrier__td stop_td_2'>Date:</td>
                            <td className='rate-stop-modal__th rate-carrier__td stop_td_3'>{toDateTime(stop.stopData.fullTime)?.format("DD/MM/YY")}</td>
                            <td className='rate-stop-modal__th rate-carrier__td stop_td_4'>Purchase Order:</td>
                            <td className='rate-stop-modal__th rate-carrier__td'>{stop.stopData.purchaseOrders}</td>
                          </tr>
                          <tr className='rate-modal__tr'>
                            <td className='rate-stop-modal__th rate-carrier__td' rowSpan={5}>
                              {`${stop.locationData.address1} ${stop.locationData.address2} ${stop.locationData.address3}`}</td>
                            <td className='rate-stop-modal__th rate-carrier__td stop_td_2'>Time:</td>
                            <td className='rate-stop-modal__th rate-carrier__td stop_td_3'>{toDateTime(stop.stopData.fullTime)?.format("HH:mm")}</td>
                            <td className='rate-stop-modal__th rate-carrier__td stop_td_4'>Del#:</td>
                            <td className='rate-stop-modal__th rate-carrier__td stop_td_5'>{delNumbersList[i].join().split(',').join(', ')}</td>
                          </tr>
                          <tr className='rate-modal__tr'>
                            <td className='rate-stop-modal__th rate-carrier__td stop_td_2'>Pallet:</td>
                            <td className='rate-stop-modal__th rate-carrier__td stop_td_3'>{stop.stopData.pallets}</td>
                            <td className='rate-stop-modal__th rate-carrier__td stop_td_4'>Appointment:</td>
                            <td className='rate-stop-modal__th rate-carrier__td'>{stop.stopData.hasAppointment}</td>
                          </tr>
                          <tr className='rate-modal__tr'>
                            <td className='rate-stop-modal__th rate-carrier__td stop_td_2'>LBS:</td>
                            <td className='rate-stop-modal__th rate-carrier__td stop_td_3'>{stop.stopData.lbs}</td>
                            <td className='rate-stop-modal__th rate-carrier__td stop_td_4'>Description</td>
                            <td className='rate-stop-modal__th rate-carrier__td'>{stop.stopData.loadSequences}</td>
                          </tr>
                          <tr className='rate-modal__tr'>
                            <td className='rate-stop-modal__th rate-carrier__td stop_td_2' colSpan={2}>Notes:</td>
                            <td className='rate-stop-modal__th rate-carrier__td' colSpan={2}>
                              <Field
                                name={`notes_${i}`}
                                className='rate-note__input'
                              />
                            </td>
                          </tr>
                        </table>
                      </div>)) : null}
                  <div className='rate__main-text'>
                      <div className='rate__main-text__bold rate-text'>Dispatch Notes:</div>
                      <div className='rate__main-text__bold'><u>PLEASE CHECK IN AS TRUE STAR TRANSIT AT EVERY FACILITY.</u></div>
                      <div className='rate-text'>*THIS RATE CONFIRMATION IS FOR YOUR VIEWING ONLY.</div>
                      <div className='rate__main-text__bold'>IMPORTANT DISCLAIMERS:</div>
                      <div className='rate-text'>Please print, initial, sign and scan this agreement and send it back to ap@truestartransit.com</div>
                      <div className='rate-text'>1) Drivers must provide check calls providing location and check in and out times of delivery. ______(Initial)</div>
                      <div className='rate-text'>Rate confirmation without driver contract are incomplete.Name will need to match what appears on the BOL and POD and driver will need to have ID for proof: <span className='rate__main-text__bold'>NO EXEPTIONS</span></div>
                      <div className='rate-text'>2) Late deliveries may result in fines of $300 plus requiring a new appointment, which may require at least a 24-hour wait period. Please don't be late. ______(Initial)</div>
                      <div className='rate-text'>3) Carrier is responsible for all costs accociated with truck breakdowns. _______ (Initial)</div>
                      <div className='rate-text'>4) Refrigerated trailers must be set at the required temperature identified on this rate sheet and provided by the shipper. Loads are trucked by temperature trackers and will be rejected if directions are not followed resulting in a claim for the carrier. _______(Initial)</div>
                      <div className='rate-text'>5) Drivers are required to go throw the entire trailer and make sure all pallets are securely loaded. Drivers MUST have appropriate load locks <span className='rate__main-text__bold '>(3 recommended)</span> and straps to secure all pallets. _______ (Initial)</div>
                      <div className='rate-text'>6) PODs are promptly required by shipping customers to verify delivery. PODs must be send within 24 hours of delivery or carriers will have to pay $250 fine. <span className='rate__main-text__bold '>NO EXCEPTIONS.</span> _______(Initial)</div>
                      <div className='rate-text'>7) <span className='rate__main-text__bold'>EFS Money codes will only be issued during the hours of 6:00 am to 5:00 pm Monday-Friday and 8:00 am - 1:00 pm Saturday.</span> _______ (Initial)</div>
                      <div className='rate-text'>8) Reefer must be running at 33 degrees Continuous at all times. $500 FINE and product rejection if reefer is not set on 33 degrees. Start/stop or cycle are NOT ACCEPTABLE  and will result in a fine. All loads are temp tracked by receiver. _______(Initial)</div>
                      <div className='rate-text'>By signing below I understand that I am an authorized representative of ______________________ (Carrier Name), and I agree to the terms of this agreement and promise ethical transport and execution of all goals.</div>
                      <div className='rate-text rate__main-text__bold'>NO LOADS CAN BE 'DOUBLE-BROKERED' OR TRANSFERRED TO ANY OTHER CARRIER THAT IS NOT NAMED IN THIS RATE CONFIRMATION. BY SIGNING THIS DOCUMENT, I PROVIDE PERSONAL GUARANTEES THAT EQUIPMENT ARRIVING IS OWNED, OPERATED AND INSURED BY THE CARRIER NAMED ABOVE</div>
                      <div className='rate-text'>
                        <span className='rate__main-text__bold'>Carrier pay</span>
                          : Line Haul: {`${rateData?.carrierData?.carrierRate ? `$${rateData?.carrierData?.carrierRate}` : ''} `}, 
                        <span className='rate__main-text__bold'>
                          TOTAL: {`${rateData?.carrierData?.carrierRate ? `$${rateData?.carrierData?.carrierRate}` : ''} `}</span>
                      </div>
                      <div className='rate-text rate__main-text__bold'>Accepted By: ___________________ Date: ___________________ Signature: _________________</div>
                      <div className='rate-text rate__main-text__bold'>Driver Nname: _____________ Cell #: ____________ Truck #: ___________ Trailer #: ___________</div>
                  </div>
                    
                  <div className='modal-form__load-details formik_button__container load-rate-btn__container'>
                    <button 
                      className='formik_btn formik_btn__rate' 
                      type="submit" 
                      disabled={!isValid || isSubmitting || EFSData?.efsStatusStr === 'Rejected' || EFSData?.efsStatusStr === 'Issued' || EFSData?.efsStatusStr === 'Sent'}
                      onClick={() => handleSubmit()}
                      >Send
                    </button>
                  </div>

                </div>
              </Form>
            )}}
          </Formik>
          </>}
        </div>  
    </Modal>
  );
};

const mapStateToProps = state => ({
  state: state,
  rateData: state.rateConfirmation.rateConfirmationData,
  loading: state.rateConfirmation.loading,
});

const mapDispatchToProps = (dispatch) => ({
  getRateConfirmationData: (resource, id) => dispatch(getRateConfirmationData(resource, id)),
  sendRateConfirmationData: (resource, data, sendPDF) => dispatch(sendRateConfirmationData(resource, data, sendPDF)),
  getLoadDetails: (resource, id, noLoading) => dispatch(getLoadDetails(resource, id, noLoading)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RateConfirmationModal);
