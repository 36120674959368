import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { GenerateInput } from '../../../helper/element';
import { ElementConditions } from '../../../config/condition.config';
import { getListFilter, buildListFilterQuery, getCriteriaQuery } from '../../../helper/list';

export const Search = props => {
    const timeOut = useRef(null);
    const history = useHistory();
    const criteriaQuery = getCriteriaQuery(history);

    const [searchValue, setSearchValue] = useState(null);
    
    useEffect(() => {
        return () => {
            clearTimeout(timeOut.current);
        }
    }, []);

    const onSearchHandelr = event => {
        if (timeOut.current) {
            clearTimeout(timeOut.current);
        }
        const val = event.target.value;
        setSearchValue(val);
        timeOut.current = setTimeout(() => {
            const filter = getListFilter(criteriaQuery) || {};
            filter[props.field] = val;
            history.push({
                search: buildListFilterQuery(criteriaQuery, filter)
            });
        }, 500);
    };

    useEffect(() => {
        const filter = getListFilter(criteriaQuery);
        filter && setSearchValue(filter[props.field]);
    }, [criteriaQuery, props.field]);

    return (
        <toolbar className="srch" compact="">
            <icon>search</icon>
            { GenerateInput('text', ElementConditions.Enable, null, searchValue, ['icon_search'], onSearchHandelr, null, null, 'Search here') }
        </toolbar>);
};

export default Search;