import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from "react-redux";
import { CSVLink  } from "react-csv";
import { Input } from 'antd';
import FactoringCompany from '../../FactoringCompany/spreadsheet';
import { getContentWidth } from '../../../../helper/common';
import HideShowMenuSwitch from '../../../layout/hideShowMenuSwitch';
import { useHistory } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { getFactoringCompanyList } from '../../../../../store/actions/factoringCompany/factoringCompany';
import { getCSVData } from '../../../project/FactoringCompany/helper'
import { FactoringCompanySelectors } from '../../../../../store/selectors/factoringCompanySelectors';

const FactoringCompanyBuild = () => {
  const dispatch = useDispatch();
  const formikRef = useRef();
  const { state, factoringCompanyList } = FactoringCompanySelectors();
  const resource = 'factoringCompany';
  const headers = state[resource].headers;
  const width = getContentWidth(headers) + 18;
  const style = { maxWidth: width };
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const [filteredFactoringData, setFilteredFactoringData] = useState(factoringCompanyList);

  const handleClearAll = setFieldValue => {
    setFieldValue('companyName', '');
    setFieldValue('email', '')
  };

  useEffect(() => {
    setFilteredFactoringData(factoringCompanyList);
}, [factoringCompanyList]);

  const refreshPage = resetForm => {
    dispatch(getFactoringCompanyList(resource))
    resetForm()
  };

  const CSVHeaders = state[resource].headers.map(column => ({
    label: column.title,
    key: column.title,
  }));
  const CSVData = getCSVData(factoringCompanyList);

const search = e => {
    let newList = [...factoringCompanyList];
    if (e.target.value){
        newList = newList?.filter(factory => {
            const searchFactory = factory.row
                .filter(item => item.key === "name" || item.key === "mailingAddress" || item.key === "zipCode");
            const bool = searchFactory.filter(item1 => item1.value?.toLowerCase().search(e.target.value?.toLowerCase()) !== -1)
            return bool.length
        })
        setFilteredFactoringData(newList)
    } else {
        setFilteredFactoringData(newList)
    }
};

  const subHeader = (<>
    <div className='sub-header__container'>
        <div className='factoring-sub-header'>
            <div style={{ minHeight: '44px', minWidth: '44px', borderBottom: '1px solid #ebeaea' }}></div>
            <button className='factoring__add-btn' 
            onClick={() => history.replace(`factoringDetails/${0}`)}
            >Add</button>
            <div className="button accent" onClick={() => refreshPage(formikRef.current.resetForm)}>Refresh</div>
            <CSVLink 
                headers={CSVHeaders}
                data={CSVData}
                filename={`FactoringCompany.csv`}
                className='btn__download__ar'
                disabled={!factoringCompanyList?.length}
            >
                Download
            </CSVLink>
            <Input
                className='factoring__search'
                onChange={search}
                placeholder="Search here"
                type="text"
            />
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <HideShowMenuSwitch />
            </div>
        </div>
    </div>
  </>);

  return (<>
    {subHeader}
    <div className={`factoring__filters ${open ? 'factoring__filters__large' : 'factoring__filters__small'} `}>
      <div className={`collapsedView ${open ? 'hideSmall' : 'openSmall'} `} onClick={() => setOpen(true)}>
        <span className='factoring__text-expande-small'>Expande</span>
        <span className='factoring__text-filters-small'>Filters</span>
        <icon className='factoring__grid-icon-small'>grid</icon>
      </div>

      <div className={`${open ? 'openLarge' : 'hideLarge'} `}>
        <div className='expandedView'>
          <div className='factoring-filter__expanded-header' onClick={() => setOpen(false)}>
              <icon className='factoring__grid-icon-large'>grid</icon>
              <span className='factoring__text-filters-large'>Filters</span>              
              <span className='factoring__text-expande-large'>
                <span className='factoring-filter__point'>.</span>
                Collapse
              </span>
          </div>
          <Formik
              innerRef={formikRef}
              initialValues={{ companyName: '',  email: '' }}
              // validationSchema={validationSchema}
              onSubmit={(values, { resetForm, setSubmitting, setFieldValue, errors, touched }) => {
                // setButtonDisabled(true);
                dispatch(getFactoringCompanyList(resource, values.companyName, values.email));
              }}>
              {({ isSubmitting, isValid, values, setFieldValue, setFieldTouched, errors, touched, handleSubmit }) => {
                return (
                  <Form>
                    <div className='factoring-company_formik_container'>
                        <div className='factoring-filter__input-container'>
                            <div className='factoring-filter__email-label'>Name</div>
                            <Field
                              name='companyName'
                              placeholder='Name'
                              style={touched.companyName && errors.companyName && { border: '1px solid red' }}
                              className={`factoring-filter__email-input ${(values.companyName)}`}
                            />
                            <span className='error-msg'><ErrorMessage name='companyName' /></span>
                            <div className='factoring-filter__email-label'>Email</div>
                            <Field
                              name='email'
                              placeholder='Email'
                              style={touched.email && errors.email && { border: '1px solid red' }}
                              className={`factoring-filter__email-input ${(values.email)}`}
                            />
                            <span className='error-msg'><ErrorMessage name='email' /></span>
                        </div>
                        <div className='factoring-filter__button-container'>
                          <button className='factoring-filter__button' type='submit' 
                              onClick={() => handleClearAll(setFieldValue)}>Clear All
                          </button>
                          <button className='factoring-filter__button' type='submit'>Apply</button>
                        </div>
                    </div>
                  </Form>
                )
              }}
          </Formik>
        </div>
      </div>

      <div style={style} className="factoring-company_build">
        <FactoringCompany resource={resource} filteredFactoringData={filteredFactoringData} />
      </div>
    </div>
    </>
    );
};

export default FactoringCompanyBuild;
