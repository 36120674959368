import React from 'react';
import Modal from 'react-modal';

const DuplicateLocationsModal = ({ 
    duplicateLocationsModalIsOpen,
    setDuplicateLocationsModalIsOpen,
  }) => {

  const closeModal = () => {
    setDuplicateLocationsModalIsOpen(false);
  };

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      minWidth: '250px',
      maxWidth: '320px',
      minHeight: '180px',      
      borderRadius: '8px',
      padding: '6px 12px',
      textAlign: 'center',
    },
  };

  return (
    <Modal
        isOpen={duplicateLocationsModalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        ariaHideApp={false}
        >
        <div className='price_modal__container'>
          <div className='price_modal_text'>The Pick Location and The Drop Locations can't be the same</div>
          <div>
            <button className='price-modal-button-cancel' type="submit" onClick={closeModal}>OK</button>
          </div>
        </div>  
    </Modal>
  );
};

export default DuplicateLocationsModal;