import React, {
    useRef
} from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { auth } from '../../../store/actions/authentication/authentication';
import LoginFormScreen from '../screen/loginFormScreen';
import Content from './content';
import { Mapping } from '../../mapping/mapping';
import { ValidateScreen } from '../../helper/validation';
import { useData } from '../../hook/data/useData';
import { useMessage } from '../../hook/data/useMessage';
import { DefaultMessageTypes } from '../../hook/actions';
import { useFormValidation } from '../../hook/data/useFormValidation';
import LoginToolBar from './toolbar/loginToolbar';
import axios from '../../axios/axios';


import { AppPages, UpdateAppPages } from '../../../project/Pages';

const getParams = () => {
    return new URLSearchParams(window.location.search);
};

const LoginForm = props => {
    const componentRefs = useRef({});
    //state
    const [data, dispatch] = useData(props.screen.DefaultState);
    const [messages, messageDispatch] = useMessage(props.screen.Form);
    //end state

    const validate = useFormValidation(messageDispatch,
        data,
        Mapping,
        ValidateScreen,
        props.screen.Form,
        componentRefs);


    const formValidation = () => {
        messageDispatch({ type: DefaultMessageTypes.START_FOCUSE, messages: true });
        const [passValidation] = validate();
        return passValidation;
    };

    const doLoginHandler = () => {
        doLogin();
    };

    const doLogin = () => {
        if (!formValidation()) {
            return;
        }
        const query = getParams();
        const redirectUrl = query.get("redirectUrl") || "";
        props.onAuth(data.userName, data.password, data.remember, redirectUrl);
    };

    const setRememberHandler = event => {
        dispatch({ type: 'remember', value: event.target.checked });
    };

    if (props.isLoggedIn) {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + props.userToken;
        UpdateAppPages();
        let redirectUrl = AppPages.DefaultPage;
        if (props.redirectUrl) {
            redirectUrl = props.redirectUrl;
        }
        return <Redirect to={redirectUrl} />;
    };

    const actionFromElement = event => {
        if (event.nativeEvent.key === "Enter") {
            doLogin();
        }
    };

    return (
        <LoginFormScreen
            isLoading={props.isLoading}
            title={props.screen.Title}>
            <form defaultbutton="[command='login']" autoComplete="off">
                <Content
                    config={props.screen.Form}
                    data={data}
                    dispatch={dispatch}
                    messages={messages}
                    messageDispatch={messageDispatch}
                    componentRefs={componentRefs}
                    actionFromElement={actionFromElement}
                />
                <LoginToolBar doLogin={doLoginHandler} setRemember={setRememberHandler} remember={data['remember']} />
            </form>
        </LoginFormScreen>);
};

const mapStateToProps = state => {
    return {
        isLoading: state.auth.loading,
        isLoggedIn: state.auth.isLoggedIn,
        userToken: state.auth.userToken,
        redirectUrl: state.auth.redirectUrl
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onAuth: (login, password, remember, returnUrl) => dispatch(auth(login, password, remember, returnUrl))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);

