import React from 'react';

import AvatarUploader from '../elements/avatarUploader';

const Uploader = () => {
    return (
        <>
            <h1>Uploader</h1>
            <AvatarUploader max={5} condition={true} />
        </>
    );
};

export default Uploader;