const itemDefaultSize = 28;

export const createWebOrder = (oData, headers) => {
  const data = { ...oData };
  const loadStatus = data.loadStatus;
  const orderWebData = {
    row: [],
    rowState: {
      loadId: `load_${data.loadId}`,
      orderId: data.id,
      type: data.loadType,
      orderData: data,
      height: itemDefaultSize,
      loadStatus,
    },
  };

  for (let headerIndex = 0; headerIndex < headers.length; headerIndex++) {
    const headerItem = headers[headerIndex];
    let value = null;
    let fieldType = 'text';
    switch (headerItem.key) {
      case 'group':
        value = data.orderGroupIndex;
        fieldType = 'text';
        break;
      case 'po':
        value = data.poNumber;
        fieldType = 'text';
        break;
      case 'so':
        value = data.soNumber;
        fieldType = 'text';
        break;
      case 'customer':
        value = data.customerName;
        fieldType = 'text';
        break;
      case 'batch':
        value = data.batch === 1 ? "Hard Batch" : data.batch === 2 ? "Soft Batch" : data.batch === 3 ? "No Batch" : '';
        fieldType = 'text';
        break;
      case 'puCity':
        value = data.pickCity;
        break;
      case 'puState':
        value = data.pickState;
        fieldType = 'text';
        break;
      case 'pickUpWH':
        fieldType = 'text';
        value = data.pickLocationName;
        break;
      case 'drop1City':
        value = data.drop1City;
        fieldType = 'text';
        break;
      case 'drop1WH':
        value = data.drop1WH;
        fieldType = 'text';
        break;
      case 'drop2City':
        value = data.drop2City;
        break;
      case 'drop2WH':
        value = data.drop2WH;
        fieldType = 'text';
        break;
      case 'puDate':
        value = data.puDate;
        fieldType = 'dateTime';
        break;
      case 'dueDate':
        value = data.dueDate;
        fieldType = 'dateTime';
        break;
      case 'route':
        value = data.routeId === 1 ? "WHS" : data.routeId === 2 ? "DIR" : data.routeId === 3 ? "Both" : 'NO';
        break;
      case 'palletCount':
        value = [data.palletCount, data.revisionPalletCount];
        fieldType = 'revisionText';
        break;
      case 'spa':
        value = [data.space, data.revisionSpace];
        fieldType = 'revisionText';
        break;
      case 'case':
        value = [data.cases, data.revisionCases];
        fieldType = 'revisionText';
        break;
      case 'lbs':
        value = [data.weight, data.revisionWeight];
        fieldType = 'revisionText';
        break;
      case 'price':
        value = data.price;
        fieldType = 'text';
        break;
      case 'notes':
        value = [data.customerOrderNotes, data.revisionNotes];
        fieldType = 'revisionText';
        break;
      case 'status':
        value = 'Revision';
        break;
      case 'link':
        value = 'Link';
        fieldType = 'link';
        break;
      case 'actions':
        value = 'text';
        fieldType = 'actions';
        break;
      default:
        break;
    }
    orderWebData.row.push({
      key: headerItem.key,
      value,
      fieldType,
    });
  }
  return orderWebData;
};

const createRevisionOrdersList = (state, customerOrders) => customerOrders.map(data => createWebOrder(data, state.headers));

export const revisionOrderAdmin = (state, responce, hasError = false, message = '') => {
  const { data } = responce;
  const revisionOrderAdmin = createRevisionOrdersList(state, data.data);

  return {
    ...state,
    ...{
      loading: false,
      acceptRevisionOrderLoading: false,
      rejectRevisionOrderLoading: false,
      hasError: false,
      messageText: '',
      revisionOrderAdminList: revisionOrderAdmin,
      total: data.total,
      orderAccepted: false,
      orderRejected: false,
    },
  };
};

export const acceptRevisionOrder = (state, responce) => {
  return {
    ...state,
    ...{
      acceptRevisionOrderLoading: false,
      total: state.total - 1,
      hasError: responce.data.hasError,
      messageText: responce.data.message ? responce.data.message : 'Something went wrong, please try again',
      orderAccepted: !responce.data.hasError,
    },
  };
};

export const rejectRevisionOrder = (state, responce) => {
  return {
    ...state,
    ...{
      rejectRevisionOrderLoading: false,
      total: state.total - 1,
      hasError: responce.data.hasError,
      messageText: responce?.data.message ? responce.data.message : 'Something went wrong, please try again',
      orderRejected: !responce.data.hasError,
    },
  };
};

export const setDisplayState = (state, rowState, header, enable) => ({
  ...state,
  ...{
    lastSelected: {
      id: rowState.id,
      headerKey: header.key,
      enable: enable,
    },
  },
});

export const setHeaderWidth = (state, ind, width) => {
  const header = state.headers[ind];
  header.width = width;
  return {
    ...state,
    ...{
      headers: [...state.headers.slice(0, ind), ...[header], ...state.headers.slice(ind + 1)],
    },
  };
};

export const loadContent = (state, response, hasError = false, message = '') => {
  return revisionOrderAdmin(state, response, hasError, message);
};

export const setError = (state, err) => ({
  ...state,
  ...{
    hasError: true,
    messageText: err?.statusText ? err?.statusText : 'Something went wrong, please try again',
  },
});

export const resetError = state => ({
  ...state,
  ...{
    hasError: false,
    message: '',
    acceptRevisionOrderLoading: false,
    rejectRevisionOrderLoading: false,
    orderAccepted: false,
    orderRejected: false,
  },
});

export const setInLoading = state => ({
  ...state,
  ...{
    loading: true,
  },
});

export const acceptRevisionOrderLoading = state => ({
  ...state,
  ...{
    acceptRevisionOrderLoading: true,
  },
});

export const rejectRevisionOrderLoading = state => ({
  ...state,
  ...{
    rejectRevisionOrderLoading: true,
  },
});


