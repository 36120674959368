import axios from 'axios';

import store from '../../../store';
import { ApiUrl } from '../../../project/Pages';
import * as authenticationActionTypes from './authenticationActionTypes';

const authStart = () => {
    return {
        type: authenticationActionTypes.AUTH_START
    };
};

const authFail = (error) => {
    return {
        type: authenticationActionTypes.AUTH_FAIL,
        error: error
    };
};

export const check = (exp, refreshTocken) => {
    const afterMinutes = 1;
    const duration = (exp - (afterMinutes * 60 * 1000) - new Date().getTime());
    return dispathc => setTimeout(() => {
        dispathc(refresh(refreshTocken));
    }, duration);
};

const doLogin = (authData) => ({
    type: authenticationActionTypes.LOGIN,
    authData: authData
});

const refresh = (refreshTocken) => {
    return dispatch => {
        const token = store.getState().auth.userToken;
        if (!token) {
            return;
        }
        const options = {
            headers: { 'Authorization': 'Bearer ' + token }
        };
        const refreshRequest = {
            RefreshToken: refreshTocken
        };
        axios.post(ApiUrl.RefreshLogin,
            refreshRequest, options)
            .then(response => {
                if (response) {
                    const data = response.data;
                    const parts = data.userToken.split('.');
                    if (parts.length > 1) {
                        const part = parts[1];
                        const jPart = JSON.parse(atob(part));
                        data.exp = jPart.exp * 1000;
                    }
                    dispatch(doRefreshTocken(data));
                }
            })
            .catch((err) => {
                console.error(err);
                console.log(err.response);
                dispatch(logout());
            });
    };
};

export const updateProfile = data => ({
    type: authenticationActionTypes.PROFILE,
    data: data
})

const doRefreshTocken = (authData) => ({
    type: authenticationActionTypes.REFRESH,
    authData: authData
});

export const logout = () => ({
    type: authenticationActionTypes.LOGOUT
});

export const autoLogin = (returnUrl, refreshToken) => {
    return dispatch => {
        dispatch(authStart());
        dispatch(logout());
        const authRequest = {
            returnUrl: returnUrl,
            refreshToken: refreshToken
        };
        axios.post(ApiUrl.AutoLogin,
            authRequest)
            .then(response => {
                const data = response.data;
                const parts = data.userToken.split('.');
                if (parts.length > 1) {
                    const part = parts[1];
                    const jPart = JSON.parse(atob(part));
                    data.exp = jPart.exp * 1000;
                }
                dispatch(doLogin(data));
            })
            .catch((err) => {
                console.error(err);
                console.log(err.response);
                dispatch(authFail(err.response));
            });
    };
};

export const auth = (login, password, remember, redirectUrl) => {
    return dispatch => {
        dispatch(authStart());
        const authRequest = {
            userName: login,
            password: password,
            remember: remember,
            returnUrl: redirectUrl
        };
        axios.post(ApiUrl.Login,
            authRequest)
            .then(response => {
                const data = response.data;
                if (!data.isAuthenticated) {
                    console.error(data.message);
                    dispatch(authFail(data.message));
                    return;
                }
                const parts = data.userToken.split('.');
                if (parts.length > 1) {
                    const part = parts[1];
                    const jPart = JSON.parse(atob(part));
                    data.exp = jPart.exp * 1000;
                }
                dispatch(doLogin(data));
            })
            .catch((err) => {
                console.error(err);
                console.log(err.response);
                dispatch(authFail(err.response));
            });
    }; 
};

const storeProfileColumn = (resource, columns, type) => ({
    type: type,
    data: {
        resource: resource,
        columns: columns
    }
});

const ListColumnWidthUpdateTemp = () => {
    return {
        type: authenticationActionTypes.LIST_COLUMN_WIDTH_UPDATE_TEMP
    };
};

export const setProfileColumnVisibility = (resource, columns) => {
    return dispatch => {
        dispatch(storeProfileColumn(resource, columns, authenticationActionTypes.LIST_COLUMN_VISIBILITY));
        dispatch(sendProfileData(resource));
        dispatch(ListColumnWidthUpdateTemp());
    };
};

export const setProfileColumnVisibilityAndIndex = (resource, columns) => {
    return dispatch => {
        dispatch(storeProfileColumn(resource, columns, authenticationActionTypes.LIST_COLUMN_VISIBILITY_AND_INDEX));
        dispatch(sendProfileData(resource));
        dispatch(ListColumnWidthUpdateTemp());
    };
};

export const setProfileFilterVisibilityAndIndex = (resource, columns) => {
    return dispatch => {
        dispatch(storeProfileColumn(resource, columns, authenticationActionTypes.LIST_FILTER_VISIBILITY_AND_INDEX));
        dispatch(sendProfileData(resource));
        dispatch(ListColumnWidthUpdateTemp());
    };
};

export const setProfileColumnWidth = (resource, columns) => {
    return {
        type: authenticationActionTypes.LIST_COLUMN_WIDTH_TEMP,
        data: {
            resource: resource,
            columns: columns
        }
    };
};

const setListColumnWidthFromTemp = () => {
    return {
        type: authenticationActionTypes.LIST_COLUMN_WIDTH_FROM_TEMP
    }
};

export const saveProfileColumnWidth = (resource, columns) => {
    return dispatch => {
        dispatch(setProfileColumnWidth(resource, columns));
        dispatch(setListColumnWidthFromTemp());
        dispatch(storeProfileColumn(resource, columns, authenticationActionTypes.LIST_COLUMN_WIDTH));
        dispatch(sendProfileData(resource));
    };
};

const setScreenSwap = (resource, swap) => {
    return {
        type: authenticationActionTypes.SCREEN_SWAP,
        data: {
            resource: resource,
            swap: swap
        }
    };
};

export const saveScreenSwap = (resource, swap) => {
    //debugger;
    return dispatch => {
        dispatch(setScreenSwap(resource, swap));
        dispatch(ListColumnWidthUpdateTemp());
        dispatch(sendProfileData(resource));
    };
};

const sendProfileData = (resource) => {
    return () => {
        //dispatch(storeProfileColumn(resource, columns, type));
        const st = store.getState().auth;
        const id = store.getState().auth.userId;
        if (st.profileSettings
            && st.profileSettings.screens
            && st.profileSettings.screens[resource]
            && (st.profileSettings.screens[resource].list || st.profileSettings.screens[resource].swap)) {
            const settingsRequest = {
                id: id,
                resource: resource,
                listData: st.profileSettings.screens[resource].list,
                swap: st.profileSettings.screens[resource].swap
            };
            const token = store.getState().auth.userToken;
            if (!token) {
                return;
            }
            const options = {
                headers: { 'Authorization': 'Bearer ' + token }
            };
            axios.put(ApiUrl.ListSettings,
                settingsRequest, options)
                .then(response => {
                    const data = response.data;
                    if (data.HasError) {
                        console.error(data.message);
                    }
                })
                .catch((err) => {
                    console.error(err);
                });
        }
    }
};
