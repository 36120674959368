import React, { useEffect } from 'react';
import Modal from 'react-modal';
import { connect } from "react-redux";
import { message } from 'antd';
import './index.css';
import { resetError } from '../../../../store/actions/accountReceivableActions/accountReceivableActions';
import Loading from "../../screen/loading";
import { Defines } from '../../../../project/Defines';
import { localDate } from '../../../helper/common';

const ChangeRequestModal = ({ 
      setChangeRequestModalIsOpen,
      changeRequestModalIsOpen,
      hasError,
      actionMessage,
      changeRequestData,
      loading,
      resetError,
      resource,
    }) => {

  const closeModal = () => {
    setChangeRequestModalIsOpen(false);
  };

useEffect(() => {
  if (hasError) {
      message.error(actionMessage);
      resetError(resource);
  }
}, [hasError, actionMessage, resetError, resource]);

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      minWidth: '300px',
      minHeight: '180px',
      maxWidth: '550px',
      borderRadius: '8px',
      padding: loading ? '100px 0' : '6px 0',
    },
  };

  const headersList = ['Change by', 'Pal Old', 'Pal New', 'S Old', 'S New', 'LBS Old', 'LBS New', 'Price Old', 'Price New', 'Date'];

  return (
    <Modal
        isOpen={changeRequestModalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        ariaHideApp={false}
        >
        <div className='comment_modal__container'>
          {loading ? <Loading /> : <>
            <button onClick={closeModal} className='revision__x-button'>
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10 0C4.47 0 0 4.47 0 10C0 15.53 4.47 20 10 20C15.53 20 20 15.53 20 10C20 4.47 15.53 0 10 0ZM14.3 14.3C13.91 14.69 13.28 14.69 12.89 14.3L10 11.41L7.11 14.3C6.72 14.69 6.09 14.69 5.7 14.3C5.31 13.91 5.31 13.28 5.7 12.89L8.59 10L5.7 7.11C5.31 6.72 5.31 6.09 5.7 5.7C6.09 5.31 6.72 5.31 7.11 5.7L10 8.59L12.89 5.7C13.28 5.31 13.91 5.31 14.3 5.7C14.69 6.09 14.69 6.72 14.3 7.11L11.41 10L14.3 12.89C14.68 13.27 14.68 13.91 14.3 14.3Z" fill="#678482"/>
              </svg>
            </button>
            <div className='change-request__modal-text'>Changes</div>
            <div className='change-request-history-table'>
                <div className='change-request-history__header'>
                  {headersList.map(item => <div className='change-request__data'>{item}</div>)}
                </div>
                {changeRequestData?.map(data => {
                    return (
                      <div className='change-request-history__data'>
                        <div className='change-request__data change-request__data-change-by'>{data.updated}</div>
                        <div className='change-request__data change-request__data-old'>{data.fromData && data.field === 'pallet_count' ? parseFloat(data.fromData) : ''}</div>
                        <div className='change-request__data change-request__data-new'>{data.toData && data.field === 'pallet_count' ? parseFloat(data.toData) : ''}</div>
                        <div className='change-request__data change-request__data-old'>{data.fromData && data.field === 'space' ? parseFloat(data.fromData) : ''}</div>
                        <div className='change-request__data change-request__data-new'>{data.toData && data.field === 'space' ? parseFloat(data.toData) : ''}</div>
                        <div className='change-request__data change-request__data-old'>{data.fromData && data.field === 'weight' ? parseFloat(data.fromData) : ''}</div>
                        <div className='change-request__data change-request__data-new'>{data.toData && data.field === 'weight' ? parseFloat(data.toData) : ''}</div>
                        <div className='change-request__data change-request__data-old'>{data.fromData && data.field === 'price' ? parseFloat(data.fromData) : ''}</div>
                        <div className='change-request__data change-request__data-new'>{data.toData && data.field === 'price' ? parseFloat(data.toData) : ''}</div>
                        <div className='change-request__data change-request__data-date'>{localDate(data.editDate, Defines.Format.PrintDateTime)}</div>
                    </div>
                    )
                })}
            </div>
          </>}
        </div>  
    </Modal>
  );
};

const mapStateToProps = state => ({
  state: state,
  changeRequestData: state.AccountReceivable.changeRequestData?.data,
  loading: state.AccountReceivable.loading,
  hasError: state.AccountReceivable.hasError,
  actionMessage: state.AccountReceivable.message,
});

const mapDispatchToProps = (dispatch) => ({
  resetError: resource => dispatch(resetError(resource)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangeRequestModal);
