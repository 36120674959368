import React, { useEffect, useCallback } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { Formik, Form } from 'formik';
import Select from 'react-select';
import * as Yup from 'yup';
import { Radio } from 'antd';
import { getCustomersList } from '../../../../store/actions/customers/customers';
import { setBatchType } from '../../../../store/actions/orders/orders';
import Loading from '../../screen/loading';
import './index.css';

const GroupOrdersModal = ({
  setGroupOrdersModalIsOpen,
  groupOrdersModalIsOpen,
  loading,
  getCustomersList,
  customersList,
  setGroupOrdersListModalIsOpen,
  setCreateGroupValues,
  setBatchType,
}) => {
  const resource = 'customer';

  const closeModal = () => {
    setGroupOrdersModalIsOpen(false);
  };
  
  useEffect(() => {
    getCustomersList(resource, 'customerkeyvaluewithdefloclist');
  }, [getCustomersList]);

  let submitAction = '';

  const customStyle = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      minWidth: '400px',
      minHeight: '326px',
      maxWidth: '600px',
      maxHeight: '326px',
      borderRadius: '8px',
      padding: loading ? '0 0 8px 0' : '0',
    },
  };

  const customStyles = {
    control: (base, state) => ({
      ...base,
      borderColor: state.isFocused ? 'lightGray' : 'lightGray',
      borderRadius: '8px',
      boxShadow: 'none',
      minHeight: '37px',
      width: '100%',
      cursor: 'notAllowed',
    }),
    menu: (base, state) => ({
      ...base,
      zIndex: '20',
      height: '200px',
      overflowY: 'scroll',
    }),
    menuList: () => ({
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      borderRadius: 'none!important',
    }),
    valueContainer: (base, state) => ({
      display: 'flex',
      paddingLeft: '5px',
      maxWidth: '224px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      alignItems: 'center',
    }),
    container: (base, state) => ({
      ...base,
      pointerEvents: 'auto',
    }),
    singleValue: (base, state) => ({
      maxWidth: '224px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      margin: '4px 0',
    }),
    options: (base, state) => ({
      padding: '4px 8px',
      cursor: 'pointer',
      borderRadius: 'unset',
    }),
  };

  const customersOptionsList = customersList?.map(item => {
    return { ...item, label: item.text };
  });

  customersOptionsList?.length && customersOptionsList[0].value !== '' && customersOptionsList.unshift({ value: '', text: '', label: '' });

  const getTextFromValues = useCallback(
    (value, list) => {
      return list?.length ? list?.filter(item => item.value === value)[0]?.text : [];
    },
    [],
  );

  const handleSubmitSave = values => {
    setCreateGroupValues(values);
    setGroupOrdersListModalIsOpen(true);
    setBatchType('orders', values.paymentMethod);
  };

  const validationSchema = Yup.object().shape({
    customer: Yup.string().required('Required'),
    paymentMethod: Yup.number().required('Required'),
  });

  if (loading) {
    return (
      <Modal isOpen={groupOrdersModalIsOpen} style={customStyle}>
        <div style={{ marginTop: '35% ' }}>
          <Loading />
        </div>
      </Modal>
    );
  }

  return (
    <Modal isOpen={groupOrdersModalIsOpen} onRequestClose={closeModal} style={customStyle} ariaHideApp={false}>
      <div className='comment_modal__container'>
        <div className='group_modal__title-container'>
          <div>Grouping Orders</div>
          <button onClick={closeModal} className='create-group-modal_x-btn'>X</button>
        </div>
        <Formik
          initialValues={{
            customer: '',
            paymentMethod: '',
          }}
          validationSchema={validationSchema}
          enableReinitialize={true}
          onSubmit={(values, { resetForm, setSubmitting, setFieldValue, errors }) => {
            setTimeout(() => {
              setSubmitting(false);
              handleSubmitSave(values, submitAction);
            }, 400);
          }}>
          {({ isSubmitting, isValid, values, setFieldValue, errors, touched, handleSubmit }) => {
            return (
              <Form style={{ width: '86%' }}>
                <div className='modal_formik_container'>
                  <div className='formik_form__load-details'>
                    <div className='load-efs__select-container'>
                      <Select
                        options={customersOptionsList}
                        name='customer'
                        value={ getTextFromValues(values.customer, customersOptionsList) ? 
                              { label: getTextFromValues(values.customer, customersOptionsList) } : ''}
                        className={`${touched.customer && errors.customer ? 'dropdown_error' : ''} `}
                        onChange={option => setFieldValue('customer', option.value)}
                        styles={customStyles}
                        placeholder="Select Customer"
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                      />
                    </div>

                    <div className='create-group__radio-container'>
                      <div className='group-batch__payment-method__title'>Choose batch type</div>
                      <Radio.Group name='radiogroup' className={`${touched.paymentMethod && errors.paymentMethod ? 'dropdown_error' : ''} `}>
                        <Radio name='paymentMethod' buttonSolidCheckedActiveBg="blue" value={2} onChange={e => setFieldValue('paymentMethod', e.target.value)}>
                          Soft Batch
                        </Radio>
                        <Radio name='paymentMethod' value={1} onChange={e => setFieldValue('paymentMethod', e.target.value)}>
                          Hard Batch
                        </Radio>
                      </Radio.Group>
                    </div>
                  </div>

                  <div className='modal-form__load-details formik_form__load-details formik_button__container load-efs_btn__container create-group_btn__container'>
                    <button className='formik_btn__create-group create-group__cancel-btn' type='submit' onClick={closeModal}>
                      CANCEL
                    </button>
                    <button className='formik_btn__create-group' type='submit' onClick={handleSubmit}>
                      CONTINUE
                    </button>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};

const mapStateToProps = state => ({
  state: state,
  customersList: state.customers.customersList,
  loading: state.customers.loading,
});

const mapDispatchToProps = dispatch => ({
  getCustomersList: (resource, endPoint) => dispatch(getCustomersList(resource, endPoint)),
  setBatchType: (resource, batchType) => dispatch(setBatchType(resource, batchType)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupOrdersModal);
