import React from "react";
import moment from 'moment';
import { setValue } from "../../../hook/data/useData";
import SheetCell from "../../custom/sheet/sheetCell";
import { ElementTypes } from '../../../config/element.config';
import { CloneObject } from '../../../helper/common'

import { Defines } from '../../../../project/Defines';

export default (props, data) => {
  const onChangeHandler = (event) => {
    changeRecordData(event.keyField, event.field, event.newData, event.column, event.nativeEvent.source);
  };

  const changeRecordData = (keyField, field, newValue, column, outSource) => {
    const newData = CloneObject(data);
    const item = newData.find(i => i[props.keyFieldName] === keyField).stopData;
    if (item) {
      setValue(item, field, {
        value: newValue,
        dependencies: column.Dependencies,
        outSource: outSource
      });
      props.dispatch({ type: props.type, value: newData });
    }
  };

  return data.map((stop, i) => {
    const locationData = stop.locationData;
    const stopData = stop.stopData;

    return (<React.Fragment key={`${stop.id}_${i}`}>
      <tr className="load_info">
        <td>
          <table>
            <tbody>
              <tr>
                <th>{locationData.name}</th>
              </tr>
              <tr>
                <td rowSpan="2">
                  <wrap left="">
                    <text>
                      {locationData.address1}
                      {locationData.address2 ? `, ${locationData.address2}` : locationData.address2}
                      {locationData.address3 ? `, ${locationData.address3}` : locationData.address3}
                      {/* TST Cold Storage - San Fernando, CA 13571 Vaughn St UNIT C San Fernando, CA, 91342 */}
                    </text>
                  </wrap>
                </td>
              </tr>
              {/* <tr></tr>
              <tr>
                <th>Shipper Notes:</th>
              </tr> */}
            </tbody>
          </table>
        </td>
        <td colSpan="2">
          <table className="vertical_top_table">
            <colgroup>
              <col width="80px" />
              <col width="100px" />
              <col width="130px" />
              <col />
            </colgroup>
            <tbody>
              <tr>
                <th>Date:</th>
                <td>
                  <wrap left="">
                    <text>{stopData.date ? moment.utc(stopData.date, Defines.Format.PrintDate).format(Defines.Format.PrintDate) : ''}</text>
                  </wrap>
                </td>
                <th>Purchase Orders #:</th>
                <td>
                  <wrap left="">
                    <text>{stopData.purchaseOrders}</text>
                  </wrap>
                </td>
              </tr>
              <tr>
                <th>Time:</th>
                <td>
                  <wrap left="">
                    <text>{stopData.date ? moment(stopData.time, Defines.Format.MomentTime).format(Defines.Format.InputTime) : ''}</text>
                  </wrap>
                </td>
                <th>Type:</th>
                <td>
                  <wrap left="">
                    <text>{stopData.type || '-'}</text>
                  </wrap>
                </td>
              </tr>
              <tr>
                <th>Pallets: </th>
                <td>
                  <wrap left="">
                    <text>{stopData.pallets}</text>
                  </wrap>
                </td>
                <th>Appointment:</th>
                <td>
                  <wrap left="">
                    <text>{stopData.hasAppointment}</text>
                  </wrap>
                </td>
              </tr>
              <tr>
                <th>LBS:</th>
                <td>
                  <wrap left="">
                    <text>{stopData.lbs}</text>
                  </wrap>
                </td>
                <th>Load Sequences</th>
                <td>
                  <wrap left="">
                    <text>{stopData.loadSequences}</text>
                  </wrap>
                </td>
              </tr>
              <tr>
                <th>Notes: </th>
                {/* <td colSpan="3">
                  <div padding=""> */}
                <SheetCell
                  field={'notes'}
                  dataField={'notes'}
                  column={{
                    Field: ElementTypes.Comment(),
                    Attributes: {
                      td: {
                        colSpan: 3
                      },
                      wrap: {
                        padding: ""
                      }
                    }
                  }}
                  data={stopData.notes}
                  keyFieldName={props.keyFieldName}
                  keyField={stop[props.keyFieldName]}
                  object={stop}
                  onChange={onChangeHandler}
                  messageDispatch={props.messageDispatch}
                  messages={props.messages}
                  dispatch={props.dispatch}
                  messageIdentityPrefix={props.messageIdentityPrefix}
                />
                {/* <textarea defaultValue={stopData.notes}></textarea> */}
                {/* </div>
                </td> */}
              </tr>
              {/* <tr>
                <td colSpan="3">
                  <wrap left="">
                    <text>USE GATE CODE #8888</text>
                  </wrap>
                </td>
              </tr> */}
            </tbody>
          </table>
        </td>
      </tr>
      {i < data.length - 1 &&
        <tr className="separator">
          <th></th>
        </tr>
      }
    </React.Fragment>);
  })
};