import React from 'react';
import { Route } from 'react-router-dom';
import CacheBuster from './CacheBuster';

const CacheBusterRoute = ({ children, rKey, path, component, exact }) => {
    return (
        <CacheBuster>
            {({ loadingApp, isLatestVersion, refreshCacheAndReload }) => {
                if (loadingApp) return null;
                if (!loadingApp && !isLatestVersion) {
                    refreshCacheAndReload();
                    //console.log(loadingApp, isLatestVersion);
                }
                return (
                    <Route key={rKey} path={path} component={component} exact={exact}>
                        {children}
                    </Route>
                );
            }}
        </CacheBuster>
    );
};

export default CacheBusterRoute;
