import React, { useState, forwardRef, useImperativeHandle, useRef } from 'react';
import { Table, message } from 'antd';

import axios from '../../../axios/axios';

import NewButton from '../../list/newButton';
import { Columns } from '../../../helper/list';
// import SectionGroup from '../../form/sectionGroup/sectionGroup';
import { CloneObject, CreateGuid } from '../../../helper/common';
import { ValidateSectionGroups } from '../../../helper/validation';
import { MappingSectionGroups } from '../../../mapping/mapping';
import { Action } from '../../../config';
import { useDeleteItem } from '../../../hook/action/delete';
import { useFormValidation } from '../../../hook/data/useFormValidation';

import AssignedOrders from '../../../components/project/load/assignedOrders';
import LoadOrders from '../../../components/project/receiving/loadOrders';
import { DefaultDataTypes } from '../../../hook/actions';

const Expandable = forwardRef((props, ref) => {

    const TruckOrders = props.config.Resource === 'ReceivingLoad' ? LoadOrders : AssignedOrders;
    const viewConfig = props.config.List.Defaults.View;
    const tableConfig = props.config.List.Defaults.Table;
    const keyField = props.keyFieldName ? props.keyFieldName : 'id';
    const defaultExpandedRowKeys = [];
    
    const [expandedRowKeys, setExpandedRowKeys] = useState(defaultExpandedRowKeys);
    const componentRefs = useRef({});

    const groupBy = props.groupBy;
    const groupType = props.groupType;
    const groupTypeValue = props.groupTypeValue;

    // const [selectedRows, setSelectedRows] = useState([]);

    const validate = useFormValidation(
        props.messageDispatch,
        props.data,
        MappingSectionGroups,
        ValidateSectionGroups,
        props.config.SectionGroups,
        componentRefs,
        props.messageIdentityPrefix,
        keyField,
        props.type,
        expandedRowKeys);

    const deleteType = Action.Delete;

    const dispatch = (action) => {
        const value = props.type === DefaultDataTypes.INIT ? 'data' : 'value';

        if (action.type === deleteType) {
            props.dispatch({ type: props.type, [value]: props.data.filter((i) => i[keyField] !== action.value[keyField]) });
        }
        else {
            const newData = CloneObject(props.data);
            const recordIndex = newData.findIndex(r => r[keyField] === action.value[keyField]);
            if (recordIndex === -1) {
                newData.push(action.value);
            }
            else {
                newData[recordIndex] = action.value;
            }
            props.dispatch({ type: props.type, [value]: newData });
        }
    };

    useImperativeHandle(ref, () => ({
        validate() {
            const [passValidation, newExpandedRowKeys] = validate();
            setExpandedRowKeys(newExpandedRowKeys);
            return passValidation;
        },
        addNew() {
            return onNewClickHandler();
        }
    }));

    
    const onExpandedRowsChangeHandler = (expandedRows) => {
        setExpandedRowKeys(expandedRows);
    };

    const onDelete = (data) => {
        if (typeof (props.onActionRecord) === 'function') {
            props.onActionRecord(Action.Delete, data.value.id, function (response) {
                if(response.hasError) {
                    message.error(response.message);
                    
                    return;
                }
                message.success(response.message);
            });
        }

        dispatch(data);
    };

    const onNewClickHandler = () => {
        const newRecord = {};
        newRecord[keyField] = CreateGuid();
        setNewFields(props.config, newRecord);
        const newExpandedRowKeys = [...expandedRowKeys, newRecord[keyField]];
        setExpandedRowKeys(newExpandedRowKeys);

        if (props.onActionRecord) {
            if (typeof (props.onActionRecord) === 'function') {
                props.onActionRecord(Action.Edit, 0, dispatch);
            }
        } else {
            dispatch({ type: newRecord[keyField], value: newRecord });
        }
    };

    const setNewFields = (container, newRecord) => {
        if (container.Schema) {
            for (const m in container.Schema) {
                newRecord[m] = container.Schema[m];
            }
        }

        if (container.Fields || container.Components) {
            if (container.Fields) {
                for (const f in container.Fields) {
                    if (container.Fields[f].useParent) {
                        continue;
                    }

                    newRecord[f] = container.Fields[f].hasOwnProperty('default') ? container.Fields[f].default : '';
                }
            }
            if (container.Components) {
                for (const c in container.Components) {
                    const comp = container.Components[c].Component();

                    if (comp.props && comp.props.columns) {
                        const newComponentRecord = {};
                        newComponentRecord[comp.props.keyFieldName] = CreateGuid();
                        newRecord[c] = newComponentRecord;

                        setNewFields(comp.props.columns, newComponentRecord);
                    }
                }
            }
        } else {
            if (Array.isArray(container) && container.length) {
                for (const c in container) {
                    if (container[c].SectionGroups || container[c].Sections) {
                        container[c].SectionGroups && setNewFields(container[c].SectionGroups, newRecord);
                        container[c].Sections && setNewFields(container[c].Sections, newRecord);
                    } else {
                        setNewFields(container[c], newRecord);
                    }
                }
            } else {
                if (container.SectionGroups || container.Sections) {
                    container.SectionGroups && setNewFields(container.SectionGroups, newRecord);
                    container.Sections && setNewFields(container.Sections, newRecord);
                } else {
                    if (!container.hasOwnProperty('Fields') && !container.hasOwnProperty('Components')) {
                        return;
                    }

                    setNewFields(container, newRecord);
                }
            }
        }
    };

    //const findField = (record, fieldName) => {
    //    if (typeof (record) !== 'object') {
    //        return;
    //    }

    //    if (record != null && record.hasOwnProperty(fieldName)) {
    //        return record;
    //    } else if (typeof (record) === 'object') {
    //        for (const r in record) {
    //            const field = findField(record[r], fieldName);

    //            if (field) {
    //                return field;
    //            }
    //        }
    //    } else {
    //        return record;
    //    }
    //};

    const handleCheck = (dropId, record, e) => {
        const selectedRowsCopy = [...props.selectedRows].filter(r => props.dragDropData[dropId].data.find(i => i.id === r.id) !== undefined);

        if (props.grouping && record[groupType] === groupTypeValue) {
            const group = selectedRowsCopy.filter(r => r[groupBy] === record[groupBy]);
            if (group.length) {
                selectedRowsCopy.splice(selectedRowsCopy.indexOf(group[0]), group.length);
            } else {
                selectedRowsCopy.push(...props.dragDropData[dropId].data.filter((r) => r[groupBy] === record[groupBy] && r[groupType] === groupTypeValue));
            }
        } else {
            const data = selectedRowsCopy.find(r => r.id === record.id);
            if (data) {
                selectedRowsCopy.splice(selectedRowsCopy.indexOf(data), 1);
            } else {
                selectedRowsCopy.push(record);
            }
        }

        props.setSelectedRows(selectedRowsCopy);
        props.setAvailableSelection(false);
    }

    const onAction = (data, action) => {
        switch (action) {
            case Action.Edit:
                if (props.onActionRecord) {
                    if (typeof (props.onActionRecord) === 'function') {
                        props.onActionRecord(Action.Edit, data.id, dispatch);
                    }
                }

                break;
            case Action.Delete:
                deleteDataItem(data[props.config.Master], { type: Action.Delete, value: data });
                break;
            case Action.Done:
                axios({
                    url: `${props.config.Resource}/completeload`,
                    method: 'PUT',
                    data: {
                        id: data.id
                    }
                })
                    .then((response) => {
                        if (response === null) {
                            return;
                        }

                        if (response.data.hasError) {
                            message.error(response.data.message);

                            return;
                        }

                        message.success(response.data.message);

                        const newSourceData = [...props.data];
                        const originalItem = newSourceData.find(i => i.id === response.data.id);
                        const index = newSourceData.indexOf(originalItem);
        
                        newSourceData.splice(index, 1);

                        dispatch({ type: Action.Delete, value: { [keyField]: response.data.id } });
                    });
                break;
            default:
                break;
        }
    };

    const deleteDataItem = useDeleteItem(props.config.Title, onDelete);

    if (!Array.isArray(props.data)) {
        return null;
    }
    props.data && props.data.forEach(d => props.config.List.expandedRowKeys.indexOf(d[keyField]) !== -1 && defaultExpandedRowKeys.push(d[keyField]));
    const columns = Columns(props.config, onAction, props.data, dispatch)[0];

    return (props.data && Array.isArray(props.data) ?
        <view
            light=''
            scroll={viewConfig.AllowNewButton && ''}
            auto_height={viewConfig.AutoHeight && ''}
            small={viewConfig.Small && ''}>
            <Table
                {...tableConfig.Style}
                components={null}
                {...keyField && { rowKey: keyField }}
                columns={columns}
                expandable={{
                    expandedRowRender: (record) => {
                        const config = CloneObject(props.OrderConfig);
                        config.Resource = `Load\\assignedorderlist\\${record.id}`;
                        const dropId = `truckOrders_${record.id}`;
                        return <TruckOrders
                            {...config}
                            OrderForm={props.OrderForm}
                            resource={props.resource}
                            dropId={dropId}
                            dropType={dropId}
                            dragDropData={props.dragDropData}
                            dispatchDropData={props.dispatchDropData}
                            initDragDropData={props.initDragDropData}
                            handleCheck={handleCheck.bind(this, dropId)}
                            selectedRows={props.selectedRows}
                            setSelectedRows={props.setSelectedRows}
                            setAvailableSelection={props.setAvailableSelection}
                            recordId={record.id}
                            selectable={props.selectable}
                            draggable={props.draggable}
                        />;
                        // const dispatchRecord = (action) => {
                        //     if (record[action.type] !== action.value) {
                        //         const newRecord = findField(record, action.type);
                        //         newRecord[action.type] = action.value;
                        //         if (action.dependencies) {
                        //             for (let i in action.dependencies) {
                        //                 newRecord[action.dependencies[i]] = '';
                        //             }
                        //         }
                        //         dispatch({ type: record[keyField], value: record });
                        //     }
                        // };
                        // const messageIdentityPrefix = props.messageIdentityPrefix == null ? `${props.type}.${index}` : `${props.messageIdentityPrefix}_${props.type}.${index}`;
                        // const recordData = CloneObject(record);
                        // recordData['_parent_'] = CloneTopObject(props.object);
                        // return props.config && props.config.SectionGroups.map((s, i) => <SectionGroup messageIdentityPrefix={messageIdentityPrefix} componentRefs={componentRefs} key={i} sections={s.Sections} components={s.Components} dispatch={dispatchRecord} messages={props.messages} messageDispatch={props.messageDispatch} data={recordData} object={props.object} />);
                    }
                }}
                expandedRowKeys={expandedRowKeys}
                onExpandedRowsChange={onExpandedRowsChangeHandler}
                dataSource={props.data}
            /><NewButton
                allowNewButton={viewConfig.AllowNewButton}
                onNewClick={onNewClickHandler}
            />
        </view> : null);
});

export default Expandable;