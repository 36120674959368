export const ERROR = 'ERROR';
export const ADD_CUSTOMER_ORDER_LOADING = 'ADD_CUSTOMER_ORDER_LOADING';
export const ADD_CUSTOMER_ORDER = 'ADD_CUSTOMER_ORDER';
export const GET_ALL_LOCATION_DOORS_LOADING = 'GET_ALL_LOCATION_DOORS_LOADING';
export const GET_ALL_LOCATION_DOORS = 'GET_ALL_LOCATION_DOORS';
export const GET_FAVORITE_LOCATION_DOORS_LOADING = 'GET_FAVORITE_LOCATION_DOORS_LOADING';
export const GET_FAVORITE_LOCATION_DOORS = 'GET_FAVORITE_LOCATION_DOORS';

export const getActionType = (recource, key) => {
    return CUSTOMER_ORDERS_BUILD[key]
};

export const CUSTOMER_ORDERS_BUILD = {
    ERROR: 'ERROR',
    ADD_CUSTOMER_ORDER_LOADING: 'ADD_CUSTOMER_ORDER_LOADING',
    ADD_CUSTOMER_ORDER: 'ADD_CUSTOMER_ORDER',
    GET_ALL_LOCATION_DOORS_LOADING: 'GET_ALL_LOCATION_DOORS_LOADING',
    GET_ALL_LOCATION_DOORS: 'GET_ALL_LOCATION_DOORS',
    GET_FAVORITE_LOCATION_DOORS_LOADING: 'GET_FAVORITE_LOCATION_DOORS_LOADING',
    GET_FAVORITE_LOCATION_DOORS: 'GET_FAVORITE_LOCATION_DOORS',
};