import { FACTORING_COMPANY_BUILD as actionTypes } from '../../actions/factoringCompany/factoringCompanyActionTypes';
import * as factoringCompany from './factoringCompanyReducer';

const defaultState = {
  headers: [
    {
      key: 'name',
      title: 'Name',
      width: 200,
      sortable: true,
    },
    {
      key: 'terms',
      title: 'Terms',
      width: 80,
    },
    {
      key: 'mailingAddress',
      title: 'Mailing Address',
      width: 200,
    },
    {
      key: 'additionalAddress',
      title: 'Additional Address',
      width: 200,
    },
    {
      key: 'city',
      title: 'City',
      width: 80,
    },
    {
      key: 'state',
      title: 'State',
      width: 80,
    },
    {
      key: 'zipCode',
      title: 'Zip Code',
      width: 80,
    },
    {
      key: 'email',
      title: 'Email',
      width: 200,
    },
    {
      key: 'phone',
      title: 'Phone',
      width: 100,
    },
    {
      key: 'notes',
      title: 'Notes',
      width: 250,
    },
  ],
  loading: false,
  hasError: false,
  messageText: '',
  factoringCompanyList: null,
  factoringCompanyDetails: null,
  sortType: 'asc',
  newFactoryAdded: false,
  factoryCompanyUpdated: false,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.SET_HEADER_WIDTH_FACTORING_COMPANY:
      return factoringCompany.setHeaderWidth(state, action.data.ind, action.data.width);
    case actionTypes.ERROR:
      return factoringCompany.setError(state, action.data);
    case actionTypes.RESET_ERROR:
      return factoringCompany.resetError(state);
    case actionTypes.RELOAD_ON_ERROR:
      return factoringCompany.getFactoringCompanyList(state, action.data);
    case actionTypes.GET_FACTORING_COMPANY_LIST_LOADING:
      return factoringCompany.setInLoading(state);
    case actionTypes.GET_FACTORING_COMPANY_LIST:
      return factoringCompany.getFactoringCompanyList(state, action.data);
    case actionTypes.SORT_FACTORING_COMPANY:
      return factoringCompany.sortBy(state, action.data);
    case actionTypes.GET_FACTORING_COMPANY_DETAILS_LOADING:
      return factoringCompany.setInLoading(state);
    case actionTypes.GET_FACTORING_COMPANY_DETAILS:
      return factoringCompany.getFactoringCompanyDetails(state, action.data);
    case actionTypes.DELETE_FACTORING_COMPANY_LOADING:
      return factoringCompany.setInLoading(state, action.data);
    case actionTypes.GET_STATES_LIST:
      return factoringCompany.getStatesList(state, action.data);
    case actionTypes.EMPTY_FACTORING_COMPANY_DETAILS:
      return factoringCompany.emptyFactoringCompanyDetails(state, action.data);
    case actionTypes.ADD_FACTORING_COMPANY_LOADING:
      return factoringCompany.setInLoading(state, action.data);
    case actionTypes.ADD_FACTORING_COMPANY:
      return factoringCompany.addFactoringCompanyDetails(state, action.data);
      case actionTypes.UPDATE_FACTORING_COMPANY:
        return factoringCompany.updateFactoringCompanyDetails(state, action.data);
    default:
      return state;
  }
};
