import React from 'react';

const ToolBar = props => (<toolbar none={props.isNone ? '' : null}>
    <wrap>
        <action right=''>
            {Object.keys(props.toolBar.Items).map((e, i) =>
                <React.Fragment key={i}>
                    {props.toolBar.Items[e].Button(
                        () => props.clicked(props.toolBar.Items[e].Action,
                            props.toolBar.Items[e].Value,
                            props.toolBar.Items[e].hasReference,
                            props.toolBar.Items[e].SubAction
                        ),
                        props.data)}
                </React.Fragment>)}
        </action>
    </wrap>
</toolbar>);

export default ToolBar;