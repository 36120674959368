export const getNotes = (state, response, hasError = false, message = '') => {
    const data = response.data || [];
    return {
        ...state, ...{
            notes: data.comments,
            loading: false,
            hasError,
            message,
            loadData: data,
        }    
    }
};

export const setNotes = (state, note) => {
    return {
        ...state, ...{
            notes: [...state.notes, note],
        }    
    }
};

export const setError = (state, err) => ({
    ...state,
    ...{
        hasError: true,
        message: 'Something went wrong, please try again',
    },
});

export const resetError = state => ({
    ...state,
    ...{
        hasError: false,
        message: '',
    }
});

export const setInLoading = state => ({
    ...state, ...{
        loading: true
    }
});
