import React from 'react';
import { Link } from 'react-router-dom';

import Scroll from '../../scroll/scroll';

const MainNavigation = props => {
    const userRole = props.userRole;
    const smallNavigation = userRole === 'Load Planning' || 'OTR' || 'TST Cold' || 'Booking' || 'BOOKIN';

    return (
        <nav launcher="" id="launcher">
            {props.managedMenu.length > 1 ?
                <Scroll>
                    <ul>
                        {props.managedMenu.map((item, i) => {
                            return (
                                <React.Fragment key={i}>
                                    <li
                                        key={i}
                                        className={`${item.text.toUpperCase() === 'ADMINISTRATION' ? 'sub-nav_elem' : ''} ${item.url.toLowerCase() === props.location || (item.detail && props.location.startsWith(item.detail))
                                            ? "selected" : ""}`}
                                        style={ { opacity: item.text.toUpperCase() === 'ADMINISTRATION' ? '70%' : '' } } >
                                        <Link to={item.to} onClick={props.closeAllPopups} >
                                            <text>{item.text}</text>
                                        </Link>
                                    </li>
                                    <separator className={'sub-menu__separator'} vertical=''></separator>
                                </React.Fragment>
                            )
                        })}
                    </ul>
                </Scroll>
                :
                <>{smallNavigation && <div className='role'>{userRole}</div>}</>
            }
        </nav>
    )
};

export default MainNavigation;