import { DownOutlined, UpOutlined} from '@ant-design/icons';
import React from 'react';
import { connect } from "react-redux";

const LeftActionCall = (
    {
        state,
        cellData,
        reacted,
        itemSize,
        onClickRow,
        bgColor,
        innerRef,
        type,
        resource,
    }) => {
    const loadArea = state[resource];
    const header = loadArea.headers.find(h => h.key === cellData.key);
    const width = header.width;
    let cellStyle = {
        maxWidth: `${width}px`,
        width: `${width}px`,
        minWidth: `${width}px`,
        height: `${itemSize}px`,
        position: 'sticky',
        display: 'flex',
        alignItems: 'center',
        fontSize: '17px',
        left: 0,
        backgroundColor: bgColor,
    };

    const cellStyleValue = {
        maxWidth: `${width}px`,
        width: `${width}px`,
        minWidth: `${width}px`,
        height: `${itemSize}px`,
        position: 'sticky',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: bgColor,
        left: 0
    };

    const arrowColor = type === 'stop' ? '#000000' : '#FFFFFF';

    return (
        <>
            {
                type === 'load' || type === 'stop' ?
                    <div ref={innerRef} onClick={() => { onClickRow() }}
                    className='table-body-cell'
                        style={cellStyle}>
                        {reacted ? <UpOutlined style={{ fontSize: '10px', color: arrowColor }} /> : <DownOutlined style={{ fontSize: '10px', color: arrowColor }} />}
                </div> :
                <div ref={innerRef}
                    className='table-body-cell'
                        style={cellStyleValue} onClick={() => { onClickRow() }}>
                        {cellData.value}
                </div>
            }
        </>
    
    );
};
export default connect(state => ({
    state: state
}))(LeftActionCall);