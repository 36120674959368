import React, { createRef } from 'react';

import axios from '../axios/axios';
import errorHandler from '../error/errorHandler';

export function AuthenticatedLink({ url, filename, children }) {
    const link = createRef()

    const handleAction = async (event) => {
        if (link.current.href && link.current.href !== encodeURI(`${event.currentTarget.baseURI}${filename}`))
        {
            setTimeout(() => {
                link.current.href = filename;
            }, 100);
            
            return;
        }
        axios.get(url, {
            responseType: 'blob',
            timeout: 30000,
        }).then(response => {
            new File([response.data], filename);
            const href = window.URL.createObjectURL(response.data);
            link.current.download = filename;
            link.current.href = href;
            link.current.click();
        });
        event.preventDefault();
    };
    return (<a role='button' href={filename} ref={link} onClick={handleAction}>{children}</a>);
};

export default errorHandler(AuthenticatedLink, axios);