import React from 'react';
import { Link, Switch, Route, Redirect } from 'react-router-dom';

import DragableTable from './dragableTable';
import Uploader from './uploader';
import UploaderTable from './uploaderTable';
import DragDrop from './dragDrop';
import ColorPicker from './colorPicker';
import { useClientHub } from '../hook/hub/useClientHub';

const Development = () => {
    useClientHub(message => {
        console.log(message);
    }, 'notification');

    return (

        <div className="tab_strip">
            <wrap>
                <ul>
                    <li effect="material"><Link to='/development/dragable-table'><text>Dragable Table</text></Link></li>
                    <li effect="material"><Link to='/development/uploader'><text>Uploader</text></Link></li>
                    <li effect="material"><Link to='/development/uploader-table'><text>Uploader Table</text></Link></li>
                    <li effect="material"><Link to='/development/drag-drop'><text>New Draggable</text></Link></li>
                    <li effect="material"><Link to='/development/color-picker'><text>Color Picker</text></Link></li>
                </ul>
            </wrap>
            <div>
                <view>
                    <Switch>
                        <Route exact path='/development' >
                            <Redirect to='/development/dragable-table' />
                        </Route>
                        <Route path='/development/dragable-table' component={DragableTable} />
                        <Route path='/development/uploader' component={Uploader} />
                        <Route path='/development/uploader-table' component={UploaderTable} />
                        <Route path='/development/drag-drop' component={DragDrop} />
                        <Route path='/development/color-picker' component={ColorPicker} />
                    </Switch>
                </view>
            </div>
        </div>);
};

export default Development;