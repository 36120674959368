import React from 'react';

import { Divider } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

const DropDownNewItem = props => <React.Fragment>
    {props.menu}
    <Divider style={{ margin: '4px 0' }} />
    <span
        style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer', backgroundColor: '#029b96', color: '#fff' }}
        onClick={props.onAddClickHandler}>
        <PlusOutlined /> {props.title}
    </span>
</React.Fragment>;

export default DropDownNewItem;