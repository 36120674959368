import React from 'react';
import { connect } from "react-redux";
import Split from "react-split-it";
import LastOrdersSpreadsheet from '../CustomerLastOrders/LastOrdersSpreadsheet';
import RejectedOrdersSpreadsheet from '../CustomerRejectedOrders/RejectedOrdersSpreadsheet';
import { getContentWidth } from '../../../../../src/store/reducers/customerRejectedOrders/helper/general';

const CustomerOrdersLastAndRejected = ({ state, loadingRejectedOrders, loadingLastOrders }) => {
    const resource = 'customerRejectedOrders';
    const headers = state[resource].headers;
    const width = getContentWidth(headers);
    const innerWidth = window.innerWidth;
    const smallScreen = width < innerWidth;

    const content = (
        <div className={`splitter custom-scrollBar spreadsheet__margins ${smallScreen ? 'small-spreedsheet__center__rejected-orders' : '' }`}>
            <Split
                sizes={[51, 49]}
                direction="vertical"
                gutterSize={(loadingRejectedOrders && loadingLastOrders) ? 0: 10}>
                <RejectedOrdersSpreadsheet />
                <LastOrdersSpreadsheet />
            </Split>
        </div>
    );
    return content;
};

const mapStateToProps = state => ({
    state: state,
    loadingRejectedOrders: state.customerRejectedOrders.loadingRejectedOrders,
    loadingLastOrders: state.customerLastOrders.loading,
});

export default connect(mapStateToProps)(CustomerOrdersLastAndRejected);