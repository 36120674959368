import {
    ElementTypes,
    ComponentTypes,
    Ability
} from '../../config';
import { GenerateToolBars } from '../../config/element.config';


const LocationForm = {
    Title: 'Location',
    Resource: 'Location',
    Key: 'id',
    Master: 'locationName',
    IsRest: true,
    Form: {
        SectionGroups: [
            {
                Sections: [
                    {
                        classes: 'wide',
                        Fields: {
                            Group1: [
                                {
                                    useSeparator: false,
                                    Fields: {
                                        locationTypeStr: {
                                            Title: 'Location Type',
                                            Field: ElementTypes.LabelField()
                                        },
                                        dateChange: {
                                            Title: 'Date Change Needed',
                                            Field: ElementTypes.Generic(ElementTypes.LabelField, {
                                                display: (o, data) => data === 1 ? 'No' : data === 2 ? 'Yes' : data
                                            })
                                        }
                                    }
                                }
                            ],
                            locationName: {
                                Title: 'Location',
                                Field: ElementTypes.LabelField()
                            },
                            formattedAddress: {
                                Title: 'Address',
                                Field: ElementTypes.LabelField()
                            },
                            additionalAddress: {
                                Title: 'Additional Address',
                                Field: ElementTypes.LabelField()
                            },
                            Group2: [
                                {
                                    useSeparator: false,
                                    Fields: {
                                        city: {
                                            Title: 'City',
                                            Field: ElementTypes.LabelField()
                                        },
                                        zipCode: {
                                            Title: 'Zip Code',
                                            Field: ElementTypes.LabelField()
                                        },
                                        stateCode: {
                                            Title: 'State',
                                            Field: ElementTypes.LabelField()
                                        },
                                        countryName: {
                                            Title: 'Country',
                                            Field: ElementTypes.LabelField()
                                        }
                                    }
                                }
                            ],
                            Group3: [
                                {
                                    useSeparator: false,
                                    Fields: {
                                        contactType: {
                                            Title: 'Contact Type',
                                            Field: ElementTypes.LabelField()
                                        },
                                        contact: {
                                            Title: 'Contact Name',
                                            Field: ElementTypes.LabelField()
                                        },
                                        phone: {
                                            Title: 'Phone',
                                            Field: ElementTypes.Phone()
                                        },
                                        email: {
                                            Title: 'Email',
                                            Field: ElementTypes.LabelField()
                                        },
                                    }
                                }
                            ],
                            Group4: [
                                {
                                    Title: 'Another Contact 1',
                                    Fields: {
                                        contact2: {
                                            Title: 'Contact Name',
                                            Field: ElementTypes.LabelField()
                                        },
                                        phone2: {
                                            Title: 'Phone',
                                            Field: ElementTypes.Phone()
                                        },
                                        email2: {
                                            Title: 'Email',
                                            Field: ElementTypes.LabelField()
                                        }
                                    }
                                }
                            ],
                            Group5: [
                                {
                                    Title: 'Another Contact 2',
                                    Fields: {
                                        contact3: {
                                            Title: 'Contact Name',
                                            Field: ElementTypes.LabelField(),
                                        },
                                        phone3: {
                                            Title: 'Phone',
                                            Field: ElementTypes.Phone()
                                        },
                                        email3: {
                                            Title: 'Email',
                                            Field: ElementTypes.LabelField()
                                        }
                                    }
                                }
                            ],
                            notes: {
                                Title: 'Notes',
                                Field: ElementTypes.CommentLabel()
                            }
                        }
                    },
                    {
                        classes: 'wide',
                        Fields: {
                            Group1: [
                                {
                                    useSeparator: false,
                                    Fields: {
                                        site: {
                                            Title: 'Site',
                                            Field: ElementTypes.CommentLabel()
                                        },
                                        locationLogin: {
                                            Title: 'Login',
                                            Field: ElementTypes.CommentLabel()
                                        },
                                        locationPassword: {
                                            Title: 'Password',
                                            Field: ElementTypes.CommentLabel()
                                        }
                                    }
                                }
                            ],
                            Group2: [
                                {
                                    useSeparator: false,
                                    Fields: {
                                        lumper: {
                                            Title: 'Lumper',
                                            Field: ElementTypes.Generic(ElementTypes.LabelField, {
                                                display: (o, data) => data === 1 ? 'No' : data === 2 ? 'Yes' : data
                                            })
                                        },
                                        overnightParking: {
                                            Title: 'Overnight Parking',
                                            Field: ElementTypes.Generic(ElementTypes.LabelField, {
                                                display: (o, data) => data === 1 ? 'No' : data === 2 ? 'Yes' : data
                                            })
                                        }
                                    }
                                }
                            ],
                            map: {
                                Title: 'Map',
                                Field: ElementTypes.MapField(Ability.Displayability, () => false),
                            },
                            Components: {
                                workingDays: {
                                    Component: ComponentTypes.ManagedSheet('name', {
                                        Title: 'Hours',
                                        List: {
                                            Columns: {
                                                name: {
                                                    Title: 'Day',
                                                    Field: ElementTypes.TableLabelField(),
                                                    Attributes: {
                                                        wrap: {
                                                            left: ''
                                                        }
                                                    }
                                                },
                                                working: {
                                                    Title: 'Work',
                                                    Field: ElementTypes.CheckBoxModern(null, null, Ability.Displayability, () => false)
                                                },
                                                from: {
                                                    Title: 'From',
                                                    Field: ElementTypes.TableLabelField((o) => o.working),
                                                    Width: 236
                                                },
                                                to: {
                                                    Title: 'To',
                                                    Field: ElementTypes.TableLabelField((o) => o.working),
                                                    Width: 236
                                                },
                                                comment: {
                                                    Title: 'Comment',
                                                    Field: ElementTypes.TableLabelField(),
                                                    Width: 236
                                                }
                                            }
                                        }
                                    })
                                }
                            },
                            hours: {
                                Title: 'Hours Comment',
                                Field: ElementTypes.CommentLabel()
                            }
                        }
                    }
                ]
            }
        ],
        ToolBars: GenerateToolBars(['Close'])
    }
};

export default LocationForm