import { CUSTOMER_ORDERS_BUILD as actionTypes } from '../../actions/customerOrderLocations/customerOrderLocationsActionTypes';
import * as customerOrder from './customerOrderLocationsReducer';

const defaultState = {
  favoriteDoorsLocationsLoading: false,
  hasError: false,
  message: '',
  total: 0,
  existing: 0,
  favoriteLocationsDoorsList: null,
  allLocationsDoorsList: null,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.GET_FAVORITE_LOCATION_DOORS_LOADING:
      return customerOrder.setIsLoadingFavoriteDoorsList(state);
    case actionTypes.GET_FAVORITE_LOCATION_DOORS:
      return customerOrder.getFavoriteLocationDoorList(state, action.data);
    case actionTypes.GET_ALL_LOCATION_DOORS_LOADING:
      return customerOrder.setIsLoadingAllDoorsList(state);
    case actionTypes.GET_ALL_LOCATION_DOORS:
      return customerOrder.getAllLocationDoorList(state, action.data);
    default:
      return state;
  }
};
