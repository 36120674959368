import { Defines } from './Defines';
import { AppPages } from './Pages';
import store from '../store';

/**
 *a global helper object with utility functions
 */
export const Project = {

    encode: str => {
        let buf = [];

        for (var i = str.length - 1; i >= 0; i--) {
            buf.unshift(['%#', str[i].charCodeAt(), ';'].join(''));
        }

        return buf.join('');
    },

    decode: str => {
        return str.replace(/%#(\d+);/g, function (match, dec) {
            return String.fromCharCode(dec);
        });
    },

    getManagedPageUrl: function (url, parentUrl, routeParam, quaryParams) {
        parentUrl = parentUrl ? '/' + parentUrl : '';
        url = parentUrl + '/' + url;
        if (routeParam) {
            url += '/' + routeParam;
        }
        if (quaryParams) {
            var p = [];
            for (var key in quaryParams) {
                p.push(key + "=" + quaryParams[key]);
            };
            url += "?" + p.join("&");
        }
        return url;
    },

    getPageUrl: function (page, routeParam, quaryParams) {
        const pages = this.getPages();
        switch (page.toLowerCase()) {
            case AppPages.Home:
                return this.getManagedPageUrl("", null, routeParam, quaryParams);
            case AppPages.Login:
            case AppPages.Logout:
            case AppPages.Template:
                return this.getManagedPageUrl(page, null, routeParam, quaryParams);
            default:
                const parentPage = pages[page] ? pages[page].parent : null;
                return this.getManagedPageUrl(page, parentPage, routeParam, quaryParams);
        }
    },

    getPages: function () {
        const menu = store.getState().auth.userMenu;
        const pages = {};
        for (let index in menu) {
            const item = menu[index];
            if (!item.menu_item) {
                continue;
            }
            if (item.items) {
                for (let subIndex in item.items) {
                    const subItem = item.items[subIndex];
                    if (!subItem.menu_item) {
                        continue;
                    }
                    pages[subItem.screen.toLowerCase()] = {
                        parent: item.screen.toLowerCase()
                    };
                    if (subItem.has_details) {
                        pages[(subItem.screen + Defines.DetailsComponentSufix).toLowerCase()] = {
                            parent: item.screen.toLowerCase()
                        };
                    }
                }
            }
            else {
                pages[item.screen.toLowerCase()] = {
                    parent: null
                };
                if (item.has_details) {
                    pages[(item.screen + Defines.DetailsComponentSufix).toLowerCase()] = {
                        parent: null
                    };
                }
            }
        }
        return pages;
    },

    skipHeader: function (location) {
        const skipping_pages = [AppPages.Login, AppPages.Logout, AppPages.Forgot, AppPages.ChangePassword, AppPages.Template];
        return skipping_pages.some(sp => location.includes(sp));
    },

    createRoute: function (items, routeList, parentUrl) {
        routeList = routeList ? routeList : [];

        for (let index in items) {
            const item = items[index];
            //if (!item.menu_item) {
            //    continue;
            //}
            if (item.items && item.items.length > 0) {
                this.createRoute(item.items, routeList, item.screen);
            }
            else {
                this.createItem(item, routeList, parentUrl);
            }
        }

        return routeList;
    },

    createItem: function (item, routeList, parentUrl) {
        const url = Project.getManagedPageUrl(item.screen, parentUrl);
        const screen = item.screen;
        
        routeList.push({
            path: item.has_details ? url : url + '/:id',
            screen: screen,
            has_grid_list: true
        });

        if (item.has_details) {
            routeList.push({
                path: url + Defines.DetailsPathSufix,
                screen: item.screen,
                has_grid_list: false
            });
        }
    },

    createMenu: function (items, parentUrl) {
        const menuList = [];
        for (let index in items) {
            const item = items[index];
            if (!item.menu_item) {
                continue;
            }
            let url = item.screen.toLowerCase();
            let detail = (item.screen + Defines.DetailsComponentSufix).toLowerCase();
            let pUrl = parentUrl;
            if (!pUrl && item.items && item.items.length > 0) {
                let itemIndex = 0;
                if (item.screen === 'AR' && item.items.length > 1) {
                    itemIndex = 1;
                }
                url = item.items[itemIndex].screen.toLowerCase();
                pUrl = item.screen.toLowerCase();
                detail = (item.items[itemIndex].screen + Defines.DetailsComponentSufix).toLowerCase();
            }
            menuList.push(
                {
                    to: Project.getManagedPageUrl(url, pUrl),
                    text: item.name,
                    parentUrl: pUrl,
                    detail: Project.getManagedPageUrl(detail, pUrl),
                    sub: item.items,
                    url: '/' + item.screen.toLowerCase()
                });
        }
        return menuList;
    }
};