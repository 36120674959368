import { useEffect, useState } from 'react';
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';

import store from '../../../store';

export const useClientHub = (onNotificationReceive, event) => {
    const [connection, setConnection] = useState(null);
    
    useEffect(() => {
        const token = store.getState().auth.userToken;
        if (!connection) {
            const newConnection = new HubConnectionBuilder()
                .withUrl(`/TrafficNotificationHub`, {
                    accessTokenFactory: () => token
                })
                .configureLogging(LogLevel.Information)
                .withAutomaticReconnect()
                .build();
            setConnection(newConnection);
        }
    }, [connection]);

    useEffect(() => {
        let isMounted = true;
        const fetchConnection = async () => {
            if (isMounted && connection) {
                if (connection.connectionStarted) {
                    connection.off(event);
                    connection.on(event, message => {
                        if (isMounted) {
                            onNotificationReceive(message);
                        }
                    });
                } else if (connection.connectionState === 'Disconnected') {
                    connection.start()
                        .then(() => {
                            console.log('Client HUB for TrafficControl Connected!');
                            connection.on(event, message => {
                                if (isMounted) {
                                    onNotificationReceive(message);
                                }
                            });
                        })
                        .catch(e => console.error('Client HUB Connection failed: ', e));
                }
            }
        }
        fetchConnection();
        return () => {
            isMounted = false;
        };
    }, [connection, onNotificationReceive, event]);

    return null;
};
