import { LOAD_DETAILS_BUILD as actionTypes } from '../../actions/loadDetails/loadDetailsActionTypes';
import * as loadArea from './loadDetailsReducer';

const defaultState = {
    loading: true,
    hasError: false,
    message: '',
    loadDetails: null,
    carrierList: null,
    trucksList: null,
    trailersList: null,
    driversList: null,
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case actionTypes.LOAD_DETAILS_LOADING:
            return loadArea.setInLoading(state);
        case actionTypes.GET_LOAD_DETAILS:
            return loadArea.getLoadDetails(state, action.data);
        case actionTypes.RELOAD_ON_ERROR_LOAD_DETAILS:
            return loadArea.getLoadDetails(state, action.data.responce, true, action.data.errorText ? action.data.errorText : 'Something go wrong, data reloaded!');
        case actionTypes.LOAD_DETAILS_ERROR:
            return loadArea.setError(state, action.data);
        case actionTypes.RESET_ERROR:
            return loadArea.resetError(state);
        case actionTypes.SET_LOAD_DETAILS:
            return loadArea.setLoadDetails(state, action.data.value, action.data.rowState, action.data.header);
        case actionTypes.GET_CARRIER_LIST:
            return loadArea.getCarrierList(state, action.data);
        case actionTypes.GET_TRUCKS_LIST:
            return loadArea.getTrucksList(state, action.data);
        case actionTypes.GET_TRAILERS_LIST:
            return loadArea.getTrailersList(state, action.data);
        case actionTypes.GET_DRIVERS_LIST:
            return loadArea.getDriversList(state, action.data);
        case actionTypes.CLEAR_LOAD_DETAILS_DATA:
            return loadArea.clearLoadDetailsData(state, action.data);
        case actionTypes.EMPTY_EFS_LOADING:
            return loadArea.setEFSLoading(state);
        case actionTypes.GET_EMPTY_EFS:
            return loadArea.getEmptyEFS(state, action.data);
        case actionTypes.EFS_LIST_LOADING:
            return loadArea.setEFSLoading(state);
        case actionTypes.GET_EFS:
            return loadArea.getEFS(state, action.data);
        case actionTypes.GET_EFS_LOADING:
            return loadArea.setEFSLoading(state);
        case actionTypes.GET_EFS_LIST:
            return loadArea.getEFSList(state, action.data);
        case actionTypes.SET_EFS_LOADING:
            return loadArea.setEFSLoading(state);
        case actionTypes.SET_EFS:
            return loadArea.setEFS(state, action.data);
        case actionTypes.UPDATE_EFS_LOADING:
            return loadArea.setEFSLoading(state);
        case actionTypes.UPDATE_EFS:
            return loadArea.updateEFS(state, action.data);
        case actionTypes.UPDATE_EFS_ERROR:
            return loadArea.setError(state, action.data);
        case actionTypes.EMPTY_EFS_ERROR:
            return loadArea.setError(state, action.data);
        case actionTypes.GET_EFS_ERROR:
            return loadArea.setError(state, action.data);
        case actionTypes.EFS_LIST_ERROR:
            return loadArea.setError(state, action.data);
        case actionTypes.SET_EFS_ERROR:
            return loadArea.setError(state, action.data);
        default:
            return state;
    }
};