import axios from 'axios';
import moment from 'moment';
import store from '../..';
import * as actionTypes from './deliveredTrucksActionTypes';

const buildUrl = (resource, method = '') => `/api/${resource}/${method}`;

const axiosConfig = () => ({
    headers: {
        'Authorization': 'Bearer ' + store.getState().auth.userToken
    }
});

export const load = (resource, method, loadingType) => dispatch => {
    if (loadingType !== 'withoutLoading') {
        dispatch({ type: actionTypes.getActionType(resource, actionTypes.GET_DELIVERED_TRUCKS_LOADING) });
    }
    axios.get(buildUrl(resource, method),
        axiosConfig())
        .then(responce => dispatch({ type: actionTypes.getActionType(resource, actionTypes.GET_DELIVERED_TRUCKS), data: responce }))
        .catch((err) => {
            console.error(err);
            console.log(err.response);
            dispatch({ type: actionTypes.getActionType(resource, actionTypes.DELIVERED_TRUCKS_ERROR) });
        })
};

export const reactOnAssignedItem = (resource, id, type) =>
    ({ type: actionTypes.getActionType(resource, actionTypes.REACTED_ASSIGNED_DELIVERED_TRUCKS), data: { id, type } });

export const setHeaderWidth = (resource, ind, width) =>
    ({ type: actionTypes.getActionType(resource, actionTypes.SET_HEADER_WIDTH_DELIVERED_TRUCKS), data: { ind, width } });

export const paidButtonAction = (resource, method, rowState) => dispatch =>{
    axios.put(buildUrl(resource, `${method}?loadId=${rowState.loadId}`), '',
        axiosConfig())
        .then(response => {
            if (response.data.hasError) {
                dispatch(errorAction(resource, true));
                console.error(response.data.message);
            } else {
                dispatch(load(resource, 'readytopay'))
            }
        }).catch(err => {
        dispatch(errorAction(resource, true));
        console.error(err);
        console.log(err.response);
    });
}
export const setItemData = (resource, value, rowState, header, loadsAssignedOrders) => dispatch => {
    dispatch({
        type: actionTypes.getActionType(resource, actionTypes.SET_DELIVERED_TRUCKS_DATA), data: {
            value,
            rowState,
            header
        }
    });
    
    let requestData = {};
    let subOrderRequestData;
    let orderStatusByCall;
    let orderNotesByCall;
    let loadCallCheckNote;
    let podRequestStatus;
    let callCheckAttachment;
    let browserDateTime = moment.utc(new Date()).local().utc(true).format();

    const order =  rowState.orderData;
    if (rowState.loadData.loadType === 3) {
        orderStatusByCall = order.outOrderStatusByCall;
        orderNotesByCall = order?.outNotes;
        podRequestStatus = order?.outPodRequestStatus || 0;
        callCheckAttachment = order.callCheckOutAttachment;
        loadCallCheckNote = order.outLoadCallCheckNote;
    } else if (rowState.loadData.loadType === 1) {
        orderStatusByCall = order.inOrderStatusByCall;
        orderNotesByCall = order.inNotes;
        podRequestStatus = order?.inPodRequestStatus;
        callCheckAttachment = order.callCheckInAttachment;
        loadCallCheckNote = order.inLoadCallCheckNote;
    }
    requestData = {
        orderId: rowState.isSub ? order.orderId : order.id,
        loadId: rowState.loadData?.id,
        subOrderId: rowState.isSub ? order.id : '',
        orderStatusByCall,
        orderNotesByCall,
        podRequestStatus,
        loadCallCheckNote,
        callCheckAttachment,
        browserDateTime,
        poNumber: rowState.poNumber,
    }

    if (header.key === 'orderStatusByCall') {
        requestData.orderStatusByCall = value;
        requestData.orderDelayedReasonByCall = null;
    } else if (header.key === 'orderNotesByCall') {
        requestData.orderNotesByCall = value;
    } else if (header.key === 'podRequestStatus') {
        if (rowState.orderData?.subOrderId) {
            requestData.outPodRequestStatus = value;
        }
        if (value === 3 || 
            (rowState.loadData.loadType === 1 && ((value === 0 || value === 2) && (rowState?.orderData?.inPodRequestStatus === 1 || rowState?.orderData?.inPodRequestStatus === 3))) ||
            (rowState.loadData.loadType === 3 && ((value === 0 || value === 2) && (rowState?.orderData?.outPodRequestStatus === 1 || rowState?.orderData?.outPodRequestStatus === 3)))) {
            requestData.callCheckAttachment = null;
            requestData.callCheckOutAttachment = null;
            requestData.callCheckInAttachment = null;
        }
        requestData.podRequestStatus = value;
    } else if (header.key === 'podDoc') {
        requestData.callCheckAttachment = value;
    } else if (header.key === 'loadCallCheckNote') {
        requestData.loadCallCheckNote = value;
    } else {
        requestData[header.key] = value;
    }

    if (rowState.orderData?.subOrderId) {
        subOrderRequestData = {
            ...requestData,
            subOrderId: order.subOrderId,
            id: order.subOrderId,
        };

    }
    const finalRequestData = subOrderRequestData ? subOrderRequestData : requestData;

    axios.put(buildUrl('AccountPayable', 'loadorderitem'), finalRequestData,
        axiosConfig())
        .then(response => {
            if (response.data.hasError) {
                dispatch(errorAction(resource, true, response.data.message));
                console.error(response.data.message);
            } else if ((response.data.callCheckInAttachment || response.data.callCheckOutAttachment) && header.key === 'podDoc') {
                dispatch({ type: actionTypes.getActionType(resource, actionTypes.SET_DELIVERED_TRUCKS_DATA), data: {
                        value:  rowState.loadData.loadType === 1 ? response.data.callCheckInAttachment :
                            rowState.loadData.loadType === 3 ? response.data.callCheckOutAttachment : '',
                        rowState,
                        header
                    }
                })
            }
        }).catch(err => {
        dispatch(errorAction(resource, true));
        console.error(err);
        console.log(err.response);
    });
    
};

export const setItemDisplayState = (resource, rowState, header, enable) => ({
    type: actionTypes.getActionType(resource, actionTypes.SET_DISPLAY_STATE_DELIVERED_TRUCKS), data: {
        rowState,
        header,
        enable
    }
});

export const filterDeliveredTrucks = (resource, key) => ({
    type: actionTypes.getActionType(resource, actionTypes.FILTER_DELIVERED_TRUCKS),
    data: key
});
export const sortBy = (resource, sortValue) => dispatch => {
    dispatch({
        type: actionTypes.getActionType(resource, actionTypes.SORT_DELIVERED_TRUCKS), data: sortValue
    });
}

export const getHistoryListDeliveredTrucks = (resource, url, orderId, field, isSub) => dispatch => {
    dispatch({ type: actionTypes.getActionType(resource, actionTypes.GET_HISTORY_DELIVERED_TRUCKS_LOADING) });
    const data = {
        params: {
            filterData: isSub ? {
                SubOrderId: orderId,
                field: field,
            } : 
            {
                orderId: orderId,
                field: field,
            },
        },
        headers: axiosConfig().headers,
    };
    axios.get(buildUrl(url), data, axiosConfig())
        .then(response => dispatch({ type: actionTypes.getActionType(resource, actionTypes.GET_HISTORY_DELIVERED_TRUCKS), data: response }))
        .catch((err) => {
            console.error(err);
            console.log(err.response);
            dispatch({ type: actionTypes.getActionType(resource, actionTypes.DELIVERED_TRUCKS_ERROR) });
        });
};


export const resetError = resource =>
    ({ type: actionTypes.getActionType(resource, actionTypes.RESET_ERROR) });

const errorAction = (resource, reload, method) => dispatch => {
    if (reload) {
        axios.get(buildUrl(resource, method),
            axiosConfig()
        )
            .then(responce => dispatch({ type: actionTypes.getActionType(resource, actionTypes.RELOAD_ON_ERROR_DELIVERED_TRUCKS), data: responce }))
            .catch((err) => {
                console.error(err);
                console.log(err.response);
                dispatch({ type: actionTypes.getActionType(resource, actionTypes.DELIVERED_TRUCKS_ERROR) });
            });
    }
};

const reloadAfterUpdate = (resource) => dispatch => {
    axios.get(buildUrl(resource, 'area'),
        axiosConfig()
    )
        .then(responce => dispatch({ type: actionTypes.getActionType(resource, actionTypes.GET_DELIVERED_TRUCKS), data: responce }))
        .catch((err) => {
            console.error(err);
            console.log(err.response);
            dispatch({ type: actionTypes.getActionType(resource, actionTypes.DELIVERED_TRUCKS_ERROR) });
        });
};