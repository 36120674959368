/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useCallback } from 'react';
import { Tooltip, message, notification } from 'antd';

import { GridContext } from '../list';
import axios from '../../../axios/axios';
import { CommonLabels } from '../../../../project/Defines';
import GSheetModal from './GSheetModal';

const ImportFromGSheet = ({ refreshData }) => {
    const data = useContext(GridContext);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [api, contextHolder] = notification.useNotification();

    const openNotification = (message) => {
        api.open({
            message: 'Import Info',
            description: message,
            duration: 0,
        });
    };

    const toggleModal = useCallback((isOpen) => setIsOpenModal(isOpen))

    const importFromGsheet = (sheetName) => {
        setIsLoading(true);
        axios.post(`${data.screen}/importfromgsheet?sheetName=${sheetName}`).then(result => {
            setIsLoading(false)
            if (result.data?.hasError) {
                message.error(result.data.message, 5);
                return;
            }
            setIsOpenModal(false);
            //message.success(result.data.message, 5);
            openNotification(result.data.message);
            refreshData(true)
        });
    };

    return (
        <>
            {contextHolder}
            <Tooltip title={CommonLabels.Buttons.Import}>
                <div className='button accent' onClick={() => toggleModal(true)}><text>{CommonLabels.Buttons.Import}</text></div>
            </Tooltip>
            {isOpenModal ? <GSheetModal
                isOpen={isOpenModal}
                isLoading={isLoading}
                onSubmit={importFromGsheet}
                onCloseModal={() => toggleModal(false)}
            /> : null}
        </>
    )


};

export default ImportFromGSheet;
