import axios from 'axios';
import store from '../..';
import * as actionTypes from './customerOrderAdminActionTypes';

const buildUrl = (resource = 'customerOrderAdmin') => `/api/${resource}`;
const buildPutUrl = (resource = 'customerOrderAdmin', endPoint) => `/api/${resource}/${endPoint}`;

const axiosConfig = () => ({
  headers: {
    Authorization: 'Bearer ' + store.getState().auth.userToken,
  },
});

export const getCustomerOrderAdmin = (resource, loadingType) => dispatch => {
  if (loadingType !== 'withoutLoading') {
    dispatch({ type: actionTypes.getActionType(resource, actionTypes.CUSTOMER_ORDER_ADMIN_LOADING) });
  }
  axios
    .get(buildUrl(resource), axiosConfig())
    .then(responce => dispatch({ type: actionTypes.getActionType(resource, actionTypes.GET_CUSTOMER_ORDER_ADMIN), data: responce }))
    .catch(err => {
      console.error(err);
      console.log(err.response);
      dispatch({ type: actionTypes.getActionType(resource, actionTypes.ERROR) });
    });
};

export const generateCustomerOrder = (resource, endPoint, data) => dispatch => {
  dispatch({ type: actionTypes.getActionType(resource, actionTypes.GENERATE_CUSTOMER_ORDER_LOADING) });
  axios
    .put(buildPutUrl(resource, endPoint), data, axiosConfig())
    .then(responce => dispatch({ type: actionTypes.getActionType(resource, actionTypes.GENERATE_CUSTOMER_ORDER), data: responce }))
    .catch(err => {
      console.error(err);
      console.log(err.response);
      dispatch({ type: actionTypes.getActionType(resource, actionTypes.ERROR), data: err });
    });
};

export const rejectCustomerOrder = (resource, endPoint, data) => dispatch => {
  dispatch({ type: actionTypes.getActionType(resource, actionTypes.REJECT_CUSTOMER_ORDER_LOADING) });
  axios
    .put(buildPutUrl(resource, endPoint), data, axiosConfig())
    .then(responce => dispatch({ type: actionTypes.getActionType(resource, actionTypes.REJECT_CUSTOMER_ORDER), data: responce }))
    .catch(err => {
      console.error(err);
      console.log(err.response);
      dispatch({ type: actionTypes.getActionType(resource, actionTypes.ERROR), data: err });
    });
};

export const filterByCustomer = (resource, customerId) => dispatch => {
  dispatch({
      type: actionTypes.getActionType(resource, actionTypes.FILTER_BY_CUSTOMER), data: customerId
  });
}

export const sortBy = (resource, sortValue) => dispatch => {
  dispatch({
      type: actionTypes.getActionType(resource, actionTypes.SORT_ORDERS_ADMIN), data: sortValue
  });
}

export const setHeaderWidth = (resource, ind, width) => ({
  type: actionTypes.getActionType(resource, actionTypes.SET_HEADER_WIDTH_ORDER_ADMIN),
  data: { ind, width },
});

export const setItemDisplayState = (resource, rowState, header, enable) => ({
  type: actionTypes.getActionType(resource, actionTypes.SET_DISPLAY_STATE),
  data: {
    rowState,
    header,
    enable,
  },
});

export const resetError = resource => ({ type: actionTypes.getActionType(resource, actionTypes.RESET_ERROR) });

export const setNewRequestOrdersCount = (resource, count) => dispatch => {
  dispatch({
      type: actionTypes.getActionType(resource, actionTypes.NEW_REQUEST_ORDERS_COUNT), data: count
  });
}
