import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import Select from 'react-select';
import InputMask from 'react-input-mask';
import moment from 'moment';
import { message, Upload, Checkbox } from 'antd';
import axios from '../../../axios/axios';
import { ApiUrl } from '../../../../project/Pages';
import store from '../../../../store';
import Download from '../../../elements/download';
import Loading from '../../screen/loading';
import {
  getLoadDetails,
  setLoadDetails,
  getCarrierList,
  getTrucksList,
  getTrailersList,
  getDriversList,
  clearLoadDetailsData,
  getEmptyEFS,
  getEFSList,
  getEFS,
  resetError,
} from '../../../../store/actions/loadDetails/loadDetails';
import { getCarierRateConfirmation } from '../../../../store/actions/rateConfirmation/rateConfirmation';
import { Defines, bytesToSize } from '../../../../project/Defines';
import { load } from '../../../../store/actions/loadArea/loadArea';
import EFSModal from './EFSModal';
import UploadAttachmentEditModal from './UploadAttachmentEditModal';
import WarningModal from './modalWarning';
import RateConfirmationModal from './RateConfirmationModal';
import { customStyleLoadDetailsPage, customStylesLoadDetailsPage } from './styles';

const LoadDetailsModal = ({
  isDetailsModalOpen,
  setDetailsModalToOpen,
  loadId,
  getLoadDetails,
  loadDetails,
  loading,
  setLoadDetails,
  getCarrierList,
  carrierList,
  trucksList,
  trailersList,
  driversList,
  getTrucksList,
  getTrailersList,
  getDriversList,
  clearLoadDetailsData,
  getEmptyEFS,
  EFSData,
  EFSLoading,
  EFSList,
  getEFSList,
  getEFS,
  hasError,
  actionMessage,
  resetError,
  resource,
  load,
  getCarierRateConfirmation,
  rateConfirmationMessage,
}) => {
  const closeModal = () => {
    setDetailsModalToOpen(false);
    clearLoadDetailsData(resource);
    load(resource);
    setIsUploadsListChanged(false);
  };

  useEffect(() => {
    if (hasError) {
      message.error(actionMessage);
      resetError(resource);
    }
  }, [hasError, actionMessage, resetError, resource]);

  useEffect(() => {
    if (rateConfirmationMessage) {
      message.success(rateConfirmationMessage, 3);
      resetError(resource);
    }
  }, [rateConfirmationMessage, resetError, resource]);

  let submitAction = '';
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [carrierSelected, setCarrierSelected] = useState(false);
  const [EFSModalIsOpen, setEFSModalIsOpen] = useState(false);
  const [warningModalIsOpen, setWarningModalIsOpen] = useState(false);
  const [isEditMode, setEditMode] = useState(false);
  const [attachmentsList, setAttachmentsList] = useState([]);
  const [isUploadsListChanged, setIsUploadsListChanged] = useState(false);
  const [isEFSListChanged, setEFSListChanged] = useState(false);
  const [attachmentEditModalIsOpen, setAttachmentEditModalIsOpen] = useState(false);
  const [attachment, setAttachment] = useState('');
  const [rateModalIsOpen, setRateModalIsOpen] = useState(false);
  const [additionalPayReason, setAdditionalPayReason] = useState(loadDetails?.additionalPay);

  useEffect(() => {
    setAttachmentsList(loadDetails?.attachments);
  }, [setAttachmentsList, loadDetails]);

  useEffect(() => {
    setAdditionalPayReason(loadDetails?.additionalPay);
  }, [loadDetails]);

  const [fileCont, setFileCont] = useState(null);
  const max = 5;
  const restrictions = file => {
    const isLt = file.size / 1024 / 1024 < max;
    if (!isLt) {
      message.error(`Image must be smaller than ${max}MB!`);
      return false;
    }

    const extensions = ['jpeg', 'jpg', 'png', 'heif', 'pdf'];
    const dot = file.name.lastIndexOf('.') + 1;
    const fileExtension = file.name.substr(dot, file.name.length).toLowerCase();
    if (!extensions.includes(fileExtension)) {
      message.error(`Image must be in jpeg, png, pdf or heif format!`);
      return false;
    }
    return true;
  };

  const beforeUpload = file => {
    if (!restrictions(file)) {
      return false;
    }
    setFileCont(file);
    return true;
  };

  const handleUpload = () => {
    const formData = new FormData();
    formData.append('file', fileCont);
    axios.post(ApiUrl.Upload, formData, { headers: { 'Content-Type': 'multipart/form-data' } }).then(response => {
      if (response && response.data) {
        const result = response.data;
        if (result.hasError) {
          message.error(result.message || 'File not uploaded!');
          return;
        }
        onChange(result);
      }
    });
  };

  const onChange = result => {
    const mDate = moment(new Date());
    const attachment = {
      hasError: result.hasError,
      message: result.message,
      guid: result.guId,
      fileName: result.originalName,
      fileType: result.fileType,
      size: result.size,
      originalFileName: result.originalName,
      fullName: `${store.getState().auth.userFirstName} ${store.getState().auth.userLastName}`,
      createDate: mDate.format(),
      isNew: true,
      comments: '',
      deleted: false,
    };
    attachmentsList.push(attachment);
    setAttachmentsList(attachmentsList);
    setIsUploadsListChanged(true);
    setTimeout(() => {
      getEFSList('LoadEFS', loadId); // for updating images list
    }, 2000);
  };

  const removeAttachment = id => {
    const removableAttachment = attachmentsList.filter(item => item.id === id)[0];
    const attachment = {
      ...removableAttachment,
      deleted: true,
    };
    const updatedList = attachmentsList.filter(item => item.id !== id);
    updatedList.push(attachment);
    setAttachmentsList(updatedList);
    setIsUploadsListChanged(true);
    setTimeout(() => {
      getEFSList('LoadEFS', loadId); // for updating images list
    }, 2000);
  };

  const editAttachment = attach => {
    setAttachmentEditModalIsOpen(true);
    setAttachment(attach);
    setIsUploadsListChanged(true);
  };

  useEffect(() => {
    getLoadDetails(resource, loadId);
  }, [getLoadDetails, loadId, resource]);

  useEffect(() => {
    getCarierRateConfirmation('CarrierRateConfirmation', loadDetails?.carrierId);
  }, [getCarierRateConfirmation, loadDetails]);

  useEffect(() => {
    getEFSList('LoadEFS', loadId);
    if (loadDetails && (carrierSelected || loadDetails?.carrierId)) {
      getCarrierList('carrier', carrierSelected || loadDetails?.carrierId);
      getTrucksList('truck', carrierSelected || loadDetails?.carrierId);
      getTrailersList('trailer', carrierSelected || loadDetails?.carrierId);
      getDriversList('driver', carrierSelected || loadDetails?.carrierId);
    } else if (loadDetails && !loadDetails.carrierId) {
        getCarrierList('carrier', null);
    }
  }, [carrierSelected, getCarrierList, getDriversList, getEFSList, getTrailersList, getTrucksList, loadDetails, loadId]);

  const carrierListWithoutExpiredCarriers = carrierList?.filter(carrier => !carrier.isPolicyExpired);
  const carrierOptionsList = carrierListWithoutExpiredCarriers?.map(item => {
    return { ...item, label: item.text };
  });
  const trucksOptionsList = trucksList?.map(item => {
    return { ...item, label: item.text };
  });
  const trailersOptionsList = trailersList?.map(item => {
    return { ...item, label: item.text };
  });
  const driversOptionsList = driversList?.map(item => {
    return { ...item, label: item.text };
  });

  const additionalPay = [
    { label: 'Detention', text: 'Detention', value: 'Detention' },
    { label: 'Late fee', text: 'Late fee', value: 'Late fee' },
    { label: 'Recovery', text: 'Recovery', value: 'Recovery' },
    { label: 'Deduction', text: 'Deduction', value: 'Deduction' },
    { label: 'Other', text: 'Other', value: 'Other' },
  ];

  carrierOptionsList?.length && carrierOptionsList[0].value !== '' && carrierOptionsList.unshift({ value: '', text: '', label: '' });
  trucksOptionsList?.length && trucksOptionsList[0].value !== '' && trucksOptionsList.unshift({ value: '', text: '', label: '' });
  trailersOptionsList?.length && trailersOptionsList[0].value !== '' && trailersOptionsList.unshift({ value: '', text: '', label: '' });
  driversOptionsList?.length && driversOptionsList[0].value !== '' && driversOptionsList.unshift({ value: '', text: '', label: '' });

  const getTextFromValues = useCallback((value, list) => {
    return list?.length ? list?.filter(item => item.value === value)[0]?.text : [];
  }, []);

  const handleEFSOpen = (editMode, efsId) => {
    if (editMode === 'editMode') {
      setEditMode(true);
      getEFS('loadEFS', efsId);
    } else {
      setEditMode(false);
      getEmptyEFS('loadEFS');
    }
    setEFSModalIsOpen(true);
    setEFSListChanged(true);
  };

  const getColorClass = status => {
    if (status === 1) {
      return 'efs-status-color__requested';
    }
    if (status === 2) {
      return 'efs-status-color__issued';
    }
    if (status === 3) {
      return 'efs-status-color__rejected';
    }
    if (status === 4) {
      return 'efs-status-color__sent';
    } else {
      return 'efs-status-color__rejected';
    }
  };

  const rateIsGenerated = loadDetails?.attachments?.filter(attachment => attachment.fileName.includes('Rate Confirmation_')).length > 0;

  const validationSchema = Yup.object().shape({
    rate: Yup.number().positive().min(0).required(),
    amount: additionalPayReason === 'Deduction' ? Yup.number().integer().nullable() : Yup.number().integer().positive().nullable(),
    additionalPay: Yup.string().nullable(),
    target: Yup.number().positive().min(0).required(),
    notes: Yup.string().nullable(),
    carrierId: Yup.number().nullable(),
    truckId: Yup.number().nullable(),
    trailerId: Yup.number().nullable(),
    trailerSize: Yup.string().nullable(),
    trailerTypeName: Yup.string().nullable(),
    driverId: Yup.number().nullable(),
    driverPhone: Yup.string().nullable(),
    rateConfirmed: Yup.boolean(),
    carrierInformed: Yup.boolean(),
  });

  const handleSetFieldValue = (value, setFieldValue, setFieldTouched) => {
    setFieldValue('additionalPay', value);
    setAdditionalPayReason(value);
    setTimeout(() => setFieldTouched('additionalPay', true));
    setTimeout(() => setFieldTouched('amount', true));
  };

  const handleSubmitSave = (values, submitAction) => {
    const updatedLoadDetails = {
      ...loadDetails,
      rate: values.rate,
      target: values.target,
      amount: values.amount,
      additionalPay: values.additionalPay,
      notes: values.notes,
      carrierId: values.carrierId,
      truckId: values.truckId,
      trailerId: values.trailerId,
      trailerSize: values.trailerSize,
      trailerTypeName: values.trailerTypeName,
      driverId: values.driverId,
      driverPhone: values.driverPhone,
      attachments: attachmentsList,
      rateConfirmed: values.rateConfirmed,
      carrierInformed: values.carrierInformed,
    };
    setLoadDetails(resource, updatedLoadDetails, loadId);
    if (submitAction === 'second') {
      load(resource);
    }
    setButtonDisabled(false);
    setIsUploadsListChanged(false);
  };

  if (loading) {
    return (
      <Modal isOpen={isDetailsModalOpen} style={customStylesLoadDetailsPage}>
        <div style={{ marginTop: '30% ' }}>
          <Loading />
        </div>
      </Modal>
    );
  }

  return (
    <Modal isOpen={isDetailsModalOpen} onRequestClose={closeModal} style={customStyleLoadDetailsPage} ariaHideApp={false}>
      <div className='details_modal__container'>
        <div className='modal_header'>
          <div className='details_modal_header'>
            Load {loadDetails?.loadNumber}
            <button onClick={closeModal} className='modal_x-btn'>
              X
            </button>
          </div>
        </div>
        <Formik
          initialValues={{
            rate: loadDetails?.rate,
            target: loadDetails?.target,
            amount: loadDetails?.amount,
            additionalPay: loadDetails?.additionalPay,
            notes: '',
            carrierId: loadDetails?.carrierId,
            truckId: loadDetails?.truckId,
            trailerId: loadDetails?.trailerId,
            trailerSize: loadDetails?.trailerSize,
            trailerTypeName: loadDetails?.trailerTypeName,
            driverId: loadDetails?.driverId,
            driverPhone: loadDetails?.driverPhone,
            rateConfirmed: loadDetails?.rateConfirmed,
            carrierInformed: loadDetails?.carrierInformed,
          }}
          validationSchema={validationSchema}
          enableReinitialize={true}
          onSubmit={(values, { resetForm, setSubmitting, setFieldValue, errors }) => {
            if (submitAction === 'rate') {
              setRateModalIsOpen(true);
              setSubmitting(false);
            } else {
              setButtonDisabled(true);
              setTimeout(() => {
                setSubmitting(false);
                if (
                  loadDetails.rate === values.rate &&
                  loadDetails.target === values.target &&
                  loadDetails.amount === values.amount &&
                  loadDetails.additionalPay === values.additionalPay &&
                  loadDetails.carrierId === values.carrierId &&
                  loadDetails.truckId === values.truckId &&
                  loadDetails.trailerId === values.trailerId &&
                  loadDetails.driverId === values.driverId &&
                  loadDetails.driverPhone === values.driverPhone &&
                  loadDetails.notes === values.notes &&
                  loadDetails.rateConfirmed === values.rateConfirmed &&
                  loadDetails.carrierInformed === values.carrierInformed &&
                  !isUploadsListChanged &&
                  !isEFSListChanged
                ) {
                  setWarningModalIsOpen(true);
                  setButtonDisabled(false);
                } else {
                  handleSubmitSave(values, submitAction);
                  if (submitAction === 'second') {
                    closeModal();
                  }
                }
              }, 400);
            }
          }}>
          {({ isSubmitting, isValid, values, setFieldValue, setFieldTouched, errors, touched, handleSubmit }) => {
            return (
              <Form>
                <div className='modal_formik_container'>
                  <div className='modal-form__load-details'>
                    <div className='formik_form__load-details modal_formik_one_column'>
                      <div className='load-details__title_container'>
                        <div className='load-details__title-text'>Load Number</div>
                        <div className='load-details__title-number'>{loadDetails?.loadNumber}</div>
                      </div>
                      <div className='load-details__rate-and-target'>
                        <div className='load-details__input-container' style={touched.rate && errors.rate && { border: '1px solid red' }}>
                          <div className='load-details__data_placeholder'>{values.rate || values.rate > -1 ? 'Rate' : ''}</div>
                          <span style={{ marginLeft: '8px' }}>
                            {values.rate || values?.rate > -1 ? '$' : ''}
                            <Field
                              name='rate'
                              placeholder={(loadDetails?.rate > -1 && values.rate > -1) ? '' : 'Rate'}
                              className={`load-details__input ${(values.rate || values.rate > -1) && 'load-details__placeholder-padding'} `}
                            />
                          </span>
                        </div>
                        <div className='load-details__input-container' style={touched.target && errors.target && { border: '1px solid red' }}>
                          <div className='load-details__data_placeholder'>{values.target || values.target > -1 ? 'Target' : ''}</div>
                          <span style={{ marginLeft: '8px' }}>
                            {values.target || values.target > -1 ? '$' : ''}
                            <Field
                              name='target'
                              placeholder={loadDetails?.target > -1 && values.target > -1 ? '' : 'Target'}
                              className={`load-details__input ${(values.target || values.target > -1) && 'load-details__placeholder-padding'} `}
                            />
                          </span>
                        </div>
                      </div>
                      <div className='load-details__additional-pay'>
                        <div className='load-details__input-container' style={touched.amount && errors.amount && { border: '1px solid red' }}>
                          <div className='load-details__data_placeholder'>{values.amount ? 'Adjustment' : ''}</div>
                          <span style={{ marginLeft: '8px' }}>
                            {values.amount ? '$' : ''}
                            <Field
                              name='amount'
                              placeholder={loadDetails?.amount && values.amount ? '' : 'Adjustment'}
                              className={`load-details__input ${values.amount && 'load-details__placeholder-padding'} `}
                            />
                          </span>
                        </div>
                        <div className='load-details__select-container__additional-pay'>
                          <div className='load-details__select_title__additional-pay'>Adjustment reason</div>
                          <Select
                            options={additionalPay}
                            name='additionalPay'
                            value={{ label: getTextFromValues(values.additionalPay, additionalPay) }}
                            className={`${touched.additionalPay && errors.additionalPay ? 'dropdown_error' : ''} `}
                            onChange={option => handleSetFieldValue(option.value, setFieldValue, setFieldTouched)}
                            styles={customStylesLoadDetailsPage}
                            components={{
                              IndicatorSeparator: () => null,
                            }}
                          />
                        </div>
                      </div>
                      <div className='formik_form__load-details modal_formik_one_column load-details__notes'>
                        <Field
                          as='textarea'
                          name='notes'
                          placeholder='Note'
                          style={touched.notes && errors.notes && { border: '1px solid red' }}
                          className='load-details__textarea'
                        />
                      </div>
                    </div>
                    <div className='formik_form__load-details formik_form__load-details-select modal_formik_one_column'>
                      <div className='load-details__select-container'>
                        <div className='load-details__select_title'>Carrier</div>
                        <Select
                          options={carrierOptionsList}
                          name='carrierId'
                          value={{ label: getTextFromValues(values.carrierId, carrierOptionsList) }}
                          className={`${touched.carrierId && errors.carrierId ? 'dropdown_error' : ''} `}
                          onChange={option => {
                            setFieldValue('carrierId', option.value);
                            setCarrierSelected(option.value);
                            if (option.value === '') {
                              setFieldValue('truckId', '');
                              setFieldValue('trailerId', '');
                              setFieldValue('driverId', '');
                              setFieldValue('trailerSize', '');
                              setFieldValue('trailerTypeName', '');
                              setFieldValue('driverPhone', '');
                            }
                            if (option.isPolicyExpirationApproaching) {
                              message.error(`${option.text} carrier policies expiring soon. Ensure updates`, 4)
                            }
                          }}
                          styles={customStylesLoadDetailsPage}
                          components={{
                            IndicatorSeparator: () => null,
                          }}
                        />
                      </div>
                      <div className='load-details__select-container'>
                        <div className='load-details__select_title'>Truck</div>
                        <Select
                          options={trucksOptionsList}
                          name='truckId'
                          value={{ label: getTextFromValues(values.truckId, trucksOptionsList) }}
                          className={`${touched.truckId && errors.truckId ? 'dropdown_error' : ''} ${!values.carrierId && 'disabled'}`}
                          onChange={option => setFieldValue('truckId', option.value)}
                          styles={customStylesLoadDetailsPage}
                          components={{
                            IndicatorSeparator: () => null,
                          }}
                          isDisabled={!values.carrierId}
                        />
                      </div>
                      <div className='load-details__select-container'>
                        <div className='load-details__select_title'>Trailer</div>
                        <Select
                          options={trailersOptionsList}
                          name='trailerId'
                          value={{ label: getTextFromValues(values.trailerId, trailersOptionsList) }}
                          className={`${touched.trailerId && errors.trailerId ? 'dropdown_error' : ''} ${!values.carrierId && 'disabled'} `}
                          onChange={option => {
                            setFieldValue('trailerId', option.value);
                            setFieldValue('trailerSize', option.trailerSize);
                            setFieldValue('trailerTypeName', option.trailerTypeName);
                            if (option.value === '') {
                              setFieldValue('trailerSize', '');
                              setFieldValue('trailerTypeName', '');
                            }
                          }}
                          styles={customStylesLoadDetailsPage}
                          components={{
                            IndicatorSeparator: () => null,
                          }}
                          isDisabled={!values.carrierId}
                        />
                      </div>
                      <div className='trailer-details_title'>Trailer</div>
                      <div className='load-details__rate-and-target'>
                        <div className={`load-details__input-container load-details__type-size ${!values.carrierId && 'disabled'} `}>
                          <div className='load-details__data_placeholder'>{values.trailerTypeName ? 'Type' : ''}</div>
                          <Field
                            name='trailerTypeName'
                            placeholder={loadDetails?.trailerTypeName && values.trailerTypeName ? '' : 'Type'}
                            className={`load-details__input-trailer ${values.trailerTypeName && 'load-details__placeholder-padding'} `}
                            disabled
                          />
                        </div>
                        <div className={`load-details__input-container load-details__type-size ${!values.carrierId && 'disabled'} `}>
                          <div className='load-details__data_placeholder'>{values.trailerSize ? 'Size' : ''}</div>
                          <Field
                            name='trailerSize'
                            placeholder={loadDetails?.trailerSize && values.trailerSize ? '' : 'Size'}
                            className={`load-details__input-trailer ${values.trailerSize && 'load-details__placeholder-padding'} `}
                            disabled
                          />
                        </div>
                      </div>
                      <div className={`load-details__signed-rate__container`}>
                        <Field
                          name='rateConfirmed'
                          type='checkbox'
                          onChange={e => setFieldValue('rateConfirmed', e.target.checked)}
                          component={Checkbox}
                          checked={values.rateConfirmed}>
                          <div className='load-details__signed-rate__text'>Signed "Rate Confirmation"</div>
                        </Field>
                      </div>
                      <div className={`load-details__signed-rate__container`}>
                        <Field
                          name='carrierInformed'
                          type='checkbox'
                          onChange={e => setFieldValue('carrierInformed', e.target.checked)}
                          component={Checkbox}
                          checked={values.carrierInformed}>
                          <div className='load-details__signed-rate__text'>The carrier has been informed about the changes</div>
                        </Field>
                      </div>
                    </div>
                    <div className='formik_form__load-details formik_form__load-details-select modal_formik_one_column'>
                      <div className='load-details__select-container'>
                        <div className='load-details__select_title_driver'>Driver</div>
                        <Select
                          options={driversOptionsList}
                          name='driverId'
                          value={{ label: getTextFromValues(values.driverId, driversOptionsList) }}
                          className={`${touched.driverId && errors.driverId ? 'dropdown_error' : ''} ${!values.carrierId && 'disabled'} `}
                          onChange={option => {
                            setFieldValue('driverId', option.value);
                            option.value && setFieldValue('driverPhone', option.driverPhone);
                            option.value === '' && setFieldValue('driverPhone', '');
                          }}
                          styles={customStylesLoadDetailsPage}
                          components={{
                            IndicatorSeparator: () => null,
                          }}
                          isDisabled={!values.carrierId}
                        />
                      </div>

                      <div className='load-details__rate-and-target'>
                        <div className='load-details__input-container load-details__phone-number'>
                          <div className='load-details__data_placeholder'>{values.driverPhone ? 'Driver Phone' : ''}</div>
                          <InputMask
                            mask='999-999-9999'
                            maskChar={' '}
                            name={'driverPhone'}
                            country='US'
                            defaultCountry='US'
                            type='text'
                            value={values.driverPhone}
                            placeholder={loadDetails?.driverPhone && values.driverPhone ? '' : 'Driver Phone'}
                            style={{ background: 'transparent' }}
                            onChange={e => setFieldValue('driverPhone', e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{ background: '#F2F2F2' }}>
                    <div className='modal-form__load-details formik_form__load-details formik_button__container-efc-table'>
                      <table className='note-modal__table note_modal__container'>
                        <tr className='note-modal__tr note-modal__tr-th'>
                          <th className='note-modal__th'>Date Time</th>
                          <th className='note-modal__th'>Created By</th>
                          <th className='note-modal__th'>Note</th>
                        </tr>
                        {loadDetails?.comments?.length ? (
                          loadDetails.comments.map(note => (
                            <tr className='note-modal__tr' key={note.id}>
                              <td className='note-modal__td'>{moment.utc(note.createDate).utcOffset(4).format('MM/DD/YY HH:mm')}</td>
                              <td className='note-modal__td'>{note.user}</td>
                              <td className='note-modal__td'>{note.comment}</td>
                            </tr>
                          ))
                        ) : (
                          <tr className='note-modal__tr' style={{ height: '24px' }}>
                            <td className='note-modal__td'></td>
                            <td className='note-modal__td'></td>
                            <td className='note-modal__td'></td>
                          </tr>
                        )}
                      </table>
                    </div>
                    <div className='modal-form__load-details formik_form__load-details formik_button__container-efc-table-efs'>
                      <div
                        className='formik_btn formik_btn__order-details__save'
                        disabled={!isValid || isSubmitting || buttonDisabled}
                        onClick={handleEFSOpen}>
                        Add EFS
                      </div>
                      <button
                        className='formik_btn formik_btn__order-details__save-close generate-efs__btn'
                        disabled={!isValid || isSubmitting || buttonDisabled || !loadDetails?.carrierId || !loadDetails?.rate}
                        onClick={() => {
                          submitAction = 'rate';
                        }}>
                        {rateIsGenerated ? 'EDIT RATE CONFIRMATION' : 'GENERATE RATE CONFIRMATION'}
                      </button>
                      <div
                        className='formik_btn formik_btn__order-details__save-close load-details__refresh-btn'
                        onClick={() => getEFSList('LoadEFS', loadId)}>
                        REFRESH
                      </div>
                    </div>

                    <div className='modal-form__load-details formik_form__load-details formik_button__container-efc-table'>
                      <table className='note-modal__table note_modal__container'>
                        <tr className='note-modal__tr note-modal__tr-th'>
                          <th className='note-modal__th'>Payment method</th>
                          <th className='note-modal__th'>Amount</th>
                          <th className='note-modal__th'>Card Number</th>
                          <th className='note-modal__th'>Note</th>
                          <th className='note-modal__th'>EFS Status</th>
                          <th className='note-modal__th note-modal__actions'>Actions</th>
                        </tr>
                        {EFSList?.data?.length ? (
                          EFSList.data.map(efs => (
                            <tr className='note-modal__tr' key={efs.id}>
                              <td className='note-modal__td'>{efs.paymentMethod}</td>
                              <td className='note-modal__td'>${efs.amount}</td>
                              <td className='note-modal__td'>{efs.cardNumber}</td>
                              <td className='note-modal__td'>{efs.note}</td>
                              <td className={`note-modal__td ${getColorClass(efs.efsStatus)} `}>{efs.efsStatusStr}</td>
                              <td className='note-modal__td note-modal__actions'>
                                <div className='button micro edit load-details__action-btn' onClick={() => handleEFSOpen('editMode', efs.id)}>
                                  <svg width='19' height='18' viewBox='0 0 19 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                    <path
                                      d='M11.1965 6.02L12.1165 6.94L3.05647 16H2.13647V15.08L11.1965 6.02ZM14.7965 0C14.5465 0 14.2865 0.1 14.0965 0.29L12.2665 2.12L16.0165 5.87L17.8465 4.04C18.2365 3.65 18.2365 3.02 17.8465 2.63L15.5065 0.29C15.3065 0.09 15.0565 0 14.7965 0ZM11.1965 3.19L0.136475 14.25V18H3.88647L14.9465 6.94L11.1965 3.19Z'
                                      fill='#3C8680'
                                    />
                                  </svg>
                                </div>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr className='note-modal__tr' style={{ height: '24px' }}>
                            <td className='note-modal__td'></td>
                            <td className='note-modal__td'></td>
                            <td className='note-modal__td'></td>
                            <td className='note-modal__td'></td>
                            <td className='note-modal__td'></td>
                            <td className='note-modal__td note-modal__actions'></td>
                          </tr>
                        )}
                      </table>
                    </div>

                    <div className='modal-form__load-details formik_form__load-details load-details__download-table formik_button__container-efc-table'>
                      <table className='note-modal__table note_modal__container'>
                        <tr className='note-modal__tr note-modal__tr-th'>
                          <th className='note-modal__th'>Download</th>
                          <th className='note-modal__th rate-attachment__name'>File Name</th>
                          <th className='note-modal__th'>Original File Name</th>
                          <th className='note-modal__th'>File Size</th>
                          <th className='note-modal__th'>Created Date</th>
                          <th className='note-modal__th'>Created By</th>
                          <th className='note-modal__th'>External</th>
                          <th className='note-modal__th note-modal__actions'>Actions</th>
                        </tr>
                        {attachmentsList?.length ? (
                          attachmentsList.map(attachment => {
                            if (attachment.deleted === false) {
                              return (
                                <tr className='note-modal__tr' key={attachment.id}>
                                  <td className='note-modal__td'>
                                    <Download guid={attachment.guid} width={35} height={35} filename={attachment.fileName} />
                                  </td>
                                  <td className='note-modal__td rate-attachment__name'>{attachment.fileName}</td>
                                  <td className='note-modal__td'>{attachment.originalFileName}</td>
                                  <td className='note-modal__td'>{bytesToSize(attachment.size)}</td>
                                  <td className='note-modal__td'>{moment(attachment.createDate).format(Defines.Format.PrintDate)}</td>
                                  <td className='note-modal__td'>{attachment.fullName}</td>
                                  <td className='note-modal__td'>{attachment.external ? 'Yes' : 'No'}</td>
                                  <td className='note-modal__td note-modal__actions'>
                                    <div className='button micro edit load-details__action-btn' onClick={() => editAttachment(attachment)}>
                                      <svg width='19' height='18' viewBox='0 0 19 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                        <path
                                          d='M11.1965 6.02L12.1165 6.94L3.05647 16H2.13647V15.08L11.1965 6.02ZM14.7965 0C14.5465 0 14.2865 0.1 14.0965 0.29L12.2665 2.12L16.0165 5.87L17.8465 4.04C18.2365 3.65 18.2365 3.02 17.8465 2.63L15.5065 0.29C15.3065 0.09 15.0565 0 14.7965 0ZM11.1965 3.19L0.136475 14.25V18H3.88647L14.9465 6.94L11.1965 3.19Z'
                                          fill='#3C8680'
                                        />
                                      </svg>
                                    </div>
                                    <div className='button micro delete load-details__action-btn' onClick={() => removeAttachment(attachment.id)}>
                                      <svg width='14' height='14' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                        <path
                                          d='M11 6V16H3V6H11ZM9.5 0H4.5L3.5 1H0V3H14V1H10.5L9.5 0ZM13 4H1V16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V4Z'
                                          fill='#3C8680'
                                        />
                                      </svg>
                                    </div>
                                  </td>
                                </tr>
                              );
                            } else {
                              return null;
                            }
                          })
                        ) : (
                          <tr className='note-modal__tr' style={{ height: '24px' }}>
                            <td className='note-modal__td'></td>
                            <td className='note-modal__td'></td>
                            <td className='note-modal__td'></td>
                            <td className='note-modal__td'></td>
                            <td className='note-modal__td'></td>
                            <td className='note-modal__td'></td>
                            <td className='note-modal__td'></td>
                            <td className='note-modal__td note-modal__actions'></td>
                          </tr>
                        )}
                      </table>
                    </div>

                    <div className='formik_btn formik_btn__order-details__save-close load-details__upload-btn upload-btn__container'>
                      <Upload name='file' customRequest={handleUpload} beforeUpload={beforeUpload} showUploadList={false}>
                        <div className='add_new_comment'>Click to Upload</div>
                      </Upload>
                    </div>
                  </div>

                  <div className='modal-form__load-details formik_form__load-details formik_button__container-efc-table'>
                    <button
                      className='formik_btn formik_btn__order-details__save'
                      type='submit'
                      disabled={!isValid || isSubmitting || buttonDisabled}
                      onClick={() => {
                        submitAction = 'first';
                        handleSubmit();
                      }}>
                      SAVE
                    </button>
                    <button
                      className='formik_btn formik_btn__order-details__save-close'
                      type='submit'
                      disabled={!isValid || isSubmitting || buttonDisabled}
                      onClick={() => {
                        submitAction = 'second';
                        handleSubmit();
                      }}>
                      SAVE & CLOSE
                    </button>
                    <button className='formik_btn formik_btn__order-details__save-close' type='submit' onClick={closeModal}>
                      CANCEL
                    </button>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
      {EFSModalIsOpen && (
        <EFSModal
          EFSModalIsOpen={EFSModalIsOpen}
          setEFSModalIsOpen={setEFSModalIsOpen}
          loadDetails={loadDetails}
          EFSData={EFSData}
          EFSLoading={EFSLoading}
          loadId={loadId}
          isEditMode={isEditMode}
          setEditMode={setEditMode}
        />
      )}
      {rateModalIsOpen && (
        <RateConfirmationModal
          rateModalIsOpen={rateModalIsOpen}
          setRateModalIsOpen={setRateModalIsOpen}
          loadDetails={loadDetails}
          EFSData={EFSData}
          EFSLoading={EFSLoading}
          loadId={loadId}
          isEditMode={isEditMode}
          setEditMode={setEditMode}
        />
      )}
      {attachmentEditModalIsOpen && (
        <UploadAttachmentEditModal
          attachmentEditModalIsOpen={attachmentEditModalIsOpen}
          setAttachmentEditModalIsOpen={setAttachmentEditModalIsOpen}
          attachment={attachment}
          loadDetails={loadDetails}
          setLoadDetails={setLoadDetails}
          loadId={loadId}
          resource={resource}
        />
      )}
      {warningModalIsOpen && <WarningModal warningModalIsOpen={warningModalIsOpen} setWarningModalIsOpen={setWarningModalIsOpen} />}
    </Modal>
  );
};

const mapStateToProps = state => ({
  state: state,
  loadDetails: state.loadDetails.loadDetails,
  loading: state.loadDetails.loading,
  carrierList: state.loadDetails.carrierList,
  trucksList: state.loadDetails.trucksList,
  trailersList: state.loadDetails.trailersList,
  driversList: state.loadDetails.driversList,
  rate: state.loadDetails.rate,
  EFSData: state.loadDetails.EFS,
  EFSLoading: state.loadDetails.EFSLoading,
  EFSList: state.loadDetails.EFSList,
  hasError: state.loadDetails.hasError,
  actionMessage: state.loadDetails.message,
  rateConfirmationGUID: state.rateConfirmation.rateConfirmationGUID,
  carrierRateConfirmationData: state.rateConfirmation.carrierRateConfirmationData,
  rateConfirmationMessage: state.rateConfirmation.rateConfirmationData?.message,
});

const mapDispatchToProps = dispatch => ({
  getLoadDetails: (resource, id) => dispatch(getLoadDetails(resource, id)),
  setLoadDetails: (resource, loadDetails, id) => dispatch(setLoadDetails(resource, loadDetails, id)),
  getCarrierList: (resource, id) => dispatch(getCarrierList(resource, id)),
  getTrucksList: (resource, id) => dispatch(getTrucksList(resource, id)),
  getTrailersList: (resource, id, trailerId) => dispatch(getTrailersList(resource, id, trailerId)),
  getDriversList: (resource, id, driverId) => dispatch(getDriversList(resource, id, driverId)),
  clearLoadDetailsData: resource => dispatch(clearLoadDetailsData(resource)),
  getEmptyEFS: resource => dispatch(getEmptyEFS(resource)),
  getEFSList: (resource, id) => dispatch(getEFSList(resource, id)),
  getEFS: (resource, id) => dispatch(getEFS(resource, id)),
  resetError: resource => dispatch(resetError(resource)),
  load: resource => dispatch(load(resource)),
  getCarierRateConfirmation: (resource, carrierId) => dispatch(getCarierRateConfirmation(resource, carrierId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoadDetailsModal);
