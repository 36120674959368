import moment from 'moment';
import { Defines } from '../../../../project/Defines';
import {
    ElementTypes
} from '../../../config';

const OrderColumns = {
    referenceNumber: {
        Title: 'Row',
        Field: ElementTypes.TableLabelField(),
        Width: 90
    },
    customerCodeOrName: {
        Title: 'Customer',
        Field: ElementTypes.TableLabelField(),
        Sorted: 'asc',
        Width: 70,
        Attributes: {
            wrap: {
                left: ''
            }
        }
    },
    puDate: {
        Title: 'Ready/Date',
        Field: ElementTypes.ColumnDate(),
        Width: 90,
        Attributes: {
            wrap: {
                left: ''
            }
        }
    },
    batchType: {
        Title: 'B',
        Field: ElementTypes.TableLabelField(),
        Width: 55
    },
    dueDate: {
        Title: 'Due-Date',
        Field: ElementTypes.ColumnDate(),
        Width: 90,
        Attributes: {
            wrap: {
                left: ''
            }
        }
    },
    drop1City: {
        Title: 'P/U City',
        Field: ElementTypes.TableLabelField(),
        Width: 80,
        Attributes: {
            wrap: {
                left: ''
            }
        }
    },
    drop1State: {
        Title: 'P/U State',
        Field: ElementTypes.TableLabelField(),
        Width: 80,
        Attributes: {
            wrap: {
                left: ''
            }
        }
    },
    drop1WH: {
        Title: 'Pick up Warehouse',
        Field: ElementTypes.TableLabelField(),
        Width: 150,
        Attributes: {
            wrap: {
                left: ''
            }
        }
    },
    drop2City: {
        Title: 'Drop City',
        Field: ElementTypes.TableLabelField(),
        Width: 100,
        Attributes: {
            wrap: {
                left: ''
            }
        }
    },
    drop2State: {
        Title: 'Drop ST',
        Field: ElementTypes.TableLabelField(),
        Width: 100,
        Attributes: {
            wrap: {
                left: ''
            }
        }
    },
    drop2WH: {
        Title: 'Drop WH',
        Field: ElementTypes.TableLabelField(),
        Width: 150,
        Attributes: {
            wrap: {
                left: ''
            }
        }
    },
    poNumber: {
        Title: 'PO#',
        Field: ElementTypes.TableLabelField(),
        Width: 70,
        Attributes: {
            wrap: {
                left: ''
            }
        }
    },
    soNumber: {
        Title: 'SO#',
        Field: ElementTypes.TableLabelField(),
        Width: 70,
        Attributes: {
            wrap: {
                left: ''
            }
        }
    },
    palletCount: {
        Title: 'Pallets',
        Field: ElementTypes.TableLabelField(),
        Width: 60,
        Attributes: {
            wrap: {
                left: ''
            }
        }
    },
    space: {
        Title: 'Spaces',
        Field: ElementTypes.TableLabelField(),
        Width: 60,
        Attributes: {
            wrap: {
                left: ''
            }
        }
    },
    cases: {
        Title: 'Cases',
        Field: ElementTypes.TableDecimalField(),
        Width: 60,
        Attributes: {
            wrap: {
                left: ''
            }
        }
    },
    weight: {
        Title: 'Weight',
        Field: ElementTypes.TableDecimalField(),
        Width: 60,
        Attributes: {
            wrap: {
                left: ''
            }
        }
    },
    inboundPickAppointment: {
        Title: 'Inbound Appt',
        Field: ElementTypes.Generic(ElementTypes.SimpleColumnIndicator, {
            displayability: (_, data) => data,
            display: (_, data) => `Appointment: ${moment.utc(data).format(Defines.Format.PrintDateTime)}`
        }),
        DataTitle: true,
        Width: 40,
        Attributes: {
            circle: {
                blue: ''
            }
        }
    },
    receiveDate: {
        Title: 'Receive Status',
        Field: ElementTypes.ReceiveIndicator((_, data) => data),
        DataTitle: true,
        Width: 40,
        Attributes: {
            circle: {
                green: ''
            }
        }
    },
    orderStatus: {
        Title: 'Status',
        Field: ElementTypes.ColumnBadge(),
        Width: 110
    },
    lastComment: {
        Title: '',
        Field: ElementTypes.LatestCommentIndicator((_, data) => data),
        DataTitle: true,
        Width: 40,
        Attributes: {
            circle: {
                green: ''
            }
        }
    }
};

export default OrderColumns;