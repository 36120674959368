import React from 'react';
import { connect } from "react-redux";
import HeaderCell from './headerCell'

const HeaderRow = ( { state, resource }) => {
    const headers = state[resource].headers;

    return (
        <div className='resp-table-header header-traffic rejected-order__header'>
            {headers.map((_, ind) => {
                return <HeaderCell key={ind} ind={ind} resource={resource} />
            })}
        </div>
    );
};

export default connect(state => ({
    state: state
}))(HeaderRow);