import { useState, useCallback } from 'react';

import { useData } from "../../../hook/data/useData";

export const useOrderData = () => {
    const [data, setData] = useState(null);
    const [truckData, truckDispatch] = useData(null);

    const dispatchData = useCallback((data) => {
        const type = data.type;
        setData(ddd => {
            const newData = { ...ddd };
            newData[type] = {
                data: data.value,
                type: type,
            };
            return newData;
        });
    }, []);

    return [data, dispatchData, truckData, truckDispatch];
}; 