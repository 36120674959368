import React, {
    useRef,
    useState
} from 'react';
import { message } from 'antd';
import { useParams, useHistory } from 'react-router-dom';

import ForgotPasswordScreen from '../screen/forgotPasswordScreen';
import Content from './content';
import { Mapping } from '../../mapping/mapping';
import { ValidateScreen } from '../../helper/validation';
import { useData } from '../../hook/data/useData';
import { useMessage } from '../../hook/data/useMessage';
import { DefaultMessageTypes } from '../../hook/actions';
import { useFormValidation } from '../../hook/data/useFormValidation';
import ForgotPasswordToolbare from './toolbar/forgetPasswordToolbar';
import axios from '../../axios/axios';


import { ApiUrl, AppPages } from '../../../project/Pages';
import { Project } from '../../../project/Project';


const ChangePasswordForm = props => {
    const componentRefs = useRef({});
    //state
    const [data, dispatch] = useData(props.screen.DefaultState);
    const [messages, messageDispatch] = useMessage(props.screen.Form);
    const [isLoading, setLoading] = useState(false);
    //end state
    const history = useHistory();

    const { replyCode } = useParams();

    const validate = useFormValidation(messageDispatch,
        data,
        Mapping,
        ValidateScreen,
        props.screen.Form,
        componentRefs);


    const formValidation = () => {
        messageDispatch({ type: DefaultMessageTypes.START_FOCUSE, messages: true });
        const [passValidation] = validate();
        return passValidation;
    };

    const resetPasswordHandler = () => {
        resetPassword();
    };

    const resetPassword = () => {
        if (!formValidation()) {
            return;
        }
        setLoading(true);
        axios.post(ApiUrl.ChangePassword,
            {
                password: data.password,
                confirmPassword: data.confirmPassword,
                replyCode: replyCode
            })
            .then(response => {
                setLoading(false);
                if (response) {
                    const result = response.data;
                    if (result.hasError) {
                        message.error(result.message);
                        return;
                    }
                    message.success(result.message);
                    history.push(Project.getPageUrl(AppPages.Login));
                }
            }).catch(error => {
                setLoading(false);
                message.error(error.response.data.title);
            });
    };

    const actionFromElement = event => {
        if (event.nativeEvent.key === "Enter") {
            resetPassword();
        }
    };

    return (
        <ForgotPasswordScreen
            isLoading={isLoading}
            title={props.screen.Title}
            text={props.screen.Text}>
            <form autoComplete="off">
                <Content
                    config={props.screen.Form}
                    data={data}
                    dispatch={dispatch}
                    messages={messages}
                    messageDispatch={messageDispatch}
                    componentRefs={componentRefs}
                    actionFromElement={actionFromElement}
                />
                <ForgotPasswordToolbare resetPassword={resetPasswordHandler} />
            </form>
        </ForgotPasswordScreen>);
};

export default ChangePasswordForm;

