export const ERROR = 'ERROR';
export const RESET_ERROR = 'RESET_ERROR';
export const SET_HEADER_WIDTH_ADMIN_REVISION = 'SET_HEADER_WIDTH_ADMIN_REVISION';
export const SET_DISPLAY_STATE = 'SET_DISPLAY_STATE';
export const GET_REVISION_ORDER_ADMIN = 'GET_REVISION_ORDER_ADMIN';
export const GET_REVISION_ORDER_ADMIN_LOADING = 'GET_REVISION_ORDER_ADMIN_LOADING';
export const ACCEPT_REVISION_ORDER = 'ACCEPT_REVISION_ORDER';
export const ACCEPT_REVISION_ORDER_LOADING = 'ACCEPT_REVISION_ORDER_LOADING';
export const REJECT_REVISION_ORDER_LOADING = 'REJECT_REVISION_ORDER_LOADING';
export const REJECT_REVISION_ORDER = 'REJECT_REVISION_ORDER';

export const getActionType = (recource, key) => {
    return CUSTOMER_ORDERS_BUILD[key]
};

export const CUSTOMER_ORDERS_BUILD = {
    ERROR: 'ERROR',
    RESET_ERROR: 'RESET_ERROR',
    SET_HEADER_WIDTH_ADMIN_REVISION: 'SET_HEADER_WIDTH_ADMIN_REVISION',
    GET_REVISION_ORDER_ADMIN: 'GET_REVISION_ORDER_ADMIN',
    GET_REVISION_ORDER_ADMIN_LOADING: 'GET_REVISION_ORDER_ADMIN_LOADING',
    ACCEPT_REVISION_ORDER: 'ACCEPT_REVISION_ORDER',
    ACCEPT_REVISION_ORDER_LOADING: 'ACCEPT_REVISION_ORDER_LOADING',
    REJECT_REVISION_ORDER_LOADING: 'REJECT_REVISION_ORDER_LOADING',
    REJECT_REVISION_ORDER: 'REJECT_REVISION_ORDER',
};
