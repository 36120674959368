import {
    ElementTypes,
    ElementValidations,
    GenerateToolBars
} from '../../config';

const Organization = {
    Title: 'Organization',
    Resource: 'Organization',
    Master: 'name',
    Key: 'id',
    //NotUseIdOnLoad: true,
    IsRest: true,
    Form: {
        DefaultId: 1,
        SectionGroups: [
            {
                Sections: [
                    {
                        Fields: {
                            name: {
                                Title: 'Name',
                                Field: ElementTypes.TextField(),
                                Validations: [ElementValidations.Required()]
                            },
                            mainPhone: {
                                Title: 'Main Phone',
                                Field: ElementTypes.Phone(),
                                Validations: [ElementValidations.Required()]
                            },
                            fax: {
                                Title: 'Fax',
                                Field: ElementTypes.TextField(),
                                Validations: [ElementValidations.Required()]
                            },
                            website: {
                                Title: 'Website',
                                Field: ElementTypes.TextField(),
                                Validations: [ElementValidations.Required()]
                            }
                        }
                    },
                    {
                        Fields: {
                            email: {
                                Title: 'Email',
                                Field: ElementTypes.TextField(),
                                Validations: [ElementValidations.Required(), ElementValidations.Email()]
                            },
                            address: {
                                Title: 'Address',
                                Field: ElementTypes.TextField(),
                                Validations: [ElementValidations.Required()]
                            },
                            addressLocation: {
                                Title: 'Address Location',
                                Field: ElementTypes.TextField(),
                                Validations: [ElementValidations.Required()]
                            }
                        }
                    }
                ]
            },
            {
                Sections: [
                    {
                        Fields: {
                            notes: {
                                Title: 'Notes',
                                Field: ElementTypes.Comment(),
                                Validations: [ElementValidations.Required()]
                            }
                        }
                    }
                ]
            }
        ],
        ToolBars: GenerateToolBars(['SaveClose', 'Cancel'])
    }
};

export default Organization;