import React from 'react';
import Modal from 'react-modal';
import { connect } from "react-redux";
import { Formik, Form, Field, ErrorMessage  } from 'formik';
import Select from 'react-select'
import * as Yup from 'yup';
import moment from 'moment';
import './index.css';
import { setEFS, updateEFS, getEFSList } from '../../../../store/actions/loadDetails/loadDetails';
import Loading from "../../screen/loading";

const EFSModal = ({
      setEFSModalIsOpen,
      EFSModalIsOpen,
      loading,
      EFSData,
      EFSLoading,
      getEFSList,
      setEFS,
      loadId,
      updateEFS,
      isEditMode,
      userRole,
    }) => {
  
  const resource = 'LoadEFS';
  const isAdmin = userRole === 'Admin';
  
  const closeModal = () => {
    setEFSModalIsOpen(false);
  };

  let submitAction = '';

  const efsPaymentMethodOptions = [
    { "value": "Advance", "text": "Advance", "label": "Advance" },
    { "value": "Other", "text": "Other", "label": "Other"}
  ];

  const customStyle = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      minWidth: '400px',
      minHeight: '300px',
      maxWidth: '600px',
      maxHeight: '600px',  
      borderRadius: '8px',
      padding: loading ? '0 0 8px 0' : '0',
    },
  };

  const customStyles = {
    control: (base, state) => ({
      ...base,
      borderColor: state.isFocused ? "lightGray" : "lightGray",
      borderRadius: '8px',
      boxShadow: 'none',
      minHeight: '37px',
      width: '100%',
      cursor: 'notAllowed',
    }),
    menu: (base, state) => ({
      ...base,
      zIndex: '20',
    }),
    menuList: () => ({
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      borderRadius: 'none!important'
    }),
    valueContainer: (base, state) => ({
      display: 'flex',
      alignItems: 'self-end',
      paddingLeft: '5px',
      maxWidth: '224px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    }),
    container: (base, state) => ({
      ...base,
      pointerEvents: 'auto',
    }),
    singleValue: (base, state) => ({
      maxWidth: '224px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      margin: '4px 0',
    }),
    options: (base, state) => ({
      padding: '4px 8px',
      cursor: 'pointer',
      borderRadius: 'unset'
    }),
  };

  const handleSubmitSave = (values, submitAction) => {
    let updatedEFS = {
        ...EFSData, 
        paymentMethod: values.paymentMethod,
        amount: values.amount,
        cardNumber: values.cardNumber,
        note: values.comment,
        email: values.email,
        loadId: loadId,
    }
    if (submitAction === 'Issue') {
      setEFS(resource, updatedEFS);
    }
    if (submitAction === 'Send') {
      updatedEFS = {
        ...updatedEFS, 
        efsStatus: 4
      };
      updateEFS(resource, updatedEFS);
    }
    setTimeout(() => getEFSList('LoadEFS', loadId), 4000);
    closeModal();
  };

  const handleReject = (values) => {
    let updatedEFS = {
        ...EFSData, 
        note: values.comment,
        email: values.email,
        loadId: loadId,
        efsStatus: 3
    }
    updateEFS(resource, updatedEFS);
    setTimeout(() => getEFSList('LoadEFS', loadId), 3000);
    closeModal();
  };

  const validationSchema = Yup.object().shape({
    paymentMethod: Yup.string().required('Required'),
    amount: Yup.number().integer().positive().required('Required'),
    cardNumber:  isAdmin ? Yup.string().required('Required') : Yup.string().nullable(),
    comment: Yup.string().nullable(),
    email: isEditMode && EFSData?.efsStatusStr === 'Requested' ? Yup.string().nullable() : 
            isEditMode  ? Yup.string().email('Invalid email').required('Driver mail is required') : Yup.string().nullable(),
  });

  return (
    <Modal
        isOpen={EFSModalIsOpen}
        onRequestClose={closeModal}
        style={customStyle}
        ariaHideApp={false}
        >
        <div className='comment_modal__container'>
          {EFSLoading ? <div style={{marginTop: '25%'}}><Loading /></div> : <>
            <div className='load-efs__title-container'>
              <div className='efs_modal__x-btn' onClick={closeModal}>X</div>
              <div className='efs-modal__title'>{isEditMode ? 'EFS' : 'New EFS'}</div>
            </div>
            <Formik
            initialValues={{
              paymentMethod: EFSData?.paymentMethod,
              amount: EFSData?.amount === 0 ? '' : EFSData?.amount,
              cardNumber: EFSData?.cardNumber,
              comment: EFSData?.note,
              email: EFSData?.email,
            }}
            validationSchema={validationSchema}
            enableReinitialize={true}
            onSubmit={(values, { resetForm, setSubmitting, setFieldValue, errors }) => {
              setTimeout(() => {
                setSubmitting(false);
                handleSubmitSave(values, submitAction);
              }, 400);
              }}
          >
            {({ isSubmitting, isValid, values, setFieldValue, errors, touched, handleSubmit }) => {
              return (
              <Form style={{ width: '86%'}}>
              <div className='modal_formik_container'>
                  <div className='formik_form__load-details'>

                    {isEditMode && <div className='load-efs__info-container'>
                      <div className='load-efs__info-title'>Status</div>
                      <div className='load-efs__info-data'>{EFSData?.efsStatusStr}</div>
                      <div className='load-efs__info-title'>Created Date</div>
                      <div className='load-efs__info-data'>{moment.utc(EFSData?.createDate).utcOffset(4).format("MM/DD/YY")}</div>
                      <div className='load-efs__info-title'>Created By</div>
                      <div className='load-efs__info-data'>{EFSData?.createByStr}</div>
                    </div>}

                    <div className='load-efs__select-container'>
                      <div className='load-efs__select_title'>EFS</div>
                      <Select 
                          options={efsPaymentMethodOptions}
                          name="paymentMethod"
                          value={{label: values.paymentMethod}}
                          className={`${touched.paymentMethod && errors.paymentMethod ? "dropdown_error" : ""} ${isEditMode && EFSData?.efsStatusStr !== 'Requested' ? "disabled" : ""} `}
                          onChange={option => setFieldValue('paymentMethod', option.value)}
                          styles={customStyles}
                          placeholder={''}
                          components={{
                            IndicatorSeparator: () => null
                          }}
                          isDisabled={isEditMode && EFSData?.efsStatusStr !== 'Requested'}
                      />
                    </div>

                    <div className='load-efs__select_title'>Amount</div>
                    <div className={`load-details__input-container ${isEditMode && EFSData?.efsStatusStr !== 'Requested' ? "disabled" : ""} ${!isAdmin && isEditMode ? "disabled" : ""} `} style={touched.amount && errors.amount && { border: '1px solid red' } }>
                      <span style={{ marginLeft: '8px', alignItems: 'center' }}>{values.amount ? '$' : ''}
                        <Field
                          name="amount"
                          className={`load-details__input-trailer ${(isEditMode && EFSData?.efsStatusStr !== 'Requested') ? "disabled load-details__input__disabled" : ""} ${!isAdmin && isEditMode ? "disabled" : ""} order-details__amount-input`}
                          disabled={isEditMode && EFSData?.efsStatusStr !== 'Requested'}
                          type="number"
                        />
                      </span>
                    </div>

                    {!isAdmin && !isEditMode ? null :
                    <> 
                      <div className='load-efs__select_title'>Card Number</div>
                      <div className={`load-details__input-container ${(isEditMode && EFSData?.efsStatusStr !== 'Requested') || !isAdmin  ? "disabled" : ""} `} style={touched.cardNumber && errors.cardNumber && { border: '1px solid red' } }>
                        <Field
                          name="cardNumber"
                          className={`load-details__input-trailer order-details__card-input ${isEditMode && EFSData?.efsStatusStr !== 'Requested' ? "disabled" : ""}`}
                          disabled={(isEditMode && EFSData?.efsStatusStr !== 'Requested') || !isAdmin}
                        />
                      </div>
                    </>}

                    {((EFSData?.efsStatusStr === 'Requested' || EFSData?.efsStatusStr === 'Open' || EFSData?.efsStatusStr === 'Rejected') && userRole !== 'Admin') ||
                      (userRole === 'Admin' && (EFSData?.efsStatusStr !== 'Sent' && EFSData?.efsStatusStr !== 'Issued')) ? null :
                      <>
                        <div className='load-efs__select_title'>Driver Email</div>
                        <div className='load-details__input-container' style={touched.email && errors.email && { border: '1px solid red', marginBottom: '16px' } }>
                          <Field
                            name="email"
                            className='load-details__input-trailer'
                            disabled={EFSData?.efsStatusStr === 'Rejected'}
                            autoComplete="off"
                          />
                          <ErrorMessage name="email" component="div" className='error-container'/>
                        </div>
                    </>}

                    <div className='load-efs__select_title'>Comment</div>
                    <div>
                      <Field
                        as="textarea"
                        name="comment"
                        style={touched.comment && errors.comment && { border: '1px solid red' } }
                        className='load-details__textarea'
                        disabled={EFSData?.efsStatusStr === 'Rejected'}
                      />
                    </div>

                  </div>

                  <div className='modal-form__load-details formik_form__load-details formik_button__container load-efs_btn__container'>
                    <button 
                      className='formik_btn formik_btn__efs' 
                      type="submit" 
                      disabled={!isValid || isSubmitting || EFSData?.efsStatusStr === 'Rejected' || EFSData?.efsStatusStr === 'Issued' || EFSData?.efsStatusStr === 'Sent'}
                      onClick={() => { submitAction = "Issue"; handleSubmit() }}
                      >{isAdmin ? 'Issue' : 'Request'}
                    </button>
                    <button 
                      className='formik_btn formik_btn__efs'
                      type="submit" 
                      disabled={!isValid || isSubmitting || !isEditMode || EFSData?.efsStatusStr === 'Rejected' || EFSData?.efsStatusStr === 'Requested'}
                      onClick={() => { submitAction = "Send"; handleSubmit() }}
                      >{EFSData?.efsStatusStr === 'Sent' ? 'ReSend' : 'Send'}
                    </button>
                    {isAdmin && <button 
                      className='formik_btn formik_btn__efs' 
                      type="submit" 
                      onClick={() => handleReject(values) }
                      disabled={isSubmitting || !isEditMode || EFSData?.efsStatusStr === 'Rejected' || EFSData?.efsStatusStr === 'Sent'}
                      >Reject
                    </button>}
                    <button 
                      className='formik_btn formik_btn__efs' 
                      type="submit" 
                      onClick={closeModal}>
                      Cancel
                    </button>
                  </div>

                </div>
              </Form>
            )}}
          </Formik>
          </>}
        </div>  
    </Modal>
  );
};

const mapStateToProps = state => ({
  state: state,
  userRole: state.auth.userRole,
});

const mapDispatchToProps = (dispatch) => ({
  setEFS: (resource, data) => dispatch(setEFS(resource, data)),
  updateEFS: (resource, data) => dispatch(updateEFS(resource, data)),
  getEFSList: (resource, id) => dispatch(getEFSList(resource, id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EFSModal);
