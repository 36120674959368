import React from 'react';
import { connect } from 'react-redux';
import CustomerOrdersLastAndRejected from '../../CustomerOrdersLastAndRejected/CustomerOrdersLastAndRejected';
import { getContentWidth } from '../../../../../store/reducers/customerRejectedOrders/helper/general';

const CustomerOrderLastAndRejectedBuild = props => {
  const { state } = props;
  const resource = 'customerLastOrders';
  const headers = state[resource].headers;
  const width = getContentWidth(headers) ;

  return (
    <div
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        margin: '0 12px 6px',
        alignSelf: 'center',
        width: '100%',
        maxWidth: width + 24,
      }}>
      <CustomerOrdersLastAndRejected {...props} />
    </div>
  );
};
const mapStateToProps = state => ({
  state: state,
});

export default connect(mapStateToProps)(CustomerOrderLastAndRejectedBuild);
