import React, { useState, useRef, useEffect } from 'react';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Defines } from '../../../../project/Defines';
import { Tooltip, Popover  } from 'antd';
import { setItemDisplayState } from '../../../../store/actions/deliveredLoads/deliveredLoads';
import { enabled } from 'store/dist/store.legacy';
import { GenerateMessages } from '../../../helper/element';
import './index.css';

const Cell = (
    {
        state,
        cellData,
        itemSize,
        rowState,
        setItemData,
        setItemDisplayState,
        resource,
        loadsAssignedOrders,
    }) => {
    const loadArea = state[resource];
    const redonly = 1;
    const header = loadArea.headers.find(h => h.key === cellData.key);
    const selectOptionsPalletTypes = { null: '', 1: 'Straight', 2: 'Chimney', 3: 'Other', 4: 'Sideways' };
    const width = header.width;
    const lastSelectId = loadArea.lastSelected.id;
    const lastSelectHeaderKey = loadArea.lastSelected.headerKey;
    const lastSelectenable = loadArea.lastSelected.enable;
    const rowStateId = rowState.id;
    const headerKey = header.key;
    const [displayState, setDisplayState] = useState(redonly);
    const [tempData, setTempData] = useState(null);

    const ordersAssignedToCurrentLoad = loadsAssignedOrders.filter(order => order.rowState.loadData.id === rowState.loadId && order.rowState.type === 'order');
    const poNumbers = ordersAssignedToCurrentLoad.map(order => order.rowState.orderData.poNumber).join(', ');
    const mailBodyText = `Please send POD for the bellow PO#s: ${poNumbers}`;
    const carrierEmail = rowState.loadData.carrierEmail;

    useEffect(() => {
        if (lastSelectId === rowStateId && lastSelectHeaderKey === headerKey && lastSelectenable) {
            setDisplayState(enabled);
        }
    }, [lastSelectId, lastSelectHeaderKey, lastSelectenable, rowStateId, headerKey]);

    const data = cellData.value;
    const podNotesHistory = cellData?.value2;
    const cellStyle = cellData.cellStyle;
    const tooltip = cellData.tooltip;
    const color = rowState.type === 'load' ? 'white' : '#101C1C';

    useEffect(() => {
        setTempData(data);
    }, [data]);

    const inputReference = useRef(null);

    const dataCompare = (newData, data) => {
        switch (cellData.fieldType) {
            case 'date':
            case 'dateTime':
                if (newData instanceof Date && data instanceof Date) {
                    return newData.getTime() === data.getTime();
                }
                return newData === data;
            default:
                return newData === data;
        }
    }

    const setData = value => {
        onSetBlur();
        let newValue = value;
        if (value !== '') {
            switch (cellData.fieldType) {
                case 'date':
                    newValue = new Date(value);
                    break;
                case 'dateTime':
                    newValue = new Date(value);
                    break;
                case 'text':
                    newValue = value;
                    break;
                default:
                    newValue = value;
                    break;
            }
        }
        if (!dataCompare(newValue, tempData)) {
            setTempData(newValue);  
            setTimeout(() => {
                setItemData(resource, newValue, rowState, header);
            }, 100);
        }
    };

    const getData = () => {        
        if (tempData !== '') {
            switch (cellData.fieldType) {
                case 'date':
                    return <>{tempData ? moment(tempData).format(Defines.Format.PrintDate) : ''}</>
                case 'dateTime':
                    return <div className='date-time__container'>
                        <div className='date__part'>{tempData ? moment(tempData).format(Defines.Format.Date) : ''}</div>
                        <div className='time__part'>{tempData ? moment(tempData).format(Defines.Format.Time) : ''}</div>
                    </div>;
                case 'link':
                    return <Link style={{ color, textDecoration: 'underline' }} to={rowState.loadData.loadType === 3  ? '/orderprocess/outboundloadbuld' : '/orderprocess/inboundloadbuld'}>{tempData}</Link>
                case 'text':
                    if (header.key === 'loadPositionType') {
                        return selectOptionsPalletTypes[tempData];
                    } else if (header.key === 'carrierName' && rowState.type === 'load') {
                        return (
                            <a  className='call-checking__carrierName'
                                style={{color: 'white'}}
                                href={`mailto:${carrierEmail}?subject=POD request&body=${mailBodyText}`}>
                                {tempData}
                            </a>)
                    } else {
                        return tempData;
                    }
                case 'popover':
                    return (
                        <Popover content={content} title="POD status list">
                            {tempData}
                        </Popover>
                    );
                default:
                return tempData;
        }
        }
        return tempData
    };

    let splittedData = [];
    if (header.key === 'orderNotesByCall' && podNotesHistory && podNotesHistory?.[0]) {
        splittedData = podNotesHistory?.split(',');
    };

    const content = splittedData.length ? (
        <div>
            <table className='pod-status-modal__table'>
                <tr className='pod-status-modal__tr'>
                    <th className='pod-status-modal__th'>
                        Created By &nbsp; &nbsp; &nbsp; Data Time &nbsp; &nbsp; Status note</th>
                </tr>
                {splittedData?.map(data => (
                    <tr className='pod-status-modal__tr' >
                        <td className='pod-status-modal__td'>{data}</td>
                    </tr>
                ))}
            </table>
        </div>
    ) : <div>You don't have any notes</div>;

    const getInput = () => {
        switch (cellData.fieldType) {
            case 'textInput':
                return <input
                    defaultValue={tempData ? tempData : ''}
                    ref={inputReference}
                    onBlur={(value) => {
                        setData(value.currentTarget.value)
                    }}
                    style={{ cursor: 'pointer', textAlign: 'center', width: 'inherit' }}
                    className={header.key === 'loadCallCheckNote' ? 'green__BG' : ''}
                />;
            default: return <></>;
        }
    };

    const getDataWithTooltip = () => tooltip ? (<Tooltip title={GenerateMessages([tooltip])}>
        {displayState === redonly ? getData() : getInput()}
    </Tooltip>) : displayState === redonly ? getData() : getInput();

    const dataDisplay = () =>  getDataWithTooltip();

    const cellDefaultStyle = {
        maxWidth: `${width}px`,
        width: `${width}px`,
        minWidth: `${width}px`,
        height: `${itemSize}px`,
        lineHeight: 'inherit',
    };

    const onSetBlur = () => {
        setItemDisplayState(resource, rowState, header, false);
    };

    return (
        <div
            className='table-body-cell'
            style={{ ...cellDefaultStyle, ...cellStyle, color: color}}
            tabIndex={displayState === redonly ? 0 : -1}
            >
            {dataDisplay()}
        </div>
);
};

const mapStateToProps = state => ({
    state: state,
    loadsAssignedOrders: state.deliveredLoads.loadsAssignedOrders,
});

const mapDispatchToProps = (dispatch) => ({
    setItemDisplayState: (resource, rowState, header, enable) => dispatch(setItemDisplayState(resource, rowState, header, enable))
});

export default connect(mapStateToProps, mapDispatchToProps)(Cell);