import * as pageContentActionTypes from '../../actions/pageContent/pageContentActionTypes';

const defaultState = {
    isHiddenMenu: false
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case pageContentActionTypes.HIDE_SHOW_MENU:
            return { ...state, isHiddenMenu: action.data };
        default:
            return state;
    }
};