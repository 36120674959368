import React, { useEffect, useRef, useState} from 'react';
import { savePDF } from '@progress/kendo-react-pdf';
import { Spin, Table } from 'antd';
import axios from '../../../../axios/axios';
import ManifestToolBar from '../../../custom/spreadsheet/manifestToolBar';
import { DefaultTable } from '../../../../config';
import './index.css';

const ManifestList = () => {
    const resource = 'Load/manifestlist';
    const [dataManifest, setDataManifest] = useState([])
    const [backEndData, setBackEndData] = useState([])
    const [tabsKey, setTabsKey] = useState('1')
    const [loading, setLoading]=useState(false)
    const [selectedWeek, setSelectedWeek] = useState(new Date().getWeek())
    const filterJson = { 'PickWeek': selectedWeek }
    const filterData = JSON.stringify(filterJson)
    const contentArea = useRef(null);

    const color_list = [
        "tomato",
        "blueviolet",
        "cornflowerblue",
        "indianred",
        "MediumAquaMarine",
        "MediumPurple",
        "Rebeccapurple",
        "sandybrown",
        "seagreen",
        "palevioletred",
        "lightsteelblue",
        "Gold",
        "teal",
    ];

    const random_color = color_list[Math.floor(Math.random() * color_list.length)];

    useEffect(() => {
        setLoading(true)
        axios.get(resource, {
            params: {
                filterData: filterData
            }
        }).then(response => {
            setBackEndData(response.data)
            setLoading(false)
        }).catch(() => { setLoading(false) } )
    }, [filterData, selectedWeek]);

    useEffect(() => {
        if (tabsKey === '1' && backEndData.inManifest) {
            setDataManifest(backEndData.inManifest)
        } else {
            setDataManifest(backEndData.outManifest)
        }
    }, [tabsKey, backEndData]);

    const columns = [
        {        
            title: 'Cust',
            dataIndex: 'customerName',
            key: 'customerName',
            ellipsis: {
                showTitle: false,
            },
            width: 100,
            id: 1,
        },
        {
            title: 'Pick',
            dataIndex: 'pickLocation',
            key: 'pickLocation',
            width: 100,
            id: 2,
        },
        {
            title: 'Delivery',
            dataIndex: 'dropLocation',
            key: 'dropLocation',
            ellipsis: {
                showTitle: false,
            },
            width: 100,
            id: 3,
        },
        {
            title: 'PO#',
            dataIndex: 'poNumber',
            key: 'poNumber',
            ellipsis: {
                showTitle: false,
            },
            width: 100,
            id: 4,
        },
        {
            title: 'SO#',
            dataIndex: 'soNumber',
            key: 'soNumber',
            ellipsis: {
                showTitle: false,
            },
            width: 100,
            id: 5,
        },
        {
            title: 'SPA',
            dataIndex: 'space',
            key: 'space',
            id: 6,
            ellipsis: {
                showTitle: false,
            },
            width: 100,
            render(text, record) {
                return {
                    props: {
                        style: { background: random_color }
                    },
                    children: <div>{text}</div>
                };
            }
        },
        {
            title: 'PAL',
            dataIndex: 'palletCount',
            key: 'palletCount',
            width: 100,
            ellipsis: {
                showTitle: false,
            },
            id: 7,
        },
        {
            title: 'CASE',
            dataIndex: 'cases',
            key: 'cases',
            ellipsis: {
                showTitle: false,
            },
            width: 100,
            id: 8,
        },
        {
            title: 'LBS',
            dataIndex: 'weight',
            key: 'weight',
            ellipsis: {
                showTitle: false,
            },
            width: 100,
            id: 9,
        },
        {
            title: 'WK',
            dataIndex: 'pickWeek',
            key: 'pickWeek',
            ellipsis: {
                showTitle: false,
            },
            width: 100,
            id: 10,
        },
        {
            title: 'IN',
            dataIndex: 'loadNum',
            key: 'loadNum',
            width: 100,
            id: 11,
        },
        {
            title: 'OUT',
            dataIndex: 'outRefNum',
            key: 'outRefNum',
            id: 12,
            width: 100,
        },

    ];

    const handleExportWithMethod = () => {
        savePDF(contentArea.current, { paperSize: 'auto', fileName: 'Manifest' });
    };

    return (
        <Spin spinning={loading} style={{ height:'100%' }}>
            <div ref={contentArea} style={{ height:'100%', overflow: 'auto' }}>
                <ManifestToolBar 
                    handleExportWithMethod={handleExportWithMethod} 
                    selectedWeek={selectedWeek} 
                    setSelectedWeek={setSelectedWeek} 
                    setTabsKey={setTabsKey}
                    columns={columns}
                    dataManifest={dataManifest}
                />
                <div id="table-scroll" className="table-scroll">
                    {dataManifest?.length ?
                        <table id="main-table">
                            <thead>{columns.map((item, index) => (<th scope="col" key={index}>{item.title}</th>))}</thead>
                            {dataManifest?.map((item, index) => (
                                <tbody key={index}>
                                    <tr>
                                        <td><span style={{ fontWeight: 'bold' }}>{item.loadNumber}</span></td>
                                        <td><span style={{ color: '#f00' }}>{item.loadArchiveDate ? 'Deleted' : ''}</span></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    {item.orders.map((item1, index1) =>
                                        <tr key={index1}>
                                            <td>{item1.customerName}</td>
                                            <td>{item1.pickLocation}</td>
                                            <td>{item1.dropLocation}</td>
                                            <td>{item1.poNumber}</td>
                                            <td>{item1.soNumber}</td>
                                            <td style={{ backgroundColor: `${color_list[index]}` }}> {item1.space}</td>
                                            <td>{item1.palletCount}</td>
                                            <td>{item1.cases}</td>
                                            <td>{item1.weight}</td>
                                            <td>{item1.pickWeek}</td>
                                            <td>{item1.loadNum}</td>
                                            <td>{item1.outRefNum}</td>
                                        </tr>
                                    )}
                                    {<tr key={index} >
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td><span style={{ fontWeight: 'bold' }}>{item.orders.map(item => item.space).reduce((a, b) => a + b, 0)}</span></td>
                                        <td></td>
                                        <td><span style={{ fontWeight: 'bold' }}>{item.orders.map(item => item.cases).reduce((a, b) => a + b, 0)}</span></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>}
                                </tbody>
                            ))}
                        </table> :
                        <Table
                            dataSource={dataManifest}
                            pagination={false}
                            {...DefaultTable.Style}
                        />
                    }
                </div>
            </div>
        </Spin>
    );
};

export default ManifestList