import React from 'react';
import { connect } from "react-redux";

import DropDownElement from './dropDownElement';
import { ElementConditions } from '../config/condition.config';
import { addValueList } from '../../store/actions/valueList/valueList';
import { useValueListWithCache } from '../hook/data/useValueList';

const SelectElementWithCache = props => {
    const { dataSource, classes,
        condition, data, action, valudationMessages,
        style, parentValue, selectedValue, reloadOnParentChange, mode,
        showSearch, name, allowClear, placeholder, filterOption,
        valueList, addValueList, selectedValueType } = props;
    const hash = require('object-hash');
    const valueKey = typeof dataSource === 'string' ? dataSource + (parentValue ? parentValue : '') : hash(dataSource);
    useValueListWithCache(data, dataSource, parentValue, addValueList, valueList, reloadOnParentChange, valueKey);
    return condition !== ElementConditions.Hidden ? //&& valueList[valueKey] ?
        <DropDownElement
            allowClear={allowClear}
            name={name}
            placeholder={placeholder}
            showSearch={showSearch}
            mode={mode}
            data={data}
            classes={classes}
            valudationMessages={valudationMessages}
            style={style}
            condition={condition}
            valueList={valueList[valueKey]}
            action={action}
            filterOption={filterOption}
            selectedValue={selectedValue}
            defaultValue={selectedValueType === 'countryName' ? 234 : ''}
        />
        : null;
};

const mapStateToProps = state => {
    return {
        valueList: state.valueList.list
    }
};

const mapDispatchToProps = dispatch => {
    return {
        addValueList: (valueKey, valueList) => dispatch(addValueList(valueKey, valueList))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectElementWithCache);