import React from 'react';
import Modal from 'react-modal';
import { connect } from "react-redux";
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import './index.css';
import { bytesToSize } from '../../../../project/Defines';

const UploadAttachmentEditModal = ({
      setAttachmentEditModalIsOpen,
      attachmentEditModalIsOpen,
      attachment,
      loadDetails,
      setLoadDetails,
      loadId,
      resource,
    }) => {
  const closeModal = () => {
    setAttachmentEditModalIsOpen(false);
  };

  const customStyle = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      minWidth: '300px',
      minHeight: '300px',
      maxWidth: '400px',
      maxHeight: '500px',
      borderRadius: '8px',
      padding: '0',
    },
  };

  const handleSubmitSave = (values) => {    
    let updatedAttachment = {
      ...attachment, 
      comments: values.comments,
    };
    const updatedList = loadDetails.attachments.filter(item => item.id !== attachment.id);
    updatedList.push(updatedAttachment);
    const updatedLoadDetails = {
      ...loadDetails,
      attachments: updatedList
    }
    setLoadDetails(resource, updatedLoadDetails, loadId);
    closeModal();
  };

  const validationSchema = Yup.object().shape({
    comments: Yup.string().required('Required'),
  });

  return (
    <Modal
        isOpen={attachmentEditModalIsOpen}
        onRequestClose={closeModal}
        style={customStyle}
        ariaHideApp={false}
        >
        <div className='attachment_modal__container'>
            <div className='load-efs__title-container'>
              <div className='efs_modal__x-btn' onClick={closeModal}>X</div>
              <div className='attachment-modal-modal__title'>Attachment Comment</div>
            </div>
            <Formik
              initialValues={{
                comments: attachment?.comments,
              }}
              validationSchema={validationSchema}
              enableReinitialize={true}
              onSubmit={(values, { resetForm, setSubmitting, setFieldValue, errors }) => {
                setTimeout(() => {
                  setSubmitting(false);
                  handleSubmitSave(values);
                }, 400);
              }}
            >
            {({ isSubmitting, isValid, values, setFieldValue, errors, touched, handleSubmit }) => {
              return (
              <Form>
              <div className='modal_formik_container attachmemt-data__container'>
                  <div className='formik_form__load-details'>
                    <div className='load-efs__info-container'>
                      <div className='load-efs__info-title'>File Name</div>
                      <div className='load-efs__info-data'>{attachment?.fileName}</div>
                      <div className='load-efs__info-title'>Original File Name</div>
                      <div className='load-efs__info-data'>{attachment?.originalFileName}</div>
                      <div className='load-efs__info-title'>File Size</div>
                      <div className='load-efs__info-data'>{bytesToSize(attachment?.size)}</div>
                    </div>
                    <div className='load-efs__select_title'>File Comment</div>
                    <div>
                      <Field
                        as="textarea"
                        name="comments"
                        style={touched.comments && errors.comments && { border: '1px solid red' } }
                        className='load-details__textarea'
                      />
                    </div>
                  </div>

                  <div className='modal-form__load-details formik_form__load-details formik_button__container attchment-btn__info-container'>
                    <button 
                      className='formik_btn formik_btn__efs' 
                      type="submit" 
                      disabled={!isValid || isSubmitting}
                      onClick={handleSubmit}
                      >Save
                    </button>
                    <button 
                      className='formik_btn formik_btn__efs' 
                      type="submit" 
                      onClick={closeModal}>
                      Cancel
                    </button>
                  </div>

                </div>
              </Form>
            )}}
          </Formik>
        </div>  
    </Modal>
  );
};

const mapStateToProps = state => ({
  state: state,
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadAttachmentEditModal);
