import axios from 'axios';
import store from '../..';
import * as actionTypes from './customersActionTypes';

const buildUrl = (resource, endpoint) => endpoint ? `/api/${resource}/${endpoint}` : `/api/${resource}`;

const axiosConfig = () => ({
    headers: {
        'Authorization': 'Bearer ' + store.getState().auth.userToken
    }
});

export const getCustomersList = (resource, endpoint) => dispatch => {
    dispatch({ type: actionTypes.getActionType(resource, actionTypes.GET_CUSTOMERS_LIST_LOADING) });
    axios.get(buildUrl(resource, endpoint),
        axiosConfig())
        .then(responce => dispatch({ type: actionTypes.getActionType(resource, actionTypes.GET_CUSTOMERS_LIST), data: responce }))
        .catch((err) => {
            console.error(err);
            console.log(err.response);
            dispatch({ type: actionTypes.getActionType(resource, actionTypes.GET_CUSTOMERS_LIST_ERROR) });
        })
};

export const resetError = resource =>
    ({ type: actionTypes.getActionType(resource, actionTypes.RESET_ERROR) });


export const getCustomerPrice = (resource, customerId) => dispatch => {
    dispatch({ type: actionTypes.getActionType(resource, actionTypes.GET_CUSTOMERS_PRICE_LIST_LOADING) });
    const filterData  = {
        params: {
            filterData: { customerId: customerId},
        },
        headers: axiosConfig().headers,
    };
    axios.get(buildUrl(resource), filterData,
        axiosConfig())
        .then(responce => dispatch({ type: actionTypes.getActionType(resource, actionTypes.GET_CUSTOMERS_PRICE_LIST), data: responce }))
        .catch((err) => {
            console.error(err);
            console.log(err.response);
            dispatch({ type: actionTypes.getActionType(resource, actionTypes.GET_CUSTOMERS_PRICE_LIST_ERROR) });
        })
};

export const addEmptyLineOnPriceList = (resource, customerId) => ({
    type: actionTypes.getActionType(resource, actionTypes.ADD_EMPTY_LINE_ON_PRICE_LIST), data: customerId
});

export const removeEmptyLinesOnPriceList = (resource) => ({
    type: actionTypes.getActionType(resource, actionTypes.REMOVE_EMPTY_LINES_ON_PRICE_LIST), data: {}
});

export const setItemDisplayState = (resource, rowState, header, enable) => ({
    type: actionTypes.getActionType(resource, actionTypes.SET_DISPLAY_STATE), data: {
        rowState,
        header,
        enable
    }
});

export const setItemData = (resource, value, rowState, header, priceTemplateType) => dispatch => {
    dispatch({
        type: actionTypes.getActionType(resource, actionTypes.SET_CUSTOMER_PRICE_DATA), data: {
            value,
            rowState,
            header
        }
    });

    if (rowState.id !== 0) {        
        dispatch({ type: actionTypes.getActionType(resource, actionTypes.SET_CUSTOMER_PRICE_DATA_LOADING) });
        axios.put(buildUrl(priceTemplateType), rowState.data, axiosConfig())
        .catch(err => {
            console.error(err);
            console.log(err.response);
            dispatch({ type: actionTypes.getActionType(resource, actionTypes.SET_CUSTOMER_PRICE_DATA_ERROR) });
        });
    }
};

export const addCustomerPrice = (resource, customersPriceList, priceTemplateType, customerId) => dispatch => {
    const requestData = customersPriceList[0].rowState.data;
    dispatch({ type: actionTypes.getActionType(resource, actionTypes.ADD_CUSTOMER_PRICE_LOADING) });
    axios.post(buildUrl(priceTemplateType), requestData, axiosConfig())
    .then(response => {
        if (response.data.hasError) {
            dispatch({ type: actionTypes.getActionType(resource, actionTypes.ADD_CUSTOMER_PRICE_ERROR), data: response.data.message });
            console.error(response.data.message)
            dispatch(reloadPriceListAfterUpdate(priceTemplateType, customerId))
        } else {
            dispatch({ type: actionTypes.getActionType(resource, actionTypes.ADD_CUSTOMER_PRICE), data: response })
        }
    }).catch(err => {
        console.error(err);
        console.log(err.response);
        dispatch({ type: actionTypes.getActionType(resource, actionTypes.ADD_CUSTOMER_PRICE_ERROR) });
    });
};

export const deleteCustomerPrice = (resource, priceType, id, customerId) => dispatch => {
    const filterData  = {
        params: { id: id },
        headers: axiosConfig().headers,
    };    
    dispatch({ type: actionTypes.getActionType(resource, actionTypes.DELETE_CUSTOMER_PRICE_LOADING) });
    axios.delete(buildUrl(priceType), filterData, axiosConfig())
    .then(response => {
        if (response.data.hasError) {
            dispatch({ type: actionTypes.getActionType(resource, actionTypes.DELETE_CUSTOMER_PRICE_ERROR) });
            console.error(response.data.message);
        }
        dispatch(reloadPriceListAfterUpdate(priceType, customerId))
    }).catch(err => {
        console.error(err);
        console.log(err.response);
        dispatch({ type: actionTypes.getActionType(resource, actionTypes.DELETE_CUSTOMER_PRICE_ERROR) });
    });
};

export const setCustomerPriceType = (resource, endpoint, customerId, type) => dispatch => {
    const filterData = { 
        customerId: customerId,
        type: type,
    };
    dispatch({ type: actionTypes.getActionType(resource, actionTypes.SET_CUSTOMER_PRICE_TYPE_LOADING) });
    axios.put(buildUrl(resource, endpoint), filterData, axiosConfig())
    .then(response => {
        if (response.data.hasError) {
            dispatch({ type: actionTypes.getActionType(resource, actionTypes.SET_CUSTOMER_PRICE_TYPE_ERROR) });
            console.error(response.data.message);
        }
        dispatch(reloadAfterUpdate(resource, customerId))
    }).catch((err) => {
            console.error(err);
            console.log(err.response);
            dispatch({ type: actionTypes.getActionType(resource, actionTypes.SET_CUSTOMER_PRICE_TYPE_ERROR) });
        })
};

const reloadAfterUpdate = (resource, customerId) => dispatch => {
    dispatch({ type: actionTypes.getActionType(resource, actionTypes.SET_CUSTOMER_PRICE_TYPE_LOADING) });
    axios.get(buildUrl(resource, customerId),
        axiosConfig()
    )
        .then(responce => dispatch({ type: actionTypes.getActionType(resource, actionTypes.GET_CUSTOMERS_LIST), data: responce }))
        .catch((err) => {
            console.error(err);
            console.log(err.response);
            dispatch({ type: actionTypes.getActionType(resource, actionTypes.GET_CUSTOMERS_LIST_ERROR) });
        });
};

const reloadPriceListAfterUpdate = (resource, customerId) => dispatch => {
    dispatch({ type: actionTypes.getActionType(resource, actionTypes.GET_CUSTOMERS_PRICE_LIST_LOADING) });
    const filterData  = {
        params: {
            filterData: { customerId: customerId},
        },
        headers: axiosConfig().headers,
    };
    axios.get(buildUrl(resource), filterData, axiosConfig())
        .then(responce => dispatch({ type: actionTypes.getActionType(resource, actionTypes.GET_CUSTOMERS_PRICE_LIST), data: responce }))
        .catch((err) => {
            console.error(err);
            console.log(err.response);
            dispatch({ type: actionTypes.getActionType(resource, actionTypes.GET_CUSTOMERS_PRICE_LIST_ERROR) });
        });
};

export const setPerPalletType = (resource, perPalletType) => ({
    type: actionTypes.getActionType(resource, actionTypes.SET_PER_PALLET_TYPE), data: perPalletType
});

export const getLocationsList = (resource, id) => dispatch => {
    dispatch({ type: actionTypes.getActionType(resource, actionTypes.GET_CUSTOMER_LOCATIONS_LIST_LOADING) });
    axios.get(buildUrl(resource, 'locationlist'),
        axiosConfig())
        .then(responce => dispatch({ type: actionTypes.getActionType(resource, actionTypes.GET_CUSTOMER_LOCATIONS_LIST), data: responce }))
        .catch((err) => {
            console.error(err);
            console.log(err.response);
            dispatch({ type: actionTypes.getActionType(resource, actionTypes.GET_CUSTOMER_LOCATIONS_LIST_ERROR) });
        })
};