export const SET_CUSTOMER_DASHBOARD_DATA = 'SET_CUSTOMER_DASHBOARD_DATA';
export const CUSTOMER_DASHBOARD_LOADING = 'CUSTOMER_DASHBOARD_LOADING';
export const ERROR = 'ERROR';
export const RESET_ERROR = 'RESET_ERROR';

export const getActionType = (recource, key) => {
    return CUSTOMER_DASHBOARD_BUILD[key]
};

export const CUSTOMER_DASHBOARD_BUILD = {
    SET_CUSTOMER_DASHBOARD_DATA: 'SET_CUSTOMER_DASHBOARD_DATA',
    CUSTOMER_DASHBOARD_LOADING: 'CUSTOMER_DASHBOARD_LOADING',
    ERROR: 'ERROR',
    RESET_ERROR: 'RESET_ERROR'
};
