export const LOAD = 'LOAD';
export const ERROR = 'ERROR';
export const REACTED_ASSIGNED = 'REACTED_ASSIGNED';
export const REACTED_ASSIGNEDS = 'REACTED_ASSIGNEDS';
export const REACTED_AVAILABLE = 'REACTED_AVAILABLE';
export const REACTED_AVAILABLES = 'REACTED_AVAILABLES';
export const UNREACT_ORDERS = 'UNREACT_ORDERS';
export const SET_HEADER_WIDTH = 'SET_HEADER_WIDTH';
export const UNASSIGN_ORDERS = 'UNASSIGN_ORDERS';
export const ADD_NEW_LOAD = 'ADD_NEW_LOAD';
export const ASSIGN_ORDERS_TO_LOAD = 'ASSIGN_ORDERS_TO_LOAD';
export const ASSIGN_ORDERS_TO_NEW_LOAD = 'ASSIGN_ORDERS_TO_NEW_LOAD';
export const ASSIGN_ORDERS_TO_NEW_LOAD_WITH_MODAL = 'ASSIGN_ORDERS_TO_NEW_LOAD_WITH_MODAL';
export const DELETE_LOAD = 'DELETE_LOAD';
export const SET_IN_LOADING = 'SET_IN_LOADING';
export const GET_MANIFEST_LIST = 'GET_MANIFEST_LIST';
export const RELOAD_ON_ERROR = 'RELOAD_ON_ERROR';
export const RESET_ERROR = 'RESET_ERROR';
export const SET_DATA = 'SET_DATA';
export const SET_LOAD_DATA = 'SET_LOAD_DATA';
export const SET_DISPLAY_STATE = 'SET_DISPLAY_STATE';
export const UPDATE_LOAD_DATA = 'UPDATE_LOAD_DATA';
export const UPDATE_EFS_DATA = 'UPDATE_EFS_DATA';
export const CREATE_SUB_LOAD_ASSIGN_ORDERS = 'CREATE_SUB_LOAD_ASSIGN_ORDERS';
export const SET_ADD_NEW_IN_PROCESS = 'SET_ADD_NEW_IN_PROCESS';
export const SET_REFRESH_IN_PROCESS = 'SET_REFRESH_IN_PROCESS';
export const SET_CREATE_IN_PROCESS = 'SET_CREATE_IN_PROCESS';
export const SET_CREATE_SUB_IN_PROCESS = 'SET_CREATE_SUB_IN_PROCESS';
export const SET_SORT_LOAD_BY_CARRIER = 'SET_SORT_LOAD_BY_CARRIER';
export const SET_SORT_LOAD_BY_PICK_APPTS = 'SET_SORT_LOAD_BY_PICK_APPTS';
export const SET_SORT_LOAD_BY_PICK_LOCATION = 'SET_SORT_LOAD_BY_PICK_LOCATION';
export const SET_SORT_LOAD_BY_DROP_LOCATION = 'SET_SORT_LOAD_BY_DROP_LOCATION';
export const SET_SORT_LOAD_BY_DELIVER_NUMBER = 'SET_SORT_LOAD_BY_DELIVER_NUMBER';
export const FILTER_IN_OUT_ORDERS = 'FILTER_IN_OUT_ORDERS';
export const GET_HISTORY = 'GET_HISTORY';
export const GET_HISTORY_LOADING = 'GET_HISTORY_LOADING';
export const SET_RE_ORDER_LOAD = 'SET_RE_ORDER_LOAD';
export const FILTER_UNASSIGNED_ORDERS = 'FILTER_UNASSIGNED_ORDERS';

export const getActionType = (resource, key) => {

    switch (resource) {
        case 'manifestlist': return MANIFEST_LIST_BUILD[key];
        case 'outboundload': return OUTBOUND_LOAD_BUILD[key];
        default:
        case 'load': return INBOUND_LOAD_BUILD[key];
        
    }
};

export const INBOUND_LOAD_BUILD = {
    LOAD: 'INBOUND_LOAD',
    ERROR: 'INBOUND_ERROR',
    GET_MANIFEST_LIST : 'GET_MANIFEST_LIST',
    REACTED_ASSIGNED: 'INBOUND_REACTED_ASSIGNED',
    CHECKBOX_ASSIGNED: 'INBOUND_CHECKBOX_ASSIGNED',
    CHECKBOX_AVAILABLE: 'INBOUND_CHECKBOX_AVAILABLE',
    REACTED_ASSIGNEDS: 'INBOUND_REACTED_ASSIGNEDS',
    REACTED_AVAILABLE: 'INBOUND_REACTED_AVAILABLE',
    REACTED_AVAILABLES: 'INBOUND_REACTED_AVAILABLES',
    UNREACT_ORDERS: 'INBOUND_UNREACT_ORDERS',
    SET_HEADER_WIDTH: 'INBOUND_SET_HEADER_WIDTH',
    UNASSIGN_ORDERS: 'INBOUND_UNASSIGN_ORDERS',
    ADD_NEW_LOAD: 'INBOUND_ADD_NEW_LOAD',
    ASSIGN_ORDERS_TO_LOAD: 'INBOUND_ASSIGN_ORDERS_TO_LOAD',
    ASSIGN_ORDERS_TO_NEW_LOAD: 'INBOUND_ASSIGN_ORDERS_TO_NEW_LOAD',
    ASSIGN_ORDERS_TO_NEW_LOAD_WITH_MODAL: 'INBOUND_ASSIGN_ORDERS_TO_NEW_LOAD_WITH_MODAL',
    DELETE_LOAD: 'INBOUND_DELETE_LOAD',
    SET_IN_LOADING: 'INBOUND_SET_IN_LOADING',
    RELOAD_ON_ERROR: 'INBOUND_RELOAD_ON_ERROR',
    RESET_ERROR: 'INBOUND_RESET_ERROR',
    SET_DATA: 'INBOUND_SET_DATA',
    SET_LOAD_DATA: 'INBOUND_SET_LOAD_DATA',
    UPDATE_EFS_DATA: 'INBOUND_UPDATE_EFS_DATA',
    SET_DISPLAY_STATE: 'INBOUND_SET_DISPLAY_STATE',
    UPDATE_LOAD_DATA: 'INBOUND_UPDATE_LOAD_DATA',
    CREATE_SUB_LOAD_ASSIGN_ORDERS: 'INBOUND_CREATE_SUB_LOAD_ASSIGN_ORDERS',
    SET_ADD_NEW_IN_PROCESS: 'INBOUND_SET_ADD_NEW_IN_PROCESS',
    SET_REFRESH_IN_PROCESS: 'INBOUND_SET_REFRESH_IN_PROCESS',
    SET_CREATE_IN_PROCESS: 'INBOUND_SET_CREATE_IN_PROCESS',
    SET_CREATE_SUB_IN_PROCESS: 'INBOUND_SET_CREATE_SUB_IN_PROCESS',
    SET_SORT_LOAD_BY_CARRIER: 'INBOUND_SET_SORT_LOAD_BY_CARRIER',
    SET_SORT_LOAD_BY_PICK_APPTS: 'INBOUND_SET_SORT_LOAD_BY_PICK_APPTS',
    SET_SORT_LOAD_BY_PICK_LOCATION: 'INBOUND_SET_SORT_LOAD_BY_PICK_LOCATION',
    SET_SORT_LOAD_BY_DROP_LOCATION: 'INBOUND_SET_SORT_LOAD_BY_DROP_LOCATION',
    SET_SORT_LOAD_BY_DELIVER_NUMBER: 'INBOUND_SET_SORT_LOAD_BY_DELIVER_NUMBER',
    FILTER_IN_OUT_ORDERS: 'FILTER_IN_OUT_ORDERS',
    GET_HISTORY: 'INBOUND_GET_HISTORY',
    GET_HISTORY_LOADING: 'INBOUND_GET_HISTORY_LOADING',
    SET_RE_ORDER_LOAD: 'INBOUND_SET_RE_ORDER_LOAD',
    FILTER_UNASSIGNED_ORDERS: 'INBOUND_FILTER_UNASSIGNED_ORDERS',
};

export const OUTBOUND_LOAD_BUILD = {
    LOAD: 'OUTBOUND_LOAD',
    ERROR: 'OUTBOUND_ERROR',
    REACTED_ASSIGNED: 'OUTBOUND_REACTED_ASSIGNED',
    REACTED_ASSIGNEDS: 'OUTBOUND_REACTED_ASSIGNEDS',
    REACTED_AVAILABLE: 'OUTBOUND_REACTED_AVAILABLE',
    REACTED_AVAILABLES: 'OUTBOUND_REACTED_AVAILABLES',
    UNREACT_ORDERS: 'OUTBOUND_UNREACT_ORDERS',
    SET_HEADER_WIDTH: 'OUTBOUND_SET_HEADER_WIDTH',
    UNASSIGN_ORDERS: 'OUTBOUND_UNASSIGN_ORDERS',
    ADD_NEW_LOAD: 'OUTBOUND_ADD_NEW_LOAD',
    ASSIGN_ORDERS_TO_LOAD: 'OUTBOUND_ASSIGN_ORDERS_TO_LOAD',
    ASSIGN_ORDERS_TO_NEW_LOAD: 'OUTBOUND_ASSIGN_ORDERS_TO_NEW_LOAD',
    ASSIGN_ORDERS_TO_NEW_LOAD_WITH_MODAL: 'OUTBOUND_ASSIGN_ORDERS_TO_NEW_LOAD_WITH_MODAL',
    DELETE_LOAD: 'OUTBOUND_DELETE_LOAD',
    GET_MANIFEST_LIST: 'GET_MANIFEST_LIST',
    SET_IN_LOADING: 'OUTBOUND_SET_IN_LOADING',
    RELOAD_ON_ERROR: 'OUTBOUND_RELOAD_ON_ERROR',
    RESET_ERROR: 'OUTBOUND_RESET_ERROR',
    SET_DATA: 'OUTBOUND_SET_DATA',
    SET_LOAD_DATA: 'OUTBOUND_SET_LOAD_DATA',
    UPDATE_EFS_DATA: 'OUTBOUND_UPDATE_EFS_DATA',
    SET_DISPLAY_STATE: 'OUTBOUND_SET_DISPLAY_STATE',
    UPDATE_LOAD_DATA: 'OUTBOUND_UPDATE_LOAD_DATA',
    CREATE_SUB_LOAD_ASSIGN_ORDERS: 'OUTBOUND_CREATE_SUB_LOAD_ASSIGN_ORDERS',
    SET_ADD_NEW_IN_PROCESS: 'OUTBOUND_SET_ADD_NEW_IN_PROCESS',
    SET_REFRESH_IN_PROCESS: 'OUTBOUND_SET_REFRESH_IN_PROCESS',
    SET_CREATE_IN_PROCESS: 'OUTBOUND_SET_CREATE_IN_PROCESS',
    SET_CREATE_SUB_IN_PROCESS: 'OUTBOUND_SET_CREATE_SUB_IN_PROCESS',
    SET_SORT_LOAD_BY_CARRIER: 'OUTBOUND_SET_SORT_LOAD_BY_CARRIER',
    SET_SORT_LOAD_BY_PICK_APPTS: 'OUTBOUND_SET_SORT_LOAD_BY_PICK_APPTS',
    SET_SORT_LOAD_BY_PICK_LOCATION: 'OUTBOUND_SET_SORT_LOAD_BY_PICK_LOCATION',
    SET_SORT_LOAD_BY_DROP_LOCATION: 'OUTBOUND_SET_SORT_LOAD_BY_DROP_LOCATION',
    SET_SORT_LOAD_BY_DELIVER_NUMBER: 'OUTBOUND_SET_SORT_LOAD_BY_DELIVER_NUMBER',
    FILTER_IN_OUT_ORDERS: 'FILTER_IN_OUT_ORDERS',
    GET_HISTORY: 'OUTBOUND_GET_HISTORY',
    GET_HISTORY_LOADING: 'OUTBOUND_GET_HISTORY_LOADING',
    SET_RE_ORDER_LOAD: 'OUTBOUND_SET_RE_ORDER_LOAD',
    FILTER_UNASSIGNED_ORDERS: 'INBOUND_FILTER_UNASSIGNED_ORDERS',
};

export const MANIFEST_LIST_BUILD = {
    GET_MANIFEST_LIST: 'GET_MANIFEST_LIST',
    SET_IN_LOADING: 'SET_IN_LOADING',
}