import axios from 'axios';
import store from '../..';
import * as actionTypes from './commentsActionTypes';

const buildUrl = (resource, id) => `/api/${resource}/${id}`;
const buildUrlPut = resource => `/api/${resource}`;

const axiosConfig = () => ({
    headers: {
        'Authorization': 'Bearer ' + store.getState().auth.userToken
    }
});

export const getComments = (resource, id) => dispatch => {
    dispatch({ type: actionTypes.getActionType(resource, actionTypes.COMMENTS_LOADING) });
    axios.get(buildUrl(resource, id),
        axiosConfig())
        .then(responce => dispatch({ type: actionTypes.getActionType(resource, actionTypes.GET_COMMENTS), data: responce }))
        .catch((err) => {
            console.error(err);
            console.log(err.response);
            dispatch({ type: actionTypes.getActionType(resource, actionTypes.COMMENTS_ERROR) });
        })
};

export const setComment = (resource, orderId, newComment, attachment ) => dispatch => {
    const comment = {
        id: orderId,
        comment: newComment,
        attachments: attachment,
    };

    // dispatch({
    //     type: actionTypes.getActionType(resource, actionTypes.SET_COMMENT), data: {
    //         comment
    //     }
    // });

    dispatch({ type: actionTypes.getActionType(resource, actionTypes.COMMENTS_LOADING) })
    axios.put(buildUrlPut(resource), comment,
    axiosConfig())
    .then(response => {
        if (response.data.hasError) {
            dispatch(errorAction(resource, true, response.data.message));
            console.error(response.data.message);
        }
        dispatch(reloadAfterUpdate(resource, orderId))
    }).catch(err => {
        dispatch(errorAction(resource, true));
        console.error(err);
        console.log(err.response);
    });
};

export const resetError = resource =>
    ({ type: actionTypes.getActionType(resource, actionTypes.RESET_ERROR) });

const errorAction = (resource, reload, errorText) => dispatch => {
    if (reload) {
        axios.get(buildUrl(resource),
            axiosConfig()
        )
            .then(responce => dispatch({ type: actionTypes.getActionType(resource, actionTypes.RELOAD_ON_ERROR_COMMENTS), data: {
                responce, 
                errorText,
            }}))
            .catch((err) => {
                console.error(err);
                console.log(err.response);
                dispatch({ type: actionTypes.getActionType(resource, actionTypes.COMMENTS_ERROR) });
            });
    }
};

const reloadAfterUpdate = (resource, orderId) => dispatch => {
    axios.get(buildUrl(resource, orderId),
        axiosConfig()
    )
        .then(responce => dispatch({ type: actionTypes.getActionType(resource, actionTypes.GET_COMMENTS), data: responce }))
        .catch((err) => {
            console.error(err);
            console.log(err.response);
            dispatch({ type: actionTypes.getActionType(resource, actionTypes.COMMENTS_ERROR) });
        });
};
