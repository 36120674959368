import { TRAFFIC_CONTROL_BUILD as actionTypes } from '../../actions/trafficControl/trafficControlActionTypes';
import * as trafficControl from './trafficReducers';
import { getContentWidthExceptLeftAction } from './helper/general';

const defaultState = {
  headers: [
    {
      key: 'loadNum',
      title: 'Load #',
      width: 100,
      visible: true,
    },
    {
      key: 'carrierName',
      title: 'CARRIER',
      width: 80,
      visible: true,
    },
    {
      key: 'driverName',
      title: 'DRIVER NAME',
      width: 90,
      visible: true,
      pickGroup: true,
    },
    {
      key: 'driverPhone',
      title: 'DRIVER #',
      width: 80,
      visible: true,
      pickGroup: true,
    },
    {
      key: 'st',
      title: 'ST',
      width: 50,
      visible: true,
    },
    {
      key: 'palIn',
      title: 'PAL IN',
      width: 50,
      visible: true,
      pickGroup: true,
    },
    {
      key: 'palOut',
      title: 'PAL OUT',
      width: 55,
      visible: true,
      pickGroup: true,
    },
    {
      key: 'day',
      title: 'DAY',
      width: 50,
      visible: true,
    },
    {
      key: 'time',
      title: 'TIME',
      width: 50,
      visible: true,
    },
    {
      key: 'customer',
      title: 'CUST',
      width: 100,
      visible: true,
      dropGroup: true,
    },
    {
      key: 'in',
      title: 'IN',
      width: 50,
      visible: true,
      dropGroup: true,
    },
    {
      key: 'out',
      title: 'OUT',
      width: 50,
      visible: true,
      dropGroup: true,
    },
    {
      key: 'whDay',
      title: 'DAY',
      width: 50,
      visible: true,
    },
    {
      key: 'whTime',
      title: 'WH TIME',
      width: 60,
      visible: true,
    },
    {
      key: 'eta',
      title: 'ETA',
      width: 85,
      visible: true,
    },
    {
      key: 'checkIn',
      title: 'CHECK IN',
      width: 60,
      visible: true,
    },
    {
      key: 'checkOut',
      title: 'CHECK OUT',
      width: 70,
      visible: true,
    },
    {
      key: 'firstDrop',
      title: '1ST DROP',
      width: 100,
      visible: true,
    },
    {
      key: 'truckVerified',
      title: 'TRUCK VERIFIED',
      width: 100,
      visible: true,
    },
    {
      key: 'inboundsNeeded',
      title: 'INBOUNDS NEEDED',
      width: 120,
      visible: true,
    },
    {
      key: 'trafficNote',
      title: 'ADD NOTES',
      width: 100,
      visible: true,
    },
    {
      key: 'status',
      title: 'STATUS',
      width: 70,
      visible: true,
    },
    {
      key: 'status2',
      title: '',
      width: 80,
      visible: true,
    },
    {
      key: 'status3',
      title: '',
      width: 80,
      visible: true,
    },
  ],
  subHeaders: [
    {
      key: 'empty1',
      title: '',
      width: 400,
      visible: true,
    },
    {
      key: 'netPal',
      title: 'NET PAL',
      width: 50,
      visible: true,
    },
    {
      key: 'empty2',
      title: '',
      width: 55,
      visible: true,
    },
    {
      key: 'previouSP',
      title: 'PREVIOUS P/U',
      width: 300,
      visible: true,
    },
    {
      key: 'sanFernando',
      title: 'SAN FERNANDO',
      width: 325,
      visible: true,
      pickGroup: true,
    },
    {
      key: 'empty3',
      title: '',
      width: 650,
      visible: true,
      pickGroup: true,
    },
  ],
  currentAndNextHeaders: [
    {
      key: 'title',
      width: () => 150,
    },
    {
      key: 'empty',
      width: headers => getContentWidthExceptLeftAction(headers) - 150,
    },
  ],
  loading: true,
  hasError: false,
  message: '',
  total: 0,
  existing: 0,
  doneTrafficList: null,
  currentTrafficList: null,
  nextTrafficList: null,
  currentAndNextTrafficList: null,
  lastSelected: {
    id: null,
    headerKey: null,
    enable: false,
  },
  inLoadNeedId: '',
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.SET_IN_LOADING:
      return trafficControl.setInLoading(state);
    case actionTypes.ERROR:
      return trafficControl.setError(state, action.data);
    case actionTypes.SET_HEADER_WIDTH_TRAFFIC:
      return trafficControl.setHeaderWidth(state, action.data.ind, action.data.width);
    case actionTypes.RESET_ERROR:
      return trafficControl.resetError(state);
    case actionTypes.RELOAD_ON_ERROR_TRAFFIC:
      return trafficControl.setTrafficControl(state, action.data);
    case actionTypes.GET_TRAFFIC_CONTROL:
      return trafficControl.setTrafficControl(state, action.data);
    case actionTypes.SET_DATA:
      return trafficControl.setData(state, action.data.value, action.data.rowState, action.data.header, action.data.subType);
    case actionTypes.SET_DISPLAY_STATE:
      return trafficControl.setDisplayState(state, action.data.rowState, action.data.header, action.data.enable);
    case actionTypes.UPDATE_LOAD_STATUS:
      return trafficControl.updateLoadStatus(state, action.data.newStatus, action.data.rowState, action.data.header, action.data.loadId,);
    case actionTypes.RE_ORDER_LOADS:
      return trafficControl.reOrderLoads(state, action.data.newList);
    case actionTypes.SET_LOAD_NEED_ID:
      return trafficControl.setLoadNeedId(state, action.data);
      default:
      return state;
  }
};
