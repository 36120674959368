import React, { useState, useEffect } from "react";
import { message } from "antd";
import { ResponsivePie } from "@nivo/pie";

import axios from "../../../axios/axios";
import { ArraySum } from "../../../helper/common";

const PieChart = props => {
    const [data, setData] = useState([]);
    const resource = props.Resource;
    const changesCount = props.changesCount;

    useEffect(() => {
        let isMounted = true;
        const fetchData = async (isMounted) => {
            const responseOrderStatus = await axios(
                resource
            );
            if (isMounted) {
                if (responseOrderStatus) {
                    const result = responseOrderStatus.data;
                    if (result) {
                        if (result.hasError) {
                            message.error(result.message);

                            return;
                        }

                        const labels = {
                            openCount: 'Open',
                            inboundProgressCount: 'Inbound Progress',
                            receivedCount: 'Received',
                            outboundProgressCount: 'Outbound Progress',
                            deliveredCount: 'Delivered'
                        };
                        const sum = ArraySum(Object.keys(labels).map(l => result[l]));

                        setData(Object.keys(labels).map((l, i) => ({
                            id: i,
                            label: labels[l],
                            value: +((result[l] / sum * 100).toFixed(2))
                        })));
                    }
                }
            }
        };
        fetchData(isMounted);
        return () => {
            isMounted = false;
        };
    }, [resource, changesCount]);

    return (
        <ResponsivePie
            data={data}
            margin={{ top: 40, right: 0, bottom: 80, left: 0 }}
            sortByValue
            innerRadius={0.7}
            padAngle={1}
            cornerRadius={3}
            activeOuterRadiusOffset={8}
            colors={['#b3e5fc', '#29b6f6', '#039be5', '#0277bd', '#01579b']}
            borderColor={{ from: 'color', modifiers: [['darker', '0']] }}
            arcLinkLabel="label"
            arcLinkLabelsSkipAngle={25}
            arcLinkLabelsTextOffset={10}
            arcLinkLabelsTextColor="#333333"
            arcLinkLabelsOffset={10}
            arcLinkLabelsDiagonalLength={20}
            arcLinkLabelsStraightLength={25}
            arcLinkLabelsThickness={3}
            arcLinkLabelsColor={{ from: 'color' }}
            arcLabel={e => e.value ? `${e.value}%` : ''}
            arcLabelsTextColor={{ from: 'color', modifiers: [['darker', '3']] }}
            tooltip={({ datum: { id, value, label, color } }) => (
                <div
                    style={{
                        padding: 12,
                        color,
                        background: '#222222',
                    }}
                >
                    <strong>
                        {label}: {`${(value)}%`}
                    </strong>
                </div>
            )}
            legends={[
                {
                    anchor: 'bottom',
                    direction: 'row',
                    justify: false,
                    translateX: 0,
                    translateY: 60,
                    itemsSpacing: 25,
                    itemWidth: 100,
                    itemHeight: 10,
                    itemTextColor: '#999',
                    itemDirection: 'left-to-right',
                    itemOpacity: 1,
                    symbolSize: 18,
                    symbolShape: 'circle',
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemTextColor: '#000'
                            }
                        }
                    ]
                }
            ]}
        />
    );
};

export default PieChart;