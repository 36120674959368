import {
  ElementTypes,
  ElementValidations,
  GenerateToolBars
} from '../../config';

const SendMail = {
  Resource: 'PDFGenerator/sendrateconfirmation',
  Master: 'loadNumber',
  IsRest: true,
  Key: 'loadId',
  Form: {
    Title: 'CC Mail To...',
    SectionGroups: [
      {
        Sections: [
          {
            Fields: {
              ccAddresses: {
                Field: ElementTypes.Comment(),
                Validations: [ElementValidations.EmailList()]
              }
            }
          }
        ]
      }
    ],
    ToolBars: GenerateToolBars(['Send', 'Cancel'])
  }
};

export default SendMail;