import React from 'react';
import { connect } from "react-redux";

const RegionCell = (
    {
        state,
        cellData,
        itemSize,
        onClickRow,
        resource
    }) => {
    const loadArea = state[resource];
    const width = loadArea.regionHeaders.find(h => h.key === cellData.key).width(loadArea.headers);

    let cellStyle = {
        maxWidth: `${width}px`,
        width: `${width}px`,
        minWidth: `${width}px`,
        height: `${itemSize}px`
    };

    return (
        <div onClick={() => onClickRow()}
            className='table-body-cell'
            style={cellStyle}>
        </div>
    );
};
export default connect(state => ({
    state: state
}))(RegionCell);