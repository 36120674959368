import { ArraySum } from '../../../helper/common';

export class Stop {
    key = null;
    id = null;
    isFirst = true;
    isEmpty = false;
    loadId = null;
    loadNumber = null;//ned from backend + InboundLoadNumber +
    //totalPallets = 0; //calculate + 
    //totalWeight = 0; //calculate



    loadTotalPallets = 0; //calculate
    loadTotalWeight = 0; //calculate

    truckNumber = null;//need from backend + TruckNumber
    driverName = null; //need from backend + DriverName
    driverPhone = null; //need from backend + DriverPhone
    rate = null; //need from backend + InboundRate +
    customer = null; // +
    customerId = null;
    customerTooltip = 'No Customer';
    pickLocationId = null;
    pickAppointment = null; //need from backend + InboundAppointment +
    pickStatus = null; //need from backend + InbioundAppointmentStatus +
    pickCity = null; //+
    pickState = null;
    pickWarehouse = null; //+
    dropLocationId = null;
    dropAppointment = null;//need from backend + inboundDropAppointment +
    dropStatus = null;//need from backend + DropInboundAppointmentStatus +
    dropCity = null;
    dropState = null;
    dropWarehouse = null; //+
    isWarehouse = true;
    warehouseId = 1;
    sequencesName = null;
    inboundStopNotes = null;
    outboundStopNotes = null;
    carrierName = null;
    trailer = null;
    color = null;
    readyToBook = false;
    truckId = null;
    carrierId = null;
    inTransit = false;
    isInbound = false;
    in = null;
    out = null;

    orders = [];


    get poNumbers() {
        return this.orders.reduce((r, a) => {
            if (r !== '') {
                if (a.poNumber !== '') {
                    r = `${r}, ${a.poNumber}`;
                }
            }
            else {
                r = a.poNumber;
            }
            return r;
        }, '');
    };

    get poNumberCount() {
        return this.orders.length;
    };

    get totalPallets() {
        return ArraySum(this.orders, 'palletCount');
    };

    get totalWeight() {
        return ArraySum(this.orders, 'weight');
    };

    get gridKey() {
        if (this.isFirst) {
            return this.loadId;
        }
        return this.key;
    };
}