import React, { Suspense } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { Spin } from 'antd';
import Main from './codeless/components/layout/main';
import ForgotPassword from './codeless/pages/forgotPassword'; //'./pages/ForgotPassword';
import ChangePassword from './codeless/pages/changePassword';//'./pages/ChangePassword';
import NotFound from './codeless/pages/notFound';
import { Template } from './Template';
import Login from './codeless/pages/login';
import Logout from './codeless/pages/logout';
/*import CacheBuster from './CacheBuster';*/

import CacheBusterRoute from './CacheBusterRoute';

//FOR DEVELOPMENT, GENERATE ELEMENT_CONFIQ ARGS
//import { Development_GenerateFunctionArguments } from './codeless/config/element.config';
//Development_GenerateFunctionArguments();
/**
*  the Base component that also manages routing
 * @module App
 */
export default function App() {
    const loading = <div style={{ "margin": "25% auto" }}><Spin size="large" /></div>;
    //return (
    //    <CacheBuster>
    //        {({ loadingApp, isLatestVersion, refreshCacheAndReload }) => {
    //            if (loadingApp) return null;
    //            if (!loadingApp && !isLatestVersion) {
    //                refreshCacheAndReload();
    //                console.log(loadingApp, isLatestVersion);
    //            }

                return (
                    <Suspense fallback={loading}>
                        <Main>
                            <Switch>
                                <CacheBusterRoute exact path='/' >
                                    <Redirect to="/login" />
                                </CacheBusterRoute>
                                <CacheBusterRoute path='/login' component={Login} />
                                <CacheBusterRoute path='/logout' component={Logout} />
                                <CacheBusterRoute path='/forgot' component={ForgotPassword} />
                                <CacheBusterRoute path='/changepassword/:replyCode' component={ChangePassword} />

                                <CacheBusterRoute path='/template' component={Template} />
                                <CacheBusterRoute path="*" component={NotFound} />
                            </Switch>
                        </Main>
                    </Suspense>
                );
    //        }}
    //    </CacheBuster>
    //);
}
