import React from "react";

export default (data) => (
  <div>
    <div>
      <b>{data.name}</b>
    </div>
    <div>{data.address}</div>
    <div>{data.addressLocation}</div>
    <div>{data.phone}</div>
    <div>{data.fax}</div>
  </div>
)