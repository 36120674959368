import React, { useEffect, useState, useMemo } from 'react';
import SubNavigation from '../navigation/subNavigation';
import { Project } from '../../../../project/Project';

const SubToolbar = props => {
    const [managedMenu, setManagedMenu] = useState(null);
    useEffect(() => {
        setManagedMenu(Project.createMenu(props.menu, props.parentUrl));
    }, [props.menu, props.parentUrl]);
    const location = window.location.pathname.toLowerCase();

    return (<toolbar className='subToolbar subHeaders' style={{ height: 'auto'}}>
        {
            useMemo(() => <div className="nav_strip" launcher="" id="launcher">
                {managedMenu ? <SubNavigation
                    managedMenu={managedMenu}
                    location={location}
                    closeAllPopups={props.closeAllPopups}
                    parentMenu={props.parentMenu}
                    /> : null}
            </div>, [managedMenu, location, props.closeAllPopups, props.parentMenu])
        }
    </toolbar>);
};

export default SubToolbar;