import React from 'react';
import { Link } from 'react-router-dom';
import store from '../../../../store';

const UserMenu = profiles => {
    const menuList = store.getState().auth.userMenu;
    const orgMenu = menuList.find(m => m.name === 'Organization');
    const userMenu = menuList.find(m => m.name === 'UserProfile');

    return <div className="list_menu ant-dropdown-menu">
        <ul>
            {/*<li command="user">
                <a>
                    <icon></icon>
                    <text>My Account</text>
                </a>
            </li>
            <li className="separator"></li>*/}
            {userMenu ? <li command="profile" onClick={profiles.openUserProfile}><icon></icon><text>Profile</text></li> : null}
            {orgMenu ? <li command="org" onClick={profiles.openOrgProfile}><icon></icon><text>Organization</text></li> : null}
            {(userMenu || orgMenu) && <li className="separator"></li>}
            <li command="easter" effect="material" className="disabled" bottom="">
                <icon>info</icon>
                <text>Version</text>
                <separator vertical="">
                </separator>
                <text>
                    <b>{process.env.REACT_APP_VERSION}</b>
                </text>
                <text>
                    <count data-count="">
                    </count>
                </text>
            </li>
            <li className="separator"></li>
            <li>
                <Link to="/logout">
                    <icon></icon>
                    <text>Sign Out</text>
                </Link>
            </li>
        </ul>
    </div>
};

export default UserMenu;