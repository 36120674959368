import { useEffect } from 'react';

import { DefaultMessageTypes } from '../actions'

export const useElementValidationFocus = (messages, ref, messageIdentity) => {
    useEffect(() => {
        if (messages && messages[DefaultMessageTypes.SET_FOCUSE]
            && messages[DefaultMessageTypes.SET_FOCUSE] === messageIdentity
            && messages[DefaultMessageTypes.START_FOCUSE]
            && ref && ref.current//) {
            && ref.current.focus && typeof ref.current.focus === 'function') {
            ref.current.focus();
        }
    });
};

export const useElementDefaultFocus = (ref, setFocus) => {
    useEffect(() => {
        if (setFocus && ref && ref.current) {
            ref.current.focus();
        }
    }, [setFocus, ref]);
};