import axios from 'axios';
import moment from 'moment';
import store from '../..';
import * as actionTypes from './deliveredLoadsActionTypes';

const buildUrl = (resource, endpoint = '') => `/api/${resource}/${endpoint}`;

const axiosConfig = () => ({
    headers: {
        'Authorization': 'Bearer ' + store.getState().auth.userToken
    }
});

export const load = (resource, loadingType) => dispatch => {
    if (loadingType !== 'withoutLoading') {
        dispatch({ type: actionTypes.getActionType(resource, actionTypes.GET_DELIVERED_LOADS_LOADING) });
    };
    axios.get(buildUrl(resource, 'area'),
        axiosConfig())
        .then(responce => dispatch({ type: actionTypes.getActionType(resource, actionTypes.GET_DELIVERED_LOADS), data: responce }))
        .catch((err) => {
            console.error(err);
            console.log(err.response);
            dispatch({ type: actionTypes.getActionType(resource, actionTypes.DELIVERED_LOADS_ERROR) });
        })
};

export const reactOnAssignedItem = (resource, id, type) =>
    ({ type: actionTypes.getActionType(resource, actionTypes.REACTED_ASSIGNED_DELIVERED_LOADS), data: { id, type } });

export const setHeaderWidth = (resource, ind, width) =>
    ({ type: actionTypes.getActionType(resource, actionTypes.SET_HEADER_WIDTH_DELIVERED_LOADS), data: { ind, width } });

export const setItemData = (resource, value, rowState, header, loadsAssignedOrders) => dispatch => {
    dispatch({
        type: actionTypes.getActionType(resource, actionTypes.SET_DELIVERED_LOADS_DATA), data: {
            value,
            rowState,
            header
        }
    });

    let requestData = {};
    let orderDelayedReasonByCall;
    let orderStatusByCall;
    let orderNotesByCall;
    let loadCallCheckNote;
    let pickInDate;
    let pickOutDate;
    let dropInDate;
    let dropOutDate;
    let podRequestStatus;
    let callCheckAttachment;
    let browserDateTime = moment.utc(new Date()).local().utc(true).format();

    const order =  rowState.orderData;
    if (rowState.loadData.loadType === 3) {
        orderDelayedReasonByCall = order.outDelayedReason;
        orderStatusByCall = order.outOrderStatusByCall;
        orderNotesByCall = order?.outNotes;
        podRequestStatus = order?.outPodRequestStatus
        pickInDate = order.outboundPickIn;
        pickOutDate = order.outboundPickOut;
        dropInDate = order.outboundDropIn;
        dropOutDate = order.outboundDropOut;
        callCheckAttachment = order.callCheckOutAttachment;
        loadCallCheckNote = order.outLoadCallCheckNote;
    } else if (rowState.loadData.loadType === 1) {
        orderDelayedReasonByCall = order.inDelayedReason;
        orderStatusByCall = order.inOrderStatusByCall;
        orderNotesByCall = order.inNotes;
        podRequestStatus = order?.inPodRequestStatus;
        pickInDate = order.inboundPickIn;
        pickOutDate = order.inboundPickOut;
        dropInDate = order.inboundDropIn;
        dropOutDate = order.inboundDropOut;
        callCheckAttachment = order.callCheckInAttachment;
        loadCallCheckNote = order.inLoadCallCheckNote;
    }
    requestData = {
        orderId: order.id,
        loadId: rowState.loadData?.id,
        orderStatusByCall,
        orderDelayedReasonByCall,
        orderNotesByCall,
        podRequestStatus,
        loadCallCheckNote,
        callCheckAttachment,
        pickInDate,
        pickOutDate,
        dropInDate,
        dropOutDate,
        browserDateTime,
    }

    if (header.key === 'orderStatusByCall') {
        requestData.orderStatusByCall = value;
        requestData.orderDelayedReasonByCall = null;
    } else if (header.key === 'delayedReason') {
        requestData.orderDelayedReasonByCall = value;
    } else if (header.key === 'orderNotesByCall') {
        requestData.orderNotesByCall = value;
    } else if (header.key === 'podRequestStatus') {
        requestData.podRequestStatus = value;
    } else if (header.key === 'podDoc') {
        requestData.callCheckAttachment = value;
    } else if (header.key === 'pickInDate') {
        requestData.pickInDate = value;
    } else if (header.key === 'pickOutDate') {
        requestData.pickOutDate = value;
    } else if (header.key === 'dropInDate') {
        requestData.dropInDate =  value
    } else if (header.key === 'dropOutDate') {
        requestData.dropOutDate = value;
    } else if (header.key === 'loadCallCheckNote') {
        requestData.loadCallCheckNote = value;
    }

    axios.put(buildUrl('AccountPayable', 'loadorderitem'), requestData,
        axiosConfig())
        .then(response => {
            if (response.data.hasError) {
                dispatch(errorAction(resource, true, response.data.message));
                console.error(response.data.message);
            } else if ((response.data.callCheckInAttachment || response.data.callCheckOutAttachment) && header.key === 'podDoc') {
                dispatch({ type: actionTypes.getActionType(resource, actionTypes.SET_DELIVERED_LOADS_DATA), data: {
                        value:  rowState.loadData.loadType === 1 ? response.data.callCheckInAttachment :
                            rowState.loadData.loadType === 3 ? response.data.callCheckOutAttachment : '',
                        rowState,
                        header
                    }
                })
            } else if (header.key === 'orderNotesByCall') {
                dispatch(reloadAfterUpdate(resource))
            }
        }).catch(err => {
        dispatch(errorAction(resource, true));
        console.error(err);
        console.log(err.response);
    });
};

export const setItemDisplayState = (resource, rowState, header, enable) => ({
    type: actionTypes.getActionType(resource, actionTypes.SET_DISPLAY_STATE_DELIVERED_LOADS), data: {
        rowState,
        header,
        enable
    }
});

export const filterDeliveredLoads = (resource, key) => ({
    type: actionTypes.getActionType(resource, actionTypes.FILTER_DELIVERED_LOADS),
    data: key
});
export const sortBy = (resource, sortValue) => dispatch => {
    dispatch({
        type: actionTypes.getActionType(resource, actionTypes.SORT_DELIVERED_LOADS), data: sortValue
    });
}
export const resetError = resource =>
    ({ type: actionTypes.getActionType(resource, actionTypes.RESET_ERROR) });

const errorAction = (resource, reload) => dispatch => {
    if (reload) {
        axios.get(buildUrl(resource),
            axiosConfig()
        )
            .then(responce => dispatch({ type: actionTypes.getActionType(resource, actionTypes.RELOAD_ON_ERROR_DELIVERED_LOADS), data: responce }))
            .catch((err) => {
                console.error(err);
                console.log(err.response);
                dispatch({ type: actionTypes.getActionType(resource, actionTypes.DELIVERED_LOADS_ERROR) });
            });
    }
};

const reloadAfterUpdate = (resource) => dispatch => {
    axios.get(buildUrl(resource, 'area'),
        axiosConfig()
    )
        .then(responce => dispatch({ type: actionTypes.getActionType(resource, actionTypes.GET_DELIVERED_LOADS), data: responce }))
        .catch((err) => {
            console.error(err);
            console.log(err.response);
            dispatch({ type: actionTypes.getActionType(resource, actionTypes.DELIVERED_LOADS_ERROR) });
        });
};