import { useReducer } from 'react';
import moment from 'moment';

import { DefaultDataTypes } from '../actions';
import { Defines } from '../../../project/Defines';

export const setValue = (newData, propName, action) => {
    newData[propName] = action.value;
    if (action.dependencies) {
        for (let i in action.dependencies) {
            if (Array.isArray(action.dependencies[i])) {
                const destination = action.dependencies[i][0];
                const source = action.dependencies[i][1];
                const landing = action.outSource?.filter(order => order.value === newData.dropLocationId)[0]?.landing;
                if (action.outSource && Array.isArray(action.outSource)) {
                    const selectedOutSource = action.outSource.find(v => v['value'] === action.value);
                    if (selectedOutSource && selectedOutSource[source] != null) {
                        setValue(newData, destination, { value: selectedOutSource[source], dependencies: null, outSource: null });
                    }
                    else if (selectedOutSource && selectedOutSource[source] === null) {
                        setValue(newData, destination, { value: '', dependencies: null, outSource: null });
                    }
                    else {
                        setValue(newData, action.dependencies[i], { value: '', dependencies: null, outSource: null });
                    }
                }
                else {
                    setValue(newData, action.dependencies[i], { value: '', dependencies: null, outSource: null });
                }
                if ((propName === 'dropLocationId' && (destination === 'deliverDate' || destination === 'deliverDate2'))
                    || propName === 'dueDate') {
                    const date = new Date(newData.dueDate);
                    const selectedWeekNumber = date.getWeek();
                    const selectedWeekDay = moment(date).day();
                    let momentWeekDay = '';
                    let landingValue = document.getElementById("landingId").innerHTML;
                    let landingText = "";
                    if (landingValue.includes('/')) {
                        landingValue = landingValue.split('/')[0];
                    };
                    switch (landingValue) {
                        case 'MON':
                            landingText = 1;
                            break;
                        case 'TUES':
                            landingText = 2;
                            break;
                        case 'WED':
                            landingText = 3;
                            break;
                        case 'THURS':
                            landingText = 4;
                            break;
                        case 'FRI':
                            landingText = 5;
                            break;
                        case 'SAT':
                            landingText = 6;
                            break;
                        case 'SUN':
                            landingText = 7;
                            break;
                        default:
                            landingText = '';
                            break;
                    };
                    const finalLanding = landing ? landing : landingText;

                    if (newData.dueDate && finalLanding) {
                        if (Number(selectedWeekDay) >= Number(finalLanding)) {
                            momentWeekDay = moment().day(finalLanding).week(selectedWeekNumber);                            
                        } else {
                            momentWeekDay = moment().day(finalLanding).week(selectedWeekNumber-1);
                        }
                        const momentDate = moment.utc(momentWeekDay).local().format(Defines.Format.PrintDate)
                        setValue(newData, 'deliverDate', { value: momentDate, dependencies: null, outSource: null });
                        setValue(newData, 'deliverDate2', { value: momentDate, dependencies: null, outSource: null });
                    }
                }
            }
            else if (typeof (action.dependencies[i]) === 'object') {
                for (let dipoIn in action.dependencies[i]) {
                    const dipo = newData[dipoIn];
                    if (dipo && Array.isArray(dipo)) {
                        for (let el in dipo) {
                            setValue(dipo[el], action.dependencies[i][dipoIn], { value: '', dependencies: null, outSource: null });
                        }
                    }
                }
            }
            else if (typeof (action.dependencies[i]) === 'function') {
                action.dependencies[i](newData, setValue);
            }
            else {
                setValue(newData, action.dependencies[i], { value: '', dependencies: null, outSource: null });
            }
        }
    }
};

let _GENERAL_INIT_COUNT_ = 0;

export const useData = (data) => {
    const setData = (state, action) => {
        const type = action.type;
        if (type === DefaultDataTypes.INIT) {
            if (action.data && !Array.isArray(action.data) && typeof (action.data) === 'object') {
                if (_GENERAL_INIT_COUNT_ === Number.MAX_VALUE) {
                    _GENERAL_INIT_COUNT_ = 0;
                }
                _GENERAL_INIT_COUNT_++;
                action.data._INIT_COUNT_ = _GENERAL_INIT_COUNT_;
            }
            return action.data;
        }
        else {
            if (state[type] !== action.value) {
                const newState = { ...state };
                setValue(newState, type, action);
                return newState;
            }
        }
        return state;
    };
    return useReducer(setData, data);
};