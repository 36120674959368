import { useReducer } from 'react';

import { ArrayEquals } from '../../helper/common';
import { DefaultMessageTypes } from '../actions';

export const useMessage = (form) => {
    const setMessages = (state, action) => {
        const type = action.type;
        const messages = action.messages;
        if (type === DefaultMessageTypes.START_FOCUSE) {
            if (state[DefaultMessageTypes.START_FOCUSE] !== messages) {
                const newState = { ...state };
                newState[DefaultMessageTypes.START_FOCUSE] = messages;
                return newState;
            }
            return state;
        }
        if (!ArrayEquals(state[type], messages)) {
            const newState = { ...state };

            if(action.messageType === 'warning') {
                newState.warnings = {
                    ...newState.warnings,
                    [type]: messages
                };
            } else {
                newState[type] = messages;
            }
            newState[DefaultMessageTypes.SET_FOCUSE] = null;
            for (let t in newState) {
                if (t !== DefaultMessageTypes.SET_FOCUSE && newState[t] && newState[t].length > 0) {
                    newState[DefaultMessageTypes.SET_FOCUSE] = t;
                    break;
                }
            }
            return newState;
        }
        return state;
    };
    let defValidations = { SET_FOCUSE: null, START_FOCUSE: false };

    const setFields = (parent, process) => {
        if (typeof parent === 'object') {
            for (let t in parent) {
                if(t === 'Components') {
                    for (let c in parent[t]) {
                        const comp = parent[t][c].Component();

                        comp && comp.props && comp.props.columns && setFields(comp.props.columns, false);
                    }
                }
                
                if (process) {
                    defValidations[t] = [];
                }
                else if (t === 'Fields') {
                    setFields(parent[t], true);
                }
                else {
                    setFields(parent[t], false);
                }
            }
        }
    };

    setFields(form, false);
    return useReducer(setMessages, defValidations);
};

