import { orderBy } from "lodash";

const itemDefaultSize = 28;

export const createWebOrder = (oData, headers) => {
  const data = { ...oData };
  const loadStatus = data.loadStatus;
  const orderWebData = {
    row: [],
    rowState: {
      id: `load_${data.loadId}`,
      loadId: data.loadId,
      type: data.loadType,
      loadData: data,
      height: itemDefaultSize,
      loadStatus,
    },
  };

  for (let headerIndex = 0; headerIndex < headers.length; headerIndex++) {
    const headerItem = headers[headerIndex];
    let value = null;
    let fieldType = 'text';
    switch (headerItem.key) {
      case 'customer':
        value = data.customerName;
        fieldType = 'checkbox';
        break;
      case 'readyDate':
        value = data.readyDate;
        fieldType = 'dateTime';
        break;
      case 'dueDate':
        value = data.dueDate;
        fieldType = 'dateTime';
        break;
      case 'pickLocation':
        value = data.pickLocation;
        fieldType = 'text';
        break;
      case 'dropLocation':
        value = data.dropLocation;
        fieldType = 'text';
        break;
      case 'poNumber':
        value = data.poNumber;
        fieldType = 'text';
        break;
      case 'soNumber':
        value = data.soNumber;
        break;
      case 'palletCount':
        value = data.palletCount;
        fieldType = 'text';
        break;
      case 'lbs':
        fieldType = 'text';
        value = data.weight;
        break;
      case 'space':
        fieldType = 'text';
        value = data.space;
        break;
      case 'temperature':
        value = data.temperature === 1 ? 'Frozen' : 
                data.temperature === 2 ? 'Chilled' :
                data.temperature === 3 ? 'Dry' :
                data.temperature === 4 ? 'C|F' : '';
        fieldType = 'text';
        break;
      // case 'freight':
      //   value = data.freight;
      //   fieldType = 'text';
      //   break;
      case 'orderCycle':
        value = 'Requested';
        break;
      default:
        break;
    }
    orderWebData.row.push({
      key: headerItem.key,
      value,
      fieldType,
    });
  }
  return orderWebData;
};

const createCustomerOrdersList = (state, customerOrders) => customerOrders.map(data => createWebOrder(data, state.headers));

export const customerOrderAdmin = (state, responce, hasError = false, message = '') => {
  const { data } = responce;
  const customerOrderAdmin = createCustomerOrdersList(state, data.data);

  return {
    ...state,
    ...{
      loading: false,
      hasError: false,
      messageText: '',
      customerOrderAdmin: customerOrderAdmin,
      total: data.total,
      orderGenerated: false,
    },
  };
};

export const generateCustomerOrder = (state, responce) => {
  return {
    ...state,
    ...{
      generateCustomerOrderLoading: false,
      total: state.total - 1,
      hasError: responce.data.hasError,
      messageText: responce?.data.message ? responce?.data.message : '',
      orderGenerated: !responce.data.hasError,
    },
  };
};

export const rejectCustomerOrder = (state, responce) => {
  return {
    ...state,
    ...{
      rejectCustomerOrderLoading: false,
      total: state.total - 1,
      hasError: responce.data.hasError,
      messageText: responce?.data.message ? responce?.data.message : '',
      orderRejected: !responce.data.hasError,
    },
  };
};

export const filterByCustomer = (state, customerId) => ({
  ...state,
  ...{
    filteredCustomerOrderAdmin: state.sortedCustomerOrderAdmin ? state.sortedCustomerOrderAdmin.filter(item => item.rowState.loadData.customerId === customerId) : state.customerOrderAdmin?.filter(item => item.rowState.loadData.customerId === customerId),
  },
});

const sortByValue = (customerOrderAdmin, value) => {
  let sortedList = [];
  if (value === 'byCustomer') {
    sortedList = orderBy(customerOrderAdmin, [c => c.row[0].value], ["asc"]);
  };
  if (value === 'byDate') {
    sortedList = orderBy(customerOrderAdmin, [c => c.row[1].value], ["asc"]);
  };
  if (value === 'byDirection') {
    sortedList = orderBy(customerOrderAdmin, [c => c.row[4].value], ["asc"]);
  };
  return sortedList;
};

export const sortBy = (state, sortValue) => ({
  ...state,
  ...{
    sortedCustomerOrderAdmin: state.filteredCustomerOrderAdmin?.length ? sortByValue(state.filteredCustomerOrderAdmin, sortValue) : sortByValue(state.customerOrderAdmin, sortValue),
  },
});

export const setDisplayState = (state, rowState, header, enable) => ({
  ...state,
  ...{
    lastSelected: {
      id: rowState.id,
      headerKey: header.key,
      enable: enable,
    },
  },
});

export const setHeaderWidth = (state, ind, width) => {
  const header = state.headers[ind];
  header.width = width;
  return {
    ...state,
    ...{
      headers: [...state.headers.slice(0, ind), ...[header], ...state.headers.slice(ind + 1)],
    },
  };
};

export const loadContent = (state, response, hasError = false, message = '') => {
  return customerOrderAdmin(state, response, hasError, message);
};

export const setError = (state, err) => ({
  ...state,
  ...{
    hasError: true,
    messageText: err?.statusText ? err?.statusText : 'Something went wrong, please try again',
  },
});

export const resetError = state => ({
  ...state,
  ...{
    hasError: false,
    message: '',
    generateCustomerOrderLoading: false,
    rejectCustomerOrderLoading: false,
    orderRejected: false,
    orderGenerated: false,
  },
});

export const setInLoading = state => ({
  ...state,
  ...{
    loading: true,
  },
});

export const generateCustomerOrderLoading = state => ({
  ...state,
  ...{
    generateCustomerOrderLoading: true,
  },
});

export const rejectCustomerOrderLoading = state => ({
  ...state,
  ...{
    rejectCustomerOrderLoading: true,
  },
});

export const newRequestOrdersCount = (state, count) => ({
  ...state,
  ...{
    newRequestCount: count,
  },
});


