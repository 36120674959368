import { useSelector } from "react-redux";

export const CustomerPriceSelectors = () => {
  const state = useSelector((state) => state);
  const customersPriceList = useSelector((state) => state.customers.customersPriceList);
  const loading = useSelector((state) => state.customers.loading);
  const hasError = useSelector((state) => state.customers.hasError);
  const actionMessage = useSelector((state) => state.customers.message);
  const customersList = useSelector((state) => state.customers.customersList);
  const favoriteDoorsLocationsLoading = useSelector((state) => state.customerOrderLocations.favoriteDoorsLocationsLoading);
  const allDoorsLocationsLoading = useSelector((state) => state.customerOrderLocations.allDoorsLocationsLoading);
  const allLocationsDoorsList = useSelector((state) => state.customerOrderLocations.allLocationsDoorsList);
  const favoriteLocationsDoorsList = useSelector((state) => state.customerOrderLocations.favoriteLocationsDoorsList);
  const perPalletType = useSelector((state) => state.customers.perPalletType);
  return {  state, 
            customersPriceList, 
            loading, 
            hasError, 
            actionMessage, 
            customersList, 
            perPalletType,
            favoriteDoorsLocationsLoading, 
            allDoorsLocationsLoading, 
            allLocationsDoorsList, 
            favoriteLocationsDoorsList
          };
};