export const useAppointment = setStops => {

    const updatePickAppointment = (newStops, result) => {
        newStops.forEach(s => {
            if (s.loadId === result.outboundLoadId) {
                s.pickAppointment = result.outboundPickAppointment;
                s.pickStatus = result.pickStatus;
                const newOrders = [...s.orders];
                newOrders.forEach(o => {
                    o.outboundPickAppointment = s.pickAppointment;
                    o.pickOutboundAppointmentStatus = s.pickStatus;
                });
                s.orders = newOrders;
            }
        });
        setStops(newStops);
    };

    const updateDropAppointment = (newStops, result) => {
        const changedStop = newStops.find(s => s.id === result.id);
        if (changedStop) {
            changedStop.dropAppointment = result.outboundDropAppointment;
            changedStop.dropStatus = result.status;
            const newOrders = [...changedStop.orders];
            newOrders.forEach(o => {
                o.outboundDropAppointment = changedStop.dropAppointment;
                o.outboundAppointmentStatus = changedStop.dropStatus;
            });
            changedStop.orders = newOrders;
            setStops(newStops);
        }
        else {
            console.error(`Stop not found, id - ${result.id}`)
        }
    };
    return [updatePickAppointment, updateDropAppointment];
};