import store from '../store';
import { Defines } from './Defines.js';

const GetAppPages = function () {
    let pages = {
        Home: 'home',
        Login: 'login',
        Logout: 'logout',
        Forgot: 'forgot',
        ChangePassword: 'changepassword',
        Template: 'template',
        NotFound: 'notfound',
        DefaultPage: null
    };

    const menu = store.getState().auth.userMenu;
    let firstPage = pages.NotFound;
    for (let index in menu) {
        const item = menu[index];
        if (item.items) {
            pages[item.screen] = item.screen.toLowerCase();
            for (let subIndex in item.items) {
                const subItem = item.items[subIndex];
                pages[subItem.screen] = subItem.screen.toLowerCase();
                if (item.default && !pages.DefaultPage) {
                    pages.DefaultPage = `${pages[item.screen]}/${pages[subItem.screen]}`;
                }
                if (firstPage === pages.NotFound && subItem.menu_item) {
                    firstPage = `${pages[item.screen]}/${pages[subItem.screen]}`;
                }
                if (subItem.has_details) {
                    pages[subItem.screen + Defines.DetailsComponentSufix] = (subItem.screen + Defines.DetailsComponentSufix).toLowerCase();
                }
            }
        }
        else {
            pages[item.screen] = item.screen.toLowerCase();
            if (item.default && !pages.DefaultPage) {
                pages.DefaultPage = pages[item.screen];
            }
            if (firstPage === pages.NotFound && item.menu_item) {
                firstPage = pages[item.screen];
            }
            if (item.has_details) {
                pages[item.screen + Defines.DetailsComponentSufix] = (item.screen + Defines.DetailsComponentSufix).toLowerCase();
            }
        }
    }
    if (!pages.DefaultPage) {
        pages.DefaultPage = firstPage;
    }
    return pages;
};

export let AppPages = GetAppPages();

export const UpdateAppPages = function () {
    AppPages = GetAppPages();
};

export const ApiUrl = {
    Login: 'api/account/login',
    RefreshLogin: 'api/account/refresh',
    AutoLogin: 'api/account/autologin',
    Register: 'api/account/register',
    Forgot: '/account/forgot',
    ChangePassword: '/account/changepassword',
    Thumbnail: 'attachment/thumbnail',
    Download: 'attachment/download',
    Upload: 'attachment/upload',
    ListSettings: 'api/userprofile/listprofile'
};
