import { CUSTOMER_ORDERS_BUILD as actionTypes } from '../../actions/revisionOrderAdmin/revisionOrderAdminActionTypes';
import * as customerOrder from './revisionOrderAdminReducers';

const defaultState = {
  headers: [
    {
      key: 'po',
      title: 'PO#',
      width: 56,
    },
    {
      key: 'so',
      title: 'SO#',
      width: 56,
    },
    {
      key: 'customer',
      title: 'CUSTOMER',
      width: 100,
    },
    {
      key: 'batch',
      title: 'BATCH',
      width: 70,
    },
    // {
    //   key: 'puCity',
    //   title: 'P/U CITY',
    //   width: 80,
    // },
    // {
    //   key: 'puState',
    //   title: 'P/U STATE',
    //   width: 80,
    // },
    {
      key: 'pickUpWH',
      title: 'PICK UP WH',
      width: 100,
    },
    // {
    //   key: 'drop1City',
    //   title: 'DROP 1 CITY',
    //   width: 100,
    // },
    // {
    //   key: 'drop1WH',
    //   title: 'DROP 1 WH',
    //   width: 100,
    // },
    // {
    //   key: 'drop2City',
    //   title: 'DROP 2 CITY',
    //   width: 100,
    // },
    {
      key: 'drop2WH',
      title: 'DELIVERY WH',
      width: 100,
    },
    {
      key: 'route',
      title: 'ROUTE',
      width: 60,
    },
    {
      key: 'puDate',
      title: 'PU DATE',
      width: 80,
    },
    {
      key: 'dueDate',
      title: 'DUE DATE',
      width: 80,
    },
    {
      key: 'palletCount',
      title: 'PAL',
      width: 60,
    },
    {
      key: 'spa',
      title: 'SPA',
      width: 50,
    },
    {
      key: 'case',
      title: 'Cs',
      width: 50,
    },
    {
      key: 'lbs',
      title: 'LBS',
      width: 50,
    },
    {
      key: 'price',
      title: 'PRICE',
      width: 55,
    },
    {
      key: 'notes',
      title: 'NOTES',
      width: 80,
    },
    {
      key: 'status',
      title: 'STATUS',
      width: 70,
    },
    {
      key: 'link',
      title: 'LINK',
      width: 60,
    },
    {
      key: 'group',
      title: 'GROUP',
      width: 60,
    },
    {
      key: 'actions',
      title: 'ACTION',
      width: 120,
    },
  ],
  loading: false,
  acceptRevisionOrderLoading: false,
  rejectRevisionOrderLoading: false,
  hasError: false,
  messageText: '',
  total: 0,
  revisionOrderAdminList: null,
  orderAccepted: false,
  orderRejected: false,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.SET_HEADER_WIDTH_ADMIN_REVISION:
      return customerOrder.setHeaderWidth(state, action.data.ind, action.data.width);
    case actionTypes.ERROR:
      return customerOrder.setError(state, action.data);
    case actionTypes.RESET_ERROR:
      return customerOrder.resetError(state);
    case actionTypes.GET_REVISION_ORDER_ADMIN_LOADING:
      return customerOrder.setInLoading(state);
    case actionTypes.GET_REVISION_ORDER_ADMIN:
      return customerOrder.revisionOrderAdmin(state, action.data);
    case actionTypes.ACCEPT_REVISION_ORDER_LOADING:
      return customerOrder.acceptRevisionOrderLoading(state);
    case actionTypes.REJECT_REVISION_ORDER_LOADING:
      return customerOrder.rejectRevisionOrderLoading(state);
    case actionTypes.ACCEPT_REVISION_ORDER:
      return customerOrder.acceptRevisionOrder(state, action.data);
    case actionTypes.REJECT_REVISION_ORDER:
      return customerOrder.rejectRevisionOrder(state, action.data);
    default:
      return state;
  }
};
