export const getContentWidth = (headers, page) => {
    const isBookingPage = page;
    if (isBookingPage) {
        return ((headers && headers.length > 0) ?
            headers.reduce((a, b) => {
                return a + (b.visible ? b.width : 0);
            }, 0) :
            0);
    } else {
        return ((headers && headers.length > 0) ?
            headers.reduce((a, b) => {
                return a + (b.visible && !b.hideForLoadPlanning ? b.width : 0);
            }, 0) :
            0);
    }
};

export const getContentWidthExceptLeftAction = headers => {
    return ((headers && headers.length > 0) ?
        headers.reduce((a, b) => b.leftAction ? a + 0 : a + b.width, 0) :
        0);
};

