export const ERROR = 'ERROR';
export const SET_HEADER_WIDTH_TRAFFIC_UPCOMING = 'SET_HEADER_WIDTH_TRAFFIC_UPCOMING';
export const SET_IN_LOADING = 'SET_IN_LOADING';
export const RELOAD_ON_ERROR = 'RELOAD_ON_ERROR';
export const RESET_ERROR = 'RESET_ERROR';
export const SET_DISPLAY_STATE = 'SET_DISPLAY_STATE';
export const UPDATE_LOAD_STATUS = 'UPDATE_LOAD_STATUS';
export const GET_TRAFFIC_CONTROL_UPCOMING = 'GET_TRAFFIC_CONTROL_UPCOMING';
export const TRAFFIC_UPCOMING_LOADING = 'TRAFFIC_UPCOMING_LOADING';
export const SET_DATA_UPCOMING = 'SET_DATA_UPCOMING';
export const FILTER_BY_DATE_UPCOMING = 'FILTER_BY_DATE_UPCOMING';

export const getActionType = (recource, key) => {
    return TRAFFIC_CONTROL_BUILD[key]
};

export const TRAFFIC_CONTROL_BUILD = {
    RELOAD_ON_ERROR: 'RELOAD_ON_ERROR',
    ERROR: 'ERROR',
    GET_TRAFFIC_CONTROL_UPCOMING: 'GET_TRAFFIC_CONTROL_UPCOMING',
    TRAFFIC_UPCOMING_LOADING: 'TRAFFIC_UPCOMING_LOADING',
    SET_DATA_UPCOMING: 'SET_DATA_UPCOMING',
    FILTER_BY_DATE_UPCOMING: 'FILTER_BY_DATE_UPCOMING',
    SET_HEADER_WIDTH_TRAFFIC_UPCOMING: 'SET_HEADER_WIDTH_TRAFFIC_UPCOMING',
    RESET_ERROR: 'RESET_ERROR',
};
