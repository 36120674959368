import React from 'react';

import Sheet from '../../custom/sheet/sheet';
import { CloneObject } from '../../../helper/common';

const Permission = props => {

    const updateMenuItem = (items, item, level) => {
        item.level = level;
        const isParent = isParentItem(item);
        if (isParent) {
            item.items.forEach(i => {
                updateMenuItem(items, i, level);
            });
        }
        else if (item.parent_id) {
            const parentItem = findMenuItem(items, item.parent_id);
            if (parentItem) {
                if (parentItem.level < level) {
                    parentItem.level = level;
                }
                else {
                    let maxLevel = level;
                    parentItem.items.forEach(i => {
                        if (i.level > maxLevel) {
                            maxLevel = i.level;
                        }
                    });
                    parentItem.level = maxLevel;
                }
            }
        }
    };

    const isParentItem = (item) => item && item.items && item.items.length > 0

    const findMenuItem = (items, id) => {
        let item = items.find(i => i.screen_id === id);
        if (!item) {
            items.forEach(i => {
                if (isParentItem(i) && !item) {
                    item = findMenuItem(i.items, id);
                }
            });
        }
        return item;
    };

    const findUpdateMenuItem = (items, id, level) => {
        const item = findMenuItem(items, id);
        if (!item) {
            console.error('Item not defined');
            return null;
        }
        updateMenuItem(items, item, level);
    };

    const actions = {
        view: (items, e) => {
            if (!e.nativeEvent.target.checked) {
                findUpdateMenuItem(items, e.keyField, 0);
            } else {
                findUpdateMenuItem(items, e.keyField, 1);
            }
        },
        edit: (items, e) => {
            if (!e.nativeEvent.target.checked) {
                findUpdateMenuItem(items, e.keyField, 1);
            } else {
                findUpdateMenuItem(items, e.keyField, 2);
            }
        },
        default: (items, e) => {
            const item = findMenuItem(items, e.keyField);
            if (item) {

                items.forEach(i => {
                    i.default = false;
                });
                item.default = true;
            }
        },
        screen_order: (items, e) => {
            const item = findMenuItem(items, e.keyField);
            if (item) {
                item.screen_order = e.nativeEvent.target.value;
            }
        },
        menu_item: (items, e) => {
            const item = findMenuItem(items, e.keyField);
            if (item) {
                if (!e.nativeEvent.target.checked) {
                    item.menu_item = false;
                } else {
                    item.menu_item = true;
                }
            }
        },
        has_details: (items, e) => {
            const item = findMenuItem(items, e.keyField);
            if (item) {
                if (!e.nativeEvent.target.checked) {
                    item.has_details = false;
                } else {
                    item.has_details = true;
                }
            }
        }
    };

    const onChangeHandler = (event) => {
        const newData = CloneObject(props.data);
        actions[event.field](newData, event);
        props.dispatch({ type: props.type, value: newData });
    };

    return (<view>
        <Sheet
            classes={'table_view'}
            keyFieldName={props.keyFieldName}
            colgroup columns={props.columns}
            data={props.data}
            onChange={onChangeHandler}
            messageDispatch={props.messageDispatch}
            messages={props.messages} />
    </view>);
};

export default Permission;