import React, { useRef, useState } from 'react';
import { connect } from "react-redux";

import FormScreen from '../components/screen/formScreen';
import Content from '../components/form/content';
import { Action } from '../config/action.config';
import { Mapping } from '../mapping/mapping';
import { ValidateScreen } from '../helper/validation';
import { DefaultMessageTypes } from '../hook/actions';
import { useData } from '../hook/data/useData';
import { useMessage } from '../hook/data/useMessage';
import { useFormValidation } from '../hook/data/useFormValidation';

import { openPopup } from '../../store/actions/popup/popup';
import { CommonLabels } from '../../project/Defines';
import { PopupTypes } from '../components/custom/popup/popup';

const Section = props => {
    const { config,
        sectionData,
        close,
        onSave,
        onCancel } = props;
    //state
    const componentRefs = useRef({});
    const [data, dispatch] = useData(sectionData);
    const [firstData, setFirstData] = useState(sectionData);
    const [isUpdating, setUpdating] = useState(false);
    const [messages, messageDispatch] = useMessage();
    const validate = useFormValidation(messageDispatch,
        data,
        Mapping,
        ValidateScreen,
        config,
        componentRefs);

    const sectionValidation = () => {
        messageDispatch({ type: DefaultMessageTypes.START_FOCUSE, messages: true });
        const [passValidation] = validate();
        return passValidation;
    };

    const confirmOptions = {
        windowKey: 'wndConfirmClose',
        type: PopupTypes.Confirm,
        title: 'Are you sure?',
        text: '',
        buttonYesText: CommonLabels.Buttons.Close,
    };

    const dataAction = (action, additionalData) => {
        switch (action) {
            case Action.Save:
                if (!sectionValidation()) {
                    return;
                }

                if (!data.noteCheckBeforeSave && JSON.stringify(data) === JSON.stringify(firstData)) {
                    props.openAlert({
                        windowKey: 'wndConfirmClose',
                        type: PopupTypes.Alert,
                        title: "You didn't make any changes",
                        text: '',
                        buttonYesText: CommonLabels.Buttons.Close,
                    });
                } else {
                    onSave && onSave(data);
                    setFirstData(data);
                }

                break;
            case Action.SaveClose:
                if (!sectionValidation()) {
                    return;
                }

                if (!data.noteCheckBeforeSave && JSON.stringify(data) === JSON.stringify(firstData)) {
                    confirmOptions.text = "You didn't make any changes. Do you really want to leave the page?";
                    confirmOptions.yesCallback = () => {
                        onCancel && onCancel();
                        close();
                    }

                    props.openConfirm(confirmOptions);
                } else {
                    onSave && onSave(data);
                    close();
                }

                break;
            case Action.SaveSend:
                if (!sectionValidation()) {
                    return;
                }

                onSave && onSave(data, setUpdating, close);
                break;
            case Action.Close:
                if (!data.noteCheckBeforeSave && JSON.stringify(data) !== JSON.stringify(firstData)) {
                    confirmOptions.text = "You made the changes and not save them. Do you really want to leave the page?";
                    confirmOptions.yesCallback = () => {
                        onCancel && onCancel();
                        close();
                    }

                    props.openConfirm(confirmOptions);
                } else {
                    onCancel && onCancel();
                    close();
                }

                break;
            default:
                break;
        }
    };

    return (
        <FormScreen showPopup={true} title={config.Title} isLoading={isUpdating} fullScreen={props.fullScreen} onCancel={() => dataAction(Action.Close)}>
            <Content
                config={config}
                data={data}
                dispatch={dispatch}
                messages={messages}
                messageDispatch={messageDispatch}
                componentRefs={componentRefs}
                dataAction={dataAction}
            />
        </FormScreen>)
};

const mapDispatchToProps = dispatch => {
    return {
        openConfirm: (data) => dispatch(openPopup(data)),
        openAlert: (data) => dispatch(openPopup(data))
    };
};

export default connect(null, mapDispatchToProps)(Section);