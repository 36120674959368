import {
    ElementTypes,
    ElementValidations,
    Ability,
    ElementConditions
} from '../../config';

const Login = {
    Title: 'Please log in to your account',
    Resource: 'Login',
    Master: '',
    Key: '',
    DefaultState: {
        userName: '',
        password: '',
        remember: false
    },
    Form: {
        Wrap: [
            {
                Fields: {
                    userName: {
                        Title: 'Email',
                        Field: ElementTypes.TextField(Ability.Displayability, Ability.Editability, [], {}, ElementConditions.Enable, ' '),
                        Validations: [ElementValidations.Required()]
                    },
                    password: {
                        Title: 'Password',
                        Field: ElementTypes.PasswordField(true, Ability.Displayability, Ability.Editability, [], {}, ElementConditions.Enable, ' '),
                        Validations: [ElementValidations.Required()]
                    }
                }
            }
        ]
    }
}; 

export default Login;