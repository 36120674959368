export const getOrdersList = (state, response, hasError = false, message = '') => {
    const data = response.data || [];
    return {
        ...state, ...{
            ordersList: data,
            loading: false,
            hasError,
            message,
        }    
    }
};

export const setError = (state, err) => ({
    ...state,
    ...{
        hasError: true,
        message: 'Something went wrong, please try again',
    },
});

export const resetError = state => ({
    ...state,
    ...{
        hasError: false,
        message: '',
        newGroupCreated: false,
    }
});

export const setInLoading = state => ({
    ...state, ...{
        loading: true
    }
});

const extractWebLoadsToList = (state, orders)  => {
    let loadsAssignedOrders = [];
    orders.forEach(item=> loadsAssignedOrders.push(createWebOrder(item, state.headers)))
    return loadsAssignedOrders;
};

export const updateOrdersLoadState = (state, orders, hasError = false, message = '', filterType = 'all', sortType = {type: "", ind: "", sort: null}) => ({
    ...state, ...{
        orders,
        loading: false,
        hasError,
        message,
        filterType,
        sortType,
        loadsAssignedOrders: extractWebLoadsToList(state, orders),
    }
});

export const loadContent = (state, response, hasError = false, message = '') => {
    const data = response.data || [];
    return updateOrdersLoadState(state, [...data.data],  hasError, message);
};

export const createWebOrder = (oData, headers, isHidden) => {
    const orderData = { ...oData };

    const orderWebData = {
        row: [],
        rowState: {
            type: 'order',
            id: `order_${orderData.id}`,
            orderObjectId: orderData.id,
            orderData: orderData,
            orderId: `order_${orderData.id}`,
            visible: !isHidden,
            reacted: false,
        }
    };

    for (let hederIndex = 0; hederIndex < headers.length; hederIndex++) {
        const headerItem = headers[hederIndex];
        let value = null;
        let fieldType = 'text';
        let cellStyle = {};
        let tooltip = null;
        if (!headerItem.leftAction) {
            switch (headerItem.key) {
                case 'checkbox':
                    fieldType = 'checkbox';
                    break;
                case 'temperature':
                    value = orderData.temperature === 1 ? 'Frozen' : 
                            orderData.temperature === 2 ? 'Chilled' :
                            orderData.temperature === 3 ? 'Dry' :
                            orderData.temperature === 4 ? 'C|F' : '';
                    fieldType = 'text';
                    break;
                case 'readyDate':
                    value = orderData.readyDate;
                    fieldType = 'date';
                    break;
                case 'dueDate':
                    value = orderData.dueDate;
                    fieldType = 'date';
                    break;
                case 'customer':
                    value = orderData.customerName;
                    fieldType = 'text';
                    tooltip = orderData.customerName;
                    break;
                case 'deliveryDate':
                    value = orderData.deliverDate;
                    fieldType = 'date';
                    break;
                case 'pickLocation':
                    value = orderData.pickLocationName;
                    tooltip = orderData.pickLocationName;
                    break;
                case 'drop2WH':
                    value = orderData.drop2WH;
                    tooltip = orderData.drop2WH;
                    break;
                case 'route':
                    value = orderData.route;
                    break;
                case 'poNumber':
                    value = orderData.poNumber;
                    fieldType = 'text';
                    break;
                case 'soNumber':
                    value = orderData.soNumber;
                    fieldType = 'textInput';
                    break;
                case 'palletCount':
                    value = orderData.palletCount;
                    fieldType = 'integer';
                    break;
                case 'space':
                    value = orderData.space;
                    fieldType = 'text';
                    break;
                case 'case':
                    value = orderData.cases;
                    fieldType = 'integer';
                    break;
                case 'lbs':
                    value = orderData.weight;
                    fieldType = 'integer';
                    break;
                case 'batchType':
                    value = orderData.batchType;
                    break;                  
                // case 'freight':
                //     value = orderData.freight;
                //     break;
                case 'podStatus':
                    value = orderData.outPodRequestStatus === 1 ? 'Received' : orderData.outPodRequestStatus === 2 ? 'Requested' : orderData.outPodRequestStatus === 3 ? 'Don’t Split' : '-';
                    break;
                case 'status':
                    value = orderData.orderStatus;
                    break
                case 'group':
                    value = orderData.orderGroupIndex;
                    break
                default:
                    break;
            }
        }
        else {
            value = '';
            fieldType = 'text';
        }

        orderWebData.row.push({
            key: headerItem.key,
            value,
            fieldType,
            cellStyle,
            tooltip
        });
    }

    return orderWebData;
};

export const createGroup = (state, responce) => {
    return {
    ...state,
    ...{
        hasError: responce.data.hasError,
        message: responce?.data.message ? responce?.data.message : '',
        newGroupCreated: !responce.data.hasError,
        loading: false,
        newGroupId: responce.data.groupId,
    },
    };
};

export const setBatchType = (state, responce) => {
    return {
        ...state,
        ...{
            batchType: responce,
        },
    };
};

