import React from 'react';
import { connect } from 'react-redux';

import { hideShowMenu } from '../../../store/actions/pageContent/pageContent';

const HideShowMenuSwitch = props => {
    const handleChange = checked => {
        props.handleSwitch(checked);
    };

    return (
        <action right="right">
            <div className="line small form_fields">
                <div>
                    <label checkbox="">
                        <input type="checkbox" checked={props.isHiddenMenu} onChange={e => handleChange(e.target.checked)} />
                        <text className='hide_show__menu'>{`${props.isHiddenMenu ? 'Open' : 'Hide'} Menu`}</text>
                        <box><check></check></box>
                    </label>
                </div>
            </div>
        </action>
    );
};

export default connect(state => ({
    isHiddenMenu: state.pageContent.isHiddenMenu
}),
    dispatch => ({
        handleSwitch: checked => dispatch(hideShowMenu(checked))
    }))(HideShowMenuSwitch)
