import {
    ElementTypes,
    ElementValidations,
    Action,
    ListDefaults,
    ColumnType,
    FilterType,
    GenerateToolBars,
    DefaultPagination
} from '../../config';

import CarrierConfig from '../Carrier/config';
import TrailerSizeConfig from '../TrailerSize/config';

const Trailer = {
    Title: 'Trailer',
    Resource: 'Trailer',
    Master: 'number',
    Key: 'id',
    IsRest: true,
    List: {
        Defaults: ListDefaults,
        ShowRefreshButton: true,
        Search: '_all',
        Filter: {
            name: {
                Type: FilterType.Text,
                Title: 'Name'
            },
            vinNumber: {
                Type: FilterType.Text,
                Title: 'VIN'
            },
            carrierId: {
                Type: FilterType.Select,
                Valuelist: '/api/Carrier/valuelistempty',
                Title: 'Carrier Name'
            },
            trailerTypeId: {
                Type: FilterType.Select,
                Valuelist: '/api/TrailerType/valuelistempty',
                Title: 'Trailer'
            },
            trailerSizeId: {
                Type: FilterType.Select,
                Valuelist: '/api/TrailerSize/valuelistempty',
                Title: 'Size'
            },
            active: {
                Type: FilterType.Radio,
                Title: 'Active'
            }
        },
        Pagination: DefaultPagination,
        Columns: {
            name: {
                Title: 'Name',
                Type: ColumnType.Text,
                LinkAppPage: true,
                Sorted: 'ascend',
                DataTitle: false,
                Width: 150
            },
            vinNumber: {
                Type: ColumnType.Text,
                Title: 'VIN',
                Width: 150
            },
            carrierName: {
                Title: 'Carrier Name',
                Type: ColumnType.Text,
                Width: 150
            },
            type: {
                Title: 'Type',
                Type: ColumnType.Text,
                Width: 80
            },
            size: {
                Title: 'Size',
                Type: ColumnType.Text,
                Width: 80
            },
            active: {
                Field: ElementTypes.CheckBox('Active'),
            }
        },
        Actions: {
            Width: 60,
            Items: [
                Action.Delete
            ]
        }
    },
    Form: {
        SectionGroups: [
            {
                Sections: [
                    {
                        Fields: {
                            name: {
                                Title: 'Name',
                                Field: ElementTypes.TextField(),
                                Validations: [ElementValidations.OnlyLettersAndSpace()]
                            },
                            vinNumber: {
                                Title: 'VIN',
                                Field: ElementTypes.TextField(),
                                Attributes: {
                                    input: {
                                        maxLength: 17
                                    }
                                },
                                Validations: [ElementValidations.Required(), ElementValidations.NoEmptySpace()]
                            },
                            carrierId: {
                                Title: 'Carrier Name',
                                //Field: ElementTypes.ComboAdd('/api/carrier/valuelistempty', {
                                //    TitlePrefix: '',
                                //    PopupConfig: CarrierConfig
                                //}),
                                Field: ElementTypes.Generic(ElementTypes.ComboAdd, {
                                    dataSource: '/api/carrier/valuelistempty',
                                    addparams: {
                                        TitlePrefix: '',
                                        PopupConfig: CarrierConfig
                                    },
                                    selectedValueType: 'carrierName'
                                }),
                                Validations: [ElementValidations.Required()]
                            },
                            trailerTypeId: {
                                Title: 'Type',
                                Field: ElementTypes.Generic(ElementTypes.Select, {
                                    dataSource: '/api/TrailerType/valuelistempty',
                                    selectedValueType: 'type'
                                }),
                                Validations: [ElementValidations.Required()]
                            },
                            trailerSizeId: {
                                Title: 'Size',
                                Field: ElementTypes.Generic(ElementTypes.ComboAdd, {
                                    dataSource: '/api/TrailerSize/valuelistempty',
                                    addparams: {
                                        TitlePrefix: '',
                                        PopupConfig: TrailerSizeConfig
                                    },
                                    selectedValueType: 'size'
                                }),
                                Validations: [ElementValidations.Required()]
                            },
                            notes: {
                                Title: 'Notes',
                                Field: ElementTypes.Comment()
                            },
                            active: {
                                Field: ElementTypes.CheckBox('Active'),
                            }
                        }
                    }
                ]
            }
        ],
        ToolBars: GenerateToolBars()
    }
};

export default Trailer;