export const ERROR = 'ERROR';
export const SET_HEADER_WIDTH_ORDER_IN_PROGRESS = 'SET_HEADER_WIDTH_ORDER_IN_PROGRESS';
export const SET_IN_LOADING = 'SET_IN_LOADING';
export const RELOAD_ON_ERROR = 'RELOAD_ON_ERROR';
export const RESET_ERROR = 'RESET_ERROR';
export const SET_DISPLAY_STATE = 'SET_DISPLAY_STATE';
export const GET_CUSTOMER_ORDERS_IN_PROGRESS = 'GET_CUSTOMER_ORDERS_IN_PROGRESS';
export const CUSTOMER_ORDERS_LOADING = 'CUSTOMER_ORDERS_LOADING'; 
export const SORT_ORDERS_IN_PROGRESS = 'SORT_ORDERS_IN_PROGRESS';
export const REVISION_CUSTOMER_ORDER_LOADING = 'REVISION_CUSTOMER_ORDER_LOADING';
export const REVISION_CUSTOMER_ORDER = 'REVISION_CUSTOMER_ORDER';

export const getActionType = (recource, key) => {
    return CUSTOMER_ORDERS_BUILD[key]
};

export const CUSTOMER_ORDERS_BUILD = {
    RELOAD_ON_ERROR: 'RELOAD_ON_ERROR',
    ERROR: 'ERROR',
    SET_HEADER_WIDTH_ORDER_IN_PROGRESS: 'SET_HEADER_WIDTH_ORDER_IN_PROGRESS',
    RESET_ERROR: 'RESET_ERROR',
    GET_CUSTOMER_ORDERS_IN_PROGRESS: 'GET_CUSTOMER_ORDERS_IN_PROGRESS',
    CUSTOMER_ORDERS_LOADING: 'CUSTOMER_ORDERS_LOADING',
    SORT_ORDERS_IN_PROGRESS: 'SORT_ORDERS_IN_PROGRESS',
    REVISION_CUSTOMER_ORDER_LOADING: 'REVISION_CUSTOMER_ORDER_LOADING',
    REVISION_CUSTOMER_ORDER: 'REVISION_CUSTOMER_ORDER',
};
