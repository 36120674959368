import React, { useState } from 'react';

import Sheet from "../../custom/sheet/sheet";
import { PopupTypes } from "../../custom/popup/popup";
import { Action } from "../../../config/action.config";
import { CloneObject } from '../../../helper/common';

const Orders = props => {
    const {
        data,
        draggable,
        colgroup,
        droppableId,
        recordId,
        selectable,
        handleCheck,
        selectedOrders,
        onAfterMouseOrdersSelect,
        grouping,
        groupBy,
        groupType,
        groupTypeValue,
        subGroup,
        getFilteredSortedOrders,
        getOrderForm,
        getAssignedOrderGroups,
        apiResource,
        openPopup,
        stops,
        setStops,
        availableOrders,
        setAvailableOrders,
        closePopupAsync,
        closePopup,
        getOrderConfig,
        resource,
        attributes,
        isInbound
    } = props;

    const defaultSort = {};
    const columnsObj = getOrderConfig().List.Columns;

    for (let column in columnsObj) {
        if (columnsObj[column] && columnsObj[column].Sorted) {
            defaultSort['field'] = column;
            defaultSort['direction'] = columnsObj[column].Sorted;
        }
    }

    const [sort, setSort] = useState([defaultSort]);

    const onActionRecordHandler = (record, action, stopId) => {
        switch (action) {
            case Action.Edit:
                editOrder(record.recordId, stopId);
                break;
            default:
                break;
        }
    };

    const editOrder = (id, stopId) => {
        const windowKey = 'Order';
        const config = CloneObject(getOrderForm());
        const title = `Edit ${config.Title}`;
        config.screen = config;
        config.screen.Form.DefaultId = id;
        const assignedOrderGroups = getAssignedOrderGroups();
        if (stopId) {
            if (isInbound) {
                config.screen.Form.SectionGroups[0].Sections[0].Fields.Group1 = assignedOrderGroups[5];
                config.screen.Form.SectionGroups[0].Sections[0].Fields.Group2 = assignedOrderGroups[6];
                config.screen.Form.SectionGroups[0].Sections[0].Fields.Group3 = assignedOrderGroups[7];
                config.screen.Form.SectionGroups[0].Sections[0].Fields.Group4 = assignedOrderGroups[8];
            } else {
                config.screen.Form.SectionGroups[0].Sections[0].Fields.Group1 = assignedOrderGroups[0];
                config.screen.Form.SectionGroups[0].Sections[0].Fields.Group2 = assignedOrderGroups[1];
                config.screen.Form.SectionGroups[0].Sections[0].Fields.Group3 = assignedOrderGroups[2];
                config.screen.Form.SectionGroups[0].Sections[0].Fields.Group4 = assignedOrderGroups[3];
            }
            config.screen.Form.SectionGroups[0].Sections[0].Fields.Group5 = assignedOrderGroups[4];
            config.screen.Resource = apiResource;
        }
        openPopup({
            windowKey,
            fullScreen: false,
            title: title,
            type: PopupTypes.ConfigForm,
            bodyProps: { windowKey },
            config: config,
            saveAction: (result) => {
                if (result.id && !result.hasError) {
                    result.puWeek = result.puDate && new Date(result.puDate).getWeek();
                    result.dropWeek = result.dropDate && new Date(result.dropDate).getWeek();
                }
                if (stopId) {
                    const newStops = [...stops];
                    const changedStop = newStops.find(s => s.id === stopId);
                    if (changedStop) {
                        const newOrders = [...changedStop.orders];
                        const changedOrder = newOrders.find(o => o.id === result.id);
                        if (changedOrder) {
                            const changedOrderIndex = newOrders.indexOf(changedOrder);
                            newOrders.splice(changedOrderIndex, 1, result);
                            changedStop.orders = newOrders;
                            setStops(newStops);
                        }
                        else {
                            console.error(`Order not found, id - ${result.id}`);
                        }
                    }
                    else {
                        console.error(`Stop not found. id - ${stopId}`);
                    }
                }
                else {
                    const newOrders = [...availableOrders];
                    const changedOrder = newOrders.find(o => o.id === result.id);
                    if (changedOrder) {
                        const changedOrderIndex = newOrders.indexOf(changedOrder);
                        newOrders.splice(changedOrderIndex, 1, result);
                        setAvailableOrders(newOrders);
                    }
                    else {
                        console.error(`Order not found, id - ${result.id}`);
                    }
                }
                closePopupAsync(windowKey);
            },
            closeAction: () => {
                closePopup(windowKey);
            }
        });
    };

    //const onSort = (column) => {
    //    const sorting = {};
    //    if(sort.field === column) {
    //        sorting.field = column;
    //        if(sort.direction === 'asc') {
    //            sorting.direction = 'desc';
    //        } else if(sort.direction === 'desc') {
    //            sorting.field = null;
    //            sorting.direction = null;
    //        } else {
    //            sorting.direction = 'asc';
    //        }
    //    } else {
    //        sorting.field = column;
    //        sorting.direction = 'asc';
    //    }
    //    
    //    setSort((oldState) => {
     
    //        return sorting;
    //    });
    //};

    const onSort = (column) => {
        let isItemExists = sort.some(obj => {
            return obj.field === column;
        });

        if (isItemExists) {
            sort.filter((obj, index) => {
                if (obj.field === column) {
                    if (obj.direction === 'asc') {
                        obj.direction = 'desc';

                        setSort((oldState) => {
                            return [...oldState];
                        });
                    } else {
                        obj.field = null;
                        obj.direction = null;

                        setSort((oldState) => {
                            oldState.splice(index, 1);

                            return [...oldState];
                        });
                    }
                }

                return sort;
            });
        } else {
            setSort((oldState) => {
                return [...oldState, { field: column, direction: 'asc' }];
            });
        }    
    };

    
    const isAvailable = resource === 'AvailableOrders' || resource === 'OutboundAvailableOrders' || false;

    return (<container {...attributes}>
        <Sheet
            draggable={draggable}
            colgroup={colgroup}
            droppableId={droppableId}
            selectable={selectable}
            handleCheck={handleCheck}
            selectedRows={selectedOrders}
            onAfterMouseSelect={onAfterMouseOrdersSelect}
            notUseInnerSelection
            grouping={grouping}
            groupBy={groupBy}
            groupType={groupType}
            groupTypeValue={groupTypeValue}
            subGroup={subGroup}
            resizable={props.resizable}
            action
            minRowsCanDelete={Number.MAX_SAFE_INTEGER}
            onActionRecord={(r, action) => onActionRecordHandler(r, action, recordId)}
            {...getOrderConfig()}
            resource={resource}
            stopResource={props.stopResource}
            data={getFilteredSortedOrders(data, resource, isAvailable ? sort : null)}
            onSort={isAvailable ? onSort : null}
            sort={sort}
            columns={getOrderConfig().List.Columns}
            keyFieldName='id'
            classes='table_view extra'
        />
    </container>);
};

export default React.memo(Orders);