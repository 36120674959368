import React from 'react';

const LoginField = props => (
    <div className="input_wrap">
        <div className="wrap">
            {props.children}
        </div>
        <info><message></message></info>
    </div>
);

export default LoginField;