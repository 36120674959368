import {
    ElementTypes
} from '../../config';
import { GenerateToolBars } from '../../config/element.config';

const CustomerForm = {
    Title: 'Customer',
    Resource: 'Customer',
    Key: 'id',
    Master: 'name',
    IsRest: true,
    Form: {
        SectionGroups: [
            {
                Sections: [
                    {
                        Title: null,
                        Fields: {
                            name: {
                                Title: 'Customer Name',
                                Field: ElementTypes.LabelField()
                            },
                            code: {
                                Title: 'Code',
                                Field: ElementTypes.LabelField()
                            },
                            billingAddress: {
                                Title: 'Billing Address',
                                Field: ElementTypes.LabelField()
                            },
                            mainPhone: {
                                Title: 'Main Phone',
                                Field: ElementTypes.Phone()
                            },
                            fax: {
                                Title: 'Fax',
                                Field: ElementTypes.LabelField()
                            },
                            accountNumber: {
                                Title: 'Account Number',
                                Field: ElementTypes.LabelField()
                            }
                        }
                    },
                    {
                        classes: 'wide',
                        Title: null,
                        Fields: {
                            Group1: [
                                {
                                    useSeparator: false,
                                    Fields: {
                                        contactName1: {
                                            Title: 'Contact Name',
                                            Field: ElementTypes.LabelField()
                                        },
                                        contactPhone1: {
                                            Title: 'Phone Number',
                                            Field: ElementTypes.Phone()
                                        },
                                        contactEmail1: {
                                            Title: 'Contact Email',
                                            Field: ElementTypes.LabelField()
                                        }
                                    }
                                }
                            ],
                            Group2: [
                                {
                                    useSeparator: false,
                                    Fields: {
                                        contactName2: {
                                            Title: 'Contact Name',
                                            Field: ElementTypes.LabelField()
                                        },
                                        contactPhone2: {
                                            Title: 'Phone Number',
                                            Field: ElementTypes.Phone()
                                        },
                                        contactEmail2: {
                                            Title: 'Contact Email',
                                            Field: ElementTypes.LabelField()
                                        }
                                    }
                                }
                            ],
                            notes: {
                                Title: 'Notes',
                                Field: ElementTypes.CommentLabel()
                            },
                            sectionTitle: {
                                Field: ElementTypes.SectionTitle('Favourite Locations', o => o.favLocationId1 || o.favLocationId2 || o.favLocationId3)
                            },
                            favLocation1: {
                                Field: ElementTypes.LabelField(o => o.favLocationId1),
                            },
                            favLocation2: {
                                Field: ElementTypes.LabelField(o => o.favLocationId2),
                            },
                            favLocation3: {
                                Field: ElementTypes.LabelField(o => o.favLocationId3),
                            }
                        }
                    }
                ]
            }
        ],
        ToolBars: GenerateToolBars(['Close'])
    }
};

export default CustomerForm;