import React, { useState, useMemo, useRef, useEffect, useCallback } from 'react';
import { connect } from "react-redux";
import { VariableSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import Select from 'react-select'
import { createSelectable, SelectableGroup } from '../../../components/custom/reactSelectable';
import { load, resetError, filterCallChecks, reactOnAssignedItems, filterByPODStatus } from '../../../../store/actions/callCheck/callCheck';
import { getContentWidth } from '../../../../store/reducers/callCheck/helper/general';
import Row from './row';
import HeaderRow from './headerRow';
import { createSeparator } from '../../../../store/reducers/callCheck/helper/separator';
import { Dropdown, Space, message, Menu, Input } from 'antd';
import Loading from "../../screen/loading";
import HideShowMenuSwitch from '../../layout/hideShowMenuSwitch';
import UploadModal from './UploadModal'; 
import { useClientHubOrder } from "../../../hook/hub/useClientHubOrder";
import { styles } from './style';

const Spreadsheet = props => {
    const { 
        state,
        loadsAssignedOrders,
        resetError,
        haseError,
        actionMessage,
        loading,
        load,
        resource,
        filterCallChecks,
        filterType,
        reactOnAssignedItems,
        selectable,
        userId,
        podStatus,
        filterByPODStatus,
    } = props;

    const [uploadModalIsOpen, setUploadModalIsOpen] = useState(false);
    const [uploadRowState, setUploadRowState] = useState('');
    const scrollSizeSmall = 18;
    const scrollSize = 16;
    const defRowHeight = 20;
    const assignedRef = useRef({});
    const [searchInput, setSearchInput] = useState('');
    const [selectedOrder, setSelectedOrder] = useState([]);
    const [PODStatus, setPODStatus] = useState('');

    useClientHubOrder((message) => {
        if (userId !== message.byId) {
            load(resource, 'withoutLoading');
        }
    }, 'notification');

    useEffect(() => {
        load(resource);
    }, [load, resource]);

    useEffect(() => {
        filterCallChecks(resource, filterType)
    }, [filterCallChecks, filterType, resource])

    useEffect(() => {
        if (haseError) {
            message.error(actionMessage);
            resetError(resource);
        }
    }, [haseError, actionMessage, resetError, resource]);

    const getIndex = (selectedOrderAssigned, selectedOrderAssignedIndex) => {
        if (assignedRef?.current?.scrollToItem) {
            const loadsList = displayLoadsAssignedOrders.filter(order => order.rowState.type === 'load');
            const selectedOrderId = selectedOrderAssigned[0].rowState.orderObjectId;
            let selectedLoadIndex = 0;
            for (let i = 0; i < loadsList.length; i++) {
                for (let j = 0; j < loadsList[i].ordersData.length; j++) {
                    if (loadsList[i].ordersData[j].rowState.orderObjectId === selectedOrderId) {
                        selectedLoadIndex = i;
                    }
                }
            }
            const finalIndex = Math.round(selectedOrderAssignedIndex && selectedOrderAssignedIndex + selectedLoadIndex );
            selectedOrderAssignedIndex && assignedRef.current.scrollToItem(finalIndex)
        }
    }

    useEffect(() => {
        // search data by
        const searchedOrders = displayLoadsAssignedOrders.filter(order => 
            (order.rowState?.orderData?.poNumber?.toLowerCase().includes(searchInput) && searchInput !== '') ||
            (order.rowState?.orderData?.soNumber?.toLowerCase().includes(searchInput) && searchInput !== '') ||
            (order.rowState?.orderData?.inboundLoadNumber?.toLowerCase().includes(searchInput) && searchInput !== '') ||
            (order.rowState?.orderData?.outboundLoadNumber?.toLowerCase().includes(searchInput) && searchInput !== '')
        );

        const searchedOrdersByField = searchedOrders.map(order => {
            if (order.rowState?.orderData?.poNumber?.toLowerCase().includes(searchInput)) {
                order.rowState.orderData.field = 'poNumber'
                return order;
            } else if (order.rowState?.orderData?.soNumber?.toLowerCase().includes(searchInput)) {
                order.rowState.orderData.field = 'soNumber'
                return order;
            } else if (order.rowState?.orderData?.inboundLoadNumber?.toLowerCase().includes(searchInput)) {
                order.rowState.orderData.field = 'inboundLoadNumber'
                return order;
            } else if (order.rowState?.orderData?.outboundLoadNumber?.toLowerCase().includes(searchInput)) {
                order.rowState.orderData.field = 'outboundLoadNumber'
                return order;
            }
        })

        const selectedOrderAssignedIndex = displayLoadsAssignedOrders.map((order, index) => {
            if ((order.rowState?.orderData?.poNumber?.toLowerCase().includes(searchInput) && searchInput !== '') ||
                (order.rowState?.orderData?.soNumber?.toLowerCase().includes(searchInput) && searchInput !== '') ||
                (order.rowState?.orderData?.inboundLoadNumber?.toLowerCase().includes(searchInput) && searchInput !== '') ||
                (order.rowState?.orderData?.outboundLoadNumber?.toLowerCase().includes(searchInput) && searchInput !== '')
            ) {
                return index;
            }
        }).filter(item => item !== undefined)[0];

        if (searchedOrders.length) {
            setSelectedOrder(searchedOrdersByField);
            getIndex(searchedOrders, selectedOrderAssignedIndex)
        } else {
            setSelectedOrder('')
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assignedRef, searchInput]);

    const listReference = useRef(null);
    const listHeaderReference = useRef(null);
    const mainScroll = useRef(null);
    const startOnScroll = width => mainScroll.current.scrollLeft = width;

    const onScrollX = e => {
        if (listHeaderReference.current && listReference.current && e.target) {
            listHeaderReference.current.scrollLeft =
            listReference.current.scrollLeft = e.target.scrollLeft;
        }
    };

    const header = useMemo(() =>
        <div style={{ overflow: 'hidden' }}>
            <div
                style={{
                    width: '36px',
                    position: 'absolute',
                    background: '#449996',
                    height: '31px',
                    zIndex: '1',
                    borderRight: '1px solid #325353',
                }}>
            </div>
            <div
                ref={listHeaderReference}
                style={{
                    width: '100%',
                    overflow: 'hidden'
                }}>
                <HeaderRow resource={resource} />
            </div>
        </div>,
    [resource]);
    
    const width = getContentWidth(state[resource].headers) + scrollSize;
    const extraScrollVisibility = window.innerWidth > width ? 'none' : 'flex';

    const byPODStatus = (loadsAssignedOrders, status, loadType) => {
        const podRequestStatus = loadType === 'in' ? 'inPodRequestStatus' : 'outPodRequestStatus';
        const loadTypeNumber = loadType === 'in' ? 1 : 3;
        const filteredList = [];
        for (let i = 0; i < loadsAssignedOrders?.length; i++) {
            if(loadsAssignedOrders[i].ordersData) {
                for (let j = 0; j < loadsAssignedOrders[i].ordersData.length; j++) {
                    if (loadsAssignedOrders[i].ordersData[j].rowState?.orderData?.[podRequestStatus] === status) {
                        filteredList.push(loadsAssignedOrders[i]);
                        break;
                    }
                }
            } else {
                if (loadsAssignedOrders[i].rowState?.orderData?.[podRequestStatus] === status) {
                    filteredList.push(loadsAssignedOrders[i]);
                }
            }
        }
        return filteredList.filter(item => item.rowState.loadData.loadType === loadTypeNumber);
    };

    const byPODStatusType = (loadsAssignedOrders, status, loadType) => {
        const notes = loadType === 'in' ? 'inNotes' : 'outNotes';
        const loadTypeNumber = loadType === 'in' ? 1 : 3;
        const filteredList = [];
        for (let i = 0; i < loadsAssignedOrders?.length; i++) {
            if(loadsAssignedOrders[i].ordersData) {
                for (let j = 0; j < loadsAssignedOrders[i].ordersData.length; j++) {
                    if (loadsAssignedOrders[i].ordersData[j].rowState?.orderData?.[notes] === status) {
                        filteredList.push(loadsAssignedOrders[i]);
                        break;
                    }
                }
            } else {
                if (loadsAssignedOrders[i].rowState?.orderData?.[notes] === status) {
                    filteredList.push(loadsAssignedOrders[i]);
                }
            }
        }
        return filteredList.filter(item => item.rowState.loadData.loadType === loadTypeNumber);
    };

    const byPODStatusAll = (loadsAssignedOrders, type) => {
        return [...byPODStatus(loadsAssignedOrders, type, 'in'), ...byPODStatus(loadsAssignedOrders, type, 'out')];
    };

    const byPODStatusTypeAll = (loadsAssignedOrders, type) => {
        return [...byPODStatusType(loadsAssignedOrders, type, 'in'), ...byPODStatusType(loadsAssignedOrders, type, 'out')];
    };

    let filteredData = [];
    if (podStatus !== '') {
        filteredData = loadsAssignedOrders;
        if (podStatus === '') {
            filteredData = loadsAssignedOrders;
        } else if (podStatus === 'Delivered') {
            filteredData = byPODStatusTypeAll(loadsAssignedOrders, 'Delivered');
        } else if (podStatus === 'Shortage') {
            filteredData = byPODStatusTypeAll(loadsAssignedOrders, 'Shortage');
        } else if (podStatus === 'Overage') {
            filteredData = byPODStatusTypeAll(loadsAssignedOrders, 'Overage');
        } else if (podStatus === 'Reject') {
            filteredData = byPODStatusTypeAll(loadsAssignedOrders, 'Reject');
        } else if (podStatus === 'Detoured') {
            filteredData = byPODStatusTypeAll(loadsAssignedOrders, 'Detoured');
        }
    } else {
        filteredData = loadsAssignedOrders;
        if (filterType === 'byIn') {
            filteredData = loadsAssignedOrders?.filter(item => item.rowState.loadData.loadType === 1);
        } else if (filterType === 'byOut') {
            filteredData = loadsAssignedOrders?.filter(item => item.rowState.loadData.loadType === 3);
        } else if (filterType === 'byPODRequestReceived') {
            filteredData = byPODStatusAll(loadsAssignedOrders, 1);
        } else if (filterType === 'byPODRequestRequested') {
            filteredData = byPODStatusAll(loadsAssignedOrders, 2);
        } else if (filterType === 'all') {
            filteredData = loadsAssignedOrders;
        };
    }
    const handleMouseEndSelectionAssigned = useCallback(selectedItems => {
        reactOnAssignedItems(resource, selectedItems.filter(i => i.rowState.type === 'order').map(i => i.rowState.id), 'order');
    }, [reactOnAssignedItems, resource]);

    const displayLoadsAssignedOrders = useMemo(() =>
        !filteredData || filteredData.length === 0
            ? [createSeparator(defRowHeight, state[resource].headers, 'load', 0)] : filteredData, [filteredData, state, resource]);
    
    const myLoadsAssignedOrders = useMemo(() => displayLoadsAssignedOrders.filter(d => d.rowState?.visible), [displayLoadsAssignedOrders]);

    const getAsignedItemSize = useCallback(
        index => myLoadsAssignedOrders[index] ? myLoadsAssignedOrders[index].rowState.height : defRowHeight,
        [myLoadsAssignedOrders]);
    
    const RenderAssignedRow = useCallback(({ data, index, style }) => (
        (index !== undefined && data[index])
            ? <Row
                listRef={assignedRef}
                data={data[index]}
                itemSize={getAsignedItemSize(index)}
                style={style}
                dndRowIndex={index}
                startOnScroll={startOnScroll}
                resource={resource}
                uploadModalIsOpen={uploadModalIsOpen}
                setUploadModalIsOpen={setUploadModalIsOpen}
                setUploadRowState={setUploadRowState}
                loadsAssignedOrders={myLoadsAssignedOrders}
                selectedOrder={selectedOrder}
            />
            : null
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ), [getAsignedItemSize, resource, uploadModalIsOpen, selectedOrder]);

    const SelectableComponent = useMemo(() => createSelectable(Row), []);

    const SelectableAssignedRow = useCallback(({ data, index, style }) => {
        return index !== undefined && data[index]
            ? <SelectableComponent
                selectableKey={data[index]}
                listRef={assignedRef}
                data={data[index]}
                itemSize={getAsignedItemSize(index)}
                style={style}
                dndRowIndex={index}
                startOnScroll={startOnScroll}
                resource={resource}
                uploadModalIsOpen={uploadModalIsOpen}
                setUploadModalIsOpen={setUploadModalIsOpen}
                setUploadRowState={setUploadRowState}
                loadsAssignedOrders={myLoadsAssignedOrders}
                selectedOrder={selectedOrder}
            />
            : null
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getAsignedItemSize, resource, selectedOrder]);

    const allOrdersList = useMemo(() => loadsAssignedOrders &&
    <AutoSizer disableWidth>
        {({ height }) => (
            <SelectableGroup onEndSelection={handleMouseEndSelectionAssigned}>
                <VariableSizeList
                    ref={assignedRef}
                    outerRef={el => { listReference.current = el }}
                    style={{ overflowX: 'hidden', overflowY: 'scroll' }}
                    itemData={myLoadsAssignedOrders}
                    itemCount={myLoadsAssignedOrders.length}
                    height={height}
                    itemSize={getAsignedItemSize}>
                    {selectable ? SelectableAssignedRow : RenderAssignedRow}
                </VariableSizeList>                            
            </SelectableGroup>
        )}
    </AutoSizer>
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , [loadsAssignedOrders, getAsignedItemSize, RenderAssignedRow, selectable, SelectableAssignedRow, handleMouseEndSelectionAssigned]);

    const content = (<>
        <div style={{ display: 'flex' }}>
            {header}
            <div style={{
                width: `${scrollSizeSmall}px`,
                maxWidth: `${scrollSizeSmall}px`,
                minWidth: `${scrollSizeSmall}px`,
                height: '32px',
                backgroundColor: 'lightgrey',
                display: extraScrollVisibility,
            }} />
        </div>
        <div style={{ flexGrow: 1, width: 'calc(100%)' }}>{allOrdersList}</div>
        <div style={{
            backgroundColor: 'red',
            width: '100%',
            height: `${scrollSize}px`,
            overflowX: 'scroll',
            flexShrink: 0,
        }}
            ref={mainScroll}
            onScroll={e => onScrollX(e)}>
            <div className='resp-table-row'>
                <div
                    className='table-body-cell'
                    style={{
                        maxWidth: `${width}px`,
                        width: `${width}px`,
                        minWidth: `${width}px`,
                        boxSizing: 'border-box',
                        backgroundColor: 'black',
                    }} />
            </div>
        </div>
        {uploadModalIsOpen && 
            <UploadModal
                rowState={uploadRowState}
                uploadModalIsOpen={uploadModalIsOpen}
                setUploadModalIsOpen={setUploadModalIsOpen}
            />
        }
    </>);

    const handleSelectChange = e => {
        // reset POD Status to empty
        setPODStatus({label: 'POD STATUS', value: ''});
        filterByPODStatus(resource, '');

        filterCallChecks(resource, e.key);
    };

    const menu = (
        <Menu className='customer-order__menu callBack-filter__menu' onClick={handleSelectChange}>
            <Menu.Item key="all">All</Menu.Item>
            <Menu.Item key="byIn">IN</Menu.Item>
            <Menu.Item key="byOut">OUT</Menu.Item>
            <Menu.Item key="byPODRequestRequested">POD requested</Menu.Item>
            <Menu.Item key="byPODRequestReceived">POD received</Menu.Item>
        </Menu>
    );
    const refreshPage = e => {
        // reset all filters
        setPODStatus({label: 'POD STATUS', value: ''});
        filterByPODStatus(resource, '');
        filterCallChecks(resource, 'all');

        load(resource);
    };

    const PODStatusList = [
        { value: '', label: '' },
        { value: 'Delivered', label: 'Delivered' },
        { value: 'Shortage', label: 'Shortage' },
        { value: 'Overage', label: 'Overage' },
        { value: 'Reject', label: 'Reject' },
        { value: 'Detoured', label: 'Detoured' },
    ];

    const handleChangeStatus = option => {
        // reset podRequest Status to all
        filterCallChecks(resource, 'all');

        setPODStatus(option);
        filterByPODStatus(resource, option.value);
    };

    const subHeader = (
        <div style={{ 
                display: 'flex',
                justifyContent: 'space-between',
                background: '#F2F2F2',
                height: '50px',     
            }}>
            <div className='call-check__toolbar_container' style={{height: '40px'}}>
                <div className='filter_call sort_icon'></div>
                <Dropdown className='call-check__dropdown' overlay={menu} placement="bottomLeft">
                    <span onClick={(e) => e.preventDefault()}>
                    <Space className='call-check__filter-icon'>Filter</Space>
                    </span>
                </Dropdown>
                <div className="button accent"
                    style={{marginTop: '11px'}}
                    onClick={refreshPage}
                >Refresh</div>
                <div>
                    <Input
                        className='call-check__input_search'
                        onChange={e => setSearchInput(e.target.value.toLowerCase())}
                        placeholder="PO# SO# LOAD#"
                        type="text"
                    />
                </div>
                <div className='callCheck-podStatus-select__container'>
                    <Select
                        options={PODStatusList}
                        name="podStatus"
                        placeholder="POS STATUS"
                        defaultValue={PODStatusList[0]}
                        value={PODStatus}
                        className='podStatus-select'
                        noOptionsMessage={() => podStatus}
                        onChange={option => handleChangeStatus(option)}
                        styles={styles}
                        components={{
                            IndicatorSeparator: () => null
                        }}
                    />
                </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <HideShowMenuSwitch />
            </div>
        </div>
    )

    return <>
        {subHeader}{loading ? <Loading /> : <>{content}</>}</>;
};

const mapStateToProps = state => ({
    state: state,
    loadsAssignedOrders: state.callCheck.loadsAssignedOrders,
    loading: state.callCheck.loading,
    haseError: state.callCheck.hasError,
    actionMessage: state.callCheck.message,
    filterType: state.callCheck.filterType,
    userId: state.auth.userId,
    podStatus: state.callCheck.podStatus,
});

const mapDispatchToProps = (dispatch) => ({
    load: (resource, loadingType) => dispatch(load(resource, loadingType)),
    resetError: resource => dispatch(resetError(resource)),
    filterCallChecks: (resource, key) => dispatch(filterCallChecks(resource, key)),
    reactOnAssignedItems: (resource, ids, type) => dispatch(reactOnAssignedItems(resource, ids, type)),
    filterByPODStatus: (resource, podStatus) => dispatch(filterByPODStatus(resource, podStatus)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Spreadsheet);