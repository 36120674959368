import React from 'react';
import { Input, Checkbox } from 'antd';
import Select from 'react-select';
import HideShowMenuSwitch from '../../layout/hideShowMenuSwitch';
import { styles } from './styles';

const ARSubHeader = ({ 
        podStatus, 
        setPodStatus, 
        filteredAvailableOrder, 
        pathname, 
        resource, 
        filter, 
        endpoint, 
        load,
        availableOrders,
        setFilteredData,
        isAdmin,
        markAllAsChecked,
        allIsChecked,
    }) => {
    
    const podStatusList = filteredAvailableOrder && filteredAvailableOrder.map(order => { 
        return {
            value: order?.rowState?.orderData?.outNotes, 
            'label': order?.rowState?.orderData?.outNotes }
    });

    
    const uniquePodStatusList = podStatusList
        ?.filter((order, index, self) => index === self.findIndex(t => t.label === order.label && t.value === order.value))
        .filter(order => order.value !== '')
        .concat({ value: 'All', label: 'All' });

    const handleChangePODStatus = option => {
        setPodStatus(option.value);
    };

    const refreshPage = e => {
        if (pathname === '/ar/openorders') {
            load(resource, filter, 'availableorderlist');
        } else if (pathname === '/ar/changerequestsar') {
            load(resource, filter, 'orderschangerequestslist');
        } else {
            if(pathname === '/ar/deliverednopod') {
                endpoint = 'delivered';
                filter = '{"isReadyToBill": false, "isBilled": false}';
            } else if(pathname === '/ar/readytobill') {
                filter = '{"isReadyToBill": true, "isBilled": false}';
                endpoint = 'readytobill';
            } else if(pathname === '/ar/alreadybilled') {
                filter = '{"isReadyToBill": false, "isBilled": true}';
                endpoint = 'billed';
            };
            load(resource, filter, endpoint);
        };
    };

    const search = e => {
        let newList = [...availableOrders];
        if(e.target.value){
            newList = newList?.filter(item => {
                const searchCell = item.row.filter(item1 => item1.key === "poNumber"
                    || item1.key === "soNumber"
                    || item1.key === "pickLocationName"
                    || item1.key === "drop2WH"
                    || item1.key === "customerName"
                )
                const bool = searchCell.filter(item1 => item1.value.toLowerCase().search(e.target.value.toLowerCase()) !== -1)
                return bool.length
            })
            setFilteredData(newList)
        } else {
            setFilteredData(newList)
        }
    };

    const showSelectePODStatus =  pathname === '/ar/alreadybilled' || pathname === '/ar/deliverednopod' || pathname === '/ar/readytobill';

    return (
        <div>
            <div className='AR__sub-header'>
                <div className='AR-filters'>
                    <div className='button accent' onClick={() => refreshPage()}>
                        Refresh
                    </div>
                    <Input className='AR-sub-header__search-input' onChange={search} placeholder='Search' type='text' />
                    {showSelectePODStatus && <div className='filter_container__select'>
                        <div className='filter_title'>FILTER</div>
                        <Select
                            options={uniquePodStatusList}
                            name='PONumber'
                            placeholder='POD Status'
                            value={podStatus && podStatus === 'All' ? { value: podStatus, label: 'POD Status' } : { value: podStatus, label: podStatus }}
                            noOptionsMessage={() => null}
                            onChange={option => handleChangePODStatus(option)}
                            styles={styles}
                            components={{
                                IndicatorSeparator: () => null,
                            }}
                        />
                    </div>}
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <HideShowMenuSwitch />
                </div>
            </div>
            <div>
                {pathname === '/ar/changerequestsar' && isAdmin ?
                <div style={{ background: '#F2F2F2', padding: '4px 0 14px 24px', fontSize: '14px' }}>
                    <Checkbox onChange={markAllAsChecked} checked={allIsChecked} className='customer-order-admin__checkbox'></Checkbox>
                    <span>Mark all as read</span>
                </div> : null
            }
            </div>
        </div>
        );
    };

export default ARSubHeader;
