import React, { useState } from 'react';
import { connect } from "react-redux";
import { Resizable } from 'react-resizable';
import { setHeaderWidthTraffic } from '../../../../store/actions/trafficControl/trafficControl';
import { setHeaderWidthUpcomingTraffic } from '../../../../store/actions/trafficControlUpcoming/trafficControlUpcoming';
import { trafficControlUpcoming, resetError, filterByDateUpcoming } from '../../../../store/actions/trafficControlUpcoming/trafficControlUpcoming';
import { DatePicker, Space } from 'antd';
import { CaretDownOutlined} from '@ant-design/icons';
const { RangePicker } = DatePicker;

const HeaderCell = (
    {
        state,
        ind,
        setHeaderWidthTraffic,
        setHeaderWidthUpcomingTraffic,
        resource,
        trafficSubHeader,
        filterByDateUpcoming,
        trafficUpcoming,
    }) => {

    const traffic = state[resource];
    const upcomingTrafficList = state[resource]?.upcomingTrafficList;
    const data = trafficSubHeader ? traffic.subHeaders[ind] : traffic.headers[ind]
    let cellStyle = {
        maxWidth: `${data.width}px`,
        width: `${data.width}px`,
        minWidth: `${data.width}px`,
        backgroundColor: trafficSubHeader ? '#F3F3F3' : '#29CABA',
        color: trafficSubHeader ? '#204D49' : '#FFFFFF',
    };

    const firstSubHeaderWidth = trafficUpcoming.subHeaders[0].width;
    const [showCalendar, setShowCalendar] = useState(false);

    const onResizeStart = (e, s) => {
        // console.log('onResizeStart', e, s);
    };

    const onResize = s => {
        !trafficSubHeader && resource === 'traffic' && ind !== 14 && ind !== 18 && ind < 21 && setHeaderWidthTraffic(resource, ind, s.size.width);
        !trafficSubHeader && resource === 'trafficUpcoming' && ind > 6 && ind !== 11 && setHeaderWidthUpcomingTraffic(resource, ind, s.size.width);
        //console.log('onResize', e, s);
    };

    const onResizeStop = (e, s) => {
        // console.log('onResizeStop', e, s);
    };

    const onChange = ( value ) => {
        value[0].set("hour", 0).set("minute", 0).set("second", 0);
        value[1].set("hour", 23).set("minute", 59).set("second", 59);

        const currentListDates = [{
            order: '',
            date: '',
        }];
        for (let i = 0; i < upcomingTrafficList.length; i++) {
            for (let j = 0; j < upcomingTrafficList[i].row.length; j++) {
                if (upcomingTrafficList[i].row[j].key === 'day') {
                    currentListDates.push({
                        order: upcomingTrafficList[i], 
                        date: new Date(upcomingTrafficList[i].row[j].date)
                    })
                }
            }
        }
        const getNewListObject = (currentListDates, value) => {
            return currentListDates.filter(date => date.date > value[0] && date.date < value[1]);
        };
        const filteredListObjects = getNewListObject(currentListDates, value);
        const filteredOrdersList = filteredListObjects.map(object => object.order);

        setShowCalendar(false);
        filterByDateUpcoming(resource, filteredOrdersList)
    };

    let headerCall = (ind === 0 && resource === 'trafficUpcoming' && trafficSubHeader && !showCalendar) ? 
        <div style={{ minWidth: firstSubHeaderWidth }} className='filter' onClick={() => setShowCalendar(true)}><span className='filterText'>FILTER BY DATE</span><CaretDownOutlined /></div> :
            (ind === 0 && resource === 'trafficUpcoming' && trafficSubHeader && showCalendar) ? 
        <div className='rangePicker' style={{ width: firstSubHeaderWidth }}>
            <div className='closeX' onClick={() => setShowCalendar(false)}>X</div>
            <div>
                <Space direction="vertical" size={12}>
                    <RangePicker
                        format="YYYY-MM-DD"
                        onChange={onChange}
                    />
                </Space>
            </div>
        </div> :
        <div
            className='table-header-cell table-header-cell-traffic'
            style={cellStyle}>
            {data.title}
        </div>;

    const resizableHeaderCall = (
        <Resizable
            width={data.width}
            height={0}
            handle={
                <span
                    className="react-resizable-handle"
                    onClick={e => {
                        e.stopPropagation();
                    }}
                />
            }
            onResizeStart={(_, s) => onResizeStart(s)}
            onResize={(_, s) => onResize(s)}
            onResizeStop={(_, s) => onResizeStop(s)}
            draggableOpts={{ enableUserSelectHack: false }}
        >
            {headerCall}
        </Resizable>
    );

    return resizableHeaderCall;
};

const mapStateToProps = state => ({
    state: state,
    trafficUpcoming: state.trafficUpcoming,
});

const mapDispatchToProps = (dispatch) => ({
    trafficControlUpcoming: (resource) => dispatch(trafficControlUpcoming(resource)),
    resetError: resource => dispatch(resetError(resource)),
    setHeaderWidthTraffic: (resource, ind, width) => dispatch(setHeaderWidthTraffic(resource, ind, width)),
    setHeaderWidthUpcomingTraffic: (resource, ind, width) => dispatch(setHeaderWidthUpcomingTraffic(resource, ind, width)),
    filterByDateUpcoming: (resource, filteredOrdersList) => dispatch(filterByDateUpcoming(resource, filteredOrdersList)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderCell);