import React from 'react';
import { connect } from "react-redux";
import HeaderCell from './headerCell'

const HeaderRow = ({ state, resource }) => {
    const loadArea = state[resource];

    return (
        <div className='resp-table-header' style={{ height: '30px', background: '#449996', border: 'none'}}>
            {loadArea?.headers.map((header, ind) => {
                return <HeaderCell key={ind} ind={ind} resource={resource} headerKey={header.key} />
            })}
        </div>
    );
};

export default connect(state => ({
    state: state
}))(HeaderRow);