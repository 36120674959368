import React, { useState, useEffect } from 'react';
import axios from '../../../axios/axios';
import Orders from './orders';
import errorHandler from '../../../error/errorHandler';
import { ElementTypes } from '../../../config';

const AssignedOrders = props => {
    const [isLoading, setLoading] = useState(true);

    // Order
    const { List, Resource, setAvailableSelection, OrderForm, dropId, Key, dragDropData, dropType,
        selectable, handleCheck, selectedRows, setSelectedRows, initDragDropData, dispatchDropData } = props;
    const columns = List.Columns;

    columns['inboundAppointment'] = {
        Title: 'Appt',
        Field: ElementTypes.Generic(ElementTypes.ColumnDateTime, {
            style: (_, data) => {
                return data ? null : ({ backgroundColor: 'rgb(255, 240, 86)', height: '20px', width: '100%' });
            }
        }),
        Width: 60
    };

    useEffect(() => {
        let isMounted = true;
        const fetchData = async (isMounted) => {
            if (isMounted) {
                setLoading(true);
            }
            const response = await axios(
                Resource,
                {
                    params: {}
                }
            ).finally(() => {
                if (isMounted) {
                    setLoading(false);
                }
            });
            if (isMounted) {
                if (response) {
                    const result = response.data;
                    if (result) {
                        const data = result.data;
                        for (var i = 0; i < data.length; i++) {
                            if (!data[i].key) {
                                data[i].key = data[i][Key || 'id'];
                            }
                        };
                        initDragDropData({ type: dropType, value: data });
                        setLoading(false);
                    }
                }
            }
        };
        setLoading(true);
        fetchData(isMounted);
        return () => { isMounted = false };
    }, [Resource, dropId, Key, dropType, initDragDropData]);

    const onAfterMouseSelect = () => {
        setAvailableSelection(false);
    };

    return <Orders
        isLoading={isLoading}
        resource={props.resource}
        OrderForm={OrderForm}
        dropId={dropId}
        keyFieldName={Key}
        columns={columns}
        data={dragDropData?.[dropId]?.data || []}
        dropType={dropType}
        selectable={selectable}
        handleCheck={handleCheck}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        onAfterMouseSelect={onAfterMouseSelect}
        dispatchDropData={dispatchDropData}
    />;
};
export default errorHandler(AssignedOrders, axios);