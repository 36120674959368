export const customStyles = {
  control: (base, state) => ({
    ...base,
    borderRadius: '0',
    boxShadow: 'none',
    width: '100%',
    cursor: 'notAllowed',
    background: 'rgba(136, 136, 136, 0.1)',
    border: 'none',
    minHeight: '20px',
    height: '20px',
  }),
  menu: (base, state) => ({
    ...base,
    marginTop: '4px',
  }),
  menuList: () => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    borderRadius: 'none!important',
    overflowY: 'scroll',
    height: '200px',
  }),
  valueContainer: (base, state) => ({
    display: 'flex',
    alignItems: 'self-end',
    paddingLeft: '5px',
    maxWidth: '224px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  }),
  container: (base, state) => ({
    ...base,
    pointerEvents: 'auto',
  }),
  singleValue: (base, state) => ({
    maxWidth: '224px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  }),
  input: (base, state) => ({
    padding: 0,
    margin: 0,
  }),
  options: (base, state) => ({
    padding: '3px 12px',
    cursor: 'pointer',
    borderRadius: 'unset',
  }),
  indicatorsContainer: () => ({
    marginRight: '4px',
  }),
  indicatorContainer: () => ({
    padding: '0 4px 8px 0',
  }),
};

export const customStylesForCountries = {
  ...customStyles,
  menuList: () => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    borderRadius: 'none!important',
    height: '72px',
  }),
};

export const warningModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '400px',
    minHeight: '200px',      
    borderRadius: '8px',
    padding: '6px 12px',
  },
};