import {
    GenerateSheet,
    GenerateCommentsSheet,
    GeneratePermissionSheet,
    GenerateWorkingDays,
    GenerateOrder,
    GenerateAttachment,
    GenerateManagedSheet,
    GenerateRateConfirmation,
    GenerateRateConfirmationForm,
    GenerateCarrierPackage,
    GenerateCheckBoxMultiple,
    GenerateLocations,
} from '../helper/component';

export const ComponentTypes = {
    Sheet: (keyFieldName, columns, classes) => (type, data, dispatch, messages, messageDispatch) => GenerateSheet(type, keyFieldName, columns, data, dispatch, messages, messageDispatch, classes),
    CommentsSheet: (keyFieldName, columns, classes) => (type, data, dispatch, messages, messageDispatch) => GenerateCommentsSheet(type, keyFieldName, columns, data, dispatch, messages, messageDispatch, classes),
    Permission: (keyFieldName, columns) => (type, data, dispatch, messages, messageDispatch) => GeneratePermissionSheet(type, keyFieldName, columns, data, dispatch, messages, messageDispatch),
    WorkingDays: (keyFieldName, columns) => (type, data, dispatch, messages, messageDispatch, componentRefs, messageIdentityPrefix, object) => GenerateWorkingDays(type, keyFieldName, columns, data, dispatch, messages, messageDispatch, componentRefs, messageIdentityPrefix, object),
    //Expandable: (keyFieldName, config) => (type, data, dispatch, messages, messageDispatch, componentRefs, messageIdentityPrefix, object) => GenerateExpandeble(type, config, keyFieldName, data, dispatch, messages, messageDispatch, componentRefs, messageIdentityPrefix, object),
    Order: (keyFieldName, config) => (type, data, dispatch, messages, messageDispatch, componentRefs, messageIdentityPrefix, object) => GenerateOrder(type, keyFieldName, config, data, dispatch, messages, messageDispatch, componentRefs, messageIdentityPrefix, object),
    Attachments: (keyFieldName, config) => (type, data, dispatch, messages, messageDispatch, componentRefs, messageIdentityPrefix, object) => GenerateAttachment(type, keyFieldName, config, data, dispatch, messages, messageDispatch, componentRefs, messageIdentityPrefix, object),
    ManagedSheet: (keyFieldName, config) => (type, data, dispatch, messages, messageDispatch, componentRefs, messageIdentityPrefix, object) => GenerateManagedSheet(type, keyFieldName, config, data, dispatch, messages, messageDispatch, componentRefs, messageIdentityPrefix, object),
    RateConfirmation: (/* displayability = Ability.Displayability,  */keyFieldName, config) => (type, data, dispatch, messages, messageDispatch, componentRefs, messageIdentityPrefix, object) => GenerateRateConfirmation(type, keyFieldName, config, data, dispatch, messages, messageDispatch, componentRefs, messageIdentityPrefix, object),
    CarrierPackage: (keyFieldName, config) => (type, data, dispatch, messages, messageDispatch, componentRefs, messageIdentityPrefix, object) => GenerateCarrierPackage(type, keyFieldName, config, data, dispatch, messages, messageDispatch, componentRefs, messageIdentityPrefix, object),
    CheckBoxMultiple: () => (type, data, dispatch) => GenerateCheckBoxMultiple(type, data, dispatch),
    Locations: () => (type, data, dispatch) => GenerateLocations(type, data, dispatch),
};

export const RateConfirmationForm = (keyFieldName, config) => (type, data, dispatch, messages, messageDispatch, componentRefs, messageIdentityPrefix, object) => GenerateRateConfirmationForm(type, keyFieldName, config, data, dispatch, messages, messageDispatch, componentRefs, messageIdentityPrefix, object);