import React from 'react';

const ForgotPasswordToolbare = props => (
    <React.Fragment>
        <toolbar none="">
            <button className="button primary large wide" type="button" onClick={props.resetPassword}>
                <text>Change Password</text>
            </button>
        </toolbar>
    </React.Fragment>
);

export default ForgotPasswordToolbare;