export const RELOAD_ON_ERROR_LOAD_DETAILS = 'RELOAD_ON_ERROR_LOAD_DETAILS';
export const RESET_ERROR = 'RESET_ERROR';
export const GET_CUSTOMERS_LIST = 'GET_CUSTOMERS_LIST';
export const GET_CUSTOMERS_LIST_LOADING = 'GET_CUSTOMERS_LIST_LOADING';
export const GET_CUSTOMERS_LIST_ERROR = 'GET_CUSTOMERS_LIST_ERROR';
export const GET_CUSTOMERS_PRICE_LIST = 'GET_CUSTOMERS_PRICE_LIST';
export const GET_CUSTOMERS_PRICE_LIST_LOADING = 'GET_CUSTOMERS_PRICE_LIST_LOADING';
export const GET_CUSTOMERS_PRICE_LIST_ERROR = 'GET_CUSTOMERS_PRICE_LIST_ERROR';
export const SET_DISPLAY_STATE = 'SET_DISPLAY_STATE';
export const ADD_EMPTY_LINE_ON_PRICE_LIST = 'ADD_EMPTY_LINE_ON_PRICE_LIST';
export const REMOVE_EMPTY_LINES_ON_PRICE_LIST = 'REMOVE_EMPTY_LINES_ON_PRICE_LIST';
export const ADD_CUSTOMER_PRICE_LOADING = 'ADD_CUSTOMER_PRICE_LOADING';
export const ADD_CUSTOMER_PRICE = 'ADD_CUSTOMER_PRICE';
export const ADD_CUSTOMER_PRICE_ERROR = 'ADD_CUSTOMER_PRICE_ERROR';
export const SET_CUSTOMER_PRICE_DATA_LOADING = 'SET_CUSTOMER_PRICE_DATA_LOADING';
export const SET_CUSTOMER_PRICE_DATA = 'SET_CUSTOMER_PRICE_DATA';
export const SET_CUSTOMER_PRICE_DATA_ERROR = 'SET_CUSTOMER_PRICE_DATA_ERROR';
export const SET_CUSTOMER_PRICE_TYPE = 'SET_CUSTOMER_PRICE_TYPE';
export const SET_CUSTOMER_PRICE_TYPE_LOADING = 'SET_CUSTOMER_PRICE_TYPE_LOADING';
export const SET_CUSTOMER_PRICE_TYPE_ERROR = 'SET_CUSTOMER_PRICE_TYPE_ERROR';
export const DELETE_CUSTOMER_PRICE_ERROR = 'DELETE_CUSTOMER_PRICE_ERROR';
export const DELETE_CUSTOMER_PRICE_LOADING = 'DELETE_CUSTOMER_PRICE_LOADING';
export const SET_PER_PALLET_TYPE = 'SET_PER_PALLET_TYPE';
export const GET_CUSTOMER_LOCATIONS_LIST_LOADING = 'GET_CUSTOMER_LOCATIONS_LIST_LOADING';
export const GET_CUSTOMER_LOCATIONS_LIST = 'GET_CUSTOMER_LOCATIONS_LIST';
export const GET_CUSTOMER_LOCATIONS_LIST_ERROR = 'GET_CUSTOMER_LOCATIONS_LIST_ERROR';

export const getActionType = (resource, key) => {
    return CUSTOMERS_BUILD[key];
};

export const CUSTOMERS_BUILD = {
    RELOAD_ON_ERROR_LOAD_DETAILS: 'RELOAD_ON_ERROR_LOAD_DETAILS',
    RESET_ERROR: 'RESET_ERROR',
    GET_CUSTOMERS_LIST: 'GET_CUSTOMERS_LIST',
    GET_CUSTOMERS_LIST_LOADING: 'GET_CUSTOMERS_LIST_LOADING',
    GET_CUSTOMERS_LIST_ERROR: 'GET_CUSTOMERS_LIST_ERROR',
    GET_CUSTOMERS_PRICE_LIST: 'GET_CUSTOMERS_PRICE_LIST',
    GET_CUSTOMERS_PRICE_LIST_LOADING: 'GET_CUSTOMERS_PRICE_LIST_LOADING',
    GET_CUSTOMERS_PRICE_LIST_ERROR: 'GET_CUSTOMERS_PRICE_LIST_ERROR',
    SET_DISPLAY_STATE: 'SET_DISPLAY_STATE',
    ADD_EMPTY_LINE_ON_PRICE_LIST: 'ADD_EMPTY_LINE_ON_PRICE_LIST',
    REMOVE_EMPTY_LINES_ON_PRICE_LIST: 'REMOVE_EMPTY_LINES_ON_PRICE_LIST',
    ADD_CUSTOMER_PRICE_LOADING: 'ADD_CUSTOMER_PRICE_LOADING',
    ADD_CUSTOMER_PRICE: 'ADD_CUSTOMER_PRICE',
    ADD_CUSTOMER_PRICE_ERROR: 'ADD_CUSTOMER_PRICE_ERROR',
    SET_CUSTOMER_PRICE_DATA_LOADING: 'SET_CUSTOMER_PRICE_DATA_LOADING',
    SET_CUSTOMER_PRICE_DATA: 'SET_CUSTOMER_PRICE_DATA',
    SET_CUSTOMER_PRICE_DATA_ERROR: 'SET_CUSTOMER_PRICE_DATA',
    SET_CUSTOMER_PRICE_TYPE: 'SET_CUSTOMER_PRICE_TYPE',
    SET_CUSTOMER_PRICE_TYPE_LOADING: 'SET_CUSTOMER_PRICE_TYPE_LOADING',
    SET_CUSTOMER_PRICE_TYPE_ERROR: 'SET_CUSTOMER_PRICE_TYPE_ERROR',
    DELETE_CUSTOMER_PRICE_ERROR: 'DELETE_CUSTOMER_PRICE_ERROR',
    DELETE_CUSTOMER_PRICE_LOADING: 'DELETE_CUSTOMER_PRICE_LOADING',
    SET_PER_PALLET_TYPE: 'SET_PER_PALLET_TYPE',
    GET_CUSTOMER_LOCATIONS_LIST_LOADING: 'GET_CUSTOMER_LOCATIONS_LIST_LOADING',
    GET_CUSTOMER_LOCATIONS_LIST: 'GET_CUSTOMER_LOCATIONS_LIST',
    GET_CUSTOMER_LOCATIONS_LIST_ERROR: 'GET_CUSTOMER_LOCATIONS_LIST_ERROR',
};
