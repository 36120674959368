export const ERROR = 'ERROR';
export const RESET_ERROR = 'RESET_ERROR';
export const RELOAD_ON_ERROR = 'RELOAD_ON_ERROR';
export const SET_HEADER_WIDTH_FACTORING_COMPANY = 'SET_HEADER_WIDTH_FACTORING_COMPANY';
export const SET_DISPLAY_STATE = 'SET_DISPLAY_STATE';
export const GET_FACTORING_COMPANY_LIST = 'GET_FACTORING_COMPANY_LIST';
export const GET_FACTORING_COMPANY_LIST_LOADING = 'GET_FACTORING_COMPANY_LIST_LOADING';
export const SORT_FACTORING_COMPANY = 'SORT_FACTORING_COMPANY';
export const GET_FACTORING_COMPANY_DETAILS_LOADING = 'GET_FACTORING_COMPANY_DETAILS_LOADING';
export const GET_FACTORING_COMPANY_DETAILS = 'GET_FACTORING_COMPANY_DETAILS';
export const DELETE_FACTORING_COMPANY_LOADING = 'DELETE_FACTORING_COMPANY_LOADING';
export const ADD_FACTORING_COMPANY_LOADING = 'ADD_FACTORING_COMPANY_LOADING';
export const ADD_FACTORING_COMPANY = 'ADD_FACTORING_COMPANY';
export const UPDATE_FACTORING_COMPANY_LOADING = 'UPDATE_FACTORING_COMPANY_LOADING';
export const UPDATE_FACTORING_COMPANY = 'UPDATE_FACTORING_COMPANY';
export const GET_STATES_LIST = 'GET_STATES_LIST';
export const EMPTY_FACTORING_COMPANY_DETAILS = 'EMPTY_FACTORING_COMPANY_DETAILS';

export const getActionType = (recourse, key) => {
    return FACTORING_COMPANY_BUILD[key]
};

export const FACTORING_COMPANY_BUILD = {
    ERROR: 'ERROR',
    RESET_ERROR: 'RESET_ERROR',
    RELOAD_ON_ERROR: 'RELOAD_ON_ERROR',
    SET_HEADER_WIDTH_FACTORING_COMPANY: 'SET_HEADER_WIDTH_FACTORING_COMPANY',
    GET_FACTORING_COMPANY_LIST: 'GET_FACTORING_COMPANY_LIST',
    GET_FACTORING_COMPANY_LIST_LOADING: 'GET_FACTORING_COMPANY_LIST_LOADING',
    SORT_FACTORING_COMPANY: 'SORT_FACTORING_COMPANY',
    GET_FACTORING_COMPANY_DETAILS_LOADING: 'GET_FACTORING_COMPANY_DETAILS_LOADING',
    GET_FACTORING_COMPANY_DETAILS: 'GET_FACTORING_COMPANY_DETAILS',
    DELETE_FACTORING_COMPANY_LOADING: 'DELETE_FACTORING_COMPANY_LOADING',
    ADD_FACTORING_COMPANY_LOADING: 'ADD_FACTORING_COMPANY_LOADING',
    ADD_FACTORING_COMPANY: 'ADD_FACTORING_COMPANY',
    GET_STATES_LIST: 'GET_STATES_LIST',
    EMPTY_FACTORING_COMPANY_DETAILS: 'EMPTY_FACTORING_COMPANY_DETAILS',
    UPDATE_FACTORING_COMPANY_LOADING: 'UPDATE_FACTORING_COMPANY_LOADING',
    UPDATE_FACTORING_COMPANY: 'UPDATE_FACTORING_COMPANY',
};
