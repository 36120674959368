import store from 'store';

import { CloneObject } from '../codeless/helper/common';

const UpdateState = (state, val) => {
    const newState = CloneObject(state);
    const updatedState = { ...newState, val };
    return updatedState;
};

export const updateStateFromStore = (state, storeKeys) => {
    const newState = CloneObject(state);
    for (let stateItem in newState) {
        newState[stateItem] = store.get(storeKeys[stateItem]) || newState[stateItem];
    }
    return newState;
};

export const updateStoreFromState = (state, storeKeys) => {
    for (let stateItem in storeKeys) {
        store.set(storeKeys[stateItem], state[stateItem]);
    }
};

export const removeStore = (storeKeys) => {
    for (let stateItem in storeKeys) {
        store.remove(storeKeys[stateItem]);
    }
};

export default UpdateState;