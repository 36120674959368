export const getRateConfirmationData = (state, response, hasError = false, message = '') => {
    const data = response.data || [];
    return {
        ...state, ...{
            rateConfirmationData: data,
            loading: false,
            hasError,
            message,
        }    
    }
};

export const getRateConfirmationPDF_URL = (state, response, hasError = false, message = '') => {
    const guid = response.substring(response.lastIndexOf('/') + 1);
    return {
        ...state, ...{
            rateConfirmationGUID: guid,
            loading: false,
            hasError,
            message,
        }    
    }
};

export const getCarrierRateConfirmationdata = (state, response, hasError = false, message = '') => {
    const data = response.data || [];

    return {
        ...state, ...{
            carrierRateConfirmationData: data.data,
            loading: false,
            hasError,
            message,
        }    
    }
};

export const setError = (state, err) => ({
    ...state,
    ...{
        hasError: true,
        message: 'Something went wrong, please try again',
    },
});

export const resetError = state => ({
    ...state,
    ...{
        hasError: false,
        message: '',
        ...state.rateConfirmationData,
        ...{
            hasError: false,
            message: '',
        }
    }
});

export const setInLoading = state => ({
    ...state, ...{
        loading: true
    }
});
