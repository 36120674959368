import {
    ElementTypes,
    Ability
} from '../../config';
import { GenerateToolBars } from '../../config/element.config';

const WarehouseForm = {
    Title: 'Warehouse',
    Resource: 'Warehouse',
    Master: 'name',
    Key: 'id',
    IsRest: true,
    Form: {
        SectionGroups: [
            {
                Sections: [
                    {
                        Fields: {
                            name: {
                                Title: 'Name',
                                Field: ElementTypes.LabelField()
                            },
                            code: {
                                Title: 'Code',
                                Field: ElementTypes.LabelField()
                            },
                            warehouseNotes: {
                                Title: 'Warehouse Notes',
                                Field: ElementTypes.CommentLabel()
                            },
                        }
                    },
                    {
                        Fields: {
                            warehouseAddress: {
                                Title: 'Warehouse Address',
                                Field: ElementTypes.LabelField()
                            },
                            city: {
                                Title: 'City',
                                Field: ElementTypes.LabelField()
                            },
                            stateCode: {
                                Title: 'State',
                                Field: ElementTypes.LabelField()
                            }
                        }
                    },
                    {
                        Fields: {
                            map: {
                                Title: 'Map',
                                Field: ElementTypes.MapField(Ability.Displayability, () => false)
                            },
                        }
                    }
                ]
            }
        ],
        ToolBars: GenerateToolBars(['Close'])
    }
};

export default WarehouseForm;