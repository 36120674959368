import React from 'react';

import Element from './loginField/element/element';

const Wrap = props => (
    <wrap space="">
        {props.fields && Object.keys(props.fields).map((e, i) => {
            const field = props.fields[e];
            const fieldClasses = [];//['line'];
            return <Element
                {...field}
                type={e}
                key={i}
                data={props.data}
                dispatch={props.dispatch}
                messages={props.messages}
                messageDispatch={props.messageDispatch}
                val={props.data ? props.data[e] : ''}
                fieldClasses={fieldClasses}
                actionFromElement={props.actionFromElement}
            />
        })}
    </wrap>
);

export default Wrap;