const itemDefaultSize = 22;

export const getCustomersList = (state, response, hasError = false, message = '') => {
  const data = response.data || [];
  return {
      ...state, ...{
          customersList: data,
          loading: false,
          hasError,
          message,
      }    
  }
};

export const createCustomerPrice = (oData, headers) => {
  const data = { ...oData };
  const customerPriceData = {
    row: [],
    rowState: {
      id: data.id,
      data: data,
      height: itemDefaultSize,
    },
  };

  for (let headerIndex = 0; headerIndex < headers.length; headerIndex++) {
    const headerItem = headers[headerIndex];
    let value = null;
    let fieldType = 'text';
    let editable = false;
    switch (headerItem.key) {
      case 'pickLocation':
        value = data.pickLocation ? data.pickLocation : data.pickLocationId;
        fieldType = 'selectPickLocation';
        break;
      case 'dropLocation':
        value = data.dropLocation ? data.dropLocation : data.dropLocationId;
        fieldType = 'selectDropLocation';
        break;
      case 'tl':
        value = data.maxPrice;
        fieldType = 'integer';
        editable = true;
        break;
      case '1':
        value = data.formulas[0]?.price;
        fieldType = 'integer';
        editable = true;
        break;
      case '2-4':
        value = data.formulas[1]?.price;
        fieldType = 'integer';
        editable = true;
        break;
      case '5-8':
        value = data.formulas[2]?.price;
        fieldType = 'integer';
        editable = true;
        break;
      case '9-12':
        value = data.formulas[3]?.price;
        fieldType = 'integer';
        editable = true;
        break;
      case '13-16':
        value = data.formulas[4]?.price;
        fieldType = 'integer';
        editable = true;
        break;
      case '17-21':
        value = data.formulas[5]?.price;
        fieldType = 'integer';
        editable = true;
        break;
      case 'delete':
        value = 'delete';
        fieldType = 'delete';
        break;
      case 'minPrice':
        value = data.minPrice;
        fieldType = 'integer';
        editable = true;
        break;
      case 'perWeight':
        value = data.perWeight;
        fieldType = 'integer';
        editable = true;
        break;
      case '2':
        value = data.formulas[1]?.price;
        fieldType = 'integer';
        editable = true;
        break;
      case '3':
        value = data.formulas[2]?.price;
        fieldType = 'integer';
        editable = true;
        break;
      case '4':
        value = data.formulas[3]?.price;
        fieldType = 'integer';
        editable = true;
        break;
      case '5':
        value = data.formulas[4]?.price;
        fieldType = 'integer';
        editable = true;
        break;
      case '6':
        value = data.formulas[5]?.price;
        fieldType = 'integer';
        editable = true;
        break;
      case '7':
        value = data.formulas[6]?.price;
        fieldType = 'integer';
        editable = true;
        break;
      case '8':
        value = data.formulas[7]?.price;
        fieldType = 'integer';
        editable = true;
        break;
      case '9':
        value = data.formulas[8]?.price;
        fieldType = 'integer';
        editable = true;
        break;
      case '10':
        value = data.formulas[9]?.price;
        fieldType = 'integer';
        editable = true;
        break;
      case '11':
        value = data.formulas[10]?.price;
        fieldType = 'integer';
        editable = true;
        break;
      case '12':
        value = data.formulas[11]?.price;
        fieldType = 'integer';
        editable = true;
        break;
      case '13':
        value = data.formulas[12]?.price;
        fieldType = 'integer';
        editable = true;
        break;
      case '14':
        value = data.formulas[13]?.price;
        fieldType = 'integer';
        editable = true;
        break;
      case '15':
        value = data.formulas[14]?.price;
        fieldType = 'integer';
        editable = true;
        break;
      case '16':
        value = data.formulas[15]?.price;
        fieldType = 'integer';
        editable = true;
        break;
      case '17':
        value = data.formulas[16]?.price;
        fieldType = 'integer';
        editable = true;
        break;
      case '18':
        value = data.formulas[17]?.price;
        fieldType = 'integer';
        editable = true;
        break;
      case '19':
        value = data.formulas[18]?.price;
        fieldType = 'integer';
        editable = true;
        break;
      case '20':
        value = data.formulas[19]?.price;
        fieldType = 'integer';
        editable = true;
        break;
      case '21':
        value = data.formulas[20]?.price;
        fieldType = 'integer';
        editable = true;
        break;
      default:
        break;
    }
    customerPriceData.row.push({
      key: headerItem.key,
      value,
      fieldType,
      editable,
    });
  }
  return customerPriceData;
};

export const createEmptyCustomerPriceLine = (headers, customerId) => {
  const isPricePerPall = headers.filter(header => header.key === '2');
  const data = {
    id: 0,
    customerId: customerId,
    dropLocationId: '',
    pickLocationId: '',
    minPrice: 0,
    maxPrice: 0,
    perWeight: 0,
    formulas: isPricePerPall && isPricePerPall.length > 0? [
      {
        pallCount: 1,
        price: 0
      },
      {
        pallCount: 2,
        price: 0
      },
      {
        pallCount: 3,
        price: 0
      },
      {
        pallCount: 4,
        price: 0
      },
      {
        pallCount: 5,
        price: 0
      },
      {
        pallCount: 6,
        price: 0
      },
      {
        pallCount: 7,
        price: 0
      },
      {
        pallCount: 8,
        price: 0
      },
      {
        pallCount: 9,
        price: 0
      },
      {
        pallCount: 10,
        price: 0
      },
      {
        pallCount: 11,
        price: 0
      },
      {
        pallCount: 12,
        price: 0
      },
      {
        pallCount: 13,
        price: 0
      },
      {
        pallCount: 14,
        price: 0
      },
      {
        pallCount: 15,
        price: 0
      },
      {
        pallCount: 16,
        price: 0
      },
      {
        pallCount: 17,
        price: 0
      },
      {
        pallCount: 18,
        price: 0
      },
      {
        pallCount: 19,
        price: 0
      },
      {
        pallCount: 20,
        price: 0
      },
      {
        pallCount: 21,
        price: 0
      }
    ] : [
      {
        fromItem: 1,
        toItem: 1,
        price: 0
      },
      {
        fromItem: 2,
        toItem: 4,
        price: 0
      },
      {
        fromItem: 5,
        toItem: 8,
        price: 0
      },
      {
        fromItem: 9,
        toItem: 12,
        price: 0
      },
      {
        fromItem: 13,
        toItem: 16,
        price: 0
      },
      {
        fromItem: 17,
        toItem: 21,
        price: 0
      }
    ]
  }

  const customerPriceData = {
    row: [],
    rowState: {
      id: 0,
      height: itemDefaultSize,
      data: data,
    },
  };

  for (let headerIndex = 0; headerIndex < headers.length; headerIndex++) {
    const headerItem = headers[headerIndex];
    let value = null;
    let fieldType = 'text';
    let editable = false;
    switch (headerItem.key) {
      case 'pickLocation':
        value = '';
        fieldType = 'selectPickLocation';
        break;
      case 'dropLocation':
        value = '';
        fieldType = 'selectDropLocation';
        break;
      case 'tl':
        value = '';
        fieldType = 'integer';
        editable = true;
        break;
      case '1':
        value = '';
        fieldType = 'integer';
        editable = true;
        break;
      case '2-4':
        value = '';
        fieldType = 'integer';
        editable = true;
        break;
      case '5-8':
        value = '';
        fieldType = 'integer';
        editable = true;
        break;
      case '9-12':
        value = '';
        fieldType = 'integer';
        editable = true;
        break;
      case '13-16':
        value = '';
        fieldType = 'integer';
        editable = true;
        break;
      case '17-21':
        value = '';
        fieldType = 'integer';
        editable = true;
        break;
      case 'minPrice':
        value = '';
        fieldType = 'integer';
        editable = true;
        break;
      case 'perWeight':
        value = '';
        fieldType = 'integer';
        editable = true;
        break;
      case 'delete':
        value = 'delete';
        fieldType = 'delete';
        break;
      case '2':
        value = '';
        fieldType = 'integer';
        editable = true;
        break;
      case '3':
        value = '';
        fieldType = 'integer';
        editable = true;
        break;
      case '4':
        value = '';
        fieldType = 'integer';
        editable = true;
        break;
      case '5':
        value = '';
        fieldType = 'integer';
        editable = true;
        break;
      case '6':
        value = '';
        fieldType = 'integer';
        editable = true;
        break;
      case '7':
        value = '';
        fieldType = 'integer';
        editable = true;
        break;
      case '8':
        value = '';
        fieldType = 'integer';
        editable = true;
        break;
      case '9':
        value = '';
        fieldType = 'integer';
        editable = true;
        break;
      case '10':
        value = '';
        fieldType = 'integer';
        editable = true;
        break;
      case '11':
        value = '';
        fieldType = 'integer';
        editable = true;
        break;
      case '12':
        value = '';
        fieldType = 'integer';
        editable = true;
        break;
      case '13':
        value = '';
        fieldType = 'integer';
        editable = true;
        break;
      case '14':
        value = '';
        fieldType = 'integer';
        editable = true;
        break;
      case '15':
        value = '';
        fieldType = 'integer';
        editable = true;
        break;
      case '16':
        value = '';
        fieldType = 'integer';
        editable = true;
        break;
      case '17':
        value = '';
        fieldType = 'integer';
        editable = true;
        break;
      case '18':
        value = '';
        fieldType = 'integer';
        editable = true;
        break;
      case '19':
        value = '';
        fieldType = 'integer';
        editable = true;
        break;
      case '20':
        value = '';
        fieldType = 'integer';
        editable = true;
        break;
      case '21':
        value = '';
        fieldType = 'integer';
        editable = true;
        break;
      default:
        break;
    }
    customerPriceData.row.push({
      key: headerItem.key,
      value,
      fieldType,
      editable,
    });
  }
  return customerPriceData;
};

const createCustomerPriceList = (state, customerPriceData) => customerPriceData.map(data => createCustomerPrice(data, state.headers));

export const getCustomersPriceList = (state, response, hasError = false, message = '') => {
  const data = response.data || [];
  const customerPriceList = createCustomerPriceList(state, data.data);

  return {
      ...state, ...{
          customersPriceList: customerPriceList,
          loading: false,
          hasError,
          message,
      }    
  }
};

export const setLocationsList = (state, response, hasError = false, message = '') => {
  const data = response.data || [];
  return {
      ...state, ...{
          locationsList: data,
          loading: false,
          hasError,
          message,
      }    
  }
};

export const addEmptyLineOnPriceList = (state, customerId) => {
  const emptyLine = createEmptyCustomerPriceLine(state.headers, customerId);

  return {
      ...state, ...{
          customersPriceList: [
            ...[emptyLine],
            ...state.customersPriceList,
          ]
      }
  }
};

export const removeEmptyLinesOnPriceList = (state) => {
  const updatedList = state.customersPriceList.filter(item => item?.rowState.id);

  return {
      ...state, ...{
          customersPriceList: updatedList
      }
  }
};

export const setDisplayState = (state, rowState, header, enable) => ({
  ...state, ...{
      lastSelected: {
          id: rowState.id,
          headerKey: header.key,
          enable: enable
      }
  }
});

const priceRange = {
  '1': 0,
  '2-4': 1,
  '5-8': 2,
  '9-12': 3,
  '13-16': 4,
  '17-21': 5,
};

const pricePall = {
  '1': 0,
  '2': 1,
  '3': 2,
  '4': 3,
  '5': 4,
  '6': 5,
  '7': 6,
  '8': 7,
  '9': 8,
  '10': 9,
  '11': 10,
  '12': 11,
  '13': 12,
  '14': 13,
  '15': 14,
  '16': 15,
  '17': 16,
  '18': 17,
  '19': 18,
  '20': 19,
  '21': 20,
};

export const setPriceData = (state, newValue, rowState, header) => {
    const isPricePerPall = Object.keys(pricePall).includes(header.key);

    const changedPrice = state.customersPriceList.find(price => price.rowState.data?.id === rowState.data?.id)
    if (header.key === 'tl') {
      changedPrice.rowState.data.maxPrice = newValue;
    } else if (header.key === 'minPrice') {
      changedPrice.rowState.data.minPrice = newValue;
    } else if (header.key === 'perWeight') {
      changedPrice.rowState.data.perWeight = newValue;
    } else if (header.key === 'pickLocation') {
      changedPrice.rowState.data.pickLocationId = newValue;
    } else if (header.key === 'dropLocation') {
      changedPrice.rowState.data.dropLocationId = newValue;
    } else {
      if (isPricePerPall) {
        changedPrice.rowState.data.formulas[pricePall[header.key]].price = newValue;
      } else {
        changedPrice.rowState.data.formulas[priceRange[header.key]].price = newValue;
      }
    }

    return {
      ...state,
      ...{
        customersPriceList: state.customersPriceList,
      }
    }
};

export const addCustomersPrice = (state, newPrice) => {
  const newCreatedPrice = createCustomerPrice(newPrice.data, state.headers);
  return {
    ...state, ...{
        customersPriceList: [
          ...state.customersPriceList.slice(1),
          ...[newCreatedPrice],
        ],
        loading: false
    }
  }
};

export const setcustomerPriceType = (state, response, hasError = false, message = '') => {
  return {
      ...state, ...{
          loading: true,
          hasError,
          message,
      }    
  }
};

export const setError = (state, err) => ({
  ...state,
  ...{
      hasError: true,
      message: err ? err : 'Something went wrong, please try again',
  },
});

export const resetError = state => ({
  ...state,
  ...{
      hasError: false,
      message: '',
  }
});

export const setInLoading = state => ({
  ...state, ...{
      loading: true
  }
});

export const setPerPalletType = (state, perPalletType) => ({
  ...state, ...{
    perPalletType: perPalletType
  }
});
