import React from 'react';
import moment from 'moment';
import { CSVLink  } from "react-csv";
import { Select, Tabs, Tooltip, Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import Scroll from "../../scroll/scroll";
import HideShowMenuSwitch from '../../layout/hideShowMenuSwitch';

const ManifestToolBar = ({ 
        selectedWeek,
        setSelectedWeek,
        setTabsKey,
        handleExportWithMethod,
        isHiddenSwitch,
        columns,
        dataManifest
    }) => {

    const weekArray = Array.from(Array(moment().weeksInYear()), (_, x) => x);
    const weekList = weekArray.map(item => ({ "value": `${item + 1}`, "text": `${item + 1}` }))
    const handleSelectChange = (value) => {
        setSelectedWeek(value);
    };

    function callback(key) {
        setTabsKey(`${key}`)
    };

    const CSVHeaders = columns.map(column => ({
        label: column.title,
        key: column.title,
    }));

    let CSVData = [];
    for (let j = 0; j < dataManifest?.length; j++) {    
        CSVData.push({ Cust: dataManifest[j].loadNumber});
        for (let i = 0; i < dataManifest[j].orders.length; i++) {
            let tempObject = {
                Cust: dataManifest[j].orders[i].customerName,
                Pick: dataManifest[j].orders[i].pickLocation,
                Delivery: dataManifest[j].orders[i].dropLocation,
                'PO#': dataManifest[j].orders[i].poNumber,
                'SO#': dataManifest[j].orders[i].soNumber,
                SPA: dataManifest[j].orders[i].space,
                PAL: dataManifest[j].orders[i].palletCount,
                CASE: dataManifest[j].orders[i].cases,
                LBS: dataManifest[j].orders[i].weight,
                WK: dataManifest[j].orders[i].pickWeek,
                IN: dataManifest[j].orders[i].loadNum,
                OUT: dataManifest[j].orders[i].outRefNum,
            }
            CSVData.push(tempObject);
        };
        CSVData.push({
            SPA: dataManifest[j].orders?.map(item => item.space).reduce((a, b) => a + b, 0), 
            CASE: dataManifest[j].orders?.map(item => item.cases).reduce((a, b) => a + b, 0), 
        });
    };

    return (<toolbar scroll="">
        <Scroll>
            <wrap>
                <action>
                    <Tooltip title="Select Week">
                        <label style={{ padding:'0 5px 0 0' }}>Select Week  </label>
                        <Select
                            style={{ width: '100px' }}
                            placeholder="Select Character" id="ddlAntd" size="default"
                            value={`${selectedWeek}`}
                            defaultValue={selectedWeek}
                            onChange={handleSelectChange}
                            >
                            {weekList.map((item, index) => (
                                <Select.Option key={index} value={item.value} >
                                    {item.text}
                                </Select.Option>
                            ))}
                        </Select>
                    </Tooltip> 
                </action>
                <Tabs defaultActiveKey="1" onChange={callback}>
                    <Tabs.TabPane tab="Manifest IN" key="1"></Tabs.TabPane>
                    <Tabs.TabPane tab="Manifest OUT" key="2"></Tabs.TabPane>
                </Tabs>
                <div onClick={handleExportWithMethod} className='btn__download btn__download__pdf'>
                    <DownloadOutlined />
                    <span className='download_text'>Download as PDF</span>
                </div>
                {dataManifest?.length ?
                    <CSVLink 
                        headers={CSVHeaders}
                        data={CSVData}
                        filename={`Manifest_week-${selectedWeek}.csv`}
                        className='btn__download'
                    >
                        <DownloadOutlined />
                        <span className='download_text'>Download as CSV</span>
                    </CSVLink>
                : null}
            </wrap>
        </Scroll>
        {!isHiddenSwitch && <HideShowMenuSwitch />}
    </toolbar>);
};

export default ManifestToolBar;
