import React from 'react';

import Loading from '../loading';

const Popup = props => (
    <div id='windowContent' className='window_cont'>
        {props.isLoading ? <Loading /> : props.children}
    </div>);


export default Popup;