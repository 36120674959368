import { useEffect, useCallback, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { getListCriteria, getCriteriaQuery, getCriteriaQueryFromLocation, buildListFilterQuery } from '../../../helper/list';
import { CloneObject } from '../../../helper/common';
// import { string } from 'prop-types';

export const useFiltering = (stops, availableOrders, filterConfig, mainResource, assignedOrders, subGroup, sortAppointment, profileSettings, typeGroup, availableOrdersType, loadType) => {
    const getFilterConfig = useCallback(() => CloneObject(filterConfig), [filterConfig]);
    const history = useHistory();
    const location = useLocation();
    const criteriaQuery = getCriteriaQuery(history);
    const criteria = useCallback(() => getListCriteria(criteriaQuery), [criteriaQuery]);
    const [applyFor, setApplyFor] = useState('');
    const [filter, setFilter] = useState({});

    const getOrders = (source, valueField, textField) => source.reduce((r, o) => {
        if ((r.length === 0 || !r.find(l => l.value === o[valueField])) && o[valueField] && o[textField] !== '') {
            r.push({
                value: o[valueField],
                text: o[textField]
            });
        }
        return r;
    }, []);

    const getStopsOrders = (source, valueField, textField) => source.reduce((sr, s) =>
        [...sr, ...getOrders(s.orders, valueField, textField).filter(no => !sr.find(o => o.value === no.value))]
        , []);

    const getFilterData = (valueField, textField) => {
        switch (applyFor) {
            case 'Available':
                return getOrders([...availableOrders], valueField, textField);
            case 'Assigned':
                return getStopsOrders(stops, valueField, textField);
            case 'Both':
                const availableOrdersLocations = getOrders(availableOrders, valueField, textField);
                const assignedOrders = getStopsOrders(stops, valueField, textField)
                    .filter(o => !availableOrdersLocations.find(avO => avO.value === o.value));
                return [...availableOrdersLocations, ...assignedOrders];
            default:
                return stops.reduce((r, s) => {
                    if ((r.length === 0 || !r.find(l => l.value === s[valueField])) && s[valueField] && s[textField] !== '') {
                        r.push({
                            value: s[valueField],
                            text: s[textField]
                        });
                    }
                    return r;
                }, []);
        }
    };

    const filterData = {
        pickLocation: () => getFilterData('pickLocationId', 'pickLocation'),
        pickRegion: () => getFilterData('pickRegionCode', 'pickRegionCode'),
        dropLocation: () => getFilterData('dropLocationId', 'dropLocation'),
        dropRegion: () => getFilterData('dropRegionCode', 'dropRegionCode'),
        drop2City: () => getFilterData('drop2City', 'drop2City'),
        drop2State: () => getFilterData('drop2State', 'drop2State'),
        drop2WH: () => getFilterData('drop2WH', 'drop2WH'),
        customer: () => getFilterData('customerId', 'customerCodeOrName'),
        truck: () => getFilterData('truckId', 'truckNumber'),
        carrier: () => getFilterData('carrierId', 'carrierName')
    };

    useEffect(() => {
        const filter = {};
        Object.keys(getFilterConfig()).forEach(fe => {
            if (getFilterConfig()[fe].DefaultValue) {
                filter[fe] = getFilterConfig()[fe].DefaultValue;
            }
        });
        const filterQuery = buildListFilterQuery(getCriteriaQueryFromLocation(location), filter);
        const c = criteria();
        if (Object.keys(filter).length > 0 && Object.keys(c.filter).length === 0) {
            history.push({
                search: filterQuery
            });
            return;
        }
        if (applyFor !== '' && applyFor !== c.filter['applyFor']) {
            const filter = {
                applyFor: c.filter['applyFor']
            };
            const filterQuery = buildListFilterQuery(getCriteriaQueryFromLocation(location), filter);
            history.push({
                search: filterQuery
            });
        }
        setApplyFor(c.filter['applyFor'] ? c.filter['applyFor'] : '');
        setFilter(Object.keys(c.filter).filter(ff => ff !== 'applyFor').reduce((r, f) => {
            r[f] = c.filter[f];
            return r;
        }, {}));
    }, [criteria, location, history, applyFor, getFilterConfig]);

    //const getFilteredSortedOrders = (orders, source, sorting) => {
    //    return orders && orders.filter(o =>
    //        Object.keys(filter).reduce((r, f) =>
    //            r && (
    //                (source === mainResource && 'Assigned' === applyFor) || (source === assignedOrders && 'Available' === applyFor)
    //                || (typeof o[f] === 'string' && o[f].includes(filter[f]))
    //                || (typeof filter[f] === 'string' && Array.isArray(filter[f].split(',')) && filter[f].split(',').indexOf(`${o[f]}`) > -1)
    //                || (new Date(o[f.replace('_from', '')]) instanceof Date && f.indexOf('_from') > 0 && new Date(o[f.replace('_from', '')]) >= new Date(filter[f]))
    //                || (new Date(o[f.replace('_to', '')]) instanceof Date && f.indexOf('_to') > 0 && new Date(o[f.replace('_to', '')]) <= new Date(filter[f]))
    //                || o[f] === filter[f])
    //            , true)
    //    ).sort((o1, o2) => {
    //        
    //        if (sorting?.field) {
    //            const field = sorting.field;

    //            if(!o1[field]) {
    //                return sorting.direction === 'asc' ? -1 : 1
    //            }
    //            if(!o2[field]) {
    //                return sorting.direction === 'asc' ? 1 : -1;
    //            }

    //            if (sorting.direction === 'asc') {
    //                switch (true) {
    //                    // case typeof (o1[field]) === 'object' || typeof (o2[field]) === 'object':
    //                    //     return new Date(o1[field]) - new Date(o2[field]);
    //                    case typeof (o1[field]) === 'string' || typeof (o2[field]) === 'string':
    //                        return o1[field].localeCompare(o2[field]);
    //                    default:
    //                        return o1[field] - o2[field];
    //                }
    //            } else if(sorting.direction === 'desc') {
    //                switch (true) {
    //                    // case typeof (o1[field]) === 'object' || typeof (o2[field]) === 'object':
    //                    //     return new Date(o2[field]) - new Date(o1[field]);
    //                    case typeof (o1[field]) === 'string' || typeof (o2[field]) === 'string':
    //                        return o2[field].localeCompare(o1[field]);
    //                    default:
    //                        return o2[field] - o1[field];
    //                }
    //            }
    //        }

    //        return o1.id - o2.id;
    //    }).sort((o1, o2) => o1[subGroup].localeCompare(o2[subGroup]))
    //};

    const getFilteredSortedOrders = (orders, source, sorting) => {
        const profileFilterSettings = profileSettings?.screens?.[loadType]?.list?.columns &&
             Object.keys(profileSettings.screens[loadType].list.columns);

        let filteredTypeGroupArr = availableOrdersType && availableOrdersType === source && (orders.filter((obj, index, ordersArr) => {
            let typeGroupIndex = ordersArr.findIndex(slot => slot[typeGroup] === obj[typeGroup]);

            return index === typeGroupIndex;
        }));

        const hasSameTypeGroup = filteredTypeGroupArr && profileFilterSettings && (filteredTypeGroupArr.length === profileFilterSettings.length) && filteredTypeGroupArr.every(obj => profileFilterSettings.includes(obj[typeGroup]));

        return orders && orders.filter(o =>
            Object.keys(filter).reduce((r, f) =>
                r && (
                    (source === mainResource && 'Assigned' === applyFor) || (source === assignedOrders && 'Available' === applyFor)
                    || (typeof o[f] === 'string' && o[f].includes(filter[f]))
                    || (typeof filter[f] === 'string' && Array.isArray(filter[f].split(',')) && filter[f].split(',').indexOf(`${o[f]}`) > -1)
                    || (new Date(o[f.replace('_from', '')]) instanceof Date && f.indexOf('_from') > 0 && new Date(o[f.replace('_from', '')]) >= new Date(filter[f]))
                    || (new Date(o[f.replace('_to', '')]) instanceof Date && f.indexOf('_to') > 0 && new Date(o[f.replace('_to', '')]) <= new Date(filter[f]))
                    || o[f] === filter[f])
                , true)
        ).sort((o1, o2) => {
            if (sorting && sorting.length > 0) {
                for (let item of sorting) {
                    const multiplier = item.direction !== 'desc' ? -1 : 1;
                    const firstValue = 'string' === typeof (o1[item.field]) ? o1[item.field].toLowerCase() : o1[item.field];
                    const secondValue = 'string' === typeof (o2[item.field]) ? o2[item.field].toLowerCase() : o2[item.field];

                    if (firstValue !== secondValue) {
                        return multiplier * (firstValue > secondValue ? -1 : 1);
                    }
                }
            }

            return 0;
        }).sort((o1, o2) => {
            if (availableOrdersType && availableOrdersType === source && hasSameTypeGroup) {
                return profileSettings.screens[loadType].list.columns[o1[typeGroup]].index - profileSettings.screens[loadType].list.columns[o2[typeGroup]].index;
            }

            if (availableOrdersType && availableOrdersType === source && hasSameTypeGroup === false) {
                const filterSettings0 = [];
                const filterSettings1 = [];

                filteredTypeGroupArr.forEach(slot => (profileFilterSettings.includes(slot[typeGroup]) ? filterSettings0 : filterSettings1).push(slot[typeGroup]));
                filterSettings0.sort((next, prev) => profileSettings?.screens?.[loadType]?.list?.columns?.[next && prev] &&
                    profileSettings.screens[loadType].list.columns[next].index - profileSettings.screens[loadType].list.columns[prev].index);
                filterSettings1.sort((next, prev) => next.localeCompare(prev));

                const newFilteredSettings = filterSettings0.concat(filterSettings1);

                return newFilteredSettings.indexOf(o1[typeGroup]) - newFilteredSettings.indexOf(o2[typeGroup]);
            }

            return o1[subGroup].localeCompare(o2[subGroup]);
        });
    };

    const getFilteredStops = () => {
        let filteredStop;
        if (applyFor === '') {
            filteredStop = stops.filter(s =>
                Object.keys(filter).reduce((r, f) =>
                    r && (
                        (typeof s[f] === 'string' && s[f].includes(filter[f]))
                        || s[f] === filter[f]
                    )
                    , true)
            );
        }
        else {
            filteredStop = stops && stops.reduce((r, s) => {
                if (s.orders.length === 0 || getFilteredSortedOrders(s.orders, assignedOrders).length > 0) {
                    return [...r, s];
                }
                return r;
            }, []);
        }
        let loadId = 0;
        filteredStop = filteredStop.sort((s1, s2) => new Date(s1[sortAppointment]) - new Date(s2[sortAppointment])).sort((a, b) => {
            var nameA = a.loadNumber.toUpperCase();
            var nameB = b.loadNumber.toUpperCase();
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        });
        filteredStop.forEach((s, i) => {
            if (i === 0) {
                s.isFirst = true;
                loadId = s.loadId;
            }
            else {
                if (s.loadId !== loadId) {
                    s.isFirst = true;
                    loadId = s.loadId;
                }
                else {
                    s.isFirst = false;
                }
            }
        });
        return filteredStop;
    };

    return [filterData, getFilteredSortedOrders, getFilteredStops];
};