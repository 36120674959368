export const GET_DELIVERED_LOADS_LOADING = 'GET_DELIVERED_LOADS_LOADING';
export const GET_DELIVERED_LOADS = 'GET_DELIVERED_LOADS';
export const DELIVERED_LOADS_ERROR = 'DELIVERED_LOADS_ERROR';
export const REACTED_ASSIGNED_DELIVERED_LOADS = 'REACTED_ASSIGNED_DELIVERED_LOADS';
export const RESET_ERROR = 'RESET_ERROR';
export const FILTER_DELIVERED_LOADS = 'FILTER_DELIVERED_LOADS';
export const RELOAD_ON_ERROR_DELIVERED_LOADS = 'RELOAD_ON_ERROR_DELIVERED_LOADS';
export const SET_DISPLAY_STATE_DELIVERED_LOADS = 'SET_DISPLAY_STATE_DELIVERED_LOADS';
export const SET_HEADER_WIDTH_DELIVERED_LOADS = 'SET_HEADER_WIDTH_DELIVERED_LOADS';
export const SET_DELIVERED_LOADS_DATA = 'SET_DELIVERED_LOADS_DATA';
export const SORT_DELIVERED_LOADS = 'SORT_DELIVERED_LOADS';

export const getActionType = (resource, key) => {
    return DELIVERED_LOADS_BUILD[key];
};

export const DELIVERED_LOADS_BUILD = {
    GET_DELIVERED_LOADS: 'GET_DELIVERED_LOADS',
    GET_DELIVERED_LOADS_LOADING: 'GET_DELIVERED_LOADS_LOADING',
    DELIVERED_LOADS_ERROR: 'DELIVERED_LOADS_ERROR',
    RESET_ERROR: 'RESET_ERROR',
    REACTED_ASSIGNED_DELIVERED_LOADS: 'REACTED_ASSIGNED_DELIVERED_LOADS',
    FILTER_DELIVERED_LOADS: 'FILTER_DELIVERED_LOADS',
    SORT_DELIVERED_LOADS: 'SORT_DELIVERED_LOADS',
    RELOAD_ON_ERROR_DELIVERED_LOADS: 'RELOAD_ON_ERROR_DELIVERED_LOADS',
    SET_HEADER_WIDTH_DELIVERED_LOADS: 'SET_HEADER_WIDTH_DELIVERED_LOADS',
    SET_DISPLAY_STATE_DELIVERED_LOADS: 'SET_DISPLAY_STATE_DELIVERED_LOADS',
    SET_DELIVERED_LOADS_DATA: 'SET_DELIVERED_LOADS_DATA',
};
