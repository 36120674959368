import React, { useEffect, useRef, useCallback, useMemo, useState } from 'react';
import { connect } from "react-redux";
import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeList } from 'react-window';
import { Dropdown, Space, message, Menu } from 'antd';
import { orderBy } from "lodash";
import './index.css'
import HeaderRow from './headerRow';
import Row from './row';
import Loading from "../../screen/loading";
import { getCustomerOrderAdmin, sortBy, filterByCustomer, generateCustomerOrder, rejectCustomerOrder, resetError } from '../../../../store/actions/customerOrderAdmin/customerOrderAdmin';
import { getContentWidth } from '../../../../store/reducers/customerOrderAdmin/helper/general';
import AcceptModal from "./modal";
import { useClientHubNewRequest } from "../../../hook/hub/useClientHubNewRequest";
import { useClientHubOrder } from "../../../hook/hub/useClientHubOrder";

const CustomerOrderAdmin = props => {
    const { resource,
            getCustomerOrderAdmin,
            loading,
            hasError,
            state,
            total,
            sortBy,
            filterByCustomer,
            generateCustomerOrder,
            generateCustomerOrderLoading,
            rejectCustomerOrderLoading,
            rejectCustomerOrder,
            orderRejected,
            orderGenerated,
            messageText,
            resetError,
            userId,
        } = props;
    const defRowHeight = 40;
    const scrollSize = 0;
    const customerOrderAdminRef = useRef(null);
    const listReference = useRef(null);
    const listHeaderReference = useRef(null);
    const customerOrderAdminHeaderReference = useRef(null);
    const mainScroll = useRef(null);
    const startOnScroll = width => mainScroll.current.scrollLeft = width;
    const headers = state[resource].headers;
    const width = getContentWidth(headers) + scrollSize;
    const [sortValue, setSortValue] = useState('');
    const [checkedList, setCheckedList] = useState([]);
    const [modalIsOpen, setIsOpen] = useState(false);
    const [rejectModalIsOpen, setRejectModalIsOpen] = useState(false);

    const onScrollX = e => {
        if (listHeaderReference.current && listReference.current && e.target) {
            listHeaderReference.current.scrollLeft =
            listReference.current.scrollLeft =
            e.target.scrollLeft;
        }
        if (customerOrderAdminHeaderReference.current && listReference.current && e.target) {
            customerOrderAdminHeaderReference.current.scrollLeft =
            listReference.current.scrollLeft =
            e.target.scrollLeft;
        }
    }

    useClientHubNewRequest((message) => {
        if (userId !== message.byId) {
            getCustomerOrderAdmin(resource, 'withoutLoading')
        }
    }, 'notification');

    useClientHubOrder((message) => {
        if (userId !== message.byId) {
            getCustomerOrderAdmin(resource, 'withoutLoading')
        }
    }, 'notification');

    useEffect(() => {
        getCustomerOrderAdmin(resource);
    }, [resource, getCustomerOrderAdmin]);

    useEffect(() => {
        filterByCustomer(resource, null);
    }, [filterByCustomer, resource, total]);

    useEffect(() => {
        if (hasError) {
            message.error(messageText, 3);
            resetError(resource);
            setIsOpen(false);
            setRejectModalIsOpen(false);
        }
    }, [hasError, resource, messageText, resetError]);

    useEffect(() => {
        if (orderGenerated) {
            message.success('Order accepted successfully!', 3);
            resetError(resource);
            setIsOpen(false);
            setRejectModalIsOpen(false);
}
    }, [orderGenerated, resetError, resource]);

    useEffect(() => {
        if (orderRejected) {
            message.success('Order rejected successfully!', 3);
            resetError(resource);
            setIsOpen(false);
            setRejectModalIsOpen(false);
        }
    }, [orderRejected, resetError, resource]);

    let customerOrderAdminList = [];

    const sortByValue = (customerOrderAdmin, value) => {
        let sortedList = [];
        if (value === 'byCustomer') {
            sortedList = orderBy(customerOrderAdmin, [c => c.row[0].value], ["asc"]);
        };
        if (value === 'byDate') {
            sortedList = orderBy(customerOrderAdmin, [c => c.row[1].value], ["asc"]);
        };
        if (value === 'byDirection') {
            sortedList = orderBy(customerOrderAdmin, [c => c.row[4].value], ["asc"]);
        };
        return sortedList;
    };

    if (!state[resource]?.filteredCustomerOrderAdmin?.length && !sortValue) {
        customerOrderAdminList = state[resource]?.customerOrderAdmin;
    } else if (!sortValue) {
        if (state[resource]?.filteredCustomerOrderAdmin?.length) {
            customerOrderAdminList = state[resource]?.filteredCustomerOrderAdmin;
        } else {
            customerOrderAdminList = state[resource]?.customerOrderAdmin;
        }
    } else if (sortValue) {
        if (state[resource]?.filteredCustomerOrderAdmin?.length) {
            customerOrderAdminList = sortByValue(state[resource]?.filteredCustomerOrderAdmin, sortValue) 
        } else {
            customerOrderAdminList = state[resource]?.sortedCustomerOrderAdmin;
        }
    }

    const handleAcceptButton = () => {
        setIsOpen(true);
    };

    const handleRejectButton = () => {
        setRejectModalIsOpen(true);
        setIsOpen(true);
    };

    const handleDeSelectAllButton = () => {
        setCheckedList([]);
        filterByCustomer(resource, null);
    };

    const header = useMemo(() => <div ref={listHeaderReference} style={{
        overflow: 'hidden',
        margin: '0 12px',
        borderRadius: '12px 12px 0 0',
        borderLeft: '1px solid #29CABA',
    }}>
        <HeaderRow resource={resource} />
    </div>, [resource]);

    const getCustomerOrderAdminListItemSize = useCallback(
        index => customerOrderAdminList?.[index] ? customerOrderAdminList[index].rowState.height : defRowHeight,
        [customerOrderAdminList]);

    const RenderCustomerOrderAdminListRow = useCallback(({ data, index, style }) => (
        (index !== undefined && data[index])
            ? <Row
                listRef={customerOrderAdminRef}
                data={data[index]}
                itemSize={getCustomerOrderAdminListItemSize(index)}
                style={style}
                resource={resource}
                startOnScroll={startOnScroll}
                index={index}
                setCheckedList={setCheckedList}
                checked={checkedList && checkedList.includes(data[index]?.rowState?.loadData.id)}
            />
            : null
    ), [checkedList, getCustomerOrderAdminListItemSize, resource]);

    const allCustomerOrderAdminList = useMemo(() => customerOrderAdminList &&
    <AutoSizer disableWidth>
        {({ height }) => (
            <>
                <VariableSizeList
                    ref={customerOrderAdminRef}
                    outerRef={el => { listReference.current = el }}
                    style={{ 
                        overflowX: 'hidden', 
                        overflowY: 'auto',                        
                        borderBottom: '1px solid #dedede',
                        borderLeft: '1px solid #dedede',
                        borderRight: '1px solid #dedede',
                        borderRadius: '0px 0px 12px 12px',
                    }}
                    itemData={customerOrderAdminList}
                    itemCount={customerOrderAdminList.length}
                    height={customerOrderAdminList && (customerOrderAdminList.length * 29) > height ? height : customerOrderAdminList.length * 28.2}
                    itemSize={getCustomerOrderAdminListItemSize}>
                    {RenderCustomerOrderAdminListRow}
                </VariableSizeList>
            </>)}
    </AutoSizer>
    , [customerOrderAdminList, getCustomerOrderAdminListItemSize, RenderCustomerOrderAdminListRow]);

    const handleSelectChange = e => {
        sortBy('customerOrder', e.key);
        setSortValue(e.key);
    };
    
    const menu = (
        <Menu className='customer-order__menu' onClick={handleSelectChange}>
            <Menu.Item key="byCustomer">By customer</Menu.Item>
            <Menu.Item key="byDate">By date</Menu.Item>
            <Menu.Item key="byDirection">By direction</Menu.Item>
        </Menu>
    );

    const buttonsContainer = (
        <div className='order-admin-buttons__container'>
            <button 
                className={`order-admin-button ${checkedList.length && 'order-admin-button-disabled'} `} 
                disabled={!checkedList.length}
                onClick={handleAcceptButton}
            >
                Accept
            </button>
            <button 
                className={`order-admin-button ${checkedList.length && 'order-admin-button-disabled'} `} 
                disabled={!checkedList.length}
                onClick={handleRejectButton}
            >
                Reject
            </button>
            <separator className='customerOrder-admin__separator' vertical=''></separator>
            <button 
                className={`order-admin-button ${checkedList.length && 'order-admin-button-disabled'} `} 
                disabled={!checkedList.length}
                onClick={handleDeSelectAllButton}
            >
                Deselect All
            </button>
        </div>
    );

    const content = (<>
        <div className='customer-order__upper-header'>
            <div className='customer-order__upper-header__left customer-order__upper-header__admin last_orders_left'>
            <div className='customer-order__upper-header__sort'>
                <div className='sort_icon'></div>
                <Dropdown className='customer-order__dropdown' overlay={menu} placement="bottomLeft">
                    <span onClick={(e) => e.preventDefault()}>
                    <Space>Sort</Space>
                    </span>
                </Dropdown>
            </div>
            <separator className='customerOrder__separator' vertical=''></separator>
            <div className='customer-order__upper-header__orders-count'>
                New Orders: <span>{customerOrderAdminList?.length}</span>
            </div>
            </div>
        </div>
        <div className='headerContainer'>{header}</div>
        <div style={{ flexGrow: 1, margin: '0 12px' }}>{allCustomerOrderAdminList}</div>
        {buttonsContainer}
        <div style={{
            width: 'calc(100% - 16px)',
            overflowX: 'auto',
            flexShrink: 0,
            marginBottom: '3px',
            position: 'absolute',
            bottom: '50px',
            height: '8px',
        }}
            ref={mainScroll}
            onScroll={e => onScrollX(e)}>
            <div
                className='table-body-cell'
                style={{
                    maxWidth: `${width}px`,
                    width: `${width}px`,
                    minWidth: `${width}px`,
                    borderBottom: `${width < window.innerWidth ? 'none' : ''}`,
                }} />
        </div>
        {modalIsOpen &&
            <AcceptModal
                modalIsOpen={modalIsOpen}
                rejectModalIsOpen={rejectModalIsOpen}
                rejectCustomerOrder={rejectCustomerOrder}
                setIsOpen={setIsOpen}
                resource={resource}
                generateCustomerOrder={generateCustomerOrder}
                checkedList={checkedList}
                generateCustomerOrderLoading={generateCustomerOrderLoading}
                rejectCustomerOrderLoading={rejectCustomerOrderLoading}
                getCustomerOrderAdmin={getCustomerOrderAdmin}
                total={total}
                orderGenerated={orderGenerated}
                orderRejected={orderRejected}
                setCheckedList={setCheckedList}
                filterByCustomer={filterByCustomer}
                messageText={messageText}
                hasError={hasError}
                setRejectModalIsOpen={setRejectModalIsOpen}
                customerrderAdminList={state[resource]?.filteredCustomerOrderAdmin}
            />
        }
    </>);
    return <>{loading ? <Loading /> : content}</>;
};

const mapStateToProps = state => ({
    state: state,
    loading: state.customerOrderAdmin.loading,
    total: state.customerOrderAdmin.total,
    generateCustomerOrderLoading: state.customerOrderAdmin.generateCustomerOrderLoading,
    rejectCustomerOrderLoading: state.customerOrderAdmin.rejectCustomerOrderLoading,
    orderRejected: state.customerOrderAdmin.orderRejected,
    orderGenerated: state.customerOrderAdmin.orderGenerated,
    messageText: state.customerOrderAdmin.messageText,
    hasError: state.customerOrderAdmin.hasError,
    userId: state.auth.userId,
});

const mapDispatchToProps = (dispatch) => ({
    getCustomerOrderAdmin: (resource, loadingType) => dispatch(getCustomerOrderAdmin(resource, loadingType)),
    generateCustomerOrder: (resource, endPoint, data) => dispatch(generateCustomerOrder(resource, endPoint, data)),
    rejectCustomerOrder: (resource, endPoint, data) => dispatch(rejectCustomerOrder(resource, endPoint, data)),
    filterByCustomer: (resource, customerId) => dispatch(filterByCustomer(resource, customerId)),
    sortBy: (resource, sortValue) => dispatch(sortBy(resource, sortValue)),
    resetError: resource => dispatch(resetError(resource)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomerOrderAdmin);