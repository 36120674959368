import React from 'react';
import Modal from 'react-modal';
import './index.css';
import { message } from 'antd';

const TBDUpdateModal = ({
      setTBDModalToOpen,
      isTBDModalOpen,
      setTBD,
      resource,
      setItemData,
      rowState,
    }) => {

  const closeModal = () => {
    setTBDModalToOpen(false);
  };

  const customStyle = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      minWidth: '300px',
      minHeight: '150px',
      maxWidth: '400px',
      maxHeight: '400px',
      borderRadius: '8px',
      padding: '0',
    },
  };

  const throwNeededMessage = () => {
    if (rowState.orderData.palletCount < 1) {
        message.error("For regular order, Pallet quantity can’t be 0");
    }
    if (rowState.orderData.weight < 1) {
        message.error("For regular order, LBS can’t be 0");
    }
    if (rowState.orderData.poNumber.includes('TBD')) {
        message.error("For regular order Po# can’t be start with TBD");
    }
}

  const handleConfirm = () => {
    const allowTBDUpdate = 
      rowState.orderData.palletCount && 
      rowState.orderData.weight && 
      !rowState.orderData.poNumber.includes('TBD');
    if (allowTBDUpdate)  {
      setTBD(false);
      setItemData(resource, false, rowState, { key :'isTbd' });
      closeModal();
    } else (
      throwNeededMessage()
    )
  };

  return (
    <Modal
        isOpen={isTBDModalOpen}
        onRequestClose={closeModal}
        style={customStyle}
        ariaHideApp={false}
        >
        <div className='tbd_modal__container'>
            <div className='tbd__title-container'>
              <div className='tbd_modal__x-btn' onClick={closeModal}>X</div>
              <div>Order Updating</div>
            </div>
            <div className='tbd_modal__text'>Are you sure you want to convert this TBD order to a regular order?</div>
            <div className='tbd-btn__container'>
              <div className='tbd_modal__btn-cancel' onClick={closeModal}>CANCEL</div>
              <div className='tbd_modal__btn-confirm' onClick={handleConfirm}>CONFIRM</div>
            </div>
        </div>  
    </Modal>
  );
};

export default TBDUpdateModal;

