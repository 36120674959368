import {
    ElementTypes,
    ElementValidations,
    ColumnValidation,
    Action,
    ListDefaults,
    ColumnType,
    FilterType,
    GenerateToolBars,
    DefaultPagination
} from '../../config';

import CarrierConfig from '../Carrier/config';

const Driver = {
    Title: 'Driver',
    Resource: 'Driver',
    Master: 'driverName',
    Key: 'id',
    IsRest: true,
    List: {
        Defaults: ListDefaults,
        ShowRefreshButton: true,
        Search: '_all',
        Pagination: DefaultPagination,
        Filter: {
            driverName: {
                Type: FilterType.Text,
                Title: 'Name'
            },
            carrierId: {
                Type: FilterType.Select,
                Valuelist: '/api/Carrier/valuelist',
                Title: 'Carrier Name'
            },
            driverPhone: {
                Title: 'Phone',
                Type: FilterType.Phone
            },
            expirationDate: {
                Type: FilterType.Date,
                Title: 'Expired'
            }
        },
        Columns: {
            driverName: {
                Title: 'Name',
                Type: ColumnType.Text,
                LinkAppPage: true,
                Sorted: 'ascend',
                DataTitle: false,
                Width: 150
            },
            carrierName: {
                Title: 'Carrier Name',
                Type: ColumnType.Text,
                Width: 150
            },
            driverPhone: {
                Title: 'Phone',
                Type: ColumnType.Phone,
                Width: 150
            },
            expirationDate: {
                Title: 'Expired',
                Type: (text) => ColumnValidation.MonthExpiration(text, 1, ColumnType.Date, 'orange', 'red'),
                Width: 150
            }
        },
        Actions: {
            Width: 60,
            Items: [
                Action.Delete
            ]
        }
    },
    Form: {
        SectionGroups: [
            {
                Sections: [
                    {
                        Title: null,
                        Fields: {
                            carrierId: {
                                Title: 'Carrier',
                                //Field: ElementTypes.ComboAdd('/api/carrier/valuelistempty', {
                                //    PopupConfig: CarrierConfig
                                //})
                                Field: ElementTypes.Generic(ElementTypes.ComboAdd, {
                                    dataSource: '/api/carrier/valuelistempty',
                                    addparams: {
                                        PopupConfig: CarrierConfig
                                    },
                                    selectedValueType: 'carrierName'
                                }),
                                Validations: [ElementValidations.Required()],
                            },
                            driverName: {
                                Title: 'Name',
                                Field: ElementTypes.TextField(),
                                Validations: [ElementValidations.OnlyLettersAndSpace()]
                            },
                            driverPhone: {
                                Title: 'Phone',
                                Field: ElementTypes.Phone(),
                                Attributes: {
                                    input: {
                                        maxLength: 15
                                    }
                                },
                                Validations: [ElementValidations.Required()]
                            },
                            driverEmail: {
                                Title: 'Email',
                                Field: ElementTypes.TextField(),
                                Validations: [ElementValidations.Email()]
                            },
                            licenseNumber: {
                                Title: 'License Number',
                                Field: ElementTypes.TextField(),
                                Attributes: {
                                    input: {
                                        maxLength: 20
                                    }
                                },
                                Validations: [ElementValidations.Required()]
                            },
                            expirationDate: {
                                Title: 'Expiration Date',
                                Field: ElementTypes.DateField(),
                                Validations: [ElementValidations.Required(), ElementValidations.DateIsExpired('License Number')]
                            },
                            active: {
                                Field: ElementTypes.CheckBox('Active'),
                            }
                        }
                    }
                ]
            }
        ],
        ToolBars: GenerateToolBars()
    }
}; 

export default Driver;