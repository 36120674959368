import React from 'react';

const FilterField = props => (
    <div className="filter_block">
        {props.Title && <span className={'title'}><span>{props.Title}</span></span>}
        <div className="data_cell">
            {props.formFields
                ? props.children
                : <wrap block="">
                    <label className={props.className ? props.className : "input_cont"}>
                        {props.children}
                        <i></i>
                    </label>
                </wrap>
            }
        </div>

    </div>
);

export default FilterField;