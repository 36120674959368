import React from 'react';
import DeliveredLoads from '../../DeliveredLoads/spreadsheet';

const DeliveredBuild = () => (
  <div
    style={{
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
    }}>
    <DeliveredLoads resource='deliveredLoads' />
  </div>
);

export default DeliveredBuild;
