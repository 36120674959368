import React from 'react';
import Modal from 'react-modal';
import { warningModalStyles } from './customStyles';

const WarningModal = ({ warningModalIsOpen, setWarningModalIsOpen, text }) => {
  const closeModal = () => {
    setWarningModalIsOpen(false);
  };

  return (
    <Modal
        isOpen={warningModalIsOpen}
        onRequestClose={closeModal}
        style={warningModalStyles}
        ariaHideApp={false}
        >
        <div className='edit_modal__container'>
          <div className='edit_modal_text'>{text}</div>
          <div>
            <button className='edit-modal-button' type="submit" onClick={closeModal}>Close</button>
          </div>
        </div>  
    </Modal>
  );
};

export default WarningModal;